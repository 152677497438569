import { generalAlertError } from '../../common/utils/alerts';
import managementAxe from '../../services/axios-client/managementServisAxeClient';
import caller from './activeCustomerSlice';

export const getBrokerActiveCustomers = async (
  page = 1,
  search = '',
  search_by = 'active_customer',
) => {
  caller.getBrokerActiveCustomersPending();
  try {
    const response = await managementAxe.get(
      `/broker/customers/get-brokers-active-customers?page=${page}&search=${search}&search_by=${search_by}`,
    );

    if (response.status !== 200) {
      caller.getBrokerActiveCustomersRejected('Errore Interno');
      return;
    }
    caller.getBrokerActiveCustomersSuccess(response.data);
  } catch (err) {
    caller.getBrokerActiveCustomersRejected('An error has occured');
  }
};

export const getBrokerActiveCustomersNetwork = async (
  page = 1,
  search = '',
  search_by = 'active_customer',
) => {
  try {
    const response = await managementAxe.get(
      `/broker/customers/get-brokers-rete-active-customers?page=${page}&search=${search}&search_by=${search_by}`,
    );

    if (response.status !== 200) {
      caller.getBrokerActiveCustomersNetworkRejected('Errore Interno');
      return;
    }
    caller.getBrokerActiveCustomersNetworkSuccess(response.data);
  } catch (err) {
    caller.getBrokerActiveCustomersNetworkRejected('An error has occured');
  }
};

export const getBrokerActiveCustomerInformation = async (activeCustomerId) => {
  caller.getBrokerActiveCustomerInformationPending();
  try {
    const response = await managementAxe.get(
      `/broker/customers/active-customers-information/${activeCustomerId}`,
    );
    if (response.status !== 200) {
      caller.getBrokerActiveCustomerInformationRejected('Errore Interno');
      return;
    }

    caller.getBrokerActiveCustomerInformationSuccess(response);
  } catch (err) {
    caller.getBrokerActiveCustomerInformationRejected('An error has occured');
  }
};

export const getActiveCustomerEmailInformation = async (gmailId) => {
  caller.readActiveCustomerEmailPending();
  try {
    const response = await managementAxe.get(
      `/broker/customers/read-active-customer-mail/${gmailId}`,
    );

    caller.readActiveCustomerEmailSuccess(response);
  } catch (err) {
    caller.readActiveCustomerEmailRejected('An error has occured');
  }
};

export const resetActiveCustomers = () => {
  caller.activeCustomersReset();
};

export const requestClientAppAccount = async (
  activeCustomerId,
  successCallback,
) => {
  const response = await managementAxe.post(
    `/broker/customers/active-customers/${activeCustomerId}/activate-account`,
  );

  if (response.status === 400) {
    generalAlertError("L'email e in uso per un altro account");
    return;
  }

  if (response.status === 200) {
    successCallback();
  }
};
