import styled from 'styled-components';

export const ErrorComponentStyles = styled.div`
  img{
    width: 400px;
  }

  @media screen and (max-width: 768px) {
    img{
        width: 300px;
    }
  }
`;
