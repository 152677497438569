import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faRefresh } from '@fortawesome/free-solid-svg-icons';
import {
  useState, useRef, useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import moment from 'moment';
import { io } from 'socket.io-client';
import { useLocation, useNavigate } from 'react-router-dom';
import { messagesMap } from './GeneralChat.mapping';
import { chatAxe } from '../../../../services/axios-client/chatAxeClient';
import StorageService from '../../../../services/StorageService';
import managementAxe from '../../../../services/axios-client/managementServisAxeClient';
import { CHAT_SERVICE_SOCKET_URL } from '../../../../config';
import { sendChatMessageConversation, sendChatMessageToken } from './GeneralChat.socket';
import { GeneralChatStyled } from './GeneralChat.styles';
import RobotImage from '../../../../assets/images/robot.png';
import AudioRecorderForChat from '../../AudioRecorderForChat';

const storageService = new StorageService();

function GeneralChatComponent({ openChat, setOpenChat }, ref) {
  const navigate = useNavigate();
  const location = useLocation();

  const bottomRef = useRef(null);
  const outsideSendMessageRef = useRef(null);
  const playAudioRef = useRef(null);

  const [conversationId, setConversationId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState('');
  const [partialResponse, setPartialResponse] = useState(null);
  const [finishConversation, setFinishConversation] = useState(false);
  const [isAudio, setIsAudio] = useState(false);

  const getAllowedOptionsFromHistory = (messagesMap, messages) => {
    if (messages.length === 0) {
      return messagesMap;
    }

    const msg = messages[0];

    const messageObject = messagesMap.find((o) => o.message === msg);

    if (messageObject === undefined) {
      return false;
    }

    if (messageObject.allowTyping) {
      return messageObject;
    }

    if (messageObject.options === undefined) {
      return false;
    }

    const [, ...newMessages] = messages;

    return getAllowedOptionsFromHistory(messageObject.options, newMessages);
  };

  const resetConversation = () => {
    socket?.disconnect();
    setConversationId(null);
    setMessages([]);
    setMessage('');
    setSocket(null);
    setPartialResponse(null);
    setFinishConversation(false);
  };

  const sendMessage = async (message, successfulCallback) => {
    setMessage('');
    setPartialResponse('');

    const response = await chatAxe.post('/chat/general/message/send', {
      conversationId,
      message,
    });

    if (response.status === 200) {
      setConversationId(response.data.data.conversation_id);
      setMessages([...messages, {
        id: 'new-message',
        from: 'user',
        message,
        created_at: new Date().toISOString(),
      }]);
      if (successfulCallback !== undefined) {
        successfulCallback();
      }
    }
  };

  const selectedMessage = (message) => {
    sendMessage(message.trim());
  };

  const formatMessage = (message) => message.split('\n').map((m) => <div>{m}</div>);

  const getAudioFromText = async (text) => {
    const response = await managementAxe.post('/broker/speech/text/to/audio', { text });

    if (response.status === 200) {
      const audio = new Audio(response.data.content);
      audio.play();
    }
  };

  const playAudio = (text) => {
    if (isAudio) {
      setIsAudio(false);
      getAudioFromText(text);
    }
  };

  playAudioRef.current = playAudio;

  const isLastMessageACommand = () => {
    const lastMessage = messages[messages.length - 1];

    try {
      const parsed = JSON.parse(lastMessage.message);
      if (parsed.type !== 'command') {
        return false;
      }
    } catch (err) {
      return false;
    }

    return true;
  };

  const onKeyUpHandler = (e) => {
    if ((e.key === 'Enter' || e.keyCode === 13) && !e.shiftKey) {
      sendMessage(e.target.value.trim());
    } else {
      setMessage(e.target.value.trim());
    }
  };

  useEffect(() => {
    if (conversationId === null) {
      return;
    }

    const options = {
      forceNew: true,
      query: {
        token: storageService.getToken(),
        conversationId,
      },
    };

    const s = io(CHAT_SERVICE_SOCKET_URL, options);
    setSocket(s);
  }, [conversationId]);

  useEffect(() => {
    if (socket !== null) {
      socket.off(sendChatMessageToken).on(sendChatMessageToken, ({ token }) => {
        setPartialResponse((prev) => prev + token);
      });

      socket.off(sendChatMessageConversation).on(sendChatMessageConversation, ({ conversation, finishConversation }) => {
        playAudioRef.current(conversation[conversation.length - 1].message);
        setPartialResponse(null);
        setMessages(conversation);
        if (finishConversation) {
          setFinishConversation(true);
        }
      });
    }
  }, [socket]);

  useEffect(() => {
    bottomRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages, partialResponse]);

  const userMessages = messages.filter((m) => m.from === 'user').map((m) => m.message);
  const allowedMessages = getAllowedOptionsFromHistory(messagesMap, userMessages);

  const showSendMessage = allowedMessages?.allowTyping === true && partialResponse === null && isLastMessageACommand() === false && finishConversation === false;

  useEffect(() => {
    const lastMessage = messages[messages.length - 1];

    let parsedCommand;
    try {
      parsedCommand = JSON.parse(lastMessage.message);
      if (parsedCommand.type !== 'command') {
        return;
      }
    } catch (err) {
      return;
    }

    if (parsedCommand !== undefined) {
      if (parsedCommand.page === 'auto-form') {
        setOpenChat(false);

        storageService.removeBersani();
        storageService.removeVehicleDriverData();
        storageService.removeVehicleOwnerData();
        storageService.removeVehicleQuoteAnswers();

        navigate('/quote/auto', {
          state: {
            type: 'command',
            from: 'chat',
            data: parsedCommand,
          },
        });

        if (location.pathname === '/quote/auto') {
          storageService.removeBersani();
          storageService.removeVehicleDriverData();
          storageService.removeVehicleOwnerData();
          storageService.removeVehicleQuoteAnswers();
          window.location.reload();
        }
      }

      if (parsedCommand.page === 'professionale-form') {
        setOpenChat(false);
        navigate('/quote/profession');
      }

      if (parsedCommand.page === 'tutorials') {
        setOpenChat(false);
        navigate('/tutorial');
      }

      if (parsedCommand.page === 'accident') {
        setOpenChat(false);
        navigate('/after-sale', {
          state: {
            type: 'command',
            from: 'chat',
            data: parsedCommand,
          },
        });

        if (location.pathname === '/after-sale') {
          window.location.reload();
        }
      }

      if (parsedCommand.page === 'replacement') {
        setOpenChat(false);
        navigate('/after-sale', {
          state: {
            type: 'command',
            from: 'chat',
            data: parsedCommand,
          },
        });

        if (location.pathname === '/after-sale') {
          window.location.reload();
        }
      }

      if (parsedCommand.page === 'modification') {
        setOpenChat(false);
        navigate('/after-sale', {
          state: {
            type: 'command',
            from: 'chat',
            data: parsedCommand,
          },
        });

        if (location.pathname === '/after-sale') {
          window.location.reload();
        }
      }

      if (parsedCommand.page === 'cancellation') {
        setOpenChat(false);
        navigate('/after-sale', {
          state: {
            type: 'command',
            from: 'chat',
            data: parsedCommand,
          },
        });

        if (location.pathname === '/after-sale') {
          window.location.reload();
        }
      }

      if (parsedCommand.page === 'send-documents') {
        setOpenChat(false);
        navigate('/after-sale', {
          state: {
            type: 'command',
            from: 'chat',
            data: parsedCommand,
          },
        });

        if (location.pathname === '/after-sale') {
          window.location.reload();
        }
      }

      if (parsedCommand.page === 'create-memo') {
        setOpenChat(false);
        navigate('/reminders/create', {
          state: {
            type: 'command',
            from: 'chat',
            data: parsedCommand,
          },
        });

        if (location.pathname === '/reminders/create') {
          window.location.reload();
        }
      }

      if (parsedCommand.page === 'contact-client') {
        setOpenChat(false);
        navigate('/clients', {
          state: {
            type: 'command',
            from: 'chat',
            data: parsedCommand,
          },
        });
      }
    }
  }, [messages]);

  const sendMessageFromOutside = (message) => {
    resetConversation();
    setTimeout(() => {
      outsideSendMessageRef.current.sendMessage('Flusso non diretto', () => {
        setTimeout(() => {
          setIsAudio(true);
          outsideSendMessageRef.current.sendMessage(message);
        }, 1000);
      });
    }, 1500);
  };

  outsideSendMessageRef.current = { sendMessageFromOutside, sendMessage };

  const sendMessageFromAudio = (message) => {
    setIsAudio(true);
    outsideSendMessageRef.current.sendMessage(message);
  };

  useImperativeHandle(ref, () => ({
    sendMessage(message) {
      outsideSendMessageRef.current.sendMessageFromOutside(message);
    },
  }));

  return (
    <GeneralChatStyled>
      {openChat === true && (
        <div className="chat-modal">
          <div className="backdrop" onClick={() => setOpenChat(false)} />
          <div className="chat-container">
            <img src={RobotImage} className="chat-background-image" />
            <div className="chat-title">
              <div className="chat-title-info">Parla con Greenia</div>
              {(messages.length !== 0)
                && (
                  <div className="chat-title-reset" onClick={() => resetConversation()}>
                    <FontAwesomeIcon icon={faRefresh} />
                  </div>
                )}
            </div>
            <div className="chat-content">
              {messages.filter((m) => {
                try {
                  JSON.parse(m.message);

                  return false;
                } catch (err) {
                  return true;
                }
              }).map((m) => (
                <div className={`chat-message chat-message-${m.from}`} key={m.id}>
                  <div className="chat-message-time">{moment(m.created_at).fromNow()}</div>
                  <div className="chat-message-message">{formatMessage(m.message)}</div>
                </div>
              ))}

              {Array.isArray(allowedMessages) === true && (
                <>
                  <div className="chat-options-helper">Seleziona...</div>

                  <div className="chat-options-container">
                    {allowedMessages.map((m) => (
                      <div key={m.message} className="chat-message chat-message-option" onClick={() => selectedMessage(m.message)}>
                        <div className="chat-message-message">{m.message}</div>
                      </div>
                    ))}
                  </div>
                </>
              )}

              {partialResponse !== null
                && (
                  <div className="chat-message chat-message-system">
                    <div className="chat-message-time">{moment().fromNow()}</div>
                    <div className="chat-message-message">
                      {formatMessage(partialResponse)}
                      <div className="blinking-cursor" />
                    </div>
                  </div>
                )}

              <div ref={bottomRef} className="bottom-ref" />
            </div>
            {showSendMessage === true
              && (
                <div className="chat-message-sender">
                  <textarea maxLength={500} onKeyUp={onKeyUpHandler} />
                  <div className="chat-message-send-button">
                    <AudioRecorderForChat sendMessage={sendMessageFromAudio} />
                  </div>
                  <div className="chat-message-send-button" onClick={() => sendMessage(message)}>
                    <FontAwesomeIcon icon={faPaperPlane} />
                  </div>
                </div>
              )}
          </div>
        </div>
      )}
    </GeneralChatStyled>
  );
}

export const GeneralChat = forwardRef(GeneralChatComponent);
