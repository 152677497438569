import axios from 'axios';
import { BACKEND_API_URL } from '../../config';
import { axeRequestInterceptor, axeResponseInterceptor } from './axeHelpers';

const axe = axios.create();

axe.defaults.validateStatus = (status) => status < 500;

axe.interceptors.request.use(axeRequestInterceptor);

axe.interceptors.response.use((response) => response, axeResponseInterceptor);

axe.defaults.baseURL = BACKEND_API_URL;

export default axe;
