import { createSlice } from '@reduxjs/toolkit';

import { callerCreator } from '../callerCreator';

const initialState = {
  active_customers: {
    loading: true,
    error: null,
    activeCustomers: [],
    total_pages: 1,
    page_number: 1,
  },
  active_customer: {
    loading: true,
    error: null,
    activeCustomer: null,
    activeQuotes: [],
    insurance_requests: [],
    gmails: [],
    contacts: [],
    after_sales: [],
  },
  network_active_customers: {
    loading: true,
    error: null,
    activeCustomers: [],
    activeQuotes: [],
    allQuotes: [],
    gmails: [],
    total_pages: 1,
    page_number: 1,
  },
  read_mail: {
    loading: true,
    error: null,
    email: null,
  },
};
export const activeCustomersSlice = createSlice({
  name: 'activeCustomers',
  initialState,
  reducers: {
    getBrokerActiveCustomersPending: (state) => {
      state.active_customers.loading = true;
      state.active_customers.error = null;
    },
    getBrokerActiveCustomersSuccess: (state, { payload }) => {
      state.active_customers.loading = false;
      state.active_customers.activeCustomers = payload.data;
      state.active_customers.page_number = payload.current_page;
      state.active_customers.total_pages = payload.total_pages;
    },
    getBrokerActiveCustomersRejected: (state, { payload }) => {
      state.active_customers.loading = false;
      state.active_customers.error = payload;
    },
    getBrokerActiveCustomersNetworkPending: (state) => {
      state.network_active_customers.loading = true;
      state.network_active_customers.error = null;
    },
    getBrokerActiveCustomersNetworkSuccess: (state, { payload }) => {
      state.network_active_customers.loading = false;
      state.network_active_customers.activeCustomers = payload.data;
      state.network_active_customers.page_number = payload.current_page;
      state.network_active_customers.total_pages = payload.total_pages;
    },
    getBrokerActiveCustomersNetworkRejected: (state, { payload }) => {
      state.network_active_customers.loading = false;
      state.network_active_customers.error = payload;
    },
    getBrokerActiveCustomerInformationPending: (state) => {
      state.active_customer.loading = true;
      state.active_customer.error = null;
    },
    getBrokerActiveCustomerInformationSuccess: (state, { payload }) => {
      state.active_customer.loading = false;
      state.active_customer.activeCustomer = payload.data.data.customer_information;
      state.active_customer.activeQuotes = payload.data.data.active_quotes;
      state.active_customer.insurance_requests = payload.data.data.insurance_requests;
      state.active_customer.gmails = payload.data.data.gmails;
      state.active_customer.contacts = payload.data.data.contacts;
      state.active_customer.after_sales = payload.data.data.after_sales;
    },
    getBrokerActiveCustomerInformationRejected: (state, { payload }) => {
      state.active_customer.loading = false;
      state.active_customer.error = payload;
    },
    readActiveCustomerEmailPending: (state) => {
      state.read_mail.loading = true;
      state.read_mail.error = null;
    },
    readActiveCustomerEmailSuccess: (state, { payload }) => {
      state.read_mail.loading = false;
      state.read_mail.email = payload.data.data;
    },
    readActiveCustomerEmailRejected: (state, { payload }) => {
      state.read_mail.loading = false;
      state.read_mail.error = payload;
    },
    activeCustomersReset: (state) => {
      state.active_customers = { ...initialState.active_customers };
      state.active_customer = { ...initialState.active_customer };
      state.read_mail = { ...initialState.read_mail };
    },
  },
});

export default callerCreator(activeCustomersSlice.actions);
