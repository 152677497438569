import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { SupportModalStyles } from './SupportModal.styles';
import InputTextarea from '../inputs/InputTextarea';
import InputSelect from '../inputs/InputSelect';
import { generalAlertSuccessToast } from '../../utils/alerts';
import managementAxe from '../../../services/axios-client/managementServisAxeClient';

function SupportModal({
  setSupportMessage,
  supportMessage,
  collectData,
  quotations, requestId,
}) {
  const [isSending, setIsSending] = useState(false);
  const sendInquiry = async () => {
    setIsSending(true);
    const res = await managementAxe.post('/broker/messages/create-thread-broker', { ...supportMessage, location: 'threads_page' });
    setSupportMessage({
      subject: '',
      request_id: '',
      body: '',
      recipient: '',
    });
    setIsSending(false);
    if (res.status === 200) {
      document
        .getElementById('close-modal-button')
        .click();

      generalAlertSuccessToast('La chat e stata creata');
      collectData();
    }
  };

  const schema = yup.object({
    request_id: yup
      .string()
      .required(
        "Il campo è obbligatorio. Inserisci il quotazione dell'azienda!",
      ),
    body: yup
      .string()
      .required(
        "Il campo è obbligatorio. Inserisci il messaggio dell'azienda!",
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    setValue,
    trigger,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: {
      request_id: '',
      body: '',
    },
  });
  supportMessage = watch();

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  const updateSupportMessageData = (data) => {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      setValue(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    });
    trigger(keys);
  };
  useEffect(() => {
    if (requestId !== undefined) {
      updateSupportMessageData({
        request_id: requestId,
      });
    }
  }, [requestId]);

  const formattedQuotations = quotations?.map((quotation) => {
    let option_name = quotation?.insurance_request?.customer?.business_name
      ? quotation?.insurance_request?.customer?.business_name
      : `${quotation?.insurance_request?.customer?.name} ${quotation?.insurance_request?.customer?.surname}`;

    option_name += ` ${quotation?.insurance_request?.product?.name}`;
    if (quotation.insurance_request.vehicle) option_name += ` ${quotation.insurance_request?.vehicle?.vehicle_plate}`;
    if (quotation?.insurance_request?.professional) option_name += ` ${quotation.insurance_request?.professional?.profession_desc}`;
    if (quotation.insurance_request?.infortuni) option_name += ` ${quotation.insurance_request?.infortuni?.pack_type} ${quotation?.insurance_request?.infortuni?.name}`;
    return {
      id: quotation?.request_id,
      name: option_name,
    };
  });
  return (
    <SupportModalStyles>
      <div
        className="modal fade"
        id="support-message-box"
        data-bs-backdrop="static"
        tabIndex="-1"
        aria-labelledby="support-message-boxLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="support-message-boxLabel">
                Contatto Supporto
              </h1>
              <button
                id="close-modal-button"
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              {isSending === false ? (
                <form onSubmit={handleSubmit(sendInquiry)}>

                  {quotations && (
                    <InputSelect
                      placeholder="Seleziona quotazione"
                      colClass="col-xl-12 col-md-12 ms-0"
                      label="Seleziona Quotazione"
                      name="request_id"
                      register={register}
                      error={errors.request_id?.message}
                      valid={isValid('request_id')}
                      id="request_id"
                      options={formattedQuotations}
                    />
                  )}

                  <InputTextarea
                    placeholder="Invia una messaggio..."
                    name="body"
                    label="Scrivi il Messaggio"
                    register={register('body')}
                    type="textarea"
                    rows="3"
                    id="body"
                    colClass="w-100"
                    error={errors.body?.message}
                    valid={isValid('body')}
                  />

                  <button
                    type="submit"
                    className="btn btn-outline-primary px-4"
                  >
                    Contatta il back office
                  </button>
                </form>
              ) : (
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <div className="spinner-border" role="status" />
                  <span>Inviando messaggio...</span>
                </div>
              )}
              {/* {message && (
                <div
                  className={`alert alert-${message.statusCode >= 400 ? "danger" : "success"
                    } alert-dismissible fade show mt-2`}
                  role="alert"
                >
                  {message.message}
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                  ></button>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </SupportModalStyles>
  );
}

export default SupportModal;
