import React from 'react';
import { FormInputText } from '../../../common/formInputs/FormInputText';

function CompanyBranches({ commertial_report, errors, isValid }) {
  return (
    <div className="tab-pane fade" id="commercial_company_branches">
      <h4 className="text-center mt-3">Commercial Company Branches</h4>

      <div className="row mt-5">
        {commertial_report.commercial_company_branches.map((branch, index) => (
          <div className="col-12 col-md-6 mb-4" key={index}>
            <div className="card border">
              <div className="card-body">
                <FormInputText
                  placeholder="Address"
                  label="Address"
                  value={branch.address}
                  error={errors.address?.message}
                  valid={isValid('address')}
                />
                <FormInputText
                  placeholder="Description"
                  label="Description"
                  name="description"
                  value={branch.description}
                  error={errors.description?.message}
                  valid={isValid('description')}
                />

                <p className="fw-bold text-center">Branch Types</p>
                {branch.branch_types.map((type, index) => (
                  <div key={index}>
                    <FormInputText
                      placeholder="Name"
                      label="Name"
                      value={type.name}
                      error={errors.name?.message}
                      valid={isValid('name')}
                    />
                  </div>
                ))}

              </div>
            </div>
          </div>
        ))}

      </div>
    </div>
  );
}

export default CompanyBranches;
