import React, { useState } from 'react';
import { ProfessionCardStyles } from './ProfessionCard.styles';
import {
  AquistaButton,
  AquistaButtonsContainer,
  LeftCol, LogoContainer, LogoPriceContainer, Price, PriceContainer,
  QuoteInformationContainer,
  QuoteInformationItem,
  RightCol,
} from './AdriaticCard.styles';
import { BACKEND_BASE_URL } from '../../../../config';
import { SavedButton } from './common/Buttons.styles';
import { updateGuaranteesAutoAction, updateGuaranteesMotoAction } from '../../../../features/insuranceRequests/insuranceRequestsActions';
import { confirmAlert } from '../../../utils/alerts';

function RiskCardPrima({
  quote, type, requestInfo, processingQuote, closeButton,
}) {
  const [modalCoverages, setModalCoverages] = useState([]);

  const addGuarantees = (coverages) => {
    if (processingQuote.product_id === 2) {
      updateGuaranteesMotoAction(requestInfo.request_token, coverages, processingQuote.id);
    } else {
      updateGuaranteesAutoAction(requestInfo.request_token, coverages, processingQuote.id);
    }
    closeButton();
  };

  const updateCoverages = (coverageSlug) => {
    const cov = processingQuote.guarantees.find((c) => c.guarantees_slug === coverageSlug);
    const coverages = [];
    if (cov === undefined) {
      processingQuote.guarantees.map((c) => coverages.push(c.guarantees_slug));
      coverages.push(coverageSlug);
    } else {
      setModalCoverages(modalCoverages.filter((c) => c !== coverageSlug));
      processingQuote.guarantees.filter((cv) => cv.guarantees_slug !== coverageSlug).map((c) => coverages.push(c.guarantees_slug));
    }

    if (coverageSlug === 'cristalli') {
      if (processingQuote.guarantees.find((cv) => cv.guarantees_slug === 'furto_incendio') === undefined) {
        coverages.push('furto_incendio');
      }
    }

    addGuarantees(coverages);
  };

  const addNewGuarantee = (coverageSlug) => {
    confirmAlert('Sei Sicuro Di Aggiungere Questo Rischio?', () => updateCoverages(coverageSlug));
  };

  // const includesCoverage = (key) => modalCoverages !== null && modalCoverages.includes(key);

  return (
    <ProfessionCardStyles className={quote.is_aquista === 1 ? 'selected' : ''}>
      <div className="row-container">
        <LeftCol>
          <LogoPriceContainer>
            <LogoContainer>
              <img src={BACKEND_BASE_URL + processingQuote.company.logo} alt={processingQuote.company.name} />
            </LogoContainer>
            <PriceContainer>
              <Price>
                <h5>
                  Prezzo annuo:
                </h5>
                <h2>
                  &euro;
                  {' '}
                  {parseFloat(quote.quotation_data_prima_guarantee_prices[0].discounted_to_display)}
                </h2>
              </Price>
            </PriceContainer>
          </LogoPriceContainer>
          <QuoteInformationContainer>
            <QuoteInformationItem>
              <h6>
                Compagnia:
              </h6>
              <p>
                {processingQuote.company.name}
              </p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>
                Prodotto:
              </h6>
              <p>
                {quote.name}
              </p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>
                Limit:
              </h6>
              <p>
                {quote.limits_name}
              </p>
            </QuoteInformationItem>
          </QuoteInformationContainer>
        </LeftCol>
        <RightCol>
          <AquistaButtonsContainer>
            {type.id === 'assistenza_stradale'
              && (
                <>
                  {requestInfo.assistenza_stradale_quote_id !== quote.id ? (
                    <AquistaButton
                      onClick={() => addNewGuarantee(quote.slug)}
                      className="result-profession-sixth-step"
                    >
                      Seleziona
                    </AquistaButton>
                  ) : requestInfo.assistenza_stradale_quote_id === quote.id && <SavedButton>Aggiunto</SavedButton>}
                </>
              )}
            {type.id === 'cristalli'
              && (
                <>
                  {requestInfo.cristalli_quote_id !== quote.id ? (
                    <AquistaButton
                      onClick={() => addNewGuarantee(quote.slug)}
                      className="result-profession-sixth-step"
                    >
                      Seleziona
                    </AquistaButton>
                  ) : requestInfo.cristalli_quote_id === quote.id && <SavedButton>Aggiunto</SavedButton>}
                </>
              )}
            {type.id === 'infortuni_conducente'
              && (
                <>
                  {requestInfo.infortuni_conducente_quote_id !== quote.id ? (
                    <AquistaButton
                      onClick={() => addNewGuarantee(quote.slug)}
                      className="result-profession-sixth-step"
                    >
                      Seleziona
                    </AquistaButton>
                  ) : requestInfo.infortuni_conducente_quote_id === quote.id && <SavedButton>Aggiunto</SavedButton>}
                </>
              )}
            {type.id === 'protezione_rivalse'
              && (
                <>
                  {requestInfo.rivalsa_quote_id !== quote.id ? (
                    <AquistaButton
                      onClick={() => addNewGuarantee('protezione_rivalse')}
                      className="result-profession-sixth-step"
                    >
                      Seleziona
                    </AquistaButton>
                  ) : requestInfo.rivalsa_quote_id === quote.id && <SavedButton>Aggiunto</SavedButton>}
                </>
              )}
            {type.id === 'tutela_legale'
              && (
                <>
                  {requestInfo.tutela_legale_quote_id !== quote.id ? (
                    <AquistaButton
                      onClick={() => addNewGuarantee(quote.slug)}
                      className="result-profession-sixth-step"
                    >
                      Seleziona
                    </AquistaButton>
                  ) : requestInfo.tutela_legale_quote_id === quote.id && <SavedButton>Aggiunto</SavedButton>}
                </>
              )}
            {type.id === 'kasko'
              && (
                <>
                  {requestInfo.tutela_legale_quote_id !== quote.id ? (
                    <AquistaButton
                      onClick={() => addNewGuarantee(quote.slug)}
                      className="result-profession-sixth-step"
                    >
                      Seleziona
                    </AquistaButton>
                  ) : requestInfo.tutela_legale_quote_id === quote.id && <SavedButton>Aggiunto</SavedButton>}
                </>
              )}
            {type.id === 'collisione'
              && (
                <>
                  {requestInfo.tutela_legale_quote_id !== quote.id ? (
                    <AquistaButton
                      onClick={() => addNewGuarantee(quote.slug)}
                      className="result-profession-sixth-step"
                    >
                      Seleziona
                    </AquistaButton>
                  ) : requestInfo.tutela_legale_quote_id === quote.id && <SavedButton>Aggiunto</SavedButton>}
                </>
              )}
            {type.id === 'eventi_sociopolitici'
              && (
                <>
                  {requestInfo.tutela_legale_quote_id !== quote.id ? (
                    <AquistaButton
                      onClick={() => addNewGuarantee(quote.slug)}
                      className="result-profession-sixth-step"
                    >
                      Seleziona
                    </AquistaButton>
                  ) : requestInfo.tutela_legale_quote_id === quote.id && <SavedButton>Aggiunto</SavedButton>}
                </>
              )}
            {type.id === 'eventi_naturali'
              && (
                <>
                  {requestInfo.tutela_legale_quote_id !== quote.id ? (
                    <AquistaButton
                      onClick={() => addNewGuarantee(quote.slug)}
                      className="result-profession-sixth-step"
                    >
                      Seleziona
                    </AquistaButton>
                  ) : requestInfo.tutela_legale_quote_id === quote.id && <SavedButton>Aggiunto</SavedButton>}
                </>
              )}
            {type.id === 'bonus_protetto'
              && (
                <>
                  {requestInfo.tutela_legale_quote_id !== quote.id ? (
                    <AquistaButton
                      onClick={() => addNewGuarantee(quote.slug)}
                      className="result-profession-sixth-step"
                    >
                      Seleziona
                    </AquistaButton>
                  ) : requestInfo.tutela_legale_quote_id === quote.id && <SavedButton>Aggiunto</SavedButton>}
                </>
              )}
            {type.id === 'furto_incendio'
              && (
                <>
                  {requestInfo.tutela_legale_quote_id !== quote.id ? (
                    <AquistaButton
                      onClick={() => addNewGuarantee(quote.slug)}
                      className="result-profession-sixth-step"
                    >
                      Seleziona
                    </AquistaButton>
                  ) : requestInfo.tutela_legale_quote_id === quote.id && <SavedButton>Aggiunto</SavedButton>}
                </>
              )}
          </AquistaButtonsContainer>
        </RightCol>
      </div>
    </ProfessionCardStyles>
  );
}

export default RiskCardPrima;
