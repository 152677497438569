import { createSlice } from '@reduxjs/toolkit';

import { callerCreator } from '../callerCreator';

const initialState = {
  customer_page: {
    loading: true,
    error: null,
    customer: null,
    quotazioni: [],
    emissioni: [],
  },
  customer: {
    loading: true,
    error: null,
    customer: null,
    insurance_requests: [],
    contacts: [],
  },
};

export const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    getCustomerInformationRejected: (state, { payload }) => {
      state.customer_page.loading = false;
      state.customer_page.error = payload;
    },
    getCustomerInformationSuccess: (
      state,
      { payload: { customer, quotazioni, emissioni } },
    ) => {
      state.customer_page.loading = false;
      state.customer_page.customer = customer;
      state.customer_page.quotazioni = quotazioni;
      state.customer_page.emissioni = emissioni;
    },
    customerPageReset: (state) => {
      state.customer_page = { ...initialState.customer_page };
    },
    getCustomerRejected: (state, { payload }) => {
      state.customer.loading = false;
      state.customer.error = payload;
    },
    getCustomerSuccess: (
      state,
      { payload: { customer_information, insurance_requests, contacts } },
    ) => {
      state.customer.loading = false;
      state.customer.customer = customer_information;
      state.customer.insurance_requests = insurance_requests;
      state.customer.contacts = contacts;
    },
    customerReset: (state) => {
      state.customer = { ...initialState.customer };
    },
  },
});

export default callerCreator(customersSlice.actions);
