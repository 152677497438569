import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  AddButton, BackButton, GuaranteesLeftSide, GuaranteesModalBody, GuaranteesModalContent, GuaranteesModalHeader, GuaranteesRightSide, GuaranteesTextContainer, GuaranteesTitleBox, GuarnateesModalProfessionStyles, InputCircle, InputContainer, InputText,
} from './GuaranteesModalProfession.styles';
import { professionAddExtension, professionQuotesGetQuotes } from '../../../../features/insuranceRequests/insuranceRequestsActions';

const professionaleExtensionsMap = {
  tlb: 'Tutela Legale Bronze',
  tls: 'Tutela Legale Silver',
  tlg: 'Tutela Legale Gold',
};

function GuaranteesModalProfession({
  setOpenModal, extensions, requestToken, selectableExtensions,
}) {
  const [selectedExtensions, setSelectedExtensions] = useState([]);

  const closeGuaranteesModal = () => {
    setOpenModal(false);
  };

  const addGuarantees = () => {
    professionAddExtension(requestToken, selectedExtensions, () => {
      professionQuotesGetQuotes(requestToken);
    });
  };

  const addMoreExtensions = (item) => {
    // ? Check if extension exists remove it
    if (selectedExtensions.find((ex) => ex === item) !== undefined) {
      setSelectedExtensions(selectedExtensions.filter((ext) => ext !== item));
    }
    // ? Check if extension does not exist
    if (selectedExtensions.find((ex) => ex === item) === undefined) {
      const newArr = [];
      // ? Check if another allowed extension is selected remove it
      selectedExtensions.forEach((extension) => {
        if (selectableExtensions.indexOf(extension) < 0) {
          newArr.push(extension);
        }
      });
      // ? Add selected Extension
      setSelectedExtensions([...newArr, item]);
    }
  };

  useEffect(() => {
    const newArr = [];
    extensions.forEach((ex) => {
      newArr.push(ex);
    });
    setSelectedExtensions(newArr);
  }, []);

  return (
    <GuarnateesModalProfessionStyles>
      <GuaranteesModalBody>
        <GuaranteesModalHeader>
          <GuaranteesLeftSide>
            <FontAwesomeIcon fontSize={34} icon={faTimes} cursor="pointer" onClick={() => closeGuaranteesModal()} />
            <GuaranteesTextContainer>
              <GuaranteesTitleBox>
                <h3>Aggiungi Garanzie</h3>
              </GuaranteesTitleBox>
              <p>
                Seleziona le garanzie che desideri aggiungere al tuo pacchetto assicurativo principale.
              </p>
            </GuaranteesTextContainer>
          </GuaranteesLeftSide>
          <GuaranteesRightSide>
            <BackButton onClick={() => closeGuaranteesModal()}>
              Indietro
            </BackButton>
            <AddButton onClick={() => {
              addGuarantees();
              closeGuaranteesModal();
            }}
            >
              Aggiungi
            </AddButton>
          </GuaranteesRightSide>
        </GuaranteesModalHeader>
        <GuaranteesModalContent>
          <div className="row">
            <div className="col-12 col-md-12">
              {['tlb', 'tls', 'tlg'].map((extension) => (
                <InputContainer key={extension} onClick={() => addMoreExtensions(extension)}>
                  <div>
                    <InputCircle className={selectedExtensions.find((ext) => ext === extension) !== undefined ? 'selected' : ''} />
                  </div>
                  <InputText>
                    {professionaleExtensionsMap[extension]}
                  </InputText>
                </InputContainer>
              ))}
            </div>
          </div>
        </GuaranteesModalContent>
      </GuaranteesModalBody>
    </GuarnateesModalProfessionStyles>
  );
}

export default GuaranteesModalProfession;
