import styled from 'styled-components';

export const UppyContainer = styled.div`
  .uppy-Dashboard-inner {
    height: 200px !important;
    background-color: #fff;
    border: 2px dashed rgba(130, 130, 130, 0.3);
    border-radius: 15px;
  }

  .uppy-Dashboard-AddFiles-info {
    display: block;
    margin-top: 30px !important;
    padding-top: 0 !important;
  }

  .uppy-Dashboard-AddFiles {
    border: none;
  }

  .uppy-Dashboard-note {
    color: #99dbb9;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .uppy-Dashboard-AddFiles-list {
    display: none;
  }

  .uppy-Dashboard-AddFiles-title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 12px;
    margin-bottom: 50px;
  }

  .uppy-Dashboard-AddFiles-title::before {
    content: url(${(props) => props.icon});
    transform: scale(0.7);
  }

  .uppy-Dashboard-browse {
    font-size: 14px;
  }

  @media (max-width: 1200px) {
    .uppy-Dashboard-AddFiles-info {
      margin-top: 0px !important;
    }
  }
`;
