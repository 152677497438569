import styled from 'styled-components';

export const ProfileStyles = styled.div`
    .nav-tabs .nav-item .nav-link {
        color: #00A651;
    }

    .nav-tabs .nav-link.active {
        background-color: #00A651;
        color: #ffffff;
        border-bottom: 2px solid #00A651;
    }

    .green-col {
        background-color: #00A651;
        color: #ffffff;
        border: 1px solid #00A651;
    }

    .green-col:hover {
        background-color: transparent;
        color: #00A651;
        border: 1px solid #00A651;
    }

    .form-control:focus.profile {
        color: #212529;
        background-color: #fff;
        border-color: rgba(0,166,81, 0.15);
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(0,166,81, 0.25);
    }

    .border-custom {
        border: 1px solid #dedede;
    }
`;
