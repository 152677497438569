import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TicketPageStyles } from './TicketPage.styles';
import timeIcon from '../assets/images/times.png';
import PageLoading from '../common/components/elements/PageLoading';
import { getTicket } from '../features/tickets/ticketsActions';
import managementAxe from '../services/axios-client/managementServisAxeClient';
import ErrorComponent from '../common/components/ErrorComponent';

function TicketPage() {
  const { ticketId } = useParams();

  const bottomRef = useRef(null);

  const {
    ticket_single: {
      loading, ticket, messages, error,
    },
  } = useSelector((store) => store.tickets);

  const [message, setMessage] = useState('');

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const sendMessage = async (e) => {
    e.preventDefault();

    const res = await managementAxe.post(
      `/broker/tickets/${ticketId}/ticket-messages`,
      {
        message,
      },
    );

    if (res.status === 200) {
      setMessage('');
      getTicket(ticketId);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    getTicket(ticketId);
  }, []);

  if (loading) {
    return <PageLoading />;
  }

  if (error !== null) {
    return <ErrorComponent error={error} />;
  }

  return (
    <TicketPageStyles>
      <div className="row border col-md-8 mx-auto">
        <div className="col-12 bg-greenia-green text-white border py-2 px-4 mb-0">
          <h2 className="h4 text-center mb-0">
            <span className="fw-normal">Oggetto: </span>
            {ticket.subject}
          </h2>
        </div>
        <div className="col-12 border mb-0">
          <div className="message-container">
            {messages
              ? messages.map((message, index) => (
                <div key={index} className="message">
                  <div className="sender-title d-flex align-items-center justify-content-between my-2 px-2">
                    <h6 className="h6 mb-0">
                      <span className="fw-normal">Inviato da:</span>
                      {' '}
                      {message.user !== null
                        ? message.user.name
                        : `${message.broker_agent.name
                        } ${message.broker_agent.surname}`}
                    </h6>
                    <p className="fw-normal realtive mb-0 d-flex align-items-center justify-content-center">
                      <img
                        src={timeIcon}
                        className="img-fluid me-1"
                        width={14}
                        alt=""
                      />
                      <small className="text-muted">
                        {new Date(message.created_at).toLocaleString('it-IT')}
                      </small>
                    </p>
                  </div>
                  <div
                    className="message-box"
                    style={{
                      marginRight: message.user !== null ? '70px' : '0',
                      marginLeft: message.user !== null ? '0' : '70px',
                      backgroundColor:
                        message.user_id === null
                          ? 'lightgrey'
                          : 'rgb(120,200,120)',
                    }}
                  >
                    <p className="mb-0">{message.message}</p>
                  </div>
                  <hr />
                </div>
              ))
              : ''}
            <div ref={bottomRef} />
          </div>
          <div className="reply-box">
            <form onSubmit={sendMessage}>
              <textarea
                className="form-control mb-2 reply-input"
                name="message"
                id="message"
                cols="20"
                rows="3"
                placeholder="Invia una risposta..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <button className="btn mb-2 send-message-btn">Invia</button>
            </form>
          </div>
        </div>
      </div>
    </TicketPageStyles>
  );
}

export default TicketPage;
