import styled from 'styled-components';

export const ChangePasswordForm = styled.form`
    margin: 50px auto;
    padding: 20px;
    width: calc(100% - 40px);
    max-width: 600px;
    border: 1px solid var(--greenia-color);
    border-radius: 20px;

    .submit-button {
        margin-top: 10px;
        width: 100%;
        padding: 10px 20px;
        outline: none;
        border: 2px solid var(--greenia-color);
        background-color: var(--greenia-color);
        color: white;
        font-weight: bold;
        border-radius: 10px;
        transition: .3s;

        &:hover {
            background-color: transparent;
            color: var(--greenia-color);
        }
    }
`;
