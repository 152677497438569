import styled from 'styled-components';

export const GeneralChatStyled = styled.div`
    .icon-container {
        position: fixed;
        bottom: 100px;
        right: 15px;
        height: 80px;
        width: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(255,255,255,.5);
        border-radius: 100%;
        cursor: pointer;
        z-index: 500;
        transition: .3s;

        .icon-image {
            width: 70px;
            object-fit: contain;
            transition: .3s;
        }

        &:hover {
            .icon-image {
                width: 80px;
            }
        }

        

        .icon {
            color: white;
        }

        .icon-text {
            color: white;
        }
    }

    .chat-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3000;
        display: flex;
        justify-content: center;
        align-items: center;

        .backdrop {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,.5);
        }

        .chat-container {
            position: relative;
            width: 100%;
            max-width: 500px;
            height: 90%;
            max-height: 600px;
            background-color: white;
            border: 1px solid var(--greenia-color);
            border-radius: 10px;
            z-index: 3500;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow-x: hidden;

            .chat-background-image {
                position: absolute;
                top: 50px;
                height: calc(100% - 100px);
                transform: translate(-50%, 0);
                opacity: .6;
            }

            .chat-title {
                padding: 10px;
                color: white;
                font-size: 16px;
                text-align: center;
                font-weight: bold;
                background-color: var(--greenia-color);
                border-bottom: 1px solid white;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .chat-title-reset {
                    cursor: pointer;
                }
            }

            .chat-content {
                overflow-y: auto;
                padding: 10px;
                z-index: 10;
                height: 100%;

                .chat-message-container {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: start;
                }

                .chat-robot-avatar {
                    margin-top: 5px;
                    width: 50px;
                    object-fit: contain;
                }

                .chat-message {
                    width: calc(100% - 55px);
                    min-height: 40px;
                    margin: 5px;
                    padding: 8px;
                    border: 1px solid;
                    border-radius: 10px;

                    .chat-message-time {
                        text-align: right;
                        font-size: 13px;
                    }

                    .chat-message-message {
                        font-size: 14px;
                    }
                }

                .chat-message.chat-message-system {
                    margin-right: 50px;
                    background-color: var(--greenia-color);
                    color: white;
                }

                .chat-message.chat-message-user {
                    margin-left: 50px;
                    border-color: var(--greenia-color);
                    background-color: white;
                }

                .chat-message.chat-message-option {
                    width: calc(100% - 60px);
                    margin-left: 50px;
                    background-color: rgba(80, 200, 120,.9);
                    border-color: var(--greenia-color);
                    cursor: pointer;
                    transition: .3s;

                    &:hover {
                        background-color: rgba(80, 200, 120,.8);
                    }
                }

                .chat-message.message-loading {
                    background-color: lightgrey;
                    border-color: grey;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    
                    .loading-spinner {
                        text-align: right;
                        font-size: 10px;
                    }
                }

                .chat-options-container {
                    max-height: 350px;
                    overflow-y: auto;
                }

                .chat-options-helper {
                    text-align: center;
                    color: var(--greenia-color);
                }
            }

            .chat-message-sender {
                padding: 10px;
                display: flex;
                align-items: center;
                border-top: 1px solid white;
                background-color: var(--greenia-color);
                z-index: 10;

                textarea {
                    width: 100%;
                    min-height: 40px;
                    height: 40px;
                    max-height: 100px;
                    border: 1px solid white;
                    border-radius: 5px;
                    outline: none;
                    padding: 7px;
                    background-color: rgba(255,255,255,.7);
                }

                .chat-message-send-button {
                    margin-left: 10px;
                    width: 50px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: var(--greenia-color);
                    border: 1px solid white;
                    color: white;
                    border-radius: 5px;
                    cursor: pointer;
                }
            }
        }
    }

    .bottom-ref {
        height: 1px;
        width: 100%;
    }

    @keyframes cursor-blink {
        0% {
            opacity: 0;
        }
    }

    .blinking-cursor {
        width: 14px;
        height: 14px;
        border-radius: 100%;
        background-color: white;
        animation: cursor-blink 1s steps(2) infinite;
    }
`;
