import React, { useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { LatestUpdatesPageStyles } from './LatestUpdatesPage.styles';
import PageLoading from '../../common/components/elements/PageLoading';
import {
  getLatestUpdatesByPage,
  resetLatestUpdates,
} from '../../features/dashboard/dashboardActions';

function LatestUpdatesPage() {
  const navigate = useNavigate();

  const state = useSelector((store) => store.dashboard.latest_updates);

  const {
    loading, error, latestUpdates, pages, page,
  } = state;

  const loadLatestUpdates = async (newPage) => {
    getLatestUpdatesByPage(newPage);
  };

  const handlePageClick = ({ selected }) => {
    if (selected + 1 === page) return;

    loadLatestUpdates(selected + 1);
  };

  useEffect(() => {
    loadLatestUpdates(1);

    return () => {
      resetLatestUpdates();
    };
  }, []);

  const sendToInsuranceRequestQuotationsList = (lu) => {
    if (lu.product_id === '1' || lu.product_id === '3') {
      navigate(`/quotes/${lu.request_token}`);
    } else if (lu.product_id === '2') {
      navigate(`/quotes/moto/${lu.request_token}`);
    } else if (lu.product_id === '4') {
      navigate(`/quotes/profession/${lu.request_token}`);
    } else if (lu.product_id === '5') {
      navigate(`/quotes/metlife/${lu.request_token}`);
    } else if (lu.product_id === '6') {
      navigate(`/quotes/infodrive/${lu.request_token}`);
    }
  };

  if (loading) {
    return <PageLoading />;
  }

  return (
    <LatestUpdatesPageStyles>
      <h1 className="screen-title">Ultimi aggiornamenti</h1>
      {error !== null && <div className="alert-general-error">{error}</div>}
      <div className="custom-row">
        <div className="custom-card">
          <div className="custom-card-body custom-card-body--body-only">
            <table className="custom-table">
              <thead>
                <tr>
                  <td>Ora</td>
                  <td>Nome cliente</td>
                  <td>Prodotto</td>
                  <td>Stato del contratto</td>
                  <td>Azioni</td>
                </tr>
              </thead>
              <tbody>
                {latestUpdates.length === 0 ? (
                  <div className="custom-table-empty">Nessun dato</div>
                ) : (
                  latestUpdates.map((lu) => (
                    <tr className="custom-table-row" key={lu.id}>
                      <td className="latest-updates-time">
                        {moment(lu.created_at).fromNow(true)}
                      </td>
                      <td className="latest-updates-contract">
                        {lu.customer.business_name !== ''
                          && lu.customer.business_name !== null && (
                            <>{lu.customer.business_name}</>
                        )}
                        {lu.customer.business_name === ''
                          || (lu.customer.business_name === null && (
                            <>
                              {lu.customer?.name}
                              {' '}
                              {lu.customer?.surname}
                            </>
                          ))}
                      </td>
                      <td>{lu.product.name}</td>
                      <td>{lu.status.label}</td>
                      <td className="latest-updates-actions">
                        <div
                          className="action-button"
                          onClick={() => sendToInsuranceRequestQuotationsList(lu)}
                        >
                          <FontAwesomeIcon icon={faChevronRight} />
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="custom-row">
        <ReactPaginate
          breakLabel="..."
          nextLabel="Next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pages}
          initialPage={page - 1}
          previousLabel="< Prev"
          renderOnZeroPageCount={null}
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          activeClassName="active"
        />
      </div>
    </LatestUpdatesPageStyles>
  );
}

export default LatestUpdatesPage;
