import styled from 'styled-components';

export const CollaboratorsAnalisysStyles = styled.section`
  .search-btn {
    display: table;
    margin: 0 auto;
    width: 50%;
    border: 2px solid #00a651;
    background-color: #00a651;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    padding: 6px 16px;
  }
`;
