import { createSlice } from '@reduxjs/toolkit';

import { callerCreator } from '../callerCreator';

const initialState = {
  platformChat: {
    loading: false,
    thread: null,
    ticket: null,
    location: null,
    request_token: null,
  },
};

export const platformChatSlice = createSlice({
  name: 'platformChat',
  initialState,
  reducers: {
    getPlatformThreadPending: (state) => {
      state.platformChat.loading = true;
    },
    getPlatformThreadRejected: (state) => {
      state.platformChat.loading = false;
    },
    getPlatformThreadSuccess: (state, { payload }) => {
      state.platformChat.loading = false;
      state.platformChat.thread = payload.thread;
    },
    getPlatformTicketPending: (state) => {
      state.platformChat.loading = true;
    },
    getPlatformTicketRejected: (state) => {
      state.platformChat.loading = false;
    },
    getPlatformTicketSuccess: (state, { payload }) => {
      state.platformChat.loading = false;
      state.platformChat.ticket = payload.ticket;
    },
    updateLocationAndToken: (state, { payload }) => {
      state.platformChat.location = payload.location;
      state.platformChat.request_token = payload.request_token;
    },
    updatePlatformChatState: (state) => {
      state.platformChat.thread = null;
      state.platformChat.ticket = null;
    },
  },
});

export default callerCreator(platformChatSlice.actions);
