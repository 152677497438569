import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  LeftSide, ModalBody, ModalContent, ModalHeader, PrimaInfoModalStyles, RightSide, TextContainer, TitleBox,
} from '../Cards/PrimaInfoModal.styles';
import infoIcon from '../../../../assets/redesign_icons/info-icon.svg';

function PrimaInfoModal({
  totalPriceWithGuarantees,
  calculatedPrices,
  setShowModal,
}) {
  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <PrimaInfoModalStyles>
      <ModalBody>
        <ModalHeader>
          <LeftSide>
            <FontAwesomeIcon fontSize={34} icon={faTimes} cursor="pointer" onClick={() => closeModal()} />
            <TextContainer>
              <TitleBox>
                <img src={infoIcon} alt="" />
                <h3>Informazione</h3>
              </TitleBox>
              <p>
                Qui puoi controllare le informazioni su questa offerta.
              </p>
            </TextContainer>
          </LeftSide>
          <RightSide />
        </ModalHeader>
        <ModalContent>
          <h3>Garanzie</h3>
          <table className="custom-table-compact">
            <thead>
              <tr>
                <th>Garanzia</th>
                <th>Massimale</th>
                <th style={{ textAlign: 'right' }}>Prezzo</th>
              </tr>
            </thead>
            <tbody>
              {calculatedPrices.map((guarantee, index) => (
                <tr key={index} className="custom-table-row-compact">
                  <td>{guarantee.name}</td>
                  <td>{guarantee.limits_name}</td>
                  <td style={{ textAlign: 'right' }}>
                    {Number(
                      guarantee.discounted_to_display,
                    ).toLocaleString('it-IT', {
                      minimumFractionDigits: 2,
                    })}
                    €
                  </td>
                </tr>
              ))}
              <tr className="custom-table-row-compact">
                <td>
                  <b>Totale</b>
                </td>
                <td />
                <td style={{ textAlign: 'right' }}>
                  <b>
                    {Number(totalPriceWithGuarantees).toLocaleString(
                      'it-IT',
                      { minimumFractionDigits: 2 },
                    )}
                    €
                  </b>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="card-extra-information">
            <h3>Informazioni</h3>
            <p>
              <b>Cosa è assicurato:</b>
              {' '}
              Danni causati a Terzi dalla
              circolazione del veicolo assicurato in aree pubbliche Danni
              causati a Terzi dalla circolazione del veicolo assicurato in
              aree private, purché aperte alla pubblica circolazione
              Responsabilità Civile dei trasportati per i danni da questi
              involontariamente causati durante la circolazione Danni
              causati a cose e/o persone in seguito alla circolazione del
              veicolo Danni involontariamente cagionati ai terzi
              nell'esecuzione di salita e discesa di persone disabili
              Danni involontariamente cagionati ai terzi nell'esecuzione
              delle operazioni di carico e scarico da terra al veicolo e
              viceversa Danni causati a terzi dal traino di eventuali
              rimorchi, purché effettuato a norma.
            </p>
            <br />
            <p>
              <b>Cosa non è assicurato:</b>
              {' '}
              Conducente del veicolo
              responsabile del sinstro Veicolo assicurato In caso di
              sinistro con responsabilità, limitatamente alle cose, sono
              esclusi i danni arrecati: Al proprietario del veicolo,
              l'usufruttuario, l'acquirente con patto di riservato dominio
              e il locatario nel caso di veicolo concesso in leasing Al
              coniuge o al convivente more uxorio All'ascendente e ai
              discendenti, parenti o affini entro il terzo grado, se
              conviventi ed a carico del conducente A soci a
              responsabilità illimitata e le persone che si trovano con
              questi in rapporti di parentela, se l'assicurato sia una
              società.
            </p>
            <br />
            <p>
              <b>Limitazioni di copertura:</b>
              {' '}
              La compagnia ha diritto a
              recuperare dall'assicurato le somme pagate ai terzi
              danneggiati (rivalsa) principalmente nei casi seguenti:
              CCircolazione avvenuta contro la volontà del Proprietario
              dell'usufruttuario, dell'acquirente con patto di riservato
              dominio o del locatario in caso di locazione finanziaria
              Conducente non abilitato alla guida Danni ai terzi
              trasportati se il trasporto non è effettuato a norma Veicolo
              guidato da persona in stato di ebbrezza o sotto l'influenza
              di sostanze stupefacenti Veicolo utilizzato come scuola
              guida, se al fianco del conducente non vi sia una persona
              abilitata, a svolgere le funzioni di istruttore Conducente
              in possesso di patente idonea ma scaduta Veicolo con targa
              prova Veicolo dato a noleggio con conducente Partecipazione
              del veicolo a gare o competizioni sportive Veicolo con
              revisione scaduta Danni causati da figli minori Conducente
              non previsto dalla tipologia di guida riportata in polizza
              Dolo del conducente
            </p>
          </div>
        </ModalContent>
      </ModalBody>
    </PrimaInfoModalStyles>
  );
}

export default PrimaInfoModal;
