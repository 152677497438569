import caller from './quotationsSlice';
import managementAxe from '../../services/axios-client/managementServisAxeClient';

export const listQuotationsBeingWorkedRequests = async (page, search) => {
  caller.listQuotationsBeingWorkedPending();
  try {
    const res = await managementAxe.get('/broker/quotes/quotes-in-progress', {
      params: {
        ...search,
        page,
      },
    });

    if (res.status !== 200) {
      caller.listQuotationsBeingWorkedReject('Errore Interno');
      return;
    }
    caller.listQuotationsBeingWorkedSuccess(res.data);
  } catch (error) {
    caller.listQuotationsBeingWorkedReject('Errore Interno');
  }
};

export const resetQuotationsBeingWorkedRequests = () => {
  caller.quotationsBeingWorkedReset();
};

export const listQuotationsPurchasedRequests = async (page, search) => {
  caller.listQuotationsPurchasedPending();
  try {
    const res = await managementAxe.get('/broker/quotes/purchased/list', {
      params: {
        ...search,
        page,
      },
    });

    if (res.status !== 200) {
      caller.listQuotationsPurchasedReject('Errore Interno');
      return;
    }

    caller.listQuotationsPurchasedSuccess(res.data);
  } catch (error) {
    caller.listQuotationsPurchasedReject('Errore Interno');
  }
};

export const resetQuotationsPurchasedRequests = () => {
  caller.quotationsPurchasedReset();
};

export const listQuotationsSemestralToPay = async (page, search) => {
  caller.listQuotationsSemestralToPayPending();
  try {
    const res = await managementAxe.get('/broker/quotes/semestral-quotation-to-pay', {
      params: {
        ...search,
        page,
      },
    });

    if (res.status !== 200) {
      caller.listQuotationsSemestralToPayReject('Errore Interno');
      return;
    }

    caller.listQuotationsSemestralToPaySuccess(res.data);
  } catch (error) {
    caller.listQuotationsSemestralToPayReject('Errore Interno');
  }
};

export const resetQuotationsSemestralToPay = () => {
  caller.quotationsSemestralToPayReset();
};
