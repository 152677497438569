import React, { useRef } from 'react';
import { UploadInputStyles } from './UploadInput.styles';
import UploadIcon from '../../assets/images/icons/UploadIcon';

export default function UploadInput({
  uploadHandler,
  className,
  invalid,
  text,
}) {
  const fileInputRef = useRef(null);

  const fileInputHandler = (event) => {
    uploadHandler(event.target.files);
  };
  return (
    <UploadInputStyles>
      <div className={`upload-input-container ${className}`}>
        <div
          onClick={() => fileInputRef.current.click()}
          className={`upload-button-wrapper ${invalid ? 'invalid' : ''}`}
        >
          <span className={invalid ? 'invalid' : ''}>
            <UploadIcon />
          </span>
          <small className={invalid ? 'invalid' : ''}>{text}</small>
        </div>
        <input
          onChange={fileInputHandler}
          ref={fileInputRef}
          type="file"
          hidden
          accept=".doc,.docx,application/msword,text/plain,application/pdf,image/png,image/jpeg,image/jpg"
          multiple
        />
      </div>
    </UploadInputStyles>
  );
}
