import styled from 'styled-components';

export const FilePreviewStyles = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 10px 10px;

img {
  width: 150px;
  height: 150px;
  margin: 10px 0;
  cursor: pointer;
}
.file-frame {
  width: 100%;
  height: 150px;
  margin-top: 14px;
  border: 1px solid #dedede;
  border-radius: 0.313rem;
}

.close-button{
  width: 20px;
}

.preview_filename {
  width: calc(100% - 20px);
  font-size: 14px;
  word-wrap: normal;
  overflow-x: hidden;
}

@media only screen and (max-width: 425px) {
  .file-frame {
    width: 120px;
    height: 120px;
  }

  .close-button{
    width: 120px;
  }
}
`;
