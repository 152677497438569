import React, { useRef } from 'react';
import { RespCivileModal } from '../Cards/ProfessionInfoCard.styles';
import RiskCardPrimBackoffice from '../Cards/RiskCardPrimBackoffice';

function ModalQuotesPrimaBackoffice({
  type, addingQuote, requestInfo, processingQuote,
}) {
  const closeButtonRef = useRef(null);

  const closeButton = () => {
    if (closeButtonRef.current) {
      closeButtonRef.current.click(); // Close the modal
    }
  };

  const createIdForModal = () => {
    if (type.id === 'infortuni_conducente') {
      return 'infortuni_conducente_prima';
    }

    if (type.id === 'assistenza_stradale') {
      return 'assistenza_stradale_prima';
    }

    if (type.id === 'tutela_legale') {
      return 'tutela_legale_prima';
    }

    if (type.id === 'cristalli') {
      return 'cristalli_prima';
    }

    return type.id;
  };

  return (
    <RespCivileModal className="modal fade" id={createIdForModal()} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">{type.name}</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="buttonClose" ref={closeButtonRef} />
          </div>
          <div className="modal-body">
            {addingQuote === false && (
              <>
                <div className="row purchase-quote-eight-step">
                  {(processingQuote !== null && processingQuote.company_id === 2) ? (
                    <>
                      {processingQuote.quotation_data_prima.quotation_data_prima_guarantees.length > 0 && processingQuote.quotation_data_prima.quotation_data_prima_guarantees.filter((g) => g.slug === type.id).map((quote, key) => (
                        <RiskCardPrimBackoffice
                          quote={quote}
                          type={type}
                          key={key}
                          requestInfo={requestInfo}
                          processingQuote={processingQuote}
                          closeButton={closeButton}
                        />
                      ))}
                      {processingQuote.quotation_data_prima.quotation_data_prima_guarantees.filter((g) => g.slug === type.id).length === 0 && <h5 className="fw-bold">Nessuna Quotazione Trovata</h5>}
                    </>
                  )
                    : <h5 className="fw-bold">Nessuna Quotazione Trovata</h5>}

                  {(processingQuote !== null && processingQuote.quotation_data_prima.quotation_data_prima_guarantees.filter((g) => g.slug === type.id) === 0) && <h5 className="fw-bold">Nessuna Quotazione Trovata</h5>}
                </div>
              </>
            )}

          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-danger px-4" data-bs-dismiss="modal">Chiudi</button>
          </div>
        </div>
      </div>
    </RespCivileModal>
  );
}

export default ModalQuotesPrimaBackoffice;
