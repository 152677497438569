import styled from 'styled-components';

export const ModificaDatiButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  p {
    margin-bottom: 0;
    color: #00a651;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  img {
    max-width: 25px;
  }

  @media only screen and (max-width: 900px) {
    p {
      font-size: 14px;
    }

    img {
      max-width: 15px;
    }
  }
`;

export const EstimateContainer = styled.div`
  margin: 30px 0;
  h4 {
    color: var(--common-black, #1a1921);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
    color: #1a1921;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media only screen and (max-width: 900px) {
    margin: 20px 0;
    h4 {
      font-size: 18px;
    }

    p {
      font-size: 14px;
    }
  }
`;

export const SellingDistanceButton = styled.div`
  border-radius: 10px;
  background: #00a651;
  border: 1px solid #dff3ea;
  padding: 8px 20px;
  color: #ffffff;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 0;
  display: table;
  transition: all 300ms ease-in-out;
  cursor: pointer;

  &:hover {
    background: transparent;
    border: 1px solid #00a651;
    color: #00a651;
  }

  @media only screen and (max-width: 900px) {
    font-size: 14px;
    padding: 8px 20px;
    border-radius: 10px;
    width: 100%;
  }
`;
