import caller from './brokersSlice';
import {
  generalAlertError,
  generalAlertSuccess,
} from '../../common/utils/alerts';
import managementAxe from '../../services/axios-client/managementServisAxeClient';

const serverError = 'Si è verificato un problema con il server';

export const getBrokers = async () => {
  caller.listBrokersPending();

  try {
    const response = await managementAxe.get('/broker/section-e/brokers');

    if (response.status !== 200) {
      caller.listBrokersRejected(serverError);
      return;
    }

    caller.listBrokersSuccess(response.data.data);
  } catch (error) {
    caller.listBrokersRejected(serverError);
  }
};

export const storeSectionEBroker = async (data, callback) => {
  caller.createBrokerSectionEPending();
  const response = await managementAxe.post('/broker/section-e/brokers', data);

  caller.createBrokerSectionEFulfilled();

  if (response.status === 400 && response.data.error === 'email_taken') {
    generalAlertError("L'e-mail è in uso");
    return;
  } if (response.status !== 200) {
    generalAlertError(
      "C'è stato un problema con il server, contatta l'amministratore",
    );
    return;
  }

  generalAlertSuccess('Il intermediario e stato creato');
  callback();
};

export const storeSectionEBrokerReset = () => {
  caller.createBrokerSectionEFulfilled();
};

export const getSectionEBroker = async (brokerId) => {
  caller.getBrokerSectionEPending();

  const response = await managementAxe.get(
    `/broker/section-e/brokers/${brokerId}`,
  );

  if (response.status !== 200) {
    caller.getBrokerSectionERejected(
      "C'è stato un problema con il server, contatta l'amministratore",
    );
    return;
  }

  caller.getBrokerSectionEFulfilled(response.data.data);
};

export const getSectionEBrokerReset = () => {
  caller.getBrokerSectionEReset();
};

export const updateSectionEBroker = async (brokerId, data) => {
  caller.updatingBrokerSectionEPending();

  const response = await managementAxe.put(
    `/broker/section-e/brokers/${brokerId}`,
    data,
  );

  caller.updatingBrokerSectionEFulfilled();

  if (response.status === 400 && response.data.error === 'email_taken') {
    generalAlertError("L'e-mail è in uso");
    return;
  } if (response.status !== 200) {
    generalAlertError(
      "C'è stato un problema con il server, contatta l'amministratore",
    );
    return;
  }

  generalAlertSuccess('Il intermediario e stato modificato');
};

export const uploadingDocumentForSectionEBroker = () => {
  caller.uploadingDocumentForBrokerSectionEPending();
};

export const getSectionEBrokerDocuments = async (brokerId) => {
  const response = await managementAxe.get(
    `/broker/section-e/brokers/${brokerId}/documents`,
  );

  if (response.status !== 200) {
    caller.getBrokerSectionERejected(
      "C'è stato un problema con il server, contatta l'amministratore",
    );
    return;
  }

  caller.getBrokerSectionEDocumentsSuccess(response.data.data);
};

export const finishUploadingDocumentForSectionEBroker = () => {
  caller.uploadingDocumentForBrokerSectionEFulfilled();
};

export const getSectionEBrokerQuotations = async (page, search, brokerId) => {
  caller.getBrokerSectionEQuotationsPending();

  const response = await managementAxe.get(
    `/broker/section-e/brokers/${brokerId}/quotations`,
    {
      params: {
        ...search,
        page,
      },
    },
  );

  if (response.status !== 200) {
    caller.getBrokerSectionEQuotationsRejected(
      "C'è stato un problema con il server, contatta l'amministratore",
    );
    return;
  }

  caller.getBrokerSectionEQuotationsFulfilled(response.data);
};

export const resetSectionEBrokerQuotations = () => {
  caller.getBrokerSectionEQuotationsReset();
};

export const getSectionEBrokerPurchasedQuotations = async (
  page,
  search,
  brokerId,
) => {
  caller.getBrokerSectionEPurchasedQuotationsPending();

  const response = await managementAxe.get(
    `/broker/section-e/brokers/${brokerId}/purchased-quotations`,
    {
      params: {
        ...search,
        page,
      },
    },
  );

  if (response.status !== 200) {
    caller.getBrokerSectionEPurchasedQuotationsRejected(
      "C'è stato un problema con il server, contatta l'amministratore",
    );
    return;
  }

  caller.getBrokerSectionEPurchasedQuotationsFulfilled(response.data);
};

export const resetSectionEBrokerPurchasedQuotations = () => {
  caller.getBrokerSectionEQuotationsReset();
};

export const getBrokersSectionEForReports = async () => {
  caller.getBrokersSectionEForReportsPending();
  try {
    const res = await managementAxe.get(
      '/broker/section-e/brokers/list/for-reports',
    );

    if (res.status !== 200) {
      caller.getBrokersSectionEForReportsRejected(serverError);
      return;
    }

    caller.getBrokersSectionEForReports(res.data.data);
  } catch (error) {
    caller.getBrokersSectionEForReportsRejected(serverError);
  }
};

export const getBrokersSectionEData = async (parameters) => {
  caller.getBrokersSectionEDataPending();

  const res = await managementAxe.get('/broker/section-e/brokers/report/data', {
    params: parameters,
  });

  if (res.status !== 200) {
    caller.getBrokersSectionEDataRejected('Error Occured');
    return;
  }

  caller.getBrokersSectionEDataFulfilled(res.data.data);
};

export const brokerSectionEReportsReset = () => {
  caller.getBrokersSectionEReportsReset();
};
