export default function InputText(props) {
  const registration = props.register && props.name ? props.register(props.name) : {};
  const handleChange = ({ target: { value } }) => {
    props.onChange(value);
  };

  if (props.nostyle) {
    return (
      <>
        <label className="f-label">{props.label}</label>
        <input value={props.value} type={props.type} min={props.min} max={props.max} className={`form-control text ${props.className} ${props.error ? 'is-invalid' : ''} ${props.valid ? 'is-valid' : ''}`} onChange={handleChange} pattern={props.pattern} placeholder={props.placeholder} {...registration} />
        <div className="invalid-feedback">
          {props.error}
        </div>
      </>
    );
  }

  return (
    <div className="form-input-container">
      <div className="row">
        <div className={`col-xl-4 offset-xl-4 col-md-6 offset-md-3 ${props.colClass ? props.colClass : ''}`}>
          <label className="f-label">{props.label}</label>
          <input value={props.value} placeholder={props.placeholder} className={`form-control text ${props.className ? props.className : ''} ${props.error ? 'is-invalid' : ''} ${props.valid ? 'is-valid' : ''}`} type={props.type} min={props.min} max={props.max} pattern={props.pattern} onChange={handleChange} {...registration} />
          <div className="invalid-feedback">
            {props.error}
          </div>
        </div>
      </div>
    </div>
  );
}
