import React from 'react';
import {
  AquistaButton,
  AquistaButtonsContainer,
  AragCardStyles, LeftCol, LogoContainer, LogoPriceContainer, Price, PriceContainer,
  QuoteInformationContainer,
  QuoteInformationItem,
  RightCol,
} from './AragCard.styles';
import { BACKEND_BASE_URL } from '../../../../config';
import { SavedButton } from './common/Buttons.styles';

function AragCard({
  quotation, selectQuotation, requestInfo, type,
}) {
  return (
    <AragCardStyles className={quotation.is_aquista === 1 ? 'selected' : ''}>
      <LeftCol>
        <LogoPriceContainer>
          <LogoContainer>
            <img src={BACKEND_BASE_URL + quotation.company.logo} alt={`${quotation.company.name} Logo`} />
          </LogoContainer>
          <PriceContainer>
            <Price>
              <h5>
                Prezzo annuo:
              </h5>
              <h2>
                &euro;
                {Number(quotation.amount).toLocaleString('it-IT', {
                  minimumFractionDigits: 2,
                })}
              </h2>
            </Price>
          </PriceContainer>
        </LogoPriceContainer>
        {/* <CompanyInformationContainer>
          <CompanyInfoElements onClick={() => openModal()}>
            <img src={infoIcon} alt="" />
            <p>Informazione</p>
          </CompanyInfoElements>
        </CompanyInformationContainer> */}
        <QuoteInformationContainer>
          <QuoteInformationItem>
            <h6>
              Compagnia:
            </h6>
            <p>
              {quotation.company.name}
            </p>
          </QuoteInformationItem>
          <QuoteInformationItem>
            <h6>
              Prodotto:
            </h6>
            <p>
              {type.name}
            </p>
          </QuoteInformationItem>
          <QuoteInformationItem>
            <h6>
              Emissione:
            </h6>
            <p>
              Tradizionale
            </p>
          </QuoteInformationItem>
          <QuoteInformationItem>
            <h6>
              Frequenza pagamento:
            </h6>
            <p>
              {quotation.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}
            </p>
          </QuoteInformationItem>
        </QuoteInformationContainer>
      </LeftCol>
      <RightCol>
        <AquistaButtonsContainer>
          {requestInfo.tutela_legale_quote_id !== quotation.id ? (
            <AquistaButton
              onClick={() => selectQuotation(quotation.id)}
              className="purchase-quote-nine-step"
            >
              Seleziona
            </AquistaButton>
          ) : requestInfo.tutela_legale_quote_id === quotation.id && <SavedButton>Aggiunto</SavedButton>}
        </AquistaButtonsContainer>
      </RightCol>
    </AragCardStyles>
  );
}

export default AragCard;
