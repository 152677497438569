import React, {
  useEffect, useRef, useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleStop, faMicrophone, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { MediaRecorder, register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';
import { sendAudioForTranscriptionReturnData } from '../../features/speech/speechActions';
import { AudioContainer } from './AudioRecorderForChat.styles';
import { generalAlertError } from '../utils/alerts';

function AudioRecorderForChat({ sendMessage }) {
  const [recording, setRecording] = useState(false);
  const [loading, setLoading] = useState(false);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);

  const stopRecording = async () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
      setRecording(false);
    }
  };

  const handleDataAvailable = (event) => {
    if (event.data.size > 0) {
      chunksRef.current.push(event.data);
    }
  };

  const audioToBase64 = async (blob) => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const base64data = reader.result;
      resolve(base64data);
    };
    reader.readAsDataURL(blob);
  });

  const startRecording = async () => {
    chunksRef.current = [];
    try {
      await register(await connect());
    } catch (error) {
      // DO Nothing
    }
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorderRef.current = new MediaRecorder(stream, { mimeType: 'audio/wav' });
        mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
        mediaRecorderRef.current.addEventListener('stop', async () => {
          const audioBlob = new Blob(chunksRef.current, { type: 'audio/wav' });
          const base64Audio = await audioToBase64(audioBlob);

          const data = {
            audio: base64Audio.slice(22),
          };
          setLoading(true);
          const transcriptions = await sendAudioForTranscriptionReturnData(data);

          setLoading(false);
          if (transcriptions !== null) {
            if (transcriptions.length === 0) {
              generalAlertError('Per favore riprova! La voce non era molto chiara!');
              return;
            }

            sendMessage(transcriptions[0].transcript);
          }
          chunksRef.current = [];
        });
        mediaRecorderRef.current.start();
        setRecording(true);
      });
  };

  useEffect(() => {
    if (recording === true) {
      setTimeout(() => {
        stopRecording();
      }, 6000);
    }
  }, [recording]);

  return (
    <>
      <AudioContainer onClick={recording ? stopRecording : startRecording}>
        {loading === false
          && (
            <>
              {!recording
          && (
            <div className="d-flex align-items-center justify-content-center flex-column">
              <FontAwesomeIcon icon={faMicrophone} color="white" />
            </div>
          )}
              {recording
          && (
            <div className="d-flex align-items-center justify-content-center flex-column">
              <FontAwesomeIcon icon={faCircleStop} color="white" />
            </div>
          )}
            </>
          )}
        {loading === true
          && (
          <div className="d-flex align-items-center justify-content-center flex-column">
            <FontAwesomeIcon icon={faSpinner} color="white" />
          </div>
          )}
      </AudioContainer>
    </>
  );
}

export default AudioRecorderForChat;
