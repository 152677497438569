import React, { useEffect, useState, useRef } from 'react';
import { SimpleModalStyles } from './SimpleModal.styles';
import useClickOutside from '../../../hooks/useClickOutside';

function SimpleModal({ title, setIsOpen, children }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 760);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth <= 1000 && window.innerWidth >= 769,
  );
  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 760);
      setIsTablet(window.innerWidth <= 1000 && window.innerWidth >= 769);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const modalRef = useRef(null);
  useClickOutside(modalRef, () => setIsOpen(false));

  return (
    <SimpleModalStyles>
      <div
        ref={modalRef}
        className={`provider-modal-container  ${isMobile ? 'mobile' : ''} ${
          isTablet ? 'tablet' : ''
        } ${isTablet} `}
      >
        <span
          className="provider-modal-close-button btn btn-secondary"
          onClick={() => setIsOpen(false)}
        >
          Chiudere
        </span>
        <h3 className="provider-modal-header">{title}</h3>
        <div className="provider-modal-body">

          {children}
        </div>
      </div>
    </SimpleModalStyles>
  );
}

export default SimpleModal;
