import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PurchaseStyles } from './Purchase.styles';
import PageLoading from '../../common/components/elements/PageLoading';
import {
  commercialCollectData,
  commercialGetBackofficeUsers,
  resetCommercialPurchase,
} from '../../features/purchases/purchasesActions';

function PurchaseCommercial() {
  const { quotationId } = useParams();
  alert(quotationId);
  const state = useSelector((store) => store.purchases.commercial);
  const {
    loading, quotation, insuranceRequest, quoteStatus, backofficeUsers,
  } = state;

  console.log(insuranceRequest);
  const [supportMessage, setSupportMessage] = useState({
    subject: '',
    request_id: '',
    body: '',
    recipient: '',
  });
  console.log(quotation, quoteStatus, backofficeUsers, supportMessage);

  const collectQuoteData = async () => {
    commercialCollectData(quotationId, setSupportMessage);
  };

  // const navigateBack = () => {
  //   navigate(`/quotes/commercial/${insuranceRequest.request_token}`);
  // };

  useEffect(() => {
    alert(quotationId);
    console.log('asdasd');
    collectQuoteData();
    commercialGetBackofficeUsers();

    return () => {
      resetCommercialPurchase();
    };
  }, []);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <PurchaseStyles>
      <div>Hi there!</div>
    </PurchaseStyles>
  );
}

export default PurchaseCommercial;
