import styled from 'styled-components';

export const UploadInputStyles = styled.div`
    .upload-input-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .upload-button-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
        cursor: pointer;
    }
    .upload-button-wrapper.invalid {
        pointer-events: none;
    }

    .upload-button-wrapper  {
        display:flex;
        align-items:center;
        justify-content:center;
        width:300px;
        height:150px;
        border: 1px solid #dedede;
        border-radius: 0.313rem;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }
    .upload-button-wrapper.invalid {
        border: 1px solid red;
        color: red;
    }

    .upload-button-wrapper small {
        color: grey;
    }
    .upload-button-wrapper small.invalid {
        color: red;
    }
`;
