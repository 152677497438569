import { useEffect, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Chart from 'react-apexcharts';
// import Joyride from 'react-joyride';
import PageLoading from '../../common/components/elements/PageLoading';

import InputSelect from '../../common/components/inputs/InputSelect';
import {
  BrokersReportsStyles, InfoButton, InputSelectStyle, SelectRangeContainer, SelectRangeStyle,
} from './BrokersReports.styles';
import { brokerSectionEReportsReset, getBrokersSectionEData, getBrokersSectionEForReports } from '../../features/brokers/brokersActions';
import ErrorComponent from '../../common/components/ErrorComponent';
// import { useTourContext } from '../../context/useTourContext';
// import { joyrideOptions } from '../../common/utils/joyrideUtils';
// import { handleCallbackBrokersReports } from '../../common/utils/joyrideCallbacks';
// import { brokersReportSteps } from '../../common/utils/joyrideSteps';

export function BrokersReports() {
  const navigate = useNavigate();

  const state = useSelector((store) => store.brokers.broker_section_e_reports);

  const {
    loading, brokers, data, error,
  } = state;

  const [showDayPicker, setShowDayPicker] = useState(false);
  const [range, setRange] = useState();
  const [selectedBrokerId, setSelectedBrokerId] = useState('');
  // const [steps, setSteps] = useState([]);

  useEffect(() => {
    getBrokersSectionEForReports();

    return () => {
      brokerSectionEReportsReset();
    };
  }, []);

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('brokerReport') === 'done') {
  //     setState({ run: false });
  //   } else if (loading === false) {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 3000);
  //   }
  // }, [localStorage.getItem('brokerReport'), loading]);

  // useEffect(() => {
  //   brokersReportSteps(setSteps);
  // }, []);

  // const fireNewQuoteTour = () => {
  //   localStorage.removeItem('brokerReport');
  //   setState({ run: true, tourActive: true });
  // };

  useEffect(() => {
    const params = {};

    if (range !== undefined) {
      if (range.to === undefined) {
        return;
      }
      params.from = range.from;
      params.to = range.to;
    }

    params.broker_id = selectedBrokerId;

    getBrokersSectionEData(params);
  }, [range, selectedBrokerId]);

  const changeRange = (value) => {
    setRange(value);

    if (value !== undefined && value.to !== undefined) {
      setShowDayPicker(false);
    }
  };

  if (loading) {
    return <PageLoading />;
  }

  if (error !== null) {
    return <ErrorComponent error={error} />;
  }

  const showRange = () => {
    const format = 'DD.MM.YYYY';

    if (range === undefined) {
      return `${moment().subtract(1, 'month').format(format)} - ${moment().format(format)}`;
    }

    if (range.to === undefined) {
      return `${moment(range.from).format(format)} - `;
    }

    return `${moment(range.from).format(format)} - ${moment(range.to).format(format)}`;
  };

  const commissionsChartData = {
    labels: data.commission_earned_chart_data.brokers.map((d) => d.date),
    datasets: [
      {
        label: 'I miei comissioni',
        data: data.commission_earned_chart_data.you.map((d) => d.total),
        borderColor: 'green',
        backgroundColor: 'green',
      },
      {
        label: 'Intermediari',
        data: data.commission_earned_chart_data.brokers.map((d) => d.total),
        borderColor: 'lightgreen',
        backgroundColor: 'lightgreen',
      },
    ],
  };

  const insuranceRequestsChartData = {
    labels: data.insurance_requests_chart_data.map((d) => d.date),
    datasets: [
      {
        label: 'Preventivi',
        data: data.insurance_requests_chart_data.map((d) => d.total),
        borderColor: 'green',
        backgroundColor: 'green',
      },
    ],
  };

  return (
    <BrokersReportsStyles>
      {/* <Joyride
        steps={steps}
        continuous
        showBeacon={showBeacon}
        locale={{
          back: 'Indietro',
          close: 'Chiudi',
          last: 'Fine',
          next: 'Prossima',
          open: 'Apri il modale',
          restart: 'Riavvia il tour ',
          skip: 'Salta',
        }}
        styles={{
          options: joyrideOptions,
          buttonNext: {
            backgroundColor: '#00a651',
            outline: 'none',
          },
          buttonBack: {
            color: '#00a651',
            outline: 'none',
          },
          beaconInner: {
            backgroundColor: '#00a651',
          },
          beaconOuter: {
            borderColor: '#00a651',
            backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
          },
          buttonSkip: {
            outline: 'none',
          },
          buttonClose: {
            outline: 'none',
          },
        }}
        run={run}
        callback={(data) => handleCallbackBrokersReports(data, steps, setState)}
        showSkipButton
        showProgress
      /> */}
      <div className="d-flex justify-content-between">
        <h2>Report rete</h2>
        <div className="d-flex justify-content-center gap-2">
          {/* {localStorage.getItem('brokerReport') === 'done' && (
          <div style={{ cursor: 'pointer' }} onClick={() => fireNewQuoteTour()} className="mb-3 ">
            <button className="btn btn-success px-2 py-1">Tutorial</button>
          </div>
          )} */}
          <InfoButton onClick={() => navigate('/brokers')}>
            Gestisci rete
          </InfoButton>
        </div>
      </div>

      <SelectRangeContainer>
        <div className="d-flex">
          <SelectRangeStyle showDayPicker={showDayPicker} className="btn btn-success select-date" onClick={() => setShowDayPicker((prev) => !prev)}>
            Seleziona date
          </SelectRangeStyle>

          <InputSelectStyle className="select-broker">
            <InputSelect
              label="Intermediario"
              placeholder="- Seleziona -"
              options={[
                {
                  id: -1,
                  name: 'Tutti Agenti',
                },
                ...brokers,
              ]}
              colClass="m-0 w-100"
              value={selectedBrokerId}
              onChange={(value) => setSelectedBrokerId(value)}
            />
          </InputSelectStyle>
        </div>

        <div className="range-selection mb-3">
          Intervallo selezionato:
          {' '}
          {showRange()}
        </div>

        {showDayPicker === true
          && (
            <DayPicker
              className="day-picker"
              mode="range"
              numberOfMonths={2}
              selected={range}
              onSelect={changeRange}
              footer={(
                <button
                  className="btn btn-sm btn-warning text-white"
                  onClick={() => {
                    setRange(undefined);
                    setShowDayPicker(false);
                  }}
                >
                  Reset
                </button>
              )}
            />
          )}

      </SelectRangeContainer>

      <div className="row mb-4 select-result-intervallo">
        <div className="col-12 col-md-6 col-xl-3 mb-3 mb-xl-0">
          <div className="custom-card">
            <div className="custom-card-header">
              <b>Premi emessi</b>
            </div>
            <div className="custom-card-body">
              <div className="card-data">
                {data.sum_of_contracts_in_emessa}
                {' '}
                &euro;
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6 col-xl-3 mb-3 mb-xl-0">
          <div className="custom-card">
            <div className="custom-card-header">
              <b>Contratti emessi</b>
            </div>
            <div className="custom-card-body">
              <div className="card-data">{data.nr_of_contract_in_emessa}</div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6 col-xl-3 mb-3 mb-xl-0">
          <div className="custom-card">
            <div className="custom-card-header">
              <b>Preventivi fatti</b>
            </div>
            <div className="custom-card-body">
              <div className="card-data">{data.nr_of_insurance_requests}</div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6 col-xl-3 mb-3 mb-xl-0">
          <div className="custom-card">
            <div className="custom-card-header">
              <b>Provvigioni Guadagnate</b>
            </div>
            <div className="custom-card-body">
              <div className="card-data">
                {data.commission_gained}
                {' '}
                &euro;
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6 mb-3 mb-md-0 chart-one-broker-report">
          <div className="custom-card" style={{ flexBasis: 400, flexShrink: 0 }}>
            <Chart
              type="line"
              options={{
                stroke: {
                  width: 3,
                },
                xaxis: {
                  categories: insuranceRequestsChartData.labels,
                },
              }}
              series={[{
                data: insuranceRequestsChartData.datasets[0].data,
                color: '#05AA05',
              }]}
            />
          </div>
        </div>
        <div className="col-12 col-md-6 chart-two-broker-report">
          <div className="custom-card" style={{ flexBasis: 400, flexShrink: 0 }}>
            <Chart
              type="line"
              options={{
                stroke: {
                  width: 3,
                },
                xaxis: {
                  categories: commissionsChartData.labels,
                },
              }}
              series={[
                {
                  name: 'I miei comissioni',
                  data: commissionsChartData.datasets[0].data,
                  color: '#05AA05',
                },
                {
                  name: 'Intermediari',
                  data: commissionsChartData.datasets[1].data,
                  color: '#55FF55',
                },
              ]}
            />
          </div>
        </div>
      </div>

    </BrokersReportsStyles>
  );
}
