import moment from 'moment';
import React from 'react';

function CompletedQuoteScreenInfoAuto({
  rc_quotation,
  tutela_quotation,
  infortuni_conducente_quotation,
  assistenza_quotation,
  cristalli_quotation,
  rivalsa_quotation,
}) {
  return (
    <>
      <div className="col-12 col-md-6">
        <div className="card border mb-2 polizze-emessa-status-step-three">
          <div className="card-header bg-secondary text-white">
            <h2 className="h3 mb-0 card-title">Dati Responsabilita Civile</h2>
          </div>
          <div className="card-body">
            {rc_quotation.company_id != 2 ? (
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <b>Compagnia: </b>
                  {' '}
                  {rc_quotation.company.name}
                </li>
                <li className="list-group-item">
                  <b>Stato:</b>
                  {' '}
                  {rc_quotation.status === 7 && 'Polizza Emmesa'}
                </li>
                <li className="list-group-item">
                  <b>Data Decorrenza:</b>
                  {' '}
                  {moment(rc_quotation.insurance_request.policy_effective_date)
                    .toDate()
                    .toLocaleDateString('it-IT')}
                </li>
                <li className="list-group-item">
                  <b>Frequenza Pagamento:</b>
                  {' '}
                  {rc_quotation.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}
                </li>
                <li className="list-group-item">
                  <b>Pagato:</b>
                  {' '}
                  {rc_quotation.amount_paid === null
                    ? '0'
                    : rc_quotation.amount_paid.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Da Pagare:</b>
                  {' '}
                  {rc_quotation.amount_paid === rc_quotation.amount
                    ? '0'
                    : ((Math.ceil(rc_quotation.amount * 100) / 100) - rc_quotation.amount_paid).toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Prossimo Pagamento:</b>
                  {' '}
                  {moment(rc_quotation.insurance_request.policy_effective_date)
                    .add(Math.floor(365 / rc_quotation.payment_frequency), 'days')
                    .toDate()
                    .toLocaleDateString('it-IT')}
                </li>
                <li className="list-group-item">
                  <b>Premio:</b>
                  {' '}
                  {rc_quotation.amount.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Provvigioni:</b>
                  {' '}
                  {rc_quotation.commision.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Brokeraggio:</b>
                  {' '}
                  {rc_quotation.brokerage_amount.toFixed(2)}
                  {' '}
                  &euro;
                </li>
                {rc_quotation.quotation_services.length > 0 && (
                  <li className="list-group-item">
                    <b>Servizio:</b>
                    {' '}
                    {rc_quotation.quotation_services[0].amount_gross.toFixed(2)}
                    {' '}
                    &euro;
                  </li>
                )}
                <li className="list-group-item">
                  <b>Totale:</b>
                  {' '}
                  {(
                    rc_quotation.amount
                    + rc_quotation.brokerage_amount
                    + (rc_quotation.quotation_services.length > 0
                      ? rc_quotation.quotation_services[0].amount_gross
                      : 0)
                  ).toFixed(2)}
                  {' '}
                  &euro;
                </li>
              </ul>
            ) : (
              ''
            )}
            {rc_quotation.company_id == 2 ? (
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <b>Compagnia: </b>
                  {' '}
                  Ipitq ma
                </li>
                <li className="list-group-item">
                  <b>Stato:</b>
                  {' '}
                  {rc_quotation.status === 7 && 'Polizza Emmesa'}
                </li>
                <li className="list-group-item">
                  <b>Data Decorrenza:</b>
                  {' '}
                  {moment(rc_quotation.insurance_request.policy_effective_date)
                    .toDate()
                    .toLocaleDateString('it-IT')}
                </li>
                <li className="list-group-item">
                  <b>Polizza Pagata:</b>
                  {' '}
                  SI
                </li>
                <li className="list-group-item">
                  <b>Totale:</b>
                  {' '}
                  {rc_quotation.amount.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Provvigioni:</b>
                  {' '}
                  {rc_quotation.commision.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                {rc_quotation.quotation_services.length > 0 && (
                  <li className="list-group-item">
                    <b>Servizio:</b>
                    {' '}
                    {rc_quotation.quotation_services[0].amount_gross.toFixed(2)}
                    {' '}
                    &euro;
                  </li>
                )}
                <li className="list-group-item">
                  <b>Totale:</b>
                  {' '}
                  {(
                    rc_quotation.amount
                    + rc_quotation.brokerage_amount
                    + (rc_quotation.quotation_services.length > 0
                      ? rc_quotation.quotation_services[0].amount_gross
                      : 0)
                  ).toFixed(2)}
                  {' '}
                  &euro;
                </li>
              </ul>
            ) : (
              ''
            )}
            {rc_quotation.company_id === null ? (
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <b>Compagnia: </b>
                  {' '}
                  {rc_quotation.preventivas_company.company_name}
                </li>
                <li className="list-group-item">
                  <b>Stato:</b>
                  {' '}
                  {rc_quotation.status === 7 && 'Polizza Emmesa'}
                </li>
                <li className="list-group-item">
                  <b>Data Decorrenza:</b>
                  {' '}
                  {moment(rc_quotation.insurance_request.policy_effective_date)
                    .toDate()
                    .toLocaleDateString('it-IT')}
                </li>
                <li className="list-group-item">
                  <b>Polizza Pagata:</b>
                  {' '}
                  SI
                </li>
                <li className="list-group-item">
                  <b>Totale:</b>
                  {' '}
                  {rc_quotation.amount.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Provvigioni:</b>
                  {' '}
                  {rc_quotation.commision.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                {rc_quotation.quotation_services.length > 0 && (
                  <li className="list-group-item">
                    <b>Servizio:</b>
                    {' '}
                    {rc_quotation.quotation_services[0].amount_gross.toFixed(2)}
                    {' '}
                    &euro;
                  </li>
                )}
                <li className="list-group-item">
                  <b>Totale:</b>
                  {' '}
                  {(
                    rc_quotation.amount
                    + rc_quotation.brokerage_amount
                    + (rc_quotation.quotation_services.length > 0
                      ? rc_quotation.quotation_services[0].amount_gross
                      : 0)
                  ).toFixed(2)}
                  {' '}
                  &euro;
                </li>
              </ul>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      {tutela_quotation && (
        <div className="col-12 col-md-6">
          <div className="card border mb-2 polizze-emessa-status-step-three">
            <div className="card-header bg-secondary text-white">
              <h2 className="h3 mb-0 card-title">Dati Tutela Legale</h2>
            </div>
            <div className="card-body">
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <b>Compagnia: </b>
                  {' '}
                  {tutela_quotation.company.name}
                </li>
                <li className="list-group-item">
                  <b>Stato:</b>
                  {' '}
                  {tutela_quotation.status === 7 && 'Polizza Emmesa'}
                </li>
                <li className="list-group-item">
                  <b>Data Decorrenza:</b>
                  {' '}
                  {moment(tutela_quotation.insurance_request.policy_effective_date)
                    .toDate()
                    .toLocaleDateString('it-IT')}
                </li>
                <li className="list-group-item">
                  <b>Frequenza Pagamento:</b>
                  {' '}
                  {tutela_quotation.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}
                </li>
                <li className="list-group-item">
                  <b>Pagato:</b>
                  {' '}
                  {tutela_quotation.amount_paid === null
                    ? '0'
                    : tutela_quotation.amount_paid.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Premio:</b>
                  {' '}
                  {tutela_quotation.amount.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Provvigioni:</b>
                  {' '}
                  {tutela_quotation.commision.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Totale:</b>
                  {' '}
                  {(tutela_quotation.amount).toFixed(2)}
                  {' '}
                  &euro;
                </li>
              </ul>
            </div>
          </div>
        </div>

      )}
      {infortuni_conducente_quotation && (
        <div className="col-12 col-md-6">
          <div className="card border mb-2 polizze-emessa-status-step-three">
            <div className="card-header bg-secondary text-white">
              <h2 className="h3 mb-0 card-title">Dati Infortuni Conducente</h2>
            </div>
            <div className="card-body">
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <b>Compagnia: </b>
                  {' '}
                  {infortuni_conducente_quotation.company.name}
                </li>
                <li className="list-group-item">
                  <b>Stato:</b>
                  {' '}
                  {infortuni_conducente_quotation.status === 7 && 'Polizza Emmesa'}
                </li>
                <li className="list-group-item">
                  <b>Data Decorrenza:</b>
                  {' '}
                  {moment(infortuni_conducente_quotation.insurance_request.policy_effective_date)
                    .toDate()
                    .toLocaleDateString('it-IT')}
                </li>
                <li className="list-group-item">
                  <b>Frequenza Pagamento:</b>
                  {' '}
                  {infortuni_conducente_quotation.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}
                </li>
                <li className="list-group-item">
                  <b>Pagato:</b>
                  {' '}
                  {infortuni_conducente_quotation.amount_paid === null
                    ? '0'
                    : infortuni_conducente_quotation.amount_paid.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Premio:</b>
                  {' '}
                  {infortuni_conducente_quotation.amount.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Provvigioni:</b>
                  {' '}
                  {infortuni_conducente_quotation.commision.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Totale:</b>
                  {' '}
                  {(infortuni_conducente_quotation.amount).toFixed(2)}
                  {' '}
                  &euro;
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
      {assistenza_quotation && (
        <div className="col-12 col-md-6">
          <div className="card border mb-2 polizze-emessa-status-step-three">
            <div className="card-header bg-secondary text-white">
              <h2 className="h3 mb-0 card-title">Dati Assistenza Stradale</h2>
            </div>
            <div className="card-body">
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <b>Compagnia: </b>
                  {' '}
                  {assistenza_quotation.company.name}
                </li>
                <li className="list-group-item">
                  <b>Stato:</b>
                  {' '}
                  {assistenza_quotation.status === 7 && 'Polizza Emmesa'}
                </li>
                <li className="list-group-item">
                  <b>Data Decorrenza:</b>
                  {' '}
                  {moment(assistenza_quotation.insurance_request.policy_effective_date)
                    .toDate()
                    .toLocaleDateString('it-IT')}
                </li>
                <li className="list-group-item">
                  <b>Frequenza Pagamento:</b>
                  {' '}
                  {assistenza_quotation.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}
                </li>
                <li className="list-group-item">
                  <b>Pagato:</b>
                  {' '}
                  {assistenza_quotation.amount_paid === null
                    ? '0'
                    : assistenza_quotation.amount_paid.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Premio:</b>
                  {' '}
                  {assistenza_quotation.amount.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Provvigioni:</b>
                  {' '}
                  {assistenza_quotation.commision.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Totale:</b>
                  {' '}
                  {(assistenza_quotation.amount).toFixed(2)}
                  {' '}
                  &euro;
                </li>
              </ul>
            </div>
          </div>
        </div>

      )}
      {cristalli_quotation && (
        <div className="col-12 col-md-6">
          <div className="card border mb-2 polizze-emessa-status-step-three">
            <div className="card-header bg-secondary text-white">
              <h2 className="h3 mb-0 card-title">Dati Cristalli</h2>
            </div>
            <div className="card-body">
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <b>Compagnia: </b>
                  {' '}
                  {cristalli_quotation.company.name}
                </li>
                <li className="list-group-item">
                  <b>Stato:</b>
                  {' '}
                  {cristalli_quotation.status === 7 && 'Polizza Emmesa'}
                </li>
                <li className="list-group-item">
                  <b>Data Decorrenza:</b>
                  {' '}
                  {moment(cristalli_quotation.insurance_request.policy_effective_date)
                    .toDate()
                    .toLocaleDateString('it-IT')}
                </li>
                <li className="list-group-item">
                  <b>Frequenza Pagamento:</b>
                  {' '}
                  {cristalli_quotation.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}
                </li>
                <li className="list-group-item">
                  <b>Pagato:</b>
                  {' '}
                  {cristalli_quotation.amount_paid === null
                    ? '0'
                    : cristalli_quotation.amount_paid.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Premio:</b>
                  {' '}
                  {cristalli_quotation.amount.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Provvigioni:</b>
                  {' '}
                  {cristalli_quotation.commision.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Totale:</b>
                  {' '}
                  {(cristalli_quotation.amount).toFixed(2)}
                  {' '}
                  &euro;
                </li>
              </ul>
            </div>
          </div>
        </div>

      )}
      {rivalsa_quotation && (
        <div className="col-12 col-md-6">
          <div className="card border mb-2 polizze-emessa-status-step-three">
            <div className="card-header bg-secondary text-white">
              <h2 className="h3 mb-0 card-title">Dati Rivalsa</h2>
            </div>
            <div className="card-body">
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <b>Compagnia: </b>
                  {' '}
                  {rivalsa_quotation.company.name}
                </li>
                <li className="list-group-item">
                  <b>Stato:</b>
                  {' '}
                  {rivalsa_quotation.status === 7 && 'Polizza Emmesa'}
                </li>
                <li className="list-group-item">
                  <b>Data Decorrenza:</b>
                  {' '}
                  {moment(rivalsa_quotation.insurance_request.policy_effective_date)
                    .toDate()
                    .toLocaleDateString('it-IT')}
                </li>
                <li className="list-group-item">
                  <b>Frequenza Pagamento:</b>
                  {' '}
                  {rivalsa_quotation.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}
                </li>
                <li className="list-group-item">
                  <b>Pagato:</b>
                  {' '}
                  {rivalsa_quotation.amount_paid === null
                    ? '0'
                    : rivalsa_quotation.amount_paid.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Premio:</b>
                  {' '}
                  {rivalsa_quotation.amount.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Provvigioni:</b>
                  {' '}
                  {rivalsa_quotation.commision.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Totale:</b>
                  {' '}
                  {(rivalsa_quotation.amount).toFixed(2)}
                  {' '}
                  &euro;
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CompletedQuoteScreenInfoAuto;
