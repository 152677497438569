/* eslint-disable import/no-unresolved */
import React from 'react';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import ArticleCard from '../components/ArticleCard';
import 'swiper/css';
import 'swiper/css/navigation';
import { WidgetStyles } from './Widgets.styles';

function LatestArticles() {
  const state = useSelector((store) => store.dashboard.dashboard);

  const {
    latest_articles,
  } = state;

  return (
    <WidgetStyles className="custom-card h-100">
      <div className="custom-card-header">
        <div className="custom-card-section">
          <h3>
            Le ultime notizie dal mercato assicurativo
          </h3>
        </div>
      </div>
      <div className="p-2">
        <Swiper
          modules={[Pagination]}
          className="p-1"
          pagination={{
            clickable: true,
            el: '.custom-pagination',
          }}
          breakpoints={{
            576: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            578: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            992: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            1200: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1400: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1500: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
        >
          {latest_articles.map((article, index) => (
            <SwiperSlide key={index}>
              <ArticleCard article={article} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="d-flex align-items-center justify-content-center gap-2 mt-2 custom-pagination" />
      </div>

    </WidgetStyles>
  );
}

export default LatestArticles;
