import React from 'react';
import { useNavigate } from 'react-router-dom';
import { WidgetStyles } from './Widgets.styles';

function AnalisiRuiWidget() {
  const navigate = useNavigate();

  return (
    <WidgetStyles id="client-search" className="custom-card h-100">
      <div className="custom-card-header">
        <div className="custom-card-section">
          <h3>Analisi Rui</h3>
        </div>
      </div>
      <div
        className="card card-body"
        style={{ position: 'relative', overflowY: 'visible' }}
      >
        <div className="custom-card-section">
          <div className="row">
            <div className="col-12 col-md-12">
              <h5 className="my-2 text-center mt-4">
                Seleziona Tipo Di Ricerca
              </h5>
              <div className="d-flex align-items-stretch w-75 mx-auto justify-content-center flex-column gap-2 mt-4">
                <button className="btn btn-success d-table mx-auto w-100" onClick={() => navigate('/network-analisys')}>Analisi Rete</button>
                <button className="btn btn-success d-table mx-auto w-100" onClick={() => navigate('/collaborators-analisys')}>Analisi Collaboratori</button>
                <button className="btn btn-success d-table mx-auto w-100" onClick={() => navigate('/comparative-analisys')}>Analisi Comparativa</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WidgetStyles>
  );
}

export default AnalisiRuiWidget;
