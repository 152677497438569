import styled from 'styled-components';

export const TicketModalStyles = styled.div`
    .form-control:focus.reply-input {
        color: #212529;
        background-color: #fff;
        border-color: rgb(0 166 81 / 50%);
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgb(0 166 81 / 25%)
    }

    .form-control:focus.reply-select {
        color: #212529;
        background-color: #fff;
        border-color: rgb(0 166 81 / 50%);
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgb(0 166 81 / 25%)
    }

    .form-control.reply-subject {
        border: 1px solid #ced4da !important;
        
    }

    .form-control:focus.reply-subject {
        border: 1px solid #ced4da !important;
        color: #212529;
        background-color: #fff;
        border-color: rgb(0 166 81 / 50%);
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgb(0 166 81 / 25%)
    }
`;
