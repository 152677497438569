import React from 'react';
import { ProfessionCardStyles } from './ProfessionCard.styles';
import {
  AquistaButton,
  AquistaButtonsContainer,
  LeftCol, LogoContainer, LogoPriceContainer, Price, PriceContainer,
  QuoteInformationContainer,
  QuoteInformationItem,
  RightCol,
} from './AdriaticCard.styles';
import { BACKEND_BASE_URL } from '../../../../config';
import { SavedButton } from './common/Buttons.styles';

function RiskCard({
  quote, type, selectQuotation, requestInfo,
}) {
  return (
    <ProfessionCardStyles className={quote.is_aquista === 1 ? 'selected' : ''}>
      <div className="row-container">
        <LeftCol>
          <LogoPriceContainer>
            <LogoContainer>
              <img src={BACKEND_BASE_URL + quote.company.logo} alt={quote.company.name} />
            </LogoContainer>
            <PriceContainer>
              <Price>
                <h5>
                  Prezzo annuo:
                </h5>
                <h2>
                  &euro;
                  {' '}
                  {parseFloat(quote.amount)}
                </h2>
              </Price>
            </PriceContainer>
          </LogoPriceContainer>
          {/* <CompanyInformationContainer>
            {quote.quote_type === 'tutela_legale' && (
              <>
                <CompanyInfoElements
                  onClick={() => downloadResourceDocs(10, 0)}
                  title="questionario_pi_tutela_legale"
                >
                  <img src={preventiveIcon} alt="" />
                  <p>Questionario</p>
                </CompanyInfoElements>
                <CompanyInfoElements
                  onClick={() => downloadResourceDocs(9, 0)}
                  title="set_informativo_pi_Tutela_legale"
                >
                  <img src={preContractIcon} alt="" />
                  <p>Informativa Precontrattuale</p>
                </CompanyInfoElements>
              </>
            )}
          </CompanyInformationContainer> */}
          <QuoteInformationContainer>
            <QuoteInformationItem>
              <h6>
                Compagnia:
              </h6>
              <p>
                {quote.company.name}
              </p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>
                Prodotto:
              </h6>
              <p>
                {type.name}
              </p>
            </QuoteInformationItem>
            <QuoteInformationItem>
              <h6>
                Massimale:
              </h6>
              <p>
                10000
                &euro;
              </p>
            </QuoteInformationItem>
          </QuoteInformationContainer>
        </LeftCol>
        <RightCol>
          <AquistaButtonsContainer>
            {type.id === 'assistenza_stradale'
              && (
                <>
                  {requestInfo.assistenza_stradale_quote_id !== quote.id ? (
                    <AquistaButton
                      onClick={() => selectQuotation(quote.id)}
                      className="result-profession-sixth-step"
                    >
                      Seleziona
                    </AquistaButton>
                  ) : requestInfo.assistenza_stradale_quote_id === quote.id && <SavedButton>Aggiunto</SavedButton>}
                </>
              )}
            {type.id === 'cristalli'
              && (
                <>
                  {requestInfo.cristalli_quote_id !== quote.id ? (
                    <AquistaButton
                      onClick={() => selectQuotation(quote.id)}
                      className="result-profession-sixth-step"
                    >
                      Seleziona
                    </AquistaButton>
                  ) : requestInfo.cristalli_quote_id === quote.id && <SavedButton>Aggiunto</SavedButton>}
                </>
              )}
            {type.id === 'infortuni_conducente'
              && (
                <>
                  {requestInfo.infortuni_conducente_quote_id !== quote.id ? (
                    <AquistaButton
                      onClick={() => selectQuotation(quote.id)}
                      className="result-profession-sixth-step"
                    >
                      Seleziona
                    </AquistaButton>
                  ) : requestInfo.infortuni_conducente_quote_id === quote.id && <SavedButton>Aggiunto</SavedButton>}
                </>
              )}
            {type.id === 'rivalsa'
              && (
                <>
                  {requestInfo.rivalsa_quote_id !== quote.id ? (
                    <AquistaButton
                      onClick={() => selectQuotation(quote.id)}
                      className="result-profession-sixth-step"
                    >
                      Seleziona
                    </AquistaButton>
                  ) : requestInfo.rivalsa_quote_id === quote.id && <SavedButton>Aggiunto</SavedButton>}
                </>
              )}
            {type.id === 'tutela_legale'
              && (
                <>
                  {requestInfo.tutela_legale_quote_id !== quote.id ? (
                    <AquistaButton
                      onClick={() => selectQuotation(quote.id)}
                      className="result-profession-sixth-step"
                    >
                      Seleziona
                    </AquistaButton>
                  ) : requestInfo.tutela_legale_quote_id === quote.id && <SavedButton>Aggiunto</SavedButton>}
                </>
              )}
          </AquistaButtonsContainer>
        </RightCol>
      </div>
    </ProfessionCardStyles>
  );
}

export default RiskCard;
