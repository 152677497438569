import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { BadgeStatus, RemoveDocument, UploadedDocumentWrapper } from '../Purchase.styles';
import pdfUploaded from '../../../assets/redesign_icons/file.svg';
import { Uploader } from '../../../common/components/redesignComponents/Uploader/Uploader';
import { apiUploadOptionalDocument } from '../../../services/axios-client/axeCommons';
import { generalAlertSuccessToast } from '../../../common/utils/alerts';

function NonSIgnableDocumentRequestComponent({
  document_request, removeOptionalDocument, downloadOptionalDocument, collectQuoteData,
}) {
  const uploadOptionalDocument = (file, optionalDocumentRequestId) => {
    let fileInfo;
    let baseURL = '';
    const uploadedFile = file;
    // Make new FileReader
    const reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(uploadedFile);
    // on reader load pdf...
    reader.onload = async () => {
      fileInfo = uploadedFile.name;
      const fileType = uploadedFile.type;
      baseURL = reader.result;
      const data = {
        optional_document_request_id: optionalDocumentRequestId,
        original_filename: fileInfo,
        unsigned_document: baseURL.replace(`data:${fileType};base64,`, ''),
      };
      const response = await apiUploadOptionalDocument(data);
      if (response.status !== 200) {
        return;
      }
      collectQuoteData();
      generalAlertSuccessToast('File caricato');
    };
  };

  return (
    <>
      {document_request.document !== null ? (
        <UploadedDocumentWrapper>
          {(
            document_request.document.is_uploaded_valid === 1
          ) && (
          <BadgeStatus background="#99DBB9" color="#009232">
            Valido
          </BadgeStatus>
          )}
          {(document_request.document.is_uploaded_valid === 0)
            && (
              <>
                <RemoveDocument onClick={() => removeOptionalDocument(document_request.document.id)}>
                  <FontAwesomeIcon icon={faTimesCircle} color="red" />
                </RemoveDocument>
                <BadgeStatus background="#FFB4B4" color="#F00">
                  Non Valido
                </BadgeStatus>
              </>
            )}
          {(document_request.document.is_uploaded_valid === null) && (
            <BadgeStatus background="#009232" color="#ffffff">
              In verifica
            </BadgeStatus>
          )}
          <img src={pdfUploaded} alt="" width={50} />
          {(document_request.document.motivation !== null)
            && (
              <p className="text-danger">
                {document_request.document.motivation}
              </p>
            )}
          <p onClick={() => downloadOptionalDocument(document_request.document.id)}>
            Tocca qui per scaricare
          </p>
          <h3>
            {document_request.optional_document_type.name.toUpperCase()}
          </h3>
        </UploadedDocumentWrapper>
      ) : <Uploader onFileUpload={(file) => uploadOptionalDocument(file, document_request.id)} note={`CARICA ${document_request.optional_document_type.name.toUpperCase()}`} />}
    </>
  );
}

export default NonSIgnableDocumentRequestComponent;
