import { useEffect, useState } from 'react';
import { FormInputCheckbox } from './FormInputCheckbox';
import { FormInputSelect } from './FormInputSelect';
import { Label } from './styles/common';

export function FormInputBirthPlace({
  label, placeholder, options, onChange, value, states, error, valid,
}) {
  const [searchFraze, setSearchFraze] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [dropdownActive, setDropdownActive] = useState(false);

  const handleChange = (opt, dontCallChange) => {
    if (opt.born_abroad !== undefined) {
      if (opt.born_abroad) {
        opt.commune_of_birth_code = null;
        opt.province_of_birth_code = null;
      } else {
        opt.country_of_birth_code = 'Z000';
      }
    }

    const newValue = { ...value, ...opt };

    if (!newValue.born_abroad) {
      const commune = options.find((o) => o.cadastral_code === newValue.commune_of_birth_code);

      if (commune !== undefined) {
        let fraze = commune.name;
        let code = '';
        if (commune.communal_territory) {
          code = ` (${commune.communal_territory.car_plate_symbol})`;
        }
        fraze += code;
        setSearchFraze(fraze);
        setDropdownActive(false);
      }
    } else {
      setSearchFraze('');
      setDropdownActive(false);
    }

    if (dontCallChange !== true) {
      onChange(newValue);
    }
  };

  const handleSearch = ({ target }) => {
    if (target.value === '') {
      setSearchFraze(target.value);
      setFilteredOptions([]);
      setDropdownActive(false);
    } else {
      const filtered = options.filter((item) => item.name.toLowerCase().startsWith(target.value.toLowerCase()));
      setSearchFraze(target.value);
      setFilteredOptions(filtered);
      if (filtered.length) {
        setDropdownActive(true);
      } else {
        setDropdownActive(false);
      }
    }
  };

  useEffect(() => {
    if (value.commune_of_birth_code !== null && value.commune_of_birth_code !== '') {
      const selected = options.filter((item) => item.cadastral_code === value.commune_of_birth_code);
      if (selected.length) handleChange(selected[0], true);
    }
  }, [value]);

  return (
    <div className="form-input-container">
      <div className="row">
        <div className="position-relative">
          <Label>{label}</Label>
          <div className="d-flex">
            <input value={searchFraze} type="text" disabled={value.born_abroad} onChange={handleSearch} className={`form-control text-center ${error ? 'is-invalid' : ''} ${valid ? 'is-valid' : ''}`} placeholder={placeholder} />
            {dropdownActive
              && (
                <div className="form-select-dropdown-new">
                  {filteredOptions.length > 0 && filteredOptions.map((opt) => (
                    <span
                      key={opt.id}
                      onClick={() => handleChange({
                        commune_of_birth_code: opt.cadastral_code,
                        province_of_birth_code: opt.communal_territory.car_plate_symbol,
                      })}
                    >
                      {opt.name}
                      {' '}
                      {opt.communal_territory ? `(${opt.communal_territory.car_plate_symbol})` : ''}
                      {' '}

                    </span>
                  ))}
                </div>
              )}
          </div>
          <FormInputCheckbox
            label="Stato estero"
            checked={value.born_abroad}
            onChange={() => {
              handleChange({ born_abroad: !value.born_abroad });
            }}
          />
          {value.born_abroad === true && states !== undefined
            && (
              <FormInputSelect
                placeholder="Stato"
                label="Stato"
                options={states}
                onChange={(val) => handleChange({ country_of_birth_code: states.find((s) => s.id == val).state_code })}
                value={states.find((s) => s.state_code == value.country_of_birth_code) ? states.find((s) => s.state_code == value.country_of_birth_code).id : ''}
              />
            )}
        </div>
      </div>
      <div className="text-center general-input-error">
        {error}
      </div>
    </div>
  );
}
