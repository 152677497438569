import React from 'react';
import RiskCardSelected from '../Cards/RiskCardSelected';

function CristalliSelectedAuto({ cristalliQuote, type }) {
  return (
    <>
      {cristalliQuote !== null && (
        <div className="col-12 mb-2">
          <RiskCardSelected quote={cristalliQuote} type={type} />
        </div>
      )}

      {cristalliQuote === null && <div data-bs-toggle="modal" data-bs-target={`#${type.id}`} style={{ cursor: 'pointer' }} className="alert alert-secondary">Seleziona Quotazione!</div>}
    </>
  );
}

export default CristalliSelectedAuto;
