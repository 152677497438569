import { createSlice } from '@reduxjs/toolkit';

import { callerCreator } from '../callerCreator';

const initialState = {
  dashboard: {
    loading: true,
    error: null,
    searchResults: [],
    total_quotations_started: 0,
    total_quotations_emmese: 0,
    total_quotations_paid_amount: 0,
    total_spent_in_accidents: 0,
    latest_updates: [],
    latest_articles: [],
    agends: [],
    agends_view: 'week',
    agends_date: new Date(),
    agends_loading: false,
    widgets: [],
    commissioni: 0,
  },
  latest_updates: {
    loading: true,
    error: null,
    latestUpdates: [],
    pages: 1,
    page: 1,
  },
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    getDashboardRejected: (state, { payload }) => {
      state.dashboard.loading = false;
      state.dashboard.error = payload;
    },
    getDashboardSuccess: (state, { payload }) => {
      state.dashboard = {
        ...state.dashboard,
        loading: false,
        searchResults: [],
        ...payload,
      };
    },
    getDashboardReset: (state) => {
      state.dashboard = { ...initialState.dashboard };
    },
    listCustomersSuccess: (state, { payload }) => {
      state.dashboard.searchResults = payload;
    },
    setSearchResults: (state, { payload }) => {
      state.dashboard.searchResults = payload;
    },
    getAgendsByWeekPending: (state) => {
      state.dashboard.agends_loading = true;
      state.dashboard.agends = [];
    },
    changeAgendsView: (state, { payload }) => {
      state.dashboard.agends_view = payload;
    },
    changeNavigationDate: (state, { payload }) => {
      state.dashboard.agends_date = payload;
    },
    getAgendsByWeekSuccess: (state, { payload }) => {
      state.dashboard.agends_loading = false;
      state.dashboard.agends = payload;
    },

    getLatestUpdatesRejected: (state, { payload }) => {
      state.latest_updates.loading = false;
      state.latest_updates.error = payload;
    },
    getLatestUpdatesSuccess: (
      state,
      { payload: { page, pages, latest_updates } },
    ) => {
      state.latest_updates.loading = false;
      state.latest_updates.error = null;
      state.latest_updates.page = page;
      state.latest_updates.pages = pages;
      state.latest_updates.latestUpdates = latest_updates;
    },
    getLatestUpdatesReset: (state) => {
      state.latest_updates = { ...initialState.latest_updates };
    },
  },
});

export default callerCreator(dashboardSlice.actions);
