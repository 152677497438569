import React from 'react';
import { RiskButton, RiskButtonRemove } from '../Cards/ProfessionInfoCard.styles';
import { updateGuaranteesAutoAction, updateGuaranteesMotoAction } from '../../../../features/insuranceRequests/insuranceRequestsActions';
import { confirmAlert } from '../../../utils/alerts';

function ButtonComponentLogic({
  type, processingQuote, tutelaLegaleQuote, rivalsaQuote, cristalliQuote, infortuniConducenteQuote, assistenzaStradaleQuote,
  removeItemFromCart, requestInfo,
}) {
  const addGuarantees = (coverages) => {
    if (processingQuote.product_id === 2) {
      updateGuaranteesMotoAction(requestInfo.request_token, coverages, processingQuote.id);
    } else {
      updateGuaranteesAutoAction(requestInfo.request_token, coverages, processingQuote.id);
    }
  };

  const updateCoverages = (coverageSlug) => {
    const cov = processingQuote.guarantees.find((c) => c.guarantees_slug === coverageSlug);
    const coverages = [];
    if (cov === undefined) {
      processingQuote.guarantees.map((c) => coverages.push(c.guarantees_slug));
      coverages.push(coverageSlug);
    } else {
      processingQuote.guarantees.filter((cv) => cv.guarantees_slug !== coverageSlug).map((c) => coverages.push(c.guarantees_slug));
    }

    addGuarantees(coverages);
  };

  const removeGuarantee = (coverageSlug) => {
    confirmAlert('Sei Sicuro Di Rimuovere Questo Rischio?', () => updateCoverages(coverageSlug));
  };

  return (
    <div className="d-flex justify-content-end ms-auto gap-2">
      {(type.id === 'res_civile' && processingQuote !== null) && <RiskButtonRemove className="btn btn-danger" onClick={() => removeItemFromCart(processingQuote.id)}>Rimuovi</RiskButtonRemove>}

      {(type.id === 'tutela_legale' && tutelaLegaleQuote !== null) ? <RiskButtonRemove className="btn btn-danger" onClick={processingQuote.company_id === 2 ? () => removeGuarantee(type.id) : () => removeItemFromCart(tutelaLegaleQuote.id)}>Rimuovi</RiskButtonRemove> : type.id === 'tutela_legale' && tutelaLegaleQuote === null && processingQuote !== null ? <RiskButton className="btn btn-success" data-bs-toggle="modal" data-bs-target={`#${type.id}`}>Aggiungi</RiskButton> : ''}

      {(type.id === 'assistenza_stradale' && assistenzaStradaleQuote !== null) ? <RiskButtonRemove className="btn btn-danger" onClick={processingQuote.company_id === 2 ? () => removeGuarantee(type.id) : () => removeItemFromCart(assistenzaStradaleQuote.id)}>Rimuovi</RiskButtonRemove> : type.id === 'assistenza_stradale' && assistenzaStradaleQuote === null && processingQuote !== null ? <RiskButton className="btn btn-success" data-bs-toggle="modal" data-bs-target={`#${type.id}`}>Aggiungi</RiskButton> : ''}

      {(type.id === 'infortuni_conducente' && infortuniConducenteQuote !== null) ? <RiskButtonRemove className="btn btn-danger" onClick={processingQuote.company_id === 2 ? () => removeGuarantee(type.id) : () => removeItemFromCart(infortuniConducenteQuote.id)}>Rimuovi</RiskButtonRemove> : type.id === 'infortuni_conducente' && infortuniConducenteQuote === null && processingQuote !== null ? <RiskButton className="btn btn-success" data-bs-toggle="modal" data-bs-target={`#${type.id}`}>Aggiungi</RiskButton> : ''}

      {(type.id === 'cristalli' && cristalliQuote !== null) ? <RiskButtonRemove className="btn btn-danger" onClick={processingQuote.company_id === 2 ? () => removeGuarantee(type.id) : () => removeItemFromCart(cristalliQuote.id)}>Rimuovi</RiskButtonRemove> : type.id === 'cristalli' && cristalliQuote === null && processingQuote !== null ? <RiskButton className="btn btn-success" data-bs-toggle="modal" data-bs-target={`#${type.id}`}>Aggiungi</RiskButton> : ''}

      {(type.id === 'rivalsa' && rivalsaQuote !== null) ? <RiskButtonRemove className="btn btn-danger" onClick={processingQuote.company_id === 2 ? () => removeGuarantee('protezione_rivalse') : () => removeItemFromCart(rivalsaQuote.id)}>Rimuovi</RiskButtonRemove> : type.id === 'rivalsa' && rivalsaQuote === null && processingQuote !== null ? <RiskButton className="btn btn-success" data-bs-toggle="modal" data-bs-target={`#${type.id}`}>Aggiungi</RiskButton> : ''}

      {(requestInfo.processing_quotation_id === null && type.id !== 'res_civile') && <div className="alert alert-warning py-2 px-2">Seleziona Prima La Responsibilita Civile</div>}

      {type.id === 'res_civile' && <RiskButton className="btn btn-success" data-bs-toggle="modal" data-bs-target={`#${type.id}`}>Aggiungi</RiskButton>}
    </div>
  );
}

export default ButtonComponentLogic;
