import { createSlice } from '@reduxjs/toolkit';
import { callerCreator } from '../callerCreator';

const initialState = {
  // ? What we need
  widgets: [],
  loading: true,
  error: null,
};

export const widgetsSlice = createSlice({
  name: 'widgets',
  initialState,
  reducers: {
    getWidgetsPending: (state) => {
      state.loading = true;
      state.error = null;
    },
    getWidgetsRejected: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    getWidgetsSuccess: (state, { payload }) => {
      state.loading = false;
      state.widgets = payload.widgets;
    },
  },
});

export default callerCreator(widgetsSlice.actions);
