import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import UploadInput from '../../common/components/UploadInput';
import FileWrapper from '../../common/components/FileWrapper';

import { OptionModalStyles } from './OptionModal.styles';
import { addNewModification } from '../../features/afterSale/afterSaleActions';
import { FormInputTextarea } from '../../common/formInputs/FormInputTextarea';

const schema = yup.object({
  file: yup.object().nullable(),
  note: yup.string().required('Il campo è obbligatorio'),
});

const asInputOptions = {
  shouldDirty: true,
  shouldTouch: true,
  shouldValidate: true,
};

export function Modification({ quotationId, selectOption, location }) {
  const isOpenedByCommand = location.state !== undefined && location.state?.type === 'command'
    && location.state.data?.page === 'modification';

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    setValue,
    watch,
    reset: resetForm,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    values: isOpenedByCommand ? {
      file: null,
      note: location.state.data.note ?? '',
    } : {
      file: null,
      note: '',
    },
  });

  const fileInputHandler = (files) => {
    const file = files[0];
    let baseURL = '';
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const fileType = file.type;
    reader.onload = () => {
      baseURL = reader.result;
      setValue('file', {
        original_filename: file.name,
        content: baseURL.replace(`data:${fileType};base64,`, ''),
      }, asInputOptions);
    };
  };

  const resetEverything = () => {
    resetForm();
    selectOption(null);
  };

  const submitData = (formData) => {
    addNewModification(quotationId, formData, resetEverything);
  };

  const removeFileHandler = () => {
    setValue('file', null, asInputOptions);
  };

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  const answers = watch();

  return (
    <OptionModalStyles>
      <div className="option-modal-content">
        <h1 className="option-modal-title">Modifica dati non tariffari</h1>
        <form onSubmit={handleSubmit(submitData)} className="report-form-container">
          <div className="row">
            <FormInputTextarea
              placeholder="Descrivi cosa hai bisogno di modificare e  il sistema concede possibilità di caricamento documentale da parte dell'intermediario"
              label="Note"
              registration={register('note')}
              rows="3"
              error={errors.note?.message}
              valid={isValid('note')}
            />
          </div>
          <div className="flex-column mt-3 align-items-center justify-content-center gap-2">
            <UploadInput
              uploadHandler={fileInputHandler}
              text={
                answers.file !== null
                  ? 'Puoi sostituire il documento'
                  : 'Puoi caricare un documento'
              }
              invalid={false}
              className="mt-3 pl-4"
            />
            <div className="general-input-error">
              {errors.file?.message}
            </div>

            <h6 className="mt-3" hidden={answers.file === null}>
              Documento caricato
            </h6>

            {answers.file !== null
              && (
                <FileWrapper
                  className="wide-file-wrapper"
                  files={[answers.file]}
                  removeFileAction={removeFileHandler}
                />
              )}

            <div className="option-modal-actions">
              <button
                className="option-modal-button option-modal-button-close"
                onClick={() => selectOption(null)}
              >
                Chiudi
              </button>
              <button
                type="submit"
                className="option-modal-button option-modal-button-submit"
              >
                Invia
              </button>
            </div>
          </div>
        </form>
      </div>
    </OptionModalStyles>
  );
}
