import styled from 'styled-components';

export const CommercialQuestionnaireStyles = styled.div`
  .accordion .accordion-item .accordion-button:focus{
        box-shadow: 0 0 0 0.25rem rgba(0 , 166 , 81, 0.25);
        border-color: #00A651;
    }
    .accordion .accordion-item .accordion-button:not(.collapsed){
       color: #00A651;
       background-color: rgba(0 , 166 , 81, 0.1);
    }

   
`;

export const SkipQuestionarioStyles = styled.div`
  width: 50%;


  @media (max-width: 1200px) {
    width: 100%;
  }`;

export const SkipQuestionario = styled.div`
  border-radius: 30px;
  padding: 50px 30px 50px 30px;
  border: 2px solid #dbdde6;
  background: #fff;
  height: 100%;

  p{
    font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  }

  @media (max-width: 1200px) {
    padding: 10px 15px;
  }`;
