import { configureStore } from '@reduxjs/toolkit';

import { userSlice } from './user/userSlice';
import { utilitiesSlice } from './utilities/utilitiesSlice';
import { threadsSlice } from './threads/threadsSlice';
import { accidentsSlice } from './accidents/accidentsSlice';
import { dashboardSlice } from './dashboard/dashboardSlice';
import { customersSlice } from './customers/customersSlice';
import { insuranceRequestsSlice } from './insuranceRequests/insuranceRequestsSlice';
import { quotationsSlice } from './quotations/quotationsSlice';
import { formsSlice } from './forms/formsSlice';
import { purchasesSlice } from './purchases/purchasesSlice';
import { ticketsSlice } from './tickets/ticketsSlice';
import { networkAnalisysSlice } from './networkAnalisys/networkAnalisysSlice';
import { speechSlice } from './speech/speechSlice';
import { activeCustomersSlice } from './activeCustomer/activeCustomerSlice';
import { infodriveSlice } from './infodrive/infodriveSlice';
import { brokersSlice } from './brokers/brokersSlice';
import { afterSaleSlice } from './afterSale/afterSaleSlice';
import { formsBackofficeSlice } from './formsBackoffice/formsBackofficeSlice';
import { backofficeResultsSlice } from './backofficeResults/backofficeResultsSlice';
import { authSlice } from './auth/authSlice';
import { remindersSlice } from './reminders/remindersSlice';
import { commonCustomersSlice } from './commonCustomers/commonCustomersSlice';
import { widgetsSlice } from './widgets/widgetsSlice';
import { commercialReportSlice } from './commercialReport/commercialReportSlice';
import { platformChatSlice } from './platformChat/platformChatSlice';

export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    utilities: utilitiesSlice.reducer,
    threads: threadsSlice.reducer,
    accidents: accidentsSlice.reducer,
    dashboard: dashboardSlice.reducer,
    customers: customersSlice.reducer,
    insuranceRequests: insuranceRequestsSlice.reducer,
    quotations: quotationsSlice.reducer,
    forms: formsSlice.reducer,
    purchases: purchasesSlice.reducer,
    tickets: ticketsSlice.reducer,
    analisys: networkAnalisysSlice.reducer,
    speech: speechSlice.reducer,
    activeCustomers: activeCustomersSlice.reducer,
    infodrive: infodriveSlice.reducer,
    brokers: brokersSlice.reducer,
    afterSale: afterSaleSlice.reducer,
    formsBackoffice: formsBackofficeSlice.reducer,
    backofficeResults: backofficeResultsSlice.reducer,
    auth: authSlice.reducer,
    reminders: remindersSlice.reducer,
    commonCustomers: commonCustomersSlice.reducer,
    widgets: widgetsSlice.reducer,
    commercialReport: commercialReportSlice.reducer,
    platformChat: platformChatSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});
