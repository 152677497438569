export const providersList = [
  {
    value: 1,
    label: 'Avvocato',
  },
  {
    value: 2,
    label: 'Medico',
  },
  {
    value: 3,
    label: 'Carrozziere',
  },
  {
    value: 4,
    label: 'Meccanico',
  },
  {
    value: 5,
    label: 'Soccorso',
  },
  {
    value: 6,
    label: 'Stradale',
  },
  {
    value: 7,
    label: 'Perito',
  },
];
