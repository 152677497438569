import styled from 'styled-components';

export const TutorialStyles = styled.div`
    .border-custom {
        border: 1px solid #dedede;
    }

    .play-btn {
        position: absolute;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
        z-index: 999;
    }

    .pagination li {
        border-bottom: none;
    }

    .active>.page-link.green-stl, .page-link.active.green-stl {
        z-index: 3;
        color: #ffffff;
        background-color: #00A651;
        border-color: #00A651;
    }

    .page-link.green-stl {
        color: #00A651;
    }
`;
