import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FormInputDate } from '../../common/formInputs/FormInputDate';
import { RemindersForm } from './Reminders.styles';
import { FormInputText } from '../../common/formInputs/FormInputText';
import { FormInputTextarea } from '../../common/formInputs/FormInputTextarea';
import { storeReminder } from '../../features/reminders/remindersActions';
import { generalAlertError, generalAlertSuccessToast } from '../../common/utils/alerts';
import { flagResponse } from '../../common/constants';
import { FormInputRadio } from '../../common/formInputs/FormInputRadio';
import managementAxe from '../../services/axios-client/managementServisAxeClient';
import { useDebouncedEffect } from '../../common/utils/useDebouncedEffect';

export function CreateReminder() {
  const navigate = useNavigate();
  const schema = yup.object({
    date: yup
      .date()
      .typeError('Il campo è obbligatorio. Seleziona data!')
      .required('Il campo è obbligatorio. Seleziona data!')
      .min(new Date(moment().subtract(1, 'day').format('YYYY-MM-DD')), 'La data è nel passato'),
    title: yup
      .string()
      .required('Il campo è obbligatorio. Inserisci titolo!')
      .max(50, 'Il titolo non deve essere piu di 50 caratteri'),
    description: yup.string().max(300, 'Il descrizione non deve essere piu di 300 caratteri'),
  });

  const [useLink, setUseLink] = useState(0);
  const [searchClient, setSearchClient] = useState('');
  const [clientsResults, setClientsResults] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [insuranceOrQuotation, setInsuranceOrQuotation] = useState('');
  const [quotationsResults, setQuotationsResults] = useState([]);
  const [insurancesResults, setInsurancesResults] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    getValues,
    setValue,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: {
      date: null,
      title: '',
      description: '',
      quotation_id: null,
      request_id: null,
    },
  });

  const data = watch();

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  const searchAction = async (value) => {
    if (value.length >= 3) {
      const response = await managementAxe.get('/broker/dashboard/reminders/customers/search', {
        params: {
          search: value,
        },
      });

      if (response.status === 200) {
        setClientsResults(response.data.data);
      }
    } else {
      setClientsResults([]);
    }
  };

  const onSearchChange = async (value) => {
    setSearchClient(value);
  };

  const getCustomerData = async (customer) => {
    if (customer.gender === 'G') {
      setSearchClient(customer.business_name);
    } else {
      setSearchClient(`${customer.name} ${customer.surname}`);
    }

    setClientsResults([]);
    setSelectedClient(customer);
  };

  const resetSelection = () => {
    setUseLink(0);
    setSearchClient('');
    setClientsResults([]);
    setSelectedClient(null);
    setInsuranceOrQuotation('');
    setQuotationsResults([]);
    setInsurancesResults([]);
  };

  const getQuotationInfoInline = (q) => `${q.product.name} (${q.company_id !== null ? q.company.name : q.preventivas_company.company_name}) | ${q.amount} € | ${q.quotation_status.name}`;

  const selectQuotation = (quotationId) => {
    setValue('quotation_id', quotationId);
    setValue('request_id', null);
    resetSelection();
  };

  const getInsuranceInfoInline = (q) => `${q.product.name} | Creato a: ${moment(q.created_at).format('DD.MM.YYYY HH:mm')}`;

  const selectInsurance = (insuranceId) => {
    setValue('request_id', insuranceId);
    setValue('quotation_id', null);
    resetSelection();
  };

  const submitData = () => {
    const data = getValues();

    if (useLink === 1) {
      if (data.quotation_id === null && data.request_id === null) {
        generalAlertError('Devi selezionare almeno una polizza o un preventivo');
        return;
      }
    }

    storeReminder(data, () => {
      navigate('/');
      generalAlertSuccessToast('Memo creata');
    });
  };

  useDebouncedEffect(() => searchAction(searchClient), [searchClient], 400);

  useEffect(() => {
    const run = async () => {
      if (insuranceOrQuotation !== '') {
        const params = {};

        if (selectedClient.type === 'customer') {
          params.customer_id = selectedClient.id;
        } else {
          params.active_customer_id = selectedClient.id;
        }

        setQuotationsResults([]);
        setInsurancesResults([]);

        if (insuranceOrQuotation === 'quotations') {
          const response = await managementAxe.get('/broker/dashboard/reminders/customers/quotations', {
            params,
          });

          setQuotationsResults(response.data.quotations);
        } else {
          const response = await managementAxe.get('/broker/dashboard/reminders/customers/insurance-requests', {
            params,
          });

          setInsurancesResults(response.data.insurance_requests);
        }
      }
    };
    run();
  }, [insuranceOrQuotation]);

  useEffect(() => {
    if (useLink === 0) {
      resetSelection();
    }
  }, [useLink]);

  return (
    <RemindersForm onSubmit={handleSubmit(submitData)} noValidate>
      <h1>Crea Memo</h1>
      <FormInputDate
        label="Data"
        registration={register('date')}
        minDate={moment().format('YYYY-MM-DD')}
        error={errors.date?.message}
        valid={isValid('date')}
      />
      <FormInputText
        placeholder="Titolo"
        label="Titolo"
        registration={register('title')}
        error={errors.title?.message}
        valid={isValid('title')}
        max={50}
      />
      <FormInputTextarea
        placeholder="Descrizione"
        label="Descrizione"
        registration={register('description')}
        error={errors.description?.message}
        valid={isValid('description')}
        max={300}
        rows={4}
      />

      {data.quotation_id !== null && (
      <div className="d-flex align-items-center gap-2">
        <div className="px-3 py-2 bg-success text-white rounded text-center w-100">Polizza Selezionata</div>
        <div className="btn btn-danger" onClick={() => setValue('quotation_id', null)}>Rimuovi</div>
      </div>
      )}

      {data.request_id !== null && (
      <div className="d-flex align-items-center gap-2">
        <div className="px-3 py-2 bg-success text-white rounded text-center w-100">Preventivo Selezionato</div>
        <div className="btn btn-danger" onClick={() => setValue('request_id', null)}>Rimuovi</div>
      </div>
      )}

      <FormInputRadio
        name="use_link"
        label={data.quotation_id !== null || data.request_id !== null ? 'Vuoi linkare un altra pratica?' : 'Vuoi linkare una pratica?'}
        options={flagResponse}
        onChange={setUseLink}
        value={useLink}
      />

      {useLink === 1 && (
      <>
        <div className="d-flex align-items-start justify-content-center gap-2">
          <div className="w-100">
            <FormInputText
              label="Nome cliente"
              paragraph="Deve contenere almeno 3 caratteri"
              onChange={(value) => onSearchChange(value)}
              value={searchClient}
            />
            {clientsResults.length > 0 && (
              <div>
                <ul className="list-group mb-3">
                  {clientsResults.map((customer, index) => (
                    <li
                      className="list-group-item border-success"
                      key={index}
                      style={{ cursor: 'pointer' }}
                      onClick={() => getCustomerData(customer)}
                    >
                      <>
                        {customer.gender !== 'G' ? `${customer.name} ${customer.surname} (${customer.fiscal_code})` : customer.business_name}
                        {' '}
                        -
                        {' '}
                        {customer.type === 'customer' ? 'Non Attivo' : 'Attivo'}
                      </>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {selectedClient !== null && (
            <>
              <FormInputRadio
                label="Vuoi collegare il memo con una polizza o uno preventivo?"
                name="quotations_or_insurance_requests"
                onChange={setInsuranceOrQuotation}
                value={insuranceOrQuotation}
                options={[
                  { label: 'Polizza', value: 'quotations' },
                  { label: 'Preventivo', value: 'insurance_requests' },
                ]}
              />
            </>
            )}

            {quotationsResults.length > 0 && (
            <div>
              <ul className="list-group mb-3">
                {quotationsResults.map((quotation, index) => (
                  <li
                    className="list-group-item border-success"
                    key={index}
                    style={{ cursor: 'pointer' }}
                    onClick={() => selectQuotation(quotation.id)}
                  >
                    <>
                      {getQuotationInfoInline(quotation)}
                    </>
                  </li>
                ))}
              </ul>
            </div>
            )}

            {insurancesResults.length > 0 && (
            <div>
              <ul className="list-group mb-3">
                {insurancesResults.map((insurance, index) => (
                  <li
                    className="list-group-item border-success"
                    key={index}
                    style={{ cursor: 'pointer' }}
                    onClick={() => selectInsurance(insurance.id)}
                  >
                    <>
                      {getInsuranceInfoInline(insurance)}
                    </>
                  </li>
                ))}
              </ul>
            </div>
            )}
          </div>
        </div>
      </>
      )}

      <button type="submit" className="submit-button">
        Crea
      </button>
    </RemindersForm>
  );
}
