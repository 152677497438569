import React from 'react';
import RiskCardPrimaResult from '../Cards/RiskCardPrimaResult';

function ProcessingTutelaPrimaQuote({ processingQuote, type, createIdForModal }) {
  return (
    <>
      {processingQuote.guarantees.find((g) => g.guarantees_slug === type.id) !== undefined && (
        processingQuote.quotation_data_prima.quotation_data_prima_guarantees.filter((g) => g.slug === type.id).map((quote, key) => (
          <div className="col-12 mb-2" key={key}>
            <RiskCardPrimaResult
              quote={quote}
              processingQuote={processingQuote}
            />
          </div>
        ))
      )}

      {(processingQuote.guarantees.find((g) => g.guarantees_slug === type.id) === undefined) && <div data-bs-toggle="modal" data-bs-target={`#${createIdForModal()}`} style={{ cursor: 'pointer' }} className="alert alert-secondary">Seleziona Quotazione!</div>}
    </>
  );
}

export default ProcessingTutelaPrimaQuote;
