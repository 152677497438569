import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageLoading from '../../../common/components/elements/PageLoading';
import { metlifeMenoMaleGold } from '../../../common/constants/index';
import { addInfortuniExtension, getMetlifeQuotesData, resetInfortuniQuotes } from '../../../features/backofficeResults/backofficeResultsActions';
import { Layout } from '../components/Layout/Layout';
import GuaranteesModalInfortuni from '../../../common/components/redesignComponents/Modal/GuaranteesModalInfortuni';
import { InfortuniQuotesStyles } from '../../MetlifeQuote.styles';
import InfortuniCardInfo from '../../../common/components/redesignComponents/Cards/InfortuniCardInfo';
import GuaranteesDefaultState from '../../../common/components/redesignComponents/common/GuaranteesDefaultState';
import { EstimateContainer } from '../../../common/components/redesignComponents/common/Redesign.styles';
import MetlifeCard from './cards/MetlifeCard';

function BackOfficeInfortuniQuotes() {
  const { requestToken } = useParams();
  const navigate = useNavigate();
  const { estensioni } = metlifeMenoMaleGold;
  const [openModal, setOpenModal] = useState(false);

  const state = useSelector((store) => store.backofficeResults.metlife_quotes);
  const {
    quote, customer, extensions, loading, quoteId, insuranceRequest,
  } = state;

  const addExtensions = () => {
    addInfortuniExtension(quote.request_id, extensions);
  };

  const modifyData = () => {
    navigate(`/backoffice/quote/${requestToken}/infortuni`);
  };

  useEffect(() => {
    getMetlifeQuotesData(requestToken);

    return () => {
      resetInfortuniQuotes();
    };
  }, []);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <Layout>
      <div style={{
        width: '100%',
        maxWidth: '1400px',
        margin: 'auto',
        padding: '10px',
      }}
      >
        {openModal && <GuaranteesModalInfortuni setOpenModal={setOpenModal} quote={quote} extensions={estensioni} addExtensions={addExtensions} />}
        <InfortuniQuotesStyles>
          <div className="resultsPage">
            <div className="row">
              <div className="col-12 col-xxl-4 mb-2 mb-xxl-0">
                <InfortuniCardInfo insuranceRequest={insuranceRequest} modificaDati={modifyData} customer={customer} />
              </div>
              <div className="col-12 col-xxl-8">
                <GuaranteesDefaultState setShowGuaranteesModal={setOpenModal} quotes={[{ ...quote, company_id: 5 }]} />
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <EstimateContainer>
                  <h4>
                    Preventivi assicurazione
                    {' '}
                    <b>infortuni</b>
                  </h4>
                  <p>
                    Inizio Copertura:
                    {' '}
                    {new Date(insuranceRequest.policy_effective_date).toLocaleDateString('it-IT', { year: 'numeric', month: 'numeric', day: 'numeric' })}
                  </p>
                </EstimateContainer>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-12">
                <MetlifeCard quote={quote} setOpenModal={setOpenModal} quoteId={quoteId} />
              </div>
            </div>
          </div>
        </InfortuniQuotesStyles>
      </div>

    </Layout>
  );
}

export default BackOfficeInfortuniQuotes;
