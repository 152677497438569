import { createSlice } from '@reduxjs/toolkit';
import { callerCreator } from '../callerCreator';

const initialState = {
  threads_list: {
    threads: [],
    quotations: [],
    loading: true,
    error: null,
    isSearching: false,
    total_pages: 1,
    page_number: 1,
  },
  thread_single: {
    thread: null,
    messages: [],
    loading: true,
    error: null,
  },
  thread_reply: {
    loading: false,
    error: null,
  },
};

export const threadsSlice = createSlice({
  name: 'threads',
  initialState,
  reducers: {
    // ? Get All Threads Broker
    getThreadsPending: (state) => {
      state.threads_list.loading = true;
      state.threads_list.error = null;
    },
    getThreadsReject: (state, { payload }) => {
      state.threads_list.loading = false;
      state.threads_list.error = payload;
    },
    getThreadsSuccess: (state, { payload }) => {
      state.threads_list.loading = false;
      state.threads_list.threads = payload.data;
      state.threads_list.total_pages = payload.total_pages;
      state.threads_list.page_number = payload.current_page;
    },
    getThreadsQuotations: (state, { payload }) => {
      state.threads_list.quotations = payload;
    },
    // ? Search Threads
    searchThreadsPending: (state) => {
      state.threads_list.isSearching = true;
      state.threads_list.error = null;
    },
    searchThreadsReject: (state, { payload }) => {
      state.threads_list.isSearching = false;
      state.threads_list.error = payload;
    },
    searchThreadsSuccess: (state, { payload }) => {
      state.threads_list.isSearching = false;
      state.threads_list.threads = payload.data;
      state.threads_list.total_pages = payload.total_pages;
      state.threads_list.page_number = payload.current_page;
    },
    // ? Get Single Thread
    getThreadPending: (state) => {
      state.thread_single.loading = true;
      state.thread_single.error = null;
    },
    getThreadReject: (state, { payload }) => {
      state.thread_single.loading = false;
      state.thread_single.error = payload;
    },
    getThreadSuccess: (state, { payload }) => {
      state.thread_single.loading = false;
      state.thread_single.thread = payload;
      state.thread_single.messages = payload.messages;
    },
    // ? Reply To Thread
    replyThreadPending: (state) => {
      state.thread_reply.loading = true;
      state.thread_reply.error = null;
    },
    replyThreadReject: (state, { payload }) => {
      state.thread_reply.loading = false;
      state.thread_reply.error = payload;
    },
    replyThreadSuccess: (state) => {
      state.thread_reply.loading = false;
    },
    // ? Thread To Reply
    replyToThreadPending: (state) => {
      state.thread_reply.loading = true;
      state.thread_reply.error = null;
    },
    replyToThreadReject: (state, { payload }) => {
      state.thread_reply.loading = false;
      state.thread_reply.error = payload;
    },
    replyToThreadSuccess: (state) => {
      state.thread_reply.loading = false;
    },
  },
});

export default callerCreator(threadsSlice.actions);
