import * as React from 'react';
import { FormGroup, Label } from './styles/common';
import { Paragraph } from './FormInputDate.styles';

export function FormInputDate({
  label, paragraph, registration, valid, error, minDate, maxDate, value, onChange,
}) {
  if (registration === undefined) {
    const handleChange = (e) => {
      onChange(e.target.value);
    };

    return (
      <FormGroup>
        {label !== undefined
                    && <Label>{label}</Label>}
        {paragraph !== undefined
                    && <Paragraph>{paragraph}</Paragraph>}
        <input className={`form-control text-center date ${error ? 'is-invalid' : ''} ${valid ? 'is-valid' : ''}`} type="date" lang="it-IT" min={minDate} max={maxDate} value={value} onChange={handleChange} />
        <div className="invalid-feedback">
          {error}
        </div>
      </FormGroup>
    );
  }
  return (
    <FormGroup>
      {label !== undefined
                && <Label>{label}</Label>}
      {paragraph !== undefined
                && <Paragraph>{paragraph}</Paragraph>}
      <input className={`form-control text-center date ${error ? 'is-invalid' : ''} ${valid ? 'is-valid' : ''}`} type="date" lang="it-IT" min={minDate} max={maxDate} {...registration} />
      <div className="invalid-feedback">
        {error}
      </div>
    </FormGroup>
  );
}
