import styled from 'styled-components';

export const FormContainer = styled.form`
    margin: auto;
    width: 100%;
    max-width: 600px;
    border: 1px solid var(--greenia-color);
    border-radius: 10px;
    overflow-x: hidden;

    .title {
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
    }

    .form-inputs {
        margin: auto;
        padding: 0 20px;
        width: 100%;
        max-width: 550px;
    }
`;
