import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { updateStatePlatformChat } from '../features/platformChat/platformChatActions';

// Wrapper component to handle route parameter extraction and storage
const ComponentRouteWrapper = ({ location, returnComponent }) => {
  const { requestToken, vehicle } = useParams();

  useEffect(() => {
    let location_path = location;
    if (vehicle !== undefined) {
      location_path = `${location_path}_${vehicle}`;
    }
    const data = {
      request_token: requestToken,
      location: location_path,
    };

    updateStatePlatformChat(data);
    // Save the requestToken to the store
  }, [location, requestToken]);

  // Render the main component
  return returnComponent;
};

export default ComponentRouteWrapper;
