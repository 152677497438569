import styled from 'styled-components';

export const InsuredPersonStyles = styled.div`
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 1px solid gray;
  position: relative;
  padding-top: 40px;
  &::before {
    content: "Inserisci i dati della persona che viaggia con te";
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 10px;
    background-color: #57a851;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    color: #ffffff;
  }
`;
