import React, { useState } from 'react';

function Toast({ message }) {
  const [show, setShow] = useState(true);

  setTimeout(() => {
    setShow(false);
  }, 3000);

  return (
    show && (
    <div className={`alert alert-${message.statusCode >= 400 ? 'danger' : 'success'} position-fixed`} style={{ bottom: '5px', left: '5px', zIndex: 99 }}>
      {message.message}
    </div>
    )
  );
}

export default Toast;
