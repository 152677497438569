import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ApexCharts from 'apexcharts';
import PageLoading from '../common/components/elements/PageLoading';
import {
  loadCommercialQuotes,
  resetCommercialQuotes,
} from '../features/insuranceRequests/insuranceRequestsActions';
import CardItem from '../common/components/CardItem';
import CommercialModal from '../common/components/commercial/CommercialModal';
import { ModificaDatiButton } from '../common/components/redesignComponents/common/Redesign.styles';
import editIcon from '../assets/redesign_icons/edit-icon.svg';

export default function RcCommercialQuotes() {
  const { requestToken } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [modelKey, setModalKey] = useState('legal_protection');
  const [chartElement, setChartElement] = useState(null);
  const [legalProtection, setLegalProtection] = useState(0);
  const [income, setIncome] = useState(0);
  const [civilLiability, setCivilLiability] = useState(0);
  const [accidents, setAccidents] = useState(0);
  const [damageToTrust, setDamageToTrust] = useState(0);
  const [chartSeries, setChartSeries] = useState([0, 0, 0, 0, 0, 0]);
  const navigate = useNavigate();

  const [options, setOptions] = useState({
    series: [
      {
        name: 'Value',
        data: [0, 50, 10, 10, 0, 0],
      },
    ],
    chart: {
      height: 450,
      type: 'radar',
    },
    title: {
      text: '',
    },
    xaxis: {
      categories: [
        'Tutele legale',
        'Redditi',
        'Responsabilita civile',
        'Infortuni',
        'Danni alla fiducia',
        'Contenuto mezzi',
      ],
    },
    yaxis: {
      labels: {
        formatter() {
          return '';
        },
      },
    },
    plotOptions: {
      radar: {
        polygons: {
          strokeColor: '#e8e8e8',
          fill: {
            colors: ['#f8f8f8', '#fff'],
          },
        },
      },
    },
    colors: ['#00a651'],
  });

  const state = useSelector(
    (store) => store.insuranceRequests.commercial_quotes,
  );
  const { loading } = state;

  useEffect(() => {
    loadCommercialQuotes(requestToken);
    setTimeout(() => {
      const chart = new ApexCharts(document.querySelector('#chart'), options);
      chart.render();
      setChartElement(chart);
    }, 500);

    return () => {
      resetCommercialQuotes();
    };
  }, []);

  useEffect(() => {
    setOptions({
      ...options,
      series: [
        {
          name: 'Value',
          data: chartSeries,
        },
      ],
    });
  }, [chartSeries]);

  useEffect(() => {
    if (chartElement) {
      chartElement.destroy();
      const chart = new ApexCharts(document.querySelector('#chart'), options);
      chart.render();
      setChartElement(chart);
    }
  }, [options]);

  const handleCardItemAction = (key) => {
    setModalKey(key);
    setOpenModal(true);
  };

  const modificaDati = () => {
    navigate('/quote/commercial');
  };

  const onModalSave = (key) => {
    if (key === 'legal_protection') {
      setLegalProtection(10);
    }

    if (key === 'income') {
      setIncome(10);
    }

    if (key === 'civil_liability') {
      setCivilLiability(10);
    }

    if (key === 'accidents') {
      setAccidents(10);
    }

    if (key === 'damage_to_trust') {
      setDamageToTrust(10);
    }
  };

  useEffect(() => {
    setChartSeries([legalProtection, income, civilLiability, accidents, damageToTrust, 0]);
  }, [legalProtection, income, civilLiability, accidents, damageToTrust]);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <>
      <div className="pb-5 d-flex justify-content-start">
        <ModificaDatiButton onClick={modificaDati}>
          <p>Modifica Dati</p>
          <img src={editIcon} alt="Modifica Dati" />
        </ModificaDatiButton>
      </div>
      <div className="d-flex align-items-center justify-content-between pb-5 mb-5">
        <div className="col-md-6 col-12">
          <div id="chart" />
        </div>

        <div
          className="col-md-6 col-12 pl-3 d-flex flex-column gap-2 align-items-end"
        >
          <CardItem
            title="Tutele legale"
            action={() => handleCardItemAction('legal_protection')}
          />
          <CardItem
            title="Redditi"
            action={() => handleCardItemAction('income')}
          />
          <CardItem
            title="Responsabilita civile"
            action={() => handleCardItemAction('civil_liability')}
          />
          <CardItem
            title="Infortuni"
            action={() => handleCardItemAction('accidents')}
          />
          <CardItem
            title="Danni alla fiducia"
            action={() => handleCardItemAction('damage_to_trust')}
          />
          {/* <CardItem
            title={"Contenuto mezzi"}
            action={() => handleCardItemAction("media_content")}
          /> */}
          <button
            className="btn btn-primary"
            onClick={() => setChartSeries([1, 1, 1, 1, 1, 1])}
          >
            update chart test
          </button>
        </div>
      </div>

      <div className="d-flex justify-content-end mt-5 pt-5">
        <span className="price margin-right-24">Premio € 7000,00</span>
        <button
          className="btn btn-questionnaire"
          onClick={() => alert('damage_to_trust')}
        >
          Procedi
        </button>
      </div>

      {openModal && (
        <CommercialModal
          setIsOpen={setOpenModal}
          modalKey={modelKey}
          requestToken={requestToken}
          onModalSave={onModalSave}
        />
      )}
    </>
  );
}
