import React from 'react';
import ProfessionCardResult from '../Cards/ProfessionCardResult';

function ProcessingQuoteSelectedProfession({
  processingQuote, profession,
}) {
  return (
    <>
      {processingQuote
        && (
          <div className="col-12 mb-2">
            <ProfessionCardResult processingQuote={processingQuote} profession={profession} quote={processingQuote} />
          </div>
        )}
      {processingQuote === null && <div className="alert alert-secondary">Seleziona Quotazione!</div>}
    </>
  );
}

export default ProcessingQuoteSelectedProfession;
