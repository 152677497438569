import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ComparativeAnalisysStyles } from './ComparativeAnalisys.styles';
import { getComparativeAnalisys, resetComparativeAnalisys } from '../../features/networkAnalisys/networkAnalisysActions';
import PageLoading from '../../common/components/elements/PageLoading';
import { generalAlertError } from '../../common/utils/alerts';

function ComparativeAnalisys() {
  const [search1, setSearch1] = useState('');
  const [search2, setSearch2] = useState('');
  const { comparative_analisys, loading } = useSelector((store) => store.analisys.comparative_analisys);

  const handleSearch1 = (e) => {
    setSearch1(e.target.value);
  };

  const handleSearch2 = (e) => {
    setSearch2(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (search1 === '' || search2 === '') {
      generalAlertError('Numero iscrizione non valida');
      return;
    }
    getComparativeAnalisys(search1, search2);
  };

  useEffect(() => () => {
    resetComparativeAnalisys();
  }, []);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <ComparativeAnalisysStyles>
      <h5 className="mb-4">Analisi comparativa</h5>
      <form
        onSubmit={handleSubmit}
        className="w-100 d-flex align-items-center justify-content-start flex-column flex-md-row"
      >
        <input
          type="text"
          name="search1"
          onChange={handleSearch1}
          value={search1}
          className="search-input form-control mb-2 me-2 mb-lg-0"
          placeholder="Ricerca con numero iscrizione..."
        />
        <input
          type="text"
          name="search2"
          onChange={handleSearch2}
          value={search2}
          className="search-input form-control mb-2 mb-lg-0 me-2"
          placeholder="Ricerca con numero iscrizione..."
        />
        <button type="submit" className="search-btn w-25">
          Cerca
        </button>
        <div className="w-100 d-none d-md-block" />
      </form>
      <p className="fw-bold fst-italic mt-2">Inserisci 2 numeri di iscrizione intermediario Sezione A o B! </p>

      <div className="mt-5">
        <h5 className="h5 mb-2">
          Totale Associati:
          <span className="badge bg-success">{comparative_analisys.length}</span>
        </h5>
        <hr />
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Numero Iscrizione</th>
                <th scope="col">Cognome Nome</th>
                <th scope="col">Stato</th>
                <th scope="col">Data Di Nascita</th>
                <th scope="col">Commune Di Nascita</th>
                <th scope="col">Ragione Sociale</th>
                <th scope="col">Data Iscrizione</th>
              </tr>
            </thead>
            <tbody>
              {comparative_analisys.map((v, index) => (
                <tr key={index}>
                  <td>{v.NUMERO_ISCRIZIONE_RUI}</td>
                  <td>{v.COGNOME_NOME}</td>
                  <td>{v.STATO}</td>
                  <td>{v.DATA_NASCITA}</td>
                  <td>
                    {v.COMUNE_NASCITA !== ''
                      && (
                      <>
                        {v.COMUNE_NASCITA}
                        ,
                        {' '}
                        {v.PROVINCIA_NASCITA}
                      </>
                      )}
                  </td>
                  <td>
                    {v.RAGIONE_SOCIALE}
                  </td>
                  <td>
                    {v.DATA_ISCRIZIONE}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </ComparativeAnalisysStyles>
  );
}

export default ComparativeAnalisys;
