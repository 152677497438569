import { ArticleCardStyles } from './ArticleCard.styles';
import NoImg from '../../assets/images/no_img.JPG';

export default function ArticleCard({ article, className = '' }) {
  return (
    <ArticleCardStyles>
      <div className={`custom-card ${className}`}>
        <div className="custom-card-body custom-card-body--body-only h--latest-news">
          <img src={article.image ? `data:image/png;base64,${article?.image}` : NoImg} alt="" width="100" className="rounded" />
          <div className="latest-news-header">
            <div className="latest-news-title">{article?.category}</div>
          </div>
          <div className="latest-news-description">{article?.label}</div>
          <a href={article?.link} className="btn btn-success mt-2">
            Leggi di piu
          </a>
        </div>
      </div>
    </ArticleCardStyles>
  );
}
