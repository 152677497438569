import styled from 'styled-components';

export const CheckboxLabel = styled.label`
  font-weight: bold;
  color: rgb(100, 100, 100);
  /* margin-left: 6px; */

  @media screen and (max-width: 520px) {
    font-size: 14px;
  }
`;
