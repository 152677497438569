import React from 'react';
import {
  EuropeassistanceCardStyles, GuaranteesListItemResult, GuaranteesListResult, LeftColResult, LogoPriceContainer, PriceContainer,
  RightColResult,
  SelectedGuaranteesContainerResult,
} from './EuropeassistanceCard.styles';
import {
  LogoResultContainer, PriceResult, QuoteInformationResultContainer, QuoteInformationResultItem,
} from '../../../../screens/BackOffice/results/cards/PrimaCard.styles';
import { BACKEND_BASE_URL } from '../../../../config';

function EuropeassistanceCardResult({ quote }) {
  return (
    <EuropeassistanceCardStyles className={quote.is_aquista === 1 ? 'selected' : ''}>
      <LeftColResult>
        <LogoPriceContainer>
          <LogoResultContainer>
            <img src={BACKEND_BASE_URL + quote.company.logo} alt={`${quote.company.name} Logo`} />
          </LogoResultContainer>
          <PriceContainer>
            <PriceResult>
              <h5>
                Prezzo annuo:
              </h5>
              <h2>
                €
                {parseFloat(quote.amount).toFixed(2)}
              </h2>
            </PriceResult>
          </PriceContainer>
        </LogoPriceContainer>
        <QuoteInformationResultContainer>
          <QuoteInformationResultItem>
            <h6>
              Prodotto:
            </h6>
            <p>
              {quote.quotation_data_travel_europeassistance.product_name}
            </p>
          </QuoteInformationResultItem>
          {quote.quotation_data_travel_europeassistance.coverages.map((coverage, index) => (
            <React.Fragment key={index}>
              {(coverage.enabled === 0 && coverage.group_id === null) && (
                <QuoteInformationResultItem>
                  <h6>
                    {coverage.title}
                    :
                  </h6>
                  <p>
                    {coverage.limit_desc}
                  </p>
                </QuoteInformationResultItem>
              )}
              {
                quote.quotation_coverages.map((cov, index) => (
                  coverage.coverage_id === cov.guarantees_slug
                  && (
                    <QuoteInformationResultItem key={index}>
                      <h6>
                        {coverage.title}
                        :
                      </h6>
                      <p>
                        {coverage.limit_desc}
                      </p>
                    </QuoteInformationResultItem>
                  )
                ))
              }
            </React.Fragment>
          ))}
        </QuoteInformationResultContainer>
      </LeftColResult>
      <RightColResult>
        <SelectedGuaranteesContainerResult>
          <h6>
            <b>Garanzie</b>
            {' '}
            Aggiunte
          </h6>
          <GuaranteesListResult>
            {quote.quotation_data_travel_europeassistance.coverages.map((coverage, index) => (
              <React.Fragment key={index}>
                {(coverage.enabled === 0 && coverage.group_id === null)
                  && (
                    <GuaranteesListItemResult>
                      <h6>
                        <span />
                        {' '}
                        {coverage.title}
                      </h6>
                      <p>
                        &euro;
                        {parseFloat(coverage.coverage_amount_gros).toLocaleString('en-US')}
                      </p>
                    </GuaranteesListItemResult>
                  )}
                {
                  quote.quotation_coverages.map((cov, index) => (
                    coverage.coverage_id === cov.guarantees_slug
                    && (
                      <GuaranteesListItemResult key={index}>
                        <h6>
                          <span />
                          {' '}
                          {cov.guarantees_description}
                        </h6>
                        <p>
                          &euro;
                          {parseFloat(cov.amount).toLocaleString('en-US')}
                        </p>
                      </GuaranteesListItemResult>
                    )
                  ))
                }
              </React.Fragment>
            ))}

          </GuaranteesListResult>
        </SelectedGuaranteesContainerResult>
      </RightColResult>
    </EuropeassistanceCardStyles>
  );
}

export default EuropeassistanceCardResult;
