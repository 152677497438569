import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import InfodriveLogo from '../../../assets/images/logos/infodrive_logo.png';
import { infodriveGetQuote, resetInfodriveQuotes } from '../../../features/backofficeResults/backofficeResultsActions';
import PageLoading from '../../../common/components/elements/PageLoading';
import { Layout } from '../components/Layout/Layout';

function BackOfficeInfodriveQuotes() {
  const { requestToken } = useParams();
  const {
    loading, quote, insuranceRequest, failedQuotation,
  } = useSelector((store) => store.backofficeResults.infodrive_quotes);

  useEffect(() => {
    infodriveGetQuote(requestToken);

    return () => {
      resetInfodriveQuotes();
    };
  }, []);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <Layout>
      <div style={{
        width: '100%',
        maxWidth: '1200px',
        margin: 'auto',
        padding: '10px',
      }}
      >
        <div className="row">
          <div className="col-md-12 col-xl-12">
            <div className="d-flex flex-xl-row flex-column align-items-center justify-content-between mb-4">
              <div><h4>Preventivi prodotti Infodrive</h4></div>
              <div className="info-card-rc">
                <p>
                  <b>Contraente:</b>
                  {' '}
                  {insuranceRequest.customer.gender === 'G' ? insuranceRequest.customer.business_name : `${insuranceRequest.customer.name} ${insuranceRequest.customer.surname}`}
                </p>
                <p>
                  <b>E-mail:</b>
                  {' '}
                  {insuranceRequest.customer.email}
                </p>
                <p>
                  <b>Telefono:</b>
                  {' '}
                  {insuranceRequest.customer.phone}
                </p>
                <p>
                  <b>Inizio Copertura:</b>
                  {' '}
                  {new Date(insuranceRequest.policy_effective_date).toLocaleDateString('it-IT', { year: 'numeric', month: 'numeric', day: 'numeric' })}
                </p>

              </div>
            </div>
            <div className="pdate">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M152 64H296V24C296 10.75 306.7 0 320 0C333.3 0 344 10.75 344 24V64H384C419.3 64 448 92.65 448 128V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V128C0 92.65 28.65 64 64 64H104V24C104 10.75 114.7 0 128 0C141.3 0 152 10.75 152 24V64zM48 248H128V192H48V248zM48 296V360H128V296H48zM176 296V360H272V296H176zM320 296V360H400V296H320zM400 192H320V248H400V192zM400 408H320V464H384C392.8 464 400 456.8 400 448V408zM272 408H176V464H272V408zM128 408H48V448C48 456.8 55.16 464 64 464H128V408zM272 192H176V248H272V192z" /></svg>
                {' '}
                Preventivi aggiorni al
                {' '}
                {new Date().toLocaleDateString('it')}
              </span>
            </div>
            {failedQuotation === null && (
              <div className="quote-card row">
                <div className="col-xl-3">
                  <div className="brand-iamge">
                    <img src={InfodriveLogo} alt="Infodrive Logo" className="w-100" />
                  </div>
                </div>
                <div className="col-xl-5">
                  <div className="quote-info">
                    <div className="quote-info">
                      <div className="">
                        <h4 className="h5 mb-0">Informazioni Prodotto</h4>
                        <hr />
                        <div className="qinfo">
                          <span><b>Nome Prodotto:</b></span>
                          <span>{quote.product_name}</span>
                        </div>
                        <div className="qinfo">
                          <span><b>Durata:</b></span>
                          <span>
                            {quote.duration_in_months}
                            {' '}
                            Mesi
                          </span>
                        </div>
                        <div className="qinfo">
                          <span><b>Versione Prodotto:</b></span>
                          <span>{quote.version}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 d-flex justify-content-center align-items-center">
                  <div className="q-price m-0 mt-3">
                    <b>Prezzo Annuo:</b>
                    <br />
                    <span className="a-price">
                      €
                      {parseFloat(quote.total_price).toFixed(2)}
                      {' '}
                      <sub style={{ fontSize: '12px' }}>iva inclusa</sub>
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {failedQuotation !== null && (
          <div className="failed-quotations-block">
            Queste compagnie non hanno risposto con una citazione.
            <br />
            <hr />
            <div className="container ms-0">
              <div className="row">
                <div className="col-3">
                  <img src={InfodriveLogo} alt="Infodrive Logo" />
                </div>
              </div>
            </div>
          </div>
        )}

      </div>
    </Layout>
  );
}

export default BackOfficeInfodriveQuotes;
