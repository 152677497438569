import styled from 'styled-components';

export const EditBrokerStyles = styled.div`
  h1 {
    text-align: center;
  }

  h3 {
    font-size: 16px;
    text-align: center;
  }

  h6 {
    font-size: 14px;
  }

  form {
    margin: auto;
    width: 100%;
    max-width: 1000px;
  }

  .custom-label {
    text-align: center;
    font-weight: bold;
    width: 100%;
    padding: 7px;
    color: #6F7176;
  }

  .submit-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: var(--greenia-color);
    border: none;
    outline: none;
    color: white;
    border-radius: 20px;
  }

  #autocomplete {
    margin: 0 8px;
    margin-bottom: 10px;
    width: calc(100% - 16px);
    padding: 5px 10px;
  }

  .file-uploads-container {
    margin: auto;
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .file-uploads-container > div {
    margin: 5px;
    min-width: 300px;
  }

  .uploaded-documents-container {
    width: 100%;
    max-width: 1000px;
    margin: auto;
  }

  .uploaded-document {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .uploaded-document .filename {
    display: flex;
    align-items: center;
  }

  .uploaded-document .badge {
    margin: 10px;
    background-color: var(--greenia-color);
  }

  .clickable {
    cursor: pointer;
  }
`;

export const MapContainerStyles = styled.div`
  #map {
    height: 250px;
    width: 100%;
  }

  .pac-container {
    z-index: 10000;
  }
`;
