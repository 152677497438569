import styled from 'styled-components';

export const PaymentTypePeriodStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  button {
    background-color: transparent;
    color: rgba(0, 166, 81, 1);
    padding: 8px 30px;
    border: 2px solid rgba(0, 166, 81, 1);
    border-radius: 10px;
    transition: all ease-in-out 300ms;

    &.active {
      background-color: rgba(0, 166, 81, 1);
      color: #fff;
    }

    &:hover {
      background-color: rgba(0, 166, 81, 1);
      color: #fff;
    }
  }
`;
