import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { TicketModalStyles } from './TicketModal.styles';
import InputText from '../inputs/InputText';
import InputTextarea from '../inputs/InputTextarea';
import { generalAlertSuccessToast } from '../../utils/alerts';
import managementAxe from '../../../services/axios-client/managementServisAxeClient';

const schema = yup.object({
  subject: yup
    .string()
    .required("Il campo è obbligatorio. Inserisci il oggetto dell'azienda!"),
  message: yup
    .string()
    .required("Il campo è obbligatorio. Inserisci il messaggio dell'azienda!"),
});

function TicketModal({ getTickets }) {
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    reset,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: {
      subject: '',
      message: '',
    },
  });

  const supportMessage = watch();

  const [isSending, setIsSending] = useState(false);

  const sendInquiry = async (data) => {
    setIsSending(true);
    const res = await managementAxe.post('broker/tickets/create-ticket', data);
    setIsSending(false);
    if (res.status === 200) {
      reset();
      document
        .querySelectorAll('.modal-backdrop')
        .forEach((el) => el.classList.remove('modal-backdrop'));
      generalAlertSuccessToast('Ticket Added');
      getTickets();
    }
  };

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  return (
    <TicketModalStyles>
      <div
        className="modal fade"
        id="support-message-box"
        data-bs-backdrop="static"
        tabIndex="-1"
        aria-labelledby="support-message-boxLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="support-message-boxLabel">
                Contatto Supporto
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              {isSending === false ? (
                <form onSubmit={handleSubmit(sendInquiry)}>
                  <InputText
                    type="text"
                    className="form-control bg-white fw-normal reply-subject"
                    name="subject"
                    label="Oggetto"
                    placeholder="Il tuo oggetto..."
                    value={supportMessage.subject}
                    register={register}
                    error={errors.subject?.message}
                    valid={isValid('subject')}
                    colClass="w-100"
                    nostyle
                  />

                  <InputTextarea
                    placeholder="Invia una messaggio..."
                    name="message"
                    label="Scrivi il Messaggio"
                    register={register('message')}
                    type="textarea"
                    rows="3"
                    id="message"
                    colClass="w-100"
                    error={errors.message?.message}
                    valid={isValid('message')}
                  />
                  <div className="general-input-error mb-3 text-center">
                    {errors.message?.message}
                  </div>

                  <button
                    type="submit"
                    className="btn btn-outline-primary px-4"
                  >
                    Contatta il back office
                  </button>
                </form>
              ) : (
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <div className="spinner-border" role="status" />
                  <span>Inviando messaggio...</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </TicketModalStyles>
  );
}

export default TicketModal;
