import styled from 'styled-components';

export const TravelCardInfoStyles = styled.div`
  border: 2px solid #dbdde6;
  background: #fff;
  border-radius: 30px;
  padding: 20px;
  height: 100%;
`;

export const TitleActionBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    color: var(--common-black, #1a1921);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: 0;
  }

  @media only screen and (max-width: 900px) {
    h4 {
      font-size: 18px;
    }
  }
`;

export const InfoBoxContainer = styled.div`
  margin-top: 30px;
  padding-bottom: 30px;

  @media only screen and (max-width: 900px) {
    margin-top: 15px;
    padding-bottom: 15px;
  }
`;

export const InfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h4 {
    color: #343434;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
    text-align: start;
  }

  p {
    color: #343434;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 275;
    line-height: normal;
    margin-bottom: 0;
    text-align: end;
  }

  @media only screen and (max-width: 900px) {
    h4 {
      font-size: 14px;
    }

    p {
      font-size: 14px;
    }
  }
`;
