import React from 'react';
import { RiskButton, RiskButtonRemove } from '../Cards/ProfessionInfoCard.styles';

import { confirmAlert } from '../../../utils/alerts';
import { updateGuaranteesAutoAction, updateGuaranteesMotoAction } from '../../../../features/backofficeResults/backofficeResultsActions';

function ButtonComponentLogicPrimaBackoffice({
  type, processingQuote,
  requestInfo,
}) {
  const addGuarantees = (coverages) => {
    if (processingQuote.product_id === 2) {
      updateGuaranteesMotoAction(requestInfo.request_token, coverages, processingQuote.id);
    } else {
      updateGuaranteesAutoAction(requestInfo.request_token, coverages, processingQuote.id);
    }
  };

  const updateCoverages = (coverageSlug) => {
    const cov = processingQuote.guarantees.find((c) => c.guarantees_slug === coverageSlug);
    const coverages = [];
    if (cov === undefined) {
      processingQuote.guarantees.map((c) => coverages.push(c.guarantees_slug));
      coverages.push(coverageSlug);
    } else if (coverageSlug === 'furto_incendio') {
      processingQuote.guarantees.filter((cv) => (cv.guarantees_slug !== coverageSlug && cv.guarantees_slug !== 'cristalli')).map((c) => coverages.push(c.guarantees_slug));
    } else {
      processingQuote.guarantees.filter((cv) => cv.guarantees_slug !== coverageSlug).map((c) => coverages.push(c.guarantees_slug));
    }

    addGuarantees(coverages);
  };

  const removeGuarantee = (coverageSlug) => {
    confirmAlert('Sei Sicuro Di Rimuovere Questo Rischio?', () => updateCoverages(coverageSlug));
  };

  const createIdForModal = () => {
    if (type.id === 'infortuni_conducente') {
      return 'infortuni_conducente_prima';
    }

    if (type.id === 'assistenza_stradale') {
      return 'assistenza_stradale_prima';
    }

    if (type.id === 'tutela_legale') {
      return 'tutela_legale_prima';
    }

    if (type.id === 'cristalli') {
      return 'cristalli_prima';
    }

    return type.id;
  };

  return (
    <div className="d-flex justify-content-end ms-auto gap-2">

      {(type.id === 'tutela_legale' && (processingQuote !== null && processingQuote.company_id === 2 && processingQuote.guarantees.find((g) => g.guarantees_slug === type.id))) ? <RiskButtonRemove className="btn btn-danger" onClick={() => removeGuarantee(type.id)}>Rimuovi</RiskButtonRemove> : type.id === 'tutela_legale' && (processingQuote !== null && processingQuote.company_id === 2 && processingQuote.guarantees.find((g) => g.guarantees_slug === type.id) === undefined) ? <RiskButton className="btn btn-success" data-bs-toggle="modal" data-bs-target={`#${createIdForModal()}`}>Aggiungi</RiskButton> : ''}

      {(type.id === 'assistenza_stradale' && (processingQuote !== null && processingQuote.company_id === 2 && processingQuote.guarantees.find((g) => g.guarantees_slug === type.id))) ? <RiskButtonRemove className="btn btn-danger" onClick={() => removeGuarantee(type.id)}>Rimuovi</RiskButtonRemove> : type.id === 'assistenza_stradale' && (processingQuote !== null && processingQuote.company_id === 2 && processingQuote.guarantees.find((g) => g.guarantees_slug === type.id) === undefined) ? <RiskButton className="btn btn-success" data-bs-toggle="modal" data-bs-target={`#${createIdForModal()}`}>Aggiungi</RiskButton> : ''}

      {(type.id === 'infortuni_conducente' && (processingQuote !== null && processingQuote.company_id === 2 && processingQuote.guarantees.find((g) => g.guarantees_slug === type.id))) ? <RiskButtonRemove className="btn btn-danger" onClick={() => removeGuarantee(type.id)}>Rimuovi</RiskButtonRemove> : type.id === 'infortuni_conducente' && (processingQuote !== null && processingQuote.company_id === 2 && processingQuote.guarantees.find((g) => g.guarantees_slug === type.id) === undefined) ? <RiskButton className="btn btn-success" data-bs-toggle="modal" data-bs-target={`#${createIdForModal()}`}>Aggiungi</RiskButton> : ''}

      {(type.id === 'cristalli' && (processingQuote !== null && processingQuote.company_id === 2 && processingQuote.guarantees.find((g) => g.guarantees_slug === type.id))) ? <RiskButtonRemove className="btn btn-danger" onClick={() => removeGuarantee(type.id)}>Rimuovi</RiskButtonRemove> : type.id === 'cristalli' && (processingQuote !== null && processingQuote.company_id === 2 && processingQuote.guarantees.find((g) => g.guarantees_slug === type.id) === undefined) ? <RiskButton className="btn btn-success" data-bs-toggle="modal" data-bs-target={`#${createIdForModal()}`}>Aggiungi</RiskButton> : ''}

      {(type.id === 'protezione_rivalse' && (processingQuote !== null && processingQuote.company_id === 2 && processingQuote.guarantees.find((g) => g.guarantees_slug === type.id))) ? <RiskButtonRemove className="btn btn-danger" onClick={() => removeGuarantee(type.id)}>Rimuovi</RiskButtonRemove> : type.id === 'protezione_rivalse' && (processingQuote !== null && processingQuote.company_id === 2 && processingQuote.guarantees.find((g) => g.guarantees_slug === type.id) === undefined) ? <RiskButton className="btn btn-success" data-bs-toggle="modal" data-bs-target={`#${type.id}`}>Aggiungi</RiskButton> : ''}

      {(type.id === 'kasko' && processingQuote.guarantees.find((g) => g.guarantees_slug === 'kasko') !== undefined) ? <RiskButtonRemove className="btn btn-danger" onClick={() => removeGuarantee(type.id)}>Rimuovi</RiskButtonRemove> : type.id === 'kasko' && processingQuote.guarantees.find((g) => g.guarantees_slug === 'kasko') === undefined && processingQuote !== null ? <RiskButton className="btn btn-success" data-bs-toggle="modal" data-bs-target={`#${type.id}`}>Aggiungi</RiskButton> : ''}

      {(type.id === 'collisione' && processingQuote.guarantees.find((g) => g.guarantees_slug === 'collisione') !== undefined) ? <RiskButtonRemove className="btn btn-danger" onClick={() => removeGuarantee(type.id)}>Rimuovi</RiskButtonRemove> : type.id === 'collisione' && processingQuote.guarantees.find((g) => g.guarantees_slug === 'collisione') === undefined && processingQuote !== null ? <RiskButton className="btn btn-success" data-bs-toggle="modal" data-bs-target={`#${type.id}`}>Aggiungi</RiskButton> : ''}

      {(type.id === 'eventi_sociopolitici' && processingQuote.guarantees.find((g) => g.guarantees_slug === 'eventi_sociopolitici') !== undefined) ? <RiskButtonRemove className="btn btn-danger" onClick={() => removeGuarantee(type.id)}>Rimuovi</RiskButtonRemove> : type.id === 'eventi_sociopolitici' && processingQuote.guarantees.find((g) => g.guarantees_slug === 'eventi_sociopolitici') === undefined && processingQuote !== null ? <RiskButton className="btn btn-success" data-bs-toggle="modal" data-bs-target={`#${type.id}`}>Aggiungi</RiskButton> : ''}

      {(type.id === 'eventi_naturali' && processingQuote.guarantees.find((g) => g.guarantees_slug === 'eventi_naturali') !== undefined) ? <RiskButtonRemove className="btn btn-danger" onClick={() => removeGuarantee(type.id)}>Rimuovi</RiskButtonRemove> : type.id === 'eventi_naturali' && processingQuote.guarantees.find((g) => g.guarantees_slug === 'eventi_naturali') === undefined && processingQuote !== null ? <RiskButton className="btn btn-success" data-bs-toggle="modal" data-bs-target={`#${type.id}`}>Aggiungi</RiskButton> : ''}

      {(type.id === 'bonus_protetto' && processingQuote.guarantees.find((g) => g.guarantees_slug === 'bonus_protetto') !== undefined) ? <RiskButtonRemove className="btn btn-danger" onClick={() => removeGuarantee(type.id)}>Rimuovi</RiskButtonRemove> : type.id === 'bonus_protetto' && processingQuote.guarantees.find((g) => g.guarantees_slug === 'bonus_protetto') === undefined && processingQuote !== null ? <RiskButton className="btn btn-success" data-bs-toggle="modal" data-bs-target={`#${type.id}`}>Aggiungi</RiskButton> : ''}

      {(type.id === 'furto_incendio' && processingQuote.guarantees.find((g) => g.guarantees_slug === 'furto_incendio') !== undefined) ? <RiskButtonRemove className="btn btn-danger" onClick={() => removeGuarantee(type.id)}>Rimuovi</RiskButtonRemove> : type.id === 'furto_incendio' && processingQuote.guarantees.find((g) => g.guarantees_slug === 'furto_incendio') === undefined && processingQuote !== null ? <RiskButton className="btn btn-success" data-bs-toggle="modal" data-bs-target={`#${type.id}`}>Aggiungi</RiskButton> : ''}

      {(requestInfo.processing_quotation_id === null) && <div className="alert alert-warning py-2 px-2">Seleziona Prima La Responsibilita Civile</div>}
    </div>
  );
}

export default ButtonComponentLogicPrimaBackoffice;
