import { createSlice } from '@reduxjs/toolkit';

import { callerCreator } from '../callerCreator';

const initialState = {
  edit_reminder: {
    loading: true,
    error: null,
    reminder: null,
  },
};

export const remindersSlice = createSlice({
  name: 'reminders',
  initialState,
  reducers: {
    editReminderPending: (state) => {
      state.edit_reminder.loading = true;
      state.edit_reminder.error = null;
    },
    editReminderRejected: (state, { payload }) => {
      state.edit_reminder.loading = false;
      state.edit_reminder.error = payload;
    },
    editReminderFulfilled: (state, { payload }) => {
      state.edit_reminder.loading = false;
      state.edit_reminder.reminder = payload;
    },
  },
});

export default callerCreator(remindersSlice.actions);
