import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import Joyride from 'react-joyride';
import {
  addQuoteTravelToCart, loadTravelQuotes, removeQuoteTravelFromCart, resetTravelQuotes,
} from '../../features/insuranceRequests/insuranceRequestsActions';
import PageLoading from '../../common/components/elements/PageLoading';
import { BACKEND_BASE_URL } from '../../config';
import { confirmAlert, generalAlertError } from '../../common/utils/alerts';
// import { SellingDistanceLink } from '../../common/components/elements/SellingDistanceLink';
// import { SellingDistanceModal } from '../../common/components/elements/SellingDistanceModal/SellingDistanceModal';
// import axe from '../../services/axios-client/axiosClient';
import { TravelQuotesStyles } from './TravelResultPage.styles';
import TravelCardInfo from '../../common/components/redesignComponents/Cards/TravelCardInfo';
import { EstimateContainer } from '../../common/components/redesignComponents/common/Redesign.styles';
import EuropeassistanceCard from '../../common/components/redesignComponents/Cards/EuropeassistanceCard';
import { FailedQuoteTitle, FailedQuotesContainer } from '../RcQuotes.styles';
// import { useTourContext } from '../../context/useTourContext';
import ErrorComponent from '../../common/components/ErrorComponent';
// import { joyrideOptions } from '../../common/utils/joyrideUtils';
// import { handleCallbackTravelResult } from '../../common/utils/joyrideCallbacks';
// import { travelResultSteps } from '../../common/utils/joyrideSteps';
import { GuaranteesDefaultStateStyles } from '../../common/components/redesignComponents/common/GuaranteesDefaultState.styles';
import { quotationTypes } from '../../common/constants';
import {
  RespCivileModal, RiskButton, RiskButtonRemove, RiskCard, RiskContainer,
} from '../../common/components/redesignComponents/Cards/ProfessionInfoCard.styles';
import AragCard from '../../common/components/redesignComponents/Cards/AragCard';
import ProcessingQuoteSelectedTravel from '../../common/components/redesignComponents/ResultPageComponents/ProcessingQuoteSelectedTravel';
import TutelaSelectedTravel from '../../common/components/redesignComponents/ResultPageComponents/TutelaSelectedTravel';

function TravelResultPage() {
  const { requestToken } = useParams();
  const {
    loading, customer, quotations, insuranceRequest, failedQuotations, error, quotes,
    processingQuote, tutelaLegaleQuote, addingQuote,
  } = useSelector((store) => store.insuranceRequests.travel_quotes);
  const navigate = useNavigate();
  // const [showSellingDistanceModal, setShowSellingDistanceModal] = useState(false);

  const modifyData = () => {
    navigate('/quote/travel', {
      state: { requestToken },
    });
  };

  // const [steps, setSteps] = useState([]);

  // const generateSellingDfirstceLink = async () => {
  //   const res = await axe.post(`/selling-distance/${requestToken}`);

  //   if (res.status !== 200) {
  //     generalAlertError('Non siamo riusciti a generare il collegamento');
  //     return;
  //   }

  //   generalAlertSuccess('Il collegamento e stato creato');

  //   loadTravelQuotes(requestToken);
  // };

  // const getSellingDistanceLink = () => `${SELLING_DISTANCE_URL}/request/${insuranceRequest.distance_token}/travel/results`;

  // const copySellingDistanceLink = async () => {
  //   try {
  //     const link = getSellingDistanceLink();

  //     await navigator.clipboard.writeText(link);
  //     generalAlertSuccess('Il collegamento e stato copiato');
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('newResultTravel') === 'done') {
  //     setState({ run: false });
  //   } else {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 1500);
  //   }
  // }, [localStorage.getItem('newResultTravel')]);

  // const firePurchaseTour = () => {
  //   localStorage.removeItem('newResultTravel');
  //   setState({ run: true });
  // };

  const fetchQuotes = () => {
    loadTravelQuotes(requestToken);
  };

  useEffect(() => {
    loadTravelQuotes(requestToken);

    return () => {
      resetTravelQuotes();
    };
  }, []);

  const removeItemFromCart = (quoteId) => {
    confirmAlert('Sei Sicuro di rimuovere questa quotazione?', () => removeQuoteTravelFromCart(quoteId, fetchQuotes));
  };

  useEffect(() => {
    if (insuranceRequest && insuranceRequest.is_expired === true) {
      navigate('/quote/travel', {
        state: { requestToken },
      });
      generalAlertError('È necessario compilare nuovamente il preventivo!', 'Link e scaduto!');
    }
  }, [insuranceRequest]);

  const getTotal = () => {
    let quoteInProccessAmount = 0;
    let tutelaInProccessAmount = 0;

    if (processingQuote !== null) {
      quoteInProccessAmount = parseFloat(processingQuote.amount);
    }
    if (tutelaLegaleQuote !== null) {
      tutelaInProccessAmount = parseFloat(tutelaLegaleQuote.amount);
    }

    return quoteInProccessAmount + tutelaInProccessAmount;
  };

  const handleRequestSave = () => {
    if (processingQuote === null) {
      generalAlertError('Seleziona Prima La Responsabilita Civile', 'Errore');
      return;
    }

    navigate(`/quotes/travel/purchase/${requestToken}`);
  };
  // useEffect(() => {
  //   travelResultSteps(quotations, setSteps);
  // }, [quotations]);

  const selectQuotation = (quoteId) => {
    confirmAlert('Sei Sicuro di aggiungere questa quotazione?', () => addQuoteTravelToCart(quoteId, fetchQuotes));
  };

  if (loading) {
    return <PageLoading />;
  }

  return (
    error !== null ? <ErrorComponent error={error} />
      : (
        <>
          {/* <Joyride
            steps={steps}
            run={run}
            continuous
            showBeacon={showBeacon}
            locale={{
              back: 'Indietro',
              close: 'Chiudi',
              last: 'Fine',
              next: 'Prossima',
              open: 'Apri il modale',
              restart: 'Riavvia il tour ',
              skip: 'Salta',
            }}
            styles={{
              options: joyrideOptions,
              buttonNext: {
                backgroundColor: '#00a651',
                outline: 'none',
              },
              buttonBack: {
                color: '#00a651',
                outline: 'none',
              },
              beaconInner: {
                backgroundColor: '#00a651',
              },
              beaconOuter: {
                borderColor: '#00a651',
                backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
              },
              buttonSkip: {
                outline: 'none',
              },
              buttonClose: {
                outline: 'none',
              },
            }}
            callback={(data) => handleCallbackTravelResult(data, travelResultSteps, setState)}
            showSkipButton
            showProgress
            disableScrolling
          /> */}
          <TravelQuotesStyles>
            <div className="resultsPage">
              <div className="row">
                <div className="col-12 col-xxl-4 mb-2 mb-xxl-0 result-travel-first-step">
                  <TravelCardInfo insuranceRequest={insuranceRequest} customer={customer} modificaDati={modifyData} />
                </div>
                <div className="col-12 col-xxl-8 result-travel-second-step">
                  {/* <GuaranteesDefaultStateTravel /> */}
                  <GuaranteesDefaultStateStyles>
                    {processingQuote === null && tutelaLegaleQuote === null
                      ? <h5 className="fw-semibold pt-2 ps-lg-2">Seleziona Le Quotazioni Che Vuoi Aquistare</h5>
                      : (
                        <div className="container mt-4">
                          <ul className="list-unstyled">
                            {processingQuote !== null && (
                              <li>
                                <div className="d-flex justify-content-between align-items-center">
                                  <h5>
                                    {processingQuote.company !== null ? processingQuote.company.name : processingQuote.preventivas_company.company_name}
                                    {' '}
                                    <sub>
                                      (
                                      {quotationTypes.find((item) => item.id === processingQuote.quote_type).name}
                                      )
                                    </sub>
                                  </h5>
                                  <h5 className="fw-bold">
                                    {parseFloat(processingQuote.amount)}
                                    {' '}
                                    €
                                  </h5>
                                </div>
                              </li>
                            )}
                            {tutelaLegaleQuote !== null && (
                              <li>
                                <div className="d-flex justify-content-between align-items-center">
                                  <h5>
                                    {tutelaLegaleQuote.company !== null ? tutelaLegaleQuote.company.name : tutelaLegaleQuote.preventivas_company.company_name}
                                    {' '}
                                    <sub>
                                      (
                                      {quotationTypes.find((item) => item.id === tutelaLegaleQuote.quote_type).name}
                                      )
                                    </sub>
                                  </h5>
                                  <h5 className="fw-bold">
                                    {parseFloat(tutelaLegaleQuote.amount)}
                                    {' '}
                                    €
                                  </h5>
                                </div>
                              </li>
                            )}
                            <hr />
                            <li>
                              <div className="d-flex justify-content-end align-items-center">
                                <h5 className="me-5 fw-bold">
                                  Totale:
                                </h5>
                                <h5 className="fw-bold">
                                  {' '}
                                  {getTotal()}
                                  {' '}
                                  €
                                </h5>
                              </div>
                            </li>
                          </ul>
                          <div className="d-flex justify-content-end mt-3">
                            <button className="btn btn-success rounded-4 px-5 py-2" onClick={() => handleRequestSave()}>
                              Aquista
                            </button>
                          </div>
                        </div>
                      )}
                  </GuaranteesDefaultStateStyles>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <EstimateContainer>
                    <div className="d-flex gap-2 justify-content-end">
                      {/* <div>
                        <h4>
                          Preventivi assicurazione
                          {' '}
                          <b>viaggi</b>
                        </h4>
                        <p>
                          Inizio Copertura:
                          {' '}
                          {new Date(insuranceRequest.policy_effective_date).toLocaleDateString('it-IT', { year: 'numeric', month: 'numeric', day: 'numeric' })}
                        </p>
                      </div> */}
                      {/* <div>
                        {localStorage.getItem('newResultTravel') === 'done' && (
                          <div style={{ cursor: 'pointer' }} onClick={() => firePurchaseTour()}>
                            <button className="btn btn-success px-2 py-1">Tutorial</button>
                          </div>
                        )}
                      </div> */}
                    </div>
                    {/* <div className="d-flex align-items-center justify-content-start mt-2">
                    {/* <div className="d-flex align-items-center justify-content-start mt-2">
                  <div className="selling-distance">
                    {insuranceRequest.selling_point === 0 &&
                      <>
                        <SellingDistanceButton onClick={() => setShowSellingDistanceModal(true)}>Crea link per vendita a distanza</SellingDistanceButton>
                        {showSellingDistanceModal === true &&
                          <SellingDistanceModal
                            requestToken={requestToken}
                            closeModal={() => setShowSellingDistanceModal(false)}
                            loadResults={() => loadTravelQuotes(requestToken)}
                          />
                        }

                      </>
                    }
                    {insuranceRequest.selling_point === 1 &&
                      <div className="d-flex align-items-center">
                        <div className="btn btn-success m-2" onClick={() => copySellingDistanceLink()} title="Copia collegamento per il cliente">
                          <FontAwesomeIcon icon={faCopy} />
                        </div>
                        <SellingDistanceLink>{getSellingDistanceLink()}</SellingDistanceLink>
                      </div>
                    }
                  </div>
                </div> */}
                  </EstimateContainer>
                </div>
              </div>
              <RiskContainer className="mb-4">
                {quotationTypes.map((type, index) => (
                  <React.Fragment key={index}>
                    {quotes && quotes[type.id] && (
                      <RiskCard className={`mt-3 purchase-quote-fourth-step flex-grow-1 d-flex align-items-center justify-content-between flex-column p-3 ${insuranceRequest.processing_quotation_id === null && type.id !== 'res_civile' ? 'bg-secondary bg-opacity-25' : ''} ${insuranceRequest.processing_quotation_id !== null && type.id === 'res_civile' ? 'bg-success bg-opacity-25' : ''} ${insuranceRequest.tutela_legale_quote_id !== null && type.id === 'tutela_legale' ? 'bg-success bg-opacity-25' : ''} ${insuranceRequest.assistenza_stradale_quote_id !== null && type.id === 'assistenza_stradale' ? 'bg-success bg-opacity-25' : ''} ${insuranceRequest.cristalli_quote_id !== null && type.id === 'cristalli' ? 'bg-success bg-opacity-25' : ''} ${insuranceRequest.infortuni_conducente_quote_id !== null && type.id === 'infortuni_conducente' ? 'bg-success bg-opacity-25' : ''} ${insuranceRequest.rivalsa_quote_id !== null && type.id === 'rivalsa' ? 'bg-success bg-opacity-25' : ''}`}>
                        <div className="p-3 me-auto">
                          <h5>{type.name}</h5>
                        </div>
                        {(processingQuote !== null && type.id === 'res_civile') && <ProcessingQuoteSelectedTravel processingQuote={processingQuote} requestInfo={insuranceRequest} />}

                        {(processingQuote !== null && type.id === 'tutela_legale') && <TutelaSelectedTravel tutelaLegaleQuote={tutelaLegaleQuote} type={type} />}
                        {/* {(processingQuote !== null && type.id === 'assistenza_stradale') && <AssistenzaStradaleSelectedAuto assistenzaStradaleQuote={assistenzaStradaleQuote} type={type} />}
                       {(processingQuote !== null && type.id === 'infortuni_conducente') && <InfortuniConducenteSelectedAuto infortuniConducenteQuote={infortuniConducenteQuote} type={type} />}
                       {(processingQuote !== null && type.id === 'cristalli') && <CristalliSelectedAuto cristalliQuote={cristalliQuote} type={type} />}
                       {(processingQuote !== null && type.id === 'rivalsa') && <RivalsaSelectedAuto rivalsaQuote={rivalsaQuote} type={type} />} */}

                        <div className="d-flex justify-content-end ms-auto gap-2">
                          {(type.id === 'res_civile' && processingQuote !== null) && <RiskButtonRemove className="btn btn-danger" onClick={() => removeItemFromCart(processingQuote.id)}>Rimuovi</RiskButtonRemove>}

                          {(type.id === 'tutela_legale' && tutelaLegaleQuote !== null) ? <RiskButtonRemove className="btn btn-danger" onClick={() => removeItemFromCart(tutelaLegaleQuote.id)}>Rimuovi</RiskButtonRemove> : type.id === 'tutela_legale' && tutelaLegaleQuote === null && processingQuote !== null ? <RiskButton className="btn btn-success" data-bs-toggle="modal" data-bs-target={`#${type.id}`}>Aggiungi</RiskButton> : ''}

                          {insuranceRequest.processing_quotation_id === null && type.id !== 'res_civile' && <div className="alert alert-warning py-2 px-2">Seleziona Prima La Responsibilita Civile</div>}

                          {type.id === 'res_civile' && <RiskButton className="btn btn-success" data-bs-toggle="modal" data-bs-target={`#${type.id}`}>Aggiungi</RiskButton>}
                        </div>
                      </RiskCard>
                    )}
                  </React.Fragment>
                ))}
              </RiskContainer>

              {quotationTypes.map((type, index) => (
                <RespCivileModal className="modal fade" id={type.id} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" key={index}>
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">{type.name}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="buttonClose" />
                      </div>
                      <div className="modal-body">
                        {addingQuote && (
                          <div className="d-flex align-items-center justify-content-between flex-column">
                            <div className="d-flex justify-content-center">
                              <div className="spinner-border" role="status">
                                <span className="visually-hidden">Aggiungendo...</span>
                              </div>
                            </div>
                            <div className="ms-2">Aggiungendo...</div>
                          </div>
                        )}
                        {addingQuote === false && (
                          <div className="row purchase-quote-eight-step p-1">
                            {addingQuote === false && (quotes && quotes[type.id]) ? quotes[type.id].map((quote, index) => (
                              <>
                                {type.id === 'res_civile' && <EuropeassistanceCard quote={quote} requestToken={requestToken} guarantees={quotations.quotation_coverages} insuranceRequest={insuranceRequest} key={index} selectQuotation={selectQuotation} />}

                                {type.id === 'tutela_legale' && <AragCard quotation={quote} requestInfo={insuranceRequest} key={index} type={type} selectQuotation={selectQuotation} />}
                              </>
                            )) : <h5 className="fw-bold">Nessuna Quotazione Trovata</h5>}
                          </div>
                        )}
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-danger px-4" data-bs-dismiss="modal">Chiudi</button>
                      </div>
                    </div>
                  </div>
                </RespCivileModal>
              ))}
            </div>
          </TravelQuotesStyles>
          {failedQuotations.length !== 0 && (
            <div className="row mt-5">
              <FailedQuoteTitle>Queste compagnie non hanno risposto con una quotazione.</FailedQuoteTitle>
              {failedQuotations.map((quote, index) => (
                <div className="col-3" key={index}>
                  <FailedQuotesContainer>
                    <img src={BACKEND_BASE_URL + quote.company.logo} alt={`${quote.company.name} Logo`} />
                  </FailedQuotesContainer>
                </div>
              ))}
            </div>
          )}
        </>
      )
  );
}

export default TravelResultPage;
