import React from 'react';
import * as yup from 'yup';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import CenteredPageTitle from '../common/components/CenteredPageTitle';
import { FormInputText } from '../common/formInputs/FormInputText';

// import {
//   commercialFieldsBySteps,
//   schemaCommercial,
// } from './Commercial.validations';

export const validationsSchema = yup.object({
  vat_number: yup
    .string()
    .nullable()
    .when('gender', {
      is: 'G',
      then: () => yup
        .string()
        .max(11, 'La partiva iva non puo essere piu di 11 numeri!')
        .matches(/^[0-9]*$/, 'Solo numeri sono ammessi!')
        .required('Il campo è obbligatorio. Inserisci la tua partita iva!'),
    }),
});

function Commercial() {
  const navigate = useNavigate();
  // const state = useSelector((store) => store.forms.commercial);

  // useEffect(() => {
  //   commercialInitialData();

  //   return () => {
  //     resetCommercialForm();
  //   };
  // }, []);

  // let savedData = localStorage.getItem('commercialFormData');
  // if (savedData) {
  //   savedData = JSON.parse(savedData);
  //   console.log(savedData);
  // }

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    // setValue,
    // trigger,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationsSchema),
    shouldFocusError: true,
    defaultValues: {
      // product: 'commercial',
      // source: 'greenia.it',
      // ateco_code: null,
      // legal_form: null,
      vat_number: '',
      // previous_policy: '0',
      // previous_policy_amount: '',
      // previous_policy_company: '',
      // administrators: 0,
      // employees: 0,
      // managers: 0,
      // sales: '',
      // sales_estimate: '',
      // has_offices: '0',
      // number_of_offices: 0,
      // operational_office_number: 0,
      // other_operational_office_number: 0,
      // residence_commune_code: '',
      // residence_province_code: '',
      // postal_code: '',
      // address: '',
      // house_number: '',
      // is_over_12_months: '0',
      // policy_start_date: '',
      // email: '',
      // phone: '',
      // office_buildings: [],
      // operational_office_buildings: [],
      // other_operational_office_buildings: [],
      // administrators_data: [],
      // employees_data: [],
      // managers_data: [],

      // step 4

      // expiration_date: '',
      // effective_date: '',
      // previous_award: '0',
      // has_transportation_vehicles: '0',
      // transportation_vehicles_10k: 0,
      // transportation_vehicles_25k: 0,
      // transportation_vehicles_50k: 0,
      // annual_cost_of_goods_sold: '0',
      // uses_own_equipment: '0',
      // business_equipment_new_value: '0',
      // goods_supplies_stored_value: '0',
      // electronic_devices_value: '0',
    },
  });

  const commercialFormData = watch();

  // const updateFormData = (data) => {
  //   const keys = Object.keys(data);
  //   keys.forEach((key) => {
  //     setValue(key, data[key], {
  //       shouldValidate: true,
  //       shouldDirty: true,
  //       shouldTouch: true,
  //     });
  //   });
  //   trigger(keys);
  // };

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  // const prevStep = () => {
  //   if (
  //     step === 4
  //     && commercialFormData.has_offices == 0
  //     && commercialFormData.administrators == 0
  //     && commercialFormData.employees == 0
  //     && commercialFormData.managers == 0
  //   ) {
  //     commercialChangeStep(1);
  //     return;
  //   }
  //   if (step === 3 && commercialFormData.has_offices == 0) {
  //     commercialChangeStep(1);
  //     return;
  //   }

  //   commercialChangeStep(step - 1);
  // };

  // const nextStep = async () => {
  //   const res = await trigger(commercialFieldsBySteps[step - 1], {
  //     shouldFocus: true,
  //   });
  //   console.log(errors);
  //   console.log(commercialFormData);
  //   if (res !== true) {
  //     return;
  //   }

  //   if (
  //     step === 1
  //     && commercialFormData.has_offices == 0
  //     && commercialFormData.administrators == 0
  //     && commercialFormData.employees == 0
  //     && commercialFormData.managers == 0
  //   ) {
  //     commercialChangeStep(4);
  //   } else if (step === 1 && commercialFormData.has_offices == 0) {
  //     commercialChangeStep(3);
  //   } else if (
  //     step === 2
  //     && commercialFormData.administrators == 0
  //     && commercialFormData.employees == 0
  //     && commercialFormData.managers == 0
  //   ) {
  //     commercialChangeStep(3);
  //   } else {
  //     commercialChangeStep(step + 1);
  //   }

  //   setTimeout(() => window.scrollTo(0, 0), 500);
  // };

  const submitForm = () => {
    // localStorage.setItem('commercialFormData', JSON.stringify(commercialFormData));
    navigate(`/quote/commercial/${commercialFormData.vat_number}/report`);

    // todo submit the form
  };

  // const stepTitle = () => {
  //   switch (step) {
  //     case 1:
  //       return 'Completa i dati';
  //     case 2:
  //       return 'Completa i dati buildings';
  //     case 3:
  //       return 'Completa i dati people';
  //     default:
  //       return 'Completa i dati';
  //   }
  // };

  // const checkBuildingsValidation = () => {
  //   let returnValue = true;
  //   if (
  //     commercialFormData.number_of_offices != commercialFormData?.office_buildings?.length
  //   ) {
  //     returnValue = false;
  //   }
  //   if (
  //     commercialFormData.operational_office_number != commercialFormData?.operational_office_buildings?.length
  //   ) {
  //     returnValue = false;
  //   }
  //   if (
  //     commercialFormData.other_operational_office_number != commercialFormData?.other_operational_office_buildings?.length
  //   ) {
  //     returnValue = false;
  //   }

  //   return returnValue;
  // };

  // const checkPeopleValidation = () => {
  //   let returnValue = true;

  //   if (
  //     commercialFormData.administrators != commercialFormData?.administrators_data?.length
  //   ) {
  //     returnValue = false;
  //   }
  //   if (
  //     commercialFormData.employees != commercialFormData?.employees_data?.length
  //   ) {
  //     returnValue = false;
  //   }
  //   if (
  //     commercialFormData.managers != commercialFormData?.managers_data?.length
  //   ) {
  //     returnValue = false;
  //   }

  //   return returnValue;
  // };

  // const checkStepValidation = () => {
  //   if (step === 1) {
  //     return true;
  //   }
  //   if (step === 2) {
  //     return checkBuildingsValidation();
  //   }
  //   if (step === 3) {
  //     return checkPeopleValidation();
  //   }
  //   if (step === 4) {
  //     return false;
  //   }
  //   return false;
  // };

  // if (loading) {
  //   return <PageLoading />;
  // }

  return (
    <>
      <form onSubmit={handleSubmit(submitForm)}>
        <CenteredPageTitle
          // title={stepTitle()}
          title="Completa I Dati"
        />

        <div className="form-container">
          <FormInputText
            placeholder="-Partita IVA-"
            label="Partita IVA"
            registration={register('vat_number')}
            error={errors.vat_number?.message}
            valid={isValid('vat_number')}
          />
        </div>
        {/* {step === 1 && (
          <>
            <div className="form-container">
              <FormInputSelect
                label="Settore"
                placeholder="-Seleziona-"
                options={company_types}
                registration={register('ateco_code')}
                error={errors.ateco_code?.message}
                valid={isValid('ateco_code')}
              />

              {commercialFormData.ateco_code && (
                <>
                  <FormInputSelect
                    label="Forma giuridica"
                    placeholder="-Seleziona-"
                    options={legal_forms}
                    registration={register('legal_form')}
                    error={errors.legal_form?.message}
                    valid={isValid('legal_form')}
                  />
                  <FormInputText
                    placeholder="-Partita IVA-"
                    label="Partita IVA"
                    registration={register('vat_number')}
                    error={errors.vat_number?.message}
                    valid={isValid('vat_number')}
                  />
                  <FormInputText
                    placeholder="-Numero amministratori-"
                    label="Numero amministratori"
                    registration={register('administrators')}
                    error={errors.administrators?.message}
                    valid={isValid('administrators')}
                  />
                  <div
                    className="d-flex align-items-start justify-content-between flex-wrap"
                    style={{ columnGap: 20 }}
                  >
                    <FormInputText
                      placeholder="-Numero Dipendenti-"
                      label="Numero Dipendenti"
                      registration={register('employees')}
                      error={errors.employees?.message}
                      valid={isValid('employees')}
                    />
                    <FormInputText
                      placeholder="-Numero di dirigenti-"
                      label="Numero Di Dirigenti"
                      registration={register('managers')}
                      error={errors.managers?.message}
                      valid={isValid('managers')}
                    />
                  </div>

                  <FormInputPrice
                    placeholder="-Fatturato-"
                    label="Fatturato anno precedente"
                    value={commercialFormData.sales}
                    name="sales"
                    onChange={(value) => setValue('sales', value, {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    })}
                    error={errors.sales?.message}
                    valid={isValid('sales')}
                  />
                  <FormInputPrice
                    placeholder="-Fatturato-"
                    label="Fatturato anno in corso (stima)"
                    value={commercialFormData.sales_estimate}
                    name="sales_estimate"
                    onChange={(value) => setValue('sales_estimate', value, {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    })}
                    error={errors.sales_estimate?.message}
                    valid={isValid('sales_estimate')}
                  />
                  <FormInputAddressComplete
                    municipalities={municipalities}
                    formData={commercialFormData}
                    isValid={isValid}
                    errors={errors}
                    register={register}
                    updateFormData={updateFormData}
                    addressId="autocomplete-address-contractor"
                  />
                  <FormInputRadio
                    label="Hai una polizza precedente?"
                    options={flagResponse}
                    name="previous_policy"
                    registration={register('previous_policy')}
                    error={errors.previous_policy?.message}
                  />

                  {commercialFormData.previous_policy == 1 && (
                    <>
                      <FormInputPrice
                        placeholder="Totale"
                        label="Polizza precedente totale"
                        value={commercialFormData.previous_policy_amount}
                        name="previous_policy_amount"
                        onChange={(value) => setValue('previous_policy_amount', value, {
                          shouldValidate: true,
                          shouldDirty: true,
                          shouldTouch: true,
                        })}
                        error={errors.previous_policy_amount?.message}
                        valid={isValid('previous_policy_amount')}
                      />
                      <FormInputSelect
                        label="Forma giuridica"
                        placeholder="-Seleziona-"
                        options={companies}
                        registration={register('previous_policy_company')}
                        error={errors.previous_policy_company?.message}
                        valid={isValid('previous_policy_company')}
                      />
                    </>
                  )}

                  <FormInputRadio
                    label="La società ha altre sedi?"
                    options={flagResponse}
                    name="has_offices"
                    registration={register('has_offices')}
                    error={errors.has_offices?.message}
                  />

                  {commercialFormData.has_offices == 1 && (
                    <>
                      <FormInputText
                        placeholder="-Numero Sede legale-"
                        label="Numero Sede legale"
                        registration={register('number_of_offices')}
                        error={errors.number_of_offices?.message}
                        valid={isValid('number_of_offices')}
                      />
                      <FormInputText
                        placeholder="-Numero Sede operativa-"
                        label="Numero Sede operativa"
                        registration={register('operational_office_number')}
                        error={errors.operational_office_number?.message}
                        valid={isValid('operational_office_number')}
                      />
                      <FormInputText
                        placeholder="-Numero Altri sedi-"
                        label="Numero Altri sedi"
                        registration={register(
                          'other_operational_office_number',
                        )}
                        error={errors.other_operational_office_number?.message}
                        valid={isValid('other_operational_office_number')}
                      />
                    </>
                  )}

                  <FormInputRadio
                    label="La tua attività ha più di 12 mesi?"
                    options={flagResponse}
                    name="is_over_12_months"
                    registration={register('is_over_12_months')}
                    error={errors.is_over_12_months?.message}
                  />
                  <FormInputDate
                    minDate={new Date()}
                    label="Data di inizio della copertura della polizza"
                    paragraph="Date valide: da oggi a un anno da oggi"
                    registration={register('policy_start_date')}
                    error={errors.policy_start_date?.message}
                    valid={isValid('policy_start_date')}
                  />
                  <FormInputText
                    placeholder="Mail"
                    label="Mail"
                    registration={register('email')}
                    error={errors.email?.message}
                    valid={isValid('email')}
                  />
                  <FormInputText
                    placeholder="Telefono"
                    label="Telefono"
                    registration={register('phone')}
                    error={errors.phone?.message}
                    valid={isValid('phone')}
                  />
                </>
              )}
            </div>
          </>
        )} */}
        {/* {step === 2 && (
          <>
            <BuildingsStep
              number_of_offices={commercialFormData.number_of_offices}
              operational_office_number={
                commercialFormData.operational_office_number
              }
              other_operational_office_number={
                commercialFormData.other_operational_office_number
              }
              setValue={setValue}
              formData={commercialFormData}
            />
          </>
        )} */}
        {/* {step === 3 && (
          <>
            <PeopleStep
              administrators={commercialFormData.administrators}
              employees={commercialFormData.employees}
              managers={commercialFormData.managers}
              setValue={setValue}
              formData={commercialFormData}
            />
          </>
        )} */}
        {/* {step === 4 && (
          <>
            <div className="form-container">
              <FormInputDate
                minDate={new Date()}
                label="Data di scadenza"
                registration={register('expiration_date')}
                error={errors.expiration_date?.message}
                valid={isValid('expiration_date')}
              />
              <FormInputDate
                minDate={new Date()}
                label="Data di decorrenza"
                registration={register('effective_date')}
                error={errors.effective_date?.message}
                valid={isValid('effective_date')}
              />
              <FormInputPrice
                placeholder="Premio precedente"
                label="Premio precedente"
                value={commercialFormData.previous_award}
                name="previous_award"
                onChange={(value) => setValue('previous_award', value, {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true,
                })}
                error={errors.previous_award?.message}
                valid={isValid('previous_award')}
              />
              <FormInputRadio
                label="La società posside dei veicoli che trasportano le merci?"
                options={flagResponse}
                name="has_transportation_vehicles"
                registration={register('has_transportation_vehicles')}
                error={errors.has_transportation_vehicles?.message}
              />
              {commercialFormData.has_transportation_vehicles == 1 && (
                <>
                  <FormInputText
                    label="Inserisci tutti i veicoli che trasportano le tue merci fino ad un valore di 10.000 euro."
                    placeholder="Inserisci tutti i veicoli che trasportano"
                    registration={register('transportation_vehicles_10k')}
                    error={errors.transportation_vehicles_10k?.message}
                    valid={isValid('transportation_vehicles_10k')}
                  />
                  <FormInputText
                    label="Inserisci tutti i veicoli che trasportano le tue merci fino ad un valore di 25.000 euro"
                    placeholder="Inserisci tutti i veicoli che trasportano"
                    registration={register('transportation_vehicles_25k')}
                    error={errors.transportation_vehicles_25k?.message}
                    valid={isValid('transportation_vehicles_25k')}
                  />
                  <FormInputText
                    label="Inserisci tutti i veicoli che trasportano le tue merci fino ad un valore di 50.000 euro."
                    placeholder="Inserisci tutti i veicoli che trasportano"
                    registration={register('transportation_vehicles_50k')}
                    error={errors.transportation_vehicles_50k?.message}
                    valid={isValid('transportation_vehicles_50k')}
                  />
                </>
              )}
              <FormInputPrice
                label="Inserisci il totale del costo annuale delle merci vendute"
                placeholder="Inserisci"
                value={commercialFormData.annual_cost_of_goods_sold}
                name="annual_cost_of_goods_sold"
                onChange={(value) => setValue('annual_cost_of_goods_sold', value, {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true,
                })}
                error={errors.annual_cost_of_goods_sold?.message}
                valid={isValid('annual_cost_of_goods_sold')}
              />
              <FormInputRadio
                label="La società utilizza attrezzature proprie o di terzi?"
                options={flagResponse}
                name="uses_own_equipment"
                registration={register('uses_own_equipment')}
                error={errors.uses_own_equipment?.message}
              />
              {commercialFormData.uses_own_equipment == 1 && (
                <>
                  <FormInputPrice
                    label="Inserisci il nuovo valore delle tue attrezzature aziendali (compresi i dispositivi elettronici)"
                    placeholder="Inserisci"
                    value={commercialFormData.business_equipment_new_value}
                    name="business_equipment_new_value"
                    onChange={(value) => setValue('business_equipment_new_value', value, {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    })}
                    error={errors.business_equipment_new_value?.message}
                    valid={isValid('business_equipment_new_value')}
                  />
                  <FormInputPrice
                    label="Immettere il valore delle merci e delle forniture immagazzinate."
                    placeholder="Inserisci "
                    value={commercialFormData.goods_supplies_stored_value}
                    name="goods_supplies_stored_value"
                    onChange={(value) => setValue('goods_supplies_stored_value', value, {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    })}
                    error={errors.goods_supplies_stored_value?.message}
                    valid={isValid('goods_supplies_stored_value')}
                  />
                  <FormInputPrice
                    label="Inserisci il nuovo valore dei tuoi dispositivi elettronici (compresi nelle attrezzature aziendali)."
                    placeholder="Inserisci"
                    value={commercialFormData.electronic_devices_value}
                    name="electronic_devices_value"
                    onChange={(value) => setValue('electronic_devices_value', value, {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    })}
                    error={errors.electronic_devices_value?.message}
                    valid={isValid('electronic_devices_value')}
                  />
                </>
              )}
            </div>
          </>
        )} */}
        <div className="footer-buttons">
          <div className="d-flex justify-content-between">
            {/* {step > 1 && (
              <button className="btn btn-questionnaire" onClick={prevStep}>
                Back
              </button>
            )} */}
            <button className="btn btn-questionnaire" onClick={submitForm}>
              Continua
            </button>
            {/* {checkStepValidation() && (
              <button className="btn btn-questionnaire" onClick={nextStep}>
                Continua
              </button>
            )} */}
            {/* {step === 4 && (
              <button className="btn btn-questionnaire" type="submit">
                Continua
              </button>
            )} */}
          </div>
        </div>
      </form>
    </>
  );
}

export default Commercial;
