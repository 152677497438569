import styled from 'styled-components';

export const QuoteGenerateLoadingStyles = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 600px;
  z-index: 9000;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #00a451;
    color: #fff;
  }

  p {
    width: 70px;
    height: 70px;
    display: table;
    margin: 0px auto;
    border: 4px solid white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: bolder;
    font-size: 26px;
  }
`;
