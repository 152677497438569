import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
// import Joyride from 'react-joyride';
import { useSelector } from 'react-redux';
import { BrokerHomeStyles, WidgetPlaceholder } from './BrokerHome.styles';
import PageLoading from '../../common/components/elements/PageLoading';
import {
  getDashboardData,
  resetGetDashboardData,
} from '../../features/dashboard/dashboardActions';
import ClientSearchWidget from '../../common/widgets/ClientSearchWidget';
import StatsWidget from '../../common/widgets/StatsWidget';
import LatestUpdatesWidget from '../../common/widgets/LatestUpdatesWidget';
import AgendaWidget from '../../common/widgets/AgendaWidget';
import LatestArticles from '../../common/widgets/LatestArticles';
import MakeAQuoteWidget from '../../common/widgets/MakeAQuoteWidget';
import AnalisiRuiWidget from '../../common/widgets/AnalisiRuiWidget';
import SpeechToTextWidget from '../../common/widgets/SpeechToTextWidget';
import ChatBotWidget from '../../common/widgets/ChatBotWidget';
// import { useTourContext } from '../../context/useTourContext';
import ErrorComponent from '../../common/components/ErrorComponent';
// import { joyrideOptions } from '../../common/utils/joyrideUtils';
// import { handleCallbackBrokerHome } from '../../common/utils/joyrideCallbacks';
// import { brokerHomeSteps } from '../../common/utils/joyrideSteps';

function BrokerHome() {
  const state = useSelector((store) => store.dashboard.dashboard);
  const navigate = useNavigate();
  const {
    loading,
    error,
    widgets,
  } = state;

  const loadPageData = async () => {
    getDashboardData();
  };

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('newQuoteTour') === 'done') {
  //     setState({ run: false });
  //   } else {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 1500);
  //   }
  // }, [localStorage.getItem('newQuoteTour')]);

  // const fireNewQuoteTour = () => {
  //   localStorage.removeItem('newQuoteTour');
  //   setState({ run: true, tourActive: true });
  // };

  useEffect(() => {
    loadPageData();
    return () => {
      resetGetDashboardData();
    };
  }, []);

  if (loading) {
    return <PageLoading />;
  }

  if (error !== null) {
    return <ErrorComponent error={error} />;
  }

  return (
    <BrokerHomeStyles>
      {/* <Joyride
        steps={brokerHomeSteps}
        continuous
        showBeacon={showBeacon}
        locale={{
          back: 'Indietro',
          close: 'Chiudi',
          last: 'Fine',
          next: 'Prossima',
          open: 'Apri il modale',
          restart: 'Riavvia il tour ',
          skip: 'Salta',
        }}
        styles={{
          options: joyrideOptions,
          buttonNext: {
            backgroundColor: '#00a651',
            outline: 'none',
          },
          buttonBack: {
            color: '#00a651',
            outline: 'none',
          },
          beaconInner: {
            backgroundColor: '#00a651',
          },
          beaconOuter: {
            borderColor: '#00a651',
            backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
          },
          buttonSkip: {
            outline: 'none',
          },
          buttonClose: {
            outline: 'none',
          },
        }}
        run={run}
        callback={(data) => handleCallbackBrokerHome(data, brokerHomeSteps, setState)}
        showSkipButton
        showProgress
      /> */}
      <div className="row">
        <div className="col-12">
          <div className="d-flex align-items-center justify-content-end mb-2 gap-3">
            {/* {localStorage.getItem('newQuoteTour') === 'done' && (
              <div style={{ cursor: 'pointer' }} onClick={() => fireNewQuoteTour()}>
                <button className="btn btn-success">Tutorial</button>
              </div>
            )} */}

            <Link to="/widgets" className="btn btn-success d-table broker-home-two">
              Gestisci Widgets
            </Link>
          </div>
        </div>
      </div>
      <div className="row broker-home-three">
        {widgets.map((widget) => (
          <React.Fragment key={widget.widget_id}>
            {widget.widget_id === 1
              && (
                <div className="col-12 col-md-6 my-2 widget_1">
                  <ClientSearchWidget />
                </div>
              )}
            {widget.widget_id === 2
              && (
                <div className="col-12 col-md-6 my-2 widget_2">
                  <StatsWidget />
                </div>
              )}
            {widget.widget_id === 3
              && (
                <div className="col-12 col-md-6 my-2 widget_3">
                  <LatestUpdatesWidget />
                </div>
              )}
            {widget.widget_id === 4
              && (
                <div className="col-12 col-md-6 my-2 widget_4">
                  <AgendaWidget />
                </div>
              )}
            {widget.widget_id === 5
              && (
                <div className="col-12 col-md-6 my-2 widget_5">
                  <LatestArticles />
                </div>
              )}
            {widget.widget_id === 6
              && (
                <div className="col-12 col-md-6 my-2 widget_6">
                  <MakeAQuoteWidget />
                </div>
              )}
            {widget.widget_id === 7
              && (
                <div className="col-12 col-md-6 my-2 widget_7">
                  <AnalisiRuiWidget />
                </div>
              )}
            {widget.widget_id === 8
              && (
                <div className="col-12 col-md-6 my-2 widget_8">
                  <SpeechToTextWidget />
                </div>
              )}
            {widget.widget_id === 9
              && (
                <div className="col-12 col-md-6 my-2 widget_9">
                  <ChatBotWidget />
                </div>
              )}
          </React.Fragment>
        ))}
        {widgets.length < 4
          && (
            <div className="col-12 col-md-6 my-2">
              <WidgetPlaceholder className="card card-body h-100 custom-widget" onClick={() => navigate('/widgets')}>
                <FontAwesomeIcon icon={faPlus} size="6x" className="text-success opacity-75" />
                <h2>Aggiungi Widget</h2>
              </WidgetPlaceholder>
            </div>
          )}
      </div>
    </BrokerHomeStyles>
  );
}

export default BrokerHome;
