import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import Joyride from 'react-joyride';
import DraggableContainer from '../../common/dragndrop/DraggableContainer';
import { widgetsConstatns } from '../../common/constants';
import PageLoading from '../../common/components/elements/PageLoading';
import { createWidget, getAllWidgets } from '../../features/widgets/widgetsActions';
// import { useTourContext } from '../../context/useTourContext';
// import { joyrideOptions } from '../../common/utils/joyrideUtils';
// import { handleCallbackWidgetScreen } from '../../common/utils/joyrideCallbacks';
// import { widgetsScreenSteps } from '../../common/utils/joyrideSteps';

function WidgetsScreen() {
  const { loading, widgets } = useSelector((store) => store.widgets);
  const [activeWidgets, setActiveWidgets] = useState([]);
  const [inActiveWidgets, setInActiveWidgets] = useState([]);
  // const [steps, setSteps] = useState([]);

  useEffect(() => {
    getAllWidgets();
  }, []);

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('addWidgets') === 'done') {
  //     setState({ run: false });
  //   } else if (loading === false) {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 3000);
  //   }
  // }, [localStorage.getItem('addWidgets'), loading]);

  // useEffect(() => {
  //   widgetsScreenSteps(setSteps);
  // }, []);

  // const fireNewQuoteTour = () => {
  //   localStorage.removeItem('addWidgets');
  //   setState({ run: true, tourActive: true });
  // };

  useEffect(() => {
    const firstIds = widgets.map((item) => item.widget_id);
    const inactive = widgetsConstatns.filter((item) => !firstIds.includes(item.id));

    setInActiveWidgets(inactive);
    const active = widgetsConstatns.filter((item) => firstIds.includes(item.id));
    const finalActiveWidgets = [];

    active.forEach((a) => {
      widgets.forEach((el) => {
        if (a.id === el.widget_id) {
          finalActiveWidgets.push({
            ...a,
            order_nr: el.order_nr,
          });
        }
      });
    });

    setActiveWidgets(finalActiveWidgets.sort((a, b) => a.order_nr - b.order_nr));
  }, [widgets]);

  const insertWidget = (id) => {
    createWidget(id);
  };

  if (loading) {
    return <PageLoading />;
  }

  return (
    <>
      {/* <Joyride
        steps={steps}
        continuous
        showBeacon={showBeacon}
        locale={{
          back: 'Indietro',
          close: 'Chiudi',
          last: 'Fine',
          next: 'Prossima',
          open: 'Apri il modale',
          restart: 'Riavvia il tour ',
          skip: 'Salta',
        }}
        styles={{
          options: joyrideOptions,
          buttonNext: {
            backgroundColor: '#00a651',
            outline: 'none',
          },
          buttonBack: {
            color: '#00a651',
            outline: 'none',
          },
          beaconInner: {
            backgroundColor: '#00a651',
          },
          beaconOuter: {
            borderColor: '#00a651',
            backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
          },
          buttonSkip: {
            outline: 'none',
          },
          buttonClose: {
            outline: 'none',
          },
        }}
        run={run}
        callback={(data) => handleCallbackWidgetScreen(data, steps, setState)}
        showSkipButton
        showProgress
      />
      {localStorage.getItem('addWidgets') === 'done' && (
      <div style={{ cursor: 'pointer' }} onClick={() => fireNewQuoteTour()} className="mb-3 ">
        <button className="btn btn-success px-2 py-1">Tutorial</button>
      </div>
      )} */}
      <div className="row">
        <div className="col-12 col-md-4 order-2 order-md-0 widgets-disponibili">
          <h3 className="h4 bg-light p-3 rounded-2">Widgets Disponibili</h3>
          <hr />
          <div>
            {inActiveWidgets.map((widget) => (
              <div className="btn btn-light d-block text-start p-3 my-2" key={widget.id} onClick={() => insertWidget(widget.id)}>
                <FontAwesomeIcon icon={faPlus} />
                {' '}
                {widget.name}
              </div>
            ))}
          </div>
        </div>
        <div className="col-12 col-md-8 organizza-widgets-correnti">
          <h3 className="h4 bg-light p-3 rounded-2">Organizza Widgets Correnti</h3>
          <hr />
          <div>
            <DraggableContainer activeWidgets={activeWidgets} setActiveWidgets={setActiveWidgets} />
          </div>
        </div>
      </div>
    </>
  );
}

export default WidgetsScreen;
