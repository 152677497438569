import styled from 'styled-components';

export const OptionalDocumentModalStyles = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,.5);

    .modal-dialog {
        width: 100%;
        max-width: 450px;
        background-color: white;
        padding: 20px;
        border-radius: 10px;
    }
`;
