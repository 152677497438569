import styled from 'styled-components';

export const BrokersReportsStyles = styled.div`
  .rdp {
    --rdp-accent-color: var(--greenia-color);
  }

  .custom-card .card-data {
    width: 100%;
  }
`;

export const InputSelectStyle = styled.div`
  padding: 20px;
  padding-right: 0;
  width: 100%;
  max-width: 350px;
`;

export const SelectRangeContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 633px;

  .day-picker {
    position: absolute;
    top: 100px;
    left: -15px;
    width: 100%;
    max-width: 633px;
    padding: 20px;
    border: 1px solid var(--greenia-color);
    background-color: white;
    overflow-x: auto;
    z-index: 100;
  }
`;

export const SelectRangeStyle = styled.div`
  max-width: 150px;
  min-width: 150px;
  height: 40px;
  margin-top: 70px;
`;

export const InfoButton = styled.button`
    background-color: rgb(100,100,200);
    color: white;
    font-weight: bold;
    padding: 7px 10px;
    border: none;
    border-radius: 5px;
`;
