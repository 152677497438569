import styled from 'styled-components';

export const SecurityContainer = styled.div`
    margin: 50px auto;
    width: 100%;
    max-width: 500px;

    .header {
        padding: 10px 20px;
        background-color: var(--greenia-color);
        color: white;
        font-weight: bold;
        text-align: center;
    }

    .option {
        margin: 10px;
        padding: 10px 20px;
        border: 1px solid var(--greenia-color);
        border-radius: 10px;
        color: var(--greenia-color);
        cursor: pointer;
        transition: .3s;

        &:hover {
            padding-left: 30px;
        }
    }
`;
