import styled from 'styled-components';

export const ProfessionQuotesStyles = styled.div`
  margin-bottom: 80px;
  width: 100%;
  max-width: 1400px;
  margin: auto;
  padding: 20px;
  .failed-quotations-block {
    margin-top: 120px;
  }
  .failed-quotations-block img {
    width: 100%;
    display: block;
    aspect-ratio: 6/3;
    object-fit: contain;
    mix-blend-mode: multiply;
  }
`;
