import React from 'react';
import {
  AddGuaranteesButton, AquistaButtonsContainer, GuaranteesList, GuaranteesListItem, LeftCol, LogoContainer, LogoPriceContainer, MetlifeCardStyles, Price, PriceContainer, QuoteInformationContainer, QuoteInformationItem, RightCol, SelectedGuaranteesContainer,
} from './MetlifeCard.styles';
import MetLifeLogo from '../../../../assets/images/MetLife.png';

function MetlifeCard({ quote, setOpenModal }) {
  return (
    <MetlifeCardStyles className={quote.is_aquista === 1 ? 'selected' : ''}>
      <LeftCol>
        <LogoPriceContainer>
          <LogoContainer>
            <img src={MetLifeLogo} alt="Metlife Logo" />
          </LogoContainer>
          <PriceContainer>
            <Price>
              <h5>
                Prezzo annuo:
              </h5>
              <h2>
                &euro;
                {(parseFloat(quote.premio_annuo) + parseFloat(quote.ulc_premio_aggiuntivo) + parseFloat(quote.rsm_premio_aggiuntivo)).toLocaleString()}
              </h2>
            </Price>
          </PriceContainer>
        </LogoPriceContainer>
        <QuoteInformationContainer>
          <QuoteInformationItem>
            <h6>
              Compagnia:
            </h6>
            <p>
              {quote.company}
            </p>
          </QuoteInformationItem>
          <QuoteInformationItem>
            <h6>
              Prodotto:
            </h6>
            <p>
              {quote.pack_type}
            </p>
          </QuoteInformationItem>
          <QuoteInformationItem>
            <h6>
              Piano:
            </h6>
            <p>
              {quote.plan === 'nucleo' ? 'Nucleo' : 'Individuo'}
            </p>
          </QuoteInformationItem>
          <QuoteInformationItem>
            <h6>
              Decesso:
            </h6>
            <p>
              €
              {parseFloat(quote.dc).toLocaleString('en-US')}
            </p>
          </QuoteInformationItem>
        </QuoteInformationContainer>
        <QuoteInformationContainer>
          <QuoteInformationItem>
            <h6>
              Invalidita Permanente:
            </h6>
            <p>
              €
              {parseFloat(quote.ip).toLocaleString('en-US')}
            </p>
          </QuoteInformationItem>
          <QuoteInformationItem>
            <h6>
              Diaria Ricovero:
            </h6>
            <p>
              €
              {parseFloat(quote.diaria_ricovero).toLocaleString('en-US')}
            </p>
          </QuoteInformationItem>
          <QuoteInformationItem>
            <h6>
              Diaria Gesso:
            </h6>
            <p>
              €
              {parseFloat(quote.diaria_gesso).toLocaleString('en-US')}
            </p>
          </QuoteInformationItem>
        </QuoteInformationContainer>
        <QuoteInformationContainer>
          {quote.ulc !== null && (
            <QuoteInformationItem>
              <h6>
                Ustioni / Lussazioni / Comm. Cerebrale:
              </h6>
              <p>
                €
                {parseFloat(quote.ulc).toLocaleString('en-US')}
              </p>
            </QuoteInformationItem>
          )}
          {quote.rsm !== null && (
            <QuoteInformationItem>
              <h6>
                Rimborsi Spese Medice
              </h6>
              <p>
                €
                {parseFloat(quote.rsm).toLocaleString('en-US')}
              </p>
            </QuoteInformationItem>
          )}
        </QuoteInformationContainer>
      </LeftCol>
      <RightCol>
        {(quote.ulc !== null || quote.rsm !== null) && (
          <SelectedGuaranteesContainer>
            <h2>
              <b>Garanzie</b>
              {' '}
              Aggiunte
            </h2>
            <GuaranteesList>
              {quote.ulc !== null
                && (
                  <GuaranteesListItem>
                    <h6>
                      <span />
                      {' '}
                      Ustioni / Lussazioni / Comm. Cerebrale
                    </h6>
                    <p>
                      &euro;
                      {parseFloat(quote.ulc_premio_aggiuntivo).toLocaleString('en-US')}
                    </p>
                  </GuaranteesListItem>
                )}
              {quote.rsm !== null
                && (
                  <GuaranteesListItem>
                    <h6>
                      <span />
                      {' '}
                      Rimborsi Spese Medice
                    </h6>
                    <p>
                      &euro;
                      {parseFloat(quote.rsm_premio_aggiuntivo).toLocaleString('en-US')}
                    </p>
                  </GuaranteesListItem>
                )}
            </GuaranteesList>
          </SelectedGuaranteesContainer>
        )}
        <AquistaButtonsContainer>
          <AddGuaranteesButton onClick={() => setOpenModal(true)}>Seleziona Garanzie</AddGuaranteesButton>
        </AquistaButtonsContainer>
      </RightCol>
    </MetlifeCardStyles>
  );
}

export default MetlifeCard;
