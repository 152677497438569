import React from 'react';
import { ProfessionCardStyles } from './ProfessionCard.styles';
import {
  LeftCol, LogoPriceContainer, PriceContainer, RightCol,
} from './AdriaticCard.styles';
import { BACKEND_BASE_URL } from '../../../../config';
import {
  LogoResultContainer, PriceResult, QuoteInformationResultContainer, QuoteInformationResultItem,
} from '../../../../screens/BackOffice/results/cards/PrimaCard.styles';

function RiskCardSelected({ quote, type }) {
  return (
    <ProfessionCardStyles className={quote.is_aquista === 1 ? 'selected' : ''}>
      <div className="row-container">
        <LeftCol>
          <LogoPriceContainer>
            <LogoResultContainer>
              <img src={BACKEND_BASE_URL + quote.company.logo} alt={quote.company.name} />
            </LogoResultContainer>
            <PriceContainer>
              <PriceResult>
                <h5>
                  Prezzo annuo:
                </h5>
                <h2>
                  &euro;
                  {' '}
                  {parseFloat(quote.amount)}
                </h2>
              </PriceResult>
            </PriceContainer>
          </LogoPriceContainer>
          {/* <CompanyInformationContainer>
            {quote.quote_type === 'tutela_legale' && (
              <>
                <CompanyInfoElements
                  onClick={() => downloadResourceDocs(10, 0)}
                  title="questionario_pi_tutela_legale"
                >
                  <img src={preventiveIcon} alt="" />
                  <p>Questionario</p>
                </CompanyInfoElements>
                <CompanyInfoElements
                  onClick={() => downloadResourceDocs(9, 0)}
                  title="set_informativo_pi_Tutela_legale"
                >
                  <img src={preContractIcon} alt="" />
                  <p>Informativa Precontrattuale</p>
                </CompanyInfoElements>
              </>
            )}
          </CompanyInformationContainer> */}
          <QuoteInformationResultContainer>
            <QuoteInformationResultItem>
              <h6>
                Compagnia:
              </h6>
              <p>
                {quote.company.name}
              </p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>
                Prodotto:
              </h6>
              <p>
                {type.name}
              </p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>
                Massimale:
              </h6>
              <p>
                10000
                &euro;
              </p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>
                Frequenza pagamento:
              </h6>
              <p>
                {quote.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}
              </p>
            </QuoteInformationResultItem>
          </QuoteInformationResultContainer>
        </LeftCol>
        <RightCol />
      </div>
    </ProfessionCardStyles>
  );
}

export default RiskCardSelected;
