import { useSelector } from 'react-redux';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import Joyride from 'react-joyride';
import PageLoading from '../../common/components/elements/PageLoading';
import { getSectionEBrokerPurchasedQuotations, getSectionEBrokerQuotations, resetSectionEBrokerQuotations } from '../../features/brokers/brokersActions';
import { BACKEND_BASE_URL } from '../../config';
import { BrokerQuotationsStyles, CompanyLogo } from './BrokerQuotations.styles';
import StorageService from '../../services/StorageService';
import PaginationSezE from '../../common/components/elements/PaginationSezE';
import { productsList } from '../../common/constants';
import ErrorComponent from '../../common/components/ErrorComponent';
// import { useTourContext } from '../../context/useTourContext';
// import { joyrideOptions } from '../../common/utils/joyrideUtils';
// import { handleCallbackBrokerQuotes } from '../../common/utils/joyrideCallbacks';
// import { brokerQuotationsSteps } from '../../common/utils/joyrideSteps';

const storage = new StorageService();

function BrokerQuotations() {
  const [currentTab, setCurrentTab] = useState(1); // ? 1 - In Progress Quotes --- 2 - Purchased Quotes
  const [inProgressSearch, setInProgressSearch] = useState({
    client: '',
    product: '',
  });
  const [purchasedSearch, setPurchasedSearch] = useState({
    client: '',
    product: '',
  });
  const navigate = useNavigate();
  const { brokerId } = useParams();
  // const [steps, setSteps] = useState([]);

  const state = useSelector((store) => store.brokers.broker_section_e_quotations);

  const {
    loading, error, broker, quotations, page_number, total_pages, purchased_quotations,
  } = state;

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('controllaProduzione') === 'done') {
  //     setState({ run: false });
  //   } else if (loading === false) {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 3000);
  //   }
  // }, [localStorage.getItem('controllaProduzione'), loading]);

  // useEffect(() => {
  //   brokerQuotationsSteps(setSteps);
  // }, []);

  // const fireNewQuoteTour = () => {
  //   localStorage.removeItem('controllaProduzione');
  //   setState({ run: true, tourActive: true });
  // };

  useEffect(() => {
    if (currentTab === 1) {
      getSectionEBrokerQuotations(1, inProgressSearch, brokerId);
    }

    if (currentTab === 2) {
      getSectionEBrokerPurchasedQuotations(1, purchasedSearch, brokerId);
    }

    return () => {
      resetSectionEBrokerQuotations();
    };
  }, [currentTab]);

  const handleInProgressSearch = (e) => {
    e.preventDefault();
    if (inProgressSearch.client !== '' && inProgressSearch !== '') {
      getSectionEBrokerQuotations(1, inProgressSearch, brokerId);
    }
  };

  const handlePurchasedSearch = (e) => {
    e.preventDefault();
    if (purchasedSearch.client !== '' && purchasedSearch !== '') {
      getSectionEBrokerPurchasedQuotations(1, purchasedSearch, brokerId);
    }
  };

  const goToQuotation = (isAquista, productId, quotationId, requestToken) => {
    if (isAquista) {
      const routeByProduct = {
        1: `/quotes/auto/purchase/${quotationId}`,
        2: `/quotes/moto/purchase/${quotationId}`,
        3: `/quotes/autocarro/purchase/${quotationId}`,
        4: `/quotes/profession/purchase/${quotationId}`,
        5: `/quotes/metlife/purchase/${quotationId}`,
        6: `/quotes/infodrive/purchase/${quotationId}`,
      };

      navigate(routeByProduct[productId]);
      return;
    }

    const routeByProduct = {
      1: `/quotes/${requestToken}`,
      2: `/quotes/moto/${requestToken}`,
      3: `/quotes/${requestToken}`,
      4: `/quotes/profession/${requestToken}`,
      5: `/quotes/metlife/${requestToken}`,
      6: `/quotes/infodrive/${requestToken}`,
    };

    navigate(routeByProduct[productId]);
  };

  const calculatePaymentsCommissions = (payments) => {
    let total = 0;

    payments.forEach((p) => {
      p.commissions.forEach((c) => {
        total += c.amount;
      });
    });

    return total;
  };

  const handleTabChange = (val) => {
    setCurrentTab(val);
  };

  const calculateManagingBrokerCommissions = (payments) => {
    let total = 0;

    const user = storage.getUser();

    payments.forEach((p) => {
      p.commissions.filter((c) => c.broker_id === user.id).forEach((c) => {
        total += c.amount;
      });
    });

    return total;
  };

  if (loading) {
    return <PageLoading />;
  }

  if (error !== null) {
    return <ErrorComponent error={error} />;
  }

  return (
    <BrokerQuotationsStyles>
      {/* <Joyride
        steps={steps}
        continuous
        showBeacon={showBeacon}
        locale={{
          back: 'Indietro',
          close: 'Chiudi',
          last: 'Fine',
          next: 'Prossima',
          open: 'Apri il modale',
          restart: 'Riavvia il tour ',
          skip: 'Salta',
        }}
        styles={{
          options: joyrideOptions,
          buttonNext: {
            backgroundColor: '#00a651',
            outline: 'none',
          },
          buttonBack: {
            color: '#00a651',
            outline: 'none',
          },
          beaconInner: {
            backgroundColor: '#00a651',
          },
          beaconOuter: {
            borderColor: '#00a651',
            backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
          },
          buttonSkip: {
            outline: 'none',
          },
          buttonClose: {
            outline: 'none',
          },
        }}
        run={run}
        callback={(data) => handleCallbackBrokerQuotes(data, steps, setState)}
        showSkipButton
        showProgress
      /> */}
      <div className="mb-3">
        <h4>
          Produzione del'intermediario:
          {' '}
          <b>
            {broker.name}
            {' '}
            {broker.surname}
          </b>
        </h4>
      </div>
      <hr />

      <div className="mt-3">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center justify-content-start gap-2">
            <button className={`in-processo-produzione btn btn-${currentTab === 1 ? 'success' : 'outline-success'}`} onClick={() => handleTabChange(1)}>In Processo</button>
            <button className={`in-aquista-produzione btn btn-${currentTab === 2 ? 'success' : 'outline-success'}`} onClick={() => handleTabChange(2)}>Aquistati</button>
          </div>
          {/* {localStorage.getItem('controllaProduzione') === 'done' && (
            <div style={{ cursor: 'pointer' }} onClick={() => fireNewQuoteTour()} className="mb-3 ">
              <button className="btn btn-success">Tutorial</button>
            </div>
          )} */}
        </div>
      </div>

      {currentTab === 1 && (
        <div className="mt-4">
          <form onSubmit={handleInProgressSearch}>
            <div className="row search-client-product-broker-quotation">
              <div className="col-4">
                <input type="text" className="form-control" placeholder="Cerca per cliente..." onChange={(e) => setInProgressSearch({ ...inProgressSearch, client: e.target.value })} name="client" />
              </div>
              <div className="col-4">
                <select name="product" className="form-control" defaultValue="" onChange={(e) => setInProgressSearch({ ...inProgressSearch, product: e.target.value })}>
                  <option value="">Seleziona Prodotto</option>
                  {productsList.map((prod) => (
                    <option key={prod.id} value={prod.id}>{prod.name}</option>
                  ))}
                </select>
              </div>
              <div className="col-4">
                <button className="btn btn-dark" type="submit">Cerca</button>
              </div>
            </div>
          </form>
          <div className="mt-4">
            <div className="table-responsive ">
              <table className="custom-table product-table-broker-quotation">
                <thead>
                  <tr>
                    <th>Prodotto</th>
                    <th className="header-center">Compagnia</th>
                    <th>Status</th>
                    <th>Cliente</th>
                    <th className="amount-header">Premio</th>
                    <th className="amount-header">Premio Pagato</th>
                    <th>Data</th>
                  </tr>
                </thead>
                <tbody>
                  {quotations.map((q) => (
                    <tr className="custom-table-row" key={q.id} onClick={() => goToQuotation(q.is_aquista, q.product_id, q.id, q.insurance_request.request_token)}>
                      <td>{q.product.name}</td>
                      <td className="d-flex justify-content-center align-items-center">
                        {q.company_id !== null && (
                          <CompanyLogo>
                            <img className="company-logo" src={BACKEND_BASE_URL + q.company.logo} />
                          </CompanyLogo>
                        )}

                        {q.preventivas_company_id !== null && (
                          <CompanyLogo>
                            <img className="company-logo" src={`${BACKEND_BASE_URL}/preventivass/companies/${q.preventivas_company.logo_filename}`} />
                          </CompanyLogo>
                        )}

                      </td>
                      <td>{q.quotation_status.name}</td>
                      <td>{q.insurance_request.customer.gender === 'G' ? q.insurance_request.customer.business_name : `${q.insurance_request.customer.name} ${q.insurance_request.customer.surname}`}</td>
                      <td className="amount">
                        {q.amount}
                        &euro;
                      </td>
                      <td className="amount">
                        {q.amount_paid !== null ? q.amount_paid : 0}
                        &euro;
                      </td>
                      <td>{moment(q.created_at).calendar()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="mt-2">
              <PaginationSezE total_pages={total_pages} current_page={page_number} onClick={getSectionEBrokerQuotations} brokerId={brokerId} />
            </div>
          </div>
        </div>
      )}

      {currentTab === 2 && (
        <div className="mt-4">
          <form onSubmit={handlePurchasedSearch}>
            <div className="row">
              <div className="col-4">
                <input type="text" className="form-control" placeholder="Cerca per cliente..." onChange={(e) => setPurchasedSearch({ ...purchasedSearch, client: e.target.value })} name="client" />
              </div>
              <div className="col-4">
                <select name="product" className="form-control" defaultValue="" onChange={(e) => setPurchasedSearch({ ...purchasedSearch, product: e.target.value })}>
                  <option value="">Seleziona Prodotto</option>
                  {productsList.map((prod) => (
                    <option key={prod.id} value={prod.id}>{prod.name}</option>
                  ))}
                </select>
              </div>
              <div className="col-4">
                <button className="btn btn-dark" type="submit">Cerca</button>
              </div>
            </div>
          </form>
          <div className="mt-4">
            <div className="table-responsive">
              <table className="custom-table">
                <thead>
                  <tr>
                    <th>Prodotto</th>
                    <th className="header-center">Compagnia</th>
                    <th>Status</th>
                    <th>Cliente</th>
                    <th className="amount-header">Premio</th>
                    <th className="amount-header">Premio Pagato</th>
                    <th className="amount-header">Comissioni Totali</th>
                    <th className="amount-header">Comissioni Guadagnate</th>
                    <th>Data</th>
                  </tr>
                </thead>
                <tbody>
                  {purchased_quotations.map((q) => (
                    <tr className="custom-table-row" key={q.id} onClick={() => goToQuotation(q.is_aquista, q.product_id, q.insurance_request.request_token, q.insurance_request.request_token)}>
                      <td>{q.product.name}</td>
                      <td className="d-flex justify-content-center align-items-center">
                        {q.company_id !== null && (
                          <CompanyLogo>
                            <img className="company-logo" src={BACKEND_BASE_URL + q.company.logo} />
                          </CompanyLogo>
                        )}

                        {q.preventivas_company_id !== null && (
                          <CompanyLogo>
                            <img className="company-logo" src={`${BACKEND_BASE_URL}/preventivass/companies/${q.preventivas_company.logo_filename}`} />
                          </CompanyLogo>
                        )}
                      </td>
                      <td>{q.quotation_status.name}</td>
                      <td>{q.insurance_request.customer.gender === 'G' ? q.insurance_request.customer.business_name : `${q.insurance_request.customer.name} ${q.insurance_request.customer.surname}`}</td>
                      <td className="amount">
                        {q.amount}
                        &euro;
                      </td>
                      <td className="amount">
                        {q.amount_paid !== null ? q.amount_paid : 0}
                        &euro;
                      </td>
                      <td className="amount">
                        {calculatePaymentsCommissions(q.payments).toFixed(2)}
                        &euro;
                      </td>
                      <td className="amount">
                        {calculateManagingBrokerCommissions(q.payments).toFixed(2)}
                        &euro;
                      </td>
                      <td>{moment(q.created_at).calendar()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="mt-2">
              <PaginationSezE total_pages={total_pages} current_page={page_number} onClick={getSectionEBrokerPurchasedQuotations} brokerId={brokerId} />
            </div>
          </div>
        </div>
      )}
    </BrokerQuotationsStyles>
  );
}

export default BrokerQuotations;
