import { downloadResourceDocs } from '../../services/axios-client/axeCommons';
import { UsefulDocumentsStyles } from './UsefulDocuments.styles';

export function UsefulDocuments() {
  const filesList = [
    {
      id: 1,
      name: 'Scarico Greenia.pdf',
    },
    {
      id: 2,
      name: 'Privacy.pdf',
    },
    {
      id: 3,
      name: 'precontrattuale completa Greenia.pdf',
    },
    {
      id: 4,
      name: 'Dichiarazione CF errato.pdf',
    },
    {
      id: 5,
      name: 'stato-di-famiglia.pdf',
    },
    {
      id: 6,
      name: 'modello-autocertificazione-residenza.pdf',
    },
  ];

  const downloadFile = (id) => {
    downloadResourceDocs(11, id);
  };

  return (
    <UsefulDocumentsStyles>
      <div className="files-list">
        {filesList.map((f) => (
          <div key={f.id} className="file-list d-flex align-items-center justify-content-between">
            <div className="file-name">{f.name}</div>
            <div className="btn btn-success" onClick={() => downloadFile(f.id)}>Scarica</div>
          </div>
        ))}
      </div>
    </UsefulDocumentsStyles>
  );
}
