import parseJson from '../common/helpers/parseJSON';
import stringifyJson from '../common/helpers/stringifyJSON';

export default class StorageService {
  getToken() {
    return localStorage.getItem('jwtToken');
  }

  setToken(userToken) {
    localStorage.setItem('jwtToken', userToken);
  }

  getBackOfficeToken() {
    return localStorage.getItem('backOfficeJwtToken');
  }

  setBackOfficeToken(backOfficeToken) {
    localStorage.setItem('backOfficeJwtToken', backOfficeToken);
  }

  removeBackOfficeToken() {
    localStorage.removeItem('backOfficeJwtToken');
  }

  removeToken() {
    localStorage.removeItem('jwtToken');
  }

  getUser() {
    const user = localStorage.getItem('user');
    if (user !== null) {
      return parseJson(user);
    }
    return null;
  }

  setUser(user) {
    localStorage.setItem('user', stringifyJson(user));
  }

  removeUser() {
    localStorage.removeItem('user');
  }

  setRefreshToken(refreshToken) {
    localStorage.setItem('jwtRefreshToken', refreshToken);
  }

  getRefreshToken() {
    return localStorage.getItem('jwtRefreshToken');
  }

  removeRefreshToken() {
    localStorage.removeItem('jwtRefreshToken');
  }

  saveSelectedProfession(profession) {
    localStorage.setItem('profession', stringifyJson(profession));
  }

  getSelectedProfession() {
    return parseJson(localStorage.getItem('profession'));
  }

  removeSelectedProfession() {
    localStorage.removeItem('profession');
  }

  setVehicleQuoteAnswers(answers) {
    localStorage.setItem('vqa', stringifyJson(answers));
  }

  getVehicleQuoteAnswers() {
    return parseJson(localStorage.getItem('vqa'));
  }

  removeVehicleQuoteAnswers() {
    localStorage.removeItem('vqa');
  }

  setMotoQuoteAnswers(answers) {
    localStorage.setItem('mqa', stringifyJson(answers));
  }

  getMotoQuoteAnswers() {
    return parseJson(localStorage.getItem('mqa'));
  }

  removeMotoQuoteAnswers() {
    localStorage.removeItem('mqa');
  }

  setVehicleOwnerData(owner) {
    return localStorage.setItem('vo', stringifyJson(owner));
  }

  removeVehicleOwnerData() {
    localStorage.removeItem('vo');
  }

  getVehicleOwnerData() {
    return parseJson(localStorage.getItem('vo'));
  }

  setVehicleDriverData(driver) {
    return localStorage.setItem('vd', stringifyJson(driver));
  }

  getVehicleDriverData() {
    return parseJson(localStorage.getItem('vd'));
  }

  removeVehicleDriverData() {
    localStorage.removeItem('vd');
  }

  setBersani(bersani) {
    return localStorage.setItem('bersani', stringifyJson(bersani));
  }

  getBersani() {
    return parseJson(localStorage.getItem('bersani'));
  }

  removeBersani() {
    localStorage.removeItem('bersani');
  }

  setTranscription(transcription) {
    return localStorage.setItem('transcription', stringifyJson(transcription));
  }

  getTranscription() {
    return parseJson(localStorage.getItem('transcription'));
  }

  removeTranscription() {
    localStorage.removeItem('transcription');
  }

  setTravelData(data) {
    return localStorage.setItem('td', stringifyJson(data));
  }

  getTravelData() {
    return parseJson(localStorage.getItem('td'));
  }

  removeTravelData() {
    localStorage.removeItem('td');
  }
}
