import styled from 'styled-components';

export const PageLoadingFullStyles = styled.div`
  width: 200px;
  background-color: rgba(255, 255, 255, 0.8);
  height: 100px;
  position: fixed;
  top: calc(50% - 100px);
  left: calc(50% - 50px);
  z-index: 99999;
  border-radius: 10px;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(255, 255, 255, 1);
  .content-loading {
    z-index: 9000;
  }
`;
