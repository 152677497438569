import React from 'react';
import { questionarioCommercial } from '../questions';
import { FormInputSelect } from '../../../../common/formInputs/FormInputSelect';
import { FormInputRadio } from '../../../../common/formInputs/FormInputRadio';
import { flagResponse, questionInCommercial } from '../../../../common/constants';

function StructureEdilizia({ register, errorMessages }) {
  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id="headingTwo">
        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
          {questionarioCommercial[1].title}
        </button>
      </h2>
      <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
        <div className="accordion-body">
          <ul className="list-unstyled">
            {questionarioCommercial[1].questions.map((question) => (
              <li className="mb-2">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <p className="mb-0 text-center">{question.question}</p>
                  {question.input_type === 'select' ? (
                    <FormInputSelect
                      registration={register(`${question.input_name}`)}
                      placeholder="-Seleziona-"
                      options={questionInCommercial}
                      formGroupClassName="w-50"
                      value=""
                      error={errorMessages(question.input_name)}
                    />
                  ) : (
                    <FormInputRadio
                      options={flagResponse}
                      registration={register(`${question.input_name}`)}
                      error={errorMessages(question.input_name)}
                    />
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default StructureEdilizia;
