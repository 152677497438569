import React from 'react';
import {
  BoxWrapper, ClientBox,
} from './CommonPurchase.styles';
import MessagesModal from '../../../elements/MessagesModal';
import { downloadResourceDocs } from '../../../../../services/axios-client/axeCommons';

const set_informativi = {
  1: 'set_informativo_hcc_ingegneri.pdf',
  2: 'set_informativo_hcc_architetto.pdf',
  3: 'set_informativo_hcc_avvocato.pdf',
  4: 'set_informativo_hcc_Commercialista.pdf',
  5: 'set_informativo_hcc_consulente_del_lavoro.pdf',
  6: 'set_informativo_hcc_agente_immobiliare.pdf',
  7: 'set_informativo_hcc_agenti_di_attivita_finanziaria.pdf',
  8: 'set_informativo_hcc_amministratore_condominio.pdf',
  9: 'set_informativo_hcc_geometri.pdf',
  10: 'set_informativo_hcc_mediatore_creditizi.pdf',
  11: 'set_informativo_hcc_periti.pdf',
  12: 'set_informativo_hcc_tributarista.pdf',
  13: '',
  14: 'set_informativo_hcc_ag_commercio.pdf',
  15: 'set_informativo_hcc_agronomi.pdf',
  16: 'set_informativo_hcc_societa_di_edp.pdf',
  17: 'set_informativo_hcc_chimichi.pdf',
  18: 'set_informativo_hcc_dpo.pdf',
  19: 'set_informativo_hcc_geologi.pdf',
  20: 'set_informativo_hcc_miscellaneous.pdf',
  21: 'set_informativo_hcc_miscellaneous_energetica.pdf',
  22: 'set_informativo_hcc_psicologo.pdf',
  23: 'set_informativo_hcc_revisore_legale_dei_conti.pdf',
  24: '',
  // "24": "set_informativo_hcc_singolo_componente_mediazione.pdf",
  25: 'set_informativo_hcc_tecnologo_alimentare.pdf',
  26: 'set_informativo_hcc_conformita.pdf',
};

function InfoBoxProfessione({
  insuranceRequest, statues, messagesModalData, rc_quotation, tutela_quotation,
}) {
  const getTotal = () => {
    let quoteInProccessAmount = 0;
    let tutelaInProccessAmount = 0;

    if (rc_quotation !== null) {
      quoteInProccessAmount = parseFloat(rc_quotation.amount);
    }
    if (tutela_quotation !== null) {
      tutelaInProccessAmount = parseFloat(tutela_quotation.amount);
    }

    const total = quoteInProccessAmount + tutelaInProccessAmount;

    return total.toFixed(2);
  };

  return (
    <>
      <BoxWrapper>
        <div className="row gap-2 gap-xl-0">
          <div className="col-12 col-xl-4">
            <ClientBox className="documents-status-step-one pagament-status-step-one">
              <h6>Cliente:</h6>
              <h5>
                {insuranceRequest.customer.gender !== 'G' ? `${insuranceRequest.customer.name} ${insuranceRequest.customer.surname}` : insuranceRequest.customer.business_name}
              </h5>
              <h6 className="mt-2">Fatturato:</h6>
              <h5>
                &euro;
                {' '}
                {insuranceRequest.professional.billed}
              </h5>
              <h6>Status:</h6>
              <h5 className="mt-2">
                {statues.find((stat) => stat.id === rc_quotation.status).name}
              </h5>
              <h6 className="mt-2">Professione:</h6>
              <h5>
                {insuranceRequest.professional.profession_desc}
              </h5>
              <h6 className="mt-2">Alto rischio:</h6>
              <h5>
                {insuranceRequest.professional.high_risk}
              </h5>
              <h6 className="mt-2">Massimale Fatturato:</h6>
              <h5>
                &euro;
                {' '}
                {parseFloat(insuranceRequest.professional.billed_maximum).toLocaleString('en-US')}
              </h5>
              <h6 className="mt-2">Decorrenza:</h6>
              <h5>
                {new Date(
                  insuranceRequest.policy_effective_date,
                ).toLocaleDateString('it-IT')}
              </h5>
              {set_informativi[insuranceRequest.professional.profession] !== ''
                && (
                  <>
                    <h6
                      className="mt-2 text-decoration-underline"
                      style={{ cursor: 'pointer' }}
                      onClick={() => downloadResourceDocs(8, insuranceRequest.professional.profession)}
                    >
                      Informazioni Precontrattuali
                    </h6>
                    <h6 className="mt-2 text-decoration-underline" onClick={() => downloadResourceDocs(4, insuranceRequest.professional.profession)} style={{ cursor: 'pointer' }}>Questionario</h6>
                  </>
                )}
            </ClientBox>
          </div>
          <div className="col-12 col-xl-8">
            <ClientBox>
              <h4>Informazioni Prodotti</h4>
              <hr />
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Prodotto</th>
                    <th scope="col">Compagnia</th>
                    <th scope="col">Frequenza Pagamento</th>
                    <th scope="col">Premio</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{rc_quotation.product.name}</td>
                    <td>
                      {rc_quotation.company_id !== null ? rc_quotation.company.name : rc_quotation.preventivas_company.company_name}
                    </td>
                    <td>{rc_quotation.payment_frequency === 1 ? 'Annuale' : 'Semestrale'}</td>
                    <td>
                      {parseFloat(rc_quotation.amount)}
                      {' '}
                      &euro;
                    </td>
                  </tr>
                  {tutela_quotation !== null && (
                    <tr>
                      <td>Tutela Legale</td>
                      <td>
                        {tutela_quotation.company.name}
                      </td>
                      <td>{tutela_quotation.payment_frequency === 1 ? 'Annuale' : 'Semestrale'}</td>
                      <td>
                        {parseFloat(tutela_quotation.amount)}
                        {' '}
                        &euro;
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot className="bg-secondary">
                  <tr>
                    <td className="fw-bold">
                      Totale
                    </td>
                    <td />
                    <td />
                    <td className="fw-bold">
                      &euro;
                      {' '}
                      {getTotal()}
                    </td>
                  </tr>
                </tfoot>
              </table>
              {/* <hr /> */}
              {/* <ContactBox
                as="button"
                data-bs-toggle="modal"
                data-bs-target="#messages-modal"
              >
                <img src={contactSupport} alt="Conttato Suporto" />
                {insuranceRequest.threads.length > 0 && insuranceRequest.threads[0].messages.filter((mes) => mes.read_by_broker === 0).length > 0 ? <MessageNumber>{insuranceRequest.threads[0].messages.filter((mes) => mes.read_by_broker === 0).length}</MessageNumber> : ''}
                <h5>{insuranceRequest.threads.length > 0 ? 'Apri Chat' : 'Apri Nuova Chat'}</h5>
              </ContactBox> */}
            </ClientBox>
          </div>
        </div>

        {/* {tutela_quotation !== null && (
          <ClientBox className="documents-status-step-tutela pagament-status-step-tutela ">
            <h6>Compagnia:</h6>
            <h5>
              {tutela_quotation.company.name}
            </h5>
            <h6 className="mt-2">Prodotto:</h6>
            <h5>
              {tutela_quotation.risk_information ? tutela_quotation.risk_information.product_name : 'Tutela Legale'}
            </h5>
            {tutela_quotation.risk_information
              && (
                <>
                  <h6 className="mt-2">Massimale:</h6>
                  <h5>
                    {tutela_quotation.risk_information.massimale}
                    {' '}
                    &euro;
                  </h5>
                </>
              )}
            <h6>Status:</h6>
            <h5 className="mt-2">
              {statues.find((stat) => stat.id === tutela_quotation.status).name}
            </h5>
            <h6 className="mt-2">Premio:</h6>
            <h5>
              {parseFloat(tutela_quotation.amount)}
              {' '}
              &euro;
            </h5>
          </ClientBox>
        )} */}
        {/* <ClientBox className="justify-content-between documents-status-step-three pagament-status-step-three">
          <div>
            <h6 className="mt-2 fs-6">
              Premio Totale:
            </h6>
            <h5 className="fs-5">
              &euro;
              {' '}
              {getTotal()}
            </h5>
          </div>
          <ContactBox
            as="button"
            data-bs-toggle="modal"
            data-bs-target="#messages-modal"
          >
            {insuranceRequest.threads.length > 0 && insuranceRequest.threads[0].messages.filter((mes) => mes.read_by_broker === 0).length > 0 ? <MessageNumber>{insuranceRequest.threads[0].messages.filter((mes) => mes.read_by_broker === 0).length}</MessageNumber> : ''}
            <img src={contactSupport} alt="Conttato Suporto" />
            <h5>{insuranceRequest.threads.length > 0 ? 'Apri Chat' : 'Apri Nuova Chat'}</h5>
          </ContactBox>
        </ClientBox> */}
      </BoxWrapper>
      <MessagesModal
        {...messagesModalData}
        threads={insuranceRequest.threads}
      />
    </>
  );
}

export default InfoBoxProfessione;
