export default function UploadIcon() {
  return (
    <svg
      width="10.0596mm"
      height="8.1482mm"
      version="1.1"
      viewBox="0 0 10.78 8.73"
    >
      <defs />
      <g id="Layer_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <g id="_105553291421280">
          <path
            fill="currentColor"
            d="M3.73 1.61c-0.07,-0.02 -0.09,-0.05 -0.15,-0.08 -0.57,-0.3 -1.31,-0.18 -1.77,0.29 -0.2,0.2 -0.44,0.6 -0.44,1 -0.28,0.02 -0.75,0.45 -0.94,0.7 -0.26,0.32 -0.41,0.76 -0.43,1.21l0 0.21c0.01,0.15 0.03,0.3 0.07,0.44 0.1,0.39 0.3,0.69 0.57,0.97 0.35,0.36 0.88,0.61 1.43,0.61l1.77 0c0.11,0 0.2,-0.04 0.24,-0.12 0.05,-0.1 0.03,-0.21 -0.04,-0.27 -0.12,-0.11 -0.48,-0.06 -0.68,-0.06 -0.75,0 -1.44,0.11 -2.04,-0.2 -1.23,-0.63 -1.15,-2.59 0.22,-3.09 0.23,-0.08 0.27,-0.07 0.28,-0.39 0.01,-0.52 0.51,-1.01 0.97,-1.01 0.25,0 0.41,0 0.63,0.13 0.28,0.17 0.21,0.23 0.4,0.23 0.18,0 0.2,-0.18 0.27,-0.31 0.92,-1.96 3.47,-1.81 4.3,-0.12 0.06,0.13 0.13,0.29 0.17,0.43 0.04,0.17 0.08,0.31 0.09,0.51 0,0.11 0.03,0.16 0.02,0.28 -0.02,0.18 -0.09,0.44 0.19,0.47 0.38,0.04 0.59,0.06 0.94,0.34 0.68,0.55 0.76,1.6 0.11,2.28 -0.22,0.23 -0.59,0.44 -1.03,0.44 -0.48,0 -0.97,-0 -1.45,-0 -0.2,0 -0.54,-0.05 -0.66,0.06 -0.07,0.06 -0.09,0.16 -0.05,0.26 0.04,0.09 0.13,0.14 0.25,0.14l1.94 0c0.74,0 1.42,-0.48 1.71,-1.12 0.16,-0.36 0.19,-0.5 0.19,-0.93 0,-0.72 -0.47,-1.37 -1.01,-1.66 -0.15,-0.08 -0.46,-0.22 -0.67,-0.22 0,-0.42 -0.06,-0.78 -0.17,-1.08 -0.16,-0.43 -0.39,-0.84 -0.72,-1.16l-0.17 -0.15c-0.82,-0.72 -2.05,-0.85 -3.08,-0.32 -0.39,0.2 -0.75,0.52 -1,0.88 -0.07,0.1 -0.2,0.28 -0.23,0.41z"
          />
          <path
            d="M4.11 5.13c0,0.1 -0.01,0.15 0.05,0.21 0.28,0.3 0.66,-0.45 1.01,-0.69l0 3.24c0,0.32 -0.07,0.84 0.23,0.84 0.31,-0.01 0.23,-0.5 0.23,-0.84l0 -3.24c0.35,0.24 0.7,0.96 0.99,0.7 0.21,-0.19 -0.17,-0.51 -0.22,-0.56l-1.01 -1.02 -0.66 0.66c-0.1,0.1 -0.63,0.6 -0.63,0.7z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
