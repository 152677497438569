import React, { useEffect, useState } from 'react';
import {
  // ButtonContainer, ButtonManual,
  ImageContainer,
  MainTitle,
  NumberCurrent,
  SignatureInfoWrapper,
  SignaturePaperContainer,
  SignatureTypeWrapper,
  TextContainer,
  TitleButtonsWrapper,
  WarningContainer,
} from './SignatureType.styles';
import warning from '../../../../assets/redesign_icons/warning-icon.svg';
import arrowGreen from '../../../../assets/redesign_icons/arrow-down.svg';
// import { professionSelectSignMethod } from '../../../../features/purchases/purchasesActions';
import ProfessioneDocuments from './Documents/ProfessioneDocuments';

function SignatureTypeProfessione({
  quote,
  insuranceRequest,
  collectQuoteData,
  signableDocs,
  requestedOptionalDocumentsSignable,
}) {
  const [selected, setSelected] = useState(null);
  const [digitalSign, setDigitalSign] = useState(false);

  // const selectSignatureMethod = (method) => {
  //   if (method === 0) {
  //     professionSelectSignMethod(method, quote.id, collectQuoteData);
  //     setSelected(method);
  //   }
  //   if (method === 1) {
  //     setDigitalSign(!digitalSign);
  //   }
  // };

  useEffect(() => {
    if (quote.initial_signature_type !== null) {
      setSelected(quote.initial_signature_type);
    }
  }, [quote]);

  useEffect(() => {
    if (quote.initial_signature_type === 0) {
      setDigitalSign(false);
    }

    if (quote.initial_signature_type === 1) {
      setDigitalSign(true);
    }
  }, [quote]);

  return (
    <SignatureTypeWrapper>
      <TitleButtonsWrapper>
        <div className="d-flex align-items-center justify-content-start">
          <NumberCurrent>2</NumberCurrent>
          <MainTitle>
            {' '}
            Firma i documenti del
            {' '}
            <b>cliente</b>
          </MainTitle>
        </div>
        {/* <ButtonContainer>
          <ButtonManual className={selected === 0 ? 'active-signature' : ''} onClick={quote.initial_signature_type === 1 || quote.initial_signature_type === 0 ? () => { } : () => selectSignatureMethod(0)}>Cartacea</ButtonManual>
        </ButtonContainer> */}
      </TitleButtonsWrapper>
      {selected === null && digitalSign === false ? (
        <SignatureInfoWrapper>
          <TextContainer>
            <h3>Seleziona uno dei tipi di firma sullo switcher sopra.</h3>
            <WarningContainer>
              <img src={warning} />
              {' '}
              <p>
                Fai attenzione, quando continui con uno dei tipi di firma non
                puoi modificarlo in seguito
              </p>
            </WarningContainer>
          </TextContainer>
          <ImageContainer>
            <img src={arrowGreen} alt="" />
          </ImageContainer>
        </SignatureInfoWrapper>
      ) : (
        ''
      )}
      {quote.initial_signature_type === 0 && (
        <SignaturePaperContainer>
          <ProfessioneDocuments
            signable={1}
            insuranceRequest={insuranceRequest}
            quote={quote}
            collectQuoteData={collectQuoteData}
            requestedOptionalDocumentsSignable={
              requestedOptionalDocumentsSignable
            }
            signableDocs={signableDocs}
          />
        </SignaturePaperContainer>
      )}
    </SignatureTypeWrapper>
  );
}

export default SignatureTypeProfessione;
