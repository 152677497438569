import React from 'react';
import { FormInputText } from '../../../common/formInputs/FormInputText';
import { FormInputRadio } from '../../../common/formInputs/FormInputRadio';
import { flagResponse } from '../../../common/constants';

function CommertialCompanyDirectors({ commertial_report, errors, isValid }) {
  return (
    <div className="tab-pane fade" id="commercial_company_directors">
      <h4 className="text-center mt-3">Commercial Company Directors</h4>

      <div className="row mt-5">
        {commertial_report.commercial_company_directors.map((director, index) => (
          <div className="col-12 col-md-6 mb-4" key={index}>
            <div className="card border">
              <div className="card-body">
                <FormInputText
                  placeholder="name"
                  label="Name"
                  name="name"
                  value={director.name}
                  error={errors.name_directors?.message}
                  valid={isValid('name_directors')}
                />

                <div className="col-12">
                  <div className="d-flex align-align-items-center justify-content-center gap-5">
                    <FormInputRadio
                      label="Prejudicials"
                      options={flagResponse}
                      value={director.has_prejudicials}
                      error={errors.has_prejudicials_directors?.message}
                      valid={isValid('has_prejudicials_directors')}
                    />
                    <FormInputRadio
                      label="Has Severe Prejudicials"
                      options={flagResponse}
                      name="has_severe_prejudicials"
                      value={director.has_severe_prejudicials}
                      error={errors.has_severe_prejudicials_directors?.message}
                      valid={isValid('has_severe_prejudicials_directors')}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="d-flex align-align-items-center justify-content-center gap-5">
                    <FormInputRadio
                      label="Has Protesti"
                      options={flagResponse}
                      name="has_protesti"
                      value={director.has_protesti}
                      error={errors.has_protesti_directors?.message}
                      valid={isValid('has_protesti_directors')}
                    />
                    <FormInputRadio
                      label="Has Severe Protesti"
                      options={flagResponse}
                      name="has_severe_protesti"
                      value={director.has_severe_protesti}
                      error={errors.has_severe_protesti_directors?.message}
                      valid={isValid('has_severe_protesti_directors')}
                    />
                  </div>
                </div>
                <p className="fw-bold text-center">Positions</p>
                {director.positions.map((position, index) => (
                  <div key={index}>
                    <FormInputText
                      placeholder="name"
                      label="Name"
                      value={position.name}
                      error={errors.name_position?.message}
                      valid={isValid('name_position')}
                    />
                  </div>
                ))}

              </div>
            </div>
          </div>
        ))}

      </div>
    </div>
  );
}

export default CommertialCompanyDirectors;
