import { useNavigate } from 'react-router-dom';
import { confirmAlert } from '../../common/utils/alerts';
import { disconnectOtherSessions } from '../../features/auth/authActions';
import { SecurityContainer } from './Security.styles';

export function Security() {
  const navigate = useNavigate();

  const disconnectOtherUsers = () => {
    confirmAlert('Vuoi disconnettere altre sessioni?', () => {
      disconnectOtherSessions();
    });
  };

  return (
    <SecurityContainer>
      <div className="option" onClick={() => navigate('/security/change/password')}>
        Cambia la Password
      </div>
      <div className="option" onClick={disconnectOtherUsers}>
        Disconnetti le altre sessioni
      </div>
    </SecurityContainer>
  );
}
