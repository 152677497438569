import styled from 'styled-components';

export const PayByCardAfterSaleContainer = styled.div`
  border-radius: 30px;
  padding: 25px 20px;
  border: 2px solid #dbdde6;
  background: #fff;
  height: 100%;

  h3 {
    color: var(--common-black, #1a1921);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: 30px;
  }

  .field-row {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    border-bottom: 1px solid grey;
  }

  .field-row:last-child {
    border-bottom: none;
  }

  .field-name {
    flex: 1;
  }

  .invoice-totals .field-name {
    flex: 1;
    min-width: unset;
  }

  .field-value {
    flex: 1;
  }

  @media (max-width: 600px) {
    .field-row {
      flex-direction: column;
    }
  }

  .field-value.pull-right {
    text-align: right;
  }

  .payment-record {
    margin-bottom: 10px;
    padding: 10px;
    max-width: 600px;
    border: 1px solid grey;
  }
`;

export const FormInputGroupAfterSale = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  label {
    color: #343434;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 5px;
    margin-left: 5px;
  }

  input {
    border-radius: 15px;
    padding: 8px 15px;
    border: 2px solid
      ${(props) => (props.isInvalid === true ? 'rgba(240, 0, 0, 0.4)' : '#dbdde6')};
    background: #fff;
    width: 80%;

    &:focus,
    &:active,
    &:focus-visible {
      border: 2px solid #99dbb9;
      outline: none;
      box-shadow: 1px 1px 10px #99dbb9;
    }

    ::placeholder {
      color: #dbdde6;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }

  @media (max-width: 1200px) {
    label {
      font-size: 12px;
    }
  }
`;

export const ErrorMessageAfterSale = styled.p`
  color: rgba(240, 0, 0, 0.4);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 5px;
  margin-left: 5px;
`;

export const FormInputGroupAfterSaleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;

  input {
    width: 90%;

    &:focus,
    &:active,
    &:focus-visible {
      border: 2px solid #99dbb9;
      outline: none;
      box-shadow: 1px 1px 10px #99dbb9;
    }
  }
`;

export const WarningInfoAfterSale = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  margin-bottom: 10px;
  margin-top: 10px;

  p {
    margin-bottom: 0;
    color: #343434;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px; /* 100% */
  }

  img {
    max-width: 25px;
    width: 100%;
  }

  @media only screen and (max-width: 600px) {
    p {
      font-size: 10px;
    }
    gap: 15px;
    align-items: flex-start;
  }
`;

export const PayNowButtonAfterSale = styled.button`
  margin-top: 20px;
  border-radius: 15px;
  background: #99dbb9;
  color: #343434;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: 2px solid #99dbb9;
  padding: 15px 30px;
  transition: all 250ms linear;

  &:hover {
    background: transparent;
  }

  @media (max-width: 1200px) {
    padding: 10px 20px;
    border-radius: 10px;
  }
`;

export const PayByBankTransferAfterSale = styled.div`
  border-radius: 30px;
  padding: 25px 20px;
  border: 2px solid #dbdde6;
  background: #fff;
  height: 100%;

  h3 {
    color: var(--common-black, #1a1921);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }
`;

export const PaymentProofStyledAfterSale = styled.div`
  margin: 20px auto;
`;

export const BankTransferInfoAfterSale = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 25px;
  margin-bottom: 50px;

  @media (max-width: 1200px) {
    margin-top: 10px;
    margin-bottom: 20px;
  }
`;

export const BankTransferInfoItemAfterSale = styled.li`
  color: #00a651;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  span {
    color: #343434;
    margin-left: 10px;
    font-weight: 500;
  }

  @media (max-width: 1200px) {
    font-size: 15px;
  }
`;

export const WaitingForPaymentQuoteInfoAfterSale = styled.div`
  border-radius: 30px;
  padding: 25px 5px;

  border: 2px solid #dbdde6;
  background: #fff;
  height: 100%;

  h3 {
    color: var(--common-black, #1a1921);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 56px */
  }
`;

export const QuoteInfoContainerAfterSale = styled.div``;
