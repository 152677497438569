import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import NotificationItem from '../common/components/NotificationItem';
import PageLoading from '../common/components/elements/PageLoading';
import { getAllNotifications, resetNotifications } from '../features/utilities/utilitiesActions';
import Pagination from '../common/components/elements/Pagination';
import ErrorComponent from '../common/components/ErrorComponent';

export default function Notifications() {
  const {
    notifications, loading, total_pages, page_number, error,
  } = useSelector((store) => store.utilities);

  // HOOKS
  useEffect(() => {
    getAllNotifications(page_number);

    return () => { resetNotifications(); };
  }, []);

  if (loading) {
    return <PageLoading />;
  }

  return error === null
    ? (
      <div className="mt-3">
        <h5>Notifiche</h5>

        <div className="mt-4">
          {notifications.length !== 0 ? (
            notifications.map((notification, i) => (
              <NotificationItem key={i} notification={notification} />
            ))
          ) : (
            <div className="alert alert-info">Nessuna Notifica</div>
          )}
          <Pagination total_pages={total_pages} current_page={page_number} onClick={getAllNotifications} />
        </div>
      </div>
    ) : <ErrorComponent error={error} />;
}
