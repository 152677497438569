import React, { useState } from 'react';
import SimpleModal from '../elements/SimpleModal';
import { FormInputText } from '../../formInputs/FormInputText';
import { FormInputRadio } from '../../formInputs/FormInputRadio';
import {
  civil_liability_types,
  coverage_types,
  flagResponse,
} from '../../constants';
import { FormInputSelect } from '../../formInputs/FormInputSelect';

function CommercialModal({ setIsOpen, modalKey, onModalSave }) {
  const [errors, setErrors] = useState({});

  const [legal_protection_form, setLegalProtectionForm] = useState({
    legal_protection_max: '',
    coverage_type: '',
  });

  const [income_form, setIncomeForm] = useState({
    cost_goods: '',
    equipment_value: '',
    value_warehouse_goods: '',
    value_electronic_devices: '',
    mobile_electronic_devices: '0',
    real_estate_coinsurance: '0',
    shopping_mall: '0',
  });

  const [civil_liability_form, setCivilLiabilityForm] = useState({
    civil_liability_max: '',
  });

  const [accidents_form, setAccidentsForm] = useState({
    managers_disability_sum: '',
    managers_death_sum: '',
    employee_disability_sum: '',
    employee_death_sum: '',
  });

  const [damage_to_trust_form, setDamageToTrustForm] = useState({
    damage_to_trust_max: '',
  });
  const handleInputChange = (form, key, value) => {
    if (form === 'legal_protection') {
      setLegalProtectionForm((prev) => ({ ...prev, [key]: value }));
    }

    if (form === 'income') {
      setIncomeForm((prev) => ({ ...prev, [key]: value }));
    }

    if (form === 'civil_liability') {
      setCivilLiabilityForm((prev) => ({ ...prev, [key]: value }));
    }

    if (form === 'accidents') {
      setAccidentsForm((prev) => ({ ...prev, [key]: value }));
    }

    if (form === 'damage_to_trust') {
      setDamageToTrustForm((prev) => ({ ...prev, [key]: value }));
    }

    if (errors[key] && value !== '') {
      delete errors[key];
      setErrors({ ...errors });
    } else if (value === '') {
      setErrors({ ...errors, [key]: 'Il campo è obbligatorio!' });
    }
  };

  const handleModalSave = (form) => {
    if (form === 'legal_protection') {
      console.log(legal_protection_form);
    }

    if (form === 'income') {
      console.log(income_form);
    }

    if (form === 'civil_liability') {
      console.log(civil_liability_form);
    }

    if (form === 'accidents') {
      console.log(accidents_form);
    }

    if (form === 'damage_to_trust') {
      console.log(damage_to_trust_form);
    }
    onModalSave(form);
    setIsOpen(false);
  };

  // const validateData = async () => {
  //   let isValid = false;
  //   await schemaPeople
  //     .validate(data, { abortEarly: false })
  //     .then(() => {
  //       isValid = true;
  //     })
  //     .catch((errors) => {
  //       const formattedErrors = errors.inner.reduce((acc, curr) => {
  //         acc[curr.path] = curr.message;
  //         return acc;
  //       }, {});
  //       setErrors(formattedErrors);
  //     });
  //   return { isValid };
  // };

  return (
    <SimpleModal title="" setIsOpen={setIsOpen}>
      <div className="form-container">
        {modalKey === 'legal_protection' && (
          <>
            <FormInputText
              label="Massimale"
              placeholder="Inserisci"
              onChange={(value) => handleInputChange(
                'legal_protection',
                'legal_protection_max',
                value,
              )}
              value={legal_protection_form.legal_protection_max}
              error={errors.legal_protection_max}
              trim={false}
            />
            <FormInputSelect
              label="Tipologia copertura"
              placeholder="-Seleziona-"
              options={coverage_types}
              onChange={(value) => handleInputChange('legal_protection', 'coverage_type', value)}
              value={legal_protection_form.coverage_type}
              error={errors.coverage_type}
            />
            <div className="footer-buttons">
              <div className="d-flex justify-content-between">
                <button
                  className="btn btn-questionnaire"
                  onClick={() => handleModalSave('legal_protection')}
                >
                  Salva
                </button>
              </div>
            </div>
          </>
        )}

        {modalKey === 'income' && (
          <>
            <FormInputText
              label="Costo Merci"
              placeholder="Inserisci"
              onChange={(value) => handleInputChange('income', 'cost_goods', value)}
              value={income_form.cost_goods}
              error={errors.cost_goods}
              trim={false}
            />

            <FormInputText
              label="Valore attrezzature"
              placeholder="Inserisci"
              onChange={(value) => handleInputChange('income', 'equipment_value', value)}
              value={income_form.equipment_value}
              error={errors.equipment_value}
              trim={false}
            />

            <FormInputText
              label="Valore merci magazzino"
              placeholder="Inserisci"
              onChange={(value) => handleInputChange('income', 'value_warehouse_goods', value)}
              value={income_form.value_warehouse_goods}
              error={errors.value_warehouse_goods}
              trim={false}
            />
            <FormInputText
              label="Valore dispoziviti eletronici"
              placeholder="Inserisci"
              onChange={(value) => handleInputChange('income', 'value_electronic_devices', value)}
              value={income_form.value_electronic_devices}
              error={errors.value_electronic_devices}
              trim={false}
            />
            <FormInputRadio
              label="Utilizi dispositivi eleronici mobili?"
              options={flagResponse}
              onChange={(value) => handleInputChange('income', 'mobile_electronic_devices', value)}
              value={income_form.mobile_electronic_devices}
              error={errors.mobile_electronic_devices}
            />

            <FormInputRadio
              label="Coassicurazione immobili?"
              options={flagResponse}
              onChange={(value) => handleInputChange('income', 'real_estate_coinsurance', value)}
              value={income_form.real_estate_coinsurance}
              error={errors.real_estate_coinsurance}
            />
            <FormInputRadio
              label="Negozio in galleria commerciale?"
              options={flagResponse}
              onChange={(value) => handleInputChange('income', 'shopping_mall', value)}
              value={income_form.shopping_mall}
              error={errors.shopping_mall}
            />
            <div className="footer-buttons">
              <div className="d-flex justify-content-between">
                <button
                  className="btn btn-questionnaire"
                  onClick={() => handleModalSave('income')}
                >
                  Salva
                </button>
              </div>
            </div>
          </>
        )}
        {modalKey === 'civil_liability' && (
          <>
            <FormInputSelect
              label="Importo della copertura"
              placeholder="-Seleziona-"
              options={civil_liability_types}
              onChange={(value) => handleInputChange(
                'civil_liability',
                'civil_liability_max',
                value,
              )}
              value={civil_liability_form.civil_liability_max}
              error={errors.civil_liability_max}
            />

            <div className="footer-buttons">
              <div className="d-flex justify-content-between">
                <button
                  className="btn btn-questionnaire"
                  onClick={() => handleModalSave('civil_liability')}
                >
                  Salva
                </button>
              </div>
            </div>
          </>
        )}
        {modalKey === 'accidents' && (
          <>
            <FormInputSelect
              label="Somma assicurata amministratore delegato:"
              paragraph="Scegli un importo di risarcimento adeguato."
              placeholder="-Somma in invalidità-"
              options={civil_liability_types}
              onChange={(value) => handleInputChange('accidents', 'managers_disability_sum', value)}
              value={accidents_form.managers_disability_sum}
              error={errors.managers_disability_sum}
            />
            <FormInputSelect
              placeholder="-Somma in caso di morte-"
              options={civil_liability_types}
              onChange={(value) => handleInputChange('accidents', 'managers_death_sum', value)}
              value={accidents_form.managers_death_sum}
              error={errors.managers_death_sum}
            />

            <FormInputSelect
              label="Somma assicurativa del dipendente:"
              paragraph="Scegli un importo di risarcimento adeguato."
              placeholder="-Somma in invalidità-"
              options={civil_liability_types}
              onChange={(value) => handleInputChange('accidents', 'employee_disability_sum', value)}
              value={accidents_form.employee_disability_sum}
              error={errors.employee_disability_sum}
            />
            <FormInputSelect
              placeholder="-Somma in caso di morte-"
              options={civil_liability_types}
              onChange={(value) => handleInputChange('accidents', 'employee_death_sum', value)}
              value={accidents_form.employee_death_sum}
              error={errors.employee_death_sum}
            />

            <div className="footer-buttons">
              <div className="d-flex justify-content-between">
                <button
                  className="btn btn-questionnaire"
                  onClick={() => handleModalSave('accidents')}
                >
                  Salva
                </button>
              </div>
            </div>
          </>
        )}
        {modalKey === 'damage_to_trust' && (
          <>
            <FormInputSelect
              label="Somma assicurata"
              paragraph="Scegli un importo di risarcimento adeguato."
              placeholder="-Seleziona-"
              options={civil_liability_types}
              onChange={(value) => handleInputChange(
                'damage_to_trust',
                'damage_to_trust_max',
                value,
              )}
              value={damage_to_trust_form.damage_to_trust_max}
              error={errors.damage_to_trust_max}
            />
            <div className="footer-buttons">
              <div className="d-flex justify-content-between">
                <button
                  className="btn btn-questionnaire"
                  onClick={() => handleModalSave('damage_to_trust')}
                >
                  Salva
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </SimpleModal>
  );
}

export default CommercialModal;
