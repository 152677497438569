import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { BadgeStatus, RemoveDocument, UploadedDocumentWrapper } from '../Purchase.styles';
import pdfUploaded from '../../../assets/redesign_icons/file.svg';
import { Uploader } from '../../../common/components/redesignComponents/Uploader/Uploader';
import { uploadDocument } from '../../../services/axios-client/axeCommons';
import { generalAlertSuccessToast } from '../../../common/utils/alerts';

function NonSignableDocumentComponent({
  insuranceRequest, documentType, downloadDocument, removeDocument, rc_quotation, collectQuoteData,
}) {
  const updloadDocument = (file) => {
    let fileInfo;
    let baseURL = '';
    const uploadedFile = file;
    // Make new FileReader
    const reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(uploadedFile);
    // on reader load pdf...
    reader.onload = async () => {
      fileInfo = uploadedFile.name;

      const fileType = uploadedFile.type;
      baseURL = reader.result;
      const data = {
        document_type_id: documentType.id,
        request_id: insuranceRequest.id,
        quotation_id: rc_quotation.id,
        original_filename: fileInfo,
        unsigned_document: baseURL.replace(`data:${fileType};base64,`, ''),
      };
      const response = await uploadDocument(data);
      if (response.status !== 200) {
        return;
      }
      collectQuoteData();
      generalAlertSuccessToast('File caricato');
    };
  };

  return (
    <>
      {(insuranceRequest.documents.find((doc) => doc.document_type_id === documentType.id) !== undefined) ? (
        <UploadedDocumentWrapper>
          {(insuranceRequest.documents.find((doc) => doc.document_type_id === documentType.id) && insuranceRequest.documents.find((doc) => doc.document_type_id === documentType.id).is_uploaded_valid === 1) && (
            <BadgeStatus background="#99DBB9" color="#009232">
              Valido
            </BadgeStatus>
          )}
          {(insuranceRequest.documents.find((doc) => doc.document_type_id === documentType.id) && insuranceRequest.documents.find((doc) => doc.document_type_id === documentType.id).is_uploaded_valid === 0)
            && (
              <>
                <RemoveDocument onClick={() => removeDocument(insuranceRequest.documents.find((doc) => doc.document_type_id === documentType.id).id)}>
                  <FontAwesomeIcon icon={faTimesCircle} color="red" />
                </RemoveDocument>
                <BadgeStatus background="#FFB4B4" color="#F00">
                  Non Valido
                </BadgeStatus>
              </>
            )}
          {(insuranceRequest.documents.find((doc) => doc.document_type_id === documentType.id) && insuranceRequest.documents.find((doc) => doc.document_type_id === documentType.id).is_uploaded_valid === null) && (
            <BadgeStatus background="#009232" color="#ffffff">
              In verifica
            </BadgeStatus>
          )}
          <img src={pdfUploaded} alt="" width={50} />
          {(insuranceRequest.documents.find((doc) => doc.document_type_id === documentType.id) && insuranceRequest.documents.find((doc) => doc.document_type_id === documentType.id).motivation !== null)
            && (
              <p className="text-danger">
                {insuranceRequest.documents.find((doc) => doc.document_type_id === documentType.id).motivation}
              </p>
            )}
          <p onClick={() => downloadDocument(insuranceRequest.documents.find((doc) => doc.document_type_id === documentType.id).id)}>
            Tocca qui per scaricare
          </p>
          <h3>
            {documentType.name.toUpperCase()}
          </h3>
        </UploadedDocumentWrapper>
      ) : (rc_quotation.documents.find((doc) => doc.document_type_id === documentType.id) !== undefined) ? (
        <UploadedDocumentWrapper>
          {(rc_quotation.documents.find((doc) => doc.document_type_id === documentType.id) && rc_quotation.documents.find((doc) => doc.document_type_id === documentType.id).is_uploaded_valid === 1) && (
            <BadgeStatus background="#99DBB9" color="#009232">
              Valido
            </BadgeStatus>
          )}
          {(rc_quotation.documents.find((doc) => doc.document_type_id === documentType.id) && rc_quotation.documents.find((doc) => doc.document_type_id === documentType.id).is_uploaded_valid === 0)
            && (
              <>
                <RemoveDocument onClick={() => removeDocument(rc_quotation.documents.find((doc) => doc.document_type_id === documentType.id).id)}>
                  <FontAwesomeIcon icon={faTimesCircle} color="red" />
                </RemoveDocument>
                <BadgeStatus background="#FFB4B4" color="#F00">
                  Non Valido
                </BadgeStatus>
              </>
            )}
          {(rc_quotation.documents.find((doc) => doc.document_type_id === documentType.id) && rc_quotation.documents.find((doc) => doc.document_type_id === documentType.id).is_uploaded_valid === null) && (
            <BadgeStatus background="#009232" color="#ffffff">
              In verifica
            </BadgeStatus>
          )}
          <img src={pdfUploaded} alt="" width={50} />
          {(rc_quotation.documents.find((doc) => doc.document_type_id === documentType.id) && rc_quotation.documents.find((doc) => doc.document_type_id === documentType.id).motivation !== null)
            && (
              <p className="text-danger">
                {rc_quotation.documents.find((doc) => doc.document_type_id === documentType.id).motivation}
              </p>
            )}
          <p onClick={() => downloadDocument(rc_quotation.documents.find((doc) => doc.document_type_id === documentType.id).id)}>
            Tocca qui per scaricare
          </p>
          <h3>
            {documentType.name.toUpperCase()}
          </h3>
        </UploadedDocumentWrapper>
      ) : <Uploader onFileUpload={(file) => updloadDocument(file)} note={`CARICA ${documentType.name.toUpperCase()}`} />}
    </>
  );
}

export default NonSignableDocumentComponent;
