import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useMatch,
} from 'react-router-dom';
import 'moment/locale/it';
import { Provider } from 'react-redux';
import { store } from '../features/store';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

import 'react-day-picker/dist/style.css';
import './App.css';

import NotFound from '../common/components/NotFound';
import Login from '../screens/Login';
import Quote from '../screens/Quote';
import RcProfession from '../screens/RcProfession';
import DashboardTheme from './theme/style-screens/DashboardTheme';
import RcQuotes from '../screens/RcQuotes';
import RcMotoQuotes from '../screens/RcMotoQuotes';
import RcProfessionQuotes from '../screens/RcProfessionQuotes';
import useAuth from '../hooks/useAuth';
import Profile from '../screens/Profile';
import Tutorial from '../screens/Tutorial';
import ClaimsManagementReports from '../screens/ClaimsManagementReports';
import Message from '../screens/Message';
import MessageThread from '../screens/MessageThread';
import BrokerHome from '../screens/BrokerHome/BrokerHome';
import LatestUpdatesPage from '../screens/LatestUpdatesPage/LatestUpdatesPage';
import QuotationsInProgress from '../screens/QuotationsInProgress';
import PurchasedQuotations from '../screens/PurchasedQuotations/PurchasedQuotations';
import NotSavedInsuranceRequestsPage from '../screens/NotSavedInsuranceRequestsPage/NotSavedInsuranceRequestsPage';
import Notifications from '../screens/Notifications';
import VehicleProduct from '../screens/VehicleProduct';
import AccidentsPage from '../screens/AccidentsPage/AccidentsPage';
import AccidentPage from '../screens/AccidentPage/AccidentPage';
import TicketPage from '../screens/TicketPage';
import NetworkAnalisys from '../screens/ResearchScreens/NetworkAnalisys';
import ResearchType from '../screens/ResearchScreens/ResearchType';
import ComparativeAnalisys from '../screens/ResearchScreens/ComparativeAnalisys';
import CollaboratorsAnalisys from '../screens/ResearchScreens/CollaboratorsAnalisys';
import ListActiveCustomers from '../screens/ActiveCustomers/ListActiveCustomers';
import ActiveCustomerDetails from '../screens/ActiveCustomers/ActiveCustomerDetails';
import ReadGmail from '../screens/ActiveCustomers/ReadGmail';
import CustomerInformationPage from '../screens/ActiveCustomers/CustomerInformationPage';
import RenewalsPage from '../screens/RenewalsPage';
import Brokers from '../screens/BrokersPage/Brokers';
import NewBroker from '../screens/BrokersPage/NewBroker';
import EditBroker from '../screens/BrokersPage/EditBroker';
import BrokerQuotations from '../screens/BrokersPage/BrokerQuotations';
import { BrokersReports } from '../screens/BrokersPage/BrokersReports';
import ClientsListOfSezE from '../screens/ActiveCustomers/ClientsListOfSezE';
import { CancellationsPage } from '../screens/CancellationsPage/CancellationsPage';
import { CancellationPage } from '../screens/CancellationPage/CancellationPage';
import { ReplacmentsPage } from '../screens/ReplacementsPage/ReplacementsPage';
import { ReplacementPage } from '../screens/ReplacementPage/ReplacementPage';
import { ModificationsPage } from '../screens/ModificationsPage/ModificationsPage';
import { ModificationPage } from '../screens/ModificationPage/ModificationPage';
import { SendDocumentsPage } from '../screens/SendDocumentsPage/SendDocumentsPage';
import AuthenticateBackOffice from '../screens/BackOffice/AuthenticateBackOffice';
import BackOfficeRcVehicle from '../screens/BackOffice/BackOfficeRcVehicle';
import BackOfficeRcMoto from '../screens/BackOffice/BackOfficeRcMoto';
import BackOfficeRcVan from '../screens/BackOffice/BackOfficeRcVan';
import BackOfficeRcProfession from '../screens/BackOffice/BackOfficeRcProfession';
import BackOfficeInfortuni from '../screens/BackOffice/BackOfficeInfortuni';
import BackOfficeInfodrive from '../screens/BackOffice/BackOfficeInfodrive';
import BackOfficeTravel from '../screens/BackOffice/BackOfficeTravel';
import BackOfficeVehicleQuotes from '../screens/BackOffice/results/BackOfficeVehicleQuotes';
import BackOfficeMotoQuotes from '../screens/BackOffice/results/BackOfficeMotoQuotes';
import BackofficeRcProfessionQuotes from '../screens/BackOffice/results/BackOfficeRcProfessionQuotes';
import BackOfficeInfortuniQuotes from '../screens/BackOffice/results/BackOfficeInfortuniQuotes';
import BackOfficeInfodriveQuotes from '../screens/BackOffice/results/BackOfficeInfodriveQuotes';
import BackOfficeTravelQuotes from '../screens/BackOffice/results/BackOfficeTravelQuotes';
import { ForgotPassword } from '../screens/ForgotPassword';
import { ResetPassword } from '../screens/ResetPassword';
import { CreateReminder } from '../screens/Reminder/CreateReminder';
import { UpdateReminder } from '../screens/Reminder/UpdateReminder';
import { ContactCustomer } from '../screens/CommonCustomers/ContactCustomer';
import TravelFormFlow from '../screens/TravelProduct/TravelFormFlow';
import TravelResultPage from '../screens/TravelProduct/TravelResultPage';
import WidgetsScreen from '../screens/BrokerHome/WidgetsScreen';
import SelectPostVenditaAction from '../screens/SelectPostVenditaAction';
import SelectPostVenditaType from '../screens/SelectPostVenditaType';
import { ChangePassword } from '../screens/Auth/ChangePassword';
import SemestalityQuotesToBePaid from '../screens/PurchasedQuotations/SemestalityQuotesToBePaid';
import { Redirector } from '../screens/Redirector';
import Commercial from '../screens/Commercial';
import PurchaseCommercial from '../screens/purchase/Commercial';
import RcCommercialQuotes from '../screens/RcCommercialQuotes';
import { TourProvider } from '../context/useTourContext';
import CommercialCompanyData from '../screens/Commercial/CommercialCompanyData';
import CommercialQuestionnaire from '../screens/Commercial/CommercialQuestionnaire';
import PurchaseProfessionalRequest from '../screens/purchase/PurchaseProfessionalRequest';
import PurchaseAutoRequest from '../screens/purchase/PurchaseAutoRequest';
import PurchaseAutocarroRequest from '../screens/purchase/PurchaseAutocarroRequest';
import PurchaseTravelRequest from '../screens/purchase/PurchaseTravelRequest';
import PurchaseMotoRequest from '../screens/purchase/PurchaseMotoRequest';
import ComponentRouteWrapper from '../screens/ComponentRouteWrapper';

const backofficeRoutes = [
  <Route
    path="/backoffice/:backOfficeAccessToken/:requestToken/:resultpage?"
    element={<AuthenticateBackOffice />}
  />,
  <Route
    path="/backoffice/quote/:requestToken/auto"
    element={<BackOfficeRcVehicle />}
  />,
  <Route
    path="/backoffice/quote/:requestToken/moto"
    element={<BackOfficeRcMoto />}
  />,
  <Route
    path="/backoffice/quote/:requestToken/autocarro"
    element={<BackOfficeRcVan />}
  />,
  <Route
    path="/backoffice/quote/:requestToken/profession"
    element={<BackOfficeRcProfession />}
  />,
  <Route
    path="/backoffice/quote/:requestToken/infortuni"
    element={<BackOfficeInfortuni />}
  />,
  <Route
    path="/backoffice/quote/:requestToken/infodrive"
    element={<BackOfficeInfodrive />}
  />,
  <Route
    path="/backoffice/quote/:requestToken/travel"
    element={<BackOfficeTravel />}
  />,

  <Route
    path="/backoffice/quote/:requestToken/auto/results"
    element={<BackOfficeVehicleQuotes />}
  />,
  <Route
    path="/backoffice/quote/:requestToken/moto/results"
    element={<BackOfficeMotoQuotes />}
  />,
  <Route
    path="/backoffice/quote/:requestToken/autocarro/results"
    element={<BackOfficeVehicleQuotes />}
  />,
  <Route
    path="/backoffice/quote/:requestToken/profession/results"
    element={<BackofficeRcProfessionQuotes />}
  />,
  <Route
    path="/backoffice/quote/:requestToken/infortuni/results"
    element={<BackOfficeInfortuniQuotes />}
  />,
  <Route
    path="/backoffice/quote/:requestToken/infodrive/results"
    element={<BackOfficeInfodriveQuotes />}
  />,
  <Route
    path="/backoffice/quote/:requestToken/travel/results"
    element={<BackOfficeTravelQuotes />}
  />,
  <Route path="/redirect" element={<Redirector />} />,
];

function App() {
  const { isUserLoggedIn, isUserSectionAB } = useAuth();
  const matchBackoffice = useMatch('/backoffice/*');

  if (!isUserLoggedIn) {
    return (
      <Routes>
        <Route exact path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/auth/forgot" element={<ForgotPassword />} />
        <Route path="/auth/reset/:token" element={<ResetPassword />} />

        {backofficeRoutes.map((c) => c)}

        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  }

  if (matchBackoffice) {
    return <Routes>{backofficeRoutes.map((c) => c)}</Routes>;
  }

  return (
    <DashboardTheme>
      <Routes>
        <Route path="/" element={<ComponentRouteWrapper location="dashboard_screen" returnComponent={<BrokerHome />} />} />
        <Route path="/dashboard" element={<ComponentRouteWrapper location="dashboard_screen" returnComponent={<BrokerHome />} />} />

        <Route path="/quote" element={<ComponentRouteWrapper location="quotation_products" returnComponent={<Quote />} />} />

        <Route path="/quote/:vehicle" element={<ComponentRouteWrapper location="funnel" returnComponent={<VehicleProduct />} />} />
        <Route path="/quote/profession" element={<ComponentRouteWrapper location="quotation_professione_form" returnComponent={<RcProfession />} />} />
        <Route path="/quote/commercial" element={<ComponentRouteWrapper location="quotation_commercial_form" returnComponent={<Commercial />} />} />
        <Route path="/quote/commercial/:vatNumber/report" element={<CommercialCompanyData />} />
        <Route path="/quote/commercial/:vatNumber/questionnaire/:company_id" element={<CommercialQuestionnaire />} />

        {/* <Route path="/quote/metlife" element={<Metlife />} />
        <Route path="/quote/metlife/:pack" element={<Metlife />} /> */}
        {/* <Route path="/quote/infodrive" element={<InfoDriveFlow />} /> */}
        <Route path="/quote/travel" element={<ComponentRouteWrapper location="quotation_travel_form" returnComponent={<TravelFormFlow />} />} />
        <Route path="/quotes/travel/:requestToken" element={<ComponentRouteWrapper location="quotation_travel_result" returnComponent={<TravelResultPage />} />} />
        {/* <Route path="/quotes/travel/purchase/:quotationId" element={<PurchaseTravel />} /> */}
        <Route path="/quotes/travel/purchase/:requestToken" element={<ComponentRouteWrapper location="quotation_travel_purchase" returnComponent={<PurchaseTravelRequest />} />} />
        {/* <Route
          path="/quotes/infodrive/:requestToken"
          element={<InfodriveQuotes />}
        />
        <Route
          path="/quotes/infodrive/purchase/:quotationId"
          element={<PurchaseInfodrive />}
        /> */}
        <Route path="/quotes/:requestToken" element={<ComponentRouteWrapper location="quotation_auto_result" returnComponent={<RcQuotes />} />} />
        <Route path="/quotes/moto/:requestToken" element={<ComponentRouteWrapper location="quotation_moto_result" returnComponent={<RcMotoQuotes />} />} />

        {/* <Route
          path="/quotes/metlife/:requestToken"
          element={<MetlifeQuote />}
        /> */}

        <Route
          path="/quotes/profession/:requestToken"
          element={<ComponentRouteWrapper location="quotation_professione_result" returnComponent={<RcProfessionQuotes />} />}
        />
        <Route
          path="/quotes/commercial/:requestToken"
          element={<ComponentRouteWrapper location="quotation_commercial_result" returnComponent={<RcCommercialQuotes />} />}
        />

        {/* <Route
          path="/quotes/metlife/purchase/:quotationId"
          element={<PurchaseInfortuni />}
        /> */}
        <Route
          path="/quotes/auto/purchase/:requestToken"
          element={<ComponentRouteWrapper location="quotation_auto_purchase" returnComponent={<PurchaseAutoRequest />} />}
        />
        <Route
          path="/quotes/moto/purchase/:requestToken"
          element={<ComponentRouteWrapper location="quotation_moto_purchase" returnComponent={<PurchaseMotoRequest />} />}
        />
        <Route
          path="/quotes/autocarro/purchase/:requestToken"
          element={<ComponentRouteWrapper location="quotation_autocarro_purchase" returnComponent={<PurchaseAutocarroRequest />} />}
        />
        <Route
          path="/quotes/profession/purchase/:requestToken"
          element={<ComponentRouteWrapper location="quotation_profession_purchase" returnComponent={<PurchaseProfessionalRequest />} />}
        />
        <Route
          path="/quotes/commercial/purchase/:quotationId"
          element={<ComponentRouteWrapper location="quotation_commercial_purchase" returnComponent={<PurchaseCommercial />} />}
        />
        <Route path="/profile" element={<ComponentRouteWrapper location="broker_profile" returnComponent={<Profile />} />} />
        <Route path="/tutorial" element={<ComponentRouteWrapper location="tutorial" returnComponent={<Tutorial />} />} />
        <Route
          path="/quotazioni-in-processo"
          element={<ComponentRouteWrapper location="quotation_in_progress_list" returnComponent={<QuotationsInProgress />} />}
        />
        <Route
          path="/quotazioni-aquistati"
          element={<ComponentRouteWrapper location="purchased_quotation_list" returnComponent={<PurchasedQuotations />} />}
        />
        <Route path="/quote-semestrale-da-pagare" element={<ComponentRouteWrapper location="semestrality_quotation_list" returnComponent={<SemestalityQuotesToBePaid />} />} />
        <Route
          path="/post-vendita"
          element={<ComponentRouteWrapper location="post_vendita_actions" returnComponent={<SelectPostVenditaAction />} />}
        />
        <Route
          path="/after-sale"
          element={<ComponentRouteWrapper location="after_sale_list" returnComponent={<ClaimsManagementReports />} />}
        />
        <Route
          path="/after-sale/select"
          element={<ComponentRouteWrapper location="select_after_sale" returnComponent={<SelectPostVenditaType />} />}
        />
        <Route path="/accidents" element={<ComponentRouteWrapper location="accidents_list" returnComponent={<AccidentsPage />} />} />
        <Route path="/accidents/:accidentId" element={<ComponentRouteWrapper location="accident_single_page" returnComponent={<AccidentPage />} />} />
        <Route path="/cancellations" element={<ComponentRouteWrapper location="cancellations_list" returnComponent={<CancellationsPage />} />} />
        <Route
          path="/cancellations/:cancellationId"
          element={<ComponentRouteWrapper location="cancellation_single_page" returnComponent={<CancellationPage />} />}
        />
        <Route path="/replacements" element={<ComponentRouteWrapper location="replacements_list" returnComponent={<ReplacmentsPage />} />} />
        <Route
          path="/replacements/:replacementId"
          element={<ComponentRouteWrapper location="replacement_single_page" returnComponent={<ReplacementPage />} />}
        />
        <Route path="/modifications" element={<ComponentRouteWrapper location="modifications_list" returnComponent={<ModificationsPage />} />} />
        <Route
          path="/modifications/:modificationId"
          element={<ComponentRouteWrapper location="modification_single_page" returnComponent={<ModificationPage />} />}
        />
        <Route path="/send-documents" element={<ComponentRouteWrapper location="send_documents_list" returnComponent={<SendDocumentsPage />} />} />

        <Route path="/notifications" element={<ComponentRouteWrapper location="notifications" returnComponent={<Notifications />} />} />

        <Route path="/renewals" element={<ComponentRouteWrapper location="renewals_list" returnComponent={<RenewalsPage />} />} />
        {/* Active Customers */}
        <Route path="/clients" element={<ComponentRouteWrapper location="clients_list" returnComponent={<ListActiveCustomers />} />} />
        <Route path="/clients/rete" element={<ComponentRouteWrapper location="clients_of_sez_E_list" returnComponent={<ClientsListOfSezE />} />} />
        <Route
          path="/clients/:activeClientId"
          element={<ComponentRouteWrapper location="active_client_single_page" returnComponent={<ActiveCustomerDetails />} />}
        />
        <Route path="/clients/email/:gmailId" element={<ReadGmail />} />
        <Route
          path="/clients-not-active/:customerId"
          element={<ComponentRouteWrapper location="non_active_client_single_page" returnComponent={<CustomerInformationPage />} />}
        />
        {/* Messages Routes */}
        <Route path="/messages" element={<Message />} />
        <Route path="/messages/:threadId" element={<ComponentRouteWrapper location="thread_single_page" returnComponent={<MessageThread />} />} />

        {/** Latest Updates Routes */}
        <Route path="/latest-updates" element={<ComponentRouteWrapper location="latest_update" returnComponent={<LatestUpdatesPage />} />} />
        <Route
          path="/not-saved-quotations"
          element={<ComponentRouteWrapper location="not_saved_quotation" returnComponent={<NotSavedInsuranceRequestsPage />} />}
        />

        <Route path="/tickets/:ticketId" element={<ComponentRouteWrapper location="ticket_single_page" returnComponent={<TicketPage />} />} />

        <Route path="/research-type" element={<ComponentRouteWrapper location="research_type" returnComponent={<ResearchType />} />} />
        <Route path="/network-analisys" element={<ComponentRouteWrapper location="network_analisys" returnComponent={<NetworkAnalisys />} />} />
        <Route path="/comparative-analisys" element={<ComponentRouteWrapper location="comparative_analisys" returnComponent={<ComparativeAnalisys />} />} />
        <Route
          path="/collaborators-analisys"
          element={<ComponentRouteWrapper location="collaborators_analisys" returnComponent={<CollaboratorsAnalisys />} />}
        />
        <Route
          path="/reminders/create"
          element={<ComponentRouteWrapper location="create_reminder" returnComponent={<CreateReminder />} />}
        />
        <Route
          path="/reminders/:reminderId/edit"
          element={<ComponentRouteWrapper location="update_reminder" returnComponent={<UpdateReminder />} />}
        />
        <Route
          path="/customers/:type/:customerId/contact"
          element={<ComponentRouteWrapper location="contact_customer" returnComponent={<ContactCustomer />} />}
        />

        <Route path="/widgets" element={<ComponentRouteWrapper location="widgets" returnComponent={<WidgetsScreen />} />} />

        <Route path="/security/change/password" element={<ComponentRouteWrapper location="change_password" returnComponent={<ChangePassword />} />} />

        <Route path="/redirect" element={<Redirector />} />

        {isUserSectionAB && (
          <>
            <Route path="/brokers" element={<ComponentRouteWrapper location="brokers_list" returnComponent={<Brokers />} />} />
            <Route path="/brokers/new" element={<ComponentRouteWrapper location="create_broker" returnComponent={<NewBroker />} />} />
            <Route path="/brokers/:brokerId" element={<ComponentRouteWrapper location="edit_broker" returnComponent={<EditBroker />} />} />
            <Route
              path="/brokers/:brokerId/quotations"
              element={<ComponentRouteWrapper location="broker_quotations" returnComponent={<BrokerQuotations />} />}
            />
            <Route path="/brokers-reports" element={<ComponentRouteWrapper location="broker_reports" returnComponent={<BrokersReports />} />} />
          </>
        )}

        <Route path="*" element={<NotFound />} />
      </Routes>
    </DashboardTheme>
  );
}

function AppWrapper() {
  return (
    <TourProvider>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </TourProvider>
  );
}

export default AppWrapper;
