import styled from 'styled-components';

export const ListActiveCustomersStyles = styled.section`
  .custom-select-search {
    width: 50%;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
`;

export const SearchBtn = styled.button`
  display: table;
  margin: 0 auto;
  width: 25%;
  border: 2px solid #00a651;
  background-color: #00a651;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  padding: 6px 16px;
  transition: all 300ms ease-in-out;

  &:hover {
    background-color: transparent;
    color: #00a651;
  }
`;

export const AnagraficaBtn = styled.div`
  a {
    padding: 8px 15px;
    font-weight: normal;
    background-color: #00a651;
    color: #ffffff;
    border: 1px solid #00a651;
    transition: all ease-in-out 300ms;
    cursor: pointer;
    border-radius: 5px;
    font-size: 12px;
    text-transform: uppercase;

    &:hover {
      background-color: transparent;
      color: #00a651;
      font-weight: normal;
    }
  }
`;
