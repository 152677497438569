import caller from './remindersSlice';
import managementAxe from '../../services/axios-client/managementServisAxeClient';
import { generalAlertError } from '../../common/utils/alerts';

export const storeReminder = async (data, callback) => {
  try {
    const response = await managementAxe.post('/broker/dashboard/reminders', data);

    if (response.status !== 200) {
      generalAlertError('Errore interno');
      return;
    }

    callback();
  } catch (err) {
    generalAlertError('Errore interno');
  }
};

export const getReminder = async (reminderId) => {
  try {
    const response = await managementAxe.get(`/broker/dashboard/reminders/${reminderId}`);

    if (response.status !== 200) {
      caller.editReminderRejected('Errore interno');
      return;
    }

    caller.editReminderFulfilled(response.data.reminder);
  } catch (err) {
    caller.editReminderRejected('Errore interno');
  }
};

export const updateReminder = async (reminderId, data, callback) => {
  try {
    const response = await managementAxe.put(`/broker/dashboard/reminders/${reminderId}`, data);

    if (response.status !== 200) {
      generalAlertError('Errore interno');
      return;
    }

    callback();
  } catch (err) {
    generalAlertError('Errore interno');
  }
};

export const deleteReminder = async (reminderId, callback) => {
  try {
    const response = await managementAxe.delete(`/broker/dashboard/reminders/${reminderId}`);

    if (response.status !== 200) {
      generalAlertError('Errore interno');
      return;
    }

    callback();
  } catch (err) {
    generalAlertError('Errore interno');
  }
};
