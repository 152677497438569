import styled from 'styled-components';

export const LayoutStyles = styled.div`
    width: 100%;
    overflow-x: hidden;


`;

export const HeaderStyles = styled.div`
    background-color: var(--greenia-color);
    height: 60px;
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-weight: bold;
    color: white;
`;
