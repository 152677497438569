import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { removeWidget } from '../../features/widgets/widgetsActions';
import { confirmAlert } from '../utils/alerts';
import Item from './Item';

export function SortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
  } = useSortable({ id: props.id });

  const deleteWidget = (id, name) => {
    confirmAlert(`Vuoi rimuovere il widget: ${name}`, () => removeWidget(id));
  };

  return (
    <div className="col-12 col-md-6 d-flex align-items-center justify-content-between gap-2">
      <Item setNodeRef={setNodeRef} attributes={attributes} listeners={listeners} name={props.name} widgetId={props.widgetId} deleteWidget={deleteWidget} />
    </div>
  );
}
