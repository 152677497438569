import React from 'react';
import { InsuredPersonStyles } from './InsuredPerson.styles';
import { FormInputText } from '../../common/formInputs/FormInputText';
import { FormInputDate } from '../../common/formInputs/FormInputDate';

function InsuredPerson({
  isValid, error_name, error_surname, error_date_of_birth, name, surname, birth_date, onChange, values,
}) {
  const handleChange = (name, value) => {
    onChange(name, value);
  };

  return (
    <InsuredPersonStyles>
      <FormInputText
        placeholder="Nome"
        label="Nome"
        value={values.name}
        error={error_name}
        valid={isValid(name, 'name')}
        onChange={(val) => handleChange(name, val)}
      />
      <FormInputText
        placeholder="Cognome"
        label="Cognome"
        value={values.surname}
        error={error_surname}
        valid={isValid(surname, 'surname')}
        onChange={(val) => handleChange(surname, val)}
      />
      <FormInputDate
        label="Data di nascita"
        value={values.birth_date}
        error={error_date_of_birth}
        valid={isValid(birth_date, 'birth_date')}
        onChange={(val) => handleChange(birth_date, val)}
      />
    </InsuredPersonStyles>
  );
}

export default InsuredPerson;
