import * as React from 'react';
import { FormGroup, Label } from './styles/common';

export function FormInputNumber({
  label, registration, error, valid, min, max,
}) {
  return (
    <FormGroup>
      {label !== undefined
        && <Label>{label}</Label>}
      <input min={min} max={max} className={`form-control mb-1 text-center ${error ? 'is-invalid' : ''} ${valid ? 'is-valid' : ''}`} type="number" {...registration} />
      <div className="invalid-feedback m-0">
        {error}
      </div>
    </FormGroup>
  );
}
