import * as React from 'react';

export default function InputSelectTable(props) {
  const tableRef = React.useRef();
  const selectedVehicleVersion = React.useRef(null);

  const scrollToParent = () => {
    const parent = tableRef.current;
    const parentRect = parent.getBoundingClientRect();
    const childRect = selectedVehicleVersion.current.getBoundingClientRect();

    parent.scrollLeft += (childRect.left - parentRect.left) - (parent.clientWidth / 2);
    parent.scrollTop += (childRect.top - parentRect.top) - (parent.clientHeight / 2);
  };

  React.useEffect(() => {
    if (props.selected !== null && selectedVehicleVersion.current !== null) {
      scrollToParent();
    }
  }, [props.selected]);

  return (
    <div className="form-input-container">
      <div className="row">
        <div className="col-lg-10 offset-lg-1">
          <label className="f-label">{props.label}</label>
          <div className="select-table" ref={tableRef}>
            {props.TableHeader && props.TableHeader}
            {props.options && props.options.map((opt, index) => (
              <div key={index} ref={opt.AM_code == props.selected ? selectedVehicleVersion : undefined} onClick={() => { localStorage.setItem('autoDesc', JSON.stringify(opt)); props.onSelectOption({ id: opt.id, am_code: opt.AM_code }); }}>
                <div className={`d-none d-md-block select-table-item ${opt.AM_code == props.selected ? 'selected' : ''}`}>
                  <div className="d-flex align-items-center">
                    <div className="col-7">{opt.description}</div>
                    <div className="col-1">{opt.cubic_capacity}</div>
                    <div className="col-4">
                      {opt.month}
                      {' '}
                      /
                      {' '}
                      {opt.year}
                    </div>
                  </div>
                </div>
                <div className={`d-md-none border-bottom select-table-item ${opt.AM_code == props.selected ? 'selected' : ''}`}>
                  <div className="d-flex justify-content-between align-items-center my-1">
                    <span className="px-3">Descrizione:</span>
                    <span className="px-3 text-end">{opt.description}</span>
                  </div>
                  <div className="d-flex justify-content-between align-items-center my-1">
                    <span className="px-3">CC:</span>
                    <span className="px-3 text-end">{opt.cubic_capacity}</span>
                  </div>
                  <div className="d-flex justify-content-between align-items-baseline my-1">
                    <span className="px-3">Data Veicolo:</span>
                    <span className="px-3 text-end">
                      {opt.month}
                      {' '}
                      /
                      {' '}
                      {opt.year}
                    </span>
                  </div>
                </div>
              </div>
            ))}
            {props.children
                            && (
                            <div className="children">
                              {props.children}
                            </div>
                            )}
          </div>
        </div>
      </div>
    </div>
  );
}
