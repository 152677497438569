import { faGripVertical, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

function Item({
  setNodeRef, customStyles, attributes, listeners, name, widgetId, deleteWidget,
}) {
  return (
    <div ref={setNodeRef} style={customStyles} className="w-100">
      <div className="btn btn-light d-flex align-items-center justify-content-between text-start p-3 my-2 border-start border-5 border-success">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <div className="btn btn-success me-3 btn-sm">
              <FontAwesomeIcon icon={faGripVertical} {...attributes} {...listeners} />
            </div>
            <span>{name}</span>
          </div>
        </div>
        <button className="btn btn-danger btn-sm" onClick={() => deleteWidget(widgetId, name)}>
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>

    </div>
  );
}

export default Item;
