import React from 'react';
import { useParams } from 'react-router-dom';
import RcVehicle from './RcVehicle';
import RcVan from './RcVan';
import RcMoto from './RcMoto';

function VehicleProduct() {
  const { vehicle } = useParams();

  if (vehicle === 'auto') {
    return <RcVehicle />;
  }

  if (vehicle === 'autocarro') {
    return <RcVan />;
  }

  if (vehicle === 'motorcycle') {
    return <RcMoto />;
  }
}

export default VehicleProduct;
