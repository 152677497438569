const getFullCoveragesListByProduct = (productId) => {
  const mapping = {
    1: [
      'furto',
      'incendio',
      'assistenza_stradale',
      'infortuni_conducente',
      'tutela_legale',
      'collisione',
      'cristalli',
      'rivalsa',
      'kasko',
      'eventi_naturali',
      'eventi_sociopolitici',
    ],
    2: [
      'furto',
      'incendio',
      'assistenza_stradale',
      'infortuni_conducente',
      'tutela_legale',
      'collisione',
      'cristalli',
      'rivalsa',
      'kasko',
      'eventi_naturali',
      'eventi_sociopolitici',
    ],
    3: [
      'furto',
      'incendio',
      'assistenza_stradale',
      'infortuni_conducente',
      'tutela_legale',
      'collisione',
      'cristalli',
      'rivalsa',
      'kasko',
      'eventi_naturali',
      'eventi_sociopolitici',
    ],
  };

  return (typeof productId === 'string' ? mapping[parseInt(productId)] : mapping[productId]) ?? [];
};

const convertToCommonByCompanyId = {
  2: (covs) => {
    let newCovs = covs.filter((c) => c !== 'furto_incendio');

    if (covs.includes('furto_incendio')) {
      newCovs = [...newCovs, 'furto', 'incendio'];
    }

    return newCovs;
  },
};

const convertToCommon = (companyId, coverages) => {
  if (convertToCommonByCompanyId[companyId] === undefined) {
    return coverages;
  }

  return convertToCommonByCompanyId[companyId](coverages);
};

const calculateCommonCoverages = (productId, quotations) => {
  if (quotations.length === 0) {
    return [];
  }

  const fullCoveragesList = getFullCoveragesListByProduct(productId);

  const coveragesSelected = [];

  const quotesFromDirectCompanies = quotations.filter((q) => q.company_id !== null);

  fullCoveragesList.forEach((coverage) => {
    const everyQuoteContainsIt = quotesFromDirectCompanies.every((quotation) => {
      const quotationGurantees = quotation.guarantees.map((g) => g.guarantees_slug);
      const convertedToCommonCoverages = convertToCommon(quotation.company_id, quotationGurantees);

      return convertedToCommonCoverages.includes(coverage);
    });

    if (everyQuoteContainsIt) {
      coveragesSelected.push(coverage);
    }
  });

  return coveragesSelected;
};

export const coveragesUtil = {
  calculateCommonCoverages,
};
