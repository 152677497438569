import React, {
  useEffect, useState,
} from 'react';
import { useSelector } from 'react-redux';
import quotationsAxe from '../../../services/axios-client/quotationsAxeClient';
import CompletedScreenAutoDocuments from './CompletedScreenAutoDocuments';
import CompletedQuoteScreenInfo from './CompletedQuoteScreenInfoAuto';
import CompletedQuoteScreenAutoAfterSale from './CompletedQuoteScreenAutoAfterSale';

function CompletedQuoteScreen({
  quote,
  quoteStatus,
  downloadDocument,
  downloadOptionalDocument,
  collectQuoteData,
  rc_quotation,
  tutela_quotation,
  insuranceRequest,
  assistenza_quotation,
  cristalli_quotation,
  infortuni_conducente_quotation,
  rivalsa_quotation,
}) {
  const [residenceInfo, setResidenceInfo] = useState(null);

  const { document_types } = useSelector(
    (store) => store.afterSale,
  );

  const collectMunicipalities = () => {
    try {
      quotationsAxe.get('/form-data/communes').then((res) => {
        const commune = res.data.data.find(
          (commune) => commune.cadastral_code
            == quote.insurance_request.customer.residence_commune_code,
        );
        setResidenceInfo(commune);
      });
    } catch (err) {
      // Do nothing
    }
  };

  useEffect(() => {
    if (quoteStatus === 7) {
      collectMunicipalities();
    }
  }, [quoteStatus]);

  const documentsRequests = [
    ...quote.insurance_request.document_requests.filter(
      (dr) => dr.document !== null,
    ),
    ...quote.document_requests.filter((dr) => dr.document !== null),
  ];

  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="row mb-2">
          <div className="col-12 col-md-6">
            <div className="card border mb-2 polizze-emessa-status-step-one">
              <div className="card-header bg-secondary text-white">
                <h2 className="h3 mb-0 card-title">Anagrafica</h2>
              </div>
              <div className="card-body">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    {insuranceRequest.customer.gender === 'G' && (
                      <>
                        {' '}
                        <b>Compagnia:</b>
                        {' '}
                        {insuranceRequest.customer.business_name}
                      </>
                    )}
                    {insuranceRequest.customer.gender !== 'G' && (
                      <>
                        <b>Nome e Cognome:</b>
                        {' '}
                        {insuranceRequest.customer.name}
                        {' '}
                        {insuranceRequest.customer.surname}
                      </>
                    )}
                  </li>
                  <li className="list-group-item">
                    <b>Email:</b>
                    {' '}
                    {insuranceRequest.customer.email}
                  </li>
                  <li className="list-group-item">
                    <b>Telefono:</b>
                    {' '}
                    {insuranceRequest.customer.phone}
                  </li>
                  <li className="list-group-item">
                    <b>Indirizo:</b>
                    {' '}
                    {insuranceRequest.customer.address}
                    ,
                    {' '}
                    {insuranceRequest.customer.house_number}
                    ,
                    {' '}
                    {insuranceRequest.customer.postal_code}
                  </li>
                  <li className="list-group-item">
                    <b>Residenza:</b>
                    {' '}
                    {residenceInfo !== null && residenceInfo.name}
                  </li>
                  <li className="list-group-item">
                    <b>Citta:</b>
                    {' '}
                    {residenceInfo !== null
                      && residenceInfo.communal_territory.name}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="card border polizze-emessa-status-step-two">
              <div className="card-header bg-secondary text-white">
                <h2 className="h3 mb-0 card-title">Dati Veicolo</h2>
              </div>
              <div className="card-body">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <b>Targa:</b>
                    {' '}
                    {insuranceRequest.vehicle.vehicle_plate}
                  </li>
                  <li className="list-group-item">
                    <b>Marca:</b>
                    {' '}
                    {insuranceRequest.vehicle.brand.name}
                  </li>
                  <li className="list-group-item">
                    <b>Modello:</b>
                    {' '}
                    {insuranceRequest.vehicle.model.name}
                  </li>
                  <li className="list-group-item">
                    <b>Prima Immatricolazione:</b>
                    {' '}
                    {insuranceRequest.vehicle.vehicle_month}
                    /
                    {insuranceRequest.vehicle.vehicle_year}
                  </li>
                  <li className="list-group-item">
                    <b>Altro Alimentazione:</b>
                    {' '}
                    {insuranceRequest.vehicle.fuel.name}
                  </li>
                  <li className="list-group-item">
                    <b>Antifurto:</b>
                    {' '}
                    {insuranceRequest.vehicle.theft_protection.name}
                  </li>
                  <li className="list-group-item">
                    <b>Auto Comprata:</b>
                    {' '}
                    {insuranceRequest.vehicle.vehicle_purchased_year}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-12">
        <div className="row m">
          <CompletedQuoteScreenInfo
            rc_quotation={rc_quotation}
            tutela_quotation={tutela_quotation}
            infortuni_conducente_quotation={infortuni_conducente_quotation}
            assistenza_quotation={assistenza_quotation}
            cristalli_quotation={cristalli_quotation}
            rivalsa_quotation={rivalsa_quotation}
          />
          <CompletedScreenAutoDocuments downloadDocument={downloadDocument} insuranceRequest={insuranceRequest} quote={quote} collectQuoteData={collectQuoteData} documentsRequests={documentsRequests} downloadOptionalDocument={downloadOptionalDocument} rc_quotation={rc_quotation} />
        </div>
      </div>
      <CompletedQuoteScreenAutoAfterSale quote={quote} document_types={document_types} />
    </div>
  );
}

export default CompletedQuoteScreen;
