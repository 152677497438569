import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CheckDocument, Circle, OptionModalStyles } from './OptionModal.styles';
import { getDocumentsForSend, resetSendDocumentsModal, sendDocument } from '../../features/afterSale/afterSaleActions';
import { FormInputText } from '../../common/formInputs/FormInputText';
import { confirmAlert } from '../../common/utils/alerts';

const schema = yup.object({
  email: yup.string().required('Il campo è obbligatorio')
    .email("Il campo deve essere un'email"),
  name: yup.string().required('Il campo è obbligatorio'),
  surname: yup.string().nullable(),
  selected_doc_ids: yup.array(yup.number()).min(1, 'Devi selezionare al meno un documento!'),
});

export function SendDocument({ quotationId, selectOption }) {
  const { loadingDocs, documents, customer } = useSelector((store) => store.afterSale.send_document);
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    setValue,
    watch,
    reset: resetForm,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: {
      email: '',
      name: '',
      surname: '',
      selected_doc_ids: [],
    },
  });

  const formData = watch();

  const handleSelectedDocs = (docId) => {
    if (formData.selected_doc_ids.find((s) => s === docId)) {
      const tempArr = formData.selected_doc_ids.filter((s) => s !== docId);
      setValue('selected_doc_ids', tempArr);
    } else {
      setValue('selected_doc_ids', [...formData.selected_doc_ids, docId]);
    }
  };

  const resetEverything = () => {
    resetForm();
    selectOption(null);
  };

  const submitData = (formData) => {
    confirmAlert('Sei sicuro di mandare questi documenti?', () => sendDocument(quotationId, formData, resetEverything));
  };

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (quotationId !== null) {
      getDocumentsForSend(quotationId);
    }

    return () => {
      resetSendDocumentsModal();
    };
  }, [quotationId]);

  useEffect(() => {
    if (customer) {
      setValue('name', customer.name);
      setValue('surname', customer.surname);
      setValue('email', customer.email);
    }
  }, [customer]);

  return (
    <OptionModalStyles>
      <div className="option-modal-content">
        <h1 className="option-modal-title">Invio documentazione al cliente</h1>
        {loadingDocs ? (
          <div className="d-flex justify-content-center m-4">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <form onSubmit={handleSubmit(submitData)} className="report-form-container">
            <div className="row">
              <div className="col-12 col-md-12">
                <FormInputText
                  placeholder="Email"
                  label="Email"
                  registration={register('email')}
                  error={errors.email?.message}
                  valid={isValid('email')}
                />
              </div>
              <div className="col-12 col-md-6">
                <FormInputText
                  placeholder="Nome"
                  label="Nome"
                  registration={register('name')}
                  error={errors.name?.message}
                  valid={isValid('name')}
                />
              </div>
              <div className="col-12 col-md-6">
                <FormInputText
                  placeholder="Cognome"
                  label="Cognome"
                  registration={register('surname')}
                  error={errors.surname?.message}
                  valid={isValid('surname')}
                />
              </div>
              <h4 className="text-center">Seleziona documenti da inviare</h4>
              <hr />
              <div className="d-flex align-items-center justify-content-center gap-3">
                {documents.map((doc, index) => (
                  <CheckDocument className="text-dark py-2 px-2" key={index} onClick={() => handleSelectedDocs(doc.id)}>
                    <Circle className={`${formData.selected_doc_ids.find((s) => s === doc.id) ? 'selected' : ''}`} />
                    <p className="mb-0">
                      <small>{doc.document_type_name}</small>
                    </p>
                  </CheckDocument>
                ))}
              </div>
              {errors.selected_doc_ids?.message && <p className="text-danger mb-0 mt-2">{errors.selected_doc_ids?.message}</p>}
            </div>
            <div className="flex-column mt-30 align-items-center justify-content-center gap-2">
              <div className="option-modal-actions">
                <button
                  className="option-modal-button option-modal-button-close"
                  onClick={() => selectOption(null)}
                >
                  Chiudi
                </button>
                <button
                  type="submit"
                  className="option-modal-button option-modal-button-submit"
                >
                  Invia
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </OptionModalStyles>
  );
}
