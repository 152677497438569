import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage } from '@fortawesome/free-solid-svg-icons';
import { WidgetStyles } from './Widgets.styles';
import robot from '../../assets/images/robot-head.png';
import { ChatContext } from '../../app/theme/style-screens/DashboardTheme.context';

function ChatBotWidget() {
  const chatContext = useContext(ChatContext);

  return (
    <WidgetStyles className="custom-card h-100">
      <div className="custom-card-header">
        <div className="custom-card-section">
          <div className="d-flex align-items-center justify-content-between">
            <h3>Supporto Chat AI</h3>
            <img src={robot} alt="Mellino" width={40} />
          </div>
        </div>
      </div>
      <div
        className="card card-body"
        style={{ position: 'relative', overflowY: 'visible', height: '80%' }}
      >
        <div className="custom-card-section d-flex align-items-center justify-content-center">
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <h5 className="mb-3">Hai bisogno di aiuto?</h5>
            </div>
            <div className="col-12 d-flex justify-content-center">
              <button className="btn btn-success" onClick={() => chatContext.setOpenChat((prev) => !prev)}>
                <FontAwesomeIcon icon={faMessage} />
                <span className="mx-2">Scrivi a me</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </WidgetStyles>
  );
}

export default ChatBotWidget;
