import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { LoginStyles } from './Login.styles';

import GreeniaLogo from '../assets/images/greenia-logo.svg';
import User from '../assets/images/user.png';
import { requestForgotPassword, resetForgotPassword } from '../features/auth/authActions';

const schema = yup.object({
  email: yup
    .string()
    .required('Il campo è obbligatorio'),
});

export function ForgotPassword() {
  const navigate = useNavigate();
  const userState = useSelector((store) => store.user);
  const forgotPasswordState = useSelector((store) => store.auth.forgotPassword);

  useEffect(() => {
    if (userState.token) {
      navigate('/');
    }
  }, [userState.token]);

  useEffect(() => () => {
    resetForgotPassword();
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const submitData = (data) => {
    requestForgotPassword(data, () => {
      reset();
    });
  };

  return (
    <LoginStyles>
      <div className="Auth-form-container d-flex justify-content-center bg-img">
        <form onSubmit={handleSubmit(submitData)} className="Auth-form container-form">
          <div className="Auth-form-content">
            <div className="d-flex justify-content-center">
              {' '}
              <img className="img-fluid" width={170} src={GreeniaLogo} alt="Greenia Logo" />
            </div>
            <h3 className="Auth-form-title text-dark fs-5">Hai dimenticato la password</h3>
            {forgotPasswordState.error !== null
                            && <div className="general-form-error">{forgotPasswordState.error}</div>}
            {forgotPasswordState.success === true
                            && <div className="alert alert-success">Ti abbiamo inviato un'e-mail con il collegamento per il ripristino</div>}
            <div className="form-group mt-3">
              <label htmlFor="email">Email</label>
              <div className="input-group inp-login">
                <img className="input-img" src={User} alt="" />
                <input
                  {...register('email')}
                  autoComplete="off"
                  className="form-control inpt mt-1 rounded"
                />
                <div className="general-input-error">{errors.email?.message}</div>
              </div>
            </div>

            <div className="mt-3">
              {forgotPasswordState.loading
                ? <button className="btn btn-primary btn-secondary w-100">Attendere prego...</button>
                : <button type="submit" className="btn btn-primary btn-login fw-bold w-100">Richiedi reimpostazione</button>}
            </div>
            <p className="forgot-password justify-content-center d-grid gap-2 mt-3 mt-2">
              <Link to="/login" className="text-dark">Accedi</Link>
            </p>
          </div>
        </form>
      </div>
    </LoginStyles>
  );
}
