import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from 'react';
import { FormInputText } from '../../common/formInputs/FormInputText';
import { generalAlertSuccess } from '../../common/utils/alerts';
import { ChangePasswordForm } from './ChangePassword.styles';
import { changePassword, resetChangePassword } from '../../features/auth/authActions';

const schema = yup.object({
  current_password: yup.string().required('Il campo è obbligatorio'),
  password: yup
    .string()
    .required('Il campo è obbligatorio')
    .min(6, 'La password deve contenere almeno 6 caratteri')
    .max(60, 'La password deve contenere un massimo di 60 caratteri'),
  confirm_password: yup.string()
    .required('Il campo è obbligatorio')
    .oneOf([yup.ref('password'), ''], 'Le password non coincidono'),
});

export function ChangePassword() {
  const navigate = useNavigate();
  const { loading, error } = useSelector((store) => store.auth.changePassword);

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: {
      current_password: '',
      password: '',
      confirm_password: '',
    },
  });

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  const submitData = (data) => {
    changePassword({
      current_password: data.current_password,
      password: data.password,
    }, () => {
      generalAlertSuccess('Password Cambiata');
      navigate('/');
    });
  };

  useEffect(() => () => {
    resetChangePassword();
  }, []);

  return (
    <ChangePasswordForm className="text-center" onSubmit={handleSubmit(submitData)}>
      <h4 className="mb-4">Cambia la password</h4>
      {error !== null
        && <div className="alert alert-danger">{error}</div>}
      <FormInputText
        label="Password attuale"
        type="password"
        registration={register('current_password')}
        error={errors.current_password?.message}
        valid={isValid('current_password')}
        max={60}
      />

      <FormInputText
        label="Password"
        type="password"
        registration={register('password')}
        error={errors.password?.message}
        valid={isValid('password')}
        max={60}
      />

      <FormInputText
        label="Conferma Password"
        type="password"
        registration={register('confirm_password')}
        error={errors.confirm_password?.message}
        valid={isValid('confirm_password')}
        max={60}
      />

      <button type="submit" className="submit-button" disabled={loading}>
        Cambia la password
      </button>
    </ChangePasswordForm>
  );
}
