import React from 'react';
import { PaymentTypePeriodStyles } from './PaymentTypePeriod.styles';

function PaymentTypePeriod({ selectedType, updateAnswers }) {
  const handleChangeType = (type) => {
    updateAnswers({
      payment_frequency: type,
    });
  };

  return (
    <div className="form-input-container">
      <h5 className="text-center text-muted">Periodicità dei pagamenti</h5>
      <PaymentTypePeriodStyles>
        <button className={`${selectedType === 1 ? 'active' : ''}`} onClick={() => handleChangeType(1)}>Annuale</button>
        <button className={`${selectedType === 2 ? 'active' : ''}`} onClick={() => handleChangeType(2)}>Semestrale</button>
      </PaymentTypePeriodStyles>
    </div>
  );
}

export default PaymentTypePeriod;
