import styled from 'styled-components';

export const BrokerQuotationsStyles = styled.div`
    .company-logo {
        max-width: 100px;
        max-height: 50px;
    }

    .custom-table-row {
        cursor: pointer;
    }
    
    .amount {
        text-align: right;
        padding: 0 20px;
    }

    .amount-header {
        text-align: right;
        padding: 0 20px;
    }

    .header-center {
        text-align: center;
    }
`;

export const CompanyLogo = styled.div`
    width: 100px;
    height: 80px;
    object-fit: contain;
    display: flex;
    align-items: center;
    justify-content: center;
`;
