import axios from 'axios';
import { BACKEND_RESOURCE_URL } from '../../config';
import { backOfficeAxeRequestInterceptor, backOfficeAxeResponseInterceptor } from './axeHelpers';

export const backOfficeAxe = axios.create();

backOfficeAxe.defaults.validateStatus = (status) => status < 500;

backOfficeAxe.interceptors.request.use(backOfficeAxeRequestInterceptor);

backOfficeAxe.interceptors.response.use((response) => response, backOfficeAxeResponseInterceptor);

backOfficeAxe.defaults.baseURL = `${BACKEND_RESOURCE_URL}/back-office`;
