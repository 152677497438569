import React from 'react';
import { Link } from 'react-router-dom';
import { ResearchTypeSection } from './ResearchType.style';

function ResearchType() {
  return (
    <ResearchTypeSection className="container mt-5">
      <h2 className="h2 text-center text-success">
        Seleziona Tipo di Ricerca
      </h2>
      <hr />
      <div className="d-flex align-items-stretch align-items-lg-center text-center justify-content-center flex-column flex-lg-row w-75 mx-auto mt-5 gap-4">
        <Link to="/network-analisys">
          <div className="border border-2 rounded-3 border-success research-class px-5 py-2">
            Analisi Rete
          </div>
        </Link>
        <Link to="/collaborators-analisys">
          <div className="border border-2 rounded-3 border-success research-class px-5 py-2">
            Analisi Collaboratori
          </div>
        </Link>
        <Link to="/comparative-analisys">
          <div className="border border-2 rounded-3 border-success research-class px-5 py-2">
            Analisi Comparativa
          </div>
        </Link>
      </div>

    </ResearchTypeSection>
  );
}

export default ResearchType;
