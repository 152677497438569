import styled from 'styled-components';

export const OptionModalStyles = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,.3);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;

    .option-modal-content {
        margin: 5px;
        padding: 20px;
        width: 100%;
        max-height: 90%;
        max-width: 1200px;
        background-color: white;
        overflow-y: auto;
        border: 1px solid var(--greenia-color);
        border-radius: 5px;
    }

    .option-modal-title {
        padding-bottom: 5px;
        font-size: 25px;
        text-align: center;
        border-bottom: 1px solid var(--greenia-color);
    }

    .option-modal-actions {
        margin-top: 20px;
        display: flex;
        justify-content: end;
    }

    .option-modal-button {
        margin: 5px;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
    }

    .option-modal-button-close {
        color: white;
        background-color: grey;
    }

    .option-modal-button-submit {
        color: white;
        background-color: var(--greenia-color);
        display: flex;
        align-items: center;
    }

    .option-modal-button-submit > .spinner-border {
        height: 20px;
        width: 20px;
        margin-left: 10px;
    }

    .wide-file-wrapper > * {
        width: 100% !important;
    }
`;

export const CheckDocument = styled.div`
    background-color: transparent;
    color: var(--greenia-color);
    border: 1px solid var(--greenia-color);
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
`;

export const Circle = styled.div`
    border: 1px solid var(--greenia-color);
    border-radius: 50%;
    cursor: pointer;
    width: 20px;
    height: 20px;

    &.selected {
        background-color: var(--greenia-color);
    }
`;
