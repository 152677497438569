import styled from 'styled-components';

export const GuaranteesDefaultStateStyles = styled.div`
  border: 2px solid #dbdde6;
  background: #fff;
  border-radius: 30px;
  padding: 20px;
  height: 100%;
  /* display: flex;
  align-items: center;
  justify-content: space-between; */

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const GuaranteesInfoCol = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  width: 65%;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const GuaranteesActionsCol = styled.div`
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  gap: 15px;

  img {
    max-width: 60px;
    margin: auto 0;
  }

  button {
    border-radius: 15px;
    background: #00a651;
    border: 1px solid #00a651;
    padding: 10px 30px;
    color: #ffffff;
    width: 75%;
    transition: all 300ms ease-in-out;

    &:hover {
      background: transparent;
      border: 1px solid #00a651;
      color: #00a651;
    }
  }

  @media only screen and (max-width: 600px) {
    flex-direction: row;
    width: 100%;
    img {
      max-width: 30px;
      margin-left: auto;
      margin-right: 20px;
      transform: rotate(-90deg);
    }

    button {
      font-size: 14px;
      padding: 6px 15px;
      border-radius: 10px;
      width: 50%;
    }
  }
`;

export const DefaultInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  margin-bottom: 15px;

  p {
    margin-bottom: 0;
    color: #343434;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px; /* 100% */
  }

  img {
    max-width: 18px;
  }

  @media only screen and (max-width: 600px) {
    p {
      font-size: 10px;
    }
    gap: 15px;
    align-items: flex-start;
  }
`;

export const BaseTitle = styled.div`
  @media only screen and (max-width: 600px) {
    h4 {
      font-size: 18px;
    }

    p {
      font-size: 12px;
    }
  }
`;

export const SelectedGuaranteesContainer = styled.div`
  width: 100%;
  h2 {
    margin-bottom: 0;
    color: var(--common-black, #1a1921);
    text-align: left;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
  }

  @media only screen and (max-width: 1200px) {
    margin-top: 15px;
    h2 {
      font-size: 18px;
      text-align: start;
    }
  }
`;

export const GuaranteesList = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 5px;
  margin-top: 20px;
  height: 100%;
`;

export const GuaranteesListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 15px;
  h6 {
    margin-bottom: 0;
    color: #343434;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 3px;

    &::before {
      content: "";
      display: inline-block;
      position: relative;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: #00a551;
    }
  }

  p {
    color: #343434;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
  }

  @media only screen and (max-width: 1200px) {
    h6 {
      font-size: 14px;

      &::before {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #00a551;
      }
    }

    p {
      font-size: 16px;
    }
  }
`;
