import styled from 'styled-components';

export const MetlifeStyles = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: auto;
    
    .row-width {
        width: 80%;
        margin: 0 auto;
    }

    .underline {
        position: relative;
    }

    .underline::before {
        content: '';
        position: absolute;
        width: 80%;
        height: 2px;
        background: lightgray;
        display: block;
        bottom: 0;
        left: 0;
    }


    /* Radio Buttons Styles */

    .form-check-input.radio-custom {
        width: 1.5em;
        height: 1.5em;
        margin-top: 0;
        margin-right: 10px;
    }

    .form-check-input.radio-custom[type=radio],
    .form-check-input.radio-custom:checked[type=checkbox] {
        border-radius: 5px;
    }

    .form-check-input.radio-custom:checked {
        background-color: transparent;
        border:2px solid #00A651;
    }

    .form-check-input.radio-custom:checked[type=radio],
    .form-check-input.radio-custom:checked[type=checkbox] {
        background-image: none;
        position: relative;
    }

    .form-check-input.radio-custom:checked[type=radio]::before,
    .form-check-input.radio-custom:checked[type=checkbox]::before {
        content: '';
        position: absolute;
        width: 1.2em;
        height: 1.2em;
        border-radius: 4px;
        top: calc(50% - 0.6em);
        left: calc(50% - 0.6em);
        background-color: #00A651;
    }

    .form-check-input.radio-custom:focus {
        box-shadow: 0 0 0 0.25rem rgba(0, 166, 81, 0.25);
    }

    /* Step 3 Styles */

    .plan-title {
        background-color: gray;
    }
`;
