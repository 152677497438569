const generateProfessionaleConfiguration = (message) => ({
  message,
  allowTyping: true,
});

export const messagesMap = [
  {
    message: 'Flusso diretto',
    options: [
      {
        message: 'Voglio tutelare un rischio',
        options: [
          {
            message: 'Auto',
            allowTyping: true,
            isCommand: true,
          },
          {
            message: 'Professionale',
            isCommand: true,
          },
        ],
      },
      {
        message: 'Voglio formazione su uno specifico prodotto, di carattere generale o sulla piattaforma',
        options: [
          {
            message: 'Prodotto',
            options: [
              {
                message: 'Prima',
                allowTyping: true,
              },
              {
                message: 'Adriatic',
                allowTyping: true,
              },
              {
                message: 'Linear',
                allowTyping: true,
              },
              {
                message: 'Professionale',
                options: [
                  generateProfessionaleConfiguration('Ingegnere'),
                  generateProfessionaleConfiguration('Architetto'),
                  generateProfessionaleConfiguration('Avvocato'),
                  generateProfessionaleConfiguration('Commercialista'),
                  generateProfessionaleConfiguration('Consulente Del lavoro'),
                  generateProfessionaleConfiguration('Agente immobiliare'),
                  generateProfessionaleConfiguration('Agente in attività finanziarie'),
                  generateProfessionaleConfiguration('Amministratore di condominio'),
                  generateProfessionaleConfiguration('Geometra'),
                  generateProfessionaleConfiguration('Mediatore creditizio'),
                  generateProfessionaleConfiguration('Perito'),
                  generateProfessionaleConfiguration('Tributarista'),
                  generateProfessionaleConfiguration('Agente di commercio'),
                  generateProfessionaleConfiguration('Agronomo'),
                  generateProfessionaleConfiguration('Centro elaborazione dati'),
                  generateProfessionaleConfiguration('Chimici'),
                  generateProfessionaleConfiguration('DPO'),
                  generateProfessionaleConfiguration('Geologi'),
                  generateProfessionaleConfiguration('Miscellaneous'),
                  generateProfessionaleConfiguration('Psicologo'),
                  generateProfessionaleConfiguration('Revisore leagale dei conti'),
                  generateProfessionaleConfiguration('Tecnologo alimentare'),
                ],
              },
            ],
          },
          {
            message: 'Generale',
          },
          {
            message: 'Piattaforma',
            isCommand: true,
          },
        ],
      },
      {
        message: 'Voglio eseguire una attività post vendita',
        options: [
          {
            message: 'Voglio denunciare un sinistro',
            allowTyping: true,
            isCommand: true,
          },
          {
            message: 'Voglio sostituire qualcosa nel contratto',
            allowTyping: true,
            isCommand: true,
          },
          {
            message: 'Voglio modificare qualcosa nel contratto',
            allowTyping: true,
            isCommand: true,
          },
          {
            message: 'Voglio il cessazione di un contratto',
            allowTyping: true,
            isCommand: true,
          },
          {
            message: 'Voglio inviare il documentazione al cliente',
            allowTyping: true,
            isCommand: true,
          },
        ],
      },
      {
        message: 'Voglio contattare il cliente',
        allowTyping: true,
        isCommand: true,
      },
      {
        message: 'Voglio fissare una videocall o un memo',
        options: [
          {
            message: 'Voglio fissare una videocall',
            options: [
              {
                message: 'Con Back office',
                allowTyping: true,
              },
              {
                message: 'Con Commerciale',
                allowTyping: true,
              },
              {
                message: 'Con Cliente',
              },
            ],
          },
          {
            message: 'Voglio fissare un memo',
          },
        ],
      },
      {
        message: 'Voglio mandare mail all\'amministratore per approfondire la richiesta',
      },
    ],
  },
  {
    message: 'Flusso non diretto',
    allowTyping: true,
  },
];
