import styled from 'styled-components';

export const SidebarStyles = styled.div`
    .sidebar {
        background-color: #f4f8fb;
        position: fixed;
        top: -30px;
        bottom: 0;
        left: 0;
        padding: 58px 0 0;
        box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%);
        width: 300px;
        height: calc(100% + 30px);
        overflow-y: auto;
        z-index: 600;
    }

    @media (max-width: 991px) {
        .sidebar {
            background-color: #f4f8fb;
            width: 100%;
        }
    }

    .header-logo {
        height: 30px;
    }

    .list-group-flush > .list-group-item {
        background-color: #f4f8fb;
    }

    .item {
        color: #6f7176;
        padding-left: 20px;
    }
    .text-lightgrey {
        color: #6f7176;
    }

    .list-group-item + .list-group-item.active {
        border-color: #6f7176;
        color: #6f7176;
    }

    .item-img {
        height: 20px;
    }

    .item-input {
        width: 100%;
        height: 50px;
        border: none;
        outline: none;
        padding: 0 10px;
        margin-left: 10px;
        background: rgba(0, 0, 0, 0);
    }

    #sidebarMenu,
    .navbar {
        z-index: 1000;
    }

    nav ul {
        position: relative;
        list-style-type: none;
    }

    @media only screen and (max-width: 768px) {
        nav ul {
            position: initial;
        }
    }

    li {
        border-bottom: 2px solid #edeeee;
    }

    ol,
    ul {
        padding-left: 0rem;
    }

    .py-2 {
        padding-top: 0.9rem !important;
        padding-bottom: 0.9rem !important;
        padding-left: 1.9rem !important;
    }

    #sidebarMenu {
        border-right: 1px solid #edeeee;
    }

    .list-group-item:hover {
        background-color: #f6f5f4;
    }

    .second-ul {
        padding-top: 0.9rem !important;
        padding-bottom: 0.9rem !important;
        padding-right: 0.9rem !important;
    }

    nav ul ul {
        position: absolute;
        left: 300px;
        top: 0;
        display: none;
        width: 250px;
        background-color: #f4f8fb;
    }

    @media only screen and (max-width: 768px) {
        nav ul ul {
            position: initial;
        }
    }

    nav ul .dropdown {
        position: relative;
    }

    nav ul .dropdown:hover ul {
        display: initial;
    }

    .icon {
        position: absolute;
        right: 20px;
        font-size: 1.9em;
        bottom: 10px;
        color: #737476;
    }

    .hide {
        position: absolute;
        top: 50px;
        right: 20px;
        font-size: 1.9em;
        color: #737476;
    }

    .notification-badge {
        padding-left: 6px;
        padding-right: 6px;
        padding-top: 2px;
        padding-bottom: 2px;
        background-color: red;
        border-radius: 50%;
        color: white;
    }
`;
