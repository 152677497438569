import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { emptySearchResults, searchAction } from '../../features/dashboard/dashboardActions';
import { useDebouncedEffect } from '../utils/useDebouncedEffect';
import { WidgetStyles } from './Widgets.styles';

function ClientSearchWidget() {
  const navigate = useNavigate();

  const state = useSelector((store) => store.dashboard.dashboard);

  const {
    searchResults,
  } = state;

  const [search, setSearch] = useState('');

  useDebouncedEffect(() => searchAction(search), [search], 400);

  const onSearchChange = (e) => {
    setSearch(e.target.value);

    if (e.target.value.length === 0) {
      emptySearchResults();
    }
  };

  const navigateToCustomerPage = (customerId) => {
    navigate(`/clients/${customerId}`);
  };

  return (
    <>
      <WidgetStyles id="client-search" className="custom-card h-100">
        <div className="custom-card-header">
          <div className="custom-card-section">
            <h3>Cerca Cliente</h3>
          </div>
        </div>
        <div
          className="card card-body"
          style={{ position: 'relative', overflowY: 'visible' }}
        >
          <div className="custom-card-section">
            <div className="row">
              <div className="col-12 col-md-12">
                <h6>Scrivi nome del cliente</h6>
                <div className="search-input-container">
                  <div className="search-input-icon">
                    <FontAwesomeIcon icon={faSearch} />
                  </div>
                  <div className="search-input-divider" />
                  <input
                    className="search-input-content"
                    placeholder="Nome del cliente"
                    value={search}
                    onChange={onSearchChange}
                  />
                  <div className="search-input-results">
                    {search !== '' && searchResults.length === 0
                      ? 'Nessun risultato'
                      : searchResults.map((result) => (
                        <div
                          key={result.id}
                          className="search-input-result"
                          onClick={() => navigateToCustomerPage(result.id)}
                        >
                          {result.name.toUpperCase() + (result.fiscal_code !== null ? ` - (${result.fiscal_code})` : '')}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </WidgetStyles>
      {/* <div id="client-search-mobile">
        <div className="custom-card">
          <div className="custom-card-header">
            <div className="custom-card-section" style={{ flex: 2 }}>
              <h3>Cerca Cliente</h3>
            </div>
            <div className="custom-card-section"></div>
          </div>
          <div
            className="custom-card-body"
            style={{ position: "relative", overflowY: "visible" }}
          >
            <div className="custom-card-section" style={{ flex: 2 }}>
              <h6>Scrivi nome del cliente</h6>
              <div className="search-input-container">
                <div className="search-input-icon">
                  <FontAwesomeIcon icon={faSearch} />
                </div>
                <div className="search-input-divider"></div>
                <input
                  className="search-input-content"
                  placeholder="Nome del cliente"
                  value={search}
                  onChange={onSearchChange}
                />
                <div className="search-input-results">
                  {search !== "" && searchResults.length === 0
                    ? "Nessun risultato"
                    : searchResults.map((result) => {
                      return (
                        <div
                          key={result.id}
                          className="search-input-result"
                          onClick={() => navigateToCustomerPage(result.id)}
                        >
                          {result.name.toUpperCase() + (result.fiscal_code !== null ? ' - (' + result.fiscal_code + ')' : '')}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="custom-card">
          <div className="custom-card-body custom-card-body--body-only">
            <div className="custom-card-section">
              <br />
              <h6>Vai alle offerte</h6>
              <div
                className="new-quotation-button"
                onClick={() => navigate("/quote")}
              >
                FAI UN PREVENTIVO
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default ClientSearchWidget;
