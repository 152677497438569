import { createSlice } from '@reduxjs/toolkit';
import { callerCreator } from '../callerCreator';

const initialState = {};

export const infodriveSlice = createSlice({
  name: 'infodrive',
  initialState,
  reducers: {},
});

export default callerCreator(infodriveSlice.actions);
