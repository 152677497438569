export const questionarioCommercial = [
  {
    title: 'Localizzazione e Ambiente',
    questions: [
      {
        question: "La sede oggetto di analisi si trova a meno di 500 metri da bacini idrici o corsi d'acqua?",
        input_name: 'location_and_structures.proximity_to_water_bodies',
      },
      {
        question: 'La sede oggetto di analisi è situata in prossimità di pendici friabili o aree soggette a frane?',
        input_name: 'location_and_structures.proximity_to_fragile_slopes_or_landslide_areas',
      },
      {
        question: 'La sede oggetto di analisi è sopraelevata di almeno 50 cm rispetto al piano di campagna?',
        input_name: 'location_and_structures.elevation_above_ground_level',
      },
      {
        question: 'A meno di 500 metri dalla sede oggetto di analisi, ci sono importanti vie stradali, ferroviarie, aeroporti, ecc.?',
        input_name: 'location_and_structures.proximity_to_major_transport_routes',
      },
      {
        question: 'Nel sottosuolo sono presenti falde acquifere?',
        input_name: 'location_and_structures.presence_of_groundwater',
      },
    ],
  },
  {
    title: 'Struttura Edilizia',
    questions: [
      {
        question: "All'interno della sede oggetto di analisi sono presenti locali interrati o seminterrati?",
        input_name: 'location_and_structures.presence_of_basements_or_semi_basements',
      },
      {
        question: "All'interno della sede sono presenti scaffalature?",
        input_name: 'location_and_structures.presence_of_shelving',
      },
      {
        question: 'La sede oggetto di analisi ha una configurazione del tetto con travi appoggiate (non antisismica)?',
        input_name: 'location_and_structures.non_seismic_roof_configuration',
      },
      {
        question: 'Le travature sono ancorate ai pilastri per prevenire il crollo dovuto alle spinte orizzontali?',
        input_name: 'location_and_structures.beams_anchored_to_pillars',
      },
      {
        question: 'La sede oggetto di analisi presenta cabine elettriche di trasformazione?',
        input_name: 'location_and_structures.presence_of_electrical_transformer_rooms',
      },
      {
        question: 'La sede oggetto di analisi presenta una centrale termica?',
        input_name: 'location_and_structures.presence_of_thermal_power_station',
      },
      {
        question: 'La sede oggetto di analisi presenta compressori per aria compressa?',
        input_name: 'location_and_structures.presence_of_air_compressors',
      },
      {
        question: 'La sede oggetto di analisi presenta gruppi refrigeratori?',
        input_name: 'location_and_structures.presence_of_refrigeration_units',
      },
      {
        question: "L'edificio in cui si svolge l'attività è di tipo combustibile (es. tamponamenti in legno, isolanti ad elevato contenuto organico, biologici o plastici)?",
        input_name: 'location_and_structures.combustible_building_materials',
      },
      {
        question: "All'interno della sede oggetto di analisi sono presenti ambienti confinati (es. serbatoi, vasche, cisterne) che richiedono un accesso periodico da parte dei lavoratori?",
        input_name: 'location_and_structures.presence_of_confined_spaces',
      },
      {
        question: "Le apparecchiature che compongono il sistema di elaborazione dati dell'azienda (CED/Server) sono ubicate in un piano interrato?",
        input_name: 'location_and_structures.data_processing_equipment_in_basement',
      },
      {
        question: 'Vi sono pannelli sandwich classici (con inserti in materiali poliuretanici o simili)?',
        input_name: 'location_and_structures.presence_of_classic_sandwich_panels',
      },
      {
        question: 'Di che materiale sono le strutture portanti del tetto?',
        input_name: 'location_and_structures.roof_supporting_structure_material',
        input_type: 'select',
      },
    ],
  },
  {
    title: 'Infrastrutture e Impianti',
    questions: [
      {
        question: 'Il livello delle fognature della sede oggetto di analisi è superiore rispetto a quello delle fognature pubbliche?',
        input_name: 'infrastructure_and_machines.sewer_level_above_public_sewers',
      },
      {
        question: "L'impianto elettrico è stato realizzato prima del 1990?",
        input_name: 'infrastructure_and_machines.electrical_system_pre_1990',
      },
      {
        question: 'Esiste la possibilità di danneggiamento degli impianti, macchinari o attrezzature in caso di interruzione della fornitura di energia elettrica alla sede oggetto di analisi?',
        input_name: 'infrastructure_and_machines.damage_risk_to_equipment_due_to_power_outage',
      },
      {
        question: 'Esiste la possibilità di danneggiamento dei computer o server aziendali in caso di interruzione della fornitura di energia elettrica alla sede oggetto di analisi?',
        input_name: 'infrastructure_and_machines.damage_risk_to_computers_due_to_power_outage',
      },
      {
        question: 'Il corretto funzionamento del sistema informatico aziendale è essenziale per la continuità operativa?',
        input_name: 'infrastructure_and_machines.it_system_essential_for_continuity',
      },
      {
        question: 'Sono presenti imballaggi combustibili nella sede oggetto di analisi?',
        input_name: 'infrastructure_and_machines.presence_of_combustible_packaging',
      },
      {
        question: 'Sono presenti imballaggi in materiale plastico espanso (es. polistirolo) nella sede oggetto di analisi?',
        input_name: 'infrastructure_and_machines.presence_of_expanded_plastic_packaging',
      },
      {
        question: "All'interno della sede oggetto di analisi vengono utilizzati gas infiammabili (es. per il funzionamento di macchinari o per la saldatura)?",
        input_name: 'infrastructure_and_machines.use_of_flammable_gases',
      },
      {
        question: 'Durante il periodo di chiusura estivo o invernale è presente del personale in sede?',
        input_name: 'infrastructure_and_machines.staff_present_during_closure',
      },
      {
        question: 'La sede oggetto di analisi presenta rischio di esplosione (es. zone carica carrelli, polveri fini, alta pressione metano)?',
        input_name: 'infrastructure_and_machines.explosion_risk',
      },
      {
        question: 'Il tempo di intervento dei Vigili del Fuoco è inferiore a 15 minuti?',
        input_name: 'infrastructure_and_machines.firefighter_response_time_under_15_minutes',
      },
      {
        question: "Durante le ore notturne, è presente del personale dell'azienda all'interno della sede oggetto di analisi?",
        input_name: 'infrastructure_and_machines.staff_present_at_night',
      },
      {
        question: "L'azienda stocca merce di valore (es. abbigliamento, scarpe, elettronica) per conto dei clienti?",
        input_name: 'infrastructure_and_machines.valuable_goods_storage',
      },
      {
        question: "È previsto l'utilizzo di carrelli elevatori o sistemi di movimentazione dei carichi?",
        input_name: 'infrastructure_and_machines.use_of_forklifts_or_load_handling_systems',
      },
      {
        question: 'La sede oggetto di analisi è soggetta al rischio di esposizione a elementi cancerogeni (es. formaldeide, piombo, benzene, polveri di legno duro)?',
        input_name: 'infrastructure_and_machines.risk_of_exposure_to_carcinogens',
      },
      {
        question: "All'interno della sede oggetto di analisi vengono utilizzati prodotti chimici (es. oli, sostanze chimiche, vernici)?",
        input_name: 'infrastructure_and_machines.use_of_chemicals',
      },
      {
        question: "Sono presenti serbatoi interrati con sostanze diverse dall'acqua?",
        input_name: 'infrastructure_and_machines.presence_of_underground_tanks_with_non_water_substances',
      },
      {
        question: 'Sono presenti macchinari o impianti che utilizzano oli per il loro funzionamento?',
        input_name: 'infrastructure_and_machines.machinery_using_oil',
      },
      {
        question: 'Le pavimentazioni dove sono presenti macchinari o dove sono stoccate le merci sono in cemento o resina? ',
        input_name: 'infrastructure_and_machines.concrete_or_resin_flooring',
      },
      {
        question: 'Vi è utilizzo o stoccaggio di vernici non ad acqua e/o solventi infiammabili? ',
        input_name: 'infrastructure_and_machines.use_or_storage_of_flammable_solvents',
      },
      {
        question: 'Nel processo aziendale viene fatto uso di fiamme libere?',
        input_name: 'infrastructure_and_machines.use_of_open_flames',
      },
    ],
  },
  {
    title: 'Macchinari e Attrezzature',
    questions: [
      {
        question: "Le merci stoccate nella sede oggetto di analisi sono sensibili all'umidità?",
        input_name: 'infrastructure_and_machines.goods_sensitive_to_humidity',
      },
      {
        question: "I macchinari presenti nella sede oggetto di analisi sono sensibili all'umidità?",
        input_name: 'infrastructure_and_machines.machinery_sensitive_to_humidity',
      },
      {
        question: "All'interno della sede oggetto di analisi sono presenti manufatti come ciminiere, torri, sili o serbatoi?",
        input_name: 'infrastructure_and_machines.presence_of_structures_like_chimneys_towers_silos_tanks',
      },
      {
        question: "L'azienda utilizza macchinari unici ed esclusivi per alcune fasi del processo produttivo?",
        input_name: 'infrastructure_and_machines.use_of_unique_machinery_for_production',
      },
      {
        question: "L'azienda utilizza macchinari prodotti su commessa (macchine speciali)?",
        input_name: 'infrastructure_and_machines.use_of_custom_machinery',
      },
      {
        question: "L'azienda possiede macchinari non più in produzione da parte dei costruttori?",
        input_name: 'infrastructure_and_machines.machinery_no_longer_in_production',
      },
      {
        question: 'In caso di guasto di un macchinario unico ed esclusivo, esiste la possibilità di modificare il processo produttivo utilizzando macchinari o metodi alternativi?',
        input_name: 'infrastructure_and_machines.possibility_to_modify_process_with_alternative_machinery',
      },
      {
        question: "I macchinari dell'azienda sono esposti ad ambienti corrosivi?",
        input_name: 'infrastructure_and_machines.machinery_exposed_to_corrosive_environments',
      },
      {
        question: "I macchinari dell'azienda sono situati in ambienti polverosi?",
        input_name: 'infrastructure_and_machines.machinery_in_dusty_environments',
      },
      {
        question: "In caso di guasto prolungato di un macchinario critico, esistono backup presso altri stabilimenti o reparti dell'azienda? ",
        input_name: 'infrastructure_and_machines.backup_for_critical_machinery',
      },
      {
        question: 'Vi è utilizzo di macchine mordenti nel processo?',
        input_name: 'infrastructure_and_machines.use_of_biting_machines',
      },
      {
        question: 'Esistono macchinari/attrezzature/materie prime con tempo di rimpiazzo superiore a 6 mesi?',
        input_name: 'infrastructure_and_machines.equipment_or_material_with_long_replacement_time',
      },
    ],
  },
  {
    title: 'Sicurezza e Prevenzione',
    questions: [
      {
        question: 'La sede oggetto di analisi contiene merci o prodotti facilmente rivendibili in caso di furto?',
        input_name: 'security_and_logistics.contains_easily_resellable_goods',
      },
      {
        question: 'La sede oggetto di analisi contiene merci o prodotti difficilmente identificabili e rintracciabili in caso di furto?',
        input_name: 'security_and_logistics.contains_hard_to_identify_goods',
      },
      {
        question: 'Gli ambienti della sede oggetto di analisi sono frequentemente accessibili a persone estranee al personale aziendale?',
        input_name: 'security_and_logistics.frequent_access_by_non_staff',
      },
      {
        question: 'La sede oggetto di analisi contiene merci o prodotti facilmente trasportabili che non richiedono mezzi di movimentazione per essere spostati?',
        input_name: 'security_and_logistics.contains_easily_transportable_goods',
      },
      {
        question: 'Sono stati rilevati casi di malattie professionali tra i dipendenti?',
        input_name: 'security_and_logistics.reported_occupational_illnesses',
      },
      {
        question: "Esistono situazioni in cui terzi accedono ai locali dell'azienda per installazioni o servizi?",
        input_name: 'security_and_logistics.third_party_access_for_installations_or_services',
      },
      {
        question: "L'azienda effettua installazioni o servizi presso terzi con propri operatori?",
        input_name: 'security_and_logistics.provides_installations_or_services_at_third_party_locations',
      },
      {
        question: "L'azienda conserva beni di terzi all'interno dei propri locali?",
        input_name: 'security_and_logistics.stores_third_party_goods',
      },
    ],
  },
  {
    title: 'Logistica e Trasporti',
    questions: [
      {
        question: "Il valore della merce trasportata con trasporto a carico dell'azienda (EXW se fornitura, DAP o simili per spedizioni), per le tratte italiane è superiore a 1 €/kg?",
        input_name: 'security_and_logistics.high_value_domestic_transported_goods',
      },
      {
        question: "Il valore della merce trasportata con trasporto a carico dell'azienda (EXW se fornitura, DAP o simili per spedizioni), per le tratte estere è superiore a 10 €/kg?",
        input_name: 'security_and_logistics.high_value_international_transported_goods',
      },
      {
        question: "L'azienda effettua spedizioni di merce deperibile?",
        input_name: 'security_and_logistics.ships_perishable_goods',
      },
      {
        question: "L'azienda è soggetta alla normativa ADR (gomma), IRMP (treno), IATA (aereo), IMDG (nave) per il trasporto di merci pericolose?",
        input_name: 'security_and_logistics.subject_to_hazardous_material_transport_regulations',
      },
      {
        question: "L'azienda si avvale prevalentemente di operatori logistici e trasportatori con i quali ha in essere un contratto continuativo?",
        input_name: 'security_and_logistics.uses_contract_logistics_providers',
      },
    ],
  },
  {
    title: 'Processi e Prodotti',
    questions: [
      {
        question: "L'azienda appone il proprio nome, marchio o segno distintivo sui prodotti o sulla loro confezione?",
        input_name: 'product_and_its.company_branding_on_products',
      },
      {
        question: "L'azienda importa nella Comunità Europea prodotti o componenti per la vendita o la distribuzione?",
        input_name: 'product_and_its.imports_into_eu',
      },
      {
        question: "L'azienda commercializza prodotti negli Stati Uniti, Canada o Messico?",
        input_name: 'product_and_its.sells_in_us_canada_mexico',
      },
      {
        question: 'Tra i prodotti, ve ne sono di alterabili o deteriorabili nel tempo per caratteristiche intrinseche, cause ambientali o modalità di conservazione?',
        input_name: 'product_and_its.perishable_products',
      },
      {
        question: 'I prodotti utilizzano energia elettrica per il loro funzionamento?',
        input_name: 'product_and_its.products_use_electricity',
      },
      {
        question: 'I prodotti utilizzano gas combustibili per il loro funzionamento?',
        input_name: 'product_and_its.products_use_combustible_gases',
      },
      {
        question: "L'azienda provvede all'installazione, posa in opera, manutenzione e riparazione dei propri prodotti? ",
        input_name: 'product_and_its.provides_installation_and_maintenance',
      },
      {
        question: "C'è presenza di merci speciali?",
        input_name: 'product_and_its.presence_of_special_goods',
      },
      {
        question: 'Esistono colli di bottiglia nel processo? ',
        input_name: 'product_and_its.process_bottlenecks',
      },
      {
        question: 'Qual è la vulnerabilità al bagnamento di merci e/o macchinari? ',
        input_name: 'product_and_its.vulnerability_to_wetting',
        input_type: 'select',
      },
      {
        question: 'Le merci più esposte al bagnamento hanno la possibilità di essere spostate con relativa facilità? ',
        input_name: 'product_and_its.easy_to_move_goods_away_from_wet',
      },
      {
        question: 'Qual è lo sfruttamento degli spazi di lavoro è alto? ',
        input_name: 'product_and_its.high_workspace_utilization',
        input_type: 'select',
      },
      {
        question: "L'azienda opera nella filiera della grande distribuzione? ",
        input_name: 'product_and_its.operates_in_large_distribution_chain',
      },
      {
        question: 'Esiste ed è adeguatamente fornito ed aggiornato un magazzino di ricambi essenziali?  ',
        input_name: 'product_and_its.adequately_stocked_spare_parts_warehouse',
      },
      {
        question: "Esistono archivi, documenti, registri, (sia cartacei che digitali), supporti dati, programmi in licenza d'uso necessari ed essenziali per il business aziendale?  ",
        input_name: 'product_and_its.critical_business_documents',
      },
      {
        question: "C'è la possibilità di incrementare i turni di lavoro?",
        input_name: 'product_and_its.possibility_of_increasing_work_shifts',
      },

    ],
  },
  {
    title: 'Continuità Operativa e IT',
    questions: [
      {
        question: 'Lo stabilimento è dotato di un sistema di Disaster Recovery e Business Continuity per il sistema informativo?',
        input_name: 'product_and_its.disaster_recovery_and_business_continuity_system',
      },
      {
        question: 'È possibile accedere al sistema informativo aziendale da remoto (es. VPN, FTP)?',
        input_name: 'product_and_its.remote_access_to_information_system',
      },
      {
        question: 'Lo stabilimento è dotato di un sistema di comunicazione di backup oltre a quello consueto (fibra, ADSL, ponte radio, satellite)? ',
        input_name: 'product_and_its.backup_communication_system',
      },
      {
        question: "L'azienda ha adottato adeguati modelli organizzativi per rilevare eventuali situazioni di crisi e garantire la continuità aziendale (riforma Codice Crisi d'impresa)?",
        input_name: 'product_and_its.adopted_organizational_models_for_crisis_management',
      },
    ],
  },
  {
    title: 'Finanza e Crediti',
    questions: [
      {
        question: "L'azienda applica un sistema che identifica il fido da concedere a ciascun cliente in base alla rischiosità dello stesso?",
        input_name: 'finance_and_personals.credit_system_based_on_risk',
      },
      {
        question: "Le modalità di pagamento sono studiate in modo tale da ridurre al minimo l'esposizione nei confronti del cliente al momento della consegna del bene o del servizio erogato (es. mediante anticipi, lettere di credito, fideiussioni)?",
        input_name: 'finance_and_personals.payment_methods_minimize_exposure',
      },
      {
        question: "L'utilizzo dei fidi bancari (Sbf, anticipi fatture, conto corrente) è mediamente superiore al 60% della disponibilità? ",
        input_name: 'finance_and_personals.bank_credit_utilization_above_60_percent',
      },
      {
        question: 'Il fatturato è soggetto a fluttuazioni stagionali o periodiche?',
        input_name: 'finance_and_personals.seasonal_or_periodic_revenue_fluctuations',
      },
      {
        question: "I magazzini hanno una volatilità superiore al 20% durante l'anno?",
        input_name: 'finance_and_personals.inventory_volatility_above_20_percent',
      },
    ],
  },
  {
    title: 'Personale e Ruoli Chiave',
    questions: [
      {
        question: "L'azienda ha controllate nell'Unione Europea o in Svizzera?",
        input_name: 'finance_and_personals.subsidiaries_in_eu_or_switzerland',
      },
      {
        question: "Esistono figure chiave indispensabili all'esecuzione della strategia aziendale?",
        input_name: 'finance_and_personals.key_personnel_for_strategy',
      },
      {
        question: 'Esistono figure chiave indispensabili al processo produttivo o di erogazione del servizio?',
        input_name: 'finance_and_personals.key_personnel_for_production',
      },
      {
        question: 'Esistono figure chiave che detengono relazioni esclusive con i clienti?',
        input_name: 'finance_and_personals.key_personnel_with_exclusive_client_relationships',
      },
      {
        question: "L'azienda ha ubicazioni, sedi o dipendenti al di fuori dell'Unione Europea, Norvegia e Svizzera?",
        input_name: 'finance_and_personals.locations_or_employees_outside_eu_norway_switzerland',
      },
      {
        question: "Gli Amministratori o Dirigenti possiedono azioni o quote dell'azienda?",
        input_name: 'finance_and_personals.executives_own_company_shares',
      },
      {
        question: "Tra i fornitori, esistono società di consulenza i cui soci o amministratori dell'azienda detengono quote societarie o svolgono attività per conto delle stesse?",
        input_name: 'finance_and_personals.conflict_of_interest_with_consulting_firms',
      },
      {
        question: 'Esistono contratti di sponsorizzazione (attività sportive o altro)? ',
        input_name: 'finance_and_personals.sponsorship_contracts',
      },
      {
        question: "Esistono un organigramma definito e una governance consolidata dell'azienda? ",
        input_name: 'finance_and_personals.defined_organizational_chart_and_governance',
      },
      {
        question: "L'azienda è esposta pubblicamente con il proprio brand (mediaticamente o in altro modo)?",
        input_name: 'finance_and_personals.public_exposure_of_brand',
      },
    ],
  },
];
