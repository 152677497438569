import styled from 'styled-components';

export const CommercialCompanyDataStyles = styled.div`
.nav-tabs .nav-item .nav-link {
        color: #00A651;
        font-size:16px;
        font-weight: 500;
    }

    .nav-tabs .nav-link.active {
        background-color: #00A651;
        color: #ffffff;
        border-bottom: 2px solid #00A651;
    }
  
`;
