import styled from 'styled-components';

export const TableYearHeader = styled.th`
    text-align: center;
`;

export const TableInputText = styled.input`
    width: 100%;
    padding: 5px;
    border: 1px solid ${(props) => (props.value === 'NaN' ? 'red' : 'lightgrey')};
    color: ${(props) => (props.value === 'NaN' ? 'red' : 'parent')};
    border-radius: 4px;
    font-weight: normal;
    text-align: center;
    outline: none;

    &:focus {
        border-color: lightgreen;
    }
`;

export const TableHeaderButton = styled.div`
    margin: 2px;
    padding: 5px 0;
    border: 1px solid lightgrey;
    border-radius: 2px;
    width: calc(100% - 6px);
    float: left;
    text-align: center;
    cursor: pointer;
    transition: .3s;

    &:hover {
        border-color: lightgreen;
    }
`;
