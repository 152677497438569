import React from 'react';
import {
  BoxWrapper, ClientBox,
} from './CommonPurchase.styles';
import { productsTravel } from '../../../../constants';
import { downloadFile } from '../../../../../services/axios-client/axeHelpers';
import axe from '../../../../../services/axios-client/axiosClient';

function InfoBoxTravel({
  insuranceRequest, statues, quoteStatus, rc_quotation, tutela_quotation,
}) {
  const downloadResourceDocs = async (producCode) => {
    const res = await axe.get(`/travel/informativa/${producCode}`, {
      responseType: 'blob',
    });

    downloadFile(res.data);
  };

  const getTotal = () => {
    let quoteInProccessAmount = 0;
    let tutelaInProccessAmount = 0;

    if (rc_quotation !== null) {
      quoteInProccessAmount = parseFloat(rc_quotation.amount);
    }
    if (tutela_quotation !== null) {
      tutelaInProccessAmount = parseFloat(tutela_quotation.amount);
    }

    const total = quoteInProccessAmount + tutelaInProccessAmount;

    return total.toFixed(2);
  };

  return (
    <>
      <BoxWrapper>
        <div className="row gap-2 gap-xl-0">
          <div className="col-12 col-xl-4">
            <ClientBox className="pagament-status-step-one">
              <h6>Cliente:</h6>
              <h5>
                {insuranceRequest.customer.gender !== 'G' ? `${insuranceRequest.customer.name} ${insuranceRequest.customer.surname}` : insuranceRequest.customer.business_name}
              </h5>
              <h6 className="mt-2">Tipo Prodotto:</h6>
              <h5>
                {productsTravel[rc_quotation.quotation_data_travel_europeassistance.product_code]}
              </h5>
              <h6 className="mt-2">Destinazione:</h6>
              <h5>
                {insuranceRequest.insurance_request_travel.country.name}
              </h5>
              <h6 className="mt-2">Numero Persone:</h6>
              <h5>
                {insuranceRequest.insurance_request_travel.insurance_request_travel_people.length + 1}
              </h5>
              <h6 className="mt-2">Data Partenza:</h6>
              <h5>
                {new Date(
                  insuranceRequest.policy_effective_date,
                ).toLocaleDateString('it-IT')}
              </h5>
              <h6 className="mt-2">Il Contraente Viaggia:</h6>
              <h5>
                {insuranceRequest.insurance_request_travel.is_contractor_included === 1 ? 'Si' : 'No'}
              </h5>
              <h6 className="mt-2">Status:</h6>
              <h5>
                {statues.find((st) => st.id === quoteStatus).name}
              </h5>
              <h6
                className="mt-2 text-decoration-underline"
                style={{ cursor: 'pointer' }}
                onClick={() => downloadResourceDocs(rc_quotation.quotation_data_travel_europeassistance.product_code)}
              >
                Informazioni Precontrattuali
              </h6>
            </ClientBox>
          </div>
          <div className="col-12 col-xl-8">
            <ClientBox className="pagament-status-step-two">
              <h4>Informazioni Prodotti</h4>
              <hr />
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Prodotto</th>
                    <th scope="col">Compagnia</th>
                    <th scope="col">Frequenza Pagamento</th>
                    <th scope="col">Premio</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{rc_quotation.product.name}</td>
                    <td>
                      {rc_quotation.company_id !== null ? rc_quotation.company.name : rc_quotation.preventivas_company.company_name}
                    </td>
                    <td>{rc_quotation.payment_frequency === 1 ? 'Annuale' : 'Semestrale'}</td>
                    <td>
                      {parseFloat(rc_quotation.amount)}
                      {' '}
                      &euro;
                    </td>
                  </tr>
                  {tutela_quotation !== null && (
                    <tr>
                      <td>Tutela Legale</td>
                      <td>
                        {tutela_quotation.company.name}
                      </td>
                      <td>{tutela_quotation.payment_frequency === 1 ? 'Annuale' : 'Semestrale'}</td>
                      <td>
                        {parseFloat(tutela_quotation.amount)}
                        {' '}
                        &euro;
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot className="bg-secondary">
                  <tr>
                    <td className="fw-bold">
                      Totale
                    </td>
                    <td />
                    <td />
                    <td className="fw-bold">
                      &euro;
                      {' '}
                      {getTotal()}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </ClientBox>
          </div>
        </div>
      </BoxWrapper>
    </>
  );
}

export default InfoBoxTravel;
