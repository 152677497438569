import { FilePreviewStyles } from './FilePreview.styles';

export default function FilePreview({
  title, id, src, removeFile, height, filename,
}) {
  const removeFileHandler = () => {
    removeFile(id);
  };

  return (
    <FilePreviewStyles>
      <div className="d-flex justify-content-between">
        <span className="preview_filename" title={filename}>{filename}</span>
        <span className="text-secondary d-flex justify-content-end close-button" onClick={removeFileHandler}>
          <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20">
            <path d="m249 849-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" />
          </svg>
        </span>
      </div>
      <iframe
        className="file-frame"
        {...{ title, height, src }}
      />
    </FilePreviewStyles>
  );
}
