import { createSlice } from '@reduxjs/toolkit';
import { callerCreator } from '../callerCreator';

const initialState = {
  loading: false,
  error: null,
  transcriptions: null,
};

export const speechSlice = createSlice({
  name: 'speech',
  initialState,
  reducers: {
    speechTranscriptionPending: (state) => {
      state.loading = true;
      state.error = null;
    },
    speechTranscriptionSuccess: (state, { payload }) => {
      state.loading = false;
      state.transcriptions = payload.transcriptions;
    },
    speechTranscriptionRejected: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    speechTranscriptionReset: (state) => {
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.transcriptions = initialState.transcriptions;
    },
  },
});

export default callerCreator(speechSlice.actions);
