import styled from 'styled-components';

export const ResearchTypeSection = styled.section`
  .research-class {
    transition: all 150ms linear;
  }
  .research-class:hover {
    background-color: rgb(25, 135, 84);
    color: white;
  }
`;
