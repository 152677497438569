import React from 'react';
import { FormInputTextarea } from '../../../common/formInputs/FormInputTextarea';
import { positiveOrNegative } from '../../../common/constants';
import { FormInputSelect } from '../../../common/formInputs/FormInputSelect';

function CompanyComentaries({ commertial_report, errors, isValid }) {
  return (
    <div className="tab-pane fade" id="commercial_company_commentaries">
      <h4 className="text-center mt-3">Commercial Company Commentaries</h4>

      <div className="row mt-5">
        {commertial_report.commercial_company_commentaries.map((commentary, index) => (
          <div className="col-12 col-md-6 mb-4" key={index}>
            <div className="card border">
              <div className="card-body px-5">
                <FormInputTextarea
                  placeholder="Commentary Text"
                  label="Commentary Text"
                  value={commentary.commentary_text}
                  error={errors.commentary_text?.message}
                  valid={isValid('commentary_text')}
                />
                <FormInputSelect
                  label="Positive Or Negative"
                  placeholder="-Seleziona-"
                  options={positiveOrNegative}
                  error={errors.positive_or_negative?.message}
                  valid={isValid('positive_or_negative')}
                  value={commentary.positive_or_negative}
                />

              </div>
            </div>
          </div>
        ))}

      </div>
    </div>
  );
}

export default CompanyComentaries;
