import styled from 'styled-components';

export const SaveButton = styled.div`
    padding: 8px 20px;
    width: 100%;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--greenia-color);
    color: var(--greenia-color);
    cursor: pointer;
    transition: .3s;

    &:hover {
       background-color: rgba(100,200,100, .3);
    }
`;

export const SavedButton = styled.div`
    padding: 8px 60px;
    width: 100%;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(100,200,100);
    background-color: rgb(100,200,100);
    color: white;
    cursor: default;
`;
