import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Logout from '../../assets/images/log-out.webp';
import parseJson from '../helpers/parseJSON';
import { HeaderStyles } from './Header.styles';
import StorageService from '../../services/StorageService';

const Storage = new StorageService();

export default function Header() {
  const user = parseJson(localStorage.getItem('user'));

  const handleClick = () => {
    // localStorage.clear();

    Storage.removeBackOfficeToken();
    Storage.removeToken();
    Storage.removeUser();
    Storage.removeRefreshToken();
    Storage.removeSelectedProfession();
    Storage.removeVehicleQuoteAnswers();
    Storage.removeMotoQuoteAnswers();
    Storage.removeVehicleOwnerData();
    Storage.removeVehicleDriverData();
    Storage.removeBersani();
    Storage.removeTranscription();
    Storage.removeTravelData();

    window.location.href = '/';
  };

  return (
    <HeaderStyles>
      <nav className="page-nav">
        <div className="d-flex justify-content-between align-items-center">
          <a className="menu btn-lg d-lg-none" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
            <FontAwesomeIcon icon={faBars} />
          </a>
          <div className="search-box d-lg-flex d-none align-items-center" />

          <div className="d-flex align-items-center justify-content-end">
            <div className="user-info">
              <span className="email">{user.email}</span>
              <br />
              <h6 className="fw-medium m-0">
                {user.name}
                {' '}
                {' '}
                {' '}
                {user.surname}
              </h6>
            </div>
            <span className="logout" onClick={handleClick} style={{ cursor: 'pointer' }}>
              <img src={Logout} height="25" alt="Logout icon" />
            </span>
          </div>
        </div>
      </nav>
    </HeaderStyles>
  );
}
