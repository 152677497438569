import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  AddButton, BackButton, GuaranteesLeftSide, GuaranteesModalBody, GuaranteesModalContent, GuaranteesModalHeader, GuaranteesRightSide, GuaranteesTextContainer, GuaranteesTitleBox, GuarnateesModalInfortuniStyles, InputCircle, InputContainer, InputText,
} from './GuaranteesModalInfortuni.styles';
import { updateInfortuniExtensions } from '../../../../features/backofficeResults/backofficeResultsActions';

function GuaranteesModalInfortuni({
  setOpenModal, extensions, quote, addExtensions,
}) {
  const [modalExtensions, setModalExtensions] = useState([]);

  const closeGuaranteesModal = () => {
    setOpenModal(false);
  };

  const handleExtensions = (value) => {
    if (modalExtensions.includes(value)) {
      const newExts = modalExtensions.filter((ext) => ext !== value);
      setModalExtensions(newExts);
    } else if (!modalExtensions.includes(value)) {
      setModalExtensions([...modalExtensions, value]);
    }
    updateInfortuniExtensions(value);
  };

  const addGuarantees = () => {
    addExtensions(quote.request_id, modalExtensions);
  };

  useEffect(() => {
    const exts = [];
    if (quote.rsm !== null) {
      exts.push('rimborso');
    }

    if (quote.ulc !== null) {
      exts.push('ustioni');
    }
    setModalExtensions(exts);
  }, []);

  return (
    <GuarnateesModalInfortuniStyles>
      <GuaranteesModalBody>
        <GuaranteesModalHeader>
          <GuaranteesLeftSide>
            <FontAwesomeIcon fontSize={34} icon={faTimes} cursor="pointer" onClick={() => closeGuaranteesModal()} />
            <GuaranteesTextContainer>
              <GuaranteesTitleBox>
                <h3>Aggiungi Garanzie</h3>
              </GuaranteesTitleBox>
              <p>
                Seleziona le garanzie che desideri aggiungere al tuo pacchetto assicurativo principale.
              </p>
            </GuaranteesTextContainer>
          </GuaranteesLeftSide>
          <GuaranteesRightSide>
            <BackButton onClick={() => closeGuaranteesModal()}>
              Indietro
            </BackButton>
            <AddButton onClick={() => {
              addGuarantees();
              closeGuaranteesModal();
            }}
            >
              Aggiungi (
              {modalExtensions.length}
              )
            </AddButton>
          </GuaranteesRightSide>
        </GuaranteesModalHeader>
        <GuaranteesModalContent>
          <div className="row">
            <div className="col-12 col-md-12">
              {extensions.map((extension, index) => (
                <InputContainer key={index} value={extension.id} onClick={() => handleExtensions(extension.id)}>
                  <div>
                    <InputCircle className={modalExtensions.includes(extension.id) ? 'selected' : ''} />
                  </div>
                  <InputText>
                    {extension.label}
                  </InputText>
                </InputContainer>
              ))}
            </div>
          </div>
        </GuaranteesModalContent>
      </GuaranteesModalBody>
    </GuarnateesModalInfortuniStyles>
  );
}

export default GuaranteesModalInfortuni;
