import styled from 'styled-components';

export const ArticleCardStyles = styled.div`
  flex: 1;
  max-width: 350px;
  min-width: 195px;
  padding: 15px 0;

  @media (max-width: 700px) {
    max-width: unset;
  }

  img {
    margin: auto;
    height: 100px;
    width: 100%;
    object-fit: contain;
  }

  .latest-news-header {
    display: flex;
    gap: 10px;
  }

  .latest-news-description {
    font-size: 13px;
  }

  .latest-news-title {
    margin-top: 20px;
    font-weight: bold;
  }

  .latest-news-read-more {
    margin: auto;
    margin-top: 10px;
    max-width: 140px;
    padding: 5px 20px;
    text-align: center;
    border: 2px solid grey;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
  }

  .latest-news-read-more:hover {
    background-color: rgba(200, 255, 200, 0.3);
  }

  .h--latest-news {
    flex-direction: column;
  }
`;
