import caller from './customersSlice';
import axe from '../../services/axios-client/axiosClient';
import managementAxe from '../../services/axios-client/managementServisAxeClient';

export const getCustomerInformationAction = async (customerId) => {
  try {
    const result = await axe.get(`/customers/${customerId}`);

    caller.getCustomerInformationSuccess({
      ...result.data.data,
    });
  } catch (err) {
    caller.getCustomerInformationRejected('An error has occured');
  }
};

export const resetCustomerPage = () => {
  caller.customerPageReset();
};

export const getCustomerAction = async (customerId) => {
  try {
    const result = await managementAxe.get(`/broker/customers/customers-information/${customerId}`);

    if (result.status !== 200) {
      caller.getCustomerRejected('Errore Interno');
      return;
    }

    caller.getCustomerSuccess({
      ...result.data.data,
    });
  } catch (err) {
    caller.getCustomerRejected('An error has occured');
  }
};
