import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NetworkAnalisysStyles } from './NetworkAnalisys.styles';
import { getNetworkAnalisys, resetNetworkAnalisys } from '../../features/networkAnalisys/networkAnalisysActions';
import PageLoading from '../../common/components/elements/PageLoading';
import { generalAlertError } from '../../common/utils/alerts';

function NetworkAnalisys() {
  const [search, setSearch] = useState('');
  const { network_analisys, loading } = useSelector((store) => store.analisys.network_analisys);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (search === '') {
      generalAlertError('Numero iscrizione non valida');
      return;
    }
    getNetworkAnalisys(search);
  };

  useEffect(() => () => {
    resetNetworkAnalisys();
  }, []);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <NetworkAnalisysStyles>
      <h5 className="mb-4">Analisi Rete</h5>
      <form
        onSubmit={handleSubmit}
        className="w-100 d-flex align-items-center justify-content-start flex-column flex-md-row"
      >
        <input
          type="text"
          name="search"
          onChange={handleSearch}
          value={search}
          className="search-input form-control mb-2 mb-md-0 me-2"
          placeholder="Ricerca con numero iscrizione..."
        />
        <button type="submit" className="search-btn w-25">
          Cerca
        </button>
        <div className="w-100 d-none d-md-block" />
      </form>
      <p className="fw-bold fst-italic mt-2">Inserisci il numero di iscrizione intermediario Sezione A o B! </p>

      <div className="mt-5">
        <h5 className="h5 mb-2">
          Totale Associati:
          <span className="badge bg-success">{network_analisys.length}</span>
        </h5>
        <hr />
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Numero Iscrizione</th>
                <th scope="col">Cognome Nome</th>
                <th scope="col">Stato</th>
                <th scope="col">Data Di Nascita</th>
                <th scope="col">Commune Di Nascita</th>
                <th scope="col">Ragione Sociale</th>
                <th scope="col">Data Iscrizione</th>
              </tr>
            </thead>
            <tbody>
              {network_analisys.map((item, index) => (
                <tr key={index}>
                  <td>{item.NUMERO_ISCRIZIONE_RUI}</td>
                  <td>{item.COGNOME_NOME}</td>
                  <td>{item.STATO}</td>
                  <td>{item.DATA_NASCITA}</td>
                  <td>
                    {item.COMUNE_NASCITA !== ''
                      && (
                      <>
                        {item.COMUNE_NASCITA}
                        ,
                        {' '}
                        {item.PROVINCIA_NASCITA}
                      </>
                      )}
                  </td>
                  <td>
                    {item.RAGIONE_SOCIALE}
                  </td>
                  <td>
                    {item.DATA_ISCRIZIONE}
                  </td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>
      </div>
    </NetworkAnalisysStyles>
  );
}

export default NetworkAnalisys;
