import caller from './dashboardSlice';
import axe from '../../services/axios-client/axiosClient';
import { generalAlertError } from '../../common/utils/alerts';
import managementAxe from '../../services/axios-client/managementServisAxeClient';

export const getDashboardData = async () => {
  try {
    const response = await managementAxe.get('/broker/dashboard/home');
    caller.getDashboardSuccess(response.data.data);
  } catch (err) {
    caller.getDashboardRejected('An error has occured');
  }
};

export const getAgendsByWeek = async (start, end) => {
  try {
    caller.getAgendsByWeekPending();

    const response = await managementAxe.get('/broker/dashboard/agends-by-week', {
      params: { start, end },
    });

    caller.getAgendsByWeekSuccess(response.data.data);
  } catch (err) {
    caller.getAgendsByWeekSuccess([]);
    generalAlertError('Error getting agendas');
  }
};

export const changeAgendsView = (view) => caller.changeAgendsView(view);

export const changeNavigationDate = (date) => caller.changeNavigationDate(date);

export const resetGetDashboardData = () => {
  caller.getDashboardReset();
};

export const searchAction = async (search) => {
  if (search.length === 0) return;

  const { data: { data: customers } } = await axe.get('/customers/search/by-name', {
    params: { search },
  });

  caller.listCustomersSuccess(customers);
};

export const emptySearchResults = () => {
  caller.setSearchResults([]);
};

export const getLatestUpdatesByPage = async (page) => {
  try {
    const result = await managementAxe.get('/broker/dashboard/latest-updates', {
      params: { page },
    });

    const { pages, latest_updates } = result.data.data;

    caller.getLatestUpdatesSuccess({
      page,
      pages,
      latest_updates,
    });
  } catch (err) {
    caller.getLatestUpdatesRejected('An error has occured');
  }
};

export const resetLatestUpdates = () => {
  caller.getLatestUpdatesReset();
};
