import React from 'react';
import RiskCardSelected from '../Cards/RiskCardSelected';

function TutelaSelectedAuto({ tutelaLegaleQuote, type }) {
  return (
    <>
      {tutelaLegaleQuote !== null && (
        <div className="col-12 mb-2">
          <RiskCardSelected quote={tutelaLegaleQuote} type={type} />
        </div>
      )}
      {tutelaLegaleQuote === null && <div data-bs-toggle="modal" data-bs-target={`#${type.id}`} style={{ cursor: 'pointer' }} className="alert alert-secondary">Seleziona Quotazione!</div>}
    </>
  );
}

export default TutelaSelectedAuto;
