import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
// import Joyride from 'react-joyride';
import { useSelector } from 'react-redux';
import PageLoading from '../common/components/elements/PageLoading';
import Toast from '../common/components/elements/Toast';
import {
  getAllThreads,
  listAllQuotationsForMessages,
  searchForThreads,
} from '../features/threads/threadsActions';
import { resetNotifications } from '../features/utilities/utilitiesActions';
import { MessageStyles, Tab, Tabs } from './Message.styles';
import SupportModal from '../common/components/elements/SupportModal';
import { listTickets, searchForTickets } from '../features/tickets/ticketsActions';
import TicketModal from '../common/components/elements/TicketModal';
import Pagination from '../common/components/elements/Pagination';
// import { useTourContext } from '../context/useTourContext';
import ErrorComponent from '../common/components/ErrorComponent';
// import { joyrideOptions } from '../common/utils/joyrideUtils';
// import { handleCallbackMessage } from '../common/utils/joyrideCallbacks';
// import { messageSteps } from '../common/utils/joyrideSteps';

function Message() {
  const location = useLocation();

  const {
    threads_list: {
      threads, quotations, loading, isSearching, total_pages, page_number, error: threadsError,
    },
  } = useSelector((store) => store.threads);

  const {
    tickets_list: {
      tickets, loading: ticketsLoading, isSearching: ticketsIsSearching, total_pages: ticketTotalPages, page_number: ticketPageNumber, error: ticketsError,
    },
  } = useSelector((store) => store.tickets);

  const [message, setMessage] = useState(null);
  const [search, setSearch] = useState('');
  const [searchTickets, setSearchTickets] = useState('');
  const [activeTab, setActiveTab] = useState('quotations');
  // const [steps, setSteps] = useState([]);

  const handleChange = ({ target: { value } }) => {
    setSearch(value);
  };

  const handleTicketsChange = ({ target: { value } }) => {
    setSearchTickets(value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    searchForThreads(1, search);
  };

  const handleSearchTickets = (e) => {
    e.preventDefault();
    searchForTickets(1, searchTickets);
  };

  const collectData = async () => {
    listAllQuotationsForMessages();
    getAllThreads(page_number);
    listTickets(ticketPageNumber);
  };

  useEffect(() => {
    if (location.state !== null) {
      setMessage({
        statusCode: location.state.statusCode,
        message: location.state.message,
      });
    }
  }, []);

  useEffect(() => {
    collectData();

    return () => {
      resetNotifications();
    };
  }, []);

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (activeTab === 'quotations') {
  //     if (localStorage.getItem('messages') === 'done') {
  //       setState({ run: false });
  //     } else {
  //       setState({ run: true });
  //     }
  //   }
  //   if (activeTab === 'tickets') {
  //     if (localStorage.getItem('tickets') === 'done') {
  //       setState({ run: false });
  //     } else {
  //       setState({ run: true });
  //     }
  //   }
  // }, [localStorage.getItem('messages'), localStorage.getItem('tickets')]);

  // const fireNewQuoteTour = () => {
  //   if (activeTab === 'quotations') {
  //     localStorage.removeItem('messages', 'done');
  //     setState({ run: true, tourActive: true });
  //   }
  //   if (activeTab === 'tickets') {
  //     localStorage.removeItem('tickets', 'done');
  //     setState({ run: true, tourActive: true });
  //   }
  // };

  // useEffect(() => {
  //   messageSteps(activeTab, threads, tickets, setSteps);
  // }, [activeTab, threads, tickets]);

  const [supportMessage, setSupportMessage] = useState({
    subject: '',
    request_id: '',
    body: '',
    recipient: '',
  });

  if (loading || ticketsLoading) {
    return <PageLoading />;
  }

  return (
    <MessageStyles>
      {/* <Joyride
        steps={steps}
        continuous
        showBeacon={showBeacon}
        locale={{
          back: 'Indietro',
          close: 'Chiudi',
          last: 'Fine',
          next: 'Prossima',
          open: 'Apri il modale',
          restart: 'Riavvia il tour ',
          skip: 'Salta',
        }}
        styles={{
          options: joyrideOptions,

          buttonNext: {
            backgroundColor: '#00a651',
            outline: 'none',
          },
          buttonBack: {
            color: '#00a651',
            outline: 'none',
          },
          beaconInner: {
            backgroundColor: '#00a651',
          },
          beaconOuter: {
            borderColor: '#00a651',
            backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
          },
          buttonSkip: {
            outline: 'none',
          },
          buttonClose: {
            outline: 'none',
          },
        }}
        run={run}
        callback={(data) => { handleCallbackMessage(data, activeTab, steps, setState); }}
        showSkipButton
        showProgress
      />

      {activeTab === 'quotations'
        && (
          <div className="d-flex justify-content-end align-items-end">
            {localStorage.getItem('messages') === 'done' && (
              <div style={{ cursor: 'pointer' }} onClick={() => fireNewQuoteTour()}>
                <button className="btn btn-success btn-sm text-white px-3">Tutorial</button>
              </div>
            )}
          </div>
        )}

      {activeTab === 'tickets'
        && (
          <div className="d-flex justify-content-end align-items-end">
            {localStorage.getItem('tickets') === 'done' && (
              <div style={{ cursor: 'pointer' }} onClick={() => fireNewQuoteTour()}>
                <button className="btn btn-success btn-sm text-white px-3">Tutorial</button>
              </div>
            )}
          </div>
        )} */}
      {message && <Toast message={message} />}

      <Tabs>
        <Tab active={activeTab === 'quotations'} onClick={() => setActiveTab('quotations')}>Supporto Quotazioni</Tab>
        <Tab active={activeTab === 'tickets'} onClick={() => setActiveTab('tickets')}>Supporto Generale</Tab>
      </Tabs>

      {activeTab === 'quotations'
        && (
          threadsError !== null ? <ErrorComponent error={threadsError} />
            : (
              <>
                <form
                  onSubmit={handleSearch}
                  className="w-100 flex-column flex-md-row d-flex align-items-center justify-content-start messages-one"
                >
                  <input
                    type="text"
                    name="search"
                    className="search-input form-control mb-2 mb-md-0 me-2"
                    value={search}
                    placeholder="Ricerca discussioni..."
                    onChange={handleChange}
                  />
                  <button type="submit" className="search-btn w-25">
                    Cerca
                  </button>
                  <div className="w-100 d-none d-md-block" />
                </form>

                {quotations.length !== 0
                  ? (
                    <div className="d-flex align-items-center mt-3 ">
                      <button
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#support-message-box"
                        className="btn btn-outline-primary messages-two"
                      >
                        Apri Nuova Conversazione
                      </button>
                    </div>
                  )
                  : (
                    <p className="alert alert-info d-flex justify-content-center my-2">
                      Tutte le quotazioni hanno un caso apperto!
                    </p>
                  )}

                <SupportModal
                  collectData={collectData}
                  setSupportMessage={setSupportMessage}
                  supportMessage={supportMessage}
                  backofficeUsers={[]}
                  quotations={quotations}
                />

                <section className="messages-list mt-3">
                  <ul className="list-group mb-3">
                    {isSearching ? (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <div className="spinner-border" role="status" />
                        <span>Attendere prego...</span>
                      </div>
                    ) : threads.length > 0 ? (
                      threads.map((thread, index) => (
                        <li
                          key={index}
                          className="list-group-item p-3 d-flex justify-content-between align-items-center messages-three"
                        >
                          <div>
                            {thread.unReadMessages > 0 && (
                              <span className="badge bg-danger">
                                {thread.unReadMessages}
                                {' '}
                                {thread.unReadMessages === 1
                                  ? 'Nuovo Messagio'
                                  : 'Nuovi Messagi'}
                              </span>
                            )}
                            <div className="fw-bold">
                              Oggetto:
                              {' '}
                              {thread.subject}
                            </div>
                            Creato da:
                            {' '}
                            {thread.broker_agent_id !== null
                              ? `${thread.broker_agent?.name
                              } ${thread.broker_agent?.surname}`
                              : ''}
                            {' '}
                            {thread.user_id !== null ? thread.user?.name : ''}
                            <br />
                            Prodotto:
                            {' '}
                            {thread.insurance_request?.product?.name}
                            <br />
                            Cliente:
                            {' '}
                            {thread.insurance_request?.customer?.name}
                            {' '}
                            {thread.insurance_request?.customer?.surname}
                            <br />
                            <small>
                              <strong>
                                Creato:
                                {' '}
                                {new Date(thread.created_at).toLocaleDateString('it-IT')}
                              </strong>
                            </small>
                          </div>

                          <Link to={`/messages/${thread.id}`}>
                            <span className="badge btn-to-quote px-4 py-2 rounded-3 messages-four">
                              Apri Messagio
                            </span>
                          </Link>
                        </li>
                      ))
                    ) : (
                      <li className="list-group-item d-flex justify-content-between align-items-start messages-not-found">
                        <div className="ms-2 me-auto">
                          <div className="fw-bold">Nessuna discussione trovata!</div>
                        </div>
                      </li>
                    )}
                  </ul>
                  <Pagination total_pages={total_pages} current_page={page_number} onClick={searchForThreads} search={search} />
                </section>

              </>
            )
        )}

      {activeTab === 'tickets'
        && (
          ticketsError !== null ? <ErrorComponent error={ticketsError} />
            : (
              <>
                <form
                  onSubmit={handleSearchTickets}
                  className="w-100 d-flex flex-column flex-md-row align-items-center justify-content-start ticket-one"
                >
                  <input
                    type="text"
                    name="search"
                    className="search-input form-control mb-2 mb-md-0 me-2"
                    value={searchTickets}
                    placeholder="Ricerca discussioni..."
                    onChange={handleTicketsChange}
                  />
                  <button type="submit" className="search-btn w-25">
                    Cerca
                  </button>
                  <div className="w-100 d-none d-md-block" />
                </form>

                <div className="d-flex align-items-center mt-3">
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#support-message-box"
                    className="btn btn-outline-primary ticket-two"
                  >
                    Apri Nuova Conversazione
                  </button>
                </div>

                <TicketModal
                  getTickets={listTickets}
                />

                <section className="messages-list mt-3">
                  <ul className="list-group mb-3">
                    {ticketsIsSearching ? (
                      <div className="d-flex justify-content-center align-items-center flex-column">
                        <div className="spinner-border" role="status" />
                        <span>Attendere prego...</span>
                      </div>
                    ) : tickets.length > 0 ? (
                      tickets.map((ticket, index) => (
                        <li
                          key={index}
                          className="list-group-item px-3 py-3 d-flex justify-content-between align-items-center ticket-three"
                        >
                          <div>
                            {ticket.unReadMessages > 0 && (
                              <span className="badge bg-danger">
                                {ticket.unReadMessages}
                                {' '}
                                {ticket.unReadMessages === 1
                                  ? 'Nuovo Messagio'
                                  : 'Nuovi Messagi'}
                              </span>
                            )}
                            <div className="fw-bold mb-2">
                              Oggetto:
                              {' '}
                              {ticket.subject}
                            </div>

                            <small>
                              <strong>
                                Creato:
                                {' '}
                                {new Date(ticket.created_at).toLocaleDateString('it-IT')}
                              </strong>
                            </small>
                          </div>

                          <Link to={`/tickets/${ticket.id}`}>
                            <span className="badge btn-to-quote px-4 py-2 rounded-3 ticket-four">
                              Apri Ticket
                            </span>
                          </Link>
                        </li>
                      ))
                    ) : (
                      <li className="list-group-item d-flex justify-content-between align-items-start ticket-not-found">
                        <div className="ms-2 me-auto">
                          <div className="fw-bold">Nessuna discussione trovata!</div>
                        </div>
                      </li>
                    )}
                  </ul>
                  <Pagination total_pages={ticketTotalPages} current_page={ticketPageNumber} search={searchTickets} onClick={listTickets} />
                </section>
              </>
            )
        )}
    </MessageStyles>
  );
}

export default Message;
