import { generalAlertSuccess } from '../../common/utils/alerts';
import quotationsAxe from '../../services/axios-client/quotationsAxeClient';
import caller from './commercialReportSlice';

const serverError = 'Si è verificato un problema con il server';

export const getCommertialReport = async (vat_number) => {
  caller.getCommertialReportPending();

  try {
    const response = await quotationsAxe.get(
      `/commercial/report/${vat_number}/complete`,
    );

    if (response.status !== 200) {
      caller.getCommertialReportRejected(serverError);
      return;
    }
    caller.getCommertialReportSuccess(response.data.data);
  } catch (error) {
    caller.getCommertialReportRejected(serverError);
  }
};

export const commertialReportReset = () => {
  caller.commertialReportReset();
};

export const saveQuestionario = async (company_id, data, navigate) => {
  caller.getQuestionarioPending();

  try {
    const response = await quotationsAxe.post(`/commercial/questionario/${company_id}`, data);

    if (response.status !== 200) {
      caller.getQuestionarioRejected(serverError);
      return;
    }
    generalAlertSuccess('Success');
    caller.getQuestionarioSuccess();
    navigate('/quotes/commercial/11');
  } catch (error) {
    caller.getCommertialReportRejected(serverError);
  }
};

export const skipQuestionario = async (company_id, hasQuestionario, fetchData) => {
  caller.getCompanyQuestionarioStatusPending();

  try {
    const data = { has_questionario: hasQuestionario };
    const response = await quotationsAxe.post(`/commercial/questionario/${company_id}/status`, data);

    if (response.status !== 200) {
      caller.getCompanyQuestionarioStatusRejected(serverError);
      return;
    }
    fetchData(company_id);
    // navigate('/quotes/commercial/11');
  } catch (error) {
    caller.getCommertialReportRejected(serverError);
  }
};

export const getQuestionarioStatus = async (companyId) => {
  caller.getCompanyQuestionarioStatusPending();
  try {
    const response = await quotationsAxe.get(`/commercial/questionario/${companyId}/get-questionario-status`);

    if (response.status !== 200) {
      caller.getCompanyQuestionarioStatusRejected('Errore Interno');
      return;
    }

    caller.getCompanyQuestionarioStatusSuccess(response.data.data);
  } catch (error) {
    caller.getCompanyQuestionarioStatusRejected('Errore interno');
  }
};
