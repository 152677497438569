import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
// import Joyride, { ACTIONS } from 'react-joyride';
import {
  BadgeOptional, OptionalDocumentBox, PurchaseStyles, RedirectToPrimaAlert, UploadDocumentsContainer, UploadWrapper,
} from './Purchase.styles';
import {
  deleteDocument,
  apiDownloadDocument,
  apiDeleteOptionalDocument,
  apiDownloadOptionalDocument,
} from '../../services/axios-client/axeCommons';
import PageLoading from '../../common/components/elements/PageLoading';
import CompletedQuoteScreen from '../../common/components/elements/CompletedQuoteScreen';
import {
  confirmAlert, generalAlertError, generalAlertSuccess,
} from '../../common/utils/alerts';
import {
  autocarroGetBackofficeUsers, continueWithSignatureAutocarro, purchaseInsuranceRequest, resetAutocarroPurchase,
  skipSignatureAutocarro,
} from '../../features/purchases/purchasesActions';
import OptionalDocumentModal from '../../common/components/elements/OptionalDocumentModal';
import SignatureType from '../../common/components/redesignComponents/Purchase/SignatureType';
import uploadIcon from '../../assets/redesign_icons/upload-icon.svg';
import InfoBoxAuto from '../../common/components/redesignComponents/Purchase/common/InfoBoxAuto';
import WaitingForPaymentStatus from '../../common/components/redesignComponents/Purchase/WaitingForPaymentStatus';
import { SaveBox } from './common/SaveBox';
import { NumberCurrent, SkipSignedDocs } from '../../common/components/redesignComponents/Purchase/SignatureType.styles';
import NonSignableDocumentComponent from './common/NonSignableDocumentComponent';
import NonSIgnableDocumentRequestComponent from './common/NonSIgnableDocumentRequestComponent';
// import { useTourContext } from '../../context/useTourContext';

function PurchaseAutocarroRequest() {
  const { requestToken } = useParams();
  const navigate = useNavigate();
  // const [steps, setSteps] = useState();
  // const [showBeacon, setShowBeacon] = useState();

  const state = useSelector((store) => store.purchases.autocarro);
  const {
    loading, quote, insuranceRequest, quoteStatus,
    backofficeUsers, rc_quotation, tutela_quotation, assistenza_quotation,
    cristalli_quotation, infortuni_conducente_quotation, rivalsa_quotation, nonSignableDocs, requestedOptionalDocumentsNonSignable, signableDocs, requestedOptionalDocumentsSignable,
  } = state;

  const [showOptionalDocumentModal, setShowOptionalDocumentModal] = useState(false);

  const [supportMessage, setSupportMessage] = useState({
    subject: '',
    request_id: '',
    body: '',
    recipient: '',
  });

  const statues = [
    {
      id: 1,
      name: 'Attesa Documenti',
    },
    {
      id: 2,
      name: 'Problemi Con i Documenti',
    },
    {
      id: 3,
      name: 'Attesa Firma Documenti',
    },
    {
      id: 4,
      name: 'Attesa Pagamento',
    },
    {
      id: 5,
      name: 'Attesa Smarcatura',
    },
    {
      id: 6,
      name: 'In Attesa Emissione',
    },
    {
      id: 7,
      name: 'Polizza Emessa',
    },
  ];

  const collectQuoteData = async () => {
    // autocarroCollectData(quotationId, setSupportMessage);
    purchaseInsuranceRequest(requestToken, 'autocarro', setSupportMessage);
  };

  const downloadDocument = async (documentId) => {
    const response = await apiDownloadDocument(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const downloadOptionalDocument = async (documentId) => {
    const response = await apiDownloadOptionalDocument(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const openOptionalDocumentModal = () => {
    setShowOptionalDocumentModal(true);
  };

  const removeOptionalDocument = async (documentId) => {
    try {
      const response = await apiDeleteOptionalDocument(documentId);

      if (response.status === 200) {
        collectQuoteData();
      }
    } catch (err) {
      // console.error(err);
    }
  };

  const continueWithoutSignDocs = () => {
    confirmAlert('Sei sicuro di voler firmare più tardi?', () => skipSignatureAutocarro(rc_quotation.id, navigate));
  };

  const continueWithSignDocs = () => {
    confirmAlert('Sei sicuro di voler firmare adesso?', () => continueWithSignatureAutocarro(rc_quotation.id, collectQuoteData));
  };

  useEffect(() => {
    collectQuoteData();
    autocarroGetBackofficeUsers();

    return () => {
      resetAutocarroPurchase();
    };
  }, []);

  // const {
  //   setState,
  //   state: { run },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('purchaseTour') === 'done') {
  //     setState({ run: false });
  //   } else {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 1500);
  //   }
  // }, [localStorage.getItem('purchaseTour')]);

  // const handleCallback = (data) => {
  //   const {
  //     action,
  //     index,
  //     status,
  //     // step: {
  //     //   data: { next, previous },
  //     // },
  //     type,
  //   } = data;
  //   // const isPreviousAction = action === 'prev';

  //   if (type === 'step:after') {
  //     if (index === 3) {
  //       if (action === ACTIONS.NEXT) {
  //         localStorage.setItem('purchaseTour', 'done');
  //       }
  //     }

  //     if (action === ACTIONS.CLOSE) {
  //       localStorage.setItem('purchaseTour', 'done');
  //       setState({ run: false });
  //       setShowBeacon(false);
  //     }
  //   }

  //   if (status === 'skipped') {
  //     if (action === ACTIONS.SKIP) {
  //       localStorage.setItem('purchaseTour', 'done');
  //     }
  //   }
  // };

  // const firePurchaseTour = () => {
  //   localStorage.removeItem('purchaseTour');
  //   setState({ run: true });
  // };

  // useEffect(() => {
  //   if (quote !== null) {
  //     if (quote.status === 1 || quote.status === 2) {
  //       setSteps(
  //         [
  //           {
  //             target: '.documents-status-step-one',
  //             title: <h3 className="h5">Informazioni Quotazione e Cliente</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione troverete le informazioni per
  //                 {' '}
  //                 <b>cliente</b>
  //                 ,
  //                 {' '}
  //                 <b>veicolo</b>
  //                 ,
  //                 {' '}
  //                 <b>targa</b>
  //                 ,
  //                 {' '}
  //                 <b>prodotto</b>
  //                 e
  //                 {' '}
  //                 <b>stato</b>
  //                 .
  //               </p>
  //             ),
  //             disableBeacon: true,
  //             placement: 'right',
  //           },
  //           {
  //             target: '.documents-status-step-two',
  //             title: <h3 className="h5">Informazioni Compagnia</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione troverete le informazioni sulla compagnia,
  //                 {' '}
  //                 <b>la data decorrenza de la polizza, lo stato del preventivo, le informazioni precontrattuali e il questionario.</b>
  //               </p>
  //             ),
  //             placement: 'bottom',
  //           },
  //           {
  //             target: '.documents-status-step-three',
  //             title: <h3 className="h5">Premio e Supporto</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione troverete il prezzo di quotazione e l'icona di
  //                 {' '}
  //                 <b>supporto</b>
  //                 . Se cliccate sull'icona potete iniziare una conversazione con
  //                 {' '}
  //                 <b>gli agenti del Backoffice di Greenia.</b>
  //               </p>
  //             ),
  //             placement: 'left',
  //           },
  //           {
  //             target: '.documents-status-step-four',
  //             title: <h3 className="h5">Documenti Del Cliente</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione devi caricare i documenti obbligatori per il cliente come la
  //                 {' '}
  //                 <b>carta d'identità</b>
  //                 {' '}
  //                 <b>o altri documenti richiesti dal backoffice. </b>
  //                 <hr />
  //                 <b>
  //                   Se sono presenti documenti non validi, è necessario rimuovere il documento caricato cliccando sull'icona X e caricare il file.
  //                 </b>
  //               </p>
  //             ),
  //           },
  //           {
  //             target: '.documents-status-step-five',
  //             title: <h3 className="h5">Vuoi Firmare Dopo</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione puoi scegliere se vuoi
  //                 {' '}
  //                 <b>scaricare e firmare i documenti richiesti ora o dopo che il preventivo è completo. </b>
  //                 Cliccando
  //                 {' '}
  //                 <b>Sì</b>
  //                 {' '}
  //                 il preventivo verrà spostato nel backoffice per controllare i documenti. Se clicchi
  //                 {' '}
  //                 <b>No</b>
  //                 {' '}
  //                 devi caricare il documenti firmati e poi verrà spostato nel backoffice per controllare i documenti.
  //               </p>
  //             ),
  //           },
  //         ],
  //       );
  //     }

  //     if (quote.status === 4) {
  //       setSteps(
  //         [
  //           {
  //             target: '.pagament-status-step-one',
  //             title: <h3 className="h5">Informazioni Quotazione e Cliente</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione troverete le informazioni per
  //                 {' '}
  //                 <b>cliente</b>
  //                 ,
  //                 {' '}
  //                 <b>veicolo</b>
  //                 ,
  //                 {' '}
  //                 <b>targa</b>
  //                 ,
  //                 {' '}
  //                 <b>prodotto</b>
  //                 e
  //                 {' '}
  //                 <b>stato</b>
  //                 .
  //               </p>
  //             ),
  //             disableBeacon: true,
  //             placement: 'right',
  //           },
  //           {
  //             target: '.pagament-status-step-two',
  //             title: <h3 className="h5">Informazioni Compagnia</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione troverete le informazioni sulla compagnia,
  //                 {' '}
  //                 <b>la data decorrenza de la polizza, lo stato del preventivo, le informazioni precontrattuali e il questionario.</b>
  //               </p>
  //             ),
  //             placement: 'bottom',
  //           },
  //           {
  //             target: '.pagament-status-step-three',
  //             title: <h3 className="h5">Premio e Supporto</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione troverete il prezzo di quotazione e l'icona di
  //                 {' '}
  //                 <b>supporto</b>
  //                 . Se cliccate sull'icona potete iniziare una conversazione con
  //                 {' '}
  //                 <b>gli agenti del Backoffice di Greenia.</b>
  //               </p>
  //             ),
  //             placement: 'left',
  //           },
  //           {
  //             target: '.pagament-status-step-four',
  //             title: <h3 className="h5">Documenti Del Cliente</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione devi caricare i documenti obbligatori per il cliente come la
  //                 {' '}
  //                 <b>carta d'identità</b>
  //                 {' '}
  //                 <b>o altri documenti richiesti dal backoffice. </b>
  //                 <hr />
  //                 <b>
  //                   Se sono presenti documenti non validi, è necessario rimuovere il documento caricato cliccando sull'icona X e caricare il file.
  //                 </b>
  //               </p>
  //             ),
  //             placement: 'top',
  //           },
  //           {
  //             target: '.pagament-status-step-five',
  //             title: <h3 className="h5">Vuoi Firmare Dopo</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione puoi scegliere se vuoi
  //                 {' '}
  //                 <b>scaricare e firmare i documenti richiesti ora o dopo che il preventivo è completo. </b>
  //                 Cliccando
  //                 {' '}
  //                 <b>Sì</b>
  //                 {' '}
  //                 il preventivo verrà spostato nel backoffice per controllare i documenti. Se clicchi
  //                 {' '}
  //                 <b>No</b>
  //                 {' '}
  //                 devi caricare il documenti firmati e poi verrà spostato nel backoffice per controllare i documenti.
  //               </p>
  //             ),
  //             placement: 'top',
  //           },
  //           {
  //             target: '.pagament-status-step-six',
  //             title: <h3 className="h5">Seleziona il prodotto</h3>,
  //             content: (
  //               <p className="m-0">
  //                 Seleziona uno dei nostri prodotti tra
  //                 {' '}
  //                 <b>Auto, Autocarro, Moto, Professione, Viaggi.</b>
  //               </p>
  //             ),
  //             placement: 'top',

  //           },
  //           {
  //             target: '.pagament-status-step-seven',
  //             title: <h3 className="h5">Seleziona il prodotto</h3>,
  //             content: (
  //               <p className="m-0">
  //                 Seleziona uno dei nostri prodotti tra
  //                 {' '}
  //                 <b>Auto, Autocarro, Moto, Professione, Viaggi.</b>
  //               </p>
  //             ),
  //             placement: 'top',

  //           },
  //           {
  //             target: '.pagament-status-step-eight',
  //             title: <h3 className="h5">Seleziona il prodotto</h3>,
  //             content: (
  //               <p className="m-0">
  //                 Seleziona uno dei nostri prodotti tra
  //                 {' '}
  //                 <b>Auto, Autocarro, Moto, Professione, Viaggi.</b>
  //               </p>
  //             ),
  //             placement: 'top',

  //           },

  //         ],
  //       );
  //     }

  //     if (quote.status === 7) {
  //       setSteps(
  //         [
  //           {
  //             target: '.polizze-emessa-status-step-one',
  //             title: <h3 className="h5">Anagrafica</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione puoi trovare l'anagrafica del cliente
  //               </p>
  //             ),

  //             disableBeacon: true,
  //             placement: 'bottom',
  //           },
  //           {
  //             target: '.polizze-emessa-status-step-two',
  //             title: <h3 className="h5">Dati Veicolo</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione puoi trovare i dati del veicolo
  //               </p>
  //             ),
  //             placement: 'top',

  //           },
  //           {
  //             target: '.polizze-emessa-status-step-three',
  //             title: <h3 className="h5">Dati Polizza</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione puoi trovare i dati della polizza.
  //               </p>
  //             ),
  //             placement: 'bottom',

  //           },
  //           {
  //             target: '.polizze-emessa-status-step-four',
  //             title: <h3 className="h5">Documenti Della Polizza</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione puoi trovare i documenti del preventivo, puoi scaricarli e se hai documenti non firmati puoi caricare quelli firmati.
  //               </p>
  //             ),
  //             placement: 'top',
  //           },
  //           {
  //             target: '.polizze-emessa-status-step-five',
  //             title: <h3 className="h5">Documenti Della Polizza</h3>,
  //             content: (
  //               <p className="m-0">
  //                 In questa sezione puoi trovare i documenti del preventivo, puoi scaricarli e se hai documenti non firmati puoi caricare quelli firmati.
  //               </p>
  //             ),
  //             placement: 'top',
  //           },
  //         ],
  //       );
  //     }
  //   }
  // }, [quote]);

  // const messages = {
  //   it: {
  //     back: 'Indietro',
  //     close: 'Chiudi',
  //     last: 'Fine',
  //     next: 'Prossima',
  //     open: 'Apri il modale',
  //     restart: 'Riavvia il tour ',
  //     skip: 'Salta',
  //   },
  //   en: {
  //     back: 'Back',
  //     close: 'Close',
  //     last: 'Last',
  //     next: 'Next',
  //     open: 'Open the dialog',
  //     restart: 'Restart the tour',
  //     skip: 'Skip',
  //   },
  // };

  // const options = {
  //   arrowColor: '#fff',
  //   backgroundColor: '#fff',
  //   beaconSize: 36,
  //   overlayColor: 'rgba(0, 0, 0, 0.5)',
  //   spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
  //   textColor: '#333',
  //   zIndex: 99999,
  // };

  useEffect(() => {
    if (loading === false && rc_quotation.prima_expired === true) {
      navigate('/quote/autocarro', {
        state: { requestToken: insuranceRequest.request_token },
      });
      generalAlertError('È necessario compilare nuovamente il preventivo con Modifica Dati', 'Link e scaduto!');
    } else if (loading === false && rc_quotation.prima_link !== undefined && rc_quotation.prima_link !== null) {
      window.open(`/redirect?url=${encodeURIComponent(rc_quotation.prima_link)}`);
    }

    if (loading === false && rc_quotation.adriatic_expired === true) {
      navigate('/quote/autocarro', {
        state: { requestToken: insuranceRequest.request_token },
      });
      generalAlertError('È necessario compilare nuovamente il preventivo con Modifica Dati', 'Link e scaduto!');
    }
  }, [loading, rc_quotation]);

  useEffect(() => {
    if (insuranceRequest && insuranceRequest.redirect_user === true) {
      navigate('/');
      generalAlertSuccess('La pratica è completa, attendi nostro feedback!');
    }
  }, [insuranceRequest]);

  const removeDocument = async (documentId) => {
    try {
      const response = await deleteDocument(documentId);

      if (response.status === 200) {
        collectQuoteData();
      }
    } catch (err) {
      // console.error(err);
    }
  };

  const navigateBack = () => {
    navigate(`/quotes/${insuranceRequest.request_token}`);
  };

  if (loading) {
    return <PageLoading />;
  }

  const documentRequestsThatNeedSignature = [
    ...insuranceRequest.document_requests.filter((dr) => dr.document !== null && dr.document.signable_document === 1),
    ...quote.document_requests.filter((dr) => dr.document !== null && dr.document.signable_document === 1),
  ];

  return (
    <PurchaseStyles>
      {/* <Joyride
        callback={handleCallback}
        continuous
        run={run}
        steps={steps}
        showBeacon={showBeacon}
        styles={{
          options,
          buttonNext: {
            backgroundColor: '#00a651',
            outline: 'none',
          },
          buttonBack: {
            color: '#00a651',
            outline: 'none',
          },
          beaconInner: {
            backgroundColor: '#00a651',
          },
          beaconOuter: {
            borderColor: '#00a651',
            backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
          },
          buttonSkip: {
            outline: 'none',
          },
          buttonClose: {
            outline: 'none',
          },
        }}
        showProgress
        locale={messages.it}
        showSkipButton
      /> */}
      {(quoteStatus !== 7)
        && (
          <>
            <div className="container mt-0">
              <div className="d-flex mb-3 justify-content-start align-items-center gap-2">
                <div id="back-button" onClick={() => navigateBack()}>
                  <FontAwesomeIcon icon={faArrowLeftLong} />
                </div>
                {/* <div>
                  {localStorage.getItem('purchaseTour') === 'done' && (
                    <div style={{ cursor: 'pointer' }} onClick={() => firePurchaseTour()}>
                      <button className="btn btn-success px-2 py-1">Tutorial</button>
                    </div>
                  )}
                </div> */}
              </div>
              {/* <AutoStepper quoteStatus={quoteStatus} /> */}
              <InfoBoxAuto
                quote={quote}
                insuranceRequest={insuranceRequest}
                rc_quotation={rc_quotation}
                tutela_quotation={tutela_quotation}
                assistenza_quotation={assistenza_quotation}
                cristalli_quotation={cristalli_quotation}
                infortuni_conducente_quotation={infortuni_conducente_quotation}
                rivalsa_quotation={rivalsa_quotation}
                statues={statues}
                quoteStatus={quoteStatus}
                messagesModalData={{
                  backofficeUsers, setSupportMessage, supportMessage, collectData: collectQuoteData,
                }}
              />
            </div>
            <div className="container mt-3">
              {(rc_quotation.company_id === 2 && rc_quotation.prima_link !== null && !rc_quotation.indirect_prima_quote_process) ? <RedirectToPrimaAlert>Verrai reindirizzato alla piattaforma Prima.it</RedirectToPrimaAlert> : quoteStatus === 1 || quoteStatus === 2 || quoteStatus === 5
                ? (
                  <>
                    <SaveBox
                      insuranceRequest={insuranceRequest}
                      quotationId={rc_quotation.id}
                      refresh={collectQuoteData}
                    />
                    {(nonSignableDocs.length > 0 || requestedOptionalDocumentsNonSignable.length > 0) && (
                      <div className="row mt-2">
                        <div className="col-12 documents-status-step-four">
                          <UploadDocumentsContainer>
                            <div className="d-flex align-items-center justify-content-start">
                              <NumberCurrent>1</NumberCurrent>
                              <h2>
                                Carica i
                                {' '}
                                <b>documenti</b>
                                {' '}
                                del cliente
                              </h2>
                            </div>
                            <UploadWrapper>
                              {nonSignableDocs.map((docType, index) => (
                                <NonSignableDocumentComponent
                                  key={index}
                                  insuranceRequest={insuranceRequest}
                                  documentType={docType}
                                  downloadDocument={downloadDocument}
                                  removeDocument={removeDocument}
                                  rc_quotation={rc_quotation}
                                  collectQuoteData={collectQuoteData}
                                />
                              ))}
                              {requestedOptionalDocumentsNonSignable.map((document_request, index) => (
                                <NonSIgnableDocumentRequestComponent
                                  key={index}
                                  document_request={document_request}
                                  removeOptionalDocument={removeOptionalDocument}
                                  downloadOptionalDocument={downloadOptionalDocument}
                                  collectQuoteData={collectQuoteData}
                                />
                              ))}
                              <OptionalDocumentBox>
                                <BadgeOptional>
                                  Facoltativo
                                </BadgeOptional>
                                <img src={uploadIcon} alt="" />
                                <button type="button" onClick={() => openOptionalDocumentModal()}>
                                  Richiedi di aggiungere un documento
                                </button>
                              </OptionalDocumentBox>
                            </UploadWrapper>
                          </UploadDocumentsContainer>
                        </div>
                      </div>
                    )}
                    <div className="documents-status-step-five">
                      {rc_quotation.sign_later === null && (
                        <div className="row mt-3">
                          <div className="col-12 col-xxl-12 mt-2 mt-xxl-0">
                            <SkipSignedDocs>
                              <p className="text-center">Vuoi Firmare Dopo?</p>
                              <div className="d-flex justify-content-center align-items-center gap-3">
                                <button className="btn btn-success border py-1 px-4" onClick={() => continueWithoutSignDocs()}>Si</button>
                                <button className="btn btn-danger border py-1 px-4" onClick={() => continueWithSignDocs()}>No</button>
                              </div>
                            </SkipSignedDocs>
                          </div>
                        </div>
                      )}
                      {rc_quotation.sign_later === 1 && <div className="alert alert-success mt-3 border rounded-4">Hai scelto di firmare i documenti più tardi.</div>}
                      {rc_quotation.sign_later === 0 && (
                        <div className="row mt-2">
                          <div className="col-12 col-xxl-12 mt-2 mt-xxl-0">
                            <SignatureType
                              quote={rc_quotation}
                              insuranceRequest={insuranceRequest}
                              collectQuoteData={collectQuoteData}
                              documentRequestsThatNeedSignature={documentRequestsThatNeedSignature}
                              signableDocs={signableDocs}
                              requestedOptionalDocumentsSignable={requestedOptionalDocumentsSignable}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                  </>
                )
                : ''}

              {
                quoteStatus === 4
                && (
                  <WaitingForPaymentStatus
                    quote={quote}
                    insuranceRequest={insuranceRequest}
                    collectQuoteData={collectQuoteData}
                    rc_quotation={rc_quotation}
                    tutela_quotation={tutela_quotation}
                    assistenza_quotation={assistenza_quotation}
                    cristalli_quotation={cristalli_quotation}
                    infortuni_conducente_quotation={infortuni_conducente_quotation}
                    rivalsa_quotation={rivalsa_quotation}
                  />
                )
              }

              {
                quoteStatus === 6
                && (
                  <div className="card-body">
                    <div className="row">
                      <div className="col-6 offset-3">
                        <div
                          className="alert alert-success"
                          role="alert"
                        >
                          <h4 className="alert-heading">
                            In Attesa Emissione
                          </h4>
                          <p>
                            Quando l'agente di backoffice carica la
                            polizza, riceverai una notifica e il
                            contratto potrà essere scaricato.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </>
        )}
      {
        showOptionalDocumentModal === true
        && (
          <OptionalDocumentModal
            quotationId={rc_quotation.id}
            closeModal={() => setShowOptionalDocumentModal(false)}
            collectQuoteData={collectQuoteData}
          />
        )
      }
      {
        (quoteStatus === 7)
        && (
          <>
            <div className="d-flex justify-content-start align-items-center gap-2 mb-3">
              <div id="back-button" onClick={() => navigateBack()}>
                <FontAwesomeIcon icon={faArrowLeftLong} />
              </div>
              {/* <div>
                {localStorage.getItem('purchaseTour') === 'done' && (
                  <div style={{ cursor: 'pointer' }} onClick={() => firePurchaseTour()}>
                    <button className="btn btn-success px-2 py-1">Tutorial</button>
                  </div>
                )}
              </div> */}
            </div>
            <CompletedQuoteScreen
              quote={quote}
              quoteStatus={quoteStatus}
              downloadDocument={downloadDocument}
              downloadOptionalDocument={downloadOptionalDocument}
              collectQuoteData={collectQuoteData}
              rc_quotation={rc_quotation}
              tutela_quotation={tutela_quotation}
              assistenza_quotation={assistenza_quotation}
              cristalli_quotation={cristalli_quotation}
              infortuni_conducente_quotation={infortuni_conducente_quotation}
              rivalsa_quotation={rivalsa_quotation}
              insuranceRequest={insuranceRequest}
            />
          </>
        )
      }
    </PurchaseStyles>
  );
}

export default PurchaseAutocarroRequest;
