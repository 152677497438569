import styled from 'styled-components';

export const PlatformChatStyles = styled.div`
  position: fixed;
  bottom: 15px;  
  right: 25px;   
  width: 400px;
  height: 400px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
`;

export const ChatHeader = styled.div`
  padding: 10px;
  background-color: #00A551;
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ChatBody = styled.div`
  padding: 10px;
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  form button {
    width: 100%;
  }
  form p{
    color: red;
    font-size: 14px;
  }
`;

export const StyledTextArea = styled.textarea`
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  margin-top: 7px;
  border: 1px solid #ccc;
  &:focus {
    outline: none;
    border-color: #00A551; /* Border turns green on focus */
    box-shadow: 0 0 5px rgba(0, 255, 0, 0.5); /* Optional: Adds a glow effect */
  }
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const MessageBubble = styled.div`
  background-color: #f1f1f1;
  border-radius: 10px;
  padding: 8px 12px;
  /* margin-left: auto; */
  /* align-self: flex-end;  */
  /* max-width: 65%; */
  word-wrap: break-word;
`;

export const MessageBubbleBroker = styled.div`
  background-color: #03FF81;
  border-radius: 10px;
  padding: 8px 12px;
  word-wrap: break-word;

  .message {
    font-size: 14px;
  }
`;

export const ChatMessage = styled.div`
overflow-y: auto;
max-height: 230px;
`;

export const Message = styled.div`
display: flex;
justify-content: end;
align-items: end;
flex-direction: column;
margin-right: 10px;
width: 65%;
margin-left: auto;
p{
  font-size: 12px;
}
`;

export const BrokerMessage = styled.div`
display: flex;
justify-content: start;
align-items: start;
flex-direction: column;
margin-left: 0px; 
width: 65%;


p{
  font-size: 12px;
  margin-bottom: 2px;
  margin-top: 0px;
}
`;
