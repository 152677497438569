import styled from 'styled-components';

export const BrokersStyles = styled.div`
    h3 {
    font-size: 16px;
    }

    h6 {
    font-size: 14px;
    }
`;

export const InfoButton = styled.button`
    background-color: rgb(100,100,200);
    color: white;
    font-weight: bold;
    padding: 7px 10px;
    border: none;
    border-radius: 5px;

   
`;
