import React from 'react';
import { FormInputText } from '../../formInputs/FormInputText';
import { FormInputCheckbox } from '../../formInputs/FormInputCheckbox';
import { FastQuoteLoading } from '../../components/elements/FastQuoteLoading';
import { Label } from '../../formInputs/styles/common';
import { FormInputSelect } from '../../formInputs/FormInputSelect';
import {
  flagResponse, km_during_one_year, months, vehiclesAmountInFamily, years,
} from '../../constants';
import FormReactSelectBrand from '../../formInputs/FormReactSelectBrand';
import FormReactSelectModel from '../../formInputs/FormReactSelectModel';
import InputSelectTable from '../../components/inputs/InputTable';
import { FormInputNumber } from '../../formInputs/FormInputNumber';
import getAllowedPurchaseYears from '../../helpers/getAllowedPurchaseYears';
import { FormInputRadio } from '../../formInputs/FormInputRadio';

function RcAutoStepOne({
  errors, updateFormData, answers, isValid, checkBoxTypeRef, isLoadingFastQuote, register, brands, isLoadingVehicleModels, vehicleModels, isLoadingVehicleVersions, vehicleVersions, formData,
}) {
  return (
    <>
      <div className="form-container">
        <div id="vehiclePlateDiv">
          <FormInputText
            error={errors.vehicle_plate?.message}
            label="Targa dell' auto"
            onChange={(value) => updateFormData({ vehicle_plate: value.toUpperCase() })}
            value={answers.vehicle_plate}
            valid={isValid('vehicle_plate')}
          />
        </div>

        {isValid('vehicle_plate') === true
          && (
            <div className="d-flex justify-content-center align-items-center gap-2 gap-md-5" ref={checkBoxTypeRef} id="checkBoxType">
              <FormInputCheckbox
                label=" Compila Manualmente?"
                checked={answers.complete_manually}
                onChange={() => updateFormData({
                  complete_manually: !answers.complete_manually,
                  renew_check: false,
                  insurance_type: '',
                  new_risks_check: false,
                })}
              />

              <FormInputCheckbox
                label=" Rinnovo?"
                checked={answers.renew_check}
                onChange={() => updateFormData({
                  renew_check: !answers.renew_check,
                  insurance_type: !answers.renew_check ? 'B' : 'N',
                  new_risks_check: false,
                  complete_manually: false,
                })}
              />

              <FormInputCheckbox
                label=" Nuovo rischio?"
                checked={answers.new_risks_check}
                onChange={() => updateFormData({
                  new_risks_check: !answers.new_risks_check,
                  renew_check: false,
                  insurance_type: '',
                  complete_manually: false,
                })}
              />
            </div>
          )}

      </div>

      {/* {validateCarPlate(answers.vehicle_plate)
              && (answers.renew_check === true
                || answers.new_risks_check === true) && (
                <div className="form-container">
                  {isLoadingFastQuote === false && (
                    <div
                      className="btn btn-medium btn-primary"
                      style={{
                        display: 'block',
                        margin: 'auto',
                        width: '300px',
                      }}
                      onClick={getVehicleInfo}
                    >
                      Ottieni informazioni sul veicolo
                    </div>
                  )}

                  {isLoadingFastQuote === true && <FastQuoteLoading />}
                </div>
            )} */}
      {isLoadingFastQuote === true && <FastQuoteLoading />}

      {answers.vehicle_plate !== ''
        && !errors.vehicle_plate?.message
        && (answers.imm_vehicle_month !== ''
          || answers.complete_manually === true) && (
          <div className="form-container" id="nextInputsImmatricolationBrand">
            <div className="row mt-2">
              <Label>Data di prima immatricolazione</Label>
              <div className="col-12 col-md-8">
                <FormInputSelect
                  registration={register('imm_vehicle_month')}
                  error={errors.imm_vehicle_month?.message}
                  valid={isValid('imm_vehicle_month')}
                  placeholder="-Mese-"
                  options={months}
                />
              </div>

              <div className="col-12 col-md-4">
                <FormInputSelect
                  registration={register('imm_vehicle_year')}
                  error={errors.imm_vehicle_year?.message}
                  valid={isValid('imm_vehicle_year')}
                  placeholder="-Anno-"
                  options={years}
                />
              </div>
            </div>
            <FormReactSelectBrand
              label="Marca"
              values={{
                vehicle_brand_code: answers.vehicle_brand_code,
              }}
              error={errors.vehicle_brand_code?.message}
              valid={isValid('vehicle_brand_code')}
              onChange={(item) => updateFormData({
                vehicle_brand_code: item.vehicle_brand_code,
              })}
              options={brands}
            />
          </div>
      )}

      {answers.imm_vehicle_year ? (
        <>
          {answers.vehicle_brand_code && (
            <div className="form-container" id="nextInputModel">
              {isLoadingVehicleModels ? (
                <p className="text-center">Attendere prego...</p>
              ) : (
                <FormReactSelectModel
                  label="Modello"
                  values={{
                    vehicle_model_code: answers.vehicle_model_code,
                  }}
                  error={errors.vehicle_model_code?.message}
                  valid={isValid('vehicle_model_code')}
                  onChange={(item) => updateFormData({
                    vehicle_model_code: item.vehicle_model_code,
                  })}
                  options={vehicleModels}
                />
              )}
            </div>
          )}
          {answers.vehicle_brand_code && answers.vehicle_model_code ? (
            isLoadingVehicleVersions ? (
              <p className="text-center">Attendere prego...</p>
            ) : answers.vehicle_model_code
              && vehicleVersions.length > 0 ? (
                <div id="versionCode">
                  <InputSelectTable
                    TableHeader={(
                      <div className="d-flex">
                        <div className="col-7">Descrizione</div>
                        <div className="col-1">CC</div>
                        <div className="col-4">Data Veicolo</div>
                      </div>
                  )}
                    label="Versione"
                    name="vehicle_version_code"
                    id="vehicle_version_code"
                    options={vehicleVersions}
                    selected={answers.vehicle_am_code}
                    onSelectOption={(value) => updateFormData({
                      vehicle_version_code: value.id,
                      vehicle_am_code: value.am_code,
                    })}
                  />
                </div>
              ) : (
                <div className="alert-general-error">
                  Nessuna versione trovata per questo modello!
                </div>
              )
          ) : (
            ''
          )}
          {answers.vehicle_version_code && (
            <div className="form-container" id="restInputsStepOne">
              <FormInputNumber
                registration={register('weight')}
                error={errors.weight?.message}
                valid={isValid('weight')}
                label="Peso dell'auto"
              />
              <FormInputNumber
                registration={register('power')}
                error={errors.power?.message}
                valid={isValid('power')}
                label="Potenza in kW"
              />
              <FormInputSelect
                label="Altra alimentazione"
                registration={register('other_power_supply')}
                error={errors.other_power_supply?.message}
                valid={isValid('other_power_supply')}
                placeholder="-Seleziona-"
                options={formData.vehicle_fuels}
              />
              <FormInputSelect
                label="Parcheggio auto"
                registration={register('vehicle_parking')}
                error={errors.vehicle_parking?.message}
                valid={isValid('vehicle_parking')}
                placeholder="-Seleziona-"
                options={formData.parking_types}
              />
              <FormInputSelect
                label="Antifurto"
                registration={register('theft_protection_code')}
                error={errors.theft_protection_code?.message}
                valid={isValid('theft_protection_code')}
                placeholder="-Seleziona-"
                options={formData.theft_protections}
              />
              <FormInputSelect
                label="Utilizzo"
                registration={register('vehicle_usage')}
                error={errors.vehicle_usage?.message}
                valid={isValid('vehicle_usage')}
                placeholder="-Seleziona-"
                options={formData.vehicle_usage_types.filter(
                  (type) => type.vehicle_type === 'auto',
                )}
              />
              <FormInputSelect
                label="Km percorsi in un anno"
                registration={register('predicted_km')}
                error={errors.predicted_km?.message}
                valid={isValid('predicted_km')}
                placeholder="-Seleziona-"
                options={km_during_one_year}
              />
              {answers.imm_vehicle_year !== '' && (
                <div className="row mt-2">
                  <Label>Mese e anno in cui hai comprato l'auto</Label>
                  <div className="col-12 col-md-8">
                    <FormInputSelect
                      registration={register('vehicle_purchased_month')}
                      error={errors.vehicle_purchased_month?.message}
                      valid={isValid('vehicle_purchased_month')}
                      placeholder="-Mese-"
                      options={months}
                    />
                  </div>

                  <div className="col-12 col-md-4">
                    <FormInputSelect
                      registration={register('vehicle_purchased_year')}
                      error={errors.vehicle_purchased_year?.message}
                      valid={isValid('vehicle_purchased_year')}
                      placeholder="-Anno-"
                      options={getAllowedPurchaseYears(
                        answers.imm_vehicle_year,
                      )}
                    />
                  </div>
                </div>
              )}

              <FormInputSelect
                label="Auto nel nucleo familiare"
                registration={register('vehicles_owned')}
                error={errors.vehicles_owned?.message}
                valid={isValid('vehicles_owned')}
                placeholder="-Seleziona-"
                options={vehiclesAmountInFamily}
              />
              <FormInputRadio
                name="tow_hook"
                label="Hai montato il gancio di traino?"
                paragraph="(iscritta a rimorchiatori, caravan, ecc.)"
                options={flagResponse}
                registration={register('tow_hook')}
                error={errors.tow_hook?.message}
              />
            </div>
          )}
        </>
      ) : (
        ''
      )}
    </>
  );
}

export default RcAutoStepOne;
