import React from 'react';
import { TutorialStyles } from './Tutorial.styles';

function Tutorial() {
  return (
    <TutorialStyles>
      <div className="row mt-3">
        <div className="col-12 mb-2">
          <h3 className="ps-3 ps-lg-3 ">Greenia Tutorials</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body profile-card pt-3">
              <div className="row mt-4">
                {/* <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-2 mt-xl-0">
                  <div className="card border-custom">
                    <div className="position-relative">
                      <iframe className="w-100" height="315" src="https://www.youtube-nocookie.com/embed/AAq89SAuPs4?rel=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" />
                    </div>
                    <div className="card-body px-2">
                      <h6 className="card-title">Come fare l'assicurazione per infortuni?</h6>
                      <p className="card-text">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Possimus, soluta...</p>
                    </div>
                  </div>
                </div> */}
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-2 mt-xl-0">
                  <div className="card border-custom">
                    <div className="position-relative">
                      <iframe className="w-100" height="315" src="https://www.youtube-nocookie.com/embed/7ukQtZTkh3g?rel=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" />
                    </div>
                    <div className="card-body px-2">
                      <h6 className="card-title">Come fare l'assicurazione Rc Professionale?</h6>
                      {/* <p className="card-text">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Possimus, soluta...</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-2 mt-xl-0">
                  <div className="card border-custom">
                    <div className="position-relative">
                      <iframe className="w-100" height="315" src="https://www.youtube-nocookie.com/embed/-r5C7mDkbgs?rel=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" />
                    </div>
                    <div className="card-body px-2">
                      <h6 className="card-title">Come fare l'assicurazione Rc Auto?</h6>
                      {/* <p className="card-text">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Possimus, soluta...</p> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row mt-2">
                                <div className="col">
                                    <nav>
                                        <ul className="pagination">
                                            <li className="page-item disabled">
                                                <a className="page-link green-stl">Precedente</a>
                                            </li>
                                            <li className="page-item"><a className="page-link green-stl" href="#">1</a></li>
                                            <li className="page-item active" aria-current="page">
                                                <a className="page-link green-stl" href="#">2</a>
                                            </li>
                                            <li className="page-item"><a className="page-link green-stl" href="#">3</a></li>
                                            <li className="page-item">
                                                <a className="page-link green-stl" href="#">Prossima</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
        {/* <div className="col-12">
                    <div className="alert alert-info">
                        Prossimamente...
                    </div>
                </div> */}
      </div>
    </TutorialStyles>
  );
}

export default Tutorial;
