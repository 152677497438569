import { createSlice } from '@reduxjs/toolkit';

import { callerCreator } from '../callerCreator';

const initialState = {
  forgotPassword: {
    loading: false,
    error: null,
    success: false,
  },
  resetPassword: {
    loading: false,
    error: null,
  },
  changePassword: {
    loading: false,
    error: null,
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    forgotPasswordPending: (state) => {
      state.forgotPassword.loading = true;
      state.forgotPassword.error = null;
      state.forgotPassword.success = false;
    },
    forgotPasswordRejected: (state, { payload }) => {
      state.forgotPassword.loading = false;
      state.forgotPassword.error = payload;
    },
    forgotPasswordFulfilled: (state) => {
      state.forgotPassword.loading = false;
      state.forgotPassword.success = true;
    },
    forgotPasswordReset: (state) => {
      state.forgotPassword = { ...initialState.forgotPassword };
    },

    resetPasswordPending: (state) => {
      state.resetPassword.loading = true;
      state.resetPassword.error = null;
    },
    resetPasswordRejected: (state, { payload }) => {
      state.resetPassword.loading = false;
      state.resetPassword.error = payload;
    },
    resetPasswordReset: (state) => {
      state.resetPassword = { ...initialState.resetPassword };
    },

    changePasswordPending: (state) => {
      state.changePassword.loading = true;
      state.changePassword.error = null;
    },
    changePasswordRejected: (state, { payload }) => {
      state.changePassword.loading = false;
      state.changePassword.error = payload;
    },
    changePasswordReset: (state) => {
      state.changePassword = { ...initialState.changePassword };
    },
  },
});

export default callerCreator(authSlice.actions);
