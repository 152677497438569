export const riskCardPrimaStyles = (type, processingQuote) => {
  if (type !== undefined) {
    return `mt-3 purchase-quote-fourth-step flex-grow-1 d-flex align-items-center justify-content-between flex-column p-3
    ${(processingQuote !== null && processingQuote.guarantees.find((g) => g.guarantees_slug === 'tutela_legale') !== undefined && type.id === 'tutela_legale') ? 'bg-success bg-opacity-25' : ''}
    ${(processingQuote !== null && processingQuote.guarantees.find((g) => g.guarantees_slug === 'assistenza_stradale') !== undefined && type.id === 'assistenza_stradale') ? 'bg-success bg-opacity-25' : ''}
    ${(processingQuote !== null && processingQuote.guarantees.find((g) => g.guarantees_slug === 'cristalli') !== undefined && type.id === 'cristalli') ? 'bg-success bg-opacity-25' : ''}
    ${(processingQuote !== null && processingQuote.guarantees.find((g) => g.guarantees_slug === 'infortuni_conducente') !== undefined && type.id === 'infortuni_conducente') ? 'bg-success bg-opacity-25' : ''}
    ${(processingQuote !== null && processingQuote.guarantees.find((g) => g.guarantees_slug === 'protezione_rivalse') !== undefined && type.id === 'protezione_rivalse') ? 'bg-success bg-opacity-25' : ''}
    ${(processingQuote !== null && processingQuote.guarantees.find((g) => g.guarantees_slug === 'kasko') !== undefined && type.id === 'kasko') ? 'bg-success bg-opacity-25' : ''}
    ${(processingQuote !== null && processingQuote.guarantees.find((g) => g.guarantees_slug === 'collisione') !== undefined && type.id === 'collisione') ? 'bg-success bg-opacity-25' : ''}
    ${(processingQuote !== null && processingQuote.guarantees.find((g) => g.guarantees_slug === 'eventi_sociopolitici') !== undefined && type.id === 'eventi_sociopolitici') ? 'bg-success bg-opacity-25' : ''}
    ${(processingQuote !== null && processingQuote.guarantees.find((g) => g.guarantees_slug === 'eventi_naturali') !== undefined && type.id === 'eventi_naturali') ? 'bg-success bg-opacity-25' : ''}
    ${(processingQuote !== null && processingQuote.guarantees.find((g) => g.guarantees_slug === 'bonus_protetto') !== undefined && type.id === 'bonus_protetto') ? 'bg-success bg-opacity-25' : ''}
    ${(processingQuote !== null && processingQuote.guarantees.find((g) => g.guarantees_slug === 'furto_incendio') !== undefined && type.id === 'furto_incendio') ? 'bg-success bg-opacity-25' : ''}
     `;
  }
  return 'mt-3 purchase-quote-fourth-step flex-grow-1 d-flex align-items-center justify-content-between flex-column p-3';
};

export const riskCardStyles = (requestInfo, type) => `mt-3 purchase-quote-fourth-step flex-grow-1 d-flex align-items-center justify-content-between flex-column p-3 
${requestInfo.processing_quotation_id === null && type.id !== 'res_civile' ? 'bg-secondary bg-opacity-25' : ''} 
${requestInfo.processing_quotation_id !== null && type.id === 'res_civile' ? 'bg-success bg-opacity-25' : ''} 
${(requestInfo.tutela_legale_quote_id !== null && type.id === 'tutela_legale') ? 'bg-success bg-opacity-25' : ''} 
${(requestInfo.assistenza_stradale_quote_id !== null && type.id === 'assistenza_stradale') ? 'bg-success bg-opacity-25' : ''} 
${(requestInfo.cristalli_quote_id !== null && type.id === 'cristalli') ? 'bg-success bg-opacity-25' : ''} 
${(requestInfo.infortuni_conducente_quote_id !== null && type.id === 'infortuni_conducente') ? 'bg-success bg-opacity-25' : ''}
${(requestInfo.rivalsa_quote_id !== null && type.id === 'rivalsa') ? 'bg-success bg-opacity-25' : ''}`;
