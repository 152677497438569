export default function InputTextarea(props) {
  const { valid, error } = props;

  const handleChange = ({ target: { value } }) => {
    props.onChange(value);
  };

  if (props.nostyle) {
    return (
      <>
        <textarea
          value={props.value}
          type="text"
          rows={props.rows}
          className={`form-control text ${valid ? 'is-valid' : ''} ${error ? 'is-invalid' : ''} ${props.className}`}
          onChange={handleChange}
          pattern={props.pattern}
          placeholder={props.placeholder}
        />
        <div className="invalid-feedback">
          {props.error}
        </div>
      </>
    );
  }

  return (
    <div
      className="form-input-container"
    >
      <div className="row">
        <div className={`${props.colClass ? props.colClass : ''}`}>
          <label className="f-label">{props.label}</label>
          <textarea
            value={props.value}
            placeholder={props.placeholder}
            className={`form-control text ${valid ? 'is-valid' : ''} ${error ? 'is-invalid' : ''} ${
              props.className ? props.className : ''
            }`}
            type="text"
            rows={props.rows}
            pattern={props.pattern}
            onChange={handleChange}
            {...(props.register !== undefined) ? props.register : {}}
          />
          <div className="invalid-feedback">
            {props.error}
          </div>
        </div>
      </div>
    </div>
  );
}
