import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  addQuoteTravelToCartBackoffice, loadTravelQuotes, removeQuoteMotoBackofficeFromCart, resetTravelQuotes,
} from '../../../features/backofficeResults/backofficeResultsActions';
import PageLoading from '../../../common/components/elements/PageLoading';
import { BACKEND_BASE_URL } from '../../../config';
import { confirmAlert, generalAlertError } from '../../../common/utils/alerts';
import { Layout } from '../components/Layout/Layout';
import { TravelQuotesStyles } from './TravelResultPage.styles';
import TravelCardInfo from './cards/TravelCardInfo';
import { EstimateContainer } from '../../../common/components/redesignComponents/common/Redesign.styles';
import { GuaranteesDefaultStateStyles } from '../components/common/Modal/GuaranteesDefaultStateTravel.styles';
import { quotationTypes } from '../../../common/constants';
import {
  RespCivileModal, RiskButton, RiskButtonRemove, RiskCard, RiskContainer,
} from '../../../common/components/redesignComponents/Cards/ProfessionInfoCard.styles';
import EuropeassistanceCard from '../../../common/components/redesignComponents/Cards/EuropeassistanceCard';
import ProcessingQuoteSelectedTravel from '../../../common/components/redesignComponents/ResultPageComponents/ProcessingQuoteSelectedTravel';
import TutelaSelectedTravel from '../../../common/components/redesignComponents/ResultPageComponents/TutelaSelectedTravel';
import AragCard from '../../../common/components/redesignComponents/Cards/AragCard';

function BackOfficeTravelQuotes() {
  const { requestToken } = useParams();
  const {
    loading, customer, quotations, insuranceRequest, failedQuotations, error, quotes,
    processingQuote, tutelaLegaleQuote, addingQuote,
  } = useSelector((store) => store.backofficeResults.travel_quotes);
  const navigate = useNavigate();

  useEffect(() => {
    loadTravelQuotes(requestToken);

    return () => {
      resetTravelQuotes();
    };
  }, []);

  const modifyData = () => {
    navigate(`/backoffice/quote/${requestToken}/travel`);
  };

  if (error) {
    generalAlertError(error);
  }

  const fetchQuotes = () => {
    loadTravelQuotes(requestToken);
  };
  const selectQuotation = (quoteId) => {
    confirmAlert('Sei Sicuro di aggiungere questa quotazione?', () => addQuoteTravelToCartBackoffice(quoteId, fetchQuotes));
  };

  const removeItemFromCart = (quoteId) => {
    confirmAlert('Sei Sicuro di rimuovere questa quotazione?', () => removeQuoteMotoBackofficeFromCart(quoteId, fetchQuotes));
  };

  const getTotal = () => {
    let quoteInProccessAmount = 0;
    let tutelaInProccessAmount = 0;

    if (processingQuote !== null) {
      quoteInProccessAmount = parseFloat(processingQuote.amount);
    }
    if (tutelaLegaleQuote !== null) {
      tutelaInProccessAmount = parseFloat(tutelaLegaleQuote.amount);
    }

    const total = quoteInProccessAmount + tutelaInProccessAmount;
    return total.toFixed(2);
  };

  // const handleRequestSave = () => {
  // if (processingQuote === null) {
  //   generalAlertError('Seleziona Prima La Responsabilita Civile', 'Errore');
  //   return;
  // }

  // navigate(`/quotes/travel/purchase/${requestToken}`);
  // };

  if (loading) {
    return <PageLoading />;
  }

  return (
    <Layout>
      <div
        className="resultsPage"
        style={{
          width: '100%',
          maxWidth: '1200px',
          margin: 'auto',
          padding: '10px',
        }}
      >
        <TravelQuotesStyles>
          <div className="resultsPage">
            <div className="row">
              <div className="col-12 col-xxl-4 mb-2 mb-xxl-0">
                <TravelCardInfo insuranceRequest={insuranceRequest} customer={customer} modificaDati={modifyData} />
              </div>
              <div className="col-12 col-xxl-8">
                {/* <GuaranteesDefaultStateTravel /> */}
                <GuaranteesDefaultStateStyles>
                  <div className="container mt-4">
                    {processingQuote === null && tutelaLegaleQuote === null
                      ? <h5 className="fw-bold">Seleziona Le Quotazioni Che Vuoi Aquistare</h5>
                      : (
                        <>
                          <ul className="list-unstyled">
                            {processingQuote !== null && (
                              <li>
                                <div className="d-flex justify-content-between align-items-center">
                                  <h5>
                                    {processingQuote.company !== null ? processingQuote.company.name : processingQuote.preventivas_company.company_name}
                                    {' '}
                                    <sub>
                                      (
                                      {quotationTypes.find((item) => item.id === processingQuote.quote_type).name}
                                      )
                                    </sub>
                                  </h5>
                                  <h5 className="fw-bold">
                                    {parseFloat(processingQuote.amount)}
                                    {' '}
                                    €
                                  </h5>
                                </div>
                              </li>
                            )}
                            {tutelaLegaleQuote !== null && (
                              <li>
                                <div className="d-flex justify-content-between align-items-center">
                                  <h5>
                                    {tutelaLegaleQuote.company !== null ? tutelaLegaleQuote.company.name : tutelaLegaleQuote.preventivas_company.company_name}
                                    {' '}
                                    <sub>
                                      (
                                      {quotationTypes.find((item) => item.id === tutelaLegaleQuote.quote_type).name}
                                      )
                                    </sub>
                                  </h5>
                                  <h5 className="fw-bold">
                                    {parseFloat(tutelaLegaleQuote.amount)}
                                    {' '}
                                    €
                                  </h5>
                                </div>
                              </li>
                            )}
                            <hr />
                            <li>
                              <div className="d-flex justify-content-end align-items-center">
                                <h5 className="me-5 fw-bold">
                                  Totale:
                                </h5>
                                <h5 className="fw-bold">
                                  {' '}
                                  {getTotal()}
                                  {' '}
                                  €
                                </h5>
                              </div>
                            </li>
                          </ul>
                          {/* <div className="d-flex justify-content-end mt-3">
                            <button className="btn btn-success rounded-4 px-5 py-2" onClick={() => handleRequestSave()}>
                              Aquista
                            </button>
                          </div> */}
                        </>
                      )}
                  </div>
                </GuaranteesDefaultStateStyles>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <EstimateContainer>
                  <h4>
                    Preventivi assicurazione
                    {' '}
                    <b>viaggi</b>
                  </h4>
                  <p>
                    Inizio Copertura:
                    {' '}
                    {new Date(insuranceRequest.policy_effective_date).toLocaleDateString('it-IT', { year: 'numeric', month: 'numeric', day: 'numeric' })}
                  </p>
                </EstimateContainer>
              </div>
            </div>
            <RiskContainer className="mb-4 result-travel-third-step">
              {quotationTypes.map((type, index) => (
                <React.Fragment key={index}>
                  {quotes && quotes[type.id] && (
                    <RiskCard className={`mt-3 purchase-quote-fourth-step flex-grow-1 d-flex align-items-center justify-content-between flex-column p-3 ${insuranceRequest.processing_quotation_id === null && type.id !== 'res_civile' ? 'bg-secondary bg-opacity-25' : ''} ${insuranceRequest.processing_quotation_id !== null && type.id === 'res_civile' ? 'bg-success bg-opacity-25' : ''} ${insuranceRequest.tutela_legale_quote_id !== null && type.id === 'tutela_legale' ? 'bg-success bg-opacity-25' : ''} ${insuranceRequest.assistenza_stradale_quote_id !== null && type.id === 'assistenza_stradale' ? 'bg-success bg-opacity-25' : ''} ${insuranceRequest.cristalli_quote_id !== null && type.id === 'cristalli' ? 'bg-success bg-opacity-25' : ''} ${insuranceRequest.infortuni_conducente_quote_id !== null && type.id === 'infortuni_conducente' ? 'bg-success bg-opacity-25' : ''} ${insuranceRequest.rivalsa_quote_id !== null && type.id === 'rivalsa' ? 'bg-success bg-opacity-25' : ''}`}>
                      <div className="p-3 me-auto">
                        <h5>{type.name}</h5>
                      </div>
                      {(processingQuote !== null && type.id === 'res_civile') && <ProcessingQuoteSelectedTravel processingQuote={processingQuote} requestInfo={insuranceRequest} />}

                      {(processingQuote !== null && type.id === 'tutela_legale') && <TutelaSelectedTravel tutelaLegaleQuote={tutelaLegaleQuote} type={type} />}

                      <div className="d-flex justify-content-end ms-auto gap-2">
                        {(type.id === 'res_civile' && processingQuote !== null) && <RiskButtonRemove className="btn btn-danger" onClick={() => removeItemFromCart(processingQuote.id)}>Rimuovi</RiskButtonRemove>}

                        {(type.id === 'tutela_legale' && tutelaLegaleQuote !== null) ? <RiskButtonRemove className="btn btn-danger" onClick={() => removeItemFromCart(tutelaLegaleQuote.id)}>Rimuovi</RiskButtonRemove> : type.id === 'tutela_legale' && tutelaLegaleQuote === null && processingQuote !== null ? <RiskButton className="btn btn-success" data-bs-toggle="modal" data-bs-target={`#${type.id}`}>Aggiungi</RiskButton> : ''}

                        {insuranceRequest.processing_quotation_id === null && type.id !== 'res_civile' && <div className="alert alert-warning py-2 px-2">Seleziona Prima La Responsibilita Civile</div>}

                        {type.id === 'res_civile' && <RiskButton className="btn btn-success" data-bs-toggle="modal" data-bs-target={`#${type.id}`}>Aggiungi</RiskButton>}
                      </div>
                    </RiskCard>
                  )}
                </React.Fragment>
              ))}
            </RiskContainer>

            {quotationTypes.map((type, index) => (
              <RespCivileModal className="modal fade" id={type.id} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" key={index}>
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">{type.name}</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="buttonClose" />
                    </div>
                    <div className="modal-body">
                      {addingQuote && (
                        <div className="d-flex align-items-center justify-content-between flex-column">
                          <div className="d-flex justify-content-center">
                            <div className="spinner-border" role="status">
                              <span className="visually-hidden">Aggiungendo...</span>
                            </div>
                          </div>
                          <div className="ms-2">Aggiungendo...</div>
                        </div>
                      )}
                      {addingQuote === false && (
                        <div className="row mb-4">
                          <div className="row purchase-quote-eight-step">
                            {addingQuote === false && (quotes && quotes[type.id]) ? quotes[type.id].map((quote, index) => (
                              (
                                <>
                                  {type.id === 'res_civile' && <EuropeassistanceCard quote={quote} requestToken={requestToken} guarantees={quotations.quotation_coverages} insuranceRequest={insuranceRequest} key={index} selectQuotation={selectQuotation} />}

                                  {type.id === 'tutela_legale' && <AragCard quotation={quote} requestInfo={insuranceRequest} key={index} type={type} selectQuotation={selectQuotation} />}
                                </>
                              )
                            )) : <h5 className="fw-bold">Nessuna Quotazione Trovata</h5>}
                          </div>
                        </div>
                      )}

                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-danger px-4" data-bs-dismiss="modal">Chiudi</button>
                    </div>
                  </div>
                </div>
              </RespCivileModal>
            ))}
          </div>
        </TravelQuotesStyles>
        {failedQuotations.length !== 0 && (
          <div className="failed-quotations-block">
            Queste compagnie non hanno risposto con una citazione.
            <br />
            <hr />
            <div className="container ms-0">
              <div className="row">
                {failedQuotations.map((failed, index) => (
                  <div className="col-3" key={index}>
                    <img src={BACKEND_BASE_URL + failed.company.logo} alt={`${failed.company.name} Logo`} width={100} />
                  </div>
                ))}
              </div>
            </div>

          </div>
        )}
      </div>
    </Layout>
  );
}

export default BackOfficeTravelQuotes;
