import React from 'react';
import LoadingGif from '../../../assets/images/loading.gif';
import { PageLoadingFullStyles } from './PageLoadingFull.styles';

function PageLoadingFull() {
  return (
    <PageLoadingFullStyles>
      <div className="d-flex content-loading">
        <div className="text-center">
          <img className="loading-gif" src={LoadingGif} />
          <br />
          Attendere prego
        </div>
      </div>
    </PageLoadingFullStyles>
  );
}

export default PageLoadingFull;
