import React, {
  createRef, useEffect, useRef, useState,
} from 'react';
import moment from 'moment';
import pdfIMG from '../../../assets/images/purchase/pdf.png';
import downloadIMG from '../../../assets/redesign_icons/download.svg';
import quotationsAxe from '../../../services/axios-client/quotationsAxeClient';
import { generalAlertError, generalAlertSuccessToast } from '../../utils/alerts';
import { apiOptionalDocumentMethodManualUpload, documentMethodManualUpload } from '../../../services/axios-client/axeCommons';
import uploadIcon from '../../../assets/redesign_icons/upload.svg';
import { ActionsWrapperResultPage } from '../redesignComponents/Purchase/SignatureType.styles';

function CompletedMotoQuoteScreen({
  quote,
  quoteStatus,
  downloadDocument,
  downloadOptionalDocument,
  collectQuoteData,
  rc_quotation,
  tutela_quotation,
  insuranceRequest,
}) {
  const [residenceInfo, setResidenceInfo] = useState(null);
  const uploadPolizzaEmessa = useRef(null); // 13 - Polizza emessa
  const uploadQuietanzaSemestrale = useRef(null); // 32 - Quietanza Semestrale
  const uploadPrivacyRef = useRef(null); // 12 - Privacy Greenia
  const uploadPreContractRef = useRef(null); // 29 - Percontrattuale Greenia
  const uploadDichiarazioniDocRef = useRef(null); // 28 - Dichiarazioni del contraente
  const uploadQuestionarioAutoDocRef = useRef(null); // 30 - Questionario Auto
  const uploadMergedDocFileRef = useRef(null); // 34 - Merged Files
  const [optionalRefs, setOptionalRefs] = useState({});

  const collectMunicipalities = () => {
    try {
      quotationsAxe.get('/form-data/communes').then((res) => {
        const commune = res.data.data.find(
          (commune) => commune.cadastral_code
            == insuranceRequest.customer.residence_commune_code,
        );
        setResidenceInfo(commune);
      });
    } catch (err) {
      // Do nothing
    }
  };

  useEffect(() => {
    if (quoteStatus === 7) {
      collectMunicipalities();
    }
  }, [quoteStatus]);

  const documentsRequests = [
    ...insuranceRequest.document_requests.filter(
      (dr) => dr.document !== null,
    ),
    ...rc_quotation.document_requests.filter((dr) => dr.document !== null),
  ];

  const fireSignedDocUpload = (typeId, docId) => {
    if (typeId === 13) {
      uploadPolizzaEmessa.current.click();
    }
    if (typeId === 32) {
      uploadQuietanzaSemestrale.current.click();
    }
    if (typeId === 29) {
      uploadPreContractRef.current.click();
    }

    if (typeId === 12) {
      uploadPrivacyRef.current.click();
    }

    if (typeId === 26) {
      optionalRefs[docId].current.click();
    }

    if (typeId === 28) {
      uploadDichiarazioniDocRef.current.click();
    }

    if (typeId === 30) {
      uploadQuestionarioAutoDocRef.current.click();
    }

    if (typeId === 34) {
      uploadMergedDocFileRef.current.click();
    }
  };

  const uploadSignedOptionalDocument = async (documentId, file) => {
    const uploadedFile = file[0];
    const reader = new FileReader();
    reader.readAsDataURL(uploadedFile);
    if (uploadedFile.type !== 'application/pdf') {
      generalAlertError('Formato di file errato. Il file deve essere in .pdf');
      return;
    }
    reader.onload = () => {
      apiOptionalDocumentMethodManualUpload(rc_quotation.id, documentId, {
        document: reader.result.replace('data:application/pdf;base64,', ''),
      }).then(() => {
        collectQuoteData();
        generalAlertSuccessToast('File caricato');
      });
    };
  };

  const uploadSignedDocument = async (documentId, files) => {
    const uploadedFile = files[0];
    const reader = new FileReader();

    reader.readAsDataURL(uploadedFile);
    if (uploadedFile.type !== 'application/pdf') {
      generalAlertError('Formato di file errato. Il file deve essere in .pdf');
      return;
    }
    reader.onload = () => {
      documentMethodManualUpload(rc_quotation.id, documentId, {
        document: reader.result.replace('data:application/pdf;base64,', ''),
      }).then(() => {
        collectQuoteData();
        generalAlertSuccessToast('File caricato');
      });
    };
  };

  useEffect(() => {
    const optionalDocs = {};
    documentsRequests.filter((d) => d.optional_document_request_id !== undefined).forEach((doc) => {
      optionalDocs[doc.id] = createRef(null);
    });

    setOptionalRefs(optionalDocs);
  }, [JSON.stringify(documentsRequests)]);

  return (
    <div className="row">
      <div className="col-xl-6">
        <div className="card border mb-2">
          <div className="card-header bg-secondary text-white">
            <h2 className="h3 mb-0 card-title">Anagrafica</h2>
          </div>
          <div className="card-body">
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                {insuranceRequest.customer.gender === 'G' && (
                  <>
                    {' '}
                    <b>Compagnia:</b>
                    {' '}
                    {insuranceRequest.customer.business_name}
                  </>
                )}
                {insuranceRequest.customer.gender !== 'G' && (
                  <>
                    <b>Nome e Cognome:</b>
                    {' '}
                    {insuranceRequest.customer.name}
                    {' '}
                    {insuranceRequest.customer.surname}
                  </>
                )}
              </li>
              <li className="list-group-item">
                <b>Email:</b>
                {' '}
                {insuranceRequest.customer.email}
              </li>
              <li className="list-group-item">
                <b>Telefono:</b>
                {' '}
                {insuranceRequest.customer.phone}
              </li>
              <li className="list-group-item">
                <b>Indirizo:</b>
                {' '}
                {insuranceRequest.customer.address}
                ,
                {' '}
                {insuranceRequest.customer.house_number}
                ,
                {' '}
                {insuranceRequest.customer.postal_code}
              </li>
              <li className="list-group-item">
                <b>Residenza:</b>
                {' '}
                {residenceInfo !== null && residenceInfo.name}
              </li>
              <li className="list-group-item">
                <b>Citta:</b>
                {' '}
                {residenceInfo !== null
                  && residenceInfo.communal_territory.name}
              </li>
            </ul>
          </div>
        </div>
        <div className="card border">
          <div className="card-header bg-secondary text-white">
            <h2 className="h3 mb-0 card-title">Dati Della Moto</h2>
          </div>
          <div className="card-body">
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <b>Targa:</b>
                {' '}
                {insuranceRequest.moto.moto_plate}
              </li>
              <li className="list-group-item">
                <b>Marca:</b>
                {' '}
                {insuranceRequest.moto.brand.name}
              </li>
              <li className="list-group-item">
                <b>Modello:</b>
                {' '}
                {insuranceRequest.moto.model.name}
              </li>
              <li className="list-group-item">
                <b>Antifurto:</b>
                {' '}
                {insuranceRequest.moto.theft_protection.name}
              </li>
              <li className="list-group-item">
                <b>Moto Comprata:</b>
                {' '}
                {insuranceRequest.moto.moto_purchased_year}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-xl-6">
        <div className="card border mb-2">
          <div className="card-header bg-secondary text-white">
            <h2 className="h3 mb-0 card-title">Dati Responsabilita Civile</h2>
          </div>
          <div className="card-body">
            {rc_quotation.company_id == 1 ? (
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <b>Compagnia: </b>
                  {' '}
                  {rc_quotation.company.name}
                </li>
                <li className="list-group-item">
                  <b>Stato:</b>
                  {' '}
                  {rc_quotation.status === 7 && 'Polizza Emmesa'}
                </li>
                <li className="list-group-item">
                  <b>Data Decorrenza:</b>
                  {' '}
                  {moment(rc_quotation.insurance_request.policy_effective_date)
                    .toDate()
                    .toLocaleDateString('it-IT')}
                </li>
                <li className="list-group-item">
                  <b>Frequenza Pagamento:</b>
                  {' '}
                  {rc_quotation.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}
                </li>
                <li className="list-group-item">
                  <b>Pagato:</b>
                  {' '}
                  {rc_quotation.amount_paid === null
                    ? '0'
                    : rc_quotation.amount_paid.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Da Pagare:</b>
                  {' '}
                  {rc_quotation.amount_paid === rc_quotation.amount
                    ? '0'
                    : (rc_quotation.amount - rc_quotation.amount_paid).toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Prossimo Pagamento:</b>
                  {' '}
                  {moment(rc_quotation.insurance_request.policy_effective_date)
                    .add(Math.floor(365 / rc_quotation.payment_frequency), 'days')
                    .toDate()
                    .toLocaleDateString('it-IT')}
                </li>
                <li className="list-group-item">
                  <b>Premio:</b>
                  {' '}
                  {rc_quotation.amount.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Provvigioni:</b>
                  {' '}
                  {rc_quotation.commision.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Brokeraggio:</b>
                  {' '}
                  {rc_quotation.brokerage_amount.toFixed(2)}
                  {' '}
                  &euro;
                </li>
                {rc_quotation.quotation_services.length > 0 && (
                  <li className="list-group-item">
                    <b>Servizio:</b>
                    {' '}
                    {rc_quotation.quotation_services[0].amount_gross.toFixed(2)}
                    {' '}
                    &euro;
                  </li>
                )}
                <li className="list-group-item">
                  <b>Totale:</b>
                  {' '}
                  {(
                    rc_quotation.amount
                    + rc_quotation.brokerage_amount
                    + (rc_quotation.quotation_services.length > 0
                      ? rc_quotation.quotation_services[0].amount_gross
                      : 0)
                  ).toFixed(2)}
                  {' '}
                  &euro;
                </li>
              </ul>
            ) : (
              ''
            )}
            {rc_quotation.company_id == 2 ? (
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <b>Compagnia: </b>
                  {' '}
                  Ipitq ma
                </li>
                <li className="list-group-item">
                  <b>Stato:</b>
                  {' '}
                  {rc_quotation.status === 7 && 'Polizza Emmesa'}
                </li>
                <li className="list-group-item">
                  <b>Data Decorrenza:</b>
                  {' '}
                  {moment(rc_quotation.insurance_request.policy_effective_date)
                    .toDate()
                    .toLocaleDateString('it-IT')}
                </li>
                <li className="list-group-item">
                  <b>Polizza Pagata:</b>
                  {' '}
                  SI
                </li>
                <li className="list-group-item">
                  <b>Totale:</b>
                  {' '}
                  {rc_quotation.amount.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Provvigioni:</b>
                  {' '}
                  {rc_quotation.commision.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                {rc_quotation.quotation_services.length > 0 && (
                  <li className="list-group-item">
                    <b>Servizio:</b>
                    {' '}
                    {rc_quotation.quotation_services[0].amount_gross.toFixed(2)}
                    {' '}
                    &euro;
                  </li>
                )}
                <li className="list-group-item">
                  <b>Totale:</b>
                  {' '}
                  {(
                    rc_quotation.amount
                    + rc_quotation.brokerage_amount
                    + (rc_quotation.quotation_services.length > 0
                      ? rc_quotation.quotation_services[0].amount_gross
                      : 0)
                  ).toFixed(2)}
                  {' '}
                  &euro;
                </li>
              </ul>
            ) : (
              ''
            )}
            {rc_quotation.company_id === 9 || rc_quotation.company_id === 10 ? (
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <b>Compagnia: </b>
                  {' '}
                  {rc_quotation.company.name}
                </li>
                <li className="list-group-item">
                  <b>Stato:</b>
                  {' '}
                  {rc_quotation.status === 7 && 'Polizza Emmesa'}
                </li>
                <li className="list-group-item">
                  <b>Data Decorrenza:</b>
                  {' '}
                  {moment(rc_quotation.insurance_request.policy_effective_date)
                    .toDate()
                    .toLocaleDateString('it-IT')}
                </li>
                <li className="list-group-item">
                  <b>Polizza Pagata:</b>
                  {' '}
                  SI
                </li>
                <li className="list-group-item">
                  <b>Totale:</b>
                  {' '}
                  {rc_quotation.amount.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Provvigioni:</b>
                  {' '}
                  {rc_quotation.commision.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                {rc_quotation.quotation_services.length > 0 && (
                  <li className="list-group-item">
                    <b>Servizio:</b>
                    {' '}
                    {rc_quotation.quotation_services[0].amount_gross.toFixed(2)}
                    {' '}
                    &euro;
                  </li>
                )}
                <li className="list-group-item">
                  <b>Totale:</b>
                  {' '}
                  {(
                    rc_quotation.amount
                    + rc_quotation.brokerage_amount
                    + (rc_quotation.quotation_services.length > 0
                      ? rc_quotation.quotation_services[0].amount_gross
                      : 0)
                  ).toFixed(2)}
                  {' '}
                  &euro;
                </li>
              </ul>
            ) : (
              ''
            )}

            {rc_quotation.company_id === 11 || rc_quotation.company_id === 12 || rc_quotation.company_id === 13 || rc_quotation.company_id === 14 ? (
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <b>Compagnia: </b>
                  {' '}
                  {rc_quotation.company.name}
                </li>
                <li className="list-group-item">
                  <b>Stato:</b>
                  {' '}
                  {rc_quotation.status === 7 && 'Polizza Emmesa'}
                </li>
                <li className="list-group-item">
                  <b>Data Decorrenza:</b>
                  {' '}
                  {moment(rc_quotation.insurance_request.policy_effective_date)
                    .toDate()
                    .toLocaleDateString('it-IT')}
                </li>
                <li className="list-group-item">
                  <b>Polizza Pagata:</b>
                  {' '}
                  SI
                </li>
                <li className="list-group-item">
                  <b>Totale:</b>
                  {' '}
                  {rc_quotation.amount.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Provvigioni:</b>
                  {' '}
                  {rc_quotation.commision.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                {rc_quotation.quotation_services.length > 0 && (
                  <li className="list-group-item">
                    <b>Servizio:</b>
                    {' '}
                    {rc_quotation.quotation_services[0].amount_gross.toFixed(2)}
                    {' '}
                    &euro;
                  </li>
                )}
                <li className="list-group-item">
                  <b>Totale:</b>
                  {' '}
                  {(
                    rc_quotation.amount
                    + rc_quotation.brokerage_amount
                    + (rc_quotation.quotation_services.length > 0
                      ? rc_quotation.quotation_services[0].amount_gross
                      : 0)
                  ).toFixed(2)}
                  {' '}
                  &euro;
                </li>
              </ul>
            ) : (
              ''
            )}
            {rc_quotation.company_id === null ? (
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <b>Compagnia: </b>
                  {' '}
                  {rc_quotation.preventivas_company.company_name}
                </li>
                <li className="list-group-item">
                  <b>Stato:</b>
                  {' '}
                  {rc_quotation.status === 7 && 'Polizza Emmesa'}
                </li>
                <li className="list-group-item">
                  <b>Data Decorrenza:</b>
                  {' '}
                  {moment(rc_quotation.insurance_request.policy_effective_date)
                    .toDate()
                    .toLocaleDateString('it-IT')}
                </li>
                <li className="list-group-item">
                  <b>Polizza Pagata:</b>
                  {' '}
                  SI
                </li>
                <li className="list-group-item">
                  <b>Totale:</b>
                  {' '}
                  {rc_quotation.amount.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Provvigioni:</b>
                  {' '}
                  {rc_quotation.commision.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                {rc_quotation.quotation_services.length > 0 && (
                  <li className="list-group-item">
                    <b>Servizio:</b>
                    {' '}
                    {rc_quotation.quotation_services[0].amount_gross.toFixed(2)}
                    {' '}
                    &euro;
                  </li>
                )}
                <li className="list-group-item">
                  <b>Totale:</b>
                  {' '}
                  {(
                    rc_quotation.amount
                    + rc_quotation.brokerage_amount
                    + (rc_quotation.quotation_services.length > 0
                      ? rc_quotation.quotation_services[0].amount_gross
                      : 0)
                  ).toFixed(2)}
                  {' '}
                  &euro;
                </li>
              </ul>
            ) : (
              ''
            )}
          </div>
        </div>
        {/* ADD TUTELA INFO HERE */}
        {tutela_quotation && 'Tutela Info Here'}
        <div className="card border">
          <div className="card-header bg-secondary text-white">
            <h2 className="h3 mb-0 card-title">Documenti Polizza</h2>
          </div>
          <div className="card-body">
            <ol className="list-group list-group-numbered">
              {insuranceRequest.documents.map((document, index) => (
                <li
                  className="list-group-item d-flex justify-content-between align-items-start"
                  key={index}
                >
                  <div className="ms-2 me-auto">
                    <div className="fw-bold mb-2">
                      {document.document_type.name}
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <img
                        src={pdfIMG}
                        className="img-fluid"
                        width="25"
                        alt="PDF"
                      />
                      <div className="mx-2">
                        <h5 className="h6 mb-0">
                          {document.original_filename}
                        </h5>
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <button
                          className="doc-btn border-0 bg-transparent"
                          onClick={() => downloadDocument(document.id)}
                        >
                          <img src={downloadIMG} width="25" alt="" />
                        </button>
                      </div>
                      {(document.signable_document === 1 && (document.is_signed_valid === 0 || (document.is_signed_valid === null && document.signed_document_filename === null))) && (
                        <ActionsWrapperResultPage>
                          {' '}
                          {document.document_type.id === 13 && (
                            <div className="action-button" onClick={() => fireSignedDocUpload(document.document_type_id)}>
                              <img src={uploadIcon} alt="" width={25} />
                              <input ref={uploadPolizzaEmessa} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                            </div>
                          )}
                          {document.document_type.id === 32 && (
                            <div className="action-button" onClick={() => fireSignedDocUpload(document.document_type_id)}>
                              <img src={uploadIcon} alt="" width={25} />
                              <input ref={uploadQuietanzaSemestrale} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                            </div>
                          )}
                          {document.document_type.id === 29
                            && (
                              <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input ref={uploadPreContractRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                              </div>
                            )}
                          {document.document_type.id === 28
                            && (
                              <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input ref={uploadDichiarazioniDocRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                              </div>
                            )}
                          {document.document_type.id === 30
                            && (
                              <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input ref={uploadQuestionarioAutoDocRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                              </div>
                            )}
                          {document.document_type.id === 34
                            && (
                              <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input ref={uploadMergedDocFileRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                              </div>
                            )}
                          {document.optional_document_request_id
                            && (
                              <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id, document.id) : () => { }}>
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input ref={optionalRefs[document.id]} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedOptionalDocument(document.id, e.target.files)} />

                              </div>
                            )}
                          {document.document_type.id === 12
                            && (
                              <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input ref={uploadPrivacyRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                              </div>
                            )}
                        </ActionsWrapperResultPage>
                      )}
                    </div>
                    {(document.signable_document === 1 && document.is_signed_valid === 0 && document.signed_document_filename === null && document.motivation !== null) && (
                      <p className="text-danger mt-2">
                        {document.motivation}
                      </p>
                    )}
                  </div>
                  <div>
                    {document.signable_document === 1 ? (
                      document.is_signed_valid === null && document.signed_document_filename !== null ? (
                        <span className="badge bg-warning rounded-pill position-absolute top-25 end-0 me-2">In Verifica</span>
                      ) : document.is_signed_valid === 0 ? (
                        <span className="badge bg-danger rounded-pill position-absolute top-25 end-0 me-2">Firma Non Valida</span>
                      ) : document.is_signed_valid === null && document.signed_document_filename === null ? (
                        <span className="badge bg-warning rounded-pill position-absolute top-25 end-0 me-2">Da Firmare</span>
                      ) : document.is_signed_valid === 1 && document.signed_document_filename !== null ? (
                        <span className="badge bg-primary rounded-pill position-absolute top-25 end-0 me-2">Firmato</span>
                      ) : null
                    ) : (
                      <span className="badge bg-danger rounded-pill position-absolute top-25 end-0 me-2">Non Richiede Firma</span>
                    )}
                    {' '}

                  </div>
                </li>
              ))}
              {quote.documents.map((document, index) => (
                <li
                  className="list-group-item d-flex justify-content-between align-items-start"
                  key={index}
                >
                  <div className="ms-2 me-auto">
                    <div className="fw-bold mb-2">
                      {document.document_type.name}
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <img
                        src={pdfIMG}
                        className="img-fluid"
                        width="25"
                        alt="PDF"
                      />
                      <div className="mx-2">
                        <h5 className="h6 mb-0">
                          {document.original_filename}
                        </h5>
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <button
                          className="doc-btn border-0 bg-transparent"
                          onClick={() => downloadDocument(document.id)}
                        >
                          <img src={downloadIMG} width="25" alt="" />
                        </button>
                      </div>
                      {(document.signable_document === 1 && document.is_signed_valid !== 1 && document.signed_document_filename !== null) && (
                        <ActionsWrapperResultPage>
                          {' '}
                          {document.document_type.id === 13 && (
                            <div className="action-button" onClick={() => fireSignedDocUpload(document.document_type_id)}>
                              <img src={uploadIcon} alt="" width={25} />
                              <input ref={uploadPolizzaEmessa} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                            </div>
                          )}
                          {document.document_type.id === 32 && (
                            <div className="action-button" onClick={() => fireSignedDocUpload(document.document_type_id)}>
                              <img src={uploadIcon} alt="" width={25} />
                              <input ref={uploadQuietanzaSemestrale} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                            </div>
                          )}
                          {document.document_type.id === 29
                            && (
                              <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input ref={uploadPreContractRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                              </div>
                            )}
                          {document.document_type.id === 28
                            && (
                              <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input ref={uploadDichiarazioniDocRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                              </div>
                            )}
                          {document.document_type.id === 30
                            && (
                              <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input ref={uploadQuestionarioAutoDocRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                              </div>
                            )}
                          {document.optional_document_request_id
                            && (
                              <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id, document.id) : () => { }}>
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input ref={optionalRefs[document.id]} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedOptionalDocument(document.id, e.target.files)} />

                              </div>
                            )}
                          {document.document_type.id === 12
                            && (
                              <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input ref={uploadPrivacyRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                              </div>
                            )}
                        </ActionsWrapperResultPage>
                      )}
                    </div>
                    {(document.signable_document === 1 && document.is_signed_valid === 0 && document.signed_document_filename === null && document.motivation !== null) && (
                      <p className="text-danger mt-2">
                        {document.motivation}
                      </p>
                    )}
                  </div>
                  <div>
                    {document.signable_document === 1
                      && document.is_signed_valid ? (
                        <span className="badge bg-primary rounded-pill me-1">
                          Firmato
                        </span>
                      ) : (
                        <span className="badge bg-danger rounded-pill me-1">
                          Non Richiede Firma
                        </span>
                      )}
                  </div>
                </li>
              ))}

              {documentsRequests.map((dr, index) => (
                <li
                  className="list-group-item d-flex justify-content-between align-items-start"
                  key={index}
                >
                  <div className="ms-2 me-auto">
                    <div className="fw-bold mb-2">
                      {dr.optional_document_type.name}
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <img
                        src={pdfIMG}
                        className="img-fluid"
                        width="25"
                        alt="PDF"
                      />
                      <div className="mx-2">
                        <h5 className="h6 mb-0">
                          {dr.document.original_filename}
                        </h5>
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <button
                          className="doc-btn border-0 bg-transparent"
                          onClick={() => downloadOptionalDocument(dr.document.id)}
                        >
                          <img src={downloadIMG} width="25" alt="" />
                        </button>
                      </div>
                      {(document.signable_document === 1 && document.is_signed_valid !== 1 && document.signed_document_filename !== null) && (
                        <ActionsWrapperResultPage>
                          {' '}
                          {document.document_type.id === 13 && (
                            <div className="d-flex flex-column">
                              <div className="action-button" onClick={() => fireSignedDocUpload(document.document_type_id)}>
                                <img src={uploadIcon} alt="" width={25} />
                                <input ref={uploadPolizzaEmessa} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                              </div>

                            </div>
                          )}
                          {document.document_type.id === 32 && (
                            <div className="action-button" onClick={() => fireSignedDocUpload(document.document_type_id)}>
                              <img src={uploadIcon} alt="" width={25} />
                              <input ref={uploadQuietanzaSemestrale} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                            </div>
                          )}
                          {document.document_type.id === 29
                            && (
                              <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input ref={uploadPreContractRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                              </div>
                            )}
                          {document.document_type.id === 28
                            && (
                              <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input ref={uploadDichiarazioniDocRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                              </div>
                            )}
                          {document.document_type.id === 30
                            && (
                              <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input ref={uploadQuestionarioAutoDocRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                              </div>
                            )}
                          {document.optional_document_request_id
                            && (
                              <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id, document.id) : () => { }}>
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input ref={optionalRefs[document.id]} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedOptionalDocument(document.id, e.target.files)} />

                              </div>
                            )}
                          {document.document_type.id === 12
                            && (
                              <div className="action-button" onClick={document.signed_document_filename === null ? () => fireSignedDocUpload(document.document_type.id) : () => { }}>
                                <img src={uploadIcon} alt="Carica" width={25} />
                                <input ref={uploadPrivacyRef} type="file" name="" id="" className="d-none" onChange={(e) => uploadSignedDocument(document.id, e.target.files)} />

                              </div>
                            )}
                        </ActionsWrapperResultPage>
                      )}
                    </div>
                    {(document.signable_document === 1 && document.is_signed_valid === 0 && document.signed_document_filename === null && document.motivation !== null) && (
                      <p className="text-danger mt-2">
                        {document.motivation}
                      </p>
                    )}
                  </div>
                  <div>
                    {document.signable_document === 1 ? (
                      document.is_signed_valid === null && document.signed_document_filename !== null ? (
                        <span className="badge bg-warning rounded-pill position-absolute top-25 end-0 me-2">In Verifica</span>
                      ) : document.is_signed_valid === 0 ? (
                        <span className="badge bg-danger rounded-pill position-absolute top-25 end-0 me-2">Firma Non Valida</span>
                      ) : document.is_signed_valid === null && document.signed_document_filename === null ? (
                        <span className="badge bg-warning rounded-pill position-absolute top-25 end-0 me-2">Da Firmare</span>
                      ) : document.is_signed_valid === 1 && document.signed_document_filename !== null ? (
                        <span className="badge bg-primary rounded-pill position-absolute top-25 end-0 me-2">Firmato</span>
                      ) : null
                    ) : (
                      <span className="badge bg-danger rounded-pill position-absolute top-25 end-0 me-2">Non Richiede Firma</span>
                    )}
                    {' '}

                  </div>
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompletedMotoQuoteScreen;
