import styled from 'styled-components';

export const FileWrapperStyles = styled.div`
    /* .uploaded-files-wrapper {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 0.5fr)) !important;
    }

    @media (min-width: 1801px) {
        .uploaded-files-wrapper {
            grid-template-columns: repeat(4, minmax(0, 0.99fr)) !important;
        }
    }

    @media (min-width: 1200px) and (max-width: 1800px) {
        .uploaded-files-wrapper {
            grid-template-columns: repeat(3, minmax(0, 0.99fr)) !important;
        }
    }

    @media (max-width: 600px) {
        .uploaded-files-wrapper {
            grid-template-columns: repeat(2, minmax(0, 0.5fr)) !important;
        }
    } */
`;
