import { markQuotationForProccessing } from '../../../features/insuranceRequests/insuranceRequestsActions';
import { SaveButton } from '../Purchase.styles';

export function SaveBox({
  insuranceRequest,
  quotationId,
  refresh,
}) {
  const caller = () => {
    markQuotationForProccessing(quotationId, refresh);
  };

  if (insuranceRequest.processing_quotation_id === quotationId) {
    return null;
  }

  return (
    <SaveButton onClick={caller}>Salva il preventivo</SaveButton>
  );
}
