import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { generalAlertError } from '../../common/utils/alerts';
import { backOfficeAxe } from '../../services/axios-client/backofficeClient';
import StorageService from '../../services/StorageService';
import PageLoading from '../../common/components/elements/PageLoading';

const storage = new StorageService();

export default function AuthenticateBackOffice() {
  const navigate = useNavigate();
  const { backOfficeAccessToken, requestToken, resultpage } = useParams();

  const authenticateBackOffice = async () => {
    const response = await backOfficeAxe.post('/login', {
      backoffice_access_token: backOfficeAccessToken,
      request_token: requestToken,
    });

    if (response.status === 401) {
      generalAlertError('Token non valido');
      return;
    }

    if (response.status !== 200) {
      generalAlertError('Errore interno');
      return;
    }

    const { access_token, form } = response.data.data;

    storage.setBackOfficeToken(access_token);
    if (resultpage !== undefined && resultpage == 1) navigate(`/backoffice/quote/${requestToken}/${form}/results`);
    else navigate(`/backoffice/quote/${requestToken}/${form}`);
  };

  useEffect(() => {
    authenticateBackOffice();
  }, []);

  return <PageLoading />;
}
