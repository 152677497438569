import { createSlice } from '@reduxjs/toolkit';

import { callerCreator } from '../callerCreator';

const initialState = {
  metlife_quotes: {
    loading: true,
    error: null,
    quoteId: null,
    quote: {
      request_id: '',
      pack_type: '',
      company: '',
      plan: '',
      name: '',
      dc: '',
      ip: '',
      diaria_ricovero: '',
      diaria_gesso: '',
      premio_annuo: '',
      ulc: '',
      ulc_premio_aggiuntivo: '',
      rsm: '',
      rsm_premio_aggiuntivo: '',
    },
    customer: {
      name: '',
      surname: '',
      gender: '',
      fiscal_code: '',
      phone: '',
      email: '',
      date_of_birth: '',
      country_of_birth_code: '',
      province_of_birth_code: '',
      commune_of_birth_code: '',
      born_abroad: 0,
      residence_province_code: '',
      residence_commune_code: '',
      postal_code: '',
      address: '',
      house_number: '',
      civil_status_id: 1,
      education_level_id: 4,
      profession_id: 13,
    },
    extensions: [],
    isSaving: false,
    saved: false,
    insuranceRequest: null,
  },
  auto_quotes: {
    loading: true,
    requestInfo: null,
    quotes: null,
    failedQuotes: null,
    noQuotes: false,
    coverages: [],
    sortBy: 'amount',
    preventivas_quotes: [],
    related_quotations: [],
    markupPercentage: 0,
    quotations: null,
    addingQuote: false,
    processingQuote: null,
    tutelaLegaleQuote: null,
    assistenzaStradaleQuote: null,
    infortuniConducenteQuote: null,
    cristalliQuote: null,
    rivalsaQuote: null,
  },
  profession_quotes: {
    loading: true,
    displayQuotes: null,
    extensions: null,
    userData: null,
    profession: null,
    isSaving: false,
    saved: false,
    quoteId: null,
    selling_point: 0,
    distance_token: null,
    quotations: null,
    addingQuote: false,
    processingQuote: null,
    tutelaLegaleQuote: null,
  },
  infodrive_quotes: {
    loading: true,
    insuranceRequest: null,
    quote: null,
    error: null,
    markupPercentage: 0,
    isSaving: false,
    saved: false,
    failedQuotation: null,
  },
  travel_quotes: {
    loading: true,
    insuranceRequest: null,
    quotations: [],
    error: null,
    failedQuotations: [],
    customer: null,
    quotes: null,
    addingQuote: false,
    processingQuote: null,
    tutelaLegaleQuote: null,
  },
  moto_quotes: {
    loading: true,
    requestInfo: null,
    quotes: null,
    failedQuotes: null,
    noQuotes: false,
    coverages: [],
    sortBy: 'amount',
    preventivas_quotes: [],
    related_quotations: [],
    markupPercentage: 0,
    quotations: null,
    addingQuote: false,
    processingQuote: null,
    tutelaLegaleQuote: null,
    assistenzaStradaleQuote: null,
    infortuniConducenteQuote: null,
    cristalliQuote: null,
    rivalsaQuote: null,
  },
};

export const backofficeResultsSlice = createSlice({
  name: 'backofficeResults',
  initialState,
  reducers: {
    metlifeQuotesDataUpdated: (state, { payload }) => {
      state.metlife_quotes = { ...state.metlife_quotes, ...payload };
    },
    infortuniQuotesUpdateExtensions: (state, { payload }) => {
      const { extensions } = state.metlife_quotes;
      let newExtensions = [];

      if (extensions.includes(payload)) {
        newExtensions = extensions.filter((extension) => extension !== payload);
      } else {
        newExtensions = [...extensions, payload];
      }
      state.metlife_quotes.extensions = newExtensions;
    },
    infortuniQuotesReset: (state) => {
      state.metlife_quotes = { ...initialState.metlife_quotes };
    },
    autoQuotesRequestInfoUpdated: (state, { payload }) => {
      state.auto_quotes.requestInfo = {
        ...payload.quotations[0],
      };
      state.auto_quotes.loading = false;
      state.auto_quotes.related_quotations = payload.related_quotations;
      state.auto_quotes.processingQuote = payload.quotations[0].processingQuote;
      state.auto_quotes.tutelaLegaleQuote = payload.quotations[0].tutelaLegaleQuote;
      state.auto_quotes.quotations = payload.quotations[0].quotes;
      state.auto_quotes.assistenzaStradaleQuote = payload.quotations[0].assistenzaStradaleQuote;
      state.auto_quotes.infortuniConducenteQuote = payload.quotations[0].infortuniConducenteQuote;
      state.auto_quotes.cristalliQuote = payload.quotations[0].cristalliQuote;
      state.auto_quotes.rivalsaQuote = payload.quotations[0].rivalsaQuote;
    },
    autoQuotesDataUpdated: (state, { payload }) => {
      state.auto_quotes = { ...state.auto_quotes, ...payload };
    },
    autoQuotesToggleCoverages: (state, { payload }) => {
      const coverageId = payload;
      const { coverages } = state.auto_quotes;
      let newCoverages = [];
      if (coverages.find((c) => c === coverageId) === undefined) {
        newCoverages = [...coverages, coverageId];
      } else {
        newCoverages = coverages.filter((c) => c !== coverageId);
      }
      state.auto_quotes.coverages = newCoverages;
    },
    changeQuotationAquistaLoading: (state, { payload }) => {
      state.auto_quotes.quotationAquistaLoading = payload;
    },
    autoQuotesReset: (state) => {
      state.auto_quotes = { ...initialState.auto_quotes };
    },

    professionQuotesDataUpdated: (state, { payload }) => {
      state.profession_quotes = { ...state.profession_quotes, ...payload };
    },
    professionQuotesReset: (state) => {
      state.profession_quotes = { ...initialState.profession_quotes };
    },
    infodriveQuotesDataUpdated: (state, { payload }) => {
      state.infodrive_quotes = { ...state.infodrive_quotes, ...payload };
    },
    infodriveQuotesReset: (state) => {
      state.infodrive_quotes = { ...state.infodrive_quotes };
    },

    travelQuotesDataUpdated: (state, { payload }) => {
      state.travel_quotes = { ...state.travel_quotes, ...payload };
    },
    travelQuotesReset: (state) => {
      state.travel_quotes = { ...initialState.travel_quotes };
    },
    travelQuotesInfoDataUpdate: (state, { payload }) => {
      state.travel_quotes.insuranceRequest = payload.insuranceRequest;
      state.travel_quotes.quotations = payload.quotations;
      state.travel_quotes.failedQuotations = payload.failed_quotations;
      state.travel_quotes.customer = payload.customer;
    },
    motoQuotesRequestInfoUpdated: (state, { payload }) => {
      state.moto_quotes.requestInfo = {
        ...payload.quotations[0],
      };
      state.moto_quotes.loading = false;
      state.moto_quotes.related_quotations = payload.related_quotations;
      state.moto_quotes.quotations = payload.quotations[0].quotes;
      state.moto_quotes.processingQuote = payload.quotations[0].processingQuote;
      state.moto_quotes.tutelaLegaleQuote = payload.quotations[0].tutelaLegaleQuote;
      state.moto_quotes.assistenzaStradaleQuote = payload.quotations[0].assistenzaStradaleQuote;
      state.moto_quotes.infortuniConducenteQuote = payload.quotations[0].infortuniConducenteQuote;
      state.moto_quotes.cristalliQuote = payload.quotations[0].cristalliQuote;
      state.moto_quotes.rivalsaQuote = payload.quotations[0].rivalsaQuote;
    },
    motoQuotesDataUpdated: (state, { payload }) => {
      state.moto_quotes = { ...state.moto_quotes, ...payload };
    },
    motoQuotesToggleCoverages: (state, { payload }) => {
      const coverageId = payload;
      const { coverages } = state.moto_quotes;
      let newCoverages = [];
      if (coverages.find((c) => c === coverageId) === undefined) {
        newCoverages = [...coverages, coverageId];
      } else {
        newCoverages = coverages.filter((c) => c !== coverageId);
      }
      state.moto_quotes.coverages = newCoverages;
    },
    motoQuotesReset: (state) => {
      state.moto_quotes = { ...initialState.moto_quotes };
    },
  },
});

export default callerCreator(backofficeResultsSlice.actions);
