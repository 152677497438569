import React from 'react';
import moment from 'moment';
import {
  InfoBoxContainer,
  InfoItem,
  TitleActionBox,
  VehicleInfoCardStyles,
} from './VehicleInfoCard.styles';
import { ModificaDatiButton } from '../common/Redesign.styles';
import editIcon from '../../../../assets/redesign_icons/edit-icon.svg';

function MotoInfoCard({ insuranceRequest, modificaDati }) {
  return (
    <VehicleInfoCardStyles>
      <TitleActionBox>
        <h4>
          Informazioni
          {' '}
          <b>moto</b>
        </h4>
        <ModificaDatiButton onClick={modificaDati}>
          <p>Modifica Dati</p>
          <img src={editIcon} alt="Modifica Dati" />
        </ModificaDatiButton>
      </TitleActionBox>
      <InfoBoxContainer>
        <InfoItem>
          <h4>Contraente:</h4>
          <p>
            {insuranceRequest.customer.gender !== 'G'
              ? `${insuranceRequest.customer.name
              } ${
                insuranceRequest.customer.surname}`
              : insuranceRequest.customer.business_name}
          </p>
        </InfoItem>
        <InfoItem>
          <h4>Marca:</h4>
          <p>{insuranceRequest.moto.brand.name}</p>
        </InfoItem>
        <InfoItem>
          <h4>Modello:</h4>
          <p>{insuranceRequest.moto.model.name}</p>
        </InfoItem>
        <InfoItem>
          <h4>Targa:</h4>
          <p>{insuranceRequest.moto.moto_plate}</p>
        </InfoItem>
        <InfoItem>
          <h4>Inizio Copertura:</h4>
          <p>
            {moment(insuranceRequest.policy_effective_date).format(
              'DD-MM-YYYY',
            )}
          </p>
        </InfoItem>
      </InfoBoxContainer>
    </VehicleInfoCardStyles>
  );
}

export default MotoInfoCard;
