import styled from 'styled-components';

export const PurchaseStyles = styled.div`
  position: relative;
  /* CSS */
  .steps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    position: relative;
  }

  .step-button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    background-color: var(--prm-gray);
    transition: 0.4s;
  }

  .step-button[aria-expanded="true"] {
    width: 60px;
    height: 60px;
    background-color: var(--greenia-color);
    color: #fff;
  }

  .done {
    background-color: var(--greenia-color);
    color: #fff;
  }

  .step-item {
    z-index: 10;
    text-align: center;
  }

  #progress {
    -webkit-appearance: none;
    position: absolute;
    width: 98%;
    z-index: 5;
    height: 10px;
    margin-left: 5px;
  }

  @media only screen and (max-width: 500px) {
    .steps {
      margin-bottom: 20px;
    }

    .step-item {
      font-size: 10px;
    }

    .step-button {
      height: 35px;
      width: 35px;
    }

    .step-button[aria-expanded="true"] {
      width: 50px;
      height: 50px;
      background-color: var(--greenia-color);
      color: #fff;
    }

    .step-title {
      font-size: 10px;
      text-align: center;
      flex: 1;
      margin-top: 5px;
    }
  }

  /*@media only screen and (max-width: )*/

  /* to customize progress bar */
  #progress::-webkit-progress-value {
    background-color: var(--greenia-color);
    transition: 0.5s ease;
  }

  #progress::-webkit-progress-bar {
    background-color: var(--prm-gray);
  }

  .support-box {
    max-width: 100px;
    width: 50px;
    max-height: 100px;
    height: 50px;
  }

  .row-flex {
    display: flex;
    flex-wrap: wrap;
  }

  .content {
    height: 100%;
    padding: 20px 20px 10px;
    color: #fff;
  }

  .doc-btn {
    margin: 0 5px;
    transition: all 250ms ease-in-out;
  }

  .doc-btn:hover {
    transform: scale(1.03);
  }

  .bg-secondary,
  .bg-primary {
    background-color: #00a651 !important;
  }

  .btn-outline-primary {
    --bs-btn-color: var(--greenia-color);
    --bs-btn-border-color: var(--greenia-color);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--greenia-color);
    --bs-btn-hover-border-color: var(--greenia-color);
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--greenia-color);
    --bs-btn-active-border-color: var(--greenia-color);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: var(--greenia-color);
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: var(--greenia-color);
    --bs-gradient: none;
  }

  .btn-outline-primary:focus {
    box-shadow: 0 0 0 0.25rem rgba(0, 166, 81, 0.5);
  }

  /* New Input Added */

  .upload_dropZone {
    color: #0f3c4b;
    background-color: var(--colorPrimaryPale, #c8dadf);
    outline: 2px dashed var(--colorPrimaryHalf, #c1ddef);
    outline-offset: -12px;
    transition: outline-offset 0.2s ease-out, outline-color 0.3s ease-in-out,
      background-color 0.2s ease-out;
  }
  .upload_dropZone.highlight {
    outline-offset: -4px;
    outline-color: var(--colorPrimaryNormal, #0576bd);
    background-color: var(--colorPrimaryEighth, #c8dadf);
  }
  .upload_svg {
    fill: var(--colorPrimaryNormal, #0576bd);
  }
  .btn-upload {
    color: #fff;
    background-color: var(--colorPrimaryNormal);
  }
  .btn-upload:hover,
  .btn-upload:focus {
    color: #fff;
    background-color: var(--colorPrimaryGlare);
  }
  .upload_img {
    width: calc(33.333% - (2rem / 3));
    object-fit: contain;
  }

  #back-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    color: var(--greenia-color);
    font-size: 20px;
    border: 1px solid var(--greenia-color);
    border-radius: 100%;
    cursor: pointer;
  }

  .alert.alert-info {
    border-color: #00a651;
    background-color: #00c05d;
    color: white;
  }
`;

export const UploadDocumentsContainer = styled.div`
  border-radius: 30px;
  border: 2px solid #dbdde6;
  background: #fff;
  padding: 20px;

  h2 {
    color: var(--common-black, #1a1921);
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

export const UploadWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  margin-top: 10px;

  @media (max-width: 530px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: 531px) and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1201px) and (max-width: 1400px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const OptionalDocumentBox = styled.div`
  height: 200px !important;
  max-width: 300px;
  background-color: #fff;
  border: 2px dashed rgba(130, 130, 130, 0.3);
  border-radius: 15px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    transform: scale(0.7);
  }

  button {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #99dbb9;
    border: 1px solid #99dbb9;
    outline: none;
    box-shadow: none;
    text-wrap: wrap;
    font-size: 14px;
    width: 70%;
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    margin-top: 5px;
  }

  @media (max-width: 1200px) {
    img {
      transform: scale(0.5);
    }

    button {
      font-size: 12px;
      line-height: 14px;
      margin-top: 0px;
      width: 85%;
    }
  }
`;

export const BadgeOptional = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 10px;
  background: #dadada;
  color: #1a1921;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-block;
  padding: 6px 12px;

  @media (max-width: 1200px) {
    padding: 4px 8px;
    font-size: 10px;
    border-radius: 8px;
    top: 5px;
    right: 5px;
  }
`;

export const DocumentPlaceholder = styled.div`
  height: 200px !important;
  max-width: 300px;
  background-color: #fff;
  border: 2px dashed rgba(130, 130, 130, 0.3);
  border-radius: 15px;
`;

export const UploadedDocumentWrapper = styled.div`
  height: 200px !important;
  max-width: 300px;
  background-color: #fff;
  border: 2px solid rgba(130, 130, 130, 0.3);
  border-radius: 15px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;

  img {
    margin-bottom: 10px;
    margin-top: 25px;
  }

  p {
    color: #d5d5d5;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
    cursor: pointer;
  }

  h3 {
    color: #99dbb9;
    font-family: Poppins;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
    margin-left: 5px;
  }

  @media (max-width: 1200px) {
    h3 {
      font-size: 14px;
      text-align: center;
      padding: 5px;
    }

    p {
      font-size: 12px;
      padding: 5px;
    }

    img {
      width: 35px;
    }
  }
`;

export const BadgeStatus = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 10px;
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-block;
  padding: 6px 12px;

  @media (max-width: 1200px) {
    padding: 4px 8px;
    font-size: 10px;
    border-radius: 8px;
    top: 5px;
    right: 5px;
  }
`;

export const RemoveDocument = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 26px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
`;

export const RedirectToPrimaAlert = styled.p`
  padding: 10px;
  background-color: rgb(0, 166, 81);
  color: #ffffff;
  margin: 20px 0;
  border-radius: 10px;
  font-family: Poppins;
  font-weight: 600;
`;

export const SaveButton = styled.div`
    width: 100%;
    max-width: 200px;
    text-align: center;
    margin: 10px;
    padding: 8px 30px;
    border-radius: 15px;
    border: 1px solid var(--greenia-color);
    color: var(--greenia-color);
    cursor: pointer;
    transition: .3s;

    &:hover {
       background-color: rgba(100,200,100, .3);
    }
`;
