import { createSlice } from '@reduxjs/toolkit';

import { callerCreator } from '../callerCreator';

const initialState = {
  add_new_accident: {
    selectedCustomer: null,
    customerQuotes: [],
    selectedQuote: null,
    searchData: [],
    customers: [],
    page_number: 1,
    total_pages: 1,
    error: null,
    loading: true,
  },
  accidents_list: {
    loading: true,
    accidents: [],
    page_number: 1,
    total_pages: 1,
    error: null,
  },
  accident_show: {
    loading: true,
    accident: null,
    accidentFiles: [],
  },
};

export const accidentsSlice = createSlice({
  name: 'accidents',
  initialState,
  reducers: {
    setSelectedCustomer: (state, { payload }) => {
      state.add_new_accident.selectedCustomer = payload;
      state.add_new_accident.searchData = [];
    },
    setSelectedQuote: (state, { payload }) => {
      state.add_new_accident.selectedQuote = payload;
    },
    listCustomerQuotesSuccess: (state, { payload }) => {
      state.add_new_accident.customerQuotes = payload;
    },
    listCustomerQuotesPending: (state) => {
      state.add_new_accident.error = null;
      state.add_new_accident.loading = true;
    },
    listCustomerQuotesRejected: (state, { payload }) => {
      state.add_new_accident.error = payload;
      state.add_new_accident.loading = false;
    },
    listCustomerInsurancesSuccess: (state, { payload }) => {
      state.add_new_accident.customers = payload.data;
      state.add_new_accident.page_number = payload.current_page;
      state.add_new_accident.total_pages = payload.total_pages;
      state.add_new_accident.loading = false;
    },
    addNewAccidentReset: (state) => {
      state.add_new_accident = { ...initialState.add_new_accident };
    },

    listAccidentsPending: (state) => {
      state.accidents_list.loading = true;
      state.accidents_list.error = null;
    },
    listAccidentsSuccess: (state, { payload }) => {
      state.accidents_list.loading = false;
      state.accidents_list.accidents = payload.data;
      state.accidents_list.page_number = payload.current_page;
      state.accidents_list.total_pages = payload.total_pages;
    },
    listAccidentsRejected: (state, { payload }) => {
      state.accidents_list.loading = false;
      state.accidents_list.error = payload;
    },
    listAccidentsReset: (state) => {
      state.accidents_list = { ...initialState.accidents_list };
    },
    showAccidentPending: (state) => {
      state.accident_show.loading = true;
      state.accident_show.error = null;
    },
    showAccidentSuccess: (state, { payload: { accident, accident_files } }) => {
      state.accident_show.loading = false;
      state.accident_show.accident = accident;
      state.accident_show.accidentFiles = accident_files;
    },
    showAccidentRejected: (state, { payload }) => {
      state.accident_show.loading = false;
      state.accident_show.error = payload;
    },
    showAccidentReset: (state) => {
      state.accident_show = { ...initialState.accident_show };
    },
    accidentAddNote: (state) => {
      state.accident_show = { ...initialState.accident_show };
    },
  },
});

export default callerCreator(accidentsSlice.actions);
