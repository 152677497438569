import React from 'react';
import { FormInputText } from '../../../common/formInputs/FormInputText';

function CompanyIndicators({ commertial_report, errors, isValid }) {
  return (
    <div className="tab-pane fade" id="commercial_company_indicators">
      <h4 className="text-center mt-3">Commercial Company Indicators</h4>
      {commertial_report.commercial_company_indicators.map((indicator, index) => (
        <div className="card border mt-5" key={index}>
          <div className="card-body mb-4">
            <div className="row">
              <div className="col-12">

                <FormInputText
                  placeholder="Description"
                  label="Description"
                  value={indicator.description}
                  error={errors.description?.message}
                  valid={isValid('description')}
                />
                <FormInputText
                  placeholder="Value Description"
                  label="Value Description"
                  value={indicator.value_description}
                  error={errors.value_description?.message}
                  valid={isValid('value_description')}
                />

                <p className="fw-bold text-center">Categories</p>
                <div className="row">
                  {indicator.categories.map((category, index) => (
                    <div className="col-12 col-md-6 mt-3" key={index}>
                      <div className="card border">
                        <div className="card-body">

                          <FormInputText
                            placeholder="Description"
                            label="Description"
                            value={category.description}
                            error={errors.description?.message}
                            valid={isValid('description')}
                          />
                          <FormInputText
                            placeholder="Value Description"
                            label="Value Description"
                            value={category.value_description}
                            error={errors.value_description?.message}
                            valid={isValid('value_description')}
                          />
                        </div>

                      </div>
                    </div>
                  ))}

                </div>

              </div>
            </div>
          </div>
        </div>
      ))}

    </div>
  );
}

export default CompanyIndicators;
