import { generalAlertError } from '../../common/utils/alerts';
import { chatAxe } from '../../services/axios-client/chatAxeClient';
import managementAxe from '../../services/axios-client/managementServisAxeClient';
import caller from './commonCustomersSlice';

export const getCustomerInfoForContact = async (type, customerId) => {
  try {
    caller.contactCustomerPending();
    const response = await managementAxe.get(
      `/broker/customers/common/${type}/${customerId}`,
    );

    if (response.status !== 200) {
      caller.contactCustomerRejected('An error has occured');
      return;
    }

    caller.contactCustomerSuccess(response.data.customer);
  } catch (err) {
    caller.contactCustomerRejected('An error has occured');
  }
};

export const contactCustomer = async (type, customerId, data, callback) => {
  try {
    const response = await managementAxe.post(`/broker/customers/common/${type}/${customerId}/contact`, data);

    if (response.status !== 200) {
      if (response.status === 400 && response.data.error === 'invalid_phone_number') {
        generalAlertError('Il numero non e valido');
        return;
      }

      generalAlertError('An error has occured');
      return;
    }

    callback();
  } catch (err) {
    generalAlertError('An error has occured');
  }
};

export const generateContactSuggestion = async (message) => {
  try {
    if (message === '') {
      generalAlertError('Devi fornire un messagio');
      return;
    }

    caller.suggestMessageUpdate({
      loadingSuggestion: true,
      suggestion: null,
    });

    const response = await chatAxe.post('/chat/broker/customers/contact', { message });

    caller.suggestMessageUpdate({
      loadingSuggestion: false,
    });

    if (response.status !== 200) {
      generalAlertError('An error has occured');
      return;
    }

    caller.suggestMessageUpdate({
      suggestion: response.data.suggestion,
    });
  } catch (err) {
    generalAlertError('An error has occured');
  }
};

export const clearSuggestion = () => caller.suggestMessageUpdate({
  suggestion: null,
});
