import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function NotFound() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/');
  }, []);

  return <p className="text-center"><strong>Oops, it seems like your are lost, you are being redirected</strong></p>;
}
