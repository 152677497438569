import React from 'react';
import { useNavigate } from 'react-router-dom';
import { WidgetStyles } from './Widgets.styles';

function MakeAQuoteWidget() {
  const navigate = useNavigate();

  return (
    <WidgetStyles id="client-search" className="custom-card h-100">
      <div className="custom-card-header">
        <div className="custom-card-section">
          <h3>Fai Un Preventivo</h3>
        </div>
      </div>
      <div
        className="card card-body"
        style={{ position: 'relative', overflowY: 'visible' }}
      >
        <div className="custom-card-section">
          <div className="row">
            <div className="col-12 col-md-12">
              <h5 className="my-2 text-center mt-4">
                Vai alle offerte
              </h5>
              <div
                className="new-quotation-button w-75 mx-auto"
                onClick={() => navigate('/quote')}
              >
                FAI UN PREVENTIVO
              </div>
            </div>
          </div>
        </div>
      </div>
    </WidgetStyles>
  );
}

export default MakeAQuoteWidget;
