import { HeaderStyles, LayoutStyles } from './Layout.styles';

function Header() {
  return (
    <HeaderStyles>
      Greenia
    </HeaderStyles>
  );
}

export function Layout({ children }) {
  return (
    <LayoutStyles>
      <Header />

      {children}
    </LayoutStyles>
  );
}
