import styled from 'styled-components';

export const LoginStyles = styled.div`
  .title-logo {
    color: var(--greenia-color);
    font-size: 38px;
    font-weight: bold;
    font-family: "Poppins";
  }

  .Auth-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: #fafafa;
  }

  .Auth-form {
    width: 420px;
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    padding: 30px 0 20px 0;
    border-radius: 8px;
    background-color: #ffffff;
  }

  .Auth-form-content {
    padding: 0 10%;
  }

  .Auth-form-title {
    text-align: center;
    margin: 1em 0;
    font-size: 24px;
    color: #8c8e90;
    font-weight: 500;
  }

  .btn-login {
    background-color: #00a551;
    border-color: #00a551;
  }

  .btn-login:hover {
    background-color: #00a451bd !important;
    border-color: #00a451bd !important;
  }
  .input-img {
    position: absolute;
    z-index: 999;
    top: 13px;
    left: 10px;
    width: 22px;
    height: auto;
  }
  div.input-group input.inpt {
    border: 2px solid #ddd;
    width: 100%;
    border-radius: 10px;
    padding-left: 40px;
  }

  .input-container {
    display: flex;
    width: 100%;
    margin-bottom: 15px;
  }

  .container-form {
    position: absolute;
    top: 50px;
    margin: 20px;
    padding: 16px;
  }

  @media only screen and (max-width: 500px) {
    .Auth-form {
      width: 390px;
      box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
      padding: 15px 0 15px 0;
      border-radius: 8px;
      background-color: #ffffff;
    }
  }
`;
