import managementAxe from '../../services/axios-client/managementServisAxeClient';
import caller from './utilitiesSlice';

export const unReadNotifications = async () => {
  const response = await managementAxe.get(
    '/broker/dashboard/notifications-count',
  );
  caller.notificationsSuccess(response.data);
};

export const getAllNotifications = async (page) => {
  caller.getNotificationsPending();
  try {
    const response = await managementAxe.get(
      '/broker/dashboard/notifications',
      {
        params: {
          page,
        },
      },
    );

    if (response.status !== 200) {
      caller.getNotificationsRejected('Errore Interno');
      return;
    }

    caller.getNotificationsSuccess(response.data);
  } catch (error) {
    caller.getNotificationsRejected(error);
  }
};

export const resetNotifications = async () => {
  caller.getNotificationsReset();
};
