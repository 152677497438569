import caller from './networkAnalisysSlice';
import axe from '../../services/axios-client/axiosClient';
import { generalAlertError } from '../../common/utils/alerts';

export const getNetworkAnalisys = async (search) => {
  caller.getNetworkAnalisys({
    loading: true,
  });
  const res = await axe.get(`/ivass/network-analysis/${search}`);

  if (res.status === 400) {
    caller.getNetworkAnalisys({
      loading: false,
    });
    generalAlertError('Numero Iscrizione Non Valido!');
    return;
  }

  if (res.status === 200) {
    caller.getNetworkAnalisys({
      loading: false,
      network_analisys: res.data.data,
    });
  }
};

export const resetNetworkAnalisys = () => {
  caller.resetNetworkAnalisys();
};

export const getCollaboratorsAnalisys = async (search) => {
  caller.getCollaboratorsAnalisys({
    loading: true,
  });

  const res = await axe.get(`/ivass/company-collaborations-analysis/${search}`);

  if (res.status === 400) {
    caller.getCollaboratorsAnalisys({
      loading: false,
    });
    generalAlertError('Numero Iscrizione Non Valido!');
    return;
  }

  if (res.status === 200) {
    caller.getCollaboratorsAnalisys({
      loading: false,
      company_collaborations: res.data.data,
    });
  }
};

export const resetCollaboratorsAnalisys = () => {
  caller.resetCollaboratorsAnalisys();
};

export const getComparativeAnalisys = async (search1, search2) => {
  caller.updateComparativeAnalisys({
    loading: true,
  });

  const res = await axe.get(
    `/ivass/comparative-analysis/${search1}/${search2}`,
  );

  if (res.status === 400) {
    caller.updateComparativeAnalisys({
      loading: false,
    });
    generalAlertError('Numero iscrizione non valida');
    return;
  }

  caller.updateComparativeAnalisys({
    loading: false,
    comparative_analisys: res.data.data,
  });
};

export const resetComparativeAnalisys = () => {
  caller.resetComparativeAnalisys();
};
