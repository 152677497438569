import styled from 'styled-components';

export const WidgetStyles = styled.div`
  min-height: 400px;

  .custom-pagination .swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    cursor: pointer;
    border-radius: 50%;
    background-color: gray;
  }

  .custom-pagination .swiper-pagination-bullet-active {
    background-color: var(--greenia-color);
  }
`;

export const ChatBotTextArea = styled.div`
   & textarea {
    &.form-control:focus {
      border-color: rgb(0, 166, 81);
      box-shadow: 0 0 0 0.25rem rgba(0, 166, 81,.25);
    }
   }
`;
