import { generalAlertError } from '../../common/utils/alerts';
import managementAxe from '../../services/axios-client/managementServisAxeClient';
import caller from './widgetsSlice';

export const getAllWidgets = async () => {
  caller.getWidgetsPending();

  try {
    const response = await managementAxe.get('/broker/widgets/get-all');

    caller.getWidgetsSuccess(response.data);
  } catch (error) {
    caller.getWidgetsRejected('Internal Error');
  }
};

export const changeOrder = async (data) => {
  caller.getWidgetsPending();
  try {
    const response = await managementAxe.post('/broker/widgets/reorder', data);

    if (response.status !== 200) {
      caller.getWidgetsRejected('Per favore riprova!');
      generalAlertError('Per favoer riprova!');
      return;
    }

    if (response.status === 200) {
      caller.getWidgetsSuccess(response.data);
    }
  } catch (error) {
    caller.getWidgetsRejected('Internal Error');
  }
};

export const removeWidget = async (widgetId) => {
  caller.getWidgetsPending();
  try {
    const response = await managementAxe.delete(
      `/broker/widgets/${widgetId}/delete`,
    );

    if (response.status !== 200) {
      caller.getWidgetsRejected('Per favore riprova!');
      generalAlertError('Per favoer riprova!');
      return;
    }

    if (response.status === 200) {
      caller.getWidgetsSuccess(response.data);
    }
  } catch (error) {
    caller.getWidgetsRejected('Internal Error');
  }
};

export const createWidget = async (widgetId) => {
  caller.getWidgetsPending();
  try {
    const response = await managementAxe.post(
      `/broker/widgets/${widgetId}/create`,
    );

    if (response.status !== 200) {
      caller.getWidgetsRejected('Per favore riprova!');
      generalAlertError('Non Puoi aggiungere piu di 4 widgets!');
      return;
    }

    if (response.status === 200) {
      caller.getWidgetsSuccess(response.data);
    }
  } catch (error) {
    caller.getWidgetsRejected('Internal Error');
  }
};
