import { useEffect } from 'react';

export default function useClickOutside(ref, actionHandler) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref?.current && !ref?.current.contains(event.target)) {
        actionHandler();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}
