import moment from 'moment';
import React from 'react';

function CompanyFinancialStatements({ commertial_report, dynamicHeaders, staticLabels }) {
  return (
    <div className="tab-pane fade" id="commercial_company_financial_statements">
      <h4 className="text-center mt-3">Commercial Company Financial Statements</h4>

      <div className="container mt-5">
        <table className="table table-bordered">
          <thead className="thead-dark">
            <tr>
              <th />
              {commertial_report.commercial_company_financial_statements.map((item, index) => (
                <th key={index}>{moment(item.year_end_date).format('Y')}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dynamicHeaders.map((header) => (
              header !== 'commercial_company_id'
                        && (
                        <tr key={header}>
                          <td>{staticLabels[header]}</td>
                          {commertial_report.commercial_company_financial_statements.map((item, index) => (
                            <td key={index}>{item[header]}</td>
                          ))}
                        </tr>
                        )
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CompanyFinancialStatements;
