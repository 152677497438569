import { createSlice } from '@reduxjs/toolkit';
import { callerCreator } from '../callerCreator';

const initialState = {
  tickets_list: {
    tickets: [],
    loading: true,
    error: null,
    isSearching: false,
    total_pages: 1,
    page_number: 1,
  },
  ticket_single: {
    ticket: null,
    messages: [],
    loading: true,
    error: null,
  },
};

export const ticketsSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    getTicketsPending: (state) => {
      state.tickets_list.loading = true;
      state.tickets_list.error = null;
    },
    getTicketsReject: (state, { payload }) => {
      state.tickets_list.loading = false;
      state.tickets_list.error = payload;
    },
    getTicketsSuccess: (state, { payload }) => {
      state.tickets_list.loading = false;
      state.tickets_list.tickets = payload.data;
      state.tickets_list.total_pages = payload.total_pages;
      state.tickets_list.page_number = payload.current_page;
    },

    searchTicketsPending: (state) => {
      state.tickets_list.isSearching = true;
      state.tickets_list.error = null;
    },
    searchTicketsReject: (state, { payload }) => {
      state.tickets_list.isSearching = false;
      state.tickets_list.error = payload;
    },
    searchTicketsSuccess: (state, { payload }) => {
      state.tickets_list.isSearching = false;
      state.tickets_list.tickets = payload.data;
      state.tickets_list.total_pages = payload.total_pages;
      state.tickets_list.page_number = payload.current_page;
    },

    getTicketPending: (state) => {
      state.ticket_single.loading = true;
      state.ticket_single.error = null;
    },
    getTicketReject: (state, { payload }) => {
      state.ticket_single.loading = false;
      state.ticket_single.error = payload;
    },
    getTicketSuccess: (state, { payload }) => {
      state.ticket_single.loading = false;
      state.ticket_single.ticket = payload;
      state.ticket_single.messages = payload.messages;
    },
  },
});

export default callerCreator(ticketsSlice.actions);
