import { createSlice } from '@reduxjs/toolkit';

import { callerCreator } from '../callerCreator';

const initialState = {
  contact_customer: {
    loading: true,
    error: null,
    customer: null,
    loadingSuggestion: false,
    suggestion: null,
  },
};
export const commonCustomersSlice = createSlice({
  name: 'commonCustomers',
  initialState,
  reducers: {
    contactCustomerPending: (state) => {
      state.contact_customer.loading = true;
      state.contact_customer.error = null;
    },
    contactCustomerRejected: (state, { payload }) => {
      state.contact_customer.loading = false;
      state.contact_customer.error = payload;
    },
    contactCustomerSuccess: (state, { payload }) => {
      state.contact_customer.loading = false;
      state.contact_customer.customer = payload;
    },
    suggestMessageUpdate: (state, { payload }) => {
      state.contact_customer = {
        ...state.contact_customer,
        ...payload,
      };
    },
  },
});

export default callerCreator(commonCustomersSlice.actions);
