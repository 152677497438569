import { createSlice } from '@reduxjs/toolkit';

import { callerCreator } from '../callerCreator';

const initialState = {
  report: {
    loading: true,
    error: null,
    commertial_report: null,
  },
  questionnaire: {
    loading: true,
    error: null,
    company: null,
  },
};

export const commercialReportSlice = createSlice({
  name: 'commercialReport',
  initialState,
  reducers: {
    getCommertialReportPending: (state) => {
      state.report.loading = true;
      state.report.error = null;
    },
    getCommertialReportRejected: (state, { payload }) => {
      state.report.loading = false;
      state.report.error = payload;
    },
    getCommertialReportSuccess: (
      state,
      { payload },
    ) => {
      state.report.loading = false;
      state.report.commertial_report = payload;
    },
    commertialReportReset: (state) => {
      state.report = { ...initialState.commertial_report };
    },
    getQuestionarioPending: (state) => {
      state.questionnaire.loading = true;
      state.questionnaire.error = null;
    },
    getQuestionarioRejected: (state, { payload }) => {
      state.questionnaire.loading = false;
      state.questionnaire.error = payload;
    },
    getQuestionarioSuccess: (
      state,
    ) => {
      state.questionnaire.loading = false;
    },
    getCompanyQuestionarioStatusPending: (state) => {
      state.questionnaire.loading = true;
      state.questionnaire.error = null;
    },
    getCompanyQuestionarioStatusRejected: (state, { payload }) => {
      state.questionnaire.loading = false;
      state.questionnaire.error = payload;
    },
    getCompanyQuestionarioStatusSuccess: (state, { payload }) => {
      state.questionnaire.loading = false;
      state.questionnaire.company = payload;
    },
  },
});

export default callerCreator(commercialReportSlice.actions);
