import React, { useEffect, useRef } from 'react';

export default function InputSelectTableMoto(props) {
  const tableRef = useRef();
  const selectedVehicleVersion = useRef(null);

  const scrollToParent = () => {
    const parent = tableRef.current;
    const parentRect = parent.getBoundingClientRect();
    const childRect = selectedVehicleVersion.current.getBoundingClientRect();

    parent.scrollLeft += (childRect.left - parentRect.left) - (parent.clientWidth / 2);
    parent.scrollTop += (childRect.top - parentRect.top) - (parent.clientHeight / 2);
  };

  useEffect(() => {
    if (props.selected !== null && selectedVehicleVersion.current !== null) {
      scrollToParent();
    }
  }, [props.selected]);

  return (
    <div className="form-input-container">
      <div className="row">
        <div className="col-lg-10 offset-lg-1">
          <label className="f-label">{props.label}</label>
          <div className="select-table" ref={tableRef}>
            {props.TableHeader && props.TableHeader}
            {props.options
              && props.options.map((opt, index) => (
                <div
                  key={index}
                  ref={opt.id == props.selected ? selectedVehicleVersion : undefined}
                  onClick={() => {
                    localStorage.setItem('motoDesc', JSON.stringify(opt));
                    props.onSelectOption({
                      id: opt.id,
                      description: opt.description,
                      prg_code_moto: opt.prg_code_moto,
                    });
                  }}
                >
                  <div
                    className={`d-none d-md-block select-table-item ${opt.id == props.selected ? 'selected' : ''
                    }`}
                  >
                    <div className="d-flex align-items-center">
                      <div className="col-6">{opt.description}</div>
                      <div className="col-6">
                        <br />
                        {opt.start_immatricolation}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`d-md-none border-bottom select-table-item ${opt.id == props.selected ? 'selected' : ''
                    }`}
                  >
                    <div className="d-flex justify-content-between align-items-center my-1">
                      <span className="px-3">Descrizione:</span>
                      <span className="px-3 text-end">{opt.description}</span>
                    </div>
                    <div className="d-flex justify-content-between align-items-baseline my-1">
                      <span className="px-3">Data Veicolo:</span>
                      <span className="px-3 text-end">
                        {opt.start_immatricolation}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            {props.children && <div className="children">{props.children}</div>}
          </div>
        </div>
      </div>
    </div>
  );
}
