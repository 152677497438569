import React, { useEffect, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { MapContainerStyles } from '../../screens/BrokersPage/NewBroker.styles';
import CarBodyRepairIcon from '../../assets/provider_icons/car-body-repair.png';
import DoctorIcon from '../../assets/provider_icons/doctor.png';
import ExpertIcon from '../../assets/provider_icons/expert.png';
import LawyerIcon from '../../assets/provider_icons/lawyer.png';
import MechanicIcon from '../../assets/provider_icons/mechanic.png';
import RescueIcon from '../../assets/provider_icons/rescue.png';
import RoadIcon from '../../assets/provider_icons/road.png';
import { providersList } from '../constants/providers';
import ProviderModal from './ProviderModal';

export default function ProvidersMap({
  mainLatitude,
  mainLongitude,
  providers,
}) {
  const [filterProviders, setFilterProviders] = useState(providers ?? null);
  const [providerDetails, setProviderDetails] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const getProviderTypeIcon = (type_id) => {
    if (type_id === 1) return LawyerIcon;
    if (type_id === 2) return DoctorIcon;
    if (type_id === 3) return CarBodyRepairIcon;
    if (type_id === 4) return MechanicIcon;
    if (type_id === 5) return RescueIcon;
    if (type_id === 6) return RoadIcon;
    if (type_id === 7) return ExpertIcon;
    return null;
  };

  const calculateDistance = (mainLat, mainLng, officeLat, officeLng) => {
    const R = 6371; // Earth's radius in kilometers

    // Convert latitude and longitude from degrees to radians
    const mainLatRad = (mainLat * Math.PI) / 180;
    const mainLngRad = (mainLng * Math.PI) / 180;
    const officeLatRad = (officeLat * Math.PI) / 180;
    const officeLngRad = (officeLng * Math.PI) / 180;

    // Calculate differences in latitude and longitude
    const dLat = officeLatRad - mainLatRad;
    const dLng = officeLngRad - mainLngRad;

    // Use Haversine formula to calculate distance
    const a = Math.sin(dLat / 2) ** 2
      + Math.cos(mainLatRad) * Math.cos(officeLngRad) * Math.sin(dLng / 2) ** 2;

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    // Calculate distance in kilometers
    const distance = R * c;

    return Number(distance).toFixed(2);
  };

  useEffect(() => {
    if (
      mainLatitude !== null
      && mainLongitude !== null
      && filterProviders !== null
    ) {
      const loader = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        version: 'weekly',
        libraries: ['places'],
      });

      loader.load().then((google) => {
        const myLatLng = {
          lat: Number(mainLatitude),
          lng: Number(mainLongitude),
        };

        const map = new google.maps.Map(document.getElementById('map'), {
          center: myLatLng,
          zoom: 12,
        });

        new google.maps.Marker({
          position: myLatLng,
          map,
          title: 'Hello Greenia!',
        });

        filterProviders.forEach((provider) => {
          const position = {
            lat: Number(provider.latitude),
            lng: Number(provider.longitude),
          };
          const infowindow = new google.maps.InfoWindow();
          const marker_img = getProviderTypeIcon(provider.type_id);
          const distance = calculateDistance(
            myLatLng.lat,
            myLatLng.lng,
            Number(position.lat),
            Number(position.lng),
          );

          const contentString = `
                <div>
                  Sede: ${provider.company_name}<br>
                  Address: ${provider.headquarters}<br>
                  Email: ${provider.email}<br>
                  Phone: ${provider.phone}<br>
                  Distanza: ${distance} Km di distanza<br>
                  <span class="btn btn-success my-2 providerOpenButton" id="providerOpenButton" >Apri</span>
                </div>
              `;

          const providerMarker = new google.maps.Marker({
            position, // set marker position
            map, // specify the map where the marker should be added
            title: 'Informazioni sul fornitore',
            icon: {
              url: marker_img, // Replace with the actual path to your glyph icon
              scaledSize: new google.maps.Size(32, 32),
            },
          });

          // Add any event listeners or custom functionality to your marker
          providerMarker.addListener('click', () => {
            document.addEventListener('click', (event) => {
              if (event.target && event.target.id === 'providerOpenButton') {
                setProviderDetails(provider);
                setOpenModal(true);
              }
            });
            infowindow.close();
            infowindow.setContent(contentString);
            infowindow.open(providerMarker.map, providerMarker);
          });

          provider.secondary_offices.forEach((office) => {
            const position = {
              lat: Number(office.latitude),
              lng: Number(office.longitude),
            };

            const officeDistance = calculateDistance(
              myLatLng.lat,
              myLatLng.lng,
              Number(position.lat),
              Number(position.lng),
            );

            const infowindow = new google.maps.InfoWindow();

            const contentString = ` 
                  <div>
                    Sede: ${provider.company_name} </br>
                    Office: ${office.company_name ?? ''}  </br>
                    Address: ${office.address} </br>
                    Email: ${office.email} </br>
                    Phone ${office.phone} </br> 
                    <span>Distanza: ${officeDistance} Km di distanza </span> </br>
                    <span class="btn btn-success my-2" id="providerOpenButton">Apri</span>
                  </div>
                `;

            const providerMarker = new google.maps.Marker({
              position, // set marker position
              map, // specify the map where the marker should be added
              title: 'Informazioni sul fornitore',
              icon: {
                url: marker_img, // Replace with the actual path to your glyph icon
                scaledSize: new google.maps.Size(32, 32),
              },
            });

            // Add any event listeners or custom functionality to your marker
            providerMarker.addListener('click', () => {
              document.addEventListener('click', (event) => {
                if (event.target && event.target.id === 'providerOpenButton') {
                  setProviderDetails(provider);
                  setOpenModal(true);
                }
              });
              infowindow.close();
              infowindow.setContent(contentString);
              infowindow.open(providerMarker.map, providerMarker);
            });
          });
        });
      });

      return () => {
        if (loader) {
          loader.reset();
          delete window.google;
          Loader.instance = undefined;
        }
      };
    }

    return () => { };
  }, [filterProviders]);

  const handleFilterProviders = (type_id) => {
    const newFilterArray = providers.filter(
      (provider) => provider.type_id === type_id,
    );
    setFilterProviders(newFilterArray);
  };

  const handleRemoveFilter = () => {
    setFilterProviders(providers);
  };

  return (
    <>
      <div className="grid mb-3">
        <button
          className="btn btn-success px-4 "
          onClick={() => handleRemoveFilter()}
        >
          Rimuovere il filtro
        </button>
        {providersList.map((provider, index) => (
          <button
            key={index}
            type="button"
            className="btn btn-sm btn-success px-4 m-1"
            onClick={() => handleFilterProviders(provider.value)}
          >
            {provider.label}
          </button>
        ))}
      </div>
      <MapContainerStyles>
        <div id="map" />
      </MapContainerStyles>
      {openModal && (
        <ProviderModal provider={providerDetails} setIsOpen={setOpenModal} />
      )}
    </>
  );
}
