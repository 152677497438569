import React, { useState } from 'react';
import {
  AquistaButton, AquistaButtonsContainer, CompanyInfoElements, CompanyInformationContainer, LeftCol, LogoContainer, LogoPriceContainer, Price, PriceContainer, ProfessionCardStyles, QuoteInformationContainer, QuoteInformationItem, RightCol,
} from './ProfessionCard.styles';
import TokioMarineLogo from '../../../../assets/images/tokiomarine.png';
import infoIcon from '../../../../assets/redesign_icons/info-icon.svg';
import preventiveIcon from '../../../../assets/redesign_icons/preventivo-icon.svg';
import preContractIcon from '../../../../assets/redesign_icons/pre-contract-icon.svg';
import ProfessionInfoModal from '../Modal/ProfessionInfoModal';
import { downloadResourceDocs } from '../../../../services/axios-client/axeCommons';
import GuaranteesModalProfession from '../Modal/GuaranteesModalProfession';
import { SavedButton } from './common/Buttons.styles';

function ProfessionCard({
  requestInfo, userData, setOpenModal, set_informativi, docs, revealModal, extensions, requestToken, selectableExtensions, profession, selectQuotation,
  //  quoteId,
}) {
  const [showModal, setShowModal] = useState(false);
  // const navigate = useNavigate();
  const openModal = () => {
    setShowModal(!showModal);
  };

  // const goToNextPage = () => {
  //   navigate(`/quotes/profession/purchase/${quoteId}`);
  // };

  return (
    <>
      {showModal && <ProfessionInfoModal userData={userData} setShowModal={setShowModal} docs={docs} set_informativi={set_informativi} extensions={extensions} requestToken={requestToken} selectableExtensions={selectableExtensions} profession={profession} />}
      {revealModal && <GuaranteesModalProfession setOpenModal={setOpenModal} profession={profession} extensions={extensions} requestToken={requestToken} selectableExtensions={selectableExtensions} />}
      <ProfessionCardStyles className={userData.is_aquista === 1 ? 'selected' : ''}>
        <div className="row-container">
          <LeftCol>
            <LogoPriceContainer>
              <LogoContainer>
                <img src={TokioMarineLogo} alt="Tokio Marine Logo" />
              </LogoContainer>
              <PriceContainer>
                <Price>
                  <h5>
                    Prezzo annuo:
                  </h5>
                  <h2>
                    &euro;
                    {' '}
                    {userData.amount.toFixed(2).toLocaleString()}
                  </h2>
                </Price>
              </PriceContainer>
            </LogoPriceContainer>
            <CompanyInformationContainer>
              <CompanyInfoElements onClick={() => openModal()}>
                <img src={infoIcon} alt="" />
                <p>Informazione</p>
              </CompanyInfoElements>
              {docs[profession.profession] !== '' && (
                <CompanyInfoElements
                  onClick={() => downloadResourceDocs(4, profession.profession)}
                  title={`${docs[profession.profession]}`}
                >
                  <img src={preventiveIcon} alt="" />
                  <p>Questionario</p>
                </CompanyInfoElements>
              )}
              {set_informativi[profession.profession] !== '' && (
                <CompanyInfoElements
                  onClick={() => downloadResourceDocs(8, profession.profession)}
                  title={`${set_informativi[profession.profession]}`}
                >
                  <img src={preContractIcon} alt="" />
                  <p>Informativa Precontrattuale</p>
                </CompanyInfoElements>
              )}
            </CompanyInformationContainer>
            <QuoteInformationContainer>
              <QuoteInformationItem>
                <h6>
                  Compagnia:
                </h6>
                <p>
                  {userData.company.name}
                </p>
              </QuoteInformationItem>
              <QuoteInformationItem>
                <h6>
                  Prodotto:
                </h6>
                <p>
                  RC Professionale
                </p>
              </QuoteInformationItem>
              <QuoteInformationItem>
                <h6>
                  Professione:
                </h6>
                <p>
                  {profession.profession_desc}
                </p>
              </QuoteInformationItem>
              <QuoteInformationItem>
                <h6>
                  Massimale:
                </h6>
                <p>
                  {Number(profession.billed_maximum).toLocaleString('it-IT', { minimumFractionDigits: 2 })}
                  &euro;
                </p>
              </QuoteInformationItem>
            </QuoteInformationContainer>
            <QuoteInformationContainer className="ms-3 ms-sm-0">
              <QuoteInformationItem>
                <h6>
                  Franchigia:
                </h6>
                <p>
                  1,000&euro;
                </p>
              </QuoteInformationItem>
              <QuoteInformationItem>
                <h6>
                  Retroattività:
                </h6>
                <p>
                  {profession.retroactivity_name}
                </p>
              </QuoteInformationItem>
              <QuoteInformationItem>
                <h6>
                  Scaglione fatturato:
                </h6>
                <p>
                  {profession.billed}
                  &euro;
                </p>
              </QuoteInformationItem>
              <QuoteInformationItem>
                <h6>
                  Tempo medio di emissione:
                </h6>
                <p>
                  2 giorni
                </p>
              </QuoteInformationItem>
            </QuoteInformationContainer>
          </LeftCol>
          <RightCol>
            <div className="mobile-width">
              <AquistaButtonsContainer>
                {requestInfo.processing_quotation_id !== userData.id ? (
                  <AquistaButton
                    onClick={() => selectQuotation(userData.id)}
                    className="result-profession-sixth-step"
                  >
                    Seleziona
                  </AquistaButton>
                ) : requestInfo.processing_quotation_id === userData.id && <SavedButton>Aggiunto</SavedButton>}
              </AquistaButtonsContainer>
            </div>
          </RightCol>
        </div>
      </ProfessionCardStyles>
    </>
  );
}

export default ProfessionCard;
