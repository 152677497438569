import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  AddButton,
  BackButton,
  GuaranteesLeftSide,
  GuaranteesModalBody,
  GuaranteesModalContent,
  GuaranteesModalHeader,
  GuaranteesRightSide,
  GuaranteesTextContainer,
  GuaranteesTitleBox,
  GuarnateesModalStyles,
  InputCircle,
  InputContainer,
  InputText,
} from './GuaranteesModal.styles';
import {
  updateGuaranteesMotoAction,
} from '../../../../features/insuranceRequests/insuranceRequestsActions';

function GuaranteesMotoModal({
  setShowGuaranteesModal,
  coverages: defaultCoverages,
  requestToken,
}) {
  const [coverages, setCoverages] = useState(defaultCoverages);

  const closeGuaranteesModal = () => {
    setShowGuaranteesModal(false);
  };

  const updateCoverages = ({ id }) => {
    let cov;
    if (coverages.includes(id)) {
      cov = coverages.filter((c) => c !== id);
    } else {
      cov = [...coverages, id];
    }

    setCoverages(cov);
  };

  const includesCoverage = (slug) => coverages.includes(slug);

  const addGuarantees = () => {
    if (coverages === null) return;
    updateGuaranteesMotoAction(requestToken, coverages, null);
  };

  useEffect(() => {
    setCoverages(defaultCoverages);
  }, [JSON.stringify(defaultCoverages)]);

  return (
    <GuarnateesModalStyles>
      <GuaranteesModalBody>
        <GuaranteesModalHeader>
          <GuaranteesLeftSide>
            <FontAwesomeIcon
              fontSize={34}
              icon={faTimes}
              cursor="pointer"
              onClick={() => closeGuaranteesModal()}
            />
            <GuaranteesTextContainer>
              <GuaranteesTitleBox>
                <h3>Aggiungi Garanzie</h3>
              </GuaranteesTitleBox>
              <p>
                Seleziona le garanzie che desideri aggiungere al tuo pacchetto
                assicurativo principale.
              </p>
            </GuaranteesTextContainer>
          </GuaranteesLeftSide>
          <GuaranteesRightSide>
            <BackButton onClick={() => closeGuaranteesModal()}>
              Indietro
            </BackButton>
            <AddButton
              onClick={() => {
                addGuarantees();
                closeGuaranteesModal();
              }}
            >
              Aggiungi (
              {coverages.length + 1}
              )
            </AddButton>
          </GuaranteesRightSide>
        </GuaranteesModalHeader>
        <GuaranteesModalContent>
          <div className="row">
            <div className="col-12 col-md-6">
              <InputContainer value="rca" onChange={() => { }}>
                <div>
                  <InputCircle className="selected" />
                </div>
                <InputText>RCA (sempre inclusa)</InputText>
              </InputContainer>
              <InputContainer
                value="furto"
                onClick={(selected) => updateCoverages({ selected, id: 'furto' })}
              >
                <div>
                  <InputCircle
                    className={includesCoverage('furto') && 'selected'}
                  />
                </div>
                <InputText>Furto</InputText>
              </InputContainer>
              <InputContainer
                value="incendio"
                onClick={(selected) => updateCoverages({ selected, id: 'incendio' })}
              >
                <div>
                  <InputCircle
                    className={includesCoverage('incendio') && 'selected'}
                  />
                </div>
                <InputText>Incendio</InputText>
              </InputContainer>
              <InputContainer
                value="assistenza_stradale"
                onClick={(selected) => updateCoverages({
                  selected,
                  id: 'assistenza_stradale',
                })}
              >
                <div>
                  <InputCircle
                    className={
                      includesCoverage('assistenza_stradale') && 'selected'
                    }
                  />
                </div>
                <InputText>Assistenza stradale</InputText>
              </InputContainer>
              <InputContainer
                value="infortuni_conducente"
                onClick={(selected) => updateCoverages({
                  selected,
                  id: 'infortuni_conducente',
                })}
              >
                <div>
                  <InputCircle
                    className={
                      includesCoverage('infortuni_conducente') && 'selected'
                    }
                  />
                </div>
                <InputText>Infortuni conducente</InputText>
              </InputContainer>
              <InputContainer
                value="tutela_legale"
                onClick={(selected) => updateCoverages({ selected, id: 'tutela_legale' })}
              >
                <div>
                  <InputCircle
                    className={includesCoverage('tutela_legale') && 'selected'}
                  />
                </div>
                <InputText>Tutela legale</InputText>
              </InputContainer>
              <InputContainer
                value="collisione"
                onClick={(selected) => updateCoverages({ selected, id: 'collisione' })}
              >
                <div>
                  <InputCircle
                    className={includesCoverage('collisione') && 'selected'}
                  />
                </div>
                <InputText>Kasco collisione</InputText>
              </InputContainer>
            </div>
            <div className="col-12 col-md-6">
              <InputContainer
                value="cristalli"
                onClick={(selected) => updateCoverages({ selected, id: 'cristalli' })}
              >
                <div>
                  <InputCircle
                    className={includesCoverage('cristalli') && 'selected'}
                  />
                </div>
                <InputText>Cristalli</InputText>
              </InputContainer>
              <InputContainer
                value="rivalsa"
                onClick={(selected) => updateCoverages({ selected, id: 'rivalsa' })}
              >
                <div>
                  <InputCircle
                    className={includesCoverage('rivalsa') && 'selected'}
                  />
                </div>
                <InputText>Zero rivalsa per ebbrezza</InputText>
              </InputContainer>
              <InputContainer
                value="kasko"
                onClick={(selected) => updateCoverages({ selected, id: 'kasko' })}
              >
                <div>
                  <InputCircle
                    className={includesCoverage('kasko') && 'selected'}
                  />
                </div>
                <InputText>Kasko completa</InputText>
              </InputContainer>
              <InputContainer
                value="eventi_naturali"
                onClick={(selected) => updateCoverages({ selected, id: 'eventi_naturali' })}
              >
                <div>
                  <InputCircle
                    className={
                      includesCoverage('eventi_naturali') && 'selected'
                    }
                  />
                </div>
                <InputText>Eventi Naturali</InputText>
              </InputContainer>
              <InputContainer
                value="eventi_sociopolitici"
                onClick={(selected) => updateCoverages({
                  selected,
                  id: 'eventi_sociopolitici',
                })}
              >
                <div>
                  <InputCircle
                    className={
                      includesCoverage('eventi_sociopolitici') && 'selected'
                    }
                  />
                </div>
                <InputText>Atti vandalici</InputText>
              </InputContainer>
            </div>
          </div>
        </GuaranteesModalContent>
      </GuaranteesModalBody>
    </GuarnateesModalStyles>
  );
}

export default GuaranteesMotoModal;
