import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faWindowMinimize } from '@fortawesome/free-solid-svg-icons';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  BrokerMessage,
  ChatBody, ChatHeader, ChatMessage, CloseButton, Message, MessageBubble, MessageBubbleBroker, PlatformChatStyles,
  StyledTextArea,
} from './PlatformChat.styles';

import { confirmAlert } from '../../utils/alerts';
import managementAxe from '../../../services/axios-client/managementServisAxeClient';
import { getPlatformThread, getPlatformTicket, resetPlatformChat } from '../../../features/platformChat/platformChatActions';

const schema = yup.object({
  message: yup
    .string()
    .required('Scrivete la vostra domanda!'),
});

function PlatformChat({ onClose }) {
  const bottomRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const {
    // loading: loadThread,
    thread,
    ticket,
    location: storeLocation,
    request_token,
  } = useSelector((store) => store.platformChat.platformChat);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      message: '',
    },
  });

  const formData = watch();

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const resetChat = () => {
    if (thread !== null || ticket !== null) {
      confirmAlert('Sei sicuro di chiudere? Puoi continuare nella pagina messagi.', () => {
        resetPlatformChat();
        onClose();
      });
    } else {
      onClose();
    }
  };

  const sendInquiry = async () => {
    if (request_token === undefined) {
      if (ticket === null) {
        const data = {
          location: storeLocation,
          subject: 'Chat Generale',
          message: formData.message,
        };
        setLoading(true);
        const res = await managementAxe.post('broker/tickets/create-ticket', data);

        if (res.status === 200) {
          getPlatformTicket(res.data.data.id);
          setLoading(false);
          reset();
        }
      }
      if (ticket !== null) {
        setLoading(true);
        const res = await managementAxe.post(
          `/broker/tickets/${ticket.id}/ticket-messages`,
          {
            message: formData.message,
          },
        );

        if (res.status === 200) {
          getPlatformTicket(ticket.id);
          setLoading(false);
          reset();
        }
      }
    }

    if (request_token !== undefined) {
      if (thread === null) {
        const data = {
          location: storeLocation,
          request_token,
          body: formData.message,
        };
        setLoading(true);
        const res = await managementAxe.post('broker/messages/create-thread-broker/platform-chat', data);

        if (res.status === 200) {
          getPlatformThread(res.data.data.id);
          setLoading(false);
          reset();
        }
      }
      if (thread !== null) {
        const data = {
          subject: formData.message,
        };
        setLoading(true);
        const res = await managementAxe.put(
          `/broker/messages/reply-thread-broker/${thread.id}`,
          data,
        );

        if (res.status === 200) {
          getPlatformThread(thread.id);
          setLoading(false);
          reset();
        }
      }
    }
  };

  useEffect(() => {
    let interval = null;

    const fetchData = async (id, fetchFunction) => {
      setLoading(true);
      await fetchFunction(id);
      setLoading(false);
      scrollToBottom();
    };

    if (thread !== null || ticket !== null) {
      interval = setInterval(() => {
        if (thread !== null) {
          fetchData(thread.id, getPlatformThread);
        }
        if (ticket !== null) {
          fetchData(ticket.id, getPlatformTicket);
        }
      }, 60000);
    }

    return () => {
      if (interval !== null) {
        clearInterval(interval);
      }
    };
  }, [thread, ticket]);

  useEffect(() => {
    scrollToBottom();
  }, [loading, thread?.messages, ticket?.messages]);

  return (
    <PlatformChatStyles>
      <ChatHeader>
        <h6 className="mb-0 fw-bold">Supporto Greenia</h6>
        <div className="d-flex align-items-end justify-content-center gap-3">
          <CloseButton onClick={() => onClose()}>
            <FontAwesomeIcon icon={faWindowMinimize} size="xl" />
          </CloseButton>
          <CloseButton onClick={() => resetChat()}>
            <FontAwesomeIcon icon={faTimes} size="xl" />
          </CloseButton>
        </div>
      </ChatHeader>
      <ChatBody>
        <ChatMessage>
          {loading ? (
            <div className="d-flex justify-content-center mb-3">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )
            : (
              <>

                {thread !== null
                  && thread.messages.map((message) => (
                    <>
                      {message.user === null
                        && (
                          <Message>
                            <MessageBubble>{message.body}</MessageBubble>
                            <div className="d-flex align-items-center justify-content-between gap-1 mt-1 mb-2 ms-1">
                              <p className="text-muted m-0">
                                {moment(message.created_at).fromNow()}
                                {' '}
                                da
                              </p>
                              <p className="fw-bold text-muted m-0">
                                Te
                              </p>
                            </div>
                          </Message>
                        )}
                      {message.user !== null
                        && (
                          <BrokerMessage>
                            <MessageBubbleBroker>{message.body}</MessageBubbleBroker>
                            <div className="d-flex align-items-center justify-content-between gap-1 mt-1 mb-2 ms-1">
                              <p className="text-muted m-0">
                                {moment(message.created_at).fromNow()}
                                {' '}
                                da
                              </p>
                              <p className="fw-bold text-muted m-0">
                                {message.user.name}
                              </p>
                            </div>
                          </BrokerMessage>
                        )}
                    </>

                  ))}
                {ticket !== null
                  && ticket.messages.map((message) => (
                    <>
                      {message.user === null
                        && (
                          <Message>
                            <MessageBubble>{message.message}</MessageBubble>
                            <div className="d-flex align-items-center justify-content-between gap-1 mt-1 mb-2 ms-1">
                              <p className="text-muted m-0">
                                {moment(message.created_at).fromNow()}
                                {' '}
                                da
                              </p>
                              <p className="fw-bold text-muted m-0">
                                Te
                              </p>
                            </div>
                          </Message>
                        )}
                      {message.user !== null
                        && (
                          <BrokerMessage>
                            <MessageBubbleBroker>
                              <p className="message">
                                {message.message}
                              </p>
                            </MessageBubbleBroker>
                            <div className="d-flex align-items-center justify-content-between gap-1 mt-1 mb-2 ms-1">
                              <p className="text-muted m-0">
                                {moment(message.created_at).fromNow()}
                                {' '}
                                da
                              </p>
                              <p className="fw-bold text-muted m-0">
                                {message.user}
                              </p>
                            </div>
                          </BrokerMessage>
                        )}
                    </>
                  ))}

                {(ticket === null && thread === null)
                  && <p>Come possiamo aiutarti?</p>}
              </>
            )}
          <div ref={bottomRef} />
        </ChatMessage>
        <form onSubmit={handleSubmit(sendInquiry)}>
          <StyledTextArea placeholder="Type a message..." {...register('message')} />
          {errors.message?.message && <p>{errors.message?.message}</p>}
          <button className="btn btn-success " type="submit">Invia</button>
        </form>
      </ChatBody>
    </PlatformChatStyles>
  );
}

export default PlatformChat;
