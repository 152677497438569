import styled from 'styled-components';

export const FastQuoteLoadingContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1999;
    background-color: rgba(0,0,0,.5);

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const FastQuoteLoadingPopUpContainer = styled.div`
    padding: 60px 20px;
    width: 100%;
    max-width: 460px;
    background-color: var(--greenia-color);
    border-radius: 10px;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid white;

    .counter {
        margin-top: 20px;
        width: 80px;
        height: 80px;
        background-color: white;
        color: var(--greenia-color);
        font-weight: bold;
        font-size: 30px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const FastQuoteLoadingImageContainer = styled.div`
    background-color: white;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
`;

export const FastQuoteLoadingText = styled.div`
    margin-top: 20px;
    width: 100%;
    max-width: 300px;
    color: white;
    text-align: center;
    font-weight: bold;
`;
