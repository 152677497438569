import axios from 'axios';

import { axeRequestInterceptor, axeResponseInterceptor } from './axeHelpers';
import { MANAGEMENT_SERVICE_API_URL } from '../../config';

const managementAxe = axios.create();

managementAxe.defaults.validateStatus = (status) => status < 500;

managementAxe.interceptors.request.use(axeRequestInterceptor);

managementAxe.interceptors.response.use((response) => response, axeResponseInterceptor);

managementAxe.defaults.baseURL = MANAGEMENT_SERVICE_API_URL;

export default managementAxe;
