import styled from 'styled-components';

export const PostVenditaSection = styled.section`
  .research-class {
    transition: all 150ms linear;
    background-color: rgb(25, 135, 84);
    border: 1px solid rgb(25, 135, 84);
    color: white;
    width: 30%;
  }
  .research-class:hover {
    background-color: #105535;
    color: white;
    border-color: #105535;
  }

  @media screen and (max-width: 768px) {
    .research-class {
      width: 100%;
    }
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    .research-class {
      width: 50%;
    }
  }
`;

export const PostVenditaType = styled.section`
  .post-vendita-styles {
    padding: 20px 100px;
  }

  .option-button {
    margin: 5px;
    padding: 20px;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--greenia-color);
    background-color: rgba(0, 200, 0, 0);
    border: 2px solid var(--greenia-color);
    border-radius: 20px;
    cursor: pointer;
    transition: 0.3s;
  }

  .option-button:hover {
    background-color: rgba(0, 200, 0, 0.3);
  }

  .option-button-icon > * {
    height: 60px;
    width: 60px;
  }

  .option-button-text {
    font-size: 25px;
  }

  .post-vendita-text {
    font-size: 30px;
  }
`;
