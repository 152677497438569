import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import Joyride from 'react-joyride';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import PageLoading from '../../common/components/elements/PageLoading';
import { getCustomerAction } from '../../features/customers/customersActions';
import { ActiveCustomerDetailsStyles, TableRowStyle } from './ActiveCustomerDetails.styles';
// import { useTourContext } from '../../context/useTourContext';
import ErrorComponent from '../../common/components/ErrorComponent';
// import { joyrideOptions } from '../../common/utils/joyrideUtils';
// import { handleCallbackCustomerInformation } from '../../common/utils/joyrideCallbacks';
// import { customerInformationSteps } from '../../common/utils/joyrideSteps';

function CustomerInformationPage() {
  const { customerId } = useParams();
  const navigate = useNavigate();
  const {
    customer: {
      customer, insurance_requests, loading, contacts, error,
    },
  } = useSelector((store) => store.customers);

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('notActiveClient') === 'done') {
  //     setState({ run: false });
  //   } else {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 1500);
  //   }
  // }, [localStorage.getItem('notActiveClient')]);

  // const fireNewQuoteTour = () => {
  //   localStorage.removeItem('notActiveClient');
  //   setState({ run: true, tourActive: true });
  // };

  const newQuotation = (productType) => {
    if (productType === 'auto') {
      navigate('/quote/auto');
    }
    if (productType === 'autocarro') {
      navigate('/quote/autocarro');
    }
    if (productType === 'profession') {
      navigate('/quote/profession');
    }
    if (productType === 'infortuni') {
      navigate('/quote/metlife/meno-male-gold');
    }
    if (productType === 'infodrive') {
      navigate('/quote/infodrive');
    }
  };

  const sendToInsuranceRequestQuotationsList = (ir) => {
    if (ir.product_id === '1' || ir.product_id === '3') {
      navigate(`/quotes/${ir.request_token}`);
    } else if (ir.product_id === '2') {
      navigate(`/quotes/moto/${ir.request_token}`);
    } else if (ir.product_id === '4') {
      navigate(`/quotes/profession/${ir.request_token}`);
    } else if (ir.product_id === '5') {
      navigate(`/quotes/metlife/${ir.request_token}`);
    } else if (ir.product_id === '6') {
      navigate(`/quotes/infodrive/${ir.request_token}`);
    }
  };

  useEffect(() => {
    getCustomerAction(customerId);
  }, []);

  if (loading) {
    return <PageLoading />;
  }

  return error === null
    ? (
      <ActiveCustomerDetailsStyles>
        {/* <Joyride
          steps={customerInformationSteps}
          continuous
          showBeacon={showBeacon}
          locale={{
            back: 'Indietro',
            close: 'Chiudi',
            last: 'Fine',
            next: 'Prossima',
            open: 'Apri il modale',
            restart: 'Riavvia il tour ',
            skip: 'Salta',
          }}
          styles={{
            options: joyrideOptions,
            buttonNext: {
              backgroundColor: '#00a651',
              outline: 'none',
            },
            buttonBack: {
              color: '#00a651',
              outline: 'none',
            },
            beaconInner: {
              backgroundColor: '#00a651',
            },
            beaconOuter: {
              borderColor: '#00a651',
              backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
            },
            buttonSkip: {
              outline: 'none',
            },
            buttonClose: {
              outline: 'none',
            },
          }}
          run={run}
          callback={(data) => handleCallbackCustomerInformation(data, customerInformationSteps, setState)}
          showSkipButton
          showProgress
        /> */}
        <div className="d-flex align-items-start align-items-lg-center justify-content-between flex-column flex-lg-row">
          <div className="d-flex align-items-center justify-content-start">
            <div id="back-button" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faArrowLeftLong} />
            </div>
            <h5 className="mb-0 me-3 h3">Anagrafica Cliente</h5>
            {/* {localStorage.getItem('notActiveClient') === 'done' && (
              <div style={{ cursor: 'pointer' }} onClick={() => fireNewQuoteTour()}>
                <button className="btn btn-success my-2 mx-0 px-3">Tutorial</button>
              </div>
            )} */}
          </div>
          <div className="mt-2 mt-lg-0 ms-3 ms-lg-0 not-active-client-one">
            <p className="mb-1 mb-lg-0 fw-bold h6">Fai un nuovo preventivo</p>
            <div className="btn-group" role="group">
              <button type="button" onClick={() => newQuotation('auto')} className="btn btn-outline-success">Auto</button>
              <button type="button" onClick={() => newQuotation('autocarro')} className="btn btn-outline-success">Autocarro</button>
              {/* <button type="button" onClick={() => newQuotation('infortuni')} className="btn btn-outline-success">Infortuni</button> */}
              <button type="button" onClick={() => newQuotation('profession')} className="btn btn-outline-success">Professionale</button>
              {/* <button type="button" onClick={() => newQuotation('infodrive')} className="btn btn-outline-success">Infodrive</button> */}
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-12 col-md-6 not-active-client-two">
            <h4 className="h5 mt-4 mb-3">
              Scheda Anagrafica
            </h4>
            <ul className="list-group">
              {customer.gender !== 'G'
                && (
                  <>
                    <li className="list-group-item">
                      <strong>Nome: </strong>
                      {' '}
                      {customer.name}
                    </li>
                    <li className="list-group-item">
                      <strong>Cognome: </strong>
                      {' '}
                      {customer.surname}
                    </li>
                    <li className="list-group-item">
                      <strong>Data di nascita: </strong>
                      {' '}
                      {customer.date_of_birth}
                    </li>
                    <li className="list-group-item">
                      <strong>Genere: </strong>
                      {' '}
                      {customer.gender}
                    </li>
                    <li className="list-group-item">
                      <strong>Codice Fiscale: </strong>
                      {' '}
                      {customer.fiscal_code}
                    </li>
                  </>
                )}

              {customer.gender === 'G'
                && (
                  <>
                    <li className="list-group-item">
                      <strong>Ragione Sociale: </strong>
                      {' '}
                      {customer.business_name}
                    </li>
                    <li className="list-group-item">
                      <strong>Partita Iva: </strong>
                      {' '}
                      {customer.vat_number}
                    </li>
                    <li className="list-group-item">
                      <strong>Genere: </strong>
                      {' '}
                      {customer.gender}
                    </li>
                  </>
                )}
            </ul>
          </div>
          <div className="col-12 col-md-6 not-active-client-three">
            <h4 className="h5 mt-4 mb-3">
              Informazioni di contatto
            </h4>
            <ul className="list-group">
              <li className="list-group-item">
                <strong>Email: </strong>
                {' '}
                {customer.email}
              </li>
              <li className="list-group-item">
                <strong>Telefono: </strong>
                {' '}
                {customer.phone}
              </li>
              <li className="list-group-item">
                <strong>Indirizo: </strong>
                {' '}
                {customer.address}
                {' '}
                {customer.house_number}
              </li>
              <li className="list-group-item">
                <strong>Residenza: </strong>
                {' '}
                {customer.city_of_residence ? customer.city_of_residence.italian_name : ''}
              </li>
              <li className="list-group-item">
                <strong>Codice Postale: </strong>
                {' '}
                {customer.postal_code}
              </li>
              <li className="list-group-item not-active-client-four">
                <div className="btn btn-outline-success" onClick={() => navigate(`/customers/customer/${customerId}/contact`)}>Contatta</div>
              </li>
            </ul>
          </div>
        </div>

        <div className="row">
          <div className="col-12 not-active-client-five">
            <h5 className="mb-0 mt-5 h5">Preventivi Fatti</h5>
            <hr />
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Prodotto</th>
                    <th scope="col">Targa</th>
                    <th scope="col">Data D'inizio</th>
                    <th scope="col">Creato a</th>
                  </tr>
                </thead>
                <tbody>
                  {insurance_requests.length !== 0
                    ? insurance_requests.map((request, index) => (
                      <TableRowStyle key={index} onClick={() => sendToInsuranceRequestQuotationsList(request)}>
                        <td>{request.request_token}</td>
                        <td>{request.product.name}</td>
                        <td>{request.vehicle_plate}</td>
                        <td>{moment(request.policy_effective_date).format('DD.MM.YYYY')}</td>
                        <td>{moment(request.created_at).format('DD.MM.YYYY HH:mm')}</td>
                      </TableRowStyle>
                    ))
                    : (
                      <tr>
                        <td colSpan={5} />
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 not-active-client-six">
            <h5 className="mb-0 mt-5 h5">Storico Contatti</h5>
            <hr />
            <div>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Intermediario</th>
                    <th scope="col">Metodo Di Contatto</th>
                    <th scope="col">Messagio</th>
                    <th scope="col">Data</th>
                  </tr>
                </thead>
                <tbody>
                  {contacts.map((contact, index) => (
                    <TableRowStyle key={index}>
                      <td>{`${contact.broker_name} ${contact.broker_surname}`}</td>
                      <td>
                        {contact.contact_type === 1 ? 'Email' : 'SMS'}
                      </td>
                      <td>{contact.message}</td>
                      <td>{moment(contact.created_at).toDate().toLocaleDateString('it-IT')}</td>
                    </TableRowStyle>
                  ))}
                  {contacts.length === 0 && (
                    <tr>
                      <td colSpan={4}>
                        Nessun Storico Contatti Trovato!
                      </td>
                    </tr>
                  )}

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ActiveCustomerDetailsStyles>
    )
    : <ErrorComponent error={error} />;
}

export default CustomerInformationPage;
