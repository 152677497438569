import styled from 'styled-components';

export const FileUploadStyles = styled.div`
    #form-file-upload {
        height: 100%;
        width: 100%;
        max-width: 320px;
        text-align: center;
        position: relative;
    }
    
    #input-file-upload {
        display: none;
    }
    
    #label-file-upload {
        padding: 10px;
        height: 100%;
        min-height: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-width: 2px;
        border-radius: 1rem;
        border-style: dashed;
        border-color: #cbd5e1;
        background-color: #f8fafc;
    }
    
    #label-file-upload.drag-active {
        background-color: #ffffff;
    }
    
    .upload-button {
        cursor: pointer;
        padding: 0.25rem;
        font-size: 1rem;
        border: none;
        font-family: 'Oswald', sans-serif;
        background-color: transparent;
    }
    
    .upload-button:hover {
        text-decoration-line: underline;
    }
    
    #drag-file-element {
        position: absolute;
        width: 100%;
        border-radius: 1rem;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
    }
`;
