import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import CenteredPageTitle from '../../common/components/CenteredPageTitle';
import { genders } from '../../common/constants';
import {
  infoDriveChangeIndex, infoDriveGetVehicleModels, infoDriveSubmitAnswers, infodriveGetInitialData, resetInfodriveForm,
} from '../../features/formsBackoffice/formsBackofficeActions';
import PageLoading from '../../common/components/elements/PageLoading';
import convertToItalianDate from '../../common/helpers/convertToItalianDate';
import getAllowedPolicyEffectDate from '../../common/helpers/getAllowedPolicyEffectDate';
import ProgressBarInfoDrive from '../../common/components/ProgressBarInfoDrive';
import { infodriveFieldsBySteps, schemaInfoDrive } from './BackOfficeInfoDrive.validations';
import { generalAlertError } from '../../common/utils/alerts';
import InputPrivacy from '../../common/components/inputs/InputPrivacy';
import generateFiscalCode from '../../common/helpers/generateFiscalCode';
import { FormInputText } from '../../common/formInputs/FormInputText';
import { FormInputSelect } from '../../common/formInputs/FormInputSelect';
import { FormInputRadio } from '../../common/formInputs/FormInputRadio';
import { FormInputDate } from '../../common/formInputs/FormInputDate';
import { FormInputAddress } from '../../common/formInputs/FormInputAddress';
import { greeniaPrivacyPolicy, modifyRequestDataBackoffice } from '../../services/axios-client/axeCommons';
import { Layout } from './components/Layout/Layout';
import FormReactSelectInputBirthPlace from '../../common/formInputs/FormReactSelectInputBirthPlace';
import FormReactSelectModel from '../../common/formInputs/FormReactSelectModel';
import FormReactSelectBrand from '../../common/formInputs/FormReactSelectBrand';
import FormReactSelectResidenceInput from '../../common/formInputs/FormReactSelectResidenceInput';

const steps = [
  'Dati del Veicolo',
  'Dati Personali',
];

function BackOfficeInfodrive() {
  const state = useSelector((store) => store.formsBackoffice.infodrive);
  const { requestToken } = useParams();
  const navigate = useNavigate();
  const skipCheckGenderRef = useRef(false);
  const {
    register, formState: { errors, touchedFields }, watch, setValue, trigger,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schemaInfoDrive),
    shouldFocusError: true,
    defaultValues: {
      source: 'greenia.it',
      product: 'infodrive',
      name: '',
      surname: '',
      date_of_birth: '',
      commune_of_birth_code: '',
      gender: '',
      phone: '',
      email: '',
      residence_commune_code: null,
      province_of_birth_code: null,
      born_abroad: false,
      country_of_birth_code: 'Z000',
      address: '',
      house_number: '',
      postal_code: '',
      fiscal_code: '',
      residence_province_code: '',
      existing_customer: false,
      active_customer_id: null,
      policy_effective_date: '',
      business_name: '',
      vat_number: '',
      company_type: null,
      vehicle_plate: '',
      vehicle_brand_code: '',
      vehicle_model_code: '',
      privacies: [],
    },
  });

  const {
    loading,
    states,
    municipalities,
    formData,
    index,
    brands,
    vehicleModels,
    dataPrivacy,
    isLoadingVehicleModels,
  } = state;

  const userData = watch();

  const updateUserData = (data) => {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      setValue(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    });
    trigger(keys);
  };

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  const getFiscalCode = (birthDate) => {
    const d = birthDate.split('-');
    const fData = {
      name: userData.name,
      surname: userData.surname,
      gender: userData.gender,
      day: d[2],
      month: d[1],
      year: d[0],
    };
    if (userData.born_abroad) {
      fData.birthplace = states.filter(
        (s) => s.state_code === userData.country_of_birth_code,
      )[0].name;
      fData.birthplaceProvincia = 'EE';
    } else {
      fData.birthplace = municipalities.filter(
        (municipality) => municipality.cadastral_code === userData.commune_of_birth_code,
      )[0].name;
      fData.birthplaceProvincia = userData.province_of_birth_code;
    }
    return generateFiscalCode(fData);
  };

  const submitAnswers = () => {
    const data = {
      ...userData,
    };

    data.date_of_birth = userData.date_of_birth !== '' ? convertToItalianDate(userData.date_of_birth) : '';
    data.policy_effective_date = convertToItalianDate(
      userData.policy_effective_date,
    );
    data.fiscal_code = (userData.gender !== 'G') ? getFiscalCode(userData.date_of_birth) : null;

    infoDriveSubmitAnswers(data, navigate, requestToken);
  };

  const getVehicleModels = async () => {
    infoDriveGetVehicleModels(userData.vehicle_brand_code);
  };

  const validateDataPrivacy = () => {
    let { error, message } = '';

    dataPrivacy.forEach((privacy) => {
      if (privacy.required === 1) {
        const userDataArray = { ...userData }; // make a separate copy of the object
        const index = userDataArray.privacies.indexOf(privacy.id);

        if (index < 0) {
          error = true;
          message = 'Tutti i campi sono obbligatori! Tutta la privacy con la stella deve essere controllata';
        }
      }
    });
    return { error, message };
  };

  const privacyItemChangeHandler = (value, item) => {
    if (!value.target.checked) {
      const newArray = [...userData.privacies];
      const index = newArray.indexOf(item.id);
      newArray.splice(index, 1);
      updateUserData({
        privacies: newArray,
      });
      return;
    }
    const userDataNewArray = [...userData.privacies];
    userDataNewArray.push(item.id);
    updateUserData({
      privacies: userDataNewArray,
    });
  };

  const prevButton = () => {
    if (index > 1) {
      infoDriveChangeIndex(index - 1);
    }
  };

  const nextButton = async () => {
    const res = await trigger(infodriveFieldsBySteps[index - 1], { shouldFocus: true });

    if (res !== true) {
      return;
    }

    if (index === 2) {
      const validation = validateDataPrivacy();
      if (validation.error) {
        generalAlertError(validation.message);
        return;
      }
    }

    if (index === 2) {
      submitAnswers();
      return;
    }

    infoDriveChangeIndex(index + 1);
    setTimeout(() => window.scrollTo(0, 0), 500);
  };

  useEffect(() => {
    const callAsync = async () => {
      skipCheckGenderRef.current = true;
      const {
        data: { data: { userData } },
      } = await modifyRequestDataBackoffice(requestToken);
      updateUserData(userData);
      updateUserData({
        vehicle_model_code: userData.vehicle_model_code.toString(),
        date_of_birth: userData.date_of_birth === null ? '' : userData.date_of_birth,
      });
    };
    callAsync();
  }, []);

  useEffect(() => {
    infodriveGetInitialData();

    return () => {
      resetInfodriveForm();
    };
  }, []);

  useEffect(() => {
    if (userData.vehicle_brand_code) {
      getVehicleModels();
    }
  }, [userData.vehicle_brand_code]);

  useEffect(() => {
    if (skipCheckGenderRef.current === true) {
      skipCheckGenderRef.current = false;
      return;
    }

    const triggerValidation = {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    };

    if (userData.gender === 'G') {
      setValue('date_of_birth', null);
      setValue('country_of_birth_code', null);
      setValue('province_of_birth_code', null);
      setValue('commune_of_birth_code', null);
      setValue('born_abroad', false);
      setValue('name', null);
      setValue('surname', null);
    } else if (['M', 'F'].includes(userData.gender) && userData.date_of_birth === null) {
      setValue('date_of_birth', '', triggerValidation);
      setValue('country_of_birth_code', 'Z000', triggerValidation);
      setValue('province_of_birth_code', null, triggerValidation);
      setValue('commune_of_birth_code', null, triggerValidation);
      setValue('born_abroad', false, triggerValidation);
      setValue('name', '', triggerValidation);
      setValue('surname', '', triggerValidation);
    }
  }, [userData.gender, userData.date_of_birth]);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <Layout>

      <ProgressBarInfoDrive activeStep={index} steps={steps} />
      <div className="mt-2">
        <CenteredPageTitle title="Completa i dati" />
      </div>

      {index === 1 ? (
        <div className="form-container">
          <FormInputText
            label="Targa dell' auto"
            error={errors.vehicle_plate?.message}
            onChange={(value) => updateUserData({ vehicle_plate: value.toUpperCase() })}
            value={userData.vehicle_plate}
            valid={isValid('vehicle_plate')}
          />
          <FormReactSelectBrand
            label="Marca"
            values={{
              vehicle_brand_code: userData.vehicle_brand_code,
            }}
            error={errors.vehicle_brand_code?.message}
            valid={isValid('vehicle_brand_code')}
            onChange={(item) => updateUserData({
              vehicle_brand_code: item.vehicle_brand_code,
            })}
            options={brands}
          />

          {userData.vehicle_brand_code && (
            <>
              {isLoadingVehicleModels ? (
                <p className="text-center">Attendere prego...</p>
              ) : (
                <FormReactSelectModel
                  label="Modello"
                  values={{
                    vehicle_model_code: userData.vehicle_model_code,
                  }}
                  error={errors.vehicle_model_code?.message}
                  valid={isValid('vehicle_model_code')}
                  onChange={(item) => updateUserData({
                    vehicle_model_code: item.vehicle_model_code,
                  })}
                  options={vehicleModels}
                />
              )}
            </>
          )}
        </div>
      ) : ''}

      {index === 2
        ? (
          <>
            <div className="form-container">

              <FormInputRadio
                options={genders}
                label="Il contraente è"
                name="gender"
                registration={register('gender')}
                error={errors.gender?.message}
              />

              {
                userData.gender === 'G' ? (
                  <div>
                    <FormInputText
                      placeholder="Ragione Sociale"
                      label="Ragione Sociale"
                      registration={register('business_name')}
                      error={errors.business_name?.message}
                      valid={isValid('business_name')}
                    />
                    <FormInputText
                      placeholder="Partita Iva"
                      label="Partita Iva"
                      registration={register('vat_number')}
                      error={errors.vat_number?.message}
                      valid={isValid('vat_number')}
                    />
                    <FormInputSelect
                      label="Tipologia azienda"
                      registration={register('company_type')}
                      error={errors.company_type?.message}
                      valid={isValid('company_type')}
                      placeholder="-Seleziona-"
                      options={formData.company_types}
                    />
                  </div>
                ) : (
                  <div>
                    <FormInputText
                      placeholder="Nome"
                      label="Nome"
                      name="name"
                      registration={register('name')}
                      error={errors.name?.message}
                      valid={isValid('name')}
                    />
                    <FormInputText
                      placeholder="Cognome"
                      label="Cognome"
                      name="surname"
                      registration={register('surname')}
                      error={errors.surname?.message}
                      valid={isValid('surname')}
                    />
                    <FormInputDate
                      label="Data di nascita"
                      registration={register('date_of_birth')}
                      minDate="1935-01-01"
                      maxDate="2021-01-01"
                      error={errors.date_of_birth?.message}
                      valid={isValid('date_of_birth')}
                    />
                    <FormReactSelectInputBirthPlace
                      states={states}
                      options={municipalities}
                      label="Luogo di nascita"
                      name="commune_of_birth_code"
                      values={{
                        commune_of_birth_code: userData.commune_of_birth_code,
                        province_of_birth_code: userData.province_of_birth_code,
                        born_abroad: userData.born_abroad,
                        country_of_birth_code: userData.country_of_birth_code,
                      }}
                      onChange={(value) => updateUserData({
                        commune_of_birth_code: value.commune_of_birth_code,
                        province_of_birth_code: value.province_of_birth_code,
                        born_abroad: value.born_abroad,
                        country_of_birth_code: value.country_of_birth_code,
                      })}
                      valid={isValid('commune_of_birth_code')}
                      error={errors.commune_of_birth_code?.message}
                    />
                  </div>
                )
              }

              <FormReactSelectResidenceInput
                label="Residenza"
                placeholder="Comune"
                options={municipalities}
                values={{
                  residence_commune_code: userData.residence_commune_code,
                  residence_province_code: userData.residence_province_code,
                  postal_code: userData.postal_code,
                }}
                onChange={(item) => updateUserData({
                  residence_commune_code: item.residence_commune_code,
                  residence_province_code:
                    item.residence_province_code,
                  postal_code: item.postal_code ?? '',
                })}
                error={errors.residence_commune_code?.message}
                valid={isValid('residence_commune_code')}
              >
                <FormInputText
                  placeholder="-Codice Postale-"
                  label="Codice Postale"
                  registration={register('postal_code')}
                  error={errors.postal_code?.message}
                  valid={isValid('postal_code')}
                />
              </FormReactSelectResidenceInput>

              <FormInputAddress
                placeholder="Indirizzo"
                label="Indirizzo"
                valueAddress={userData.address}
                valueHouse={userData.house_number}
                onAddressChange={(value) => updateUserData({ address: value })}
                onHouseNumberChange={(value) => updateUserData({ house_number: value })}
                addressError={errors.address?.message}
                houseNumberError={errors.house_number?.message}
                validAddress={isValid('address')}
                validHouseNumber={isValid('house_number')}
              />

              <FormInputText
                placeholder="Email"
                label="Email"
                registration={register('email')}
                error={errors.email?.message}
                valid={isValid('email')}
              />

              <FormInputText
                label="Telefono"
                placeholder="Telefono"
                registration={register('phone')}
                error={errors.phone?.message}
                valid={isValid('phone')}
              />

              <FormInputDate
                minDate={getAllowedPolicyEffectDate('min')}
                maxDate={getAllowedPolicyEffectDate('max')}
                registration={register('policy_effective_date')}
                label="Data di inizio della copertura della polizza"
                paragraph="Date valide: da oggi a un anno da oggi"
                error={errors.policy_effective_date?.message}
                valid={isValid('policy_effective_date')}
              />
            </div>

            <h5 className="text-center">Informativa Privacy e IVASS</h5>
            <div className="text-center text-decoration-underline mt-2">
              <a onClick={() => greeniaPrivacyPolicy()} style={{ cursor: 'pointer' }}>
                <small className="text-center">Greenia Privacy Policy</small>
              </a>
            </div>
            {dataPrivacy.map((item, i) => (
              <InputPrivacy
                required={item.required}
                label={item.content}
                name={item.type}
                id={item.type}
                key={i}
                checked={userData.privacies.includes(item.id)}
                onChange={(value) => privacyItemChangeHandler(value, item)}
              />
            ))}

          </>
        )
        : ''}

      {index >= 1 && index <= 2 && (
        <div className="footer-buttons p-5">
          <div className="d-flex justify-content-between">
            {index !== 1 && (
              <button
                className="btn btn-questionnaire back"
                onClick={prevButton}
              >
                Indietro
              </button>
            )}
            <button className="btn btn-questionnaire" onClick={nextButton}>
              {index === 2 ? 'Vai ai preventivi' : 'Continua'}
            </button>
          </div>
        </div>
      )}
    </Layout>
  );
}

export default BackOfficeInfodrive;
