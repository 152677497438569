import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleStop, faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { MediaRecorder, register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';
import { useSelector } from 'react-redux';
import { resetTranscription, sendAudioForTranscription } from '../../features/speech/speechActions';
import { AudioContainer, ListeningBox, ListeningContainer } from './AudioRecorder.styles';
import PageLoadingFull from './elements/PageLoadingFull';
import { generalAlertError } from '../utils/alerts';
import { ChatContext } from '../../app/theme/style-screens/DashboardTheme.context';
import robotHead from '../../assets/images/robot-head.png';

function AudioRecorder() {
  const chatValues = useContext(ChatContext);

  const [recording, setRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const { transcriptions, loading } = useSelector((store) => store.speech);

  const stopRecording = async () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
      setRecording(false);
    }
  };

  const handleDataAvailable = (event) => {
    if (event.data.size > 0) {
      chunksRef.current.push(event.data);
    }
  };

  const audioToBase64 = async (blob) => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const base64data = reader.result;
      resolve(base64data);
    };
    reader.readAsDataURL(blob);
  });

  const startRecording = async () => {
    chunksRef.current = [];
    try {
      await register(await connect());
    } catch (error) {
      // DO Nothing
    }
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorderRef.current = new MediaRecorder(stream, { mimeType: 'audio/wav' });
        mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
        mediaRecorderRef.current.addEventListener('stop', async () => {
          const audioBlob = new Blob(chunksRef.current, { type: 'audio/wav' });
          const base64Audio = await audioToBase64(audioBlob);

          const data = {
            audio: base64Audio.slice(22),
          };
          sendAudioForTranscription(data);
          chunksRef.current = [];
        });
        mediaRecorderRef.current.start();
        setRecording(true);
      });
  };

  useEffect(() => {
    if (recording === true) {
      setTimeout(() => {
        stopRecording();
      }, 6000);
    }
  }, [recording]);

  useEffect(() => {
    if (transcriptions !== null && transcriptions.length !== 0) {
      // ? Send message to chat
      chatValues.setOpenChat(true);
      chatValues.chatRef.current.sendMessage(transcriptions[0].transcript);
    }

    if (transcriptions !== null && transcriptions.length === 0) {
      generalAlertError('Per favore riprova! La voce non era molto chiara!');
    }

    resetTranscription();
  }, [transcriptions]);

  return (
    <>
      <AudioContainer onClick={recording ? stopRecording : startRecording}>
        {!recording
          && (
            <div className="d-flex align-items-center justify-content-center flex-column">
              <FontAwesomeIcon icon={faMicrophone} color="green" size="4x" />
              <h4 className="mb-0 mt-2">Come posso aiutarti?</h4>
            </div>
          )}
        {recording
          && (
            <ListeningBox>
              <ListeningContainer className="d-flex align-items-center justify-content-center flex-column">
                <img src={robotHead} alt="Mellino Greenia" width={120} className="mb-3" />
                <div className="loader">
                  <span className="stroke" />
                  <span className="stroke" />
                  <span className="stroke" />
                  <span className="stroke" />
                  <span className="stroke" />
                  <span className="stroke" />
                  <span className="stroke" />
                </div>
                <div className="d-flex align-items-center justify-content-center gap-2 btn btn-success mt-3">
                  <FontAwesomeIcon icon={faCircleStop} size="2x" color="white" />
                  <div>
                    Hai finito di parlare
                  </div>
                </div>
              </ListeningContainer>
            </ListeningBox>
          )}
      </AudioContainer>
      {loading === true
        && <PageLoadingFull />}
    </>
  );
}

export default AudioRecorder;
