import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHelmetSafety, faUserTie, faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import GreeniaLogo from '../../assets/images/greenia-logo.svg';
import ListaProdotti from '../../assets/images/lista-prodotti.png';
import Portagolgio from '../../assets/images/portagolgio.png';
import ListaRinnovi from '../../assets/images/lista-rinnovi.png';
import message from '../../assets/images/message.png';
import User from '../../assets/images/user.png';
import Tutorial from '../../assets/images/tutorial.png';
import BellIcon from '../../assets/images/icons/BellIcon';
import useAuth from '../../hooks/useAuth';
import { SidebarStyles } from './Sidebar.styles';
import { generalAlertError } from '../utils/alerts';
import axe from '../../services/axios-client/axiosClient';
import { BACKOFFICE_PANEL_URL } from '../../config';

function OffcanvasSidebar() {
  const { isUserSectionAB, hasBackoffice } = useAuth();
  const { notificationsCount, messagesCount } = useSelector(
    (store) => store.utilities,
  );

  const loginToPanel = async () => {
    const res = await axe.post('/backoffice/signin');

    if (res.status !== 200) {
      generalAlertError("Qualcosa e andato storto! Riprova o chiama l'amministratore dell sistema!");
    }
    window.open(`${BACKOFFICE_PANEL_URL}/broker-back-office/login/${res.data.data.token}`, '_blank');
  };

  return (
    <>

      <div className="offcanvas offcanvas-start d-lg-none" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">

        <div className="offcanvas-header">
          <Link to="/"><img src={GreeniaLogo} alt="Greenia Logo" width={155} className="mt-2 ms-2" data-bs-dismiss="offcanvas" aria-label="Close" /></Link>
          <button type="button" className="btn-close py-0" data-bs-dismiss="offcanvas" aria-label="Close" />
        </div>
        <div className="offcanvas-body p-0">
          <SidebarStyles>
            <div className="col-lg-3" id="sidear-wrapper">
              <nav id="sidebarMenu" className="">

                <div className="mt-md-4 mt-2">
                  <ul>
                    <li data-bs-dismiss="offcanvas" aria-label="Close">
                      <Link
                        to="/notifications"
                        className="list-group-item list-group-item-action py-2 ripple"
                      >
                        <span className={
                          notificationsCount > 0 ? 'text-danger' : 'text-lightgrey'
                        }
                        >
                          <BellIcon width="24px" height="20px" />
                        </span>

                        <span className="item">
                          Notifiche
                          {' '}
                          {notificationsCount > 0 ? (
                            <span className="notification-badge">
                              <small>{notificationsCount}</small>
                            </span>
                          ) : (
                            ''
                          )}
                        </span>
                      </Link>
                    </li>
                    <li data-bs-dismiss="offcanvas" aria-label="Close" className="step-one-home">
                      <Link
                        to="/quote"
                        className="list-group-item list-group-item-action py-2 ripple"
                      >
                        <img
                          className="item-img"
                          src={ListaProdotti}
                          alt="Products List"
                        />
                        <span className="item">Nuovo preventivo</span>
                      </Link>
                    </li>
                    <li data-bs-dismiss="offcanvas" aria-label="Close">
                      <Link
                        to="/not-saved-quotations"
                        className="list-group-item list-group-item-action py-2 ripple"
                      >
                        <img className="item-img" src={Portagolgio} alt="non salvati" />
                        <span className="item">Preventivi non salvati</span>
                      </Link>
                    </li>
                    <li data-bs-dismiss="offcanvas" aria-label="Close">
                      <Link
                        to="/quotazioni-in-processo"
                        className="list-group-item list-group-item-action py-2 ripple"
                      >
                        <img className="item-img" src={Portagolgio} alt="in lavorazione" />
                        <span className="item">Pratiche in lavorazione</span>
                      </Link>
                    </li>
                    <li data-bs-dismiss="offcanvas" aria-label="Close">
                      <Link
                        to="/quotazioni-aquistati"
                        className="list-group-item list-group-item-action py-2 ripple"
                      >
                        <img className="item-img" src={Portagolgio} alt="Preventivi acquistati" />
                        <span className="item">Polizze Emesse</span>
                      </Link>
                    </li>
                    <li data-bs-dismiss="offcanvas" aria-label="Close">
                      <Link
                        to="/quote-semestrale-da-pagare"
                        className="list-group-item list-group-item-action py-2 ripple"
                      >
                        <img className="item-img" src={Portagolgio} alt="Preventivi acquistati" />
                        <span className="item">Pratiche semestrali</span>
                      </Link>
                    </li>
                    <li data-bs-dismiss="offcanvas" aria-label="Close">
                      <Link
                        to="/report"
                        className="list-group-item list-group-item-action py-2 ripple"
                      >
                        <img className="item-img" src={ListaRinnovi} alt="renewals" />
                        <span className="item">Rinnovi</span>
                      </Link>
                    </li>
                    <li data-bs-dismiss="offcanvas" aria-label="Close">
                      <Link
                        to="/clients"
                        className="list-group-item list-group-item-action py-2 ripple"
                      >
                        <FontAwesomeIcon
                          icon={faUsers}
                          style={{ color: '#737476' }}
                        />
                        <span className="item">Clienti</span>
                      </Link>
                    </li>
                    {/* <li data-bs-dismiss="offcanvas" aria-label="Close">
                      <Link
                        to="/research-type"
                        className="list-group-item list-group-item-action py-2 ripple"
                      >
                        <img className="item-img" src={researchIcon} alt="analysis" />
                        <span className="item">Analisi Rui</span>
                      </Link>
                    </li> */}
                    <li data-bs-dismiss="offcanvas" aria-label="Close">
                      <Link
                        to="/post-vendita"
                        className="list-group-item list-group-item-action py-2 ripple"
                      >
                        {' '}
                        <FontAwesomeIcon
                          icon={faHelmetSafety}
                          style={{ color: '#737476' }}
                        />
                        <span className="item">Post Vendita</span>
                      </Link>
                    </li>
                    <li data-bs-dismiss="offcanvas" aria-label="Close">
                      <Link
                        to="/messages"
                        className="list-group-item list-group-item-action py-2 ripple"
                      >
                        {' '}
                        <img className="item-img" src={message} alt="Messages" />
                        <span className="item">
                          Messaggi
                          {`(${messagesCount})`}
                        </span>
                      </Link>
                    </li>
                    <li data-bs-dismiss="offcanvas" aria-label="Close">
                      <Link
                        to="/profile"
                        className="list-group-item list-group-item-action py-2 ripple"
                      >
                        <img className="item-img" src={User} alt="profile" />
                        <span className="item">Profilo</span>
                      </Link>
                    </li>
                    <li data-bs-dismiss="offcanvas" aria-label="Close">
                      <Link
                        to="/tutorial"
                        className="list-group-item list-group-item-action py-2 ripple"
                      >
                        <img className="item-img" src={Tutorial} alt="video" />
                        <span className="item">Tutorial</span>
                      </Link>
                    </li>
                    {/* <li data-bs-dismiss="offcanvas" aria-label="Close">
                      <Link
                        to="/useful-documents"
                        className="list-group-item list-group-item-action py-2 ripple"
                      >
                        <FontAwesomeIcon
                          icon={faFile}
                          style={{ color: '#737476' }}
                        />
                        <span className="item">Documenti Utili</span>
                      </Link>
                    </li> */}
                    {isUserSectionAB && (
                      <li data-bs-dismiss="offcanvas" aria-label="Close">
                        <Link
                          to="/brokers"
                          className="list-group-item list-group-item-action py-2 ripple"
                        >
                          <img className="item-img" src={User} alt="Setup network" />
                          <span className="item">Gestisci rete</span>
                        </Link>
                      </li>
                    )}
                    {hasBackoffice && (
                      <li data-bs-dismiss="offcanvas" aria-label="Close">
                        <div
                          onClick={() => {
                            loginToPanel();
                          }}
                          to="/tutorial"
                          className="list-group-item list-group-item-action py-2 ripple"
                          style={{ cursor: 'pointer' }}
                        >
                          <FontAwesomeIcon className="item-img" icon={faUserTie} alt="" color="#737476" />
                          <span className="item fw-bold">Accedi A Backoffice</span>
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
              </nav>
            </div>
          </SidebarStyles>

        </div>

      </div>
    </>
  );
}

export default OffcanvasSidebar;
