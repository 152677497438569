import React from 'react';
import moment from 'moment';
import {
  InfoBoxContainer, InfoItem, TitleActionBox, TravelCardInfoStyles,
} from './TravelCardInfo.styles';
import { ModificaDatiButton } from '../common/Redesign.styles';
import editIcon from '../../../../assets/redesign_icons/edit-icon.svg';

function TravelCardInfo({ insuranceRequest, modificaDati, customer }) {
  return (
    <TravelCardInfoStyles>
      <TitleActionBox>
        <h4>
          Informazioni
          {' '}
          <b>viaggi</b>
        </h4>
        <ModificaDatiButton onClick={modificaDati}>
          <p>Modifica Dati</p>
          <img src={editIcon} alt="Modifica Dati" />
        </ModificaDatiButton>
      </TitleActionBox>
      <InfoBoxContainer>
        <InfoItem>
          <h4>Contraente:</h4>
          <p>
            {customer.gender !== 'G' ? `${customer.name} ${customer.surname}` : customer.business_name}
          </p>
        </InfoItem>
        <InfoItem>
          <h4>Telefono:</h4>
          <p>{customer.phone}</p>
        </InfoItem>
        <InfoItem>
          <h4>Destinazione:</h4>
          <p>
            {insuranceRequest.insurance_request_travel.travel_to.name}
          </p>
        </InfoItem>
        <InfoItem>
          <h4>Inizio Copertura:</h4>
          <p>{moment(insuranceRequest.policy_effective_date).format('DD-MM-YYYY')}</p>
        </InfoItem>
      </InfoBoxContainer>
    </TravelCardInfoStyles>
  );
}

export default TravelCardInfo;
