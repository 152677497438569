import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import Joyride from 'react-joyride';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import PageLoading from '../../common/components/elements/PageLoading';
import pdfIMG from '../../assets/images/purchase/pdf.png';
import downloadIMG from '../../assets/images/purchase/download.png';
import { getReplacement, resetReplacement } from '../../features/afterSale/afterSaleActions';
import FileUpload from '../../common/components/inputs/FileUpload';
import { apiAfterSaleOptionalDocumentMethodManualUpload, apiDownloadAfterSaleOptionalDocument } from '../../services/axios-client/axeCommons';
import { generalAlertError, generalAlertSuccessToast } from '../../common/utils/alerts';
import managementAxe from '../../services/axios-client/managementServisAxeClient';
import ErrorComponent from '../../common/components/ErrorComponent';
// import { useTourContext } from '../../context/useTourContext';
// import { joyrideOptions } from '../../common/utils/joyrideUtils';
// import { handleCallbackReplacement } from '../../common/utils/joyrideCallbacks';
// import { replacementSteps } from '../../common/utils/joyrideSteps';
import PaymentStatusAfterSale from '../../common/components/redesignComponents/Purchase/PaymentStatusAfterSale';
import { QuoteInfoContainerAfterSale, WaitingForPaymentQuoteInfoAfterSale } from '../../common/components/redesignComponents/Purchase/PaymentStatusAfterSale.styles';
import { getReasonForReplacement } from '../../common/constants';

export function ReplacementPage() {
  const navigate = useNavigate();
  const { replacementId } = useParams();
  // const [steps, setSteps] = useState([]);

  const state = useSelector((store) => store.afterSale.replacement_page);
  const { loading, replacement, error } = state;

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('singleReplacement') === 'done') {
  //     setState({ run: false });
  //   } else {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 1500);
  //   }
  // }, [localStorage.getItem('singleReplacement')]);

  // const fireNewQuoteTour = () => {
  //   localStorage.removeItem('singleReplacement');
  //   setState({ run: true, tourActive: true });
  // };

  // useEffect(() => {
  //   replacementSteps(replacement, setSteps);
  // }, [replacement]);

  useEffect(() => {
    getReplacement(replacementId);
    return () => {
      resetReplacement();
    };
  }, []);

  const downloadDocument = async (replacementRequestDocumentId) => {
    const response = await managementAxe.get(`/broker/after-sale/replacement/documents/${replacementRequestDocumentId}/download`);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const downloadOptionalDocument = async (documentId) => {
    const response = await apiDownloadAfterSaleOptionalDocument(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const showAfterSaleUploadedDocuments = () => {
    const docs = [
      ...replacement.after_sale_optional_documents,
    ];

    return (
      <>
        {docs.length === 0 && (
          <p>Nessun documento caricato.</p>
        )}

        {docs.map((doc) => (
          <li className="list-group-item p-1 mb-2" key={doc.id}>
            <div className="d-flex align-items-center justify-content-start">
              <div>
                {
                  doc.is_signed_valid === 1 ? (
                    <div
                      className="badge rounded-circle text-bg-success d-flex align-items-center justify-content-center"
                      style={{ width: '30px', height: '30px' }}
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                  ) : (
                    ''
                  )
                }

                {doc.is_signed_valid === 0 && (
                  <div
                    className="badge rounded-circle text-bg-danger d-flex align-items-center justify-content-center"
                    style={{ width: '30px', height: '30px' }}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </div>
                )}
              </div>

              <div className="d-flex align-items-center justify-content-start ms-3">
                <img src={pdfIMG} className="img-fluid me-2" width="30" alt="PDF" />
                <h5 className="h6 mb-0">
                  {doc.original_filename}
                  {doc.is_signed_valid === 0 && (
                    <>
                      <br />
                      <div
                        className="badge text-bg-danger text-wrap"
                        style={{ textAlign: 'left' }}
                      >
                        {doc.optional_document_name}
                      </div>
                    </>
                  )}
                </h5>
              </div>
              <div className="d-flex align-items-center justify-content-center ms-auto">
                <button
                  className="doc-btn border-0 bg-transparent"
                  onClick={() => downloadOptionalDocument(doc.id)}
                >
                  <img src={downloadIMG} width="30" alt="" />
                </button>
              </div>
              {doc.is_uploaded_valid === null && doc.signable_document === 0 ? (
                <span className="badge text-bg-warning rounded-pill p-2 ms-auto">
                  Documento in verifica
                </span>
              ) : (
                ''
              )}
              {doc.is_uploaded_valid === 1 && doc.signable_document === 0 ? (
                <span className="badge text-bg-success rounded-pill p-2 ms-auto">
                  Valido
                </span>
              ) : (
                ''
              )}
              {doc.signable_document === 1 && doc.signed_document_filename === null && doc.is_signed_valid === null ? (
                <span className="badge text-bg-warning rounded-pill p-2 ms-auto">
                  In Attesa Firma
                </span>
              ) : (
                ''
              )}
              {doc.signable_document === 1 && doc.signed_document_filename !== null && doc.is_signed_valid === null ? (
                <span className="badge text-bg-warning rounded-pill p-2 ms-auto">
                  Firma in verifica
                </span>
              ) : (
                ''
              )}
              {doc.signable_document === 1 && doc.is_signed_valid === 1 ? (
                <span className="badge text-bg-success rounded-pill p-2 ms-auto">
                  Firmato
                </span>
              ) : (
                ''
              )}
              {doc.signable_document === 1 && doc.is_signed_valid === 0 ? (
                <span className="badge text-bg-danger rounded-pill p-2 ms-auto">
                  Firma non valida
                </span>
              ) : (
                ''
              )}
            </div>
          </li>
        ))}

      </>
    );
  };

  const uploadSignedOptionalDocument = async (documentId, file) => {
    const uploadedFile = file[0];
    const reader = new FileReader();
    reader.readAsDataURL(uploadedFile);
    if (uploadedFile.type !== 'application/pdf') {
      generalAlertError('Formato di file errato. Il file deve essere in .pdf');
      return;
    }
    reader.onload = () => {
      apiAfterSaleOptionalDocumentMethodManualUpload(documentId, {
        document: reader.result.replace('data:application/pdf;base64,', ''),
      }).then(() => {
        getReplacement(replacementId);
        generalAlertSuccessToast('File caricato');
      });
    };
  };

  if (loading) {
    return <PageLoading />;
  }

  if (error !== null) {
    return <ErrorComponent error={error} />;
  }

  return (
    <>
      {/* <Joyride
        steps={steps}
        continuous
        showBeacon={showBeacon}
        locale={{
          back: 'Indietro',
          close: 'Chiudi',
          last: 'Fine',
          next: 'Prossima',
          open: 'Apri il modale',
          restart: 'Riavvia il tour ',
          skip: 'Salta',
        }}
        styles={{
          options: joyrideOptions,

          buttonNext: {
            backgroundColor: '#00a651',
            outline: 'none',
          },
          buttonBack: {
            color: '#00a651',
            outline: 'none',
          },
          beaconInner: {
            backgroundColor: '#00a651',
          },
          beaconOuter: {
            borderColor: '#00a651',
            backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
          },
          buttonSkip: {
            outline: 'none',
          },
          buttonClose: {
            outline: 'none',
          },
        }}
        run={run}
        callback={(data) => handleCallbackReplacement(data, steps, setState)}
        showSkipButton
        showProgress
      /> */}

      <div className="row">
        <div className="col-12 col-md-6">
          <div className="d-flex gap-2">
            <div className="btn btn-success btn-sm text-white px-4 mb-4" onClick={() => navigate('/replacements')}>Torna indietro</div>
            {/* {localStorage.getItem('singleReplacement') === 'done' && (
              <div style={{ cursor: 'pointer' }} onClick={() => fireNewQuoteTour()}>
                <button className="btn btn-success btn-sm text-white px-4 mb-4">Tutorial</button>
              </div>
            )} */}
          </div>
          <h5>
            Sostituzione #
            {replacement.id}
          </h5>
          <div className="single-replacement-one">
            {replacement.quotation.insurance_request.customer && (
              <div className="fw-bold">
                Cliente:
                {' '}
                {replacement.quotation.insurance_request.customer.business_name === null ? `${replacement.quotation.insurance_request.customer.name} ${replacement.quotation.insurance_request.customer.surname}` : replacement.quotation.insurance_request.customer.business_name}
                <br />
                <div className="status">
                  Stato:&nbsp;&nbsp;&nbsp;
                  {replacement.status === 1
                    && <div className="badge bg-danger">Aperto</div>}

                  {replacement.status === 0
                    && <div className="badge bg-success">Chiuso</div>}
                </div>

              </div>
            )}

            {replacement.quotation.insurance_request.product
              && (
                <>
                  Prodotto:
                  {' '}
                  {replacement.quotation.insurance_request.product.name}
                  <br />
                </>
              )}
            {replacement.quotation.company
              && (
                <>
                  Compagnia:
                  {' '}
                  {replacement.quotation.company.name}
                  <br />
                </>
              )}
            { (replacement.quotation.insurance_request.vehicle !== null) && (
            <>
              Targa:
              {' '}
              {replacement.quotation.insurance_request.vehicle.vehicle_plate}
              <br />
            </>
            )}
            { (replacement.quotation.insurance_request.moto !== null) && (
            <>
              Targa:
              {' '}
              {replacement.quotation.insurance_request.moto.moto_plate}
              <br />
            </>
            )}
            {(replacement.quotation.insurance_request.vehicle && replacement.quotation.insurance_request.vehicle.old_vehicle_plate !== null)
                && (
                  <>
                    Vecchia Targa:
                    {' '}
                    {replacement.quotation.insurance_request.vehicle.old_vehicle_plate}
                    <br />
                  </>
                )}
            {(replacement.quotation.insurance_request.moto && replacement.quotation.insurance_request.moto.old_moto_plate !== null)
                && (
                  <>
                    Vecchia Targa:
                    {' '}
                    {replacement.quotation.insurance_request.moto.old_moto_plate}
                    <br />
                  </>
                )}
            <br />
            <b>Note:</b>
            <br />
            {replacement.note}

            <br />
            <b>Motivi della sostituzione:</b>
            <br />
            {getReasonForReplacement(replacement?.replacement_reason)}

            <br />
            <br />

            <small>
              <strong>
                Creato:
                {new Date(replacement.created_at).toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' })}
              </strong>
            </small>
          </div>
          <br />
          <br />
        </div>
        <div className="col-12 col-md-6">
          {(replacement.after_sale_payment_request && replacement.after_sale_payment_request.is_paid === 1) && (
            <div className="row">
              <div className="col-12 mt-2 mt-xxl-0 payment-information mb-3">
                <WaitingForPaymentQuoteInfoAfterSale>
                  <QuoteInfoContainerAfterSale>
                    <div>
                      <h3 className="ps-3 mb-4 mt-0">
                        Detagli
                        {' '}
                        <b>Pagamento</b>
                      </h3>
                    </div>

                    <div className="ps-3 mb-4 mt-0">
                      <p className="my-1">
                        <strong> Premio:</strong>
                        {' '}
                        <span>
                          {parseFloat(replacement.after_sale_payment_request.amount)}
                          {' '}
                          &euro;
                        </span>
                      </p>
                      <p className="my-1">
                        <strong>Stato Pagamento:</strong>
                        {' '}
                        <span>{replacement.after_sale_payment_request.is_paid === 1 ? 'Pagato' : replacement.after_sale_payment_request.is_paid === 0 ? 'Pagamento Non valido' : 'In Attesa Pagamento'}</span>
                      </p>
                      <p className="my-1">
                        <strong>Data Richiesta:</strong>
                        {' '}
                        <span>{moment(replacement.after_sale_payment_request.created_at).format('DD-MM-YYYY')}</span>
                      </p>
                      <p className="my-1">
                        <strong>Informazione Pagamento:</strong>
                        {' '}
                        <span>{replacement.after_sale_payment_request.payment_description}</span>
                      </p>
                    </div>

                  </QuoteInfoContainerAfterSale>
                </WaitingForPaymentQuoteInfoAfterSale>
              </div>
            </div>
          )}
        </div>
      </div>

      {(replacement.after_sale_payment_request && replacement.after_sale_payment_request.is_paid === null) && <PaymentStatusAfterSale afterSale={replacement} afterSalePaymentRequest={replacement.after_sale_payment_request} collectData={getReplacement} />}

      <div className="single-replacement-two">
        {replacement.documents.length !== 0
          && (
            <>
              <h3>Documenti</h3>
              {
                replacement.documents.map((document) => (
                  <div key={document.id} className="d-flex border border-success rounded py-3 m-1" style={{ maxWidth: 600 }}>
                    <div className="d-flex align-items-center justify-content-start ms-3">
                      <img src={pdfIMG} className="img-fluid me-2" width="30" alt="PDF" />
                      <h5 className="h6 mb-0">
                        {document.original_filename}
                      </h5>
                      {document.after_sale_document_type_name && `-${document.after_sale_document_type_name}`}
                    </div>
                    <div className="d-flex align-items-center justify-content-center ms-auto">
                      <button
                        className="doc-btn border-0 bg-transparent"
                        onClick={() => downloadDocument(document.id)}
                      >
                        <img src={downloadIMG} width="30" alt="" />
                      </button>
                    </div>
                  </div>
                ))
              }
            </>
          )}
      </div>
      <div className="col-12 mt-3 mt-xl-0 d-flex single-replacement-three">
        <div className="col-6 mt-3 mt-xl-0">
          <div className="card">
            <div className="card-body px-0">
              {replacement.after_sale_optional_documents.filter((document) => document.signable_document === 1 && document.is_uploaded_valid === 1 && ((document.is_signed_valid === 0 && document.signed_document_filename !== null) || (document.is_signed_valid === null && document.signed_document_filename === null))).map((file) => (
                <FileUpload
                  key={file.id}
                  multiple={false}
                  label={`CARICA ${file.optional_document_name.toUpperCase()}`}
                  handleFile={(f) => uploadSignedOptionalDocument(file.id, f)}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="col-6 mt-3 mt-xl-0 ">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Documenti Da Firmare</h4>
            </div>
            <div className="card-body px-0">
              {showAfterSaleUploadedDocuments()}
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
