import { FormGroup, Label } from './styles/common';

export function FormInputTextarea({
  label, placeholder, rows, registration, error, valid, max, value,
}) {
  return (
    <FormGroup>
      {label !== undefined
        && <Label>{label}</Label>}
      <textarea
        placeholder={placeholder}
        rows={rows}
        maxLength={max}
        value={value}
        className={`form-control text-center ${valid ? 'is-valid' : ''} ${error ? 'is-invalid' : ''}`}
        {...registration}
      />
      <div className="invalid-feedback">
        {error}
      </div>
    </FormGroup>
  );
}
