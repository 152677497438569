import styled from 'styled-components';

export const AudioContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ListeningBox = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader {
  height: 70px;
  display: flex;
  align-items: center;
}


.loader .stroke {
  display: block;
  position: relative;
  background: var(--greenia-color);
  height: 100%;
  width: 10px;
  border-radius: 50px;
  margin: 0 5px;
  animation: animate 1.2s linear infinite;
}

@keyframes animate {
  50% {
    height: 20%;
  }
  
  100% {
    height: 100%;
  }
}

.stroke:nth-child(1) {
  animation-delay: 0s;
}

.stroke:nth-child(2) {
  animation-delay: 0.3s;
}
.stroke:nth-child(3) {
  animation-delay: 0.6s;
}
.stroke:nth-child(4) {
  animation-delay: 0.9s;
}
.stroke:nth-child(5) {
  animation-delay: 0.6s;
}
.stroke:nth-child(6) {
  animation-delay: 0.3s;
}
.stroke:nth-child(7) {
  animation-delay: 0s;
}
`;

export const ListeningContainer = styled.div`
  background-color: #fff;
  min-width: 400px;
  min-height: 300px;
  border-radius: 10px;
`;
