import styled from 'styled-components';

export const RcQuotesStyles = styled.div`
    padding: 20px;
    width: 100%;
    max-width: 1400px;
    margin: auto;

    .failed-quotations-block {
        margin-top: 120px;
    }
    .failed-quotations-block img {
        width: 100%;
        display: block;
        aspect-ratio: 6/3;
        object-fit: contain;
        mix-blend-mode: multiply;
    }
`;
