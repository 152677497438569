import React from 'react';
import { FormInputText } from '../../formInputs/FormInputText';
import PaymentTypePeriod from '../../components/inputs/PaymentTypePeriod';
import { greeniaPrivacyPolicy } from '../../../services/axios-client/axeCommons';
import InputPrivacy from '../../components/inputs/InputPrivacy';

function RcMotoStepFour({
  answers, register, errors, isValid, updateAnswers, dataPrivacy, privacyItemChangeHandler,
}) {
  return (
    <>
      <div className="form-container" id="calcolare">
        {answers.gender !== 'G' && (
        <>
          <FormInputText
            registration={register('name')}
            error={errors.name?.message}
            valid={isValid('name')}
            label="Nome"
          />
          <FormInputText
            registration={register('surname')}
            error={errors.surname?.message}
            valid={isValid('surname')}
            label="Cognome"
          />
        </>
        )}
        <FormInputText
          registration={register('email')}
          error={errors.email?.message}
          valid={isValid('email')}
          label="E-mail"
        />
        <FormInputText
          registration={register('phone')}
          error={errors.phone?.message}
          valid={isValid('phone')}
          label="Telefono"
          paragraph="Numeri di rete fissa non accettati dalle aziende"
        />

        <div className="periodicitaDeiPagamenti">
          <PaymentTypePeriod
            selectedType={answers.payment_frequency}
            updateAnswers={updateAnswers}
          />
        </div>

      </div>

      <div className="terms">
        <h5 className="text-center">Informativa Privacy e IVASS</h5>

        <div className="text-center text-decoration-underline mt-2">
          <a
            onClick={() => greeniaPrivacyPolicy()}
            style={{ cursor: 'pointer' }}
          >
            <small className="text-center">Greenia Privacy Policy</small>
          </a>
        </div>

        {dataPrivacy.map((item, i) => (
          <InputPrivacy
            required={item.required}
            label={item.content}
            name={item.type}
            id={item.type}
            key={i}
            checked={answers.privacies.includes(item.id)}
            onChange={(value) => privacyItemChangeHandler(value, item)}
          />
        ))}
      </div>
    </>
  );
}

export default RcMotoStepFour;
