import axios from 'axios';
import { QUOTATION_SERVICE_API_URL } from '../../config';
import { backOfficeAxeRequestInterceptor, backOfficeAxeResponseInterceptor } from './axeHelpers';

export const quotationsBackofficeAxe = axios.create();

quotationsBackofficeAxe.defaults.validateStatus = (status) => status < 500;

quotationsBackofficeAxe.interceptors.request.use(backOfficeAxeRequestInterceptor);

quotationsBackofficeAxe.interceptors.response.use((response) => response, backOfficeAxeResponseInterceptor);

quotationsBackofficeAxe.defaults.baseURL = `${QUOTATION_SERVICE_API_URL}/backoffice`;
