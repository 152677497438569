import { useEffect, useState } from 'react';
import { FormGroup, Label } from './styles/common';

export function FormInputSelectWithSearch(props) {
  const {
    options, onChange, returnType, disabled, value, error, valid,
  } = props;
  const [searchFraze, setSearchFraze] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [dropdownActive, setDropdownActive] = useState(false);

  const handleChange = (opt, dontCallChange) => {
    let fraze = opt.name;
    const code = opt.communal_territory ? `(${opt.communal_territory.car_plate_symbol})` : '';
    fraze += code;
    setSearchFraze(fraze);
    setDropdownActive(false);
    if (returnType === 'object') {
      if (dontCallChange !== true) {
        onChange(opt);
      }
    } else if (returnType && returnType !== 'object') {
      if (dontCallChange !== true) {
        onChange(opt[returnType]);
      }
    } else if (dontCallChange !== true) {
      onChange(opt.id);
    }
  };

  const handleSearch = ({ target }) => {
    if (target.value === '') {
      setSearchFraze(target.value);
      setFilteredOptions([]);
      setDropdownActive(false);
    } else {
      const filtered = options.filter((item) => item.name.toLowerCase().startsWith(target.value.toLowerCase()));
      setSearchFraze(target.value);
      setFilteredOptions(filtered);
      if (filtered.length) {
        setDropdownActive(true);
      } else {
        setDropdownActive(false);
      }
    }
  };

  useEffect(() => {
    if (disabled) {
      setSearchFraze('');
    }
  }, [disabled]);

  useEffect(() => {
    if (typeof value === 'object' && value.value === undefined) {
      if (value.residence_commune_code !== null || value.residence_commune_code !== '') {
        const selected = options.filter((item) => item.cadastral_code === value.residence_commune_code);
        if (selected.length) handleChange(selected[0], true);
      }
    } if (typeof value === 'object' && value.value !== undefined) {
      if (value.value !== null || value.value !== '') {
        const selected = options.filter((item) => item.cadastral_code === value.value);
        if (selected.length) handleChange(selected[0], true);
      }
    } else if (value !== null || value !== '') {
      const selected = options.filter((item) => item.id === value);
      if (selected.length) handleChange(selected[0], true);
    }
  }, [value]);

  return (
    <div className="d-flex align-items-start justify-content-between flex-wrap" style={{ columnGap: 20 }}>
      <FormGroup className="position-relative" style={{ flex: 2 }}>
        <Label>{props.label}</Label>
        <input value={searchFraze} type="text" disabled={disabled} onChange={handleSearch} className={`form-control text-center ${error ? 'is-invalid' : ''} ${valid ? 'is-valid' : ''}`} placeholder={props.placeholder} />
        <div className="invalid-feedback">{error}</div>
        {dropdownActive
          && (
            <div className="form-select-dropdown-new text-center">
              {filteredOptions.length > 0 && filteredOptions.map((opt) => (
                <span key={opt.id} onClick={() => handleChange(opt)}>
                  {opt.name}
                  {' '}
                  {opt.communal_territory ? `(${opt.communal_territory.car_plate_symbol})` : ''}
                  {' '}
                </span>
              ))}
            </div>
          )}

      </FormGroup>
      {props.children !== undefined
        && (
          <div style={{ flex: 1, flexBasis: 50 }}>
            {props.children}
          </div>
        )}
    </div>
  );
}
