import { generalAlertError } from '../../common/utils/alerts';
import axe from '../../services/axios-client/axiosClient';
import caller from './speechSlice';

export const sendAudioForTranscription = async (data) => {
  caller.speechTranscriptionPending();

  try {
    const response = await axe.post('/speech/audio/to/text', data);
    caller.speechTranscriptionSuccess(response.data.data);
  } catch (error) {
    caller.speechTranscriptionRejected(error);
  }
};

export const sendAudioForTranscriptionReturnData = async (data) => {
  try {
    const response = await axe.post('/speech/audio/to/text', data);
    return response.data.data.transcriptions;
  } catch (error) {
    generalAlertError('Non è stato possibile trascrivere il messaggio');
    return null;
  }
};

export const resetTranscription = async () => {
  caller.speechTranscriptionReset();
};
