import React from 'react';
import { RiskButton, RiskButtonRemove } from '../Cards/ProfessionInfoCard.styles';

function ButtonComponentLogicBackoffice({
  type, processingQuote, tutelaLegaleQuote, rivalsaQuote, cristalliQuote, infortuniConducenteQuote, assistenzaStradaleQuote,
  removeItemFromCart, requestInfo,
}) {
  return (
    <div className="d-flex justify-content-end ms-auto gap-2">
      {(type.id === 'res_civile' && processingQuote !== null) && <RiskButtonRemove className="btn btn-danger" onClick={() => removeItemFromCart(processingQuote.id)}>Rimuovi</RiskButtonRemove>}

      {(type.id === 'tutela_legale' && tutelaLegaleQuote !== null) ? <RiskButtonRemove className="btn btn-danger" onClick={() => removeItemFromCart(tutelaLegaleQuote.id)}>Rimuovi</RiskButtonRemove> : type.id === 'tutela_legale' && tutelaLegaleQuote === null && processingQuote !== null ? <RiskButton className="btn btn-success" data-bs-toggle="modal" data-bs-target={`#${type.id}`}>Aggiungi</RiskButton> : ''}

      {(type.id === 'assistenza_stradale' && assistenzaStradaleQuote !== null) ? <RiskButtonRemove className="btn btn-danger" onClick={() => removeItemFromCart(assistenzaStradaleQuote.id)}>Rimuovi</RiskButtonRemove> : type.id === 'assistenza_stradale' && assistenzaStradaleQuote === null && processingQuote !== null ? <RiskButton className="btn btn-success" data-bs-toggle="modal" data-bs-target={`#${type.id}`}>Aggiungi</RiskButton> : ''}

      {(type.id === 'infortuni_conducente' && infortuniConducenteQuote !== null) ? <RiskButtonRemove className="btn btn-danger" onClick={() => removeItemFromCart(infortuniConducenteQuote.id)}>Rimuovi</RiskButtonRemove> : type.id === 'infortuni_conducente' && infortuniConducenteQuote === null && processingQuote !== null ? <RiskButton className="btn btn-success" data-bs-toggle="modal" data-bs-target={`#${type.id}`}>Aggiungi</RiskButton> : ''}

      {(type.id === 'cristalli' && cristalliQuote !== null) ? <RiskButtonRemove className="btn btn-danger" onClick={() => removeItemFromCart(cristalliQuote.id)}>Rimuovi</RiskButtonRemove> : type.id === 'cristalli' && cristalliQuote === null && processingQuote !== null ? <RiskButton className="btn btn-success" data-bs-toggle="modal" data-bs-target={`#${type.id}`}>Aggiungi</RiskButton> : ''}

      {(type.id === 'rivalsa' && rivalsaQuote !== null) ? <RiskButtonRemove className="btn btn-danger" onClick={() => removeItemFromCart(rivalsaQuote.id)}>Rimuovi</RiskButtonRemove> : type.id === 'rivalsa' && rivalsaQuote === null && processingQuote !== null ? <RiskButton className="btn btn-success" data-bs-toggle="modal" data-bs-target={`#${type.id}`}>Aggiungi</RiskButton> : ''}

      {(requestInfo.processing_quotation_id === null && type.id !== 'res_civile') && <div className="alert alert-warning py-2 px-2">Seleziona Prima La Responsibilita Civile</div>}

      {type.id === 'res_civile' && <RiskButton className="btn btn-success" data-bs-toggle="modal" data-bs-target={`#${type.id}`}>Aggiungi</RiskButton>}
    </div>
  );
}

export default ButtonComponentLogicBackoffice;
