import React from 'react';
import { FormInputCheckbox } from '../../formInputs/FormInputCheckbox';
import { FormInputText } from '../../formInputs/FormInputText';
import ActiveClientModal from '../../components/elements/ActiveClientModal';
import { flagResponse, genders } from '../../constants';
import { FormInputRadio } from '../../formInputs/FormInputRadio';
import { FormInputSelect } from '../../formInputs/FormInputSelect';
import { FormInputDate } from '../../formInputs/FormInputDate';
import getMaxAllowedBirthday from '../../helpers/getMaxAllowedBirthday';
import getAllowedLicenseYears from '../../helpers/getAllowedLicenseYears';
import FormReactSelectInputBirthPlace from '../../formInputs/FormReactSelectInputBirthPlace';
import { FormInputAddressComplete } from '../../formInputs/FormInputAddressComplete';
import OffcanvasForm from '../../components/elements/OffcanvasForm';
import { validateItalianPostCode } from '../../validators';

function RcMotosStepTwo({
  register, errors, answers, onSearchChange, searchClient, searchResults, getCustomerData, selectedClient, closeClientModal, confirmActiveClient, municipalities, states, formData, isValid, updateFormData, driverFormComponentRef, driverForm, setDriverForm, driver, updateDriverFormData, ownerFormComponentRef, ownerForm, setOwnerForm, owner, updateOwnerFormData,
}) {
  return (
    <div className="form-container" id="datiPersonali">
      <div>
        <FormInputCheckbox
          registration={register('existing_customer')}
          error={errors.existing_customer?.message}
          label=" Cliente Esistente?"
        />
        {answers.existing_customer && (
        <div className="d-flex align-items-start justify-content-center gap-2">
          <div className="position-relative">
            <FormInputText
              placeholder="Nome cliente..."
              onChange={(value) => onSearchChange(value)}
              value={searchClient}
            />
            {searchResults.length > 0 && (
            <div
              className="position-absolute"
              style={{
                top: '80%',
                left: '0%',
                right: '0%',
                zIndex: 50,
              }}
            >
              <ul className="list-group">
                {searchResults.map((customer, index) => (
                  <li
                    className="list-group-item list-group-item-dark"
                    key={index}
                    style={{ cursor: 'pointer' }}
                    onClick={() => getCustomerData(customer.id)}
                  >
                    {customer.name.toUpperCase()
                                + (customer.fiscal_code !== null
                                  ? ` - (${customer.fiscal_code})`
                                  : '')}
                  </li>
                ))}
              </ul>
            </div>
            )}
          </div>
          <button
            className="btn btn-questionnaire py-1 px-2 "
            style={{
              marginTop: 10,
              display: 'block',
              width: '150px',
              border: '3px solid',
            }}
          >
            Cerca cliente
          </button>
        </div>
        )}

        {selectedClient !== null ? (
          <ActiveClientModal
            closeClientModal={closeClientModal}
            client={selectedClient}
            genders={genders}
            confirmActiveClient={confirmActiveClient}
            municipalities={municipalities}
            states={states}
            formData={formData}
          />
        ) : (
          ''
        )}
      </div>

      <FormInputRadio
        label="Il contraente è"
        options={genders}
        name="gender"
        registration={register('gender')}
        error={errors.gender?.message}
      />
      {answers.gender === 'G' && (
      <>
        <FormInputText
          registration={register('business_name')}
          error={errors.business_name?.message}
          valid={isValid('business_name')}
          label="Ragione sociale"
        />
        <FormInputText
          registration={register('vat_number')}
          error={errors.vat_number?.message}
          valid={isValid('vat_number')}
          label="Partita Iva"
        />
        <FormInputSelect
          label="Tipologia azienda"
          registration={register('company_type')}
          error={errors.company_type?.message}
          valid={isValid('company_type')}
          placeholder="-Seleziona-"
          options={formData.company_types}
        />
      </>
      )}
      {answers.gender !== 'G' && (
      <>
        <FormInputDate
          label="Data di nascita"
          registration={register('date_of_birth')}
          minDate="1935-01-01"
          maxDate={getMaxAllowedBirthday()}
          error={errors.date_of_birth?.message}
          valid={isValid('date_of_birth')}
        />
        {answers.date_of_birth !== '' && (
        <FormInputSelect
          label="Anno patente"
          registration={register('driving_license_year')}
          error={errors.driving_license_year?.message}
          valid={isValid('driving_license_year')}
          placeholder="-Seleziona-"
          options={getAllowedLicenseYears(answers.date_of_birth)}
        />
        )}
        <FormReactSelectInputBirthPlace
          states={states}
          options={municipalities}
          label="Luogo di nascita"
          name="commune_of_birth_code"
          values={{
            commune_of_birth_code: answers.commune_of_birth_code,
            province_of_birth_code: answers.province_of_birth_code,
            born_abroad: answers.born_abroad,
            country_of_birth_code: answers.country_of_birth_code,
          }}
          onChange={(value) => updateFormData({
            commune_of_birth_code: value.commune_of_birth_code,
            province_of_birth_code: value.province_of_birth_code,
            born_abroad: value.born_abroad,
            country_of_birth_code: value.country_of_birth_code,
          })}
          valid={isValid('commune_of_birth_code')}
          error={errors.commune_of_birth_code?.message}
        />
      </>
      )}

      <FormInputAddressComplete
        municipalities={municipalities}
        formData={answers}
        isValid={isValid}
        errors={errors}
        register={register}
        updateFormData={updateFormData}
        addressId="autocomplete-address-contractor"
      />

      {answers.gender !== 'G' && (
      <>
        <FormInputRadio
          label="Figli conviventi?"
          options={flagResponse}
          name="children"
          registration={register('children')}
          error={errors.children?.message}
        />
        <FormInputSelect
          placeholder="Stato civile"
          label="Stato civile"
          registration={register('civil_status_id')}
          error={errors.civil_status_id?.message}
          valid={isValid('civil_status_id')}
          options={formData.marital_statuses}
        />
        <FormInputSelect
          placeholder="Titolo di studio"
          label="Titolo di studio"
          registration={register('education_level_id')}
          error={errors.education_level_id?.message}
          valid={isValid('education_level_id')}
          options={formData.qualifications}
        />
        <FormInputSelect
          placeholder="Professione"
          label="Professione"
          registration={register('profession_id')}
          error={errors.profession_id?.message}
          valid={isValid('profession_id')}
          options={formData.professions}
        />
      </>
      )}

      {answers.gender !== 'G' && (
      <FormInputRadio
        label="Il contraente è anche il conducente abituale del veicolo?"
        options={flagResponse}
        name="contractor_is_driver"
        registration={register('contractor_is_driver')}
        error={errors.contractor_is_driver?.message}
      />
      )}

      {answers.contractor_is_driver === '0' ? (
        <>
          <OffcanvasForm
            ref={driverFormComponentRef}
            filled={driverForm}
            setFilled={setDriverForm}
            id="dati-driver"
            title="Dati Del Conduttore"
            data={driver}
            onChange={updateDriverFormData}
            genders={genders}
            getMaxAllowedBirthday={getMaxAllowedBirthday}
            municipalities={municipalities}
            validateItalianPostCode={validateItalianPostCode}
            marital_statuses={formData.marital_statuses}
            qualifications={formData.qualifications}
            professions={formData.professions}
            company_types={formData.company_types}
            isOwnerForm={false}
            states={states}
          />
        </>
      ) : (
        ''
      )}

      {answers.gender !== 'G' && (
      <FormInputRadio
        label="Il contraente è anche proprietario dell moto?"
        options={flagResponse}
        name="contractor_is_owner"
        registration={register('contractor_is_owner')}
        error={errors.contractor_is_owner?.message}
      />
      )}

      {answers.contractor_is_owner === '0' ? (
        <>
          <OffcanvasForm
            ref={ownerFormComponentRef}
            filled={ownerForm}
            states={states}
            setFilled={setOwnerForm}
            id="dati-proprietario"
            title="Dati Del Proprietario"
            data={owner}
            onChange={updateOwnerFormData}
            genders={genders}
            getMaxAllowedBirthday={getMaxAllowedBirthday}
            municipalities={municipalities}
            validateItalianPostCode={validateItalianPostCode}
            marital_statuses={formData.marital_statuses}
            qualifications={formData.qualifications}
            professions={formData.professions}
            company_types={formData.company_types}
            isOwnerForm
          />
        </>
      ) : (
        ''
      )}
    </div>
  );
}

export default RcMotosStepTwo;
