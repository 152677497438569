import managementAxe from '../../services/axios-client/managementServisAxeClient';
import caller from './ticketsSlice';

const serverError = 'Si è verificato un problema con il server';

export const listTickets = async (page, search) => {
  caller.getTicketsPending();

  try {
    const res = await managementAxe.get('/broker/tickets/get-tickets', {
      params: {
        page,
        search,
      },
    });

    caller.getTicketsSuccess(res.data);
  } catch (err) {
    caller.getTicketReject('Server Error');
  }
};

export const searchForTickets = async (page, search) => {
  caller.searchTicketsPending();

  try {
    const res = await managementAxe.get('/broker/tickets/get-tickets', {
      params: {
        page,
        search,
      },
    });

    caller.searchTicketsSuccess(res.data);
  } catch (err) {
    caller.searchTicketsReject('Server Error');
  }
};

export const getTicket = async (ticketId) => {
  caller.getTicketPending();

  try {
    const res = await managementAxe.get(
      `/broker/tickets/get-ticket/${ticketId}`,
    );

    if (res.status !== 200) {
      caller.getTicketReject(serverError);
      return;
    }

    caller.getTicketSuccess(res.data.data);
  } catch (err) {
    caller.getTicketReject(serverError);
  }
};
