import styled from 'styled-components';

export const PaginationStyles = styled.nav``;

export const PaginationList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  gap: 5px;

  li {
    &.disabled-link {
      background-color: lightgray;
      border-radius: 5px;

      button {
        border: 1px solid lightgray;
        border-radius: 5px;
        color: gray;
      }
    }

    &.active-link {
      background-color: #00a651;
      border-radius: 5px;

      button {
        border: 1px solid #00a651;
        border-radius: 5px;
        color: white;
      }
    }

    button {
      border: 1px solid #00a651;
      background-color: transparent;
      color: #00a651;
      border-radius: 5px;
      padding: 5px 10px;
      transition: all 200ms ease-in-out;

      &:hover {
        background-color: #00a651;
        color: white;
      }
    }
  }
`;
