import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import moment from 'moment';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { OptionModalStyles } from './OptionModal.styles';
import { addNewCancellation } from '../../features/afterSale/afterSaleActions';
import UploadInput from '../../common/components/UploadInput';
import FileWrapper from '../../common/components/FileWrapper';
import { FormInputDate } from '../../common/formInputs/FormInputDate';
import { FormInputTextarea } from '../../common/formInputs/FormInputTextarea';

const schema = yup.object({
  file: yup.object().required('Il campo è obbligatorio'),
  note: yup.string().required('Il campo è obbligatorio'),
  date: yup.string().required('Il campo è obbligatorio'),

});

const asInputOptions = {
  shouldDirty: true,
  shouldTouch: true,
  shouldValidate: true,
};

export function Cancellation({ quotationId, selectOption, location }) {
  const [files, setFiles] = useState([]);
  const isOpenedByCommand = location.state !== undefined && location.state?.type === 'command'
    && location.state.data?.page === 'cancellation';

  const { loading, error } = useSelector((store) => store.afterSale.cancellation);

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    setValue,
    watch,
    reset: resetForm,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    values: isOpenedByCommand ? {
      file: null,
      note: location.state.data.note ?? '',
      date: (location.state.data.date !== null || location.state.data.date !== '') ? moment(location.state.data.date).format('YYYY-MM-DD') : '',
    } : {
      file: null,
      note: '',
      date: '',
    },
  });

  const fileInputHandler = (files) => {
    const file = files[0];
    let baseURL = '';
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const fileType = file.type;
    reader.onload = () => {
      baseURL = reader.result;
      setValue('file', {
        original_filename: file.name,
        content: baseURL.replace(`data:${fileType};base64,`, ''),
      }, asInputOptions);

      setFiles([{
        filename: file.name,
        content: baseURL,
      }]);
    };
  };

  const resetEverything = () => {
    resetForm();
    selectOption(null);
  };

  const submitData = (formData) => {
    addNewCancellation(quotationId, formData, resetEverything);
  };

  const removeFileHandler = () => {
    setValue('file', null, asInputOptions);
  };

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  const answers = watch();

  return (
    <OptionModalStyles>
      <div className="option-modal-content">
        <h1 className="option-modal-title">Cessazione</h1>

        {error !== null
          && <div className="alert alert-danger">{error}</div>}

        <form onSubmit={handleSubmit(submitData)} className="report-form-container">

          <div className="row">
            <FormInputDate
              label="Data"
              minDate={moment().format('YYYY-MM-DD')}
              registration={register('date')}
              error={errors.date?.message}
              valid={isValid('date')}
            />
          </div>
          <div className="row">
            <FormInputTextarea
              placeholder="Note"
              label="Note"
              registration={register('note')}
              rows="3"
              error={errors.note?.message}
              valid={isValid('note')}
            />
          </div>
          <div className="flex-column mt-3 align-items-center justify-content-center gap-2">
            <UploadInput
              uploadHandler={fileInputHandler}
              text={
                answers.file !== null
                  ? 'Puoi sostituire il documento'
                  : 'Puoi caricare un documento'
              }
              invalid={false}
              className="mt-3 pl-4"
            />
            <div className="general-input-error">
              {errors.file?.message}
            </div>

            <h6 className="mt-3" hidden={answers.file === null}>
              Documento caricato
            </h6>

            {answers.file !== null
              && (
                <FileWrapper
                  className="wide-file-wrapper"
                  files={files}
                  removeFileAction={removeFileHandler}
                />
              )}

            <div className="option-modal-actions">
              <button
                className="option-modal-button option-modal-button-close"
                onClick={() => selectOption(null)}
              >
                Chiudi
              </button>
              <button
                disabled={loading}
                type="submit"
                className="option-modal-button option-modal-button-submit"
              >
                Invia
                {loading === true
                  && <div className="spinner-border text-light" role="status" />}
              </button>
            </div>
          </div>
        </form>
      </div>
    </OptionModalStyles>
  );
}
