import styled from 'styled-components';

export const ClaimsManagementReportsStyles = styled.div`
    .report-main-container {
        margin-top: 0.626rem;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
    }

    .quote-container {
        width: 40%;
        overflow-y: auto;
        max-height: 50%;
    }

    .report-container {
        width: 50%;
        margin-left:10px;
        padding-left:10px;
        border-left: 2px solid #dedede;
    }

    .report-form-container {
        max-width: 1080px; 
        margin: 0 auto; 
    }
    
    .mt-60 {
        margin-top: 60px;
    }
    @media (max-width: 600px) {
    .report-main-container {
        display: flex;
        flex-direction: columns;
    }

    .quote-container {
        width: 100%;
    }

    .report-container {
        margin-top: 0.626rem;
        width: 100%;
        border-left: none;
        margin-left:0;
        padding-left:0;

    }
    }

    .submit-button{
        padding:10px;
        font-size: inherit;
        margin-top: 10px;
    }

    .options {
        width: 100%;
        max-width: 1000px;
        margin: auto;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .option-button {
        margin: 5px;
        padding: 20px;
        width: 100%;
        max-width: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: var(--greenia-color);
        background-color: rgba(0, 200, 0, 0);
        border: 2px solid var(--greenia-color);
        border-radius: 20px;
        cursor: pointer;
        transition: .3s;
        
    }

    .option-button:hover {
        background-color: rgba(0, 200, 0, .3);
    }

    .option-button-icon > * {
        height: 40px;
        width: 40px;
    }

    .option-button-text {
        font-size: 20px;
    }

    .post-vendita-button{
        padding: 5px 15px;
        font-weight: normal;
        background-color: #00a651;
        color: #ffffff;
        border: 1px solid #00a651;
        transition: all ease-in-out 300ms;
        cursor: pointer;
        border-radius: 5px;
        font-size: 12px;
        text-transform: uppercase;

        &:hover {
        background-color: transparent;
        color: #00a651;
        font-weight: normal;
        }
        &:focus{
            box-shadow: none;
        }
        }

        .post-vendita-text-border{
            border: 2px solid #00a651;
            border-radius: 8px;
            
        }
`;
