import React from 'react';
import ProcessingQuoteSelectedAuto from './ProcessingQuoteSelectedAuto';
import TutelaSelectedAuto from './TutelaSelectedAuto';
import AssistenzaStradaleSelectedAuto from './AssistenzaStradaleSelectedAuto';
import InfortuniConducenteSelectedAuto from './InfortuniConducenteSelectedAuto';
import CristalliSelectedAuto from './CristalliSelectedAuto';
import RivalsaSelectedAuto from './RivalsaSelectedAuto';

function SelectedQuotesCards({
  processingQuote, type, refresh, tutelaLegaleQuote, cristalliQuote, rivalsaQuote,
  assistenzaStradaleQuote, infortuniConducenteQuote, requestInfo,
}) {
  return (
    <>
      {(processingQuote !== null && type.id === 'res_civile') && <ProcessingQuoteSelectedAuto processingQuote={processingQuote} requestInfo={requestInfo} refresh={refresh} />}

      {(processingQuote !== null && processingQuote.company_id !== 2 && type.id === 'tutela_legale') && <TutelaSelectedAuto tutelaLegaleQuote={tutelaLegaleQuote} type={type} />}
      {(processingQuote !== null && processingQuote.company_id !== 2 && type.id === 'assistenza_stradale') && <AssistenzaStradaleSelectedAuto assistenzaStradaleQuote={assistenzaStradaleQuote} type={type} />}
      {(processingQuote !== null && processingQuote.company_id !== 2 && type.id === 'infortuni_conducente') && <InfortuniConducenteSelectedAuto infortuniConducenteQuote={infortuniConducenteQuote} type={type} />}
      {(processingQuote !== null && processingQuote.company_id !== 2 && type.id === 'cristalli') && <CristalliSelectedAuto cristalliQuote={cristalliQuote} type={type} />}
      {(processingQuote !== null && processingQuote.company_id !== 2 && type.id === 'rivalsa') && <RivalsaSelectedAuto rivalsaQuote={rivalsaQuote} type={type} />}
    </>
  );
}

export default SelectedQuotesCards;
