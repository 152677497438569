import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CollaboratorsAnalisysStyles } from './CollaboratorsAnalisys.styles';
import PageLoading from '../../common/components/elements/PageLoading';
import { getCollaboratorsAnalisys, resetCollaboratorsAnalisys } from '../../features/networkAnalisys/networkAnalisysActions';
import { generalAlertError } from '../../common/utils/alerts';

function CollaboratorsAnalisys() {
  const [search, setSearch] = useState('');
  const { company_collaborations, loading } = useSelector((store) => store.analisys.company_collaborations);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (search === '') {
      generalAlertError('Numero iscrizione non valida');
      return;
    }
    getCollaboratorsAnalisys(search);
  };

  useEffect(() => resetCollaboratorsAnalisys(), []);

  if (loading) {
    return <PageLoading />;
  }
  return (
    <CollaboratorsAnalisysStyles>
      <h5 className="mb-4">Analisi Compagnie</h5>
      <form
        onSubmit={handleSubmit}
        className="w-100 d-flex align-items-center justify-content-start flex-column flex-md-row"
      >
        <input
          type="text"
          name="search"
          onChange={handleSearch}
          value={search}
          className="search-input form-control mb-2 mb-md-0 me-2"
          placeholder="Ricerca con numero iscrizione..."
        />
        <button type="submit" className="search-btn w-25">
          Cerca
        </button>
        <div className="w-100 d-none d-md-block" />
      </form>
      <p className="fw-bold fst-italic mt-2">Inserisci il numero di iscrizione intermediario Sezione E! </p>

      <div className="mt-5">
        <h5 className="h5 mb-2">
          Totale Associati:
          <span className="badge bg-success">{company_collaborations.length}</span>
        </h5>
        <hr />
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Numero Iscrizione</th>
                <th scope="col">Cognome Nome</th>
                <th scope="col">Stato</th>
                <th scope="col">Data Di Nascita</th>
                <th scope="col">Commune Di Nascita</th>
                <th scope="col">Compania</th>
                <th scope="col">Ragione Sociale</th>
                <th scope="col">Data Iscrizione</th>
              </tr>
            </thead>
            <tbody>
              {company_collaborations.map((item, index) => (
                <tr key={index}>
                  <td>{item.NUMERO_ISCRIZIONE_RUI}</td>
                  <td>{item.COGNOME_NOME}</td>
                  <td>{item.STATO}</td>
                  <td>{item.DATA_NASCITA}</td>
                  <td>
                    {item.COMUNE_NASCITA !== ''
                      && (
                        <>
                          {item.COMUNE_NASCITA}
                          ,
                          {' '}
                          {item.PROVINCIA_NASCITA}
                        </>
                      )}
                  </td>
                  <td>
                    {item.COMPANY}
                  </td>
                  <td>
                    {item.RAGIONE_SOCIALE ? item.RAGIONE_SOCIALE : 'Nessuna Ragione Sociale Trovata'}
                  </td>
                  <td>
                    {item.DATA_ISCRIZIONE}
                  </td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>
      </div>
    </CollaboratorsAnalisysStyles>
  );
}

export default CollaboratorsAnalisys;
