import styled from 'styled-components';

export const CardItemStyles = styled.div`
  width: 80%;
  height: fit-content;

  .card-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 3px solid green;
    border-radius: 10px;
    padding: 10px;
  }
`;
