export function validateCarPlate(value) {
  const pattern = /^[A-Z]{2}[0-9]{3}[A-Z]{2} *$/;
  return value.toUpperCase().match(pattern);
}
export function validateVatNumber(value) {
  const pattern = /^[0-9]{11}$/;
  return value.match(pattern);
}
export function validateItalianPostCode(value) {
  const pattern = /^[0-9]{5}$/;
  return value.match(pattern);
}
export function validateBirthday(date) {
  const maxBirthDate = new Date();
  maxBirthDate.setFullYear(maxBirthDate.getFullYear() - 18);
  const BDay = new Date(date);
  return BDay < maxBirthDate;
}

export function validateMotoPlate(value) {
  const pattern = /^[A-Z]{2}[0-9]{5} *$/;
  return value.toUpperCase().match(pattern);
}
