export default function getAllowedLicenseYears(birthday) {
  const bDay = new Date(birthday);
  const bYear = bDay.getFullYear();
  const minYear = bYear + 18;
  const maxYear = new Date().getFullYear();
  const y = [];
  for (let i = maxYear; i >= minYear; i--) {
    y.push({ id: i, name: i, value: i });
  }
  return y;
}
