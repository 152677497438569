import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MessageThreadStyles } from './MessageThread.styles';
import timeIcon from '../assets/images/times.png';
import PageLoading from '../common/components/elements/PageLoading';
import { unReadNotifications } from '../features/utilities/utilitiesActions';
import { showOneThread, threadReply } from '../features/threads/threadsActions';
import ErrorComponent from '../common/components/ErrorComponent';

function MessageThread() {
  const {
    thread_single: {
      loading, thread, messages, error,
    },
    thread_reply,
  } = useSelector((store) => store.threads);
  const [subject, setSubject] = useState('');
  const { threadId } = useParams();
  const bottomRef = useRef(null);
  const navigate = useNavigate();

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleData = ({ target: { value } }) => {
    setSubject(value);
  };

  const sendMessage = () => {
    threadReply(threadId, { subject }, () => {
      setSubject('');
      showOneThread(threadId);
    });
  };

  useEffect(() => {
    showOneThread(threadId);
    unReadNotifications();
  }, []);

  if (loading || thread_reply.loading) {
    return <PageLoading />;
  }

  if (error !== null) {
    return <ErrorComponent error={error} />;
  }

  return (
    <MessageThreadStyles>
      <div className="row border col-md-8 mx-auto">

        <div className="col-12 bg-greenia-green text-white border py-2 px-4 mb-0">
          <h2 className="h4 text-center mb-0">
            <span className="fw-normal">Oggetto: </span>
            {thread.subject}
          </h2>
        </div>
        <div className="col-12 border mb-0">
          <div className="message-container">
            {messages
              ? messages.map((message, index) => (
                <div key={index} className="message">
                  <div className="sender-title d-flex align-items-center justify-content-between my-2 px-2">
                    <h6 className="h6 mb-0">
                      <span className="fw-normal">Inviato da:</span>
                      {' '}
                      {message.user !== null
                        ? message.user.name
                        : `${message.broker_agent.name
                        } ${message.broker_agent.surname}`}
                    </h6>
                    <p className="fw-normal realtive mb-0 d-flex align-items-center justify-content-center">
                      <img
                        src={timeIcon}
                        className="img-fluid me-1"
                        width={14}
                        alt=""
                      />
                      <small className="text-muted">
                        {new Date(message.created_at).toLocaleString('it-IT')}
                      </small>
                    </p>
                  </div>
                  <div
                    className="message-box"
                    style={{
                      marginRight: message.user !== null ? '70px' : '0',
                      marginLeft: message.user !== null ? '0' : '70px',
                      backgroundColor:
                        message.user_id === null
                          ? 'lightgrey'
                          : 'rgb(120,200,120)',
                    }}
                  >
                    <p className="mb-0">{message.body}</p>
                  </div>
                  <hr />
                </div>
              ))
              : ''}
            <div ref={bottomRef} />
          </div>
          <div className="reply-box">
            <form onSubmit={sendMessage}>
              <textarea
                className="form-control mb-2 reply-input"
                name="subject"
                id="subject"
                cols="20"
                rows="3"
                placeholder="Invia una risposta..."
                value={subject}
                onChange={handleData}
              />
              <div className="d-flex justify-content-around">
                <button className="btn mb-2 send-message-btn">Invia</button>
                {thread.url && (
                  <button
                    className="btn btn-outline-success mb-2 "
                    onClick={() => navigate(`/quotes/${thread.url}`)}
                  >
                    Vai al preventivo
                  </button>
                )}
              </div>

            </form>
          </div>
        </div>
      </div>
    </MessageThreadStyles>
  );
}

export default MessageThread;
