import FilePreview from './FilePreview';
import { FileWrapperStyles } from './FileWrapper.styles';

export default function FileWrapper({ files, className = '', removeFileAction }) {
  return (
    <FileWrapperStyles>
      <div className={`uploaded-files-wrapper ${className}`}>
        {files.map((file, i) => (
          <FilePreview title={i} id={i} removeFile={() => removeFileAction(i)} filename={file.original_filename} src={`${file.type}${file.content}`} height="400" className="mt-2" key={i} />
        ))}
      </div>
    </FileWrapperStyles>
  );
}
