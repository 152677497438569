import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { LoginStyles } from './Login.styles';

import GreeniaLogo from '../assets/images/greenia-logo.svg';
import Password from '../assets/images/password.png';
import { requestResetPassword } from '../features/auth/authActions';
import { generalAlertSuccess } from '../common/utils/alerts';

const schema = yup.object({
  password: yup
    .string()
    .required('Il campo è obbligatorio')
    .min(6, 'La password deve contenere almeno 6 caratteri')
    .max(60, 'La password deve contenere un massimo di 60 caratteri'),
  password_confirmation: yup.string()
    .required('Il campo è obbligatorio')
    .oneOf([yup.ref('password'), ''], 'Le password non coincidono'),
});

export function ResetPassword() {
  const navigate = useNavigate();
  const { token } = useParams();
  const userState = useSelector((store) => store.user);
  const resetPasswordState = useSelector((store) => store.auth.resetPassword);

  useEffect(() => {
    if (userState.token) {
      navigate('/');
    }
  }, [userState.token]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const submitData = ({ password }) => {
    requestResetPassword(token, { password }, () => {
      generalAlertSuccess('La password è stata cambiata');
      navigate('/login');
    });
  };

  return (
    <LoginStyles>
      <div className="Auth-form-container d-flex justify-content-center bg-img">
        <form onSubmit={handleSubmit(submitData)} className="Auth-form container-form">
          <div className="Auth-form-content">
            <div className="d-flex justify-content-center">
              {' '}
              <img className="img-fluid" width={170} src={GreeniaLogo} alt="Greenia Logo" />
            </div>
            <h3 className="Auth-form-title text-dark fs-5">Cambia la password</h3>
            {resetPasswordState.error !== null
                            && <div className="general-form-error">{resetPasswordState.error}</div>}

            <div className="form-group mt-3">
              <label htmlFor="password">Password</label>
              <div className="input-group inp-login">
                <img className="input-img" src={Password} alt="" />
                <input
                  {...register('password')}
                  type="password"
                  className="form-control mt-1 inpt rounded"
                />
                <div className="general-input-error">{errors.password?.message}</div>
              </div>
            </div>

            <div className="form-group mt-3">
              <label htmlFor="password_confirmation">Confirma la password</label>
              <div className="input-group inp-login">
                <img className="input-img" src={Password} alt="" />
                <input
                  {...register('password_confirmation')}
                  type="password"
                  className="form-control mt-1 inpt rounded"
                />
                <div className="general-input-error">{errors.password_confirmation?.message}</div>
              </div>
            </div>

            <div className="mt-3">
              {resetPasswordState.loading
                ? <button className="btn btn-primary btn-secondary w-100">Attendere prego...</button>
                : <button type="submit" className="btn btn-primary btn-login fw-bold w-100">Imposta</button>}
            </div>
            <p className="forgot-password justify-content-center d-grid gap-2 mt-3 mt-2">
              <Link to="/auth/forgot" className="text-dark">Hai dimenticato la password</Link>
            </p>
          </div>
        </form>
      </div>
    </LoginStyles>
  );
}
