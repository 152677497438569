import React from 'react';
import errorImg from '../../assets/images/error_image.svg';
import { ErrorComponentStyles } from './ErrorComponent.styles';

function ErrorComponent({ error }) {
  return (
    <ErrorComponentStyles className="pt-3 d-flex align-items-center justify-content-center flex-column mt-5">
      <img src={errorImg} alt="Error" />
      <p className="fw-semibold mt-5">{error}</p>
    </ErrorComponentStyles>
  );
}

export default ErrorComponent;
