import styled from 'styled-components';

export const ActiveCustomerDetailsStyles = styled.section`
  #back-button {
    margin: 10px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    height: 40px;
    width: 40px;
    color: var(--greenia-color);
    font-size: 20px;
    border: 1px solid var(--greenia-color);
    border-radius: 100%;
    cursor: pointer;
  }
`;

export const ReadMailBtn = styled.div`
  a {
    padding: 8px 15px;
    font-weight: normal;
    background-color: #00a651;
    color: #ffffff;
    border: 1px solid #00a651;
    transition: all ease-in-out 300ms;
    cursor: pointer;
    border-radius: 5px;
    font-size: 12px;
    text-transform: uppercase;

    &:hover {
      background-color: transparent;
      color: #00a651;
      font-weight: normal;
    }
  }
`;

export const TableRowStyle = styled.tr`
  cursor: pointer;
  transition: all 300ms ease-in-out;

  &:hover {
    background-color: #00a651;
    color: #ffffff;
  }
`;
