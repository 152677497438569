import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import Joyride from 'react-joyride';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import PageLoading from '../../common/components/elements/PageLoading';
import { getBrokerActiveCustomerInformation } from '../../features/activeCustomer/activeCustomerActions';
import { ActiveCustomerDetailsStyles, ReadMailBtn, TableRowStyle } from './ActiveCustomerDetails.styles';
// import { useTourContext } from '../../context/useTourContext';
import ErrorComponent from '../../common/components/ErrorComponent';
// import { joyrideOptions } from '../../common/utils/joyrideUtils';
// import { handleCallbackActiveCustDetails } from '../../common/utils/joyrideCallbacks';
// import { activeCustomerDetailsSteps } from '../../common/utils/joyrideSteps';

function ActiveCustomerDetails() {
  const { activeClientId } = useParams();
  const navigate = useNavigate();
  const {
    active_customer: {
      activeCustomer, activeQuotes, insurance_requests, loading, gmails, contacts, after_sales, error,
    },
  } = useSelector((store) => store.activeCustomers);

  const newQuotation = (activeCustomerId, productType) => {
    if (productType === 'auto') {
      navigate('/quote/auto', {
        state: {
          activeCustomerId,
        },
      });
    }
    if (productType === 'autocarro') {
      navigate('/quote/autocarro', {
        state: {
          activeCustomerId,
        },
      });
    }
    if (productType === 'profession') {
      navigate('/quote/profession', {
        state: {
          activeCustomerId,
        },
      });
    }
    if (productType === 'infortuni') {
      navigate('/quote/metlife/meno-male-gold', {
        state: {
          activeCustomerId,
        },
      });
    }
    if (productType === 'infodrive') {
      navigate('/quote/infodrive', {
        state: {
          activeCustomerId,
        },
      });
    }
  };

  const getTypeName = (type) => {
    if (type === 'replacement') {
      return 'Sostituzione';
    }
    if (type === 'modification') {
      return 'Modifica Dati';
    }
    if (type === 'cancellation') {
      return 'Cessazione';
    }
    if (type === 'accident') {
      return 'Sinistro';
    }
    if (type === 'send_document') {
      return 'Invia Documenti';
    }

    return '';
  };

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('activeClientSinglePage') === 'done') {
  //     setState({ run: false });
  //   } else {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 1500);
  //   }
  // }, [localStorage.getItem('activeClientSinglePage')]);

  // const fireNewQuoteTour = () => {
  //   localStorage.removeItem('activeClientSinglePage');
  //   setState({ run: true, tourActive: true });
  // };

  const navigateToQuoteScreen = (product, quoteId) => {
    if (product === 'auto') {
      navigate(`/quotes/auto/purchase/${quoteId}`);
    }
    if (product === 'autocarro') {
      navigate(`/quotes/autocarro/purchase/${quoteId}`);
    }
    if (product === 'profession' || product === 'rc-professionale') {
      navigate(`/quotes/profession/purchase/${quoteId}`);
    }
    if (product === 'infortuni') {
      navigate(`/quotes/metlife/purchase/${quoteId}`);
    }
    if (product === 'infodrive') {
      navigate(`/quotes/infodrive/purchase/${quoteId}`);
    }
  };

  const sendToInsuranceRequestQuotationsList = (ir) => {
    if (ir.product_id === '1' || ir.product_id === '3') {
      navigate(`/quotes/${ir.request_token}`);
    } else if (ir.product_id === '2') {
      navigate(`/quotes/moto/${ir.request_token}`);
    } else if (ir.product_id === '4') {
      navigate(`/quotes/profession/${ir.request_token}`);
    } else if (ir.product_id === '5') {
      navigate(`/quotes/metlife/${ir.request_token}`);
    } else if (ir.product_id === '6') {
      navigate(`/quotes/infodrive/${ir.request_token}`);
    }
  };

  const navigateToPostVendita = () => {
    navigate('/after-sale', {
      state: {
        type: 'redirect',
        data: {
          activeCustomerId: activeCustomer.id,
          search: activeCustomer.gender === 'G' ? activeCustomer.business_name : `${activeCustomer.name} ${activeCustomer.surname}`,
        },
      },
    });
  };

  // const createClientAppAccount = (id) => {
  //   requestClientAppAccount(id, () => {
  //     getBrokerActiveCustomerInformation(activeClientId);
  //     generalAlertSuccess('Account e stato creato');
  //   });
  // };

  useEffect(() => {
    getBrokerActiveCustomerInformation(activeClientId);
  }, []);

  if (loading) {
    return <PageLoading />;
  }

  return error === null
    ? (
      <ActiveCustomerDetailsStyles>
        {/* <Joyride
          steps={activeCustomerDetailsSteps}
          continuous
          showBeacon={showBeacon}
          locale={{
            back: 'Indietro',
            close: 'Chiudi',
            last: 'Fine',
            next: 'Prossima',
            open: 'Apri il modale',
            restart: 'Riavvia il tour ',
            skip: 'Salta',
          }}
          styles={{
            options: joyrideOptions,
            buttonNext: {
              backgroundColor: '#00a651',
              outline: 'none',
            },
            buttonBack: {
              color: '#00a651',
              outline: 'none',
            },
            beaconInner: {
              backgroundColor: '#00a651',
            },
            beaconOuter: {
              borderColor: '#00a651',
              backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
            },
            buttonSkip: {
              outline: 'none',
            },
            buttonClose: {
              outline: 'none',
            },
          }}
          run={run}
          callback={(data) => handleCallbackActiveCustDetails(data, activeCustomerDetailsSteps, setState)}
          showSkipButton
          showProgress
        /> */}
        <div className="d-flex flex-column gap-2 gap-md-0 flex-md-row align-items-md-center align-items-md-end justify-content-between">
          <div className="d-flex align-items-center justify-content-start">
            <div id="back-button" onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faArrowLeftLong} />
            </div>
            <h5 className="mb-0 me-3 h3">Anagrafica Cliente</h5>
            {/* {localStorage.getItem('activeClientSinglePage') === 'done' && (
              <div style={{ cursor: 'pointer' }} onClick={() => fireNewQuoteTour()}>
                <button className="btn btn-success my-2 mx-0 px-3">Tutorial</button>
              </div>
            )} */}
          </div>
          <div className="active-client-single-page-one">
            <p className="mb-0 fw-bold h6">Gestisci</p>
            <div className="d-flex justify-content-start">
              <button type="button" className="btn btn-outline-success" onClick={() => navigateToPostVendita()}>Post Vendita</button>
            </div>
          </div>
          <div className="active-client-single-page-two">
            <p className="mb-0 fw-bold h6">Fai un nuovo preventivo</p>
            <div className="btn-group" role="group">
              <button type="button" onClick={() => newQuotation(activeClientId, 'auto')} className="btn btn-outline-success">Auto</button>
              <button type="button" onClick={() => newQuotation(activeClientId, 'autocarro')} className="btn btn-outline-success">Autocarro</button>
              {/* <button type="button" onClick={() => newQuotation(activeClientId, 'infortuni')} className="btn btn-outline-success">Infortuni</button> */}
              <button type="button" onClick={() => newQuotation(activeClientId, 'profession')} className="btn btn-outline-success">Professionale</button>
              {/* <button type="button" onClick={() => newQuotation(activeClientId, 'infodrive')} className="btn btn-outline-success">Infodrive</button> */}
            </div>
          </div>
        </div>
        {/* {activeCustomer.has_account === false &&
        <div className="btn btn-success" onClick={() => createClientAppAccount(activeCustomer.id)}>Crea Account per il app</div>
      } */}
        <hr />
        <div className="row">
          <div className="col-12 col-md-6 active-client-single-page-three">
            <h4 className="h5 mt-3">
              Scheda Anagrafica
            </h4>
            <ul className="list-group">
              {activeCustomer.gender !== 'G'
                && (
                  <>
                    <li className="list-group-item">
                      <strong>Nome: </strong>
                      {' '}
                      {activeCustomer.name}
                    </li>
                    <li className="list-group-item">
                      <strong>Cognome: </strong>
                      {' '}
                      {activeCustomer.surname}
                    </li>
                    <li className="list-group-item">
                      <strong>Data di nascita: </strong>
                      {' '}
                      {activeCustomer.date_of_birth}
                    </li>
                    <li className="list-group-item">
                      <strong>Genere: </strong>
                      {' '}
                      {activeCustomer.gender}
                    </li>
                    <li className="list-group-item">
                      <strong>Codice Fiscale: </strong>
                      {' '}
                      {activeCustomer.fiscal_code}
                    </li>
                  </>
                )}
              {activeCustomer.gender === 'G'
                && (
                  <>
                    <li className="list-group-item">
                      <strong>Ragione Sociale: </strong>
                      {' '}
                      {activeCustomer.business_name}
                    </li>
                    <li className="list-group-item">
                      <strong>Partita Iva: </strong>
                      {' '}
                      {activeCustomer.vat_number}
                    </li>
                    <li className="list-group-item">
                      <strong>Genere: </strong>
                      {' '}
                      {activeCustomer.gender}
                    </li>
                  </>
                )}
            </ul>
          </div>
          <div className="col-12 col-md-6 active-client-single-page-four">
            <h4 className="h5 mt-3">
              Informazioni di contatto
            </h4>
            <ul className="list-group">
              <li className="list-group-item">
                <strong>Email: </strong>
                {' '}
                {activeCustomer.email}
              </li>
              <li className="list-group-item">
                <strong>Telefono: </strong>
                {' '}
                {activeCustomer.email}
              </li>
              <li className="list-group-item">
                <strong>Indirizo: </strong>
                {' '}
                {activeCustomer.address}
                {' '}
                {activeCustomer.house_number}
              </li>
              <li className="list-group-item">
                <strong>Residenza: </strong>
                {' '}
                {activeCustomer.city_of_residence ? activeCustomer.city_of_residence.italian_name : ''}
              </li>
              <li className="list-group-item">
                <strong>Codice Postale: </strong>
                {' '}
                {activeCustomer.postal_code}
              </li>
              <li className="list-group-item">
                <div className="btn btn-outline-success" onClick={() => navigate(`/customers/active-customer/${activeClientId}/contact`)}>Contatta</div>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 active-client-single-page-five">
            <h5 className="mb-0 mt-5 h5">Preventivi Fatti</h5>
            <hr />
            <div>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Prodotto</th>
                    <th scope="col">Targa</th>
                    <th scope="col">Data D'inizio</th>
                    <th scope="col">Creato a</th>
                  </tr>
                </thead>
                <tbody>
                  {insurance_requests.length !== 0
                    ? insurance_requests.map((request, index) => (
                      <TableRowStyle key={index} onClick={() => sendToInsuranceRequestQuotationsList(request)}>
                        <td>{request.request_token}</td>
                        <td>{request.product.name}</td>
                        <td>{request.vehicle_plate}</td>
                        <td>{moment(request.policy_effective_date).format('DD.MM.YYYY')}</td>
                        <td>{moment(request.created_at).format('DD.MM.YYYY HH:mm')}</td>
                      </TableRowStyle>
                    ))
                    : (
                      <tr>
                        <td colSpan={5}>Nessun Preventivo Trovato!</td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-12 col-md-6 active-client-single-page-six">
            <h5 className="mb-0 mt-5 h5">Polizze emesse</h5>
            <hr />
            <div>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Compagnia</th>
                    <th scope="col">Premio</th>
                    <th scope="col">Data Di Emmisione</th>
                    <th scope="col">Data Di Scadenza</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {activeQuotes !== 0
                    ? activeQuotes.map((quote, index) => (
                      <TableRowStyle key={index} onClick={() => navigateToQuoteScreen(quote.product.slug, quote.request_token)}>
                        <td>{quote.company !== null ? quote.company.name : quote.preventivas_company.company_name}</td>
                        <td>
                          &euro;
                          {quote.amount}
                        </td>
                        <td>{moment(quote.insurance_request.policy_effective_date).toDate().toLocaleDateString('it-IT')}</td>
                        <td>{moment(quote.insurance_request.policy_effective_date).add(1, 'years').toDate().toLocaleDateString('it-IT')}</td>
                        <td>{quote.quotation_status.name}</td>
                      </TableRowStyle>
                    ))
                    : (
                      <tr>
                        <td colSpan={5}>Nessuna Polizza Emessa Trovata!</td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 active-client-single-page-seven">
            <h5 className="mb-0 mt-5 h5">Attivita Post Vendita</h5>
            <hr />
            <div>
              <table className="table table-bordered table-responsive">
                <thead>
                  <tr>
                    <th scope="col">Tipo</th>
                    <th scope="col">Prodotto</th>
                    <th scope="col">Email</th>
                    <th scope="col">Nota</th>
                    <th scope="col">Targa</th>
                    <th scope="col">Documenti Inviati</th>
                    <th scope="col">Data</th>
                  </tr>
                </thead>
                <tbody>
                  {after_sales.map((afterSale, index) => (
                    <TableRowStyle key={index} onClick={() => navigateToQuoteScreen(afterSale.product_slug, afterSale.quotation_id)}>
                      <td>
                        {getTypeName(afterSale.type)}
                      </td>
                      <td>
                        {afterSale.product_name}
                      </td>
                      <td>
                        {afterSale.email ? afterSale.email : 'Nessuna Mail!'}
                      </td>
                      <td>
                        {afterSale.nota ? afterSale.nota : 'Nessuna Nota!'}
                      </td>
                      <td>
                        {afterSale.vehicle_plate ? afterSale.vehicle_plate : 'Nessuna Targa!'}
                      </td>
                      <td>
                        {afterSale.document_names ? `Inviati: ${afterSale.document_names}` : 'Nessun Documento Inviato!'}
                      </td>
                      <td>{moment(afterSale.created_at).toDate().toLocaleDateString('it-IT')}</td>
                    </TableRowStyle>
                  ))}

                  {after_sales.length === 0 && (
                    <tr>
                      <td colSpan={7}>
                        Nessuna Attivita Post Vendita Trovata!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-12 active-client-single-page-eight">
          <h5 className="mb-0 mt-5 h5">Storico Contatti</h5>
          <hr />
          <div>
            <table className="table table-bordered table-responsive">
              <thead>
                <tr>
                  <th scope="col">Intermediario</th>
                  <th scope="col">Metodo Di Contatto</th>
                  <th scope="col">Messagio</th>
                  <th scope="col">Data</th>
                </tr>
              </thead>
              <tbody>
                {contacts.map((contact, index) => (
                  <TableRowStyle key={index}>
                    <td>{`${contact.broker_name} ${contact.broker_surname}`}</td>
                    <td>
                      {contact.contact_type === 1 ? 'Email' : 'SMS'}
                    </td>
                    <td>{contact.message}</td>
                    <td>{moment(contact.created_at).toDate().toLocaleDateString('it-IT')}</td>
                  </TableRowStyle>
                ))}
                {contacts.length === 0 && (
                  <tr>
                    <td colSpan={4}>
                      Nessun Storico Contatti Trovato!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-12 active-client-single-page-nine">
            <h5 className="mb-0 mt-5 h5">Lista Email</h5>
            <hr />
            <div>
              <table className="table table-bordered table-responsive">
                <thead>
                  <tr>
                    <th scope="col">Oggetto</th>
                    <th scope="col">Da Nome</th>
                    <th scope="col">A Nome</th>
                    <th scope="col">Data</th>
                    <th scope="col">Quotazione</th>
                    <th>Azioni</th>
                  </tr>
                </thead>
                <tbody>
                  {gmails.map((mail, index) => (
                    <tr key={index}>
                      <td>{mail.subject}</td>
                      <td>{mail.fromName}</td>
                      <td>{mail.toName}</td>
                      <td>{moment(mail.date).toDate().toLocaleDateString('it-IT')}</td>
                      <td>{mail.insurance_request.product.name}</td>
                      <td>
                        <ReadMailBtn>
                          <Link to={`/clients/email/${mail.id}`}>
                            Leggi
                          </Link>
                        </ReadMailBtn>
                      </td>
                    </tr>
                  ))}

                  {gmails.length === 0
                    && (
                      <tr>
                        <td colSpan={6}>Nessuna Mail Trovata!</td>
                      </tr>
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ActiveCustomerDetailsStyles>
    )
    : <ErrorComponent error={error} />;
}

export default ActiveCustomerDetails;
