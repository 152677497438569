import styled from 'styled-components';

export const RemindersForm = styled.form`
    margin: 50px auto;
    padding: 20px;
    width: calc(100% - 40px);
    max-width: 600px;
    border: 1px solid var(--greenia-color);
    border-radius: 20px;

    h1 {
        text-align: center;
        font-size: 23px;
        font-weight: bold;
        border-bottom: 1px solid var(--greenia-color);
    }

    .submit-button {
        width: 100%;
        padding: 10px 20px;
        outline: none;
        border: 2px solid var(--greenia-color);
        background-color: var(--greenia-color);
        color: white;
        font-weight: bold;
        border-radius: 10px;
        transition: .3s;

        &:hover {
            background-color: transparent;
            color: var(--greenia-color);
        }
    }

    .delete-button {
        display: inline-block;
        padding: 10px 20px;
        outline: none;
        border: 2px solid rgb(200,40,40);
        background-color: rgb(200,40,40);
        color: white;
        font-weight: bold;
        border-radius: 10px;
        cursor: pointer;
        transition: .3s;

        &:hover {
            background-color: transparent;
            color: rgb(200,40,40);
        }
    }

    .list-group {
        max-height: 300px;
        overflow-y: auto;
    }
`;
