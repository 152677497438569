import { useEffect, useState } from 'react';
import moment from 'moment';
import { TableHeaderButton, TableInputText, TableYearHeader } from './InputViolations.styles';

export const calculateEmptyViolationsData = () => {
  const currentYear = moment().year();
  const years = [];
  for (let i = currentYear - 10; i <= currentYear; i++) {
    years.push(i);
  }

  const violationsData = {
    fullTotal: [],
    fullThings: [],
    fullPersons: [],
    fullMixed: [],
    partialTotal: [],
    partialThings: [],
    partialPersons: [],
    partialMixed: [],
  };

  Object.keys(violationsData).forEach((key) => {
    violationsData[key] = years.map((year) => ({
      year: year.toString(),
      value: '0',
    }));
  });

  return violationsData;
};

const calculateViolationsNumber = (violationsData) => {
  let violationsNumber = 0;

  violationsData.fullTotal.forEach((v) => {
    if (!['NA', 'NaN', '--'].includes(v.value)) {
      violationsNumber += parseInt(v.value);
    }
  });

  violationsData.partialTotal.forEach((v) => {
    if (!['NA', 'NaN', '--'].includes(v.value)) {
      violationsNumber += parseInt(v.value);
    }
  });

  return violationsNumber;
};

export default function InputViolations(props) {
  const { value } = props;

  const [violationsData, setViolationsData] = useState(value);

  useEffect(() => {
    if (JSON.stringify(violationsData) !== JSON.stringify(value)) {
      const violations_number = calculateViolationsNumber(violationsData);
      props.onChange({ data: violationsData, violations_number });
    }
  }, [violationsData]);

  useEffect(() => {
    setViolationsData(value);
  }, [value]);

  const calculateNewValue = (result) => result.reduce((prev, curr) => {
    if (prev === '') {
      return Number.NaN;
    }

    if (Number.isNaN(prev)) {
      return Number.NaN;
    }

    if (prev === 'NA' && curr === 'NA') {
      return 'NA';
    }

    if (curr === '--') {
      if (prev === 'NA' || prev === '--') {
        return 0;
      }

      if (Number.isInteger(prev)) {
        return prev;
      }

      const prevParsed = parseInt(prev);

      if (Number.isNaN(prevParsed) || prevParsed.toString() !== prev.toString() || prevParsed >= 100) {
        return Number.NaN;
      }

      return prevParsed;
    }

    if (curr === '') {
      return Number.NaN;
    }

    if (curr === 'NA' && prev !== '--') {
      return parseInt(prev);
    }

    if (curr === 'NA' && prev === '--') {
      return 0;
    }

    const val = parseInt(curr);

    if (Number.isNaN(val) || val.toString() !== curr || val >= 100) {
      return Number.NaN;
    }

    if (prev === 'NA' || prev === '--') {
      return val;
    }

    const prevParsed = parseInt(prev);

    if (Number.isNaN(prevParsed) || prevParsed.toString() !== prev.toString()) {
      return Number.NaN;
    }

    if (prevParsed < 0 || val < 0) {
      return Number.NaN;
    }

    if (prevParsed + val >= 100) {
      return Number.NaN;
    }

    return prevParsed + val;
  }).toString();

  const updateValidationsDataValue = (year, violationType) => (e) => {
    const { value } = e.target;

    setViolationsData((prev) => {
      const afterChange = {
        ...prev,
        [violationType]: prev[violationType].map((v) => {
          if (v.year !== year) return v;

          return {
            ...v,
            value,
          };
        }),
      };

      if (['fullThings', 'fullPersons', 'fullMixed'].includes(violationType)) {
        afterChange.fullTotal = afterChange.fullTotal.map((v) => {
          if (v.year !== year) {
            return v;
          }

          const result = [
            afterChange.fullThings.find((s) => s.year === year).value,
            afterChange.fullPersons.find((s) => s.year === year).value,
            afterChange.fullMixed.find((s) => s.year === year).value,
          ];

          return {
            ...v,
            value: calculateNewValue(result),
          };
        });
      } else {
        afterChange.partialTotal = afterChange.partialTotal.map((v) => {
          if (v.year !== year) {
            return v;
          }

          const result = [
            afterChange.partialThings.find((s) => s.year === year).value,
            afterChange.partialPersons.find((s) => s.year === year).value,
            afterChange.partialMixed.find((s) => s.year === year).value,
          ];

          return {
            ...v,
            value: calculateNewValue(result),
          };
        });
      }

      return afterChange;
    });
  };

  const setValuesForYear = (year, value) => () => {
    setViolationsData((prev) => {
      const newVal = { ...prev };
      Object.keys(newVal).forEach((key) => {
        newVal[key] = newVal[key].map((v) => {
          if (v.year !== year) {
            return v;
          }

          return {
            ...v,
            value,
          };
        });
      });
      return newVal;
    });
  };

  return (
    <div style={{ width: '100%', overflowX: 'auto' }}>
      <table className="custom-table" style={{ width: 900, margin: 'auto', marginTop: 20 }}>
        <thead>
          <tr>
            <th colSpan={violationsData.fullTotal.length + 1}><center>Sinistri</center></th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th />
            {violationsData.fullTotal.map((v) => (
              <th key={v.year}>
                <TableHeaderButton onClick={setValuesForYear(v.year, '0')}>0</TableHeaderButton>
                <TableHeaderButton onClick={setValuesForYear(v.year, '--')}>--</TableHeaderButton>
                <TableHeaderButton onClick={setValuesForYear(v.year, 'NA')}>NA</TableHeaderButton>
              </th>
            ))}
          </tr>
        </thead>
        <thead>
          <tr>
            <th><center>Tipo</center></th>
            {violationsData.fullTotal.map((v) => <TableYearHeader key={v.year}>{v.year}</TableYearHeader>)}
          </tr>
        </thead>
        <tbody>
          <tr className="custom-table-row custom-table-total">
            <th>Con responsabilita' principale</th>
            {violationsData.fullTotal.map((v) => (
              <td>
                <TableInputText type="text" value={v.value} disabled />
              </td>
            ))}
          </tr>
          <tr className="custom-table-row">
            <th>Di cui con danni a: COSE</th>
            {violationsData.fullThings.map((v) => (
              <td>
                <TableInputText type="text" value={v.value} onChange={updateValidationsDataValue(v.year, 'fullThings')} />
              </td>
            ))}
          </tr>
          <tr className="custom-table-row">
            <th>Di cui con danni a: PERSONE</th>
            {violationsData.fullPersons.map((v) => (
              <td>
                <TableInputText type="text" value={v.value} onChange={updateValidationsDataValue(v.year, 'fullPersons')} />
              </td>
            ))}
          </tr>
          <tr className="custom-table-row">
            <th>Di cui con danni a: MISTI (persone+cose)</th>
            {violationsData.fullMixed.map((v) => (
              <td>
                <TableInputText type="text" value={v.value} onChange={updateValidationsDataValue(v.year, 'fullMixed')} />
              </td>
            ))}
          </tr>
          <tr className="custom-table-row custom-table-total">
            <th>Con responsabilita' paritaria</th>
            {violationsData.partialTotal.map((v) => (
              <td>
                <TableInputText type="text" value={v.value} disabled />
              </td>
            ))}
          </tr>
          <tr className="custom-table-row">
            <th>Di cui con danni a: COSE</th>
            {violationsData.partialThings.map((v) => (
              <td>
                <TableInputText type="text" value={v.value} onChange={updateValidationsDataValue(v.year, 'partialThings')} />
              </td>
            ))}
          </tr>
          <tr className="custom-table-row">
            <th>Di cui con danni a: PERSONE</th>
            {violationsData.partialPersons.map((v) => (
              <td>
                <TableInputText type="text" value={v.value} onChange={updateValidationsDataValue(v.year, 'partialPersons')} />
              </td>
            ))}
          </tr>
          <tr className="custom-table-row">
            <th>Di cui con danni a: MISTI (persone+cose)</th>
            {violationsData.partialMixed.map((v) => (
              <td>
                <TableInputText type="text" value={v.value} onChange={updateValidationsDataValue(v.year, 'partialMixed')} />
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
}
