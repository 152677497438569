import { useMemo, useRef, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import Header from '../../../common/components/Header';
import Sidebar from '../../../common/components/Sidebar';
import { DashboardThemeStyles, SmallChat } from './DashboardTheme.styles';
import OffcanvasSidebar from '../../../common/components/OffcanvasSidebar';
import { GeneralChat } from '../../../common/components/chats/GeneralChat/GeneralChat';
import { ChatContext } from './DashboardTheme.context';
import contactSupport from '../../../assets/redesign_icons/contactSuport-white.svg';
import PlatformChat from '../../../common/components/platformaChat/PlatformChat';

export default function DashboardTheme(props) {
  const [showSidebar, setShowSidebar] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const chatRef = useRef();
  const location = useLocation();
  const [showChat, setShowChat] = useState(false);

  // Define the routes where the image should NOT be displayed
  const excludedRoutes = ['/messages'];

  // Use matchPath to define patterns for routes with parameters
  const hideOnRoutesWithParams = [
    matchPath('/messages/:threadId', location.pathname),
    matchPath('/tickets/:ticketId', location.pathname),
    // Add more routes with parameters here
  ];
  const shouldHideImage = excludedRoutes.includes(location.pathname)
  || hideOnRoutesWithParams.some(Boolean);

  const chatValue = useMemo(() => ({ setOpenChat, chatRef }), [chatRef]);

  const toggleChat = () => {
    setShowChat(!showChat); // Toggle the chat visibility
  };

  return (

    <DashboardThemeStyles>
      <Sidebar
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
      />
      <div className="page-content">
        <Header setShowSidebar={setShowSidebar} />
        <OffcanvasSidebar />

        <div className="page-content-items">
          {!shouldHideImage && (

          <>
            {!showChat && (
            <SmallChat>
              <img
                src={contactSupport}
                width={50}
                alt="Bottom right"
                className="cursor-pointer"
                onClick={toggleChat}
              />
              <p className="cursor-pointer" onClick={toggleChat}>Apri Chat</p>
            </SmallChat>
            )}

            {showChat && <PlatformChat onClose={toggleChat} />}
          </>
          )}
          <ChatContext.Provider value={chatValue}>{props.children}</ChatContext.Provider>
        </div>
        <GeneralChat ref={chatRef} openChat={openChat} setOpenChat={setOpenChat} />
      </div>
    </DashboardThemeStyles>

  );
}
