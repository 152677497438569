import React from 'react';
import TokioMarineLogo from '../../../../assets/images/Tokio_Marine.svg.png';
import {
  LeftCol, LogoPriceContainer, PriceContainer, RightCol,
} from './AdriaticCard.styles';
import {
  LogoResultContainer, PriceResult, QuoteInformationResultContainer, QuoteInformationResultItem,
} from '../../../../screens/BackOffice/results/cards/PrimaCard.styles';
import { ProfessionCardStyles } from './ProfessionCard.styles';

function ProfessionCardResult({ profession, quote }) {
  return (
    <ProfessionCardStyles className={quote.is_aquista === 1 ? 'selected' : ''}>
      <div className="row-container">
        <LeftCol>
          <LogoPriceContainer>
            <LogoResultContainer>
              <img src={TokioMarineLogo} alt="Tokio Marine Logo" />
            </LogoResultContainer>
            <PriceContainer>
              <PriceResult>
                <h5>
                  Prezzo annuo:
                </h5>
                <h2>
                  &euro;
                  {' '}
                  {quote.amount.toFixed(2).toLocaleString()}
                </h2>
              </PriceResult>
            </PriceContainer>
          </LogoPriceContainer>
          <QuoteInformationResultContainer>
            <QuoteInformationResultItem>
              <h6>
                Compagnia:
              </h6>
              <p>
                {quote.company.name}
              </p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>
                Prodotto:
              </h6>
              <p>
                RC Professionale
              </p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>
                Professione:
              </h6>
              <p>
                {profession.profession_desc}
              </p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>
                Massimale:
              </h6>
              <p>
                {Number(profession.billed_maximum).toLocaleString('it-IT', { minimumFractionDigits: 2 })}
                &euro;
              </p>
            </QuoteInformationResultItem>
          </QuoteInformationResultContainer>
          <QuoteInformationResultContainer className="ms-3 ms-sm-0">
            <QuoteInformationResultItem>
              <h6>
                Franchigia:
              </h6>
              <p>
                1,000&euro;
              </p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>
                Retroattività:
              </h6>
              <p>
                {profession.retroactivity_name}
              </p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>
                Scaglione fatturato:
              </h6>
              <p>
                {profession.billed}
                &euro;
              </p>
            </QuoteInformationResultItem>
            <QuoteInformationResultItem>
              <h6>
                Tempo medio di emissione:
              </h6>
              <p>
                2 giorni
              </p>
            </QuoteInformationResultItem>
          </QuoteInformationResultContainer>
        </LeftCol>
        <RightCol />
      </div>
    </ProfessionCardStyles>
  );
}

export default ProfessionCardResult;
