import managementAxe from '../../services/axios-client/managementServisAxeClient';
import caller from './threadsSlice';

const serverError = 'Si è verificato un problema con il server';

export const getAllThreads = async (page) => {
  caller.getThreadsPending();
  try {
    const response = await managementAxe.get(
      '/broker/messages/get-all-threads-broker',
      {
        params: {
          page,
        },
      },
    );
    caller.getThreadsSuccess(response.data);
  } catch (error) {
    caller.getThreadsReject(error);
  }
};

export const searchForThreads = async (page, search) => {
  caller.searchThreadsPending();
  try {
    const response = await managementAxe.get(
      `/broker/messages/threads-search-broker?page=${page}&search=${search}`,
    );
    caller.searchThreadsSuccess(response.data);
  } catch (error) {
    caller.searchThreadsReject(error);
  }
};

export const showOneThread = async (threadId) => {
  caller.getThreadPending();
  try {
    const response = await managementAxe.get(
      `/broker/messages/get-thread-broker/${threadId}`,
    );

    if (response.status !== 200) {
      caller.getThreadReject(serverError);
      return;
    }

    caller.getThreadSuccess(response.data.data);
  } catch (error) {
    caller.getThreadReject(serverError);
  }
};

export const threadReply = async (threadId, data, onSuccess) => {
  caller.replyThreadPending();
  try {
    const response = await managementAxe.put(
      `/broker/messages/reply-thread-broker/${threadId}`,
      data,
    );

    if (response.status === 200) {
      caller.replyThreadSuccess();
      onSuccess();
    }
  } catch (error) {
    caller.replyThreadReject(error);
  }
};

export const listAllQuotationsForMessages = async () => {
  const response = await managementAxe.get(
    '/broker/messages/get-quotations-without-threads',
  );
  caller.getThreadsQuotations(response.data.data);
};
