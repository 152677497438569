import React from 'react';
import moment from 'moment';
import { FormInputText } from '../../../common/formInputs/FormInputText';
import { FormInputSelect } from '../../../common/formInputs/FormInputSelect';
import { flagResponse, shareholderType } from '../../../common/constants';
import { FormInputDate } from '../../../common/formInputs/FormInputDate';
import { FormInputRadio } from '../../../common/formInputs/FormInputRadio';

function Shareholders({ commertial_report, errors, isValid }) {
  return (
    <div className="tab-pane fade" id="share_holders">
      <h4 className="text-center mt-3">Share Holders</h4>
      {commertial_report.share_holders.map((shareHolder, index) => (
        <div className="card border p-3 mt-5" key={index}>
          <div className="row">
            <div className="col-12 col-md-6">
              <FormInputText
                placeholder="Name"
                label="Name"
                value={shareHolder.name}
                error={errors.name?.message}
                valid={isValid('name')}
              />
            </div>
            <div className="col-12 col-md-6">
              <FormInputText
                placeholder="Address"
                label="Address"
                value={shareHolder.address}
                error={errors.address?.message}
                valid={isValid('address')}
              />
            </div>
            <div className="col-12 col-md-6">
              <FormInputSelect
                label="Shareholder Type"
                placeholder="-Seleziona-"
                options={shareholderType}
                value={shareHolder.shareholder_type}
                error={errors.shareholder_type?.message}
                valid={isValid('shareholder_type')}
              />
            </div>
            <div className="col-12 col-md-6">
              <FormInputText
                placeholder="Percentage Held"
                label="Percentage Held"
                name="percentage_held"
                value={shareHolder.percentage_held}
                error={errors.percentage_held?.message}
                valid={isValid('percentage_held')}
              />
            </div>
            <div className="col-12 col-md-6">
              <FormInputDate
                minDate={new Date()}
                label="Constitution Date"
                name="constitution_date"
                value={moment(shareHolder.constitution_date).format('YYYY-MM-DD')}
                error={errors.constitution_date?.message}
                valid={isValid('constitution_date')}
              />
            </div>
            <div className="col-12 col-md-6">
              <FormInputText
                placeholder="Gender"
                label="Gender"
                name="gender"
                value={shareHolder.gender}
                error={errors.gender?.message}
                valid={isValid('gender')}
              />
            </div>

            <div className="col-12 col-md-6">
              <div className="d-flex align-align-items-center justify-content-center gap-5">
                <FormInputRadio
                  label="Prejudicials"
                  options={flagResponse}
                  name="prejudicials"
                  value={shareHolder.prejudicials}
                  error={errors.prejudicials?.message}
                  valid={isValid('prejudicials')}
                />
                <FormInputRadio
                  label="Has Severe Prejudicials"
                  options={flagResponse}
                  name="has_severe_prejudicials"
                  value={shareHolder.has_severe_prejudicials}
                  error={errors.has_severe_prejudicials?.message}
                  valid={isValid('has_severe_prejudicials')}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="d-flex align-align-items-center justify-content-center gap-5">
                <FormInputRadio
                  label="Protesti"
                  options={flagResponse}
                  name="protesti"
                  value={shareHolder.protesti}
                  error={errors.protesti?.message}
                  valid={isValid('protesti')}
                />
                <FormInputRadio
                  label="Has Severe Protesti"
                  options={flagResponse}
                  name="has_severe_protesti"
                  value={shareHolder.has_severe_protesti}
                  error={errors.has_severe_protesti?.message}
                  valid={isValid('has_severe_protesti')}
                />
              </div>
            </div>
            <div className="col-12 ">
              <div className="d-flex align-align-items-center justify-content-center gap-5">
                <FormInputRadio
                  label="Company Representative"
                  options={flagResponse}
                  name="company_representative"
                  value={shareHolder.company_representative}
                  error={errors.company_representative?.message}
                  valid={isValid('company_representative')}
                />

              </div>
            </div>

          </div>
        </div>
      ))}
    </div>
  );
}

export default Shareholders;
