import React from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import SupportModal from './SupportModal';

import managementAxe from '../../../services/axios-client/managementServisAxeClient';

const messageSchema = yup.object({
  body: yup
    .string()
    .required('Il campo è obbligatorio'),
});

function NewThreadMessageForm({ threadId, collectData }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(messageSchema),
    shouldFocusError: true,
  });

  const submitData = async (data) => {
    const response = await managementAxe.post(`/broker/messages/threads/${threadId}/reply`, data);

    if (response.status === 200) {
      reset();
      collectData();
    }
  };

  return (
    <form className="d-flex justify-content-between align-items-center m-2" onSubmit={handleSubmit(submitData)}>
      <div className="w-100">
        <textarea className="form-control" {...register('body')} />
        <div className="general-input-error">{errors.body?.message}</div>
      </div>

      <button type="submit" className="btn btn-sm btn-success m-1">Invia</button>
    </form>
  );
}

function MessagesModal({
  backofficeUsers, setSupportMessage, supportMessage, collectData, customer, threads,
}) {
  return (
    <>
      <div className="modal fade" id="messages-modal" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="messages-modalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="messages-modalLabel">Messaggi</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              {threads.length === 0
                && (
                  <div className="d-flex align-items-center justify-content-between">
                    <h3>Crea nuova chat</h3>
                    <button
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#support-message-box"
                      className="btn btn-outline-primary"
                    >
                      Supporto
                    </button>
                  </div>
                )}

              {threads.map((thread, index) => (
                <div key={thread.id} className="border border-success rounded mt-2">
                  <div className="d-flex align-items-center justify-content-between p-2">
                    <b>
                      #
                      {index + 1}
                      {' '}
                      {thread.subject}
                    </b>
                    <sub className="text-nowrap">{moment(thread.created_at).fromNow()}</sub>
                  </div>

                  {thread.messages.map((message) => (
                    <div
                      key={message.id}
                      style={{
                        marginRight: message.user_id !== null ? '50px' : '0',
                        marginLeft: message.user_id !== null ? '0' : '50px',
                      }}
                    >
                      <div
                        className="d-flex justify-content-between p-2 m-2 border rounded"
                        style={{ backgroundColor: message.user_id === null ? 'lightgrey' : 'rgb(120,200,120)' }}
                      >
                        <div>
                          {message.user !== null
                            && (
                              <span className="text-success">
                                {message.user.name}
                                :
                                {' '}
                              </span>
                            )}
                          {message.broker_agent !== null
                            && (
                              <span className="text-success">
                                {message.broker_agent.name}
                                {' '}
                                {message.broker_agent.surname}
                                {' '}
                                {message.broker_agent.email}
                                :
                                {' '}
                              </span>
                            )}
                          {message.body}
                        </div>
                        <sub className="text-nowrap">{moment(message.created_at).fromNow()}</sub>
                      </div>
                    </div>
                  ))}

                  <NewThreadMessageForm threadId={thread.id} collectData={collectData} />
                </div>
              ))}

            </div>
          </div>
        </div>
      </div>
      <SupportModal
        collectData={collectData}
        backofficeUsers={backofficeUsers}
        setSupportMessage={setSupportMessage}
        supportMessage={supportMessage}
        requestId={supportMessage.request_id}
        customer={customer}
      />
    </>
  );
}

export default MessagesModal;
