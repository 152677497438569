import React from 'react';
import { useSelector } from 'react-redux';
import { VictoryPie } from 'victory';
import DottedDivider from '../components/DottedDivider/DottedDivider';
import { WidgetStyles } from './Widgets.styles';

function TooltipWrapper() {
  return <svg width="100%" height="100%" />;
}

function StatsWidget() {
  const state = useSelector((store) => store.dashboard.dashboard);

  const {
    total_quotations_started,
    total_quotations_emmese,
    total_quotations_paid_amount,
    total_spent_in_accidents,
    commissioni,
  } = state;

  const formatNumberMethod = (number) => {
    const formattedNumber = new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);

    // Replace the period with an apostrophe to get the desired format
    return formattedNumber.replace(/\./g, "'");
  };

  const progress = total_spent_in_accidents === 0
    ? 0
    : (
      (total_quotations_paid_amount / total_spent_in_accidents)
      * 100
    ).toFixed(2);

  return (
    <WidgetStyles id="stats-info" className="custom-card h-100">
      <div className="custom-card-header">
        <div className="custom-card-section">
          <h3>Reports</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="custom-card-section">
            <DottedDivider />
            <div className="custom-card-section text-center">
              <h3 className="my-1">Quotazioni</h3>
            </div>
            <DottedDivider />
            <div className="stats-field">
              <div className="stats-field__description">Emissioni</div>
              <div className="stats-field__price">
                {total_quotations_emmese}
              </div>
              <div className="stats-field__price-subscription">
                Totale:
                {' '}
                {total_quotations_started}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="custom-card-section">
            <DottedDivider />
            <div className="custom-card-section text-center">
              <h3 className="my-1">Portofolio</h3>
            </div>
            <DottedDivider />
            <div className="stats-field">
              <div className="stats-field__description">Totale</div>
              <div className="stats-field__price">
                {formatNumberMethod(total_quotations_paid_amount)}
                &euro;
              </div>
              <div className="stats-field__price-subscription">
                Sinistri:
                {' '}
                {formatNumberMethod(total_spent_in_accidents)}
                &euro;
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="custom-card-section">
            <DottedDivider />
            <div className="custom-card-section text-center">
              <h3 className="my-1">Commisioni Totali</h3>
            </div>
            <DottedDivider />
            <div className="stats-field">
              <div className="stats-field__description">Totale</div>
              <div className="stats-field__price">
                {formatNumberMethod(commissioni)}
                &euro;
              </div>
            </div>
          </div>
        </div>

        {/* <DottedDivider /> */}
        <div className="col-12 col-md-6">
          <div className="custom-card-section">
            <DottedDivider />
            <div className="custom-card-section text-center">
              <h3 className="my-1">Preventivi - Sinistri Ratio</h3>
            </div>
            <DottedDivider />
            <div className="ratio-statistic-custom mt-2">
              <div className="ratio-statistic-custom-percentage-value">
                {progress}
                %
              </div>
              <VictoryPie
                padAngle={0}
                // used to hide labels
                labelComponent={<TooltipWrapper />}
                innerRadius={70}
                radius={60}
                width={150}
                height={150}
                data={[
                  { key: '', y: progress },
                  { key: '', y: 100 - progress },
                ]}
                colorScale={['#19B3A6', '#EEEEEE']}
              />
            </div>
          </div>
        </div>
      </div>
    </WidgetStyles>

  );
}

export default StatsWidget;
