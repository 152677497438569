export const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;
export const BACKEND_RESOURCE_URL = process.env.REACT_APP_BACKEND_RESOURCE_URL;
export const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
export const UPLOAD_FILE_BASE_URL = process.env.REACT_APP_UPLOAD_FILE_BASE_URL;
export const FILE_SERVER_URL = process.env.REACT_APP_FILE_SERVER_URL;
export const QUOTATION_SERVICE_API_URL = process.env.REACT_APP_QUOTATION_SERVICE_API_URL;
export const SELLING_DISTANCE_URL = process.env.REACT_APP_SELLING_DISTANCE_URL;
export const CHAT_SERVICE_API_URL = process.env.REACT_APP_CHAT_SERVICE_API_URL;
export const CHAT_SERVICE_SOCKET_URL = process.env.REACT_APP_CHAT_SERVICE_SOCKET_URL;
export const BACKOFFICE_PANEL_URL = process.env.REACT_APP_PANEL_URL;
export const MANAGEMENT_SERVICE_API_URL = process.env.REACT_APP_MANAGEMENT_SERVICE_API_URL;
