import React from 'react';
import {

  GuaranteesDefaultStateStyles,

} from './GuaranteesDefaultState.styles';
// import warningIcon from '../../../../assets/redesign_icons/warning-icon.svg';
// import arrowDown from '../../../../assets/redesign_icons/arrow-down.svg';

function GuaranteesDefaultState() {
  // const [guaranteesLength, setGuaranteesLength] = useState(null);
  // const coverageName = {
  //   incendio: 'Incendio',
  //   furto: 'Furto',
  //   eventi_naturali: 'Eventi Naturali',
  //   assistenza_stradale: 'Assistenza stradale',
  //   eventi_sociopolitici: 'Atti vandalici',
  //   cristalli: 'Cristalli',
  //   rivalsa: 'Rivalsa',
  //   infortuni_conducente: 'Infortuni conducente',
  //   tutela_legale: 'Tutela Legale',
  //   furto_incendio: 'Furto e Incendio',
  // };

  // const professionaleExtensionsMap = {
  //   tlb: 'Tutela Legale Bronze',
  //   tls: 'Tutela Legale Silver',
  //   tlg: 'Tutela Legale Gold',
  // };

  // useEffect(() => {
  //   if (quotes !== null) {
  //     let adriaticGuarantees = 0;
  //     let primaGuarantees = 0;
  //     let metlifeGuarantees = 0;
  //     let professionGuarantees = 0;

  //     quotes.forEach((quote) => {
  //       if (quote.company_id === 1) {
  //         adriaticGuarantees = quote.guarantees.length;
  //       }
  //       if (quote.company_id === 2) {
  //         primaGuarantees = quote.guarantees.length;
  //       }
  //       if (quote.company_id === 5) {
  //         if (quote.ulc !== null) {
  //           metlifeGuarantees += 1;
  //         }

  //         if (quote.rsm !== null) {
  //           metlifeGuarantees += 1;
  //         }
  //       }

  //       if (quote.company_id === 4) {
  //         professionGuarantees = extensions.filter(
  //           (ext) => ext === 'tlb' || ext === 'tls' || ext === 'tlg',
  //         ).length;
  //       }
  //     });
  //     setGuaranteesLength(
  //       adriaticGuarantees
  //       + primaGuarantees
  //       + metlifeGuarantees
  //       + professionGuarantees,
  //     );
  //   }
  // }, [quotes, extensions]);

  // const openGuaranteesModal = () => {
  //   setShowGuaranteesModal(true);
  // };

  // const calcAmount = (quotation_data_prima_guarantees, primaCoverages, key) => {
  //   const prices = quotation_data_prima_guarantees.find(
  //     (f) => f.slug === key,
  //   ).quotation_data_prima_guarantee_prices;
  //   const filterPrice = prices.filter(
  //     (p) => p.required_guarantees === ''
  //       || p.required_guarantees.split(',').every((e) => primaCoverages.includes(e)),
  //   );
  //   return filterPrice.reduce((prev, curr) => (prev.discounted < curr.discounted ? prev : curr));
  // };

  // const coverageAmount = (
  //   { quotation_data_prima: { quotation_data_prima_guarantees } },
  //   primaCoverages,
  //   coverageSlug,
  // ) => calcAmount(
  //   quotation_data_prima_guarantees,
  //   primaCoverages,
  //   coverageSlug,
  // );

  // const guaranteeAmount = coverageAmount(quote, convertToPrimaCoverages(quote.guarantees.map((v) => v.guarantees_slug)), cove.guarantees_slug).discounted_to_display;

  // const convertToPrimaCoverages = (coverages) => {
  //   const cov = ['rca', ...coverages].filter(
  //     (c) => !['furto', 'incendio'].includes(c),
  //   );
  //   if (coverages.includes('furto') || coverages.includes('incendio')) {
  //     cov.push('furto_incendio');
  //   }
  //   return cov;
  // };

  return (
    <GuaranteesDefaultStateStyles>
      {/* <GuaranteesInfoCol>
        {guaranteesLength === 0
          ? (
            <>
              <BaseTitle>
                <h4>
                  Aggiungi
                  {' '}
                  <b>garanzie</b>
                </h4>
                <p>
                  Se vuoi aggiungere qualche garanzia extra al tuo prodotto puoi selezionarla da qui
                </p>
              </BaseTitle>
              <DefaultInfo>
                <img src={warningIcon} alt="" />
                <p>
                  Attenzione, non tutti i prodotti supportano tutte le garanzie selezionate qui
                </p>
              </DefaultInfo>
            </>
          )
          : (
            <SelectedGuaranteesContainer>
              <h2>
                <b>Garanzie</b>
                {' '}
                Aggiunte
              </h2>
              <GuaranteesList>
                {quotes !== null && quotes.map((quote, index) => (
                  <React.Fragment key={index}>
                    {quote.company_id === 1
                      && quote.guarantees.map((cove, i) => (
                        <GuaranteesListItem key={i}>
                          <h6>
                            <span />
                            {' '}
                            {coverageName[cove.guarantees_slug]}
                          </h6>
                        </GuaranteesListItem>
                      ))}
                    {quote.company_id === 2
                      && quote.guarantees.map((cove, i) => (
                        <GuaranteesListItem key={i}>
                          <h6>
                            <span />
                            {' '}
                            {coverageName[cove.guarantees_slug]}
                          </h6>
                        </GuaranteesListItem>
                      ))}
                    {quote.company_id === 5 && quote.ulc !== null ? (
                      <GuaranteesListItem>
                        <h6>
                          <span />
                          {' '}
                          Ustioni / Lussazioni / Comm. Cerebrale
                        </h6>
                        <p>
                          &euro;
                          {quote.ulc_premio_aggiuntivo}
                        </p>
                      </GuaranteesListItem>
                    ) : (
                      ''
                    )}
                    {quote.company_id === 5 && quote.rsm !== null ? (
                      <GuaranteesListItem>
                        <h6>
                          <span />
                          {' '}
                          Rimborsi Spese Medice
                        </h6>
                        <p>
                          &euro;
                          {quote.rsm_premio_aggiuntivo}
                        </p>
                      </GuaranteesListItem>
                    ) : (
                      ''
                    )}
                    {quote.company_id === 4
                      ? extensions.map((extension) => (['tlb', 'tls', 'tlg'].includes(extension) ? (
                        <GuaranteesListItem>
                          <h6>
                            <span />
                            {professionaleExtensionsMap[extension]}
                          </h6>
                          <p>
                            {Number(extension_price).toLocaleString(
                              'it-IT',
                              { minimumFractionDigits: 2 },
                            )}
                            &euro;
                          </p>
                        </GuaranteesListItem>
                      ) : (
                        ''
                      )))
                      : ''}
                  </React.Fragment>
                ))}
              </GuaranteesList>
            </SelectedGuaranteesContainer>
          )}
      </GuaranteesInfoCol>
      <GuaranteesActionsCol>
        <img src={arrowDown} alt="" />
        <button type="button" onClick={() => openGuaranteesModal()}>
          Seleziona Garanzie
        </button>
      </GuaranteesActionsCol> */}
    </GuaranteesDefaultStateStyles>
  );
}

export default GuaranteesDefaultState;
