import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { CommercialCompanyDataStyles } from './CommercialCompanyDataStyles.styles';
import { getCommertialReport } from '../../features/commercialReport/commercialReportActions';
import PageLoading from '../../common/components/elements/PageLoading';
import CommercialCompany from './Tabs/CommercialCompany';
import CompanyComentaries from './Tabs/CompanyComentaries';
import Shareholders from './Tabs/Shareholders';
import CommertialCompanyDirectors from './Tabs/CommertialCompanyDirectors';
import CompanyExtendedGroupStructure from './Tabs/CompanyExtendedGroupStructure';
import CompanyFinancialStatements from './Tabs/CompanyFinancialStatements';
import CompanyBranches from './Tabs/CompanyBranches';
import CompanyIndicators from './Tabs/CompanyIndicators';
import { CenteredDivTitle } from '../../common/components/CenteredPageTitle.styles';

export const validationsSchema = yup.object({

  activity_code: yup.string().nullable(),
  activity_description: yup.string().nullable(),
  activity_industry_sector: yup.string().nullable(),
  alter_summary_address: yup.string().nullable(),
  alter_summary_employees_nr: yup.string().nullable(),
  alter_summary_incorporation_date: yup.string().nullable(),
  alter_summary_province: yup.string().nullable(),
  alter_summary_share_capital: yup.string().nullable(),
  business_name: yup.string().nullable(),
  company_id: yup.string().nullable(),
  company_number: yup.string().nullable(),
  company_registration_number: yup.string().nullable(),
  company_status: yup.string().nullable(),
  credit_rating_common_desc: yup.string().nullable(),
  credit_rating_common_value: yup.string().nullable(),
  credit_rating_limit_value: yup.string().nullable(),
  summary_extra_has_cigs_events: yup.string().nullable(),
  summary_extra_has_company_insolvency: yup.string().nullable(),
  summary_extra_has_insolvency: yup.string().nullable(),
  summary_extra_has_prejudicials: yup.string().nullable(),
  summary_extra_has_protesti: yup.string().nullable(),
  summary_extra_has_severe_prejudicials: yup.string().nullable(),
  summary_extra_has_severe_protesti: yup.string().nullable(),
  turnover_value: yup.string().nullable(),
  commentary_text: yup.string().nullable(),
  positive_or_negative: yup.string().nullable(),
  address: yup.string().nullable(),
  company_representative: yup.string().nullable(),
  constitution_date: yup.string().nullable(),
  gender: yup.string().nullable(),
  has_severe_prejudicials: yup.string().nullable(),
  has_severe_protesti: yup.string().nullable(),
  name: yup.string().nullable(),
  percentage_held: yup.string().nullable(),
  prejudicials: yup.string().nullable(),
  protesti: yup.string().nullable(),
  shareholder_type: yup.string().nullable(),
  // has_prejudicials_directors: yup.string().nullable(),
  // has_protesti_directors: yup.string().nullable(),
  // has_severe_prejudicials_directors: yup.string().nullable(),
  // has_severe_protesti_directors: yup.string().nullable(),
  // name_directors: yup.string().nullable(),
  // name_position: yup.string().nullable(),
  directors: yup.array().nullable(),
  extended_group_structure: yup.array().nullable(),
  company_branches: yup.array().nullable(),
  company_indicators: yup.array().nullable(),

});

function CommercialCompanyData() {
  const { vatNumber } = useParams();
  const navigate = useNavigate();
  const { loading, commertial_report } = useSelector((store) => store.commercialReport.report);

  const {
    register,
    // handleSubmit,
    formState: { errors, touchedFields },
    setValue,
    // trigger,
    // watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationsSchema),
    shouldFocusError: true,
    defaultValues: {
      activity_code: '',
      activity_description: '',
      activity_industry_sector: '',
      alter_summary_address: '',
      alter_summary_employees_nr: '',
      alter_summary_incorporation_date: '',
      alter_summary_province: '',
      alter_summary_share_capital: '',
      business_name: '',
      company_id: '',
      company_number: '',
      company_registration_number: '',
      company_status: '',
      credit_rating_common_desc: '',
      credit_rating_common_value: '',
      credit_rating_limit_value: '',
      summary_extra_has_cigs_events: '',
      summary_extra_has_company_insolvency: '',
      summary_extra_has_insolvency: '',
      summary_extra_has_prejudicials: '',
      summary_extra_has_protesti: '',
      summary_extra_has_severe_prejudicials: '',
      summary_extra_has_severe_protesti: '',
      turnover_value: '',
      commentary_text: '',
      positive_or_negative: '',
      address: '',
      company_representative: '',
      constitution_date: '',
      gender: '',
      has_severe_prejudicials: '',
      has_severe_protesti: '',
      name: '',
      percentage_held: '',
      prejudicials: '',
      protesti: '',
      shareholder_type: '',
      directors: [],
      extended_group_structure: [],
      company_branches: [],
      company_indicators: [],
    },
  });

  // const answers = watch();

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  const updateAnswers = (data) => {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      setValue(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    });
    // trigger(keys);
  };

  useEffect(() => {
    getCommertialReport(vatNumber);
  }, [vatNumber]);

  useEffect(() => {
    if (commertial_report !== null) {
      updateAnswers({
        activity_code: commertial_report.commercial_company.activity_code,
        activity_description: commertial_report.commercial_company.activity_description,
        activity_industry_sector: commertial_report.commercial_company.activity_industry_sector,
        alter_summary_address: commertial_report.commercial_company.alter_summary_address,
        alter_summary_employees_nr: commertial_report.commercial_company.alter_summary_employees_nr,
        alter_summary_incorporation_date: moment(commertial_report.commercial_company.alter_summary_incorporation_date).format('YYYY-MM-DD'),
        alter_summary_province: commertial_report.commercial_company.alter_summary_province,
        alter_summary_share_capital: commertial_report.commercial_company.alter_summary_share_capital,
        business_name: commertial_report.commercial_company.business_name,
        company_id: commertial_report.commercial_company.company_id,
        company_number: commertial_report.commercial_company.company_number,
        company_registration_number: commertial_report.commercial_company.company_registration_number,
        company_status: commertial_report.commercial_company.company_status,
        credit_rating_common_desc: commertial_report.commercial_company.credit_rating_common_desc,
        credit_rating_common_value: commertial_report.commercial_company.credit_rating_common_value,
        credit_rating_limit_value: commertial_report.commercial_company.credit_rating_limit_value,
        summary_extra_has_cigs_events: commertial_report.commercial_company.summary_extra_has_cigs_events === true ? '1' : '0',
        summary_extra_has_company_insolvency: commertial_report.commercial_company.summary_extra_has_company_insolvency === true ? '1' : '0',
        summary_extra_has_insolvency: commertial_report.commercial_company.summary_extra_has_insolvency === true ? '1' : '0',
        summary_extra_has_prejudicials: commertial_report.summary_extra_has_prejudicials === true ? '1' : '0',
        summary_extra_has_protesti: commertial_report.commercial_company.summary_extra_has_protesti === true ? '1' : '0',
        summary_extra_has_severe_prejudicials: commertial_report.commercial_company.summary_extra_has_severe_prejudicials === true ? '1' : '0',
        summary_extra_has_severe_protesti: commertial_report.commercial_company.summary_extra_has_severe_protesti === true ? '1' : '0',
        turnover_value: commertial_report.commercial_company.turnover_value,
        commentary_text: commertial_report.commercial_company_commentaries.commentary_text,
        positive_or_negative: commertial_report.commercial_company_commentaries.positive_or_negative,
        address: commertial_report.share_holders.address,
        company_representative: commertial_report.share_holders.company_representative === true ? '1' : '0',
        constitution_date: moment(commertial_report.share_holders.constitution_date).format('YYYY-MM-DD'),
        gender: commertial_report.share_holders.gender,
        has_severe_prejudicials: commertial_report.share_holders.has_severe_prejudicials === true ? '1' : '0',
        has_severe_protesti: commertial_report.share_holders.has_severe_protesti === true ? '1' : '0',
        name: commertial_report.share_holders.name,
        percentage_held: commertial_report.share_holders.percentage_held,
        prejudicials: commertial_report.share_holders.prejudicials === true ? '1' : '0',
        protesti: commertial_report.share_holders.protesti === true ? '1' : '0',
        shareholder_type: commertial_report.share_holders.shareholder_type,
        directors: commertial_report.commercial_company_directors,
        extended_group_structure: commertial_report.commercial_company_extended_group_structure,
        company_branches: commertial_report.commercial_company_branches,
        company_indicators: commertial_report.commercial_company_indicators,

      });
    }
  }, [commertial_report]);

  // console.log(commertial_report);

  const continueToQuestionario = () => {
    navigate(`/quote/commercial/${vatNumber}/questionnaire/${commertial_report.commercial_company.company_id}`);
  };

  if (loading) {
    return <PageLoading />;
  }

  // Extract dynamic headers from the first object
  const dynamicHeaders = Object.keys(commertial_report.commercial_company_financial_statements[0]).filter((key) => key !== 'year_end_date');

  // Define static "Conto economico" column labels
  const staticLabels = {
    balance_sheet_total_assets: 'Balance Sheet Total Assets',
    balance_sheet_total_current_assets: 'Balance Sheet Total Current Assets',
    balance_sheet_total_liabilities: 'Balance Sheet Total Liabilities',
    balance_sheet_total_shareholders_equity: 'Balance Sheet Total Shareholders Equity',
    balance_sheet_bank_liabilities: 'Balance Sheet Bank_Liabilities',
    balance_sheet_called_up_share_capital: 'Balance Sheet Called Up Share Capital',
    balance_sheet_cash: 'Balance Sheet Cash',
    balance_sheet_group_payables: 'Balance Sheet Group Payables',
    balance_sheet_group_payables_due_after_1_year: 'Balance Sheet Group Payables due After 1 Year',
    balance_sheet_group_receivables: 'Balance Sheet Group Receivables',
    balance_sheet_receivables_due_after_1_year: 'Balance Sheet Receivables Due After 1 Year',
    balance_sheet_loans_to_group: 'Balance Sheet Loans To Group',
    balance_sheet_total_current_liabilities: 'Balance Sheet Total Current Liabilities',
    balance_sheet_miscellaneous_fixed_assets: 'Balance Sheet Miscellaneous Fixed Assets',
    balance_sheet_miscellaneous_liabilities: 'Balance Sheet Miscellaneous Liabilities',
    balance_sheet_miscellaneous_liabilities_due_after_1_year: 'Balance Sheet Miscellaneous Liabilities Due After 1 Year',
    balance_sheet_miscellaneous_receivables: 'Balance Sheet Miscellaneous Receivables',
    balance_sheet_other_current_assets: 'Balance Sheet Other Current Assets',
    balance_sheet_other_intangible_assets: 'Balance Sheet Other Intangible Assets',
    balance_sheet_other_inventories: 'Balance Sheet Other Inventories',
    balance_sheet_other_loans_or_finance: 'Balance Sheet Other Loans Or Finance',
    balance_sheet_other_loans_or_finance_due_after_1_year: 'Balance Sheet Other Loans/Finance Due After 1 year',
    balance_sheet_other_reserves: 'Balance Sheet Other Reserves',
    balance_sheet_other_tangible_assets: 'Balance Sheet Other Tangible Assets',
    balance_sheet_revenue_reserves: 'Balance Sheet Revenue Reserves',
    balance_sheet_total_fixed_assets: 'Balance Sheet Total Fixed Assets',
    balance_sheet_total_intangible_assets: 'Balance Sheet Total Intangible Assets',
    balance_sheet_total_long_term_liabilities: 'Balance Sheet Long Term Liabilities',
    balance_sheet_total_other_fixed_assets: 'Balance Sheet Total Other Fixed Assets',
    balance_sheet_total_receivables: 'Balance Sheet Total Receivables',
    balance_sheet_total_tangible_assets: 'Balance Sheet Total Tangible Assets',
    balance_sheet_trade_payables: 'Balance Sheet Trade Payables',
    balance_sheet_trade_receivables: 'Balance Sheet Trade Receivables',
    balance_sheet_work_in_progress: 'Balance Sheet Work In Progress',
    profit_loss_revenue: 'Profit Loss Revenue',
    profit_loss_operating_costs: 'Profit Loss Operating Costs',
    profit_loss_operating_profit: 'Profit Loss Operating Profit',
    profit_loss_financial_expenses: 'Profit Loss Financial Expenses',
    profit_loss_wages_salaries: 'Profit Loss Wages Salaries',
    profit_loss_depreciation: 'Profit Loss Depreciation',
    profit_loss_amortisation: 'Profit Loss Amortisation',
    profit_loss_profit_before_tax: 'Profit Loss Profit Before Tax',
    profit_loss_financial_income: 'Profit Loss Financial Income',
    profit_loss_profit_other_appropriations: 'Profit Loss Profit Other Appropriations',
    profit_loss_tax: 'Profit Loss Profit Tax',
    profit_loss_profit_after_tax: 'Profit Loss Profit After Tax',
    profit_loss_profit_retained_profit: 'Profit Loss Profit Retained Profit',
    ratios_current_ratio: 'Ratios Current Ratio',
    ratios_liquidity_ratio_or_acid_test: 'Ratios Liquidity Ratio or Acid Test',
    ratios_equity_in_percentage: 'Ratios Equity in Percentage',
    ratios_current_debt_ratio: 'Ratios Current Debt Ratio',
    ratios_gearing: 'Ratios Gearing',
    ratios_pre_tax_profit_margin: 'Ratios Pre Tax Profit Margin',
    ratios_return_on_capital_employed: 'Ratios Return on Capital Employed',
    ratios_return_on_net_assets_employed: 'Ratios Return on Net Assets Employed',
    ratios_debtor_days: 'Ratios Debtor Days',
    ratios_creditor_days: 'Ratios Creditor Days',
    ratios_return_on_total_assets_employed: 'Ratios Return on Total Assets Employed',
    ratios_total_debt_ratio: 'Ratios Total Debt Ratio',
    ratios_sales_or_net_working_capital: 'Ratios Sales or Net Working Capital',
    other_financials_net_worth: 'Other Financials Net Worth',
    other_financials_working_capital: 'Other Financials Working Capital',

  };

  return (

    <CommercialCompanyDataStyles>
      <CenteredDivTitle className="d-flex align-items-center justify-content-center gap-3 mt-3">
        <div className="row title-questionnaire">
          <div className="quote-h2 divider">
            <h2>Completa I Dati</h2>
          </div>
        </div>
      </CenteredDivTitle>

      <div className="card container">
        <div className="card-body">
          <ul className="nav nav-tabs nav-tabs-bordered">
            <li className="nav-item">
              <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#commercial-company">Commercial Company</button>
            </li>
            <li className="nav-item">
              <button className="nav-link" data-bs-toggle="tab" data-bs-target="#commercial_company_commentaries">Commercial Company Commentaries</button>
            </li>
            <li className="nav-item">
              <button className="nav-link" data-bs-toggle="tab" data-bs-target="#share_holders">Share Holders</button>
            </li>
            <li className="nav-item">
              <button className="nav-link" data-bs-toggle="tab" data-bs-target="#commercial_company_directors">Company Directors</button>
            </li>
            <li className="nav-item">
              <button className="nav-link" data-bs-toggle="tab" data-bs-target="#commercial_company_extended_group_structure">Extended Group Structure</button>
            </li>
            <li className="nav-item">
              <button className="nav-link" data-bs-toggle="tab" data-bs-target="#commercial_company_financial_statements">Company Financial Statements</button>
            </li>
            <li className="nav-item">
              <button className="nav-link" data-bs-toggle="tab" data-bs-target="#commercial_company_branches">Company Branches</button>
            </li>
            <li className="nav-item">
              <button className="nav-link" data-bs-toggle="tab" data-bs-target="#commercial_company_indicators">Company Indicators</button>
            </li>
          </ul>

          <div className="tab-content pt-2">
            <CommercialCompany errors={errors} isValid={isValid} register={register} />
            <CompanyComentaries errors={errors} isValid={isValid} commertial_report={commertial_report} />
            <Shareholders errors={errors} isValid={isValid} commertial_report={commertial_report} />
            <CommertialCompanyDirectors errors={errors} isValid={isValid} commertial_report={commertial_report} />
            <CompanyExtendedGroupStructure errors={errors} isValid={isValid} commertial_report={commertial_report} />
            <CompanyFinancialStatements commertial_report={commertial_report} dynamicHeaders={dynamicHeaders} staticLabels={staticLabels} />
            <CompanyBranches errors={errors} isValid={isValid} commertial_report={commertial_report} />
            <CompanyIndicators errors={errors} isValid={isValid} commertial_report={commertial_report} />
          </div>
        </div>
      </div>

      <div className="footer-buttons">
        <div className="d-flex justify-content-between">

          <button className="btn btn-questionnaire" onClick={continueToQuestionario}>
            Continua
          </button>

        </div>
      </div>
    </CommercialCompanyDataStyles>

  );
}

export default CommercialCompanyData;
