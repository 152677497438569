import styled from 'styled-components';

export const StyledDottedDivider = styled.div`
    margin-left: 10px;
    margin-right: 10px;
    background-image: linear-gradient(grey 33%, rgba(255,255,255,0) 0%);
    background-position: right;
    background-size: 1px 1px;
    background-repeat: repeat-y;
    border:1px dashed grey;
`;
