import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { FormGroup, Label } from './styles/common';

function FormReactSelectStates({
  label, options, values, error, valid, onChange,
}) {
  const [prepOptions, setPrepOptions] = useState([]);
  const [inputText, setInputText] = useState('');

  const defaultBorder = '#CBCBCD';

  const filterStates = (inputValue) => {
    if (inputValue.length >= 3) {
      return prepOptions.filter((i) => i.label.toLowerCase().startsWith(inputValue.toLowerCase()));
    }

    return [];
  };

  const promiseOptions = (inputValue) => new Promise((resolve) => {
    setTimeout(() => {
      resolve(filterStates(inputValue));
    }, 500);
  });

  const handleChange = (value) => {
    if (value.travel_country_code !== undefined || value.travel_country_code !== '') {
      const country = options.find((opt) => opt.id == value.travel_country_code);
      if (country !== undefined) {
        onChange({
          travel_country_code: country.id,
        });
      }
    }

    if (value.travel_country_code === null || value.travel_country_code === '' || value.travel_country_code === undefined) {
      onChange({
        travel_country_code: '',
      });
    }
  };

  const handleInputChange = (inputValue) => {
    setInputText(inputValue);
  };

  const handleMenuClose = () => {
    if (!values.travel_country_code && inputText) {
      const startingWithOptions = prepOptions.filter((option) => option.label.toLowerCase().startsWith(inputText.toLowerCase()));
      const firstStartingOption = startingWithOptions[0];
      const findCarPlateSymbol = options.find((opt) => opt.id == firstStartingOption.value);
      if (firstStartingOption && findCarPlateSymbol) {
        onChange({
          travel_country_code: firstStartingOption.value,
        });
      }
    }
  };

  useEffect(() => {
    setPrepOptions(options.map((i) => ({ value: i.id, label: i.name })));
  }, [options]);

  return (
    <FormGroup>
      <Label>{label}</Label>
      <AsyncSelect
        onMenuClose={handleMenuClose}
        onInputChange={handleInputChange}
        loadOptions={promiseOptions}
        defaultOptions
        isSearchable
        placeholder=""
        value={options.filter((opt) => opt.id == values.travel_country_code).map((o) => ({ value: o.id, label: o.name }))[0]}
        onChange={(val) => {
          handleChange({
            travel_country_code: val === null || val === undefined ? '' : val.value,
          });
        }}
        loadingMessage={() => 'Caricando stati...'}
        clearValue
        isClearable
        noOptionsMessage={() => 'Stati non trovati!'}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderWidth: '2px !important',
            borderColor: `${error ? '#dc3545' : valid ? '#198754' : state.isSeleced ? defaultBorder : state.isFocused ? defaultBorder : defaultBorder}`,
            padding: '0rem 0.75rem',
            fontWeight: 'bold',
            borderRadius: '0.375rem',
            backgroundColor: '#FBFAFA',
            '&:hover': defaultBorder,
            boxShadow: 'none',
            textAlign: 'center',
          }),
          singleValue: (provided) => ({
            ...provided,
            marginLeft: '15%',
          }),
        }}
      />
      <div className={`invalid-feedback ${error ? 'd-block' : ''}`}>{error}</div>
    </FormGroup>
  );
}

export default FormReactSelectStates;
