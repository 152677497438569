import caller from './backofficeResultsSlice';
import { quotationsBackofficeAxe } from '../../services/axios-client/quotationsBackofficeAxeClient';
import { generalAlertError, generalAlertSuccessToast } from '../../common/utils/alerts';
import { backOfficeAxe } from '../../services/axios-client/backofficeClient';

export const getMetlifeQuotesData = async (requestToken) => {
  const { data: res } = await quotationsBackofficeAxe.get(
    `/quotation-results/quotes/infortuni/${requestToken}`,
  );

  caller.metlifeQuotesDataUpdated({
    loading: false,
    customer: {
      name: res.data.customer.name,
      surname: res.data.customer.surname,
      gender: res.data.customer.gender,
      fiscal_code: res.data.customer.fiscal_code,
      phone: res.data.customer.phone,
      email: res.data.customer.email,
      date_of_birth: res.data.customer.date_of_birth,
      country_of_birth_code: res.data.customer.country_of_birth_code,
      province_of_birth_code: res.data.customer.province_of_birth_code,
      commune_of_birth_code: res.data.customer.commune_of_birth_code,
      born_abroad: res.data.customer.born_abroad,
      residence_province_code: res.data.customer.residence_province_code,
      residence_commune_code: res.data.customer.residence_commune_code,
      postal_code: res.data.customer.postal_code,
      address: res.data.customer.address,
      house_number: res.data.customer.house_number,
      civil_status_id: res.data.customer.civil_status_id,
      education_level_id: res.data.customer.education_level_id,
      profession_id: res.data.customer.profession_id,
      province: res.data.customer.province,
    },
    quote: {
      request_id: res.data.quote.request_id,
      pack_type: res.data.quote.pack_type,
      company: res.data.quote.company,
      plan: res.data.quote.plan,
      name: res.data.quote.name,
      dc: res.data.quote.decesso,
      ip: res.data.quote.invalidita_permanente,
      diaria_ricovero: res.data.quote.diaria_ricovero,
      diaria_gesso: res.data.quote.diaria_gesso,
      premio_annuo: res.data.quote.premio_annuo,
      ulc: res.data.quote.ulc,
      ulc_premio_aggiuntivo:
        res.data.quote.ulc !== null ? res.data.quote.ulc_premio_aggiuntivo : 0,
      rsm: res.data.quote.rsm,
      rsm_premio_aggiuntivo:
        res.data.quote.rsm !== null ? res.data.quote.rsm_premio_aggiuntivo : 0,
      is_aquista: res.data.quote.is_aquista,
    },
    saved: res.data.quote.is_saved === 1,
    quoteId: res.data.quote.quotation_id,
    insuranceRequest: res.data.insurance_request,
  });
};

export const updateInfortuniExtensions = (value) => {
  caller.infortuniQuotesUpdateExtensions(value);
};

export const addInfortuniExtension = async (request_id, extensions) => {
  const data = {
    ulc: '',
    rsm: '',
    request_id,
  };

  if (extensions.includes('ustioni')) {
    data.ulc = 1;
  } else data.ulc = 0;
  if (extensions.includes('rimborso')) {
    data.rsm = 1;
  } else data.rsm = 0;

  caller.metlifeQuotesDataUpdated({
    loading: true,
  });

  const { data: res } = await quotationsBackofficeAxe.put(
    '/guarantees/infortuni/metlife/update-quote',
    data,
  );

  caller.metlifeQuotesDataUpdated({
    quote: {
      request_id: res.data.request_id,
      pack_type: res.data.pack_type,
      company: res.data.company,
      plan: res.data.plan,
      name: res.data.name,
      dc: res.data.decesso,
      ip: res.data.invalidita_permanente,
      diaria_ricovero: res.data.diaria_ricovero,
      diaria_gesso: res.data.diaria_gesso,
      premio_annuo: res.data.premio_annuo,
      ulc: res.data.ulc,
      ulc_premio_aggiuntivo:
        res.data.ulc !== null ? res.data.ulc_premio_aggiuntivo : 0,
      rsm: res.data.rsm,
      rsm_premio_aggiuntivo:
        res.data.rsm !== null ? res.data.rsm_premio_aggiuntivo : 0,
    },
    loading: false,
  });
};

export const resetInfortuniQuotes = () => {
  caller.infortuniQuotesReset();
};

export const loadAutoQuotes = async (requestToken) => {
  const res = await quotationsBackofficeAxe.get(
    `/quotation-results/quotes/vehicle/${requestToken}`,
  );
  if (res.status === 200) {
    caller.autoQuotesRequestInfoUpdated(res.data.data);
  }
};

export const addQuoteToCartAutoBackOffice = async (quoteId, callback) => {
  caller.autoQuotesDataUpdated({
    addingQuote: true,
  });
  try {
    const response = await backOfficeAxe.get(`/quotations/save/${quoteId}`);

    if (response.status !== 200) {
      generalAlertError('La quotazione non e stata aggiunta! Riprova.');
      caller.autoQuotesDataUpdated({
        addingQuote: false,
      });
      return;
    }
    caller.autoQuotesDataUpdated({
      addingQuote: false,
    });

    callback();
  } catch (error) {
    generalAlertError('Errore Interno.');
    caller.autoQuotesDataUpdated({
      addingQuote: false,
    });
  }
};

export const loadMotoQuotes = async (requestToken) => {
  const res = await quotationsBackofficeAxe.get(
    `/quotation-results/quotes/moto/${requestToken}`,
  );
  if (res.status === 200) {
    caller.motoQuotesRequestInfoUpdated(res.data.data);
  }
};

export const addQuoteMotoToCartBackoffice = async (quoteId, callback) => {
  caller.motoQuotesDataUpdated({
    addingQuote: true,
  });
  try {
    const response = await backOfficeAxe.get(`/quotations/save/${quoteId}`);

    if (response.status !== 200) {
      generalAlertError('La quotazione non e stata aggiunta! Riprova.');
      caller.motoQuotesDataUpdated({
        addingQuote: false,
      });
      return;
    }
    caller.motoQuotesDataUpdated({
      addingQuote: false,
    });

    callback();
  } catch (error) {
    generalAlertError('Errore Interno.');
    caller.motoQuotesDataUpdated({
      addingQuote: false,
    });
  }
};

export const updateAutoQuotesData = (requestInfo) => {
  const { quotations, failed_quotations } = requestInfo;
  let companyIds = [];

  quotations.forEach((quote) => {
    companyIds.push(quote.company_id);
  });

  failed_quotations.forEach((quote) => {
    companyIds.push(quote.company_id);
  });

  companyIds = companyIds.filter((v, i, a) => a.indexOf(v) === i);

  const toUpdate = {};

  if (quotations.length === 0) {
    toUpdate.quotes = null;
    toUpdate.noQuotes = true;
  } else {
    toUpdate.quotes = quotations;
    toUpdate.noQuotes = false;
  }

  toUpdate.failedQuotes = failed_quotations;

  caller.autoQuotesDataUpdated(toUpdate);

  const activeBrands = [1, 2];

  let clearTimer = false;

  if (companyIds.length === activeBrands.length) {
    clearTimer = true;
  }

  return clearTimer;
};

export const updateMotoQuotesData = (requestInfo) => {
  const { quotations, failed_quotations } = requestInfo;
  let companyIds = [];

  quotations.forEach((quote) => {
    companyIds.push(quote.company_id);
  });

  failed_quotations.forEach((quote) => {
    companyIds.push(quote.company_id);
  });

  companyIds = companyIds.filter((v, i, a) => a.indexOf(v) === i);

  const toUpdate = {};

  if (quotations.length === 0) {
    toUpdate.quotes = null;
    toUpdate.noQuotes = true;
  } else {
    toUpdate.quotes = quotations;
    toUpdate.noQuotes = false;
  }

  toUpdate.failedQuotes = failed_quotations;

  caller.motoQuotesDataUpdated(toUpdate);

  const activeBrands = [1, 2];

  let clearTimer = false;

  if (companyIds.length === activeBrands.length) {
    clearTimer = true;
  }

  return clearTimer;
};

export const purchasePrima = async (quotationId) => {
  caller.changeQuotationAquistaLoading(quotationId);

  const res = await backOfficeAxe.post(
    `/quotations/${quotationId}/purchase/prima`,
  );

  caller.changeQuotationAquistaLoading(null);

  if (res.status === 200) {
    const { expired, link } = res.data.data;
    if (expired === true) {
      generalAlertError(
        'È necessario compilare nuovamente il preventivo',
        'Link e scaduto!',
      );
      return;
    }

    if (link !== null) {
      window.open(`/redirect?url=${link}`);
    }
  }
};

export const generateInfodriveQuoteResultPage = async (request_token) => {
  caller.autoQuotesDataUpdated({
    loading: true,
  });

  const res = await quotationsBackofficeAxe.post(
    `/quotations/infodrive/quote/${request_token}`,
  );

  if (res.status === 400) {
    generalAlertError('Non puoi aggiungere questo prodotto in questo momento!');
    return;
  }

  await loadAutoQuotes(request_token);
};

export const toggleCoveragesAction = (coverageId) => {
  caller.autoQuotesToggleCoverages(coverageId);
};

export const toggleMotoCoveragesAction = (coverageId) => {
  caller.motoQuotesToggleCoverages(coverageId);
};

export const updateGuaranteesAutoAction = async (
  requestToken,
  coverages,
  quoteId,
) => {
  if (quoteId !== undefined || quoteId !== null) {
    await quotationsBackofficeAxe.post(
      `/guarantees/vehicle/${requestToken}/update-guarantees`,
      {
        coverages,
      },
      {
        params: {
          quoteId,
        },
      },
    );
  } else {
    await quotationsBackofficeAxe.post(
      `/guarantees/vehicle/${requestToken}/update-guarantees`,
      {
        coverages,
      },
    );
  }

  loadAutoQuotes(requestToken);
};

export const resetAutoQuotes = () => {
  caller.autoQuotesReset();
};

export const professionQuotesGetQuotes = async (requestToken) => {
  const { data: res } = await quotationsBackofficeAxe.get(
    `/quotation-results/quotes/profession/${requestToken}`,
  );

  caller.professionQuotesDataUpdated({
    loading: false,
    quoteId: res.data.quotations[0].id,
    profession: res.data.profession,
    userData: res.data,
    extensions: res.data.profession.extensions,
    customer: res.data.customer,
    quotations: res.data.quotes,
    processingQuote: res.data.processingQuote,
    tutelaLegaleQuote: res.data.tutelaLegaleQuote,
  });
};

export const addQuoteToCartBackOffice = async (quoteId, callback) => {
  caller.professionQuotesDataUpdated({
    addingQuote: true,
  });
  try {
    const response = await backOfficeAxe.get(`/quotations/save/${quoteId}`);

    if (response.data.error === 'insurance_in_process') {
      generalAlertError('Impossibile aggiungere la quotazione. Il preventivo e in elaborazione');
      caller.professionQuotesDataUpdated({
        addingQuote: false,
      });
      return;
    }

    if (response.status !== 200) {
      generalAlertError('La quotazione non e stata aggiunta! Riprova.');
      caller.professionQuotesDataUpdated({
        addingQuote: false,
      });
      return;
    }
    caller.professionQuotesDataUpdated({
      addingQuote: false,
    });

    callback();
  } catch (error) {
    generalAlertError('Errore Interno.');
    caller.professionQuotesDataUpdated({
      addingQuote: false,
    });
  }
};

export const professionAddExtension = async (
  requestToken,
  extensions,
  callback,
) => {
  await quotationsBackofficeAxe.put(
    `/guarantees/profession/${requestToken}/update`,
    {
      extensions: extensions.join(','),
    },
  );
  callback();
};

export const resetProfessionQuotes = () => {
  caller.professionQuotesReset();
};

export const infodriveGetQuote = async (requestToken) => {
  const res = await quotationsBackofficeAxe.get(
    `/quotation-results/quotes/infodrive/${requestToken}`,
  );
  caller.infodriveQuotesDataUpdated({
    insuranceRequest: res.data.data.insuranceRequest,
    quote: res.data.data.quote,
    loading: false,
    markupPercentage: res.data.data.quote.markup_percentage,
    failedQuotation: res.data.data.failedQuotation,
  });
};

export const resetInfodriveQuotes = () => {
  caller.infodriveQuotesReset();
};

/**
 * Travel Quotes
 */

export const loadTravelQuotes = async (requestToken) => {
  const res = await quotationsBackofficeAxe.get(
    `/quotation-results/quotes/travel/${requestToken}`,
  );

  caller.travelQuotesDataUpdated({
    insuranceRequest: res.data.insuranceRequest,
    quotations: res.data.quotations,
    failedQuotations: res.data.failed_quotations,
    customer: res.data.customer,
    quotes: res.data.quotes,
    processingQuote: res.data.processingQuote,
    tutelaLegaleQuote: res.data.tutelaLegaleQuote,
    loading: false,
  });
};

export const addQuoteTravelToCartBackoffice = async (quoteId, callback) => {
  caller.travelQuotesDataUpdated({
    addingQuote: true,
  });
  try {
    const response = await backOfficeAxe.get(`/quotations/save/${quoteId}`);

    if (response.status !== 200) {
      generalAlertError('La quotazione non e stata aggiunta! Riprova.');
      caller.travelQuotesDataUpdated({
        addingQuote: false,
      });
      return;
    }
    caller.travelQuotesDataUpdated({
      addingQuote: false,
    });

    callback();
  } catch (error) {
    generalAlertError('Errore Interno.');
    caller.travelQuotesDataUpdated({
      addingQuote: false,
    });
  }
};

export const resetTravelQuotes = () => {
  caller.travelQuotesReset();
};

export const updateTravelGuarantee = async (
  quoteId,
  coverages,
  requestToken,
) => {
  const res = await quotationsBackofficeAxe.put(
    `/guarantees/travel/${quoteId}/update`,
    {
      coverages,
    },
  );

  if (res.status === 200) {
    loadTravelQuotes(requestToken);
  }
};

export const updateGuaranteesMotoAction = async (
  requestToken,
  coverages,
  quoteId,
) => {
  if (quoteId !== undefined || quoteId !== null) {
    await quotationsBackofficeAxe.post(
      `/guarantees/moto/${requestToken}/update-guarantees`,
      {
        coverages,
      },
      {
        params: {
          quoteId,
        },
      },
    );
  } else {
    await quotationsBackofficeAxe.post(
      `/guarantees/moto/${requestToken}/update-guarantees`,
      {
        coverages,
      },
    );
  }

  loadMotoQuotes(requestToken);
};

export const resetMotoQuotes = () => {
  caller.motoQuotesReset();
};

export const purchaseVehicleQuotation = async (quotationId, refresh) => {
  const response = await backOfficeAxe.get(`/quotes/vehicle/${quotationId}/purchase`);

  if (response.status == 400) {
    const { error } = response.data;
    if (error === 'already_purchased') {
      generalAlertError('Hai già acquistato il preventivo');
      return;
    }

    if (error === 'quotation_expired') {
      generalAlertError('La quotazione è scaduta');
      return;
    }

    if (error === 'can_not_buy') {
      generalAlertError("Impossibile effettuare l'acquisto");
      return;
    }
  } else if (response.status !== 200) {
    generalAlertError('Errore Interno');
    return;
  }

  generalAlertSuccessToast('Preventivo acquistato');
  refresh();
};

export const updateSortByMoto = (sortBy) => {
  caller.motoQuotesDataUpdated({
    sortBy,
  });
};

export const removeQuoteAutoFromCart = async (quoteId, callback) => {
  caller.autoQuotesDataUpdated({
    loading: true,
  });

  try {
    const response = await backOfficeAxe.get(`/quotations/remove/${quoteId}`);

    if (response.status !== 200) {
      generalAlertError('La quotazione non e stata rimossa! Riprova.');
      caller.autoQuotesDataUpdated({
        loading: false,
      });
      return;
    }
    caller.autoQuotesDataUpdated({
      loading: false,
    });

    callback();
  } catch (error) {
    generalAlertError('Errore Interno.');
    caller.autoQuotesDataUpdated({
      loading: false,
    });
  }
};

export const removeQuoteProfessionFromCart = async (quoteId, callback) => {
  caller.professionQuotesDataUpdated({
    loading: true,
  });

  try {
    const response = await backOfficeAxe.get(`/quotations/remove/${quoteId}`);

    if (response.status !== 200) {
      generalAlertError('La quotazione non e stata rimossa! Riprova.');
      caller.professionQuotesDataUpdated({
        loading: false,
      });
      return;
    }
    caller.professionQuotesDataUpdated({
      loading: false,
    });

    callback();
  } catch (error) {
    generalAlertError('Errore Interno.');
    caller.professionQuotesDataUpdated({
      loading: false,
    });
  }
};

export const removeQuoteMotoBackofficeFromCart = async (quoteId, callback) => {
  caller.motoQuotesDataUpdated({
    addingQuote: true,
  });
  try {
    const response = await backOfficeAxe.get(`/quotations/remove/${quoteId}`);

    if (response.data.error === 'insurance_in_process') {
      generalAlertError('Impossibile aggiungere la quotazione. Il preventivo e in elaborazione');
      caller.motoQuotesDataUpdated({
        addingQuote: false,
      });
      return;
    }

    if (response.status !== 200) {
      generalAlertError('La quotazione non e stata aggiunta! Riprova.');
      caller.motoQuotesDataUpdated({
        addingQuote: false,
      });
      return;
    }
    caller.motoQuotesDataUpdated({
      addingQuote: false,
    });

    callback();
  } catch (error) {
    generalAlertError('Errore Interno.');
    caller.motoQuotesDataUpdated({
      addingQuote: false,
    });
  }
};
