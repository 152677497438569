import React from 'react';
import { WidgetStyles } from './Widgets.styles';
import AudioRecorder from '../components/AudioRecorder';
import robot from '../../assets/images/robot-head.png';

function SpeechToTextWidget() {
  return (
    <WidgetStyles className="custom-card h-100">
      <div className="custom-card-header">
        <div className="custom-card-section">
          <div className="d-flex align-items-center justify-content-between">
            <h3>Supporto Chat Vocale AI</h3>
            <img src={robot} alt="Mellino" width={40} />
          </div>
        </div>
      </div>
      <div
        className="card card-body"
        style={{ position: 'relative', overflowY: 'visible', height: '80%' }}
      >
        <div className="custom-card-section d-flex align-items-center justify-content-center">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="d-flex align-items-center justify-content-center flex-column gap-3">
                <AudioRecorder />
              </div>
            </div>
          </div>
        </div>
      </div>
    </WidgetStyles>
  );
}

export default SpeechToTextWidget;
