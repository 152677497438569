import {
  faArrowRightLong,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { WidgetStyles } from './Widgets.styles';

function LatestUpdatesWidget() {
  const navigate = useNavigate();

  const state = useSelector((store) => store.dashboard.dashboard);

  const { latest_updates } = state;

  const sendToInsuranceRequestQuotationsList = (lu) => {
    if (lu.product_id === '1' || lu.product_id === '3') {
      navigate(`/quotes/${lu.request_token}`);
    } else if (lu.product_id === '4') {
      navigate(`/quotes/profession/${lu.request_token}`);
    } else if (lu.product_id === '2') {
      navigate(`/quotes/moto/${lu.request_token}`);
    } else if (lu.product_id === '5') {
      navigate(`/quotes/metlife/${lu.request_token}`);
    } else if (lu.product_id === '6') {
      navigate(`/quotes/infodrive/${lu.request_token}`);
    }
  };

  return (
    <WidgetStyles className="custom-card latest-updates-card h-100">
      <div className="custom-card-header" style={{ position: 'relative' }}>
        <h3>Ultimi aggiornamenti</h3>
        <div
          className="go-to-page-button"
          onClick={() => navigate('/latest-updates')}
        >
          <FontAwesomeIcon icon={faArrowRightLong} />
        </div>
      </div>
      <div className="custom-card-body h--flex-column">
        <table className="custom-table">
          <thead>
            <tr>
              <th>Creato</th>
              <th>Nome</th>
              <th>Prodotto</th>
              <th>Stato</th>
              <th>Azioni</th>
            </tr>
          </thead>
          <tbody>
            {latest_updates.length === 0 ? (
              <div className="custom-table-empty">Nessun dato</div>
            ) : (
              latest_updates.map((lu, index) => (
                <tr className="custom-table-row" key={index}>
                  <td className="latest-updates-time">
                    {moment(lu.created_at).fromNow(true)}
                  </td>
                  <td className="latest-updates-contract">
                    {lu.customer.business_name !== ''
                      && lu.customer.business_name !== null && (
                        <>{lu.customer.business_name}</>
                    )}
                    {lu.customer.business_name === ''
                      || (lu.customer.business_name === null && (
                        <>
                          {lu.customer?.name}
                          {' '}
                          {lu.customer?.surname}
                        </>
                      ))}
                  </td>
                  <td>{lu.product.name}</td>
                  <td>{lu.status.label}</td>
                  <td className="latest-updates-actions">
                    <div
                      className="action-button"
                      onClick={() => sendToInsuranceRequestQuotationsList(lu)}
                    >
                      <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>

        {latest_updates.length !== 0 && (
          <div
            className="see-more-section"
            onClick={() => navigate('/latest-updates')}
          >
            <div className="see-more-content">Vedi altro</div>
            <div className="see-more-arrow">
              <FontAwesomeIcon icon={faArrowRightLong} />
            </div>
          </div>
        )}
      </div>
    </WidgetStyles>
  );
}

export default LatestUpdatesWidget;
