import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FormInputDate } from '../../common/formInputs/FormInputDate';
import { RemindersForm } from './Reminders.styles';
import { FormInputText } from '../../common/formInputs/FormInputText';
import { FormInputTextarea } from '../../common/formInputs/FormInputTextarea';
import {
  deleteReminder, getReminder, updateReminder,
} from '../../features/reminders/remindersActions';
import PageLoading from '../../common/components/elements/PageLoading';
import { confirmAlert, generalAlertSuccessToast } from '../../common/utils/alerts';
import { useDebouncedEffect } from '../../common/utils/useDebouncedEffect';
import managementAxe from '../../services/axios-client/managementServisAxeClient';
import { FormInputRadio } from '../../common/formInputs/FormInputRadio';
import { flagResponse } from '../../common/constants';

export function UpdateReminder() {
  const navigate = useNavigate();
  const { reminderId } = useParams();
  const state = useSelector((store) => store.reminders.edit_reminder);
  const { loading, error, reminder } = state;

  const schema = yup.object({
    date: yup
      .date()
      .typeError('Il campo è obbligatorio. Seleziona data!')
      .required('Il campo è obbligatorio. Seleziona data!')
      .min(new Date(moment().subtract(1, 'day').format('YYYY-MM-DD')), 'La data è nel passato'),
    title: yup
      .string()
      .required('Il campo è obbligatorio. Inserisci titolo!')
      .max(50, 'Il titolo non deve essere piu di 50 caratteri'),
    description: yup.string().max(300, 'Il descrizione non deve essere piu di 300 caratteri'),
  });

  const [useLink, setUseLink] = useState(0);
  const [searchClient, setSearchClient] = useState('');
  const [clientsResults, setClientsResults] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [insuranceOrQuotation, setInsuranceOrQuotation] = useState('');
  const [quotationsResults, setQuotationsResults] = useState([]);
  const [insurancesResults, setInsurancesResults] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    getValues,
    setValue,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: {
      date: null,
      title: '',
      description: '',
    },
  });

  const data = watch();

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  const searchAction = async (value) => {
    if (value.length >= 3) {
      const response = await managementAxe.get('/broker/dashboard/reminders/customers/search', {
        params: {
          search: value,
        },
      });

      if (response.status === 200) {
        setClientsResults(response.data.data);
      }
    } else {
      setClientsResults([]);
    }
  };

  const onSearchChange = async (value) => {
    setSearchClient(value);
  };

  const getCustomerData = async (customer) => {
    if (customer.gender === 'G') {
      setSearchClient(customer.business_name);
    } else {
      setSearchClient(`${customer.name} ${customer.surname}`);
    }

    setClientsResults([]);
    setSelectedClient(customer);
  };

  const resetSelection = () => {
    setUseLink(0);
    setSearchClient('');
    setClientsResults([]);
    setSelectedClient(null);
    setInsuranceOrQuotation('');
    setQuotationsResults([]);
    setInsurancesResults([]);
  };

  const getQuotationInfoInline = (q) => `${q.product.name} (${q.company_id !== null ? q.company.name : q.preventivas_company.company_name}) | ${q.amount} € | ${q.quotation_status.name}`;

  const selectQuotation = (quotationId) => {
    setValue('quotation_id', quotationId);
    setValue('request_id', null);
    resetSelection();
  };

  const getInsuranceInfoInline = (q) => `${q.product.name} | Creato a: ${moment(q.created_at).format('DD.MM.YYYY HH:mm')}`;

  const selectInsurance = (insuranceId) => {
    setValue('request_id', insuranceId);
    setValue('quotation_id', null);
    resetSelection();
  };

  const submitData = () => {
    const data = getValues();
    updateReminder(reminderId, data, () => {
      navigate('/');
      generalAlertSuccessToast('Memo aggiornata');
    });
  };

  const removeReminder = () => {
    confirmAlert('Sei sicuro di rimuovere questo memo?', () => {
      deleteReminder(reminderId, () => {
        navigate('/');
        generalAlertSuccessToast('Memo Eliminato');
      });
    });
  };

  const handleChange = (data) => {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      setValue(key, data[key]);
    });
  };

  const goToInsurance = () => {
    const productId = reminder.request_product_id;
    const requestToken = reminder.request_token;

    if (productId === '1' || productId === '3') {
      navigate(`/quotes/${requestToken}`);
    } else if (productId === '2') {
      navigate(`/quotes/moto/${requestToken}`);
    } else if (productId === '4') {
      navigate(`/quotes/profession/${requestToken}`);
    } else if (productId === '5') {
      navigate(`/quotes/metlife/${requestToken}`);
    } else if (productId === '6') {
      navigate(`/quotes/infodrive/${requestToken}`);
    }
  };

  const goToQuotation = () => {
    const productId = reminder.quotation_product_id;
    const quoteId = reminder.quotation_id;
    if (productId === 1) {
      navigate(`/quotes/auto/purchase/${quoteId}`);
    }
    if (productId === 2) {
      navigate(`/quotes/moto/purchase/${quoteId}`);
    }
    if (productId === 3) {
      navigate(`/quotes/autocarro/purchase/${quoteId}`);
    }
    if (productId === 4) {
      navigate(`/quotes/profession/purchase/${quoteId}`);
    }
    if (productId === 5) {
      navigate(`/quotes/metlife/purchase/${quoteId}`);
    }
    if (productId === 6) {
      navigate(`/quotes/infodrive/purchase/${quoteId}`);
    }
  };

  useEffect(() => {
    if (reminder !== null) {
      handleChange({
        date: reminder.date,
        title: reminder.title,
        description: reminder.description,
        quotation_id: reminder.quotation_id,
        request_id: reminder.request_id,
      });
    }
  }, [reminder]);

  useEffect(() => {
    getReminder(reminderId);
  }, []);

  useDebouncedEffect(() => searchAction(searchClient), [searchClient], 400);

  useEffect(() => {
    const run = async () => {
      if (insuranceOrQuotation !== '') {
        const params = {};

        if (selectedClient.type === 'customer') {
          params.customer_id = selectedClient.id;
        } else {
          params.active_customer_id = selectedClient.id;
        }

        setQuotationsResults([]);
        setInsurancesResults([]);

        if (insuranceOrQuotation === 'quotations') {
          const response = await managementAxe.get('/broker/dashboard/reminders/customers/quotations', {
            params,
          });

          setQuotationsResults(response.data.quotations);
        } else {
          const response = await managementAxe.get('/broker/dashboard/reminders/customers/insurance-requests', {
            params,
          });

          setInsurancesResults(response.data.insurance_requests);
        }
      }
    };
    run();
  }, [insuranceOrQuotation]);

  useEffect(() => {
    if (useLink === 0) {
      resetSelection();
    }
  }, [useLink]);

  if (loading) {
    return <PageLoading />;
  }

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  return (
    <RemindersForm onSubmit={handleSubmit(submitData)} noValidate>

      <div className="d-flex justify-content-end align-items-center gap-2 my-2">
        {reminder.request_id !== null
        && <div className="btn btn-success" onClick={() => goToInsurance()}>Vai sull preventivo linkato</div>}
        {reminder.quotation_id !== null
        && <div className="btn btn-success" onClick={() => goToQuotation()}>Vai sull preventivo linkato</div>}
      </div>
      <h1>Aggiorna Memo</h1>
      <FormInputDate
        label="Data"
        registration={register('date')}
        minDate={moment().format('YYYY-MM-DD')}
        error={errors.date?.message}
        valid={isValid('date')}
      />
      <FormInputText
        placeholder="Titolo"
        label="Titolo"
        registration={register('title')}
        error={errors.title?.message}
        valid={isValid('title')}
        max={50}
      />
      <FormInputTextarea
        placeholder="Descrizione"
        label="Descrizione"
        registration={register('description')}
        error={errors.description?.message}
        valid={isValid('description')}
        max={300}
        rows={4}
      />

      {data.quotation_id !== null && (
      <div className="d-flex align-items-center gap-2">
        <div className="px-3 py-2 bg-success text-white rounded text-center w-100">Polizza Selezionata</div>
        <div className="btn btn-danger" onClick={() => setValue('quotation_id', null)}>Rimuovi</div>
      </div>
      )}

      {data.request_id !== null && (
      <div className="d-flex align-items-center gap-2">
        <div className="px-3 py-2 bg-success text-white rounded text-center w-100">Preventivo Selezionato</div>
        <div className="btn btn-danger" onClick={() => setValue('request_id', null)}>Rimuovi</div>
      </div>
      )}

      <FormInputRadio
        name="use_link"
        label={data.quotation_id !== null || data.request_id !== null ? 'Vuoi linkare un altra pratica?' : 'Vuoi linkare una pratica?'}
        options={flagResponse}
        onChange={setUseLink}
        value={useLink}
      />

      {useLink === 1 && (
      <>
        <div className="d-flex align-items-start justify-content-center gap-2">
          <div className="w-100">
            <FormInputText
              label="Nome cliente"
              paragraph="Deve contenere almeno 3 caratteri"
              onChange={(value) => onSearchChange(value)}
              value={searchClient}
            />
            {clientsResults.length > 0 && (
              <div>
                <ul className="list-group mb-3">
                  {clientsResults.map((customer, index) => (
                    <li
                      className="list-group-item border-success"
                      key={index}
                      style={{ cursor: 'pointer' }}
                      onClick={() => getCustomerData(customer)}
                    >
                      <>
                        {customer.gender !== 'G' ? `${customer.name} ${customer.surname} (${customer.fiscal_code})` : customer.business_name}
                        {' '}
                        -
                        {' '}
                        {customer.type === 'customer' ? 'Non Attivo' : 'Attivo'}
                      </>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {selectedClient !== null && (
            <>
              <FormInputRadio
                label="Vuoi collegare il memo con una polizza o uno preventivo?"
                name="quotations_or_insurance_requests"
                onChange={setInsuranceOrQuotation}
                value={insuranceOrQuotation}
                options={[
                  { label: 'Polizza', value: 'quotations' },
                  { label: 'Preventivo', value: 'insurance_requests' },
                ]}
              />
            </>
            )}

            {quotationsResults.length > 0 && (
            <div>
              <ul className="list-group mb-3">
                {quotationsResults.map((quotation, index) => (
                  <li
                    className="list-group-item border-success"
                    key={index}
                    style={{ cursor: 'pointer' }}
                    onClick={() => selectQuotation(quotation.id)}
                  >
                    <>
                      {getQuotationInfoInline(quotation)}
                    </>
                  </li>
                ))}
              </ul>
            </div>
            )}

            {insurancesResults.length > 0 && (
            <div>
              <ul className="list-group mb-3">
                {insurancesResults.map((insurance, index) => (
                  <li
                    className="list-group-item border-success"
                    key={index}
                    style={{ cursor: 'pointer' }}
                    onClick={() => selectInsurance(insurance.id)}
                  >
                    <>
                      {getInsuranceInfoInline(insurance)}
                    </>
                  </li>
                ))}
              </ul>
            </div>
            )}
          </div>
        </div>
      </>
      )}

      <button type="submit" className="submit-button">
        Aggiorna
      </button>

      <br />
      <br />
      <br />
      <br />
      <br />
      <hr />
      <div className="btn btn-danger" onClick={removeReminder}>Rimuovi Memo</div>
    </RemindersForm>
  );
}
