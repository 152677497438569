import CodiceFiscale from 'codice-fiscale-js';

const birthplaceMapping = {
  'CONGO EX ZAIRE': 'CONGO LEOPOLDVILLE', //! OK
  'CINESE REPUB. POPOLARE': 'CINA REPUBBLICA POPOLARE', //! OK
  'S. PIERRE E MIQUELON': 'SAINT PIERRE ET MIQUELON (ISOLE)', //! OK
  'BERMUDE (ISOLE)': 'BERMUDA (ISOLE)', //! OK
  MAYOTTE: 'MAYOTTE (ISOLA)', //! OK
  'LA REUNION (ISOLE)': 'LA REUNION (ISOLA)', //! OK
  COMORE: 'COMORE (ISOLE)', //! OK
  'CAPO VERDE (ISOLA)': 'CAPO VERDE', //! OK
  BECIUANIA: 'BECIUANIA-SUD AFRICA BRITANNICO', //! OK
  BASUTOLAND: 'BASUTOLAND-SUD AFRICA BRITANNICO', //! OK
  'BANGLA DESH': 'BANGLADESH', //! OK
  MALESIA: 'MALAYSIA', //! OK
  TIMOR: 'TIMOR (ISOLA)', //! OK
  'MONGOLIA REP. POPOLARE': 'MONGOLIA', //! OK
  'FORMOSA O TAIWAN': 'FORMOSA=TAIWAN', //! OK
  'COSTA DEI PIRATI': 'COSTA DEI PIRATI=TRUCIAL STATES', //! OK
  'COREA (REPUBB. POPOLARE)': 'COREA DEL NORD', //! OK
  'COREA (REPUBBLICA DI)': 'COREA DEL SUD', //! OK
  'ARABIA MERIDIONALE PROTETT.': 'ARABIA MERIDIONALE PROTETTORATO', //! OK
  'SERBIA MONTENEGRO': 'SERBIA E MONTENEGRO', //! OK
  'REPUBBLICA SLOVACCA': 'SLOVACCHIA', //! OK
  RUSSIA: 'RUSSIA=FEDERAZIONE RUSSA', //! OK
  'BOSNIA-ERZEGOVINA': 'BOSNIA ED ERZEGOVINA', //! OK
  TURKEMENISTAN: 'TURKMENISTAN', //! OK
  URSS: 'UNIONE REPUBBLICHE SOCIALISTE SOVIETICHE', //! OK
  'NORMANNE (ISOLE)': 'NORMANNE (ISOLE)=ISOLE DEL CANALE', //! OK
  'PRINCIPATO DI MONACO': 'MONACO', //! OK
  'MAN (ISOLE)': 'MAN (ISOLA)', //! OK
  'MALTA COMINO GOZO (ISOLE)': 'MALTA', //! OK
  JUGOSLAVIA: 'IUGOSLAVIA', //! OK
  'GERMANIA REPU. FEDERALE': 'GERMANIA REPUBBLICA FEDERALE', //! OK ?
  'GERMANIA REPUB. DEMOCRATICA': 'GERMANIA REPUBBLICA DEMOCRATICA', //! OK ?
  'VERGINI (ISOLE) AMERICANE': 'VERGINI AMERICANE (ISOLE)', //! OK
  'GUAYANA BRITANNICA': 'GUYANA BRITANNICA', //! OK
  'GUAYANA FRANCESE': 'GUAYANA=GUYANA FRANCESE', //! OK
  'MALVINE O FALKLAND': 'MALVINE=FALKLAND (ISOLE)', //! OK ?
  'TRINIDAD O TOBAGO': 'TRINIDAD E TOBAGO', //! OK
  'CHRISTMAS (ISOLE)': 'CHRISTMAS (ISOLA)', //! OK
  COOK: 'COOK (ISOLE)', //! OK
  'FIGI O VITI (ISOLE)': 'FIGI=VITI', //! OK
  'GUAM (ISOLE)': 'GUAM (ISOLA)', //! OK
  'NIUE O SAVAGE (ISOLE)': 'NIUE=SAVAGE (ISOLE)', //! OK
  'NORFOLK (ISOLE)': 'NORFOLK (ISOLE E ISOLE DEL MAR DEI CORALLI)', //! OK
  'NUOVA CALEDONIA (ISOLE)': 'NUOVA CALEDONIA (ISOLE E DIPENDENZE)', //! OK
  'NUOVE EBRIDI(ISOLE)': 'NUOVE EBRIDI (ISOLE CONDOMINIO FRANCO-INGLESE)', //! OK
  'PITCAIRN E DIPENDENZE': 'PITCAIRN (E DIPENDENZE)', //! OK
  'POLINESIA (ISOLE)': 'POLINESIA FRANCESE (ISOLE)', //! OK
  'SAMOA OCCIDENTALI (ISOLE)': 'SAMOA', //! OK
  "TOKELAU O DELL'UNIONE (ISOLE)": "TOKELAU=ISOLE DELL'UNIONE", //! OK
  'TONGA O DEGLI AMICI(ISOLE)': 'TONGA', //! OK
  'DIP. NORVEGESI ARTICHE': 'DIPENDENZE NORVEGESI ARTICHE', //! OK
  'DIP. NORVEGESI ANTARTICHE': 'DIPENDENZE NORVEGESI ANTARTICHE', //! OK
  'REPUBBLICA DELLA CINA NAZIONALE': 'CINA NAZIONALE', //! ON SELECT MARKS FARMOSA O TAIWAN
  'NAMIBIA=AFRICA DEL SUD OVEST': 'NAMIBIA', //! OK -> selects AFRICA DEL SUD-OVEST
  DAHOMEY: 'DAHOMEY=BENIN', //! OK -> selects Benin even that is the same on codicefiscale js
  MAURIZIO: 'MAURIZIO (ISOLE)', //! OK
  VOLTA: 'VOLTA=ALTOVOLTA', //! OK -> selects Burkina??
  PANAMA: 'PANAMA', //! OK
  'SAINT KITTS E NEVIS=SAINT CHRISTOPHER E NEVIS': 'SAINT KITTS E NEVIS', //! OK
  MALVINE: 'MALVINE=FALKLAND (ISOLE)', //! OK
  MARSHALL: 'MARSHALL (ISOLE)', //! OK
};

export default function generateFiscalCode({
  name,
  surname,
  gender,
  day,
  month,
  year,
  birthplace,
  birthplaceProvincia,
}) {
  const finalBirthPlace = birthplaceMapping[birthplace] ?? birthplace;

  const cf = new CodiceFiscale({
    name,
    surname,
    gender,
    day,
    month,
    year,
    birthplace: finalBirthPlace,
    birthplaceProvincia,
  });
  return cf.code;
}

export const decodeFiscalCode = (fiscalCode) => new CodiceFiscale(fiscalCode).toJSON();
