import React, { useCallback, useState } from 'react';
import {
  DndContext,
  useSensor,
  useSensors,
  rectIntersection,
  MouseSensor,
  DragOverlay,
  TouchSensor,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { SortableItem } from './SortableItem';
import { changeOrder } from '../../features/widgets/widgetsActions';
import Item from './Item';

function DraggableContainer({ activeWidgets, setActiveWidgets }) {
  const [activeId, setActiveId] = useState(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragStart = useCallback((event) => {
    setActiveId(event.active.id);
  }, []);

  const handleDragEnd = useCallback((event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setActiveWidgets((items) => {
        const oldIndex = items.findIndex((item) => item.order_nr === active.id);
        const newIndex = items.findIndex((item) => item.order_nr === over.id);
        const movedArr = arrayMove(items, oldIndex, newIndex);

        changeOrder(movedArr);

        return movedArr;
      });
    }
    setActiveId(null);
  }, []);

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={rectIntersection}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
    >
      <SortableContext
        items={activeWidgets}
        strategy={verticalListSortingStrategy}
      >
        <div className="row">
          {activeWidgets.map((el) => (
            <SortableItem key={el.order_nr} id={el.order_nr} name={el.name} widgetId={el.id} />
          ))}
        </div>
      </SortableContext>
      <DragOverlay adjustScale style={{ transformOrigin: '0 0' }}>
        {activeId ? (
          <Item name={activeWidgets.find((e) => e.order_nr === activeId).name} widgetId={activeWidgets.find((e) => e.order_nr === activeId).id} />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
}

export default DraggableContainer;
