import styled from 'styled-components';

export const ContactForm = styled.form`
    margin: 50px auto;
    padding: 20px;
    width: calc(100% - 40px);
    max-width: 600px;
    border: 1px solid var(--greenia-color);
    border-radius: 20px;

    .submit-button {
        margin-top: 30px;
        width: 100%;
        padding: 10px 20px;
        outline: none;
        border: 2px solid var(--greenia-color);
        background-color: var(--greenia-color);
        color: white;
        font-weight: bold;
        border-radius: 10px;
        transition: .3s;

        &:hover {
            background-color: transparent;
            color: var(--greenia-color);
        }
    }

    .show-suggestions {
        padding: 10px;
        color: rgb(100,200,100);
        font-weight: bold;
        cursor: pointer;
        border: 1px solid rgb(100,200,100);
        border-radius: 10px;
        font-size: 14px;
        text-align: center;
        transition: .3s;

        &:hover {
            background-color: rgb(100,200,100);
            color: white;
        }
    }

    .suggestion {
        border: 1px solid rgb(100,200,100);
        border-radius: 5px;
        padding: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 15px;
    }

    .copy-to-message {
        display: inline-block;
        margin-top: 10px;
        padding: 5px;
        color: rgb(100,200,100);
        border: 1px solid rgb(100,200,100);
        border-radius: 5px;
        cursor: pointer;
    }
`;
