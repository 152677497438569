import React from 'react';
import EuropeassistanceCardResult from '../Cards/EuropeassistanceCardResult';

function ProcessingQuoteSelectedTravel({ processingQuote }) {
  return (
    <>
      {processingQuote
        && (
          <div className="col-12 mb-2">
            <EuropeassistanceCardResult quote={processingQuote} />
          </div>
        )}
      {processingQuote === null && <div className="alert alert-secondary">Seleziona Quotazione!</div>}
    </>
  );
}

export default ProcessingQuoteSelectedTravel;
