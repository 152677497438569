import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import jwtDecode from 'jwt-decode';
import StorageService from '../services/StorageService';

const Storage = new StorageService();

const checkIfAB = (user) => {
  if (
    user.broker_section === 'A'
    || user.broker_section === 'B'
  ) return true;
  return false;
};

const useAuth = () => {
  const authUser = useSelector((store) => store.user);
  let isUserLoggedIn = false;
  let isUserSectionAB = false;
  let hasBackoffice = false;

  const checkHasBackoffice = (user) => {
    if (user.backoffice === 1) return true;
    return false;
  };

  const token = Storage.getToken();

  if (token !== null) {
    const user = jwtDecode(token);
    isUserLoggedIn = true;
    isUserSectionAB = checkIfAB(user);
    hasBackoffice = checkHasBackoffice(user);
  }

  useEffect(() => {
    const token = Storage.getToken();

    if (token !== null) {
      const user = jwtDecode(token);
      isUserLoggedIn = true;
      isUserSectionAB = checkIfAB(user);
      hasBackoffice = checkHasBackoffice(user);
    }
  }, [authUser.token]);

  return {
    isUserLoggedIn,
    isUserSectionAB,
    hasBackoffice,
  };
};

export default useAuth;
