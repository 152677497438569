import styled from 'styled-components';

export const UsefulDocumentsStyles = styled.div`
    width: 100%;
    max-width: 1000px;
    margin: auto;
    margin-top: 40px;

    .title {
        font-weight: bold;
    }

    .files-list {
        padding: 20px;
    }

    .file-list {
        padding: 10px;
        border-bottom: 1px solid lightgrey;
    }
`;
