import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
// import Joyride from 'react-joyride';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faPlus } from '@fortawesome/free-solid-svg-icons';
import { BrokersStyles, InfoButton } from './Brokers.styles';
import PageLoading from '../../common/components/elements/PageLoading';
import TableButton from '../../common/components/TableButton';
import {
  getBrokers,
} from '../../features/brokers/brokersActions';
// import { useTourContext } from '../../context/useTourContext';
import ErrorComponent from '../../common/components/ErrorComponent';
// import { joyrideOptions } from '../../common/utils/joyrideUtils';
// import { handleCallbackBrokers } from '../../common/utils/joyrideCallbacks';
// import { brokersSteps } from '../../common/utils/joyrideSteps';

function Brokers() {
  const navigate = useNavigate();
  const state = useSelector((store) => store.brokers.brokers_list);
  const { loading, brokers, error } = state;
  // const [steps, setSteps] = useState([]);

  const loadBrokersData = async () => {
    getBrokers();
  };

  useEffect(() => {
    loadBrokersData();
  }, []);

  // const {
  //   setState,
  //   state: { run, showBeacon },
  // } = useTourContext();

  // useEffect(() => {
  //   if (localStorage.getItem('brokersList') === 'done') {
  //     setState({ run: false });
  //   } else {
  //     setTimeout(() => {
  //       setState({ run: true });
  //     }, 1500);
  //   }
  // }, [localStorage.getItem('brokersList')]);

  // const fireNewQuoteTour = () => {
  //   localStorage.removeItem('brokersList');
  //   setState({ run: true, tourActive: true });
  // };

  // useEffect(() => {
  //   brokersSteps(brokers, setSteps);
  // }, [brokers]);

  if (loading) {
    return <PageLoading />;
  }

  if (error !== null) {
    return <ErrorComponent error={error} />;
  }

  const headers = [
    'Azioni',
    'Nome',
    'Cognome',
    'Ivass',
    'Telefono',
    'Eta',
    'Genere',
    'Indirizzo',
    'Email',
    'Commisioni',
  ];

  return (
    <BrokersStyles>
      {/* <Joyride
        steps={steps}
        continuous
        showBeacon={showBeacon}
        locale={{
          back: 'Indietro',
          close: 'Chiudi',
          last: 'Fine',
          next: 'Prossima',
          open: 'Apri il modale',
          restart: 'Riavvia il tour ',
          skip: 'Salta',
        }}
        styles={{
          options: joyrideOptions,
          buttonNext: {
            backgroundColor: '#00a651',
            outline: 'none',
          },
          buttonBack: {
            color: '#00a651',
            outline: 'none',
          },
          beaconInner: {
            backgroundColor: '#00a651',
          },
          beaconOuter: {
            borderColor: '#00a651',
            backgroundColor: 'rgba(68 , 154 , 71, 0.4)',
          },
          buttonSkip: {
            outline: 'none',
          },
          buttonClose: {
            outline: 'none',
          },
        }}
        run={run}
        callback={(data) => handleCallbackBrokers(data, steps, setState)}
        showSkipButton
        showProgress
      /> */}
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <h1 className="h3 ps-2 ps-lg-0 py-3 py-md-0">Gestisci rete</h1>
        <div className="d-flex align-items-center justify-content-end">
          <InfoButton onClick={() => navigate('/brokers-reports')} className="broker-list-one">
            <FontAwesomeIcon icon={faChartLine} />
            &nbsp;&nbsp;&nbsp;Report
          </InfoButton>
          <InfoButton onClick={() => navigate('/brokers/new')} className="ms-2 me-2 bg-success broker-list-two">
            <FontAwesomeIcon icon={faPlus} />
            &nbsp;&nbsp;&nbsp;Aggiungi Intermediario
          </InfoButton>
          {/* {localStorage.getItem('brokersList') === 'done' && (
            <div style={{ cursor: 'pointer' }} onClick={() => fireNewQuoteTour()}>
              <button className="btn btn-success text-white px-4 ">Tutorial</button>
            </div>
          )} */}
        </div>
      </div>
      <div className="overflow-auto mt-3 table-responsive broker-list-three">
        <table className="custom-table table">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th scope="col" key={index}>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {brokers.map((data, index) => (
              <tr className="custom-table-row" key={index}>
                <td className="d-flex align-items-center" style={{ minWidth: 120 }}>
                  <TableButton
                    text="Modifica"
                    className="warning broker-list-four"
                    route={`brokers/${data.id}`}
                  />
                  <TableButton
                    text="Controlla Produzione"
                    className="info broker-list-five"
                    route={`brokers/${data.id}/quotations`}
                  />
                </td>

                <td>{data?.name}</td>
                <td>{data?.surname}</td>
                <td>{data?.ivass_registration_number}</td>
                <td>{data?.phone}</td>
                <td>{data?.age}</td>
                <td>{data?.gender}</td>
                <td className="text-truncate">{data?.address}</td>
                <td>{data?.email}</td>
                <td>
                  {data?.commission_to_managing_broker}
                  %
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </BrokersStyles>
  );
}

export default Brokers;
