import React from 'react';
import { FormInputText } from '../../../common/formInputs/FormInputText';
import { FormInputSelect } from '../../../common/formInputs/FormInputSelect';
import { creditRatingCommonValue, flagResponse } from '../../../common/constants';
import { FormInputDate } from '../../../common/formInputs/FormInputDate';
import { FormInputRadio } from '../../../common/formInputs/FormInputRadio';

function CommercialCompany({ register, isValid, errors }) {
  return (
    <div className="tab-pane fade show active commercial-company" id="commercial-company">
      <h4 className="text-center mt-3">Commertial Company</h4>
      <div className="row mt-4">
        <div className="col-12 col-md-6">
          <FormInputText
            placeholder="Business Name"
            label="Business Name"
            registration={register('business_name')}
            error={errors.business_name?.message}
            valid={isValid('business_name')}
          />
        </div>
        <div className="col-12 col-md-6">
          <FormInputText
            placeholder="Company Number"
            label="Company Number"
            registration={register('company_number')}
            error={errors.company_number?.message}
            valid={isValid('company_number')}
          />
        </div>
        <div className="col-12 col-md-6">
          <FormInputText
            placeholder="Company Registration Number"
            label="Company Registration Number"
            registration={register('company_registration_number')}
            error={errors.company_registration_number?.message}
            valid={isValid('company_registration_number')}
          />

        </div>
        <div className="col-12 col-md-6">
          <FormInputText
            placeholder="Activity Code"
            label="Activity Code"
            registration={register('activity_code')}
            error={errors.activity_code?.message}
            valid={isValid('activity_code')}
          />
        </div>
        <div className="col-12 col-md-6">
          <FormInputText
            placeholder="Activity Description"
            label="Activity Description"
            registration={register('activity_description')}
            error={errors.activity_description?.message}
            valid={isValid('activity_description')}
          />
        </div>
        <div className="col-12 col-md-6">
          <FormInputText
            placeholder="Activity Industry Sector"
            label="Activity Industry Sector"
            registration={register('activity_industry_sector')}
            error={errors.activity_industry_sector?.message}
            valid={isValid('activity_industry_sector')}
          />
        </div>
        <div className="col-12 col-md-6">
          <FormInputText
            placeholder="Company Status"
            label="Company Status"
            registration={register('company_status')}
            error={errors.company_status?.message}
            valid={isValid('company_status')}
          />
        </div>
        <div className="col-12 col-md-6">
          <FormInputText
            placeholder="Turnover Value"
            label="Turnover Value"
            registration={register('turnover_value')}
            error={errors.turnover_value?.message}
            valid={isValid('turnover_value')}
          />
        </div>
        <div className="col-12 col-md-6">
          <FormInputSelect
            label="Credit Rating Common Value"
            placeholder="-Seleziona-"
            options={creditRatingCommonValue}
            registration={register('credit_rating_common_value')}
            error={errors.credit_rating_common_value?.message}
            valid={isValid('credit_rating_common_value')}
          />
        </div>
        <div className="col-12 col-md-6">
          <FormInputText
            placeholder="Credit Rating Common Description"
            label="Credit Rating Common Description"
            registration={register('credit_rating_common_desc')}
            error={errors.credit_rating_common_desc?.message}
            valid={isValid('credit_rating_common_desc')}
          />
        </div>
        <div className="col-12 col-md-6">
          <FormInputText
            placeholder="Credit Rating Limit Value"
            label="Credit Rating Limit Value"
            registration={register('credit_rating_limit_value')}
            error={errors.credit_rating_limit_value?.message}
            valid={isValid('credit_rating_limit_value')}
          />
        </div>
        <div className="col-12 col-md-6">
          <FormInputText
            placeholder="Alter Summary Province"
            label="Alter Summary Province"
            registration={register('alter_summary_province')}
            error={errors.alter_summary_province?.message}
            valid={isValid('alter_summary_province')}
          />
        </div>
        <div className="col-12 col-md-6">
          <FormInputText
            placeholder="Alter Summary Address"
            label="Alter Summary Address"
            registration={register('alter_summary_address')}
            error={errors.alter_summary_address?.message}
            valid={isValid('alter_summary_address')}
          />
        </div>
        <div className="col-12 col-md-6">
          <FormInputDate
            minDate={new Date()}
            label="Alter Summary Incorporation Date"
          // paragraph="Date valide: da oggi a un anno da oggi"
            registration={register('alter_summary_incorporation_date')}
            error={errors.alter_summary_incorporation_date?.message}
            valid={isValid('alter_summary_incorporation_date')}
          />
        </div>
        <div className="col-12 col-md-6">
          <FormInputText
            placeholder="Alter Summary Employees Nr"
            label="Alter Summary Employees Nr"
            registration={register('alter_summary_employees_nr')}
            error={errors.alter_summary_employees_nr?.message}
            valid={isValid('alter_summary_employees_nr')}
          />
        </div>
        <div className="col-12 col-md-6">
          <FormInputText
            placeholder="Alter Summary Share Capital"
            label="Alter Summary Share Capital"
            registration={register('alter_summary_share_capital')}
            error={errors.alter_summary_share_capital?.message}
            valid={isValid('alter_summary_share_capital')}
          />
        </div>

        <div className="col-12 col-md-6">
          <div className="d-flex align-align-items-center justify-content-center gap-5">
            <FormInputRadio
              label="Extra Has Company Insolvency"
              options={flagResponse}
              name="summary_extra_has_company_insolvency"
              registration={register('summary_extra_has_company_insolvency')}
              error={errors.summary_extra_has_company_insolvency?.message}
            />
            <FormInputRadio
              label="Extra Has Insolvency"
              options={flagResponse}
              name="summary_extra_has_insolvency"
              registration={register('summary_extra_has_insolvency')}
              error={errors.summary_extra_has_insolvency?.message}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="d-flex align-align-items-center justify-content-center gap-5">
            <FormInputRadio
              label="Has Protesti"
              options={flagResponse}
              name="summary_extra_has_protesti"
              registration={register('summary_extra_has_protesti')}
              error={errors.summary_extra_has_protesti?.message}
            />
            <FormInputRadio
              label="Has Severe Protesti"
              options={flagResponse}
              name="summary_extra_has_severe_protesti"
              registration={register('summary_extra_has_severe_protesti')}
              error={errors.summary_extra_has_severe_protesti?.message}
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="d-flex align-align-items-center justify-content-center gap-5">
            <FormInputRadio
              label="Has Prejudicials"
              options={flagResponse}
              name="summary_extra_has_prejudicials"
              registration={register('summary_extra_has_prejudicials')}
              error={errors.summary_extra_has_prejudicials?.message}
            />
            <FormInputRadio
              label="Has Severe Prejudicials"
              options={flagResponse}
              name="summary_extra_has_severe_prejudicials"
              registration={register('summary_extra_has_severe_prejudicials')}
              error={errors.summary_extra_has_severe_prejudicials?.message}
            />
          </div>
        </div>
        <div className="col-12 col-md-6 d-flex justify-content-center align-align-items-center">
          <FormInputRadio
            label="Has Cigs Events"
            options={flagResponse}
            name="summary_extra_has_cigs_events"
            registration={register('summary_extra_has_cigs_events')}
            error={errors.summary_extra_has_cigs_events?.message}
          />
        </div>
      </div>
    </div>
  );
}

export default CommercialCompany;
