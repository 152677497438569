import axios from 'axios';
import { CHAT_SERVICE_API_URL } from '../../config';
import { axeRequestInterceptor, axeResponseInterceptor } from './axeHelpers';

export const chatAxe = axios.create();

chatAxe.defaults.validateStatus = (status) => status < 500;

chatAxe.interceptors.request.use(axeRequestInterceptor);

chatAxe.interceptors.response.use((response) => response, axeResponseInterceptor);

chatAxe.defaults.baseURL = CHAT_SERVICE_API_URL;
