import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PageLoading from '../../common/components/elements/PageLoading';
import {
  clearSuggestion, contactCustomer, generateContactSuggestion, getCustomerInfoForContact,
} from '../../features/commonCustomers/commonCustomersActions';
import { ContactForm } from './Form.styles';
import { FormInputSelect } from '../../common/formInputs/FormInputSelect';
import { FormInputText } from '../../common/formInputs/FormInputText';
import { FormInputTextarea } from '../../common/formInputs/FormInputTextarea';
import { generalAlertSuccess } from '../../common/utils/alerts';

export function ContactCustomer() {
  const validateValue = {
    shouldDirty: true,
    shouldValidate: true,
    shouldTouch: true,
  };

  const { type, customerId } = useParams();
  const {
    loading, error, customer, loadingSuggestion, suggestion,
  } = useSelector((store) => store.commonCustomers.contact_customer);

  const schema = yup.object({
    contact_type: yup.string().required('Il campo è obbligatorio'),
    contact_info: yup
      .string()
      .required('Il campo è obbligatorio')
      .max(255, 'Non deve essere piu di 255 caratteri')
      .when('contact_type', ([contact_type], schema) => (contact_type === '1'
        ? schema.email('Deve essere une email valido')
        : schema.matches(/^[3]{1}[0-9]{8,9}$/, 'Il numero non e corretto'))),
    message: yup.string().required('Il campo è obbligatorio')
      .max(500, 'Il messagio non deve essere piu di 500 caratteri'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    setValue,
    watch,
    reset,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: {
      contact_type: null,
      contact_info: '',
      message: '',
    },
  });

  const data = watch();

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  const submitData = (data) => {
    contactCustomer(type, customerId, data, () => {
      generalAlertSuccess('Messagio inviato');
      reset();
    });
  };

  const generateSuggestion = () => {
    generateContactSuggestion(data.message);
  };

  const copySuggestion = () => {
    setValue('message', suggestion, validateValue);
    clearSuggestion();
  };

  useEffect(() => {
    getCustomerInfoForContact(type, customerId);
  }, []);

  useEffect(() => {
    if (data.contact_type === '1') {
      setValue('contact_info', customer.email, validateValue);
    } else if (data.contact_type === '2') {
      setValue('contact_info', customer.phone, validateValue);
    }
  }, [data.contact_type]);

  if (loading) {
    return <PageLoading />;
  }

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  return (
    <ContactForm onSubmit={handleSubmit(submitData)}>
      <h4>
        Contatta:
        {customer.gender === 'G' ? customer.business_name : `${customer.name} ${customer.surname}`}
      </h4>
      <FormInputSelect
        registration={register('contact_type')}
        error={errors.contact_type?.message}
        valid={isValid('contact_type')}
        placeholder="- Contatta con -"
        options={[
          {
            id: '1',
            name: 'Email',
          }, {
            id: '2',
            name: 'SMS',
          },
        ]}
      />
      <FormInputText
        placeholder="Contatto"
        label="Contatto"
        registration={register('contact_info')}
        error={errors.contact_info?.message}
        valid={isValid('contact_info')}
        max={255}
      />
      <FormInputTextarea
        placeholder="Messagio"
        label="Messagio"
        registration={register('message')}
        error={errors.message?.message}
        valid={isValid('message')}
        max={500}
        rows={4}
      />

      <div className="show-suggestions" onClick={generateSuggestion}>Suggerisci un modo di scrivere più professionalmente questa comunicazione</div>

      {loadingSuggestion === true
        && <div className="suggestion">Attendere prego...</div>}
      {suggestion !== null
        && (
          <div className="suggestion">
            {suggestion}
            <br />
            <div className="copy-to-message" onClick={copySuggestion}>Copia sul messagio</div>
          </div>
        )}

      <button type="submit" className="submit-button">
        Contatta
      </button>
    </ContactForm>
  );
}
