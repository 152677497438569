import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getActiveCustomerEmailInformation } from '../../features/activeCustomer/activeCustomerActions';
import PageLoading from '../../common/components/elements/PageLoading';
import { ReadGmailStyles } from './ReadGmail.styles';
import { downloadFile } from '../../services/axios-client/axeHelpers';
import managementAxe from '../../services/axios-client/managementServisAxeClient';

function ReadGmail() {
  const { gmailId } = useParams();
  const { read_mail: { loading, email } } = useSelector((store) => store.activeCustomers);

  const downloadFileAttachment = async (attachmentId) => {
    const response = await managementAxe.get(`/broker/customers/download-email-attachment/${attachmentId}`, {
      responseType: 'blob',
    });

    if (response.status !== 200) {
      return;
    }

    downloadFile(response.data);
  };

  useEffect(() => {
    getActiveCustomerEmailInformation(gmailId);
  }, []);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <ReadGmailStyles>
      <h5 className="mb-0 h3">Informazioni Email</h5>
      <hr />
      <div className="row">
        <div className="col-6 col-md-6">
          <ul className="list-group">
            <li className="list-group-item">
              <strong>Nome Da: </strong>
              {' '}
              {email.fromName}
            </li>
            <li className="list-group-item">
              <strong>Nome To: </strong>
              {' '}
              {email.toName}
            </li>
            <li className="list-group-item">
              <strong>Data: </strong>
              {' '}
              {moment(email.date).toDate().toLocaleDateString('it-IT')}
            </li>
            <li className="list-group-item">
              <strong>Oggetto: </strong>
              {' '}
              {email.subject}
            </li>
          </ul>
        </div>
        <div className="col-6 col-md-6">
          <ul className="list-group">
            <li className="list-group-item">
              <strong>Utente: </strong>
              {' '}
              {email.gmail_user.email}
            </li>
            <li className="list-group-item">
              <strong>Cliente: </strong>
              {' '}
              {email.customer.gender === 'G' ? email.customer.business_name : `${email.customer.name} ${email.customer.surname}`}
            </li>
            <li className="list-group-item">
              <strong>Prodotto: </strong>
              {' '}
              {email.insurance_request.product.name}
            </li>
          </ul>
        </div>
      </div>
      <div className="row">
        <h5 className="mb-0 mt-5 h5">Messaggi</h5>
        <hr />
        <div className="col-12">
          {email.gmail_message_parts.length !== 0
            ? email.gmail_message_parts.map((message, index) => (
              <div key={index}>
                <p>
                  Messaggio:
                  {' '}
                  {message.content}
                </p>
                <p>
                  Allegati:
                  {' '}
                  <button className="btn btn-success btn-sm" onClick={() => downloadFileAttachment(message.attachment_id)}>{message.filename}</button>
                </p>
              </div>
            ))
            : <p>Nessun messaggio trovato!</p>}
        </div>
      </div>
    </ReadGmailStyles>
  );
}

export default ReadGmail;
