import * as yup from 'yup';

export const validationsSchema = yup.object().shape({
  location_and_structures: yup.object().shape({
    proximity_to_water_bodies: yup.string().required('Il campo è obbligatorio'),
    proximity_to_fragile_slopes_or_landslide_areas: yup.string().required('Il campo è obbligatorio'),
    elevation_above_ground_level: yup.string().required('Il campo è obbligatorio'),
    proximity_to_major_transport_routes: yup.string().required('Il campo è obbligatorio'),
    presence_of_groundwater: yup.string().required('Il campo è obbligatorio'),
    presence_of_basements_or_semi_basements: yup.string().required('Il campo è obbligatorio'),
    presence_of_shelving: yup.string().required('Il campo è obbligatorio'),
    non_seismic_roof_configuration: yup.string().required('Il campo è obbligatorio'),
    beams_anchored_to_pillars: yup.string().required('Il campo è obbligatorio'),
    presence_of_electrical_transformer_rooms: yup.string().required('Il campo è obbligatorio'),
    presence_of_thermal_power_station: yup.string().required('Il campo è obbligatorio'),
    presence_of_air_compressors: yup.string().required('Il campo è obbligatorio'),
    presence_of_refrigeration_units: yup.string().required('Il campo è obbligatorio'),
    combustible_building_materials: yup.string().required('Il campo è obbligatorio'),
    presence_of_confined_spaces: yup.string().required('Il campo è obbligatorio'),
    data_processing_equipment_in_basement: yup.string().required('Il campo è obbligatorio'),
    presence_of_classic_sandwich_panels: yup.string().required('Il campo è obbligatorio'),
    roof_supporting_structure_material: yup.string().required('Il campo è obbligatorio'),
  }),
  infrastructure_and_machines: yup.object().shape({
    sewer_level_above_public_sewers: yup.string().required('Il campo è obbligatorio'),
    electrical_system_pre_1990: yup.string().required('Il campo è obbligatorio'),
    damage_risk_to_equipment_due_to_power_outage: yup.string().required('Il campo è obbligatorio'),
    damage_risk_to_computers_due_to_power_outage: yup.string().required('Il campo è obbligatorio'),
    it_system_essential_for_continuity: yup.string().required('Il campo è obbligatorio'),
    presence_of_combustible_packaging: yup.string().required('Il campo è obbligatorio'),
    presence_of_expanded_plastic_packaging: yup.string().required('Il campo è obbligatorio'),
    use_of_flammable_gases: yup.string().required('Il campo è obbligatorio'),
    staff_present_during_closure: yup.string().required('Il campo è obbligatorio'),
    explosion_risk: yup.string().required('Il campo è obbligatorio'),
    firefighter_response_time_under_15_minutes: yup.string().required('Il campo è obbligatorio'),
    staff_present_at_night: yup.string().required('Il campo è obbligatorio'),
    valuable_goods_storage: yup.string().required('Il campo è obbligatorio'),
    use_of_forklifts_or_load_handling_systems: yup.string().required('Il campo è obbligatorio'),
    risk_of_exposure_to_carcinogens: yup.string().required('Il campo è obbligatorio'),
    use_of_chemicals: yup.string().required('Il campo è obbligatorio'),
    presence_of_underground_tanks_with_non_water_substances: yup.string().required('Il campo è obbligatorio'),
    machinery_using_oil: yup.string().required('Il campo è obbligatorio'),
    concrete_or_resin_flooring: yup.string().required('Il campo è obbligatorio'),
    use_or_storage_of_flammable_solvents: yup.string().required('Il campo è obbligatorio'),
    use_of_open_flames: yup.string().required('Il campo è obbligatorio'),
    goods_sensitive_to_humidity: yup.string().required('Il campo è obbligatorio'),
    machinery_sensitive_to_humidity: yup.string().required('Il campo è obbligatorio'),
    presence_of_structures_like_chimneys_towers_silos_tanks: yup.string().required('Il campo è obbligatorio'),
    use_of_unique_machinery_for_production: yup.string().required('Il campo è obbligatorio'),
    use_of_custom_machinery: yup.string().required('Il campo è obbligatorio'),
    machinery_no_longer_in_production: yup.string().required('Il campo è obbligatorio'),
    possibility_to_modify_process_with_alternative_machinery: yup.string().required('Il campo è obbligatorio'),
    machinery_exposed_to_corrosive_environments: yup.string().required('Il campo è obbligatorio'),
    machinery_in_dusty_environments: yup.string().required('Il campo è obbligatorio'),
    backup_for_critical_machinery: yup.string().required('Il campo è obbligatorio'),
    use_of_biting_machines: yup.string().required('Il campo è obbligatorio'),
    equipment_or_material_with_long_replacement_time: yup.string().required('Il campo è obbligatorio'),
  }),
  security_and_logistics: yup.object().shape({
    contains_easily_resellable_goods: yup.string().required('Il campo è obbligatorio'),
    contains_hard_to_identify_goods: yup.string().required('Il campo è obbligatorio'),
    frequent_access_by_non_staff: yup.string().required('Il campo è obbligatorio'),
    contains_easily_transportable_goods: yup.string().required('Il campo è obbligatorio'),
    reported_occupational_illnesses: yup.string().required('Il campo è obbligatorio'),
    third_party_access_for_installations_or_services: yup.string().required('Il campo è obbligatorio'),
    provides_installations_or_services_at_third_party_locations: yup.string().required('Il campo è obbligatorio'),
    stores_third_party_goods: yup.string().required('Il campo è obbligatorio'),
    high_value_domestic_transported_goods: yup.string().required('Il campo è obbligatorio'),
    high_value_international_transported_goods: yup.string().required('Il campo è obbligatorio'),
    ships_perishable_goods: yup.string().required('Il campo è obbligatorio'),
    subject_to_hazardous_material_transport_regulations: yup.string().required('Il campo è obbligatorio'),
    uses_contract_logistics_providers: yup.string().required('Il campo è obbligatorio'),
  }),
  product_and_its: yup.object().shape({
    company_branding_on_products: yup.string().required('Il campo è obbligatorio'),
    imports_into_eu: yup.string().required('Il campo è obbligatorio'),
    sells_in_us_canada_mexico: yup.string().required('Il campo è obbligatorio'),
    perishable_products: yup.string().required('Il campo è obbligatorio'),
    products_use_electricity: yup.string().required('Il campo è obbligatorio'),
    products_use_combustible_gases: yup.string().required('Il campo è obbligatorio'),
    provides_installation_and_maintenance: yup.string().required('Il campo è obbligatorio'),
    presence_of_special_goods: yup.string().required('Il campo è obbligatorio'),
    process_bottlenecks: yup.string().required('Il campo è obbligatorio'),
    vulnerability_to_wetting: yup.string().required('Il campo è obbligatorio'),
    easy_to_move_goods_away_from_wet: yup.string().required('Il campo è obbligatorio'),
    high_workspace_utilization: yup.string().required('Il campo è obbligatorio'),
    operates_in_large_distribution_chain: yup.string().required('Il campo è obbligatorio'),
    adequately_stocked_spare_parts_warehouse: yup.string().required('Il campo è obbligatorio'),
    critical_business_documents: yup.string().required('Il campo è obbligatorio'),
    possibility_of_increasing_work_shifts: yup.string().required('Il campo è obbligatorio'),
    disaster_recovery_and_business_continuity_system: yup.string().required('Il campo è obbligatorio'),
    remote_access_to_information_system: yup.string().required('Il campo è obbligatorio'),
    backup_communication_system: yup.string().required('Il campo è obbligatorio'),
    adopted_organizational_models_for_crisis_management: yup.string().required('Il campo è obbligatorio'),
  }),
  finance_and_personals: yup.object().shape({
    credit_system_based_on_risk: yup.string().required('Il campo è obbligatorio'),
    payment_methods_minimize_exposure: yup.string().required('Il campo è obbligatorio'),
    bank_credit_utilization_above_60_percent: yup.string().required('Il campo è obbligatorio'),
    seasonal_or_periodic_revenue_fluctuations: yup.string().required('Il campo è obbligatorio'),
    inventory_volatility_above_20_percent: yup.string().required('Il campo è obbligatorio'),
    subsidiaries_in_eu_or_switzerland: yup.string().required('Il campo è obbligatorio'),
    key_personnel_for_strategy: yup.string().required('Il campo è obbligatorio'),
    key_personnel_for_production: yup.string().required('Il campo è obbligatorio'),
    key_personnel_with_exclusive_client_relationships: yup.string().required('Il campo è obbligatorio'),
    locations_or_employees_outside_eu_norway_switzerland: yup.string().required('Il campo è obbligatorio'),
    executives_own_company_shares: yup.string().required('Il campo è obbligatorio'),
    conflict_of_interest_with_consulting_firms: yup.string().required('Il campo è obbligatorio'),
    sponsorship_contracts: yup.string().required('Il campo è obbligatorio'),
    defined_organizational_chart_and_governance: yup.string().required('Il campo è obbligatorio'),
    public_exposure_of_brand: yup.string().required('Il campo è obbligatorio'),
  }),
});
