import styled from 'styled-components';

export const LatestUpdatesPageStyles = styled.div`
    .latest-updates-time, .latest-updates-contract, .latest-updates-amount {
        font-weight: bold;
    }

    .latest-updates-amount {
        float: right;
    }

    .latest-updates-actions > * {
        padding: 0px 10px;
        cursor: pointer;
    }

    .action-button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 25px;
        width: 30px;
        cursor: pointer;
        border: 1px solid grey;
        border-radius: 3px;
    }
`;
