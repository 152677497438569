import React, { useEffect, useState, useRef } from 'react';
import { ProviderModalStyles } from './elements/ProviderModal.styles';
import useClickOutside from '../../hooks/useClickOutside';

function ProviderModal({ provider, setIsOpen }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 760);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth <= 1024 && window.innerWidth >= 769,
  );

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 760);
      setIsTablet(window.innerWidth <= 1024 && window.innerWidth >= 769);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const modalRef = useRef(null);
  useClickOutside(modalRef, () => setIsOpen(false));

  return (
    <ProviderModalStyles>
      <div
        ref={modalRef}
        className={`provider-modal-container  ${isMobile ? 'mobile' : ''} ${
          isTablet ? 'tablet' : ''
        } ${isTablet} `}
      >
        <span
          className="provider-modal-close-button btn btn-secondary"
          onClick={() => setIsOpen(false)}
        >
          Chiudere
        </span>
        <h3 className="provider-modal-header">Furnitori</h3>
        <div className="provider-modal-body">
          <span>
            <b>Nome della ditta:</b>
            {' '}
            {provider.company_name}
          </span>
          <span>
            <b>E-mail:</b>
            {' '}
            {provider.email}
          </span>
          <span>
            <b>Sede centrale:</b>
            {' '}
            {provider.headquarters}
          </span>
          <span>
            <b>Telefono:</b>
            {' '}
            {provider.phone}
          </span>
          <div className="table-wrapper-scroll-y my-custom-scrollbar">
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th className="text-center">Nome sede secondaria</th>
                  <th className="text-center">
                    Indirizzo della sede secondaria
                  </th>
                  <th className="text-center">
                    E-mail dell'ufficio secondario
                  </th>
                  <th className="text-center">
                    Telefono dell'ufficio secondario
                  </th>
                </tr>
              </thead>
              <tbody className="">
                {provider.secondary_offices.map((office, index) => (
                  <tr key={index}>
                    <td className="text-center">{office.company_name}</td>
                    <td>{office.address}</td>
                    <td>{office.email}</td>
                    <td>{office.phone}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ProviderModalStyles>
  );
}

export default ProviderModal;
