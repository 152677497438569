import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import moment from 'moment/moment';
import {
  BankTransferInfoAfterSale,
  BankTransferInfoItemAfterSale,
  ErrorMessageAfterSale, FormInputGroupAfterSale, FormInputGroupAfterSaleContainer, PayByBankTransferAfterSale, PayByCardAfterSaleContainer,
  PaymentProofStyledAfterSale,
  PayNowButtonAfterSale,
  QuoteInfoContainerAfterSale,
  WaitingForPaymentQuoteInfoAfterSale,
  WarningInfoAfterSale,
} from './PaymentStatusAfterSale.styles';
import warningIcon from '../../../../assets/redesign_icons/warning-icon.svg';
import UploaderBonifico from '../Uploader/UploaderBonifico';
import axe from '../../../../services/axios-client/axiosClient';
import PageLoading from '../../elements/PageLoading';
import { PayByCardContainer } from './WaitingForPaymentStatus.styles';
import parseJson from '../../../helpers/parseJSON';
import { confirmAlert, generalAlertError, generalAlertSuccess } from '../../../utils/alerts';
import managementAxe from '../../../../services/axios-client/managementServisAxeClient';

const schema = yup.object({
  card_number: yup
    .string()
    .required('Il campo è obbligatorio')
    .matches(/^[0-9]+$/, 'Dovrebbe contenere solo numeri')
    .min(16, 'Il campo dovrebbe contenere 16 numeri'),
  card_expiry: yup
    .string()
    .required('Il campo è obbligatorio')
    .matches(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/, 'Il formato non è valido')
    .min(5, 'Deve contenere una scadenza valida nel formato specificato')
    .max(5, 'Deve contenere una scadenza valida nel formato specificato'),
  card_cvv: yup
    .string()
    .required('Il campo è obbligatorio')
    .matches(/^[0-9]+$/, 'Dovrebbe contenere solo numeri')
    .min(3, 'Dovrebbe contenere almeno 3 numeri')
    .max(4, 'Dovrebbe contenere meno di 5 numeri'),
});

function PaymentStatusAfterSale({ afterSale, afterSalePaymentRequest, collectData }) {
  const [paymentAuthorizationUrl, setPaymentAuthorizationUrl] = useState(null);
  const [afterSaleData, setAfterSaleData] = useState(null);
  const [loading, setLoading] = useState(true);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset: resetCardForm,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });
  const user = parseJson(localStorage.getItem('user'));
  const formatInput = (value) => value.replace(/(\d{2})(\d{0,3})/, '$1/$2');

  const handleInputChange = (e) => {
    const inputValue = e.target.value.replace(/\//g, '');
    const formattedValue = formatInput(inputValue);

    e.target.value = formattedValue;

    setValue('card_expiry', formattedValue);
  };

  const payWithCompensation = () => {
    managementAxe.get(`/payments/${afterSale.id}/pay-with-compensation/${afterSalePaymentRequest.after_sale_type}`).then((res) => {
      if (res.status === 200) {
        collectData(afterSale.id);
        generalAlertSuccess('Sostituzione pagato con compensazione!');
        return;
      }
      generalAlertError(res?.data?.message);
    });
  };

  const compensationConfirmAlertHandle = async () => {
    confirmAlert('Sei sicuro di pagare con compensazione?', () => payWithCompensation());
  };

  const collectQuoteInformation = async () => {
    setLoading(true);

    try {
      const response = await axe.get(`/after-sale/${afterSale.id}/for-payment/${afterSalePaymentRequest.after_sale_type}`);
      if (response.status === 200) {
        setAfterSaleData(response.data.data);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const uploadPaymentProf = async (file) => {
    const uploadedFile = file;
    const reader = new FileReader();
    reader.readAsDataURL(uploadedFile);
    reader.onload = () => {
      const fileType = uploadedFile.type;

      managementAxe.post(`/payments/${afterSale.id}/upload-payment-proof/${afterSalePaymentRequest.after_sale_type}`, {
        document: {
          content: reader.result.replace(`data:${fileType};base64,`, ''),
          file_type: fileType,
        },
      }).then(() => {
        collectData(afterSale.id);
        generalAlertSuccess('Stiamo verificando la prova del pagamento, attendi nostro feedback!');
      });
    };
  };

  const submitData = async (data) => {
    setLoading(true);

    try {
      const response = await axe.post(`/payments/after-sale/${afterSale.id}/pay/${afterSalePaymentRequest.after_sale_type}`, {
        card: {
          number: data.card_number,
          exp_month: data.card_expiry.split('/')[0],
          exp_year: data.card_expiry.split('/')[1],
          cvv: data.card_cvv,
        },
      });

      if (response.status === 400) {
        setLoading(false);
      } else if (response.status === 500) {
        setLoading(false);
      } else if (response.status === 200) {
        setPaymentAuthorizationUrl(response.data.data.user_redirect);
        resetCardForm();
        collectQuoteInformation();
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    collectQuoteInformation();
  }, [afterSale.id]);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <>
      <div className="row">
        <div className="col-12 mt-2 mt-xxl-0 payment-information mb-3">
          <WaitingForPaymentQuoteInfoAfterSale>
            <QuoteInfoContainerAfterSale>
              <div>
                <h3 className="ps-3 mb-4 mt-0">
                  Detagli
                  {' '}
                  <b>Pagamento</b>
                </h3>
              </div>

              <div className="ps-3 mb-4 mt-0">
                <p className="my-1">
                  <strong> Premio:</strong>
                  {' '}
                  <span>
                    {parseFloat(afterSalePaymentRequest.amount)}
                    {' '}
                    &euro;
                  </span>
                </p>
                <p className="my-1">
                  <strong>Stato Pagamento:</strong>
                  {' '}
                  <span>{afterSalePaymentRequest.is_paid === 1 ? 'Pagato' : afterSalePaymentRequest.is_paid === 0 ? 'Pagamento Non valido' : 'In Attesa Pagamento'}</span>
                </p>
                <p className="my-1">
                  <strong>Data Richiesta:</strong>
                  {' '}
                  <span>{moment(afterSalePaymentRequest.created_at).format('DD-MM-YYYY')}</span>
                </p>
                <p className="my-1">
                  <strong>Informazione Pagamento:</strong>
                  {' '}
                  <span>{afterSalePaymentRequest.payment_description}</span>
                </p>
              </div>

            </QuoteInfoContainerAfterSale>
          </WaitingForPaymentQuoteInfoAfterSale>
        </div>
      </div>

      {afterSaleData.after_sale_payments.length !== 0
        && (
          <div className="row mt-3 mb-3">
            <div className="col-12 col-xxl-12">
              <PayByCardContainer>
                {afterSaleData.after_sale_payments.length !== 0 && (
                  <div>
                    <h3>Stato del pagamento</h3>
                    {afterSaleData.after_sale_payments.map((payment) => (
                      <div className="payment-record" key={payment.id}>
                        <div className="field-row">
                          <div className="field-name">
                            <b>Carta</b>
                          </div>
                          <div className="field-value">
                            ************
                            {payment.last_four}
                          </div>
                        </div>
                        <div className="field-row">
                          <div className="field-name">
                            <b>Stato</b>
                          </div>
                          <div className="field-value">
                            {payment.status_name}
                          </div>
                        </div>
                        <div className="field-row">
                          <div className="field-name">
                            <b>Pagato</b>
                          </div>
                          <div className="field-value">
                            {payment.amount.toLocaleString()}
                            {' '}
                            &euro;
                          </div>
                        </div>
                        <div className="field-row">
                          <div className="field-name">
                            <b>Ora</b>
                          </div>
                          <div className="field-value">
                            {moment(payment.created_at).format(
                              'DD.MM.YYYY hh:mm',
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </PayByCardContainer>
            </div>
          </div>
        )}

      <div className="row mb-3">
        <div className="col-12 col-xxl-6 card-payment-method">
          <PayByCardAfterSaleContainer>
            <h3>
              Paga con
              {' '}
              <b>carta</b>
              {' '}
              {parseFloat(afterSalePaymentRequest.amount)}
              &euro;
            </h3>
            <form onSubmit={handleSubmit(submitData)}>
              <FormInputGroupAfterSale>
                <label htmlFor="">Carta di credito o di debito</label>
                <input
                  type="text"
                  {...register('card_number')}
                  placeholder="Inserisci il numero della carta"
                />
                <ErrorMessageAfterSale>
                  {errors.card_number?.message}
                </ErrorMessageAfterSale>
              </FormInputGroupAfterSale>
              <FormInputGroupAfterSaleContainer>
                <FormInputGroupAfterSale>
                  <label htmlFor="">Data di scadenza</label>
                  <Controller
                    name="card_expiry"
                    control={control}
                    render={({ field }) => (
                      <input
                        type="text"
                        {...field}
                        placeholder="MM/AA"
                        maxLength="5"
                        onChange={handleInputChange}
                      />
                    )}
                  />
                  <ErrorMessageAfterSale>
                    {errors.card_expiry?.message}
                  </ErrorMessageAfterSale>
                </FormInputGroupAfterSale>
                <FormInputGroupAfterSale>
                  <label htmlFor="">Codice di sicurezza (CVV)</label>
                  <input
                    type="text"
                    {...register('card_cvv')}
                    placeholder="CVV"
                  />
                  <ErrorMessageAfterSale>
                    {errors.card_cvv?.message}
                  </ErrorMessageAfterSale>
                </FormInputGroupAfterSale>
              </FormInputGroupAfterSaleContainer>
              <WarningInfoAfterSale>
                <img src={warningIcon} alt="" />
                <p>
                  Se si fa clic su Invia verrà visualizzata una modalità di conferma, dopo la conferma attendere un paio di minuti per l'elaborazione. Grazie!
                </p>
              </WarningInfoAfterSale>
              <PayNowButtonAfterSale>
                Procedi con il pagamento
              </PayNowButtonAfterSale>
            </form>
          </PayByCardAfterSaleContainer>
        </div>
        <div className="col-12 col-xxl-6 mt-2 mt-xxl-0 bonifico-payment-method">
          <PayByBankTransferAfterSale>
            <h3>
              Paga con
              {' '}
              <b>bonifico</b>
              {' '}
              {parseFloat(afterSalePaymentRequest.amount)}
              &euro;
            </h3>
            {afterSale.after_sale_payment_proof !== null && (
              <PaymentProofStyledAfterSale key={afterSale.after_sale_payment_proof.id}>
                <h6>
                  Pagamento
                  {' '}
                  1
                  {' '}
                  : Pagato:
                  {' '}
                  {afterSale.after_sale_payment_proof.amount}
                  {' '}
                  &euro;
                </h6>
                <>
                  {afterSale.after_sale_payment_proof.payment_proof_valid === 0 && (
                    <div className="alert alert-danger">
                      La prova del pagamento non e valida
                    </div>
                  )}
                  {afterSale.after_sale_payment_proof.payment_proof_valid === 1 && (
                    <div className="alert alert-success">
                      La prova del pagamento e valida
                    </div>
                  )}

                  {afterSale.after_sale_payment_proof.payment_proof_file !== null
                    && afterSale.after_sale_payment_proof.payment_proof_valid === null && (
                      <div className="alert alert-warning">
                        Stiamo verificando la prova del pagemento
                      </div>
                  )}
                  <embed
                    type={afterSale.after_sale_payment_proof.file_type}
                    style={{ marginTop: '10px', width: '100%', minHeight: '200px' }}
                    src={`data:${afterSale.after_sale_payment_proof.file_type};base64,${afterSale.after_sale_payment_proof.payment_proof_file}`}
                  />
                </>
              </PaymentProofStyledAfterSale>
            )}

            <BankTransferInfoAfterSale>
              <BankTransferInfoItemAfterSale>
                Iban:
                {' '}
                <span>IT60B0326803210052864099270</span>
              </BankTransferInfoItemAfterSale>
              <BankTransferInfoItemAfterSale>
                Titolare:
                {' '}
                <span>Greenia srl</span>
              </BankTransferInfoItemAfterSale>
              <BankTransferInfoItemAfterSale>
                Causale:
                {' '}
                <span>
                  Pagamento Sostituzione
                  {' '}
                  {afterSale.quotation.insurance_request.customer.gender !== 'G' ? `${afterSale.quotation.insurance_request.customer.name} ${afterSale.quotation.insurance_request.customer.surname}` : afterSale.quotation.insurance_request.customer.business_name}
                </span>
              </BankTransferInfoItemAfterSale>
            </BankTransferInfoAfterSale>
            <UploaderBonifico note="CARICA PROVA DEL PAGAMENTO" onFileUpload={(file) => uploadPaymentProf(file)} />
            {user.compensations === 1 && (
              <div className="row justify-content-center footer-button">
                <button className="btn btn-outline-success" onClick={() => compensationConfirmAlertHandle()}>
                  Paga con compensazione
                </button>
              </div>
            )}
          </PayByBankTransferAfterSale>
        </div>
      </div>
      {paymentAuthorizationUrl && (
        <div className="x-modal" id="fastquote-modal">
          <div
            className="x-modal-cover"
          />
          <div className="general-modal-content">
            <span
              className="general-modal-close"
              title="Quidi"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z" />
              </svg>
            </span>
            <div className="modal-header">
              <h5 className="modal-title">Autorizza il pagamento</h5>
            </div>
            <div className="x-modal-body">
              <iframe
                title="Authorization frame"
                style={{ width: '100%', height: '70vh' }}
                src={paymentAuthorizationUrl}
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary m-2"
                onClick={() => setPaymentAuthorizationUrl(null)}
              >
                Chiudi
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PaymentStatusAfterSale;
