import React, { useEffect, useRef, useImperativeHandle } from 'react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { genders, vehicleTypes } from '../../constants';
import getMaxAllowedBirthday from '../../helpers/getMaxAllowedBirthday';
import { FormInputSelect } from '../../formInputs/FormInputSelect';
import { FormInputText } from '../../formInputs/FormInputText';
import { FormInputRadio } from '../../formInputs/FormInputRadio';
import { FormInputDate } from '../../formInputs/FormInputDate';
import { FormInputSelectWithSearch } from '../../formInputs/FormInputSelectWithSearch';

function BersaniInfo({
  bersani, updateBersaniData, municipalities, inheritMeritClass,
}, ref) {
  const inheritMeritClassRef = useRef(inheritMeritClass);

  const lazyFunction = () => {
    if (inheritMeritClassRef.current !== 'S') {
      return yup.string().required('Il campo è obbligatorio.');
    }

    return yup.string().nullable();
  };

  const schema = yup.object({
    name: yup.lazy(lazyFunction),
    surname: yup.lazy(lazyFunction),
    gender: yup.lazy(lazyFunction),
    date_of_birth: yup.lazy(lazyFunction),
    commune_of_birth_code: yup.lazy(lazyFunction),
    vehicle_type: yup.string().required('Il campo è obbligatorio.'),
    vehicle_plate: yup
      .string()
      .required('Il campo è obbligatorio.')
      .when('vehicle_type', ([vehicle_type], schema) => ((vehicle_type !== 'motorcycle')
        ? schema.matches(/^[A-Z]{2}[0-9]{3}[A-Z]{2}$/, 'La targa non e coretta')
        : schema.matches(/^[A-Z]{2}[0-9]{5}$/, 'La targa non e coretta'))),
  });

  const {
    register,
    formState: { errors, touchedFields, isValid: formIsValid },
    setValue,
    trigger,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: bersani,
  });

  useImperativeHandle(ref, () => ({
    triggerErrors() {
      trigger(undefined, {
        shouldFocus: true,
      });
    },
  }));

  const bersaniData = watch();

  useEffect(() => {
    if (JSON.stringify(bersani) !== JSON.stringify(bersaniData)) {
      updateBersaniData({
        ...bersaniData,
      });
    }
  }, [bersaniData]);

  const updateFormData = (data) => {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      setValue(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    });
    trigger(keys);
  };

  useEffect(() => {
    updateFormData({
      isValid: formIsValid,
    });
    updateBersaniData({
      isValid: formIsValid,
    });
  }, [formIsValid]);

  useEffect(() => {
    if (bersaniData.vehicle_type !== '') {
      trigger('vehicle_plate');
    }
  }, [bersaniData.vehicle_type]);

  useEffect(() => {
    inheritMeritClassRef.current = inheritMeritClass;
  }, [inheritMeritClass]);

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  if (inheritMeritClass === 'S') {
    return (
      <form>
        <FormInputSelect
          placeholder="Tipo di veicolo"
          label="Tipo di veicolo"
          registration={register('vehicle_type')}
          error={errors.vehicle_type?.message}
          valid={isValid('vehicle_type')}
          options={vehicleTypes}
        />

        <FormInputText
          id="inherited_vehicle_plate"
          label="Targa dell'altro veicolo di cui utilizzi la classe di merito"
          onChange={(value) => updateFormData({ vehicle_plate: value.toUpperCase() })}
          value={bersaniData.vehicle_plate}
          error={errors.vehicle_plate?.message}
          valid={isValid('vehicle_plate')}
        />
      </form>
    );
  }

  return (
    <form>
      <FormInputText
        id="name"
        name="name"
        registration={register('name')}
        label="Nome del famigliare"
        error={errors.name?.message}
        valid={isValid('name')}
      />
      <FormInputText
        registration={register('surname')}
        label="Cognome del famigliare"
        error={errors.surname?.message}
        valid={isValid('surname')}
      />
      <FormInputRadio
        label="Genere del famigliare"
        options={genders.filter((gender) => gender.value !== 'G')}
        name="gender"
        registration={register('gender')}
        error={errors.gender?.message}
        valid={isValid('gender')}
      />
      <FormInputDate
        label="Data di nascita del famigliare"
        registration={register('date_of_birth')}
        error={errors.date_of_birth?.message}
        valid={isValid('date_of_birth')}
        minDate="1935-01-01"
        maxDate={getMaxAllowedBirthday()}
      />
      <FormInputSelectWithSearch
        value={{ value: bersaniData.commune_of_birth_code }}
        placeholder="Comune del famigliare"
        label="Residenza del famigliare"
        onChange={(item) => updateFormData({
          commune_of_birth_code: item.cadastral_code,
        })}
        error={errors.commune_of_birth_code?.message}
        valid={isValid('commune_of_birth_code')}
        options={municipalities}
        returnType="object"
        countryCode="cadastral_code"
      />
      <FormInputSelect
        placeholder="Tipo di veicolo"
        label="Tipo di veicolo"
        registration={register('vehicle_type')}
        error={errors.vehicle_type?.message}
        valid={isValid('vehicle_type')}
        options={vehicleTypes}
      />

      <FormInputText
        label="Targa dell'altro veicolo di cui utilizzi la classe di merito"
        onChange={(value) => updateFormData({ vehicle_plate: value.toUpperCase() })}
        value={bersaniData.vehicle_plate}
        error={errors.vehicle_plate?.message}
        valid={isValid('vehicle_plate')}
      />
    </form>
  );
}

export default React.forwardRef(BersaniInfo);
