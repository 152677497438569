import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import DeleteIcon from '../../assets/images/icons/DeleteIcon';
import AddIcon from '../../assets/images/icons/AddIcon';
import EditIcon from '../../assets/images/icons/EditIcon';

export default function TableButton({
  text,
  title,
  icon,
  className,
  route,
  style,
  action,
  id,
}) {
  const navigate = useNavigate();
  const navigateToPage = () => {
    navigate(`/${route}`);
  };
  let component = '';
  switch (icon) {
    case 'delete':
      component = <DeleteIcon height={15} width={15} id={id} />;
      break;
    case 'add':
      component = <AddIcon height={15} width={15} id={id} />;
      break;
    case 'edit':
      component = <EditIcon height={15} width={15} id={id} />;
      break;
    case 'show':
      component = <FontAwesomeIcon icon={faEye} color="white" />;
      break;
    default:
      component = <></>;
  }
  return (
    <button
      className={`btn btn-sm rounded-1 btn-${className} ${style} m-2`}
      id={id}
      onClick={action === undefined ? navigateToPage : action}
      title={title}
    >
      {component}
      {' '}
      {text !== undefined ? text : ''}
    </button>
  );
}
