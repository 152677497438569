import { createSlice } from '@reduxjs/toolkit';
import { callerCreator } from '../callerCreator';

const initialState = {
  network_analisys: {
    network_analisys: [],
    error: null,
    loading: false,
  },
  company_collaborations: {
    company_collaborations: [],
    error: null,
    loading: false,
  },
  comparative_analisys: {
    comparative_analisys: [],
    error: null,
    loading: false,
  },
};

export const networkAnalisysSlice = createSlice({
  name: 'analisys',
  initialState,
  reducers: {
    getNetworkAnalisys: (state, { payload }) => {
      state.network_analisys = { ...state.network_analisys, ...payload };
    },
    resetNetworkAnalisys: (state) => {
      state.network_analisys = { ...initialState.network_analisys };
    },
    getCollaboratorsAnalisys: (state, { payload }) => {
      state.company_collaborations = {
        ...initialState.company_collaborations,
        ...payload,
      };
    },
    resetCollaboratorsAnalisys: (state) => {
      state.company_collaborations = { ...initialState.company_collaborations };
    },
    updateComparativeAnalisys: (state, { payload }) => {
      state.comparative_analisys = {
        ...state.comparative_analisys,
        ...payload,
      };
    },
    resetComparativeAnalisys: (state) => {
      state.comparative_analisys = { ...initialState.comparative_analisys };
    },
  },
});

export default callerCreator(networkAnalisysSlice.actions);
