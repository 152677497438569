import { CardItemStyles } from './CardItem.styles';

export default function CardItem({ title, action, className = '' }) {
  return (
    <CardItemStyles>
      <div className={`card-container ${className}`}>
        <b>{title}</b>
        <button className="btn btn-questionnaire" onClick={action}>
          Scegli
        </button>
      </div>
    </CardItemStyles>
  );
}
