import styled from 'styled-components';

export const MessageStyles = styled.div`
    .btn-to-quote {
        background-color: #00A651;
        cursor: pointer;
        color: #ffffff;
        transition: all 300ms ease-in-out;
        border: 1px solid #00A651;
    }

    .btn-to-quote a {
        color: #ffffff;
    }

    .btn-to-quote:hover a {
        color: #00A651;
    }

    .btn-to-quote:hover {
        background-color: transparent;
        color: #00A651;
        border: 1px solid #00A651;
    }

    .messages-list {
        max-height: 100%;
        height: 100%;
    }

    .search-input.form-control:focus,
    .search-plate.form-control:focus {
        box-shadow: 0 0 0 0.25rem rgb(0 166 81 / 25%) !important;
        border-color: rgb(0 166 81 / 50%) !important;
    }

    .search-btn {
        display: table;
        margin: 0 auto;
        width: 50%;
        border: 2px solid #00A651;
        background-color: #00A651;
        cursor: pointer;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        padding: 6px 16px;
    }
`;

export const Tabs = styled.div`
    display: flex;
    margin: 10px 0;
    border-bottom: 1px solid var(--greenia-color);
`;

export const Tab = styled.div`
    margin: 0 3px;
    padding: 10px 20px;
    min-width: 150px;
    text-align: center;
    border: 1px solid var(--greenia-color);
    border-bottom: none;
    color: ${(props) => (props.active ? 'white' : 'var(--greenia-color)')};
    background-color: ${(props) => (props.active ? 'var(--greenia-color)' : 'white')};
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor: pointer;
    transition: .3s;

    &:hover {
        background-color: var(--greenia-color);
        color: white;
    }
`;
