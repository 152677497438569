import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageLoading from '../../../common/components/elements/PageLoading';
import { BACKEND_BASE_URL } from '../../../config';
import { RcQuotesStyles } from './BackOfficeVehicleQuotes.styles';
import {
  addQuoteToCartAutoBackOffice,
  loadAutoQuotes, removeQuoteAutoFromCart, resetAutoQuotes, updateAutoQuotesData,
} from '../../../features/backofficeResults/backofficeResultsActions';
import { Layout } from '../components/Layout/Layout';
import { FailedQuoteTitle, FailedQuotesContainer } from '../../RcQuotes.styles';
import GuaranteesModal from '../components/common/Modal/GuaranteesModal';
import VehicleInfoCard from '../../../common/components/redesignComponents/Cards/VehicleInfoCard';
import { EstimateContainer } from '../../../common/components/redesignComponents/common/Redesign.styles';
import { coveragesUtil } from '../../../utils/coverages';
import { primaQuoteTypes, quotationTypes } from '../../../common/constants';
import { GuaranteesDefaultStateStyles } from '../../../common/components/redesignComponents/common/GuaranteesDefaultState.styles';
import { confirmAlert } from '../../../common/utils/alerts';
import {
  RiskCard, RiskContainer,
} from '../../../common/components/redesignComponents/Cards/ProfessionInfoCard.styles';
import { updateSortByAuto } from '../../../features/insuranceRequests/insuranceRequestsActions';
import ModalQuotesBackoffice from '../../../common/components/redesignComponents/ResultPageComponents/ModalQuotesBackoffice';
import SelectedQuotesCardsBackoffice from '../../../common/components/redesignComponents/ResultPageComponents/SelectedQuotesCardsBackoffice';
import ButtonComponentLogicBackoffice from '../../../common/components/redesignComponents/ResultPageComponents/ButtonComponentLogicBackoffice';
import { riskCardPrimaStyles, riskCardStyles } from '../../../common/components/redesignComponents/ResultPageComponents/RcQuotesModal.style';
import ModalQuotesPrimaBackoffice from '../../../common/components/redesignComponents/ResultPageComponents/ModalQuotesPrimaBackoffice';
import ButtonComponentLogicPrimaBackoffice from '../../../common/components/redesignComponents/ResultPageComponents/ButtonComponentLogicPrimaBackoffice';
import SelectedQuotesCardsPrimaBackoffice from '../../../common/components/redesignComponents/ResultPageComponents/SelectedQuotesCardsPrimaBackoffice';

function BackOfficeVehicleQuotes() {
  const navigate = useNavigate();
  const { requestToken } = useParams();
  const clearQuotesIntervalRef = useRef(null);
  const requestInfoRef = useRef(null);
  const [showGuaranteesModal, setShowGuaranteesModal] = useState(false);
  const [intervalQuotes, setIntervalQuotes] = useState(null);
  const state = useSelector((store) => store.backofficeResults.auto_quotes);
  const {
    loading, requestInfo, quotes, failedQuotes,
    processingQuote, tutelaLegaleQuote, quotations, addingQuote, assistenzaStradaleQuote, infortuniConducenteQuote, cristalliQuote, rivalsaQuote, sortBy, preventivas_quotes,
  } = state;
  const [counter, setCounter] = useState(180);
  const [currentQuotes, setCurrentQuotes] = useState('diretta');

  const handleCurrentQuotesDisplay = (val) => {
    setCurrentQuotes(val);
  };

  const guaranteesPrima = (quote) => {
    const guarantees = quote.quotation_data_prima_guarantees.map((guarantee) => guarantee.slug).filter((g) => g != 'rca' && g != 'furto_incendio');
    if (quote.quotation_data_prima_guarantees.find((qg) => qg.slug == 'furto_incendio') != undefined) {
      guarantees.push('incendio');
      guarantees.push('furto');
    }
    return guarantees;
  };

  const modifyData = () => {
    if (requestInfo.product_id == 1) {
      navigate(`/backoffice/quote/${requestToken}/auto`);
    } else {
      navigate(`/backoffice/quote/${requestToken}/autocarro`);
    }
  };

  useEffect(() => {
    loadAutoQuotes(requestToken);

    return () => {
      resetAutoQuotes();
    };
  }, []);

  const clearQuotesInterval = () => {
    if (intervalQuotes !== null) {
      clearInterval(intervalQuotes);
      setIntervalQuotes(null);
    }
  };

  clearQuotesIntervalRef.current = clearQuotesInterval;
  requestInfoRef.current = requestInfo;

  const sortQuotes = (sortBy) => {
    updateSortByAuto(sortBy);
  };

  const refresh = () => loadAutoQuotes(requestToken);

  useEffect(() => {
    const iq = setInterval(() => {
      const requestInfo = requestInfoRef.current;
      if (requestInfo !== null && requestInfo.product_id == 1) {
        if (requestInfo.with_preventivas === null) {
          loadAutoQuotes(requestToken);
        }

        if (requestInfo.with_preventivas !== null) {
          clearQuotesIntervalRef.current();
        }
      }
    }, 10000);

    setIntervalQuotes(iq);

    return () => {
      clearQuotesIntervalRef.current();
    };
  }, []);

  useEffect(() => {
    if (requestInfo !== null && requestInfo.quotations) {
      updateAutoQuotesData(requestInfo);
    }
  }, [requestInfo]);

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  if (loading) {
    return <PageLoading />;
  }

  const coverages = coveragesUtil.calculateCommonCoverages(requestInfo?.product_id ?? 1, quotes ?? []);

  const fetchQuotes = () => {
    loadAutoQuotes(requestToken);
  };

  const selectQuotation = (quoteId) => {
    confirmAlert('Sei Sicuro di aggiungere questa quotazione?', () => addQuoteToCartAutoBackOffice(quoteId, fetchQuotes));
  };

  const removeItemFromCart = (quoteId) => {
    confirmAlert('Sei Sicuro di rimuovere questa quotazione?', () => removeQuoteAutoFromCart(quoteId, fetchQuotes));
  };

  const getTotal = () => {
    let quoteInProccessAmount = 0;
    let tutelaInProccessAmount = 0;
    let assistenzaInProccessAmount = 0;
    let rivalsaInProccessAmount = 0;
    let cristalliInProccessAmount = 0;
    let infortuniConducenteInProccessAmount = 0;
    let primaGuaranteesPrices = 0;

    if (processingQuote !== null) {
      if (processingQuote.company_id === 2) {
        quoteInProccessAmount = parseFloat(processingQuote.quotation_data_prima.quotation_data_prima_guarantees.find((price) => price.slug === 'rca').quotation_data_prima_guarantee_prices[0].discounted_to_display);
      } else {
        quoteInProccessAmount = parseFloat(processingQuote.amount);
      }
    }

    if (processingQuote !== null) {
      quoteInProccessAmount = parseFloat(processingQuote.amount);
    }
    if (tutelaLegaleQuote !== null) {
      tutelaInProccessAmount = parseFloat(tutelaLegaleQuote.amount);
    }

    if (assistenzaStradaleQuote !== null) {
      assistenzaInProccessAmount = parseFloat(assistenzaStradaleQuote.amount);
    }

    if (rivalsaQuote !== null) {
      rivalsaInProccessAmount = parseFloat(rivalsaQuote.amount);
    }

    if (cristalliQuote !== null) {
      cristalliInProccessAmount = parseFloat(cristalliQuote.amount);
    }

    if (infortuniConducenteQuote !== null) {
      infortuniConducenteInProccessAmount = parseFloat(infortuniConducenteQuote.amount);
    }

    if (processingQuote.company_id === 2) {
      processingQuote.guarantees.forEach((guarantee) => {
        primaGuaranteesPrices += parseFloat(guarantee.amount) || 0;
      });
    }

    return (quoteInProccessAmount + tutelaInProccessAmount + assistenzaInProccessAmount + rivalsaInProccessAmount + cristalliInProccessAmount + infortuniConducenteInProccessAmount + primaGuaranteesPrices).toFixed(2);
  };

  // const handleRequestSave = () => {
  //   if (processingQuote === null) {
  //     generalAlertError('Seleziona Prima La Responsabilita Civile', 'Errore');
  //     return;
  //   }

  //   if (requestInfo.product_id == 1) {
  //     navigate(`/quotes/auto/purchase/${requestToken}`);
  //   } else if (requestInfo.product_id == 3) {
  //     navigate(`/quotes/autocarro/purchase/${requestToken}`);
  //   }
  // };

  return (
    <Layout>
      {showGuaranteesModal && <GuaranteesModal setShowGuaranteesModal={setShowGuaranteesModal} coverages={coverages} requestToken={requestToken} />}

      <RcQuotesStyles>
        <div className="resultsPage">
          <div className="row">
            <div className="col-12 col-xxl-4 mb-2 mb-md-2 mb-xxl-0">
              <VehicleInfoCard insuranceRequest={requestInfo} modificaDati={modifyData} />
            </div>
            <div className="col-12 col-xxl-8">
              <GuaranteesDefaultStateStyles>
                <div className="container mt-4">
                  {processingQuote === null && tutelaLegaleQuote === null
                    ? <h5 className="fw-bold">Seleziona Le Quotazioni Che Vuoi Aquistare</h5>
                    : (
                      <>
                        <ul className="list-unstyled">
                          {processingQuote !== null && (
                            <li>
                              <div className="d-flex justify-content-between align-items-center">
                                <h5>
                                  {processingQuote.company !== null ? processingQuote.company.name : processingQuote.preventivas_company.company_name}
                                  {' '}
                                  <sub>
                                    (
                                    {quotationTypes.find((item) => item.id === processingQuote.quote_type).name}
                                    )
                                  </sub>
                                </h5>
                                <h5 className="fw-bold">
                                  {processingQuote.company_id === 2 ? parseFloat(processingQuote.quotation_data_prima.quotation_data_prima_guarantees.find((price) => price.slug === 'rca').quotation_data_prima_guarantee_prices[0].discounted_to_display) : parseFloat(processingQuote.amount).toFixed(2)}
                                  {' '}
                                  €
                                </h5>
                              </div>
                            </li>
                          )}
                          {tutelaLegaleQuote !== null && (
                            <li>
                              <div className="d-flex justify-content-between align-items-center">
                                <h5>
                                  {tutelaLegaleQuote.company !== null ? tutelaLegaleQuote.company.name : tutelaLegaleQuote.preventivas_company.company_name}
                                  {' '}
                                  <sub>
                                    (
                                    {quotationTypes.find((item) => item.id === tutelaLegaleQuote.quote_type).name}
                                    )
                                  </sub>
                                </h5>
                                <h5 className="fw-bold">
                                  {parseFloat(tutelaLegaleQuote.amount)}
                                  {' '}
                                  €
                                </h5>
                              </div>
                            </li>
                          )}
                          {assistenzaStradaleQuote !== null && (
                            <li>
                              <div className="d-flex justify-content-between align-items-center">
                                <h5>
                                  {assistenzaStradaleQuote.company !== null ? assistenzaStradaleQuote.company.name : assistenzaStradaleQuote.preventivas_company.company_name}
                                  {' '}
                                  <sub>
                                    (
                                    {quotationTypes.find((item) => item.id === assistenzaStradaleQuote.quote_type).name}
                                    )
                                  </sub>
                                </h5>
                                <h5 className="fw-bold">
                                  {parseFloat(assistenzaStradaleQuote.amount)}
                                  {' '}
                                  €
                                </h5>
                              </div>
                            </li>
                          )}
                          {infortuniConducenteQuote !== null && (
                            <li>
                              <div className="d-flex justify-content-between align-items-center">
                                <h5>
                                  {infortuniConducenteQuote.company !== null ? infortuniConducenteQuote.company.name : infortuniConducenteQuote.preventivas_company.company_name}
                                  {' '}
                                  <sub>
                                    (
                                    {quotationTypes.find((item) => item.id === infortuniConducenteQuote.quote_type).name}
                                    )
                                  </sub>
                                </h5>
                                <h5 className="fw-bold">
                                  {parseFloat(infortuniConducenteQuote.amount)}
                                  {' '}
                                  €
                                </h5>
                              </div>
                            </li>
                          )}
                          {rivalsaQuote !== null && (
                            <li>
                              <div className="d-flex justify-content-between align-items-center">
                                <h5>
                                  {rivalsaQuote.company !== null ? rivalsaQuote.company.name : rivalsaQuote.preventivas_company.company_name}
                                  {' '}
                                  <sub>
                                    (
                                    {quotationTypes.find((item) => item.id === rivalsaQuote.quote_type).name}
                                    )
                                  </sub>
                                </h5>
                                <h5 className="fw-bold">
                                  {parseFloat(rivalsaQuote.amount)}
                                  {' '}
                                  €
                                </h5>
                              </div>
                            </li>
                          )}
                          {cristalliQuote !== null && (
                            <li>
                              <div className="d-flex justify-content-between align-items-center">
                                <h5>
                                  {cristalliQuote.company !== null ? cristalliQuote.company.name : cristalliQuote.preventivas_company.company_name}
                                  {' '}
                                  <sub>
                                    (
                                    {quotationTypes.find((item) => item.id === cristalliQuote.quote_type).name}
                                    )
                                  </sub>
                                </h5>
                                <h5 className="fw-bold">
                                  {parseFloat(cristalliQuote.amount)}
                                  {' '}
                                  €
                                </h5>
                              </div>
                            </li>
                          )}
                          {(processingQuote !== null && processingQuote.company_id === 2) && (
                            processingQuote.guarantees.map((guarantee, index) => (
                              <li key={index}>
                                <div className="d-flex justify-content-between align-items-center">
                                  <h5>
                                    Prima.it
                                    <sub>
                                      {' '}
                                      (
                                      {primaQuoteTypes.find((type) => type.id === guarantee.guarantees_slug).name}
                                      )
                                    </sub>
                                  </h5>
                                  <h5 className="fw-bold">
                                    {parseFloat(guarantee.amount)}
                                    {' '}
                                    €
                                  </h5>
                                </div>
                              </li>
                            ))
                          )}
                          <hr />
                          <li>
                            <div className="d-flex justify-content-end align-items-center">
                              <h5 className="me-5 fw-bold">
                                Totale:
                              </h5>
                              <h5 className="fw-bold">
                                {' '}
                                {getTotal()}
                                {' '}
                                €
                              </h5>
                            </div>
                          </li>
                        </ul>
                        {/* <div className="d-flex justify-content-end mt-3">
                          <button className="btn btn-success rounded-4 px-5 py-2" onClick={() => handleRequestSave()}>
                            Aquista
                          </button>
                        </div> */}
                      </>
                    )}
                </div>
              </GuaranteesDefaultStateStyles>
              {/* <GuaranteesDefaultState setShowGuaranteesModal={setShowGuaranteesModal} quotes={quotes} /> */}
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <EstimateContainer>
                <h4>
                  Preventivi assicurazione
                  {' '}
                  <b>
                    {requestInfo.product_id == '1' && 'auto'}
                    {' '}
                    {requestInfo.product_id == '3' && 'autocarro'}
                  </b>
                </h4>
                <p>
                  Inizio Copertura:
                  {' '}
                  {requestInfo.vehicle !== null && new Date(requestInfo.policy_effective_date).toLocaleDateString('it-IT', { year: 'numeric', month: 'numeric', day: 'numeric' })}
                  <br />
                </p>
              </EstimateContainer>
            </div>
          </div>

          <RiskContainer className="mb-4 result-profession-fourth-step">
            {processingQuote !== null && processingQuote.company_id === 2 ? [{ id: 'res_civile', name: 'Responsabilita Civile' }].map((type) => (
              <>
                {quotations && quotations[type.id] && (
                  <RiskCard className={riskCardStyles(requestInfo, type)}>
                    <div className="p-3 me-auto">
                      <h5>{type.name}</h5>
                    </div>
                    <SelectedQuotesCardsBackoffice
                      processingQuote={processingQuote}
                      type={type}
                      refresh={refresh}
                      tutelaLegaleQuote={tutelaLegaleQuote}
                      cristalliQuote={cristalliQuote}
                      rivalsaQuote={rivalsaQuote}
                      assistenzaStradaleQuote={assistenzaStradaleQuote}
                      infortuniConducenteQuote={infortuniConducenteQuote}
                      requestInfo={requestInfo}
                    />

                    <ButtonComponentLogicBackoffice
                      type={type}
                      processingQuote={processingQuote}
                      infortuniConducenteQuote={infortuniConducenteQuote}
                      tutelaLegaleQuote={tutelaLegaleQuote}
                      rivalsaQuote={rivalsaQuote}
                      cristalliQuote={cristalliQuote}
                      assistenzaStradaleQuote={assistenzaStradaleQuote}
                      removeItemFromCart={removeItemFromCart}
                      requestInfo={requestInfo}
                    />
                  </RiskCard>
                )}
              </>
            )) : quotationTypes.map((type) => (
              <>
                {quotations && quotations[type.id] && (
                  <RiskCard className={riskCardStyles(requestInfo, type)}>
                    <div className="p-3 me-auto">
                      <h5>{type.name}</h5>
                    </div>
                    <SelectedQuotesCardsBackoffice
                      processingQuote={processingQuote}
                      type={type}
                      refresh={refresh}
                      tutelaLegaleQuote={tutelaLegaleQuote}
                      cristalliQuote={cristalliQuote}
                      rivalsaQuote={rivalsaQuote}
                      assistenzaStradaleQuote={assistenzaStradaleQuote}
                      infortuniConducenteQuote={infortuniConducenteQuote}
                      requestInfo={requestInfo}
                    />

                    <ButtonComponentLogicBackoffice
                      type={type}
                      processingQuote={processingQuote}
                      infortuniConducenteQuote={infortuniConducenteQuote}
                      tutelaLegaleQuote={tutelaLegaleQuote}
                      rivalsaQuote={rivalsaQuote}
                      cristalliQuote={cristalliQuote}
                      assistenzaStradaleQuote={assistenzaStradaleQuote}
                      removeItemFromCart={removeItemFromCart}
                      requestInfo={requestInfo}
                    />
                  </RiskCard>
                )}
              </>
            ))}

            {(processingQuote !== null && processingQuote.company_id === 2) && (
              <>
                {processingQuote.quotation_data_prima.quotation_data_prima_guarantees.map((quote) => (
                  <>
                    {(quote.slug !== 'rca') && (
                      <RiskCard className={riskCardPrimaStyles(primaQuoteTypes.find((type) => type.id === quote.slug), processingQuote)}>
                        <div className="p-3 me-auto">
                          <h5>{primaQuoteTypes.find((type) => type.id === quote.slug).name}</h5>
                        </div>
                        <SelectedQuotesCardsPrimaBackoffice
                          processingQuote={processingQuote}
                          type={primaQuoteTypes.find((type) => type.id === quote.slug)}
                        />

                        <ButtonComponentLogicPrimaBackoffice
                          type={primaQuoteTypes.find((type) => type.id === quote.slug)}
                          processingQuote={processingQuote}
                          requestInfo={requestInfo}
                        />
                      </RiskCard>
                    )}
                  </>
                ))}
              </>
            )}
          </RiskContainer>

          {quotationTypes.map((type, index) => (
            <ModalQuotesBackoffice
              processingQuote={processingQuote}
              key={index}
              type={type}
              addingQuote={addingQuote}
              sortBy={sortBy}
              sortQuotes={sortQuotes}
              handleCurrentQuotesDisplay={handleCurrentQuotesDisplay}
              currentQuotes={currentQuotes}
              requestInfo={requestInfo}
              counter={counter}
              quotations={quotations}
              requestToken={requestToken}
              refresh={refresh}
              selectQuotation={selectQuotation}
              guaranteesPrima={guaranteesPrima}
              preventivas_quotes={preventivas_quotes}
            />
          ))}

          {(processingQuote !== null && processingQuote.company_id === 2) && primaQuoteTypes.map((type, index) => (
            <ModalQuotesPrimaBackoffice
              processingQuote={processingQuote}
              key={index}
              type={type}
              addingQuote={addingQuote}
              sortBy={sortBy}
              sortQuotes={sortQuotes}
              requestInfo={requestInfo}
              requestToken={requestToken}
              guaranteesPrima={guaranteesPrima}
            />
          ))}

          {/* <div className="row mb-4">
            <div className="d-flex align-items-center justify-content-center gap-2 mb-3">
              <button className={`btn ${currentQuotes === 'diretta' ? 'btn-success' : 'btn-outline-success'}`} onClick={() => handleCurrentQuotesDisplay('diretta')}>Compagnie Dirette</button>
              <button className={`btn ${currentQuotes === 'indiretta' ? 'btn-success' : 'btn-outline-success'}`} onClick={() => handleCurrentQuotesDisplay('indiretta')}>Compagnie Indirette</button>
            </div>
            {(requestInfo.product_id == 1 && requestInfo.with_preventivas === null) && (
              <div className="d-flex align-items-center justify-content-center mb-2">
                <QuoteLoader>{counter}</QuoteLoader>
                {' '}
                <span className="ms-2">Recuperando più preventivi...</span>
              </div>
            )}
            {quotes && (currentQuotes === 'diretta' || currentQuotes === 'indiretta') && quotes.map((quote) => (
              currentQuotes === 'diretta' ? (
                <>
                  {quote.company_id === 1
                    && (
                      <div className="col-12 mb-2" key={quote.id}>
                        <AdriaticCard requestToken={requestToken} requestInfo={requestInfo} key={quote.id} quote={quote.quotation_data_adriatic} quotation={quote} company={quote.company} saved_quote={quote.saved_quotation} refresh={refresh} />
                      </div>
                    )}
                  {quote.company_id === 2
                    && (
                      <div className="col-12 mb-2" key={quote.id}>
                        <PrimaCard requestToken={requestToken} requestInfo={requestInfo} key={quote.id} quotation={quote} saved_quote={quote.saved_quotation} guaranteesPrima={guaranteesPrima} quotationAquistaLoading={quotationAquistaLoading} refresh={refresh} />
                      </div>
                    )}
                  {(quote.company_id === 9 || quote.company_id === 10)
                    && (
                      <div className="col-12 mb-2">
                        <PreventivassCardLinearAndDallbogg requestInfo={requestInfo} quotation={quote} refresh={refresh} />
                      </div>
                    )}
                  {quote.company_id === null && quote.added_manually === 1
                    && (
                      <div className="col-12 mb-2" key={quote.id}>
                        <PreventivassCard requestInfo={requestInfo} key={quote.id} quotation={quote} refresh={refresh} />
                      </div>
                    )}
                </>
              ) : currentQuotes === 'indiretta' ? (
                <>
                  {quote.company_id === null && quote.added_manually !== 1
                    && (
                      <div className="col-12 mb-2" key={quote.id}>
                        <PreventivassCard requestInfo={requestInfo} key={quote.id} quotation={quote} refresh={refresh} />
                      </div>
                    )}
                </>
              ) : ''
            ))}

          </div> */}

          {(failedQuotes !== null && failedQuotes.length > 0)
            && (
              <div className="row mt-5">
                <FailedQuoteTitle>Queste compagnie non hanno risposto con una quotazione.</FailedQuoteTitle>
                {failedQuotes.map((quote, index) => (
                  <div className="col-3" key={index}>
                    <FailedQuotesContainer>
                      <img src={BACKEND_BASE_URL + quote.company.logo} alt={`${quote.company.name} Logo`} />
                    </FailedQuotesContainer>
                  </div>
                ))}
              </div>
            )}
        </div>
      </RcQuotesStyles>
    </Layout>
  );
}

export default BackOfficeVehicleQuotes;
