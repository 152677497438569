import styled from 'styled-components';

export const ProgressBarStyles = styled.div`
  .stepsProgressBar {
    /* max-width: 800px; */
    /* width: 100%; */
    border-radius: 30px;
    overflow: hidden;
  }
  .stepsProgressBar .p-step {
    background: #c4c4c7;
    color: #fff;
    padding: 10px;
    font-weight: 600;
    border-left: 2px solid #ffffff;
    border-right: 2px solid #ffffff;
    width: 180px;
    text-align: center;
    padding: 20px 0px;
  }
  .stepsProgressBar .p-step .indicator {
    font-size: 16px;
  }
  .stepsProgressBar .p-step.completed {
    background-color: #00a651;
  }
  .stepsProgressBar .p-step:first-child {
    border-left: none;
  }
  .stepsProgressBar .p-step:last-child {
    border-right: none;
  }

  @media screen and (max-width: 430px) {
    .stepsProgressBar .p-step {
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 5px;
      width: 105px;
    }
    .stepsProgressBar .p-step .indicator {
      display: none;
    }
  }
  @media (min-width: 431px) and (max-width: 520px) {
    .stepsProgressBar .p-step {
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px 5px;
      width: 110px;
    }
    .stepsProgressBar .p-step .indicator {
      display: none;
    }
  }
  @media (min-width: 521px) and (max-width: 639px) {
    .stepsProgressBar .p-step {
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 5px;
      width: 130px;
    }
    .stepsProgressBar .p-step .indicator {
      display: none;
    }
  }
  @media (min-width: 640px) and (max-width: 1100px) {
    .stepsProgressBar .p-step {
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: left;
      padding: 20px 0px;
      width: 155px;
    }
    .stepsProgressBar .p-step .indicator {
      font-weight: 800;
      margin-right: 5px;
      font-size: 14px;
    }
  }
`;
