import React, { useState } from 'react';
import {
  AddGuaranteesButton, AquistaButton, AquistaButtonsContainer, CompanyInformationContainer, EuropeassistanceCardStyles, GuaranteesList, GuaranteesListItem, LeftCol, LogoContainer, LogoPriceContainer, Price, PriceContainer, QuoteInformationContainer, QuoteInformationItem, RightCol, SelectedGuaranteesContainer,
} from './EuropeassistanceCard.styles';
import { BACKEND_BASE_URL } from '../../../../config';
import GuaranteesModalTravel from '../Modal/GuaranteesModalTravel';
import { SavedButton } from './common/Buttons.styles';

function EuropeassistanceCard({
  quote, guarantees, requestToken, insuranceRequest, selectQuotation,
}) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      {openModal && <GuaranteesModalTravel setOpenModal={setOpenModal} quote={quote} guarantees={guarantees} requestToken={requestToken} insuranceRequest={insuranceRequest} />}
      <EuropeassistanceCardStyles>
        <LeftCol>
          <LogoPriceContainer>
            <LogoContainer>
              <img src={BACKEND_BASE_URL + quote.company.logo} alt={`${quote.company.name} Logo`} />
            </LogoContainer>
            <PriceContainer>
              <Price>
                <h5>
                  Prezzo annuo:
                </h5>
                <h2>
                  €
                  {parseFloat(quote.amount).toFixed(2)}
                </h2>
              </Price>
            </PriceContainer>
          </LogoPriceContainer>
          <CompanyInformationContainer>
            {/* <CompanyInfoElements onClick={() => downloadResourceDocs(quote.quotation_data_travel_europeassistance.product_code)}>
              <img src={preContractIcon} alt="" />
              <p>Informazioni Precontrattuali</p>
            </CompanyInfoElements> */}
          </CompanyInformationContainer>
          <QuoteInformationContainer>
            <QuoteInformationItem>
              <h6>
                Prodotto:
              </h6>
              <p>
                {quote.quotation_data_travel_europeassistance.product_name}
              </p>
            </QuoteInformationItem>
            {quote.quotation_data_travel_europeassistance.coverages.map((coverage, index) => (
              <React.Fragment key={index}>
                {(coverage.enabled === 0 && coverage.group_id === null) && (
                  <QuoteInformationItem>
                    <h6>
                      {coverage.title}
                      :
                    </h6>
                    <p>
                      {coverage.limit_desc}
                    </p>
                  </QuoteInformationItem>
                )}
                {
                  quote.quotation_coverages.map((cov, index) => (
                    coverage.coverage_id === cov.guarantees_slug
                    && (
                      <QuoteInformationItem key={index}>
                        <h6>
                          {coverage.title}
                          :
                        </h6>
                        <p>
                          {coverage.limit_desc}
                        </p>
                      </QuoteInformationItem>
                    )
                  ))
                }
              </React.Fragment>
            ))}
          </QuoteInformationContainer>
        </LeftCol>
        <RightCol>
          <SelectedGuaranteesContainer>
            <h2>
              <b>Garanzie</b>
              {' '}
              Aggiunte
            </h2>
            <GuaranteesList style={{ height: 'unset !important' }}>
              {quote.quotation_data_travel_europeassistance.coverages.map((coverage, index) => (
                <React.Fragment key={index}>
                  {(coverage.enabled === 0 && coverage.group_id === null)
                    && (
                      <GuaranteesListItem>
                        <h6>
                          <span />
                          {' '}
                          {coverage.title}
                        </h6>
                        <p>
                          &euro;
                          {parseFloat(coverage.coverage_amount_gros).toLocaleString('en-US')}
                        </p>
                      </GuaranteesListItem>
                    )}
                  {
                    quote.quotation_coverages.map((cov, index) => (
                      coverage.coverage_id === cov.guarantees_slug
                      && (
                        <GuaranteesListItem key={index}>
                          <h6>
                            <span />
                            {' '}
                            {cov.guarantees_description}
                          </h6>
                          <p>
                            &euro;
                            {parseFloat(cov.amount).toLocaleString('en-US')}
                          </p>
                        </GuaranteesListItem>
                      )
                    ))
                  }
                </React.Fragment>
              ))}

            </GuaranteesList>
          </SelectedGuaranteesContainer>
          <AquistaButtonsContainer>
            <AddGuaranteesButton onClick={() => setOpenModal(true)} className="result-travel-fourth-step">Seleziona Garanzie</AddGuaranteesButton>
            {insuranceRequest.processing_quotation_id !== quote.id ? (
              <AquistaButton
                onClick={() => selectQuotation(quote.id)}
                className="result-travel-fifth-step"
              >
                Seleziona
              </AquistaButton>
            ) : insuranceRequest.processing_quotation_id === quote.id && <SavedButton>Aggiunto</SavedButton>}
          </AquistaButtonsContainer>
        </RightCol>
      </EuropeassistanceCardStyles>
    </>
  );
}

export default EuropeassistanceCard;
