import { generalAlertError } from '../../common/utils/alerts';
import managementAxe from '../../services/axios-client/managementServisAxeClient';
import caller from './platformChatSlice';

export const getPlatformThread = async (thread_id) => {
  caller.getPlatformThreadPending();
  try {
    const res = await managementAxe.get(`broker/messages/get-thread-broker/${thread_id}`);

    if (res.status !== 200) {
      generalAlertError('Messagi non trovati!');
      caller.getPlatformThreadRejected('Messagi non trovati!');
    }

    caller.getPlatformThreadSuccess({
      thread: res.data.data,
    });
  } catch (error) {
    caller.getPlatformThreadRejected('Errore Interno');
  }
};

export const getPlatformTicket = async (ticket_id) => {
  caller.getPlatformTicketPending();
  try {
    const res = await managementAxe.get(`broker/tickets/get-ticket/${ticket_id}`);

    if (res.status !== 200) {
      generalAlertError('Messagi non trovati!');
      caller.getPlatformThreadRejected('Messagi non trovati!');
    }

    caller.getPlatformTicketSuccess({
      ticket: res.data.data,
    });
  } catch (error) {
    caller.getPlatformTicketRejected('Errore Interno');
  }
};

export const updateStatePlatformChat = (data) => {
  caller.updateLocationAndToken(data);
};

export const resetPlatformChat = () => {
  caller.updatePlatformChatState();
};
