import styled from 'styled-components';

export const TicketPageStyles = styled.div`
    .bg-greenia-green {
        background-color: #00A651;
    }

    .text-greenia-green {
        color: #00A651;
    }

    .message-box {
        position: relative;
        border-radius: 10px;
        border: 1px solid #dee2e6;
        padding: 15px;
    }

    .message-container {
        max-height: 400px;
        overflow-y: auto;
    }

    .send-message-btn {
        padding: 8px 35px;
        background-color: #00a651;
        border: 2px solid #00A651;
        transition: all 300ms ease-in-out;
        color: #ffffff;
    }

    .send-message-btn:hover {
        background-color: transparent;
        border: 2px solid #00A651;
        color: #00A651;
    }

    .form-control:focus.reply-input {
        color: #212529;
        background-color: #fff;
        border-color: rgb(0 166 81 / 50%);
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgb(0 166 81 / 25%)
    }

    .reply-box {
        background-color: #ffffff;
        padding: 15px 5px;
    }
`;
