import { createSlice } from '@reduxjs/toolkit';

import { callerCreator } from '../callerCreator';

const initialState = {
  auto: {
    loading: true,
    quote: null,
    insuranceRequest: null,
    quoteStatus: 1,
    uploadingIdCard: false,
    uploadingVehicleCard: false,
    uploadingLibrettoVeicoloBersani: false,
    uploadingStatoDiFamiglia: false,
    uploadingIdCardFamigliare: false,
    uploadingVisuraCamerale: false,
    signMethod: null,
    uploadedFirstDocs: {
      id_card: 0,
      vehicle_card: 0,
      libretto_veicolo_bersani: 0,
      stato_di_famiglia: 0,
      id_card_famigliare: 0,
      visura_camerale: 0,
    },
    backofficeUsers: [],
    uploadingOptionalDocumentRequestId: null,
    with_compensation: 0,
    rc_quotation: null,
    tutela_quotation: null,
    assistenza_quotation: null,
    cristalli_quotation: null,
    infortuni_conducente_quotation: null,
    rivalsa_quotation: null,
    nonSignableDocs: [],
    requestedOptionalDocumentsNonSignable: [],
    signableDocs: [],
    requestedOptionalDocumentsSignable: [],
  },
  autocarro: {
    loading: true,
    quote: null,
    insuranceRequest: null,
    quoteStatus: 1,
    uploadingIdCard: false,
    uploadingVehicleCard: false,
    uploadingLibrettoVeicoloBersani: false,
    uploadingStatoDiFamiglia: false,
    uploadingIdCardFamigliare: false,
    uploadingVisuraCamerale: false,
    signMethod: null,
    uploadedFirstDocs: {
      id_card: 0,
      vehicle_card: 0,
      libretto_veicolo_bersani: 0,
      stato_di_famiglia: 0,
      id_card_famigliare: 0,
      visura_camerale: 0,
    },
    backofficeUsers: [],
    uploadingOptionalDocumentRequestId: null,
    with_compensation: 0,
    rc_quotation: null,
    tutela_quotation: null,
    assistenza_quotation: null,
    cristalli_quotation: null,
    infortuni_conducente_quotation: null,
    rivalsa_quotation: null,
    nonSignableDocs: [],
    requestedOptionalDocumentsNonSignable: [],
    signableDocs: [],
    requestedOptionalDocumentsSignable: [],
  },
  moto: {
    loading: true,
    quote: null,
    insuranceRequest: null,
    quoteStatus: 1,
    uploadingIdCard: false,
    uploadingVehicleCard: false,
    uploadingLibrettoVeicoloBersani: false,
    uploadingStatoDiFamiglia: false,
    uploadingIdCardFamigliare: false,
    uploadingVisuraCamerale: false,
    signMethod: null,
    uploadedFirstDocs: {
      id_card: 0,
      vehicle_card: 0,
      libretto_veicolo_bersani: 0,
      stato_di_famiglia: 0,
      id_card_famigliare: 0,
      visura_camerale: 0,
    },
    backofficeUsers: [],
    uploadingOptionalDocumentRequestId: null,
    with_compensation: 0,
    rc_quotation: null,
    tutela_quotation: null,
    nonSignableDocs: [],
    requestedOptionalDocumentsNonSignable: [],
    signableDocs: [],
    requestedOptionalDocumentsSignable: [],
  },
  profession: {
    loading: true,
    quote: null,
    insuranceRequest: null,
    quoteStatus: 1,
    uploadingIdCard: false,
    uploadingModuloDiProposta: false,
    uploadingVisuraCamerale: false,
    signMethod: null,
    uploadedFirstDocs: {
      id_card: 0,
      modulo_di_proposta: 0,
      visura_camerale: 0,
    },
    backofficeUsers: [],
    with_compensation: 0,
    rc_quotation: null,
    tutela_quotation: null,
    nonSignableDocs: [],
    requestedOptionalDocumentsNonSignable: [],
    signableDocs: [],
    requestedOptionalDocumentsSignable: [],
  },
  infortuni: {
    loading: true,
    quote: null,
    insuranceRequest: null,
    quoteStatus: 1,
    uploadingIdCard: false,
    uploadingVisuraCamerale: false,
    signMethod: null,
    uploadedFirstDocs: {
      id_card: 0,
      visura_camerale: 0,
    },
    backofficeUsers: [],
    with_compensation: 0,
  },
  infodrive: {
    loading: true,
    quote: null,
    insuranceRequest: null,
    infodriveQuotationData: null,
    quoteStatus: 4,
    backofficeUsers: [],
    with_compensation: 0,
  },
  travel: {
    loading: true,
    quotation: null,
    insuranceRequest: null,
    quoteStatus: 4,
    backofficeUsers: [],
    rc_quotation: null,
    tutela_quotation: null,
    nonSignableDocs: [],
    requestedOptionalDocumentsNonSignable: [],
  },

  commercial: {
    loading: true,
    quotation: null,
    insuranceRequest: null,
    quoteStatus: 4,
    backofficeUsers: [],
  },
};

export const purchasesSlice = createSlice({
  name: 'purchases',
  initialState,
  reducers: {
    autoUpdateState: (state, { payload }) => {
      state.auto = { ...state.auto, ...payload };
    },
    autoResetState: (state) => {
      state.auto = { ...initialState.auto };
    },

    autocarroUpdateState: (state, { payload }) => {
      state.autocarro = { ...state.autocarro, ...payload };
    },
    autocarroResetState: (state) => {
      state.autocarro = { ...initialState.autocarro };
    },
    motoUpdateState: (state, { payload }) => {
      state.moto = { ...state.moto, ...payload };
    },
    motoResetState: (state) => {
      state.moto = { ...initialState.moto };
    },

    professionUpdateState: (state, { payload }) => {
      state.profession = { ...state.profession, ...payload };
    },
    professionResetState: (state) => {
      state.profession = { ...initialState.profession };
    },
    infortuniUpdateState: (state, { payload }) => {
      state.infortuni = { ...state.infortuni, ...payload };
    },
    infortuniResetState: (state) => {
      state.infortuni = { ...initialState.infortuni };
    },
    infodriveUpdateState: (state, { payload }) => {
      state.infodrive = { ...state.infodrive, ...payload };
    },
    infodriveResetState: (state) => {
      state.infodrive = { ...initialState.infodrive };
    },
    travelUpdateState: (state, { payload }) => {
      state.travel = { ...state.travel, ...payload };
    },
    travelResetState: (state) => {
      state.travel = { ...initialState.travel };
    },
    commercialUpdateState: (state, { payload }) => {
      state.commercial = { ...state.commercial, ...payload };
    },
    commercialResetState: (state) => {
      state.commercial = { ...initialState.commercial };
    },
  },
});

export default callerCreator(purchasesSlice.actions);
